import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';
import IToken from '../../Login/IToken';
import { map, switchMap, filter } from 'rxjs/operators';
import decode from 'jwt-decode';

export default class UnitConventionProxy {
  authService: IToken;

  constructor(auth: IToken) {
    this.authService = auth;
  }

  get() {
    return this.authService
      .getTokenObservable()
      .pipe(filter((token) => !!token))
      .pipe(
        switchMap((token) => {
          const blc = (decode(token) as { blc: string }).blc;
          return GlobalAPICall.getObservable(process.env.REACT_APP_API_URL + `/api/unitconvention/${blc}`, {
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          });
        })
      )
      .pipe(map((response) => response.data));
  }
}
