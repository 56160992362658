import React from 'react';
import { ListItemText } from '@material-ui/core';
import { Link } from 'react-router-dom';

const HuquqPageTemplate = ({ data }) => {
  return (
    <div>
      <div>
        <h1 data-cy="title" data-testid="title">
          {data.base__title.value}
        </h1>
        <p data-cy="body-text" data-testid="body-text" dangerouslySetInnerHTML={{ __html: data.base__body.value }} />
      </div>
      <div>
        <ul className="plain-list">
          {data.linked_items.value.map((eachValue, key) => (
            <li key={key}>
              <Link
                to={
                  '/huququllah-resources/' +
                  encodeURIComponent(data.system.codename) +
                  '/' +
                  encodeURIComponent(eachValue.system.codename)
                }
              >
                <ListItemText primary={eachValue.base__title.value} />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default HuquqPageTemplate;
