import React, { Component } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withContext from '../ContextAPI/Context_HOC';
import { withRouter } from 'react-router-dom';
import { InputBase } from '@material-ui/core';
import { Row, Col } from 'react-flexbox-grid';
import QueryString from 'query-string';

const styles = () => ({
  inputInput: {
    minWidth: '400px',
    border: '1px solid brown',
    borderRadius: '10px',
    padding: '0px 5px',
  },
});

class NoMatch extends Component {
  constructor(props) {
    super(props);
    this.handleSearch = this.handleSearch.bind(this);
    this.submitSearch = this.submitSearch.bind(this);
    this.state = {
      searchValue: '',
    };
  }
  handleSearch(e) {
    this.setState({ searchValue: e.target.value });
  }
  submitSearch(e) {
    e.preventDefault();
    const queryParameters = { searchString: this.state.searchValue };
    const queryStringified = QueryString.stringify(queryParameters);
    this.props.history.push({
      pathname: '/search-results',
      search: queryStringified,
    });
  }

  render() {
    return (
      <Row middle="xs" data-cy="noMatchFoundPage" className="center page-vertical-center">
        <Col xs={12}>
          <form onSubmit={this.submitSearch}>
            <div>
              <h3>Oops! That page can’t be found.</h3>
              <h4>It looks like nothing was found at this location. Maybe try to search again?</h4>
              <InputBase
                placeholder="Search…"
                className="bounding-box start wide-form-min"
                id="searchinput"
                value={this.state.searchvalue}
                onChange={this.handleSearch}
              />
              <SearchIcon onClick={this.submitSearch} />
            </div>
          </form>
        </Col>
      </Row>
    );
  }
}
NoMatch.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withContext(withStyles(styles)(NoMatch)));
