import React, { ChangeEvent, useEffect } from 'react';
import { sortBy } from 'lodash';
import withContext from '../../ContextAPI/Context_HOC';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
import ServiceOpsRichTextResolver from '../Utilities/ServiceOpsRichTextResolver';
import ServiceOpsFlatter from '../Utilities/ServiceOpsFlatter';
import type ContentProviderEndpoint from '../../Content/ContentProviderEndpoint';
import type { ServiceOpportunitiesFlat } from './Model/service_opportunities_flat';
import type { ServiceOpportunities } from '../../Kentico/DigitalDesign/service_opportunities';
import DisplayResourcesList from '../DisplayResourcesList';

function UnitedStates(props: { context: { digitalDesignContent: ContentProviderEndpoint } }) {
  const [value, setValue] = React.useState<Number>(0);
  const [serviceOpsList, setServiceOpsList] = React.useState<ServiceOpportunitiesFlat[]>([]);
  const contentProvider = props.context.digitalDesignContent;

  useEffect(() => {
    const subscription = contentProvider
      .items<ServiceOpportunities>()
      .type('service_opportunities')
      .containsFilter('elements.service_opportunities_taxonomy', ['united_states'])
      .elementsParameter([
        'base__title',
        'base__body',
        'base__datepublished',
        'department',
        'location',
        'document_download',
        'position_type',
      ])
      .toObservable()
      .subscribe((response) => {
        if (!response.isEmpty) {
          const itemsResolved = ServiceOpsRichTextResolver(response.items);
          const itemsResolvedFlattened = ServiceOpsFlatter(itemsResolved);
          const itemsResolvedFlattenedSorted = sortBy(
            itemsResolvedFlattened,
            'isSummerPosition',
            'department.value',
            'base__title.value'
          );
          setServiceOpsList(itemsResolvedFlattenedSorted);
        }
      });
    return () => subscription.unsubscribe();
  }, [contentProvider]);

  const handleChange = (event: ChangeEvent<{}>, newValue: Number) => {
    setValue(newValue);
  };

  return (
    <div>
      <h1>United States Service Opportunities</h1>
      <Paper>
        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
          <Tab label="United States" component={Link} data-cy="us-opportunities" to="/resources/service-opportunities" />
          <Tab label="International" component={Link} data-cy="international-opportunities" to="/resources/international" />
          <Tab label="World Center" component={Link} data-cy="world-center-opportunities" to="/resources/world-center" />
        </Tabs>
      </Paper>
      <br />
      <p>
        Bahá’ís everywhere are engaged in rebuilding the world to a new design -- neighborhood by neighborhood, with a new kind of
        community life that welcomes, includes, honors, and respects all.
      </p>
      <p>We want our national organization to reflect in every way our unshakeable commitment to the pursuit of this vision.</p>
      <p>
        This means ensuring that our staff -- who have the bounty of serving the National Spiritual Assembly in a host of ways --
        are fully representative of the diversity of the people of this nation, and that we create spaces for the unique
        contributions of people from every race, nationality, and religious heritage. This also means that we are committed to an
        organizational life that seeks to ensure that women and men in equal measure are accorded a role in its stewardship.
      </p>
      <p>
        If the thought of serving at the national level in this mission appeals to you, we would welcome an opportunity to get to
        know you, and the skills, experience, and attitude you might bring to a service opportunity. Please send us your cover
        letter, current resume, and Bahá’í ID to <a href="mailto:BNO-Service@usbnc.org">BNO-Service@usbnc.org</a>.
      </p>
      <hr />
      {DisplayResourcesList({ dataArray: serviceOpsList, type: 'Position' })}
    </div>
  );
}

export default withContext(UnitedStates);
