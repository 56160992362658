import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import TextField from '@material-ui/core/TextField';
import { emailFormat, phoneFormat } from '../Utilities/GeneralRegEx';
import { BaseMyProfileState } from './EditContactInfo';

export const validationStateSchema = {
  emergencyContactName: {
    required: false,
    validator: null,
  },
  emergencyContactRelationship: {
    required: false,
    validator: null,
  },
  emergencyContactPhone: {
    required: false,
    validator: {
      regEx: phoneFormat,
      error: 'Invalid phone format.',
    },
  },
  emergencyContactEmail: {
    required: false,
    validator: {
      regEx: emailFormat,
      error: 'Invalid email format.',
    },
  },
};

const EditEmergencyContact = (props: BaseMyProfileState) => {
  const details = props.details;
  return (
    <div>
      <h4 className="medium-left-padding small-top-padding">Emergency Contact Information</h4>
      <Row className="small-bottom-padding">
        <Col sm={6}>
          <TextField
            inputProps={{ 'data-cy': 'inputemergencyContactName', maxLength: '40' }}
            id="emergencyContactName"
            name="emergencyContactName"
            label="Name"
            aria-label="Name"
            type="text"
            value={details.emergencyContactName.value}
            onChange={props.handleChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Col>
        <Col sm={6}>
          <TextField
            inputProps={{ 'data-cy': 'inputemergencyContactRelationship', maxLength: '40' }}
            id="emergencyContactRelationship"
            name="emergencyContactRelationship"
            label="Relationship"
            aria-label="Relationship"
            type="text"
            value={details.emergencyContactRelationship.value}
            onChange={props.handleChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <TextField
            inputProps={{ 'data-cy': 'inputemergencyContactEmail', maxLength: '40' }}
            id="emergencyContactEmail"
            name="emergencyContactEmail"
            label="Email"
            aria-label="Email"
            error={!!details.emergencyContactEmail.error}
            type="text"
            value={details.emergencyContactEmail.value}
            onChange={props.handleChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          {details.emergencyContactEmail.error && (
            <p className="form-field-error" data-cy="inputemergencyContactEmail_Format">
              {details.emergencyContactEmail.error}
            </p>
          )}
        </Col>
        <Col sm={6}>
          <TextField
            inputProps={{ 'data-cy': 'inputemergencyContactPhone', maxLength: '40' }}
            id="emergencyContactPhone"
            name="emergencyContactPhone"
            label="Cell Phone"
            aria-label="Cell Phone"
            error={!!details.emergencyContactPhone.error}
            type="text"
            value={details.emergencyContactPhone.value}
            onChange={props.handleChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          {details.emergencyContactPhone.error && (
            <p className="form-field-error" data-cy="inputemergencyContactPhone_Format">
              {details.emergencyContactPhone.error}
            </p>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EditEmergencyContact;
