import React from 'react';
import Modal from '../Profiles/Modal';

const DownloadNotificationModal = (props) => {
  return (
    <div>
      <Modal show={props.show}>
        <h4 data-cy="notificationModal">The downloadable file(s) are available under &quot;Attachments&quot;.</h4>

        <input type="button" value="Ok" className="primary-button" onClick={props.handleHideModal} data-cy="okButton" />
      </Modal>
    </div>
  );
};
export default DownloadNotificationModal;
