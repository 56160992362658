import React from "react";
import {IMaskInput} from "react-imask";

export const TextMaskZipCodeUtil = React.forwardRef<HTMLInputElement, any>(
  function TextMaskCustom(props, ref) {
    const {onChange, ...other} = props;
    return (
      <IMaskInput
        {...other}
        variant="outlined"
        mask="00000"
        minLength={5}
        inputRef={ref}
        onAccept={(value: any) => onChange({target: {name: props.name, value}})}
        overwrite
      />
    );
  },
);
