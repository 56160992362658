import React from 'react';
import withContext from '../ContextAPI/Context_HOC';
import { Link } from 'react-router-dom';

class RecentFeaturesListReference extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newsarticleLinks: [],
      collections: [],
    };
  }

  render() {
    const otherUpdates = this.props.details.filter((x) => x.url_slug.value && x.url_slug.value !== this.props.url);

    if (!otherUpdates || !otherUpdates.length) {
      return null;
    }

    return (
      <div>
        <h4 className="sidebar-headers">Recent Features</h4>
        <ul className="plain-list story-tags">
          {otherUpdates.map((item, key) => (
            <li key={key}>
              <Link className="plain-link news-sidebar-titles" to={{ pathname: `/website-updates/${item.url_slug.value}` }}>
                {item.base__title.value}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
export default withContext(RecentFeaturesListReference);
