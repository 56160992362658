import React, { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import withContext from '../../ContextAPI/Context_HOC';
import parse from 'html-react-parser';
import NatConProxy from '../Utilities/NatConProxy';

const NatConArticle: FunctionComponent<{
  match: { params: { id: string } };
  context: { natConProxy: NatConProxy };
  natConTaxonomy: string;
}> = ({ match, context: { natConProxy }, natConTaxonomy }) => {
  const [featuredImageUrl, setFeaturedImageUrl] = useState<string | null>(null);
  const [featuredImageDescription, setFeaturedImageDescription] = useState<string | undefined>();
  const [baseTitle, setBaseTitle] = useState<string>('');
  const [listenSection, setListenSection] = useState<JSX.Element | JSX.Element[] | null>(null);
  const [articleBody, setArticleBody] = useState<JSX.Element | JSX.Element[] | null>(null);

  useEffect(() => {
    const natConProxyGetNatConSubscription = natConProxy.getNatCons(natConTaxonomy).subscribe((x) => {
      x.map((article) => {
        if (article.system.codename === match.params.id) {
          const bodyRawHtml = article.baseBody.resolveHtml();

          if (article.baseListenhtml?.value) {
            const builtListenSection = (
              <span>
                <h4 className="sidebar-headers">Listen</h4>
                <div
                  data-cy="listen-section-container"
                  dangerouslySetInnerHTML={{
                    __html: article.baseListenhtml.value,
                  }}
                />
              </span>
            );
            setListenSection(builtListenSection);
          }

          const body = parse(bodyRawHtml);
          setArticleBody(body);
          setFeaturedImageUrl(article.featuredImage?.value?.length > 0 ? article.featuredImage.value[0].url : null);
          setFeaturedImageDescription(article.featuredImage?.value?.length > 0 ? article.featuredImage.value[0].description : '');
          setBaseTitle(article.baseTitle.value);
        }
      });
    });

    return () => natConProxyGetNatConSubscription.unsubscribe();
  }, [natConProxy]);

  return (
    <div>
      {featuredImageUrl ? (
        <Row className="top-margin">
          <Col sm={5}>
            <h1>{baseTitle}</h1>
          </Col>
          <Col sm={7}>
            {featuredImageUrl ? <img src={featuredImageUrl} alt={featuredImageDescription} className="thumbnail-img" /> : null}
          </Col>
        </Row>
      ) : (
        <Row className="top-margin">
          <Col sm={12}>
            <h1>{baseTitle}</h1>
          </Col>
        </Row>
      )}
      <Row>
        <Col sm={12}>{articleBody}</Col>
      </Row>
      {listenSection}
    </div>
  );
};
export default withContext(NatConArticle);
