import React, { Component } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import ContentProviderEndpoint from '../Content/ContentProviderEndpoint';
import ContentResolver, { ContentProjectEnum } from '../Content/ContentResolver';
import { SortOrder } from '@kentico/kontent-delivery';
import { Subscription } from 'rxjs';
import { PropertyAccessor } from '../Shared/Types/PropertyAccessor';
import { ParseHtml, guidanceRef } from './Utilities/HtmlParser';

interface NineYearPlanPioneeringProps {
  context: {
    digitalDesignContent: ContentProviderEndpoint;
    dateOptions: Intl.DateTimeFormatOptions;
    contentResolver: ContentResolver;
  };
}

class NineYearPlanPioneering extends Component {
  subscription: Subscription;
  props: NineYearPlanPioneeringProps;
  state: {
    title?: string;
    bodyDisplay: JSX.Element[];
  };
  constructor(props: NineYearPlanPioneeringProps) {
    super(props);
    this.state = {
      bodyDisplay: [],
    };
  }

  componentDidMount() {
    this.subscription = this.props.context.digitalDesignContent
      .items()
      .types(['special_content'])
      .equalsFilter('system.codename', 'pioneering_in_the_nine_year_plan_5b59d7c')
      .orderParameter('elements.base__datepublished', SortOrder.desc)
      .toObservable()
      .subscribe(async (response) => {
        if (!response.isEmpty) {
          const contentItem = response.firstItem;
          if (!contentItem) return;

          const allLinks = contentItem?.base__body.links.map((link: { codename: any; linkId: any }) => {
            return { codename: link.codename, linkId: link.linkId };
          });
          const allLinkCodenamesLength = allLinks.length;
          if (allLinkCodenamesLength) {
            const tempLinkData: PropertyAccessor = {};
            for (let i = 0; i < allLinkCodenamesLength; i++) {
              const currentLink = allLinks[i];
              const resolvedUrl = await this.props.context.contentResolver.ResolveUrl(
                ContentProjectEnum.DigitalDesign,
                currentLink.codename,
                undefined
              );
              tempLinkData[currentLink.linkId] = resolvedUrl;
            }
            const baseHTML = contentItem?.base__body.value.replace('UHJ® page', `<a href="${guidanceRef}">this page</a>`);
            const parsedBody = ParseHtml(baseHTML, tempLinkData);

            this.setState({ bodyDisplay: parsedBody, title: contentItem?.base__title.value });
          }
        }
      });
  }
  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  render() {
    return (
      <div>
        <h1 data-testid="title" data-cy="title" className="title">
          {this.state.title ?? ''}
        </h1>
        {this.state.bodyDisplay.length
          ? this.state.bodyDisplay.map((elem, key) => {
              return (
                <div key={key} data-cy="body-text">
                  {elem}
                </div>
              );
            })
          : 'Loading...'}
      </div>
    );
  }
}

export default withContext(NineYearPlanPioneering);
