import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { History } from 'history';
import withContext from '../../ContextAPI/Context_HOC';
import { Row, Col } from 'react-flexbox-grid';
import type HouseholdMemberModel from '../Utilities/HouseholdMemberModel';
import type PhysicalAddressChangeResponseModel from '../Utilities/PhysicalAddressChangeResponseModel';
import type IPhysicalAddressProxy from '../Utilities/IPhysicalAddressProxy';
import type IHouseholdProxy from '../Utilities/IHouseholdProxy';
import { combineLatest } from 'rxjs';
import PhysicalAddressModel from '../Utilities/PhysicalAddressModel';
import { CircularProgress } from '@material-ui/core';
import ErrorMessageToUser from '../../Error/ErrorMessageToUser';
import PhysicalAddressChangeRequestModel from '../Utilities/PhysicalAddressChangeRequestModel';
import { ChangeResult } from '../Utilities/PhysicalAddressChangeResponseModelWithStatus';

export type PropsContext = {
  physicalAddressProxy: IPhysicalAddressProxy;
  householdProxy: IHouseholdProxy;
  setEditContactInformationSuccessMessage: (newEditContactInformationSuccessMessage: string | undefined) => void;
  setEditContactInformationNeutralMessage: (newEditContactInformationNeutralMessage: string | undefined) => void;
};

export type Props = {
  goToPreviousPage: () => void;
  newAddress: undefined | PhysicalAddressChangeResponseModel;
  history: History;
  context: PropsContext;
  requestModel: PhysicalAddressChangeRequestModel;
};

const SummaryPage: FunctionComponent<Props> = ({
  goToPreviousPage,
  newAddress,
  requestModel,
  history,
  context: {
    physicalAddressProxy,
    householdProxy,
    setEditContactInformationSuccessMessage,
    setEditContactInformationNeutralMessage,
  },
}) => {
  const [showErrorMessageToUser, setShowErrorMessageToUser] = useState(false);
  const [showCircularProgress, setShowCircularProgress] = useState(true);
  const [oldLocalityCode, setOldLocalityCode] = useState<string | null>(null);
  const [oldLocalityName, setOldLocalityName] = useState<string | null>(null);
  const [oldClusterCode, setOldClusterCode] = useState<string | null>(null);
  const [oldClusterName, setOldClusterName] = useState<string | null>(null);
  const [oldResidentialAddress, setOldResidentialAddress] = useState<PhysicalAddressModel | undefined>();
  const [eligibleMembers, setEligibleMembers] = useState<HouseholdMemberModel[]>([]);

  useEffect(() => {
    setShowCircularProgress(true);
    const physicalAddressProxyGetObservable = physicalAddressProxy.get();
    const householdProxyPostObservable = householdProxy.members({ IsEligibleToMove: true });

    const combinedObservables = combineLatest([physicalAddressProxyGetObservable, householdProxyPostObservable]);

    const subscription = combinedObservables.subscribe({
      next: (combinedResponses) => {
        const physicalAddressResponse = combinedResponses[0];
        const eligibleMembersResponse = combinedResponses[1];

        setOldLocalityCode(physicalAddressResponse.blc);
        setOldLocalityName(physicalAddressResponse.localityName);
        setOldClusterCode(physicalAddressResponse.clusterCode);
        setOldClusterName(physicalAddressResponse.clusterName);
        setOldResidentialAddress(physicalAddressResponse);
        setEligibleMembers(eligibleMembersResponse);
        setShowCircularProgress(false);
      },
      error: () => setShowErrorMessageToUser(true),
    });

    return () => subscription.unsubscribe();
  }, [physicalAddressProxy, householdProxy]);

  const submitChangeRequest = useCallback(() => {
    setShowCircularProgress(true);
    const changeRequest: PhysicalAddressChangeRequestModel = { ...requestModel, validateOnly: false };
    const subscription = physicalAddressProxy.set(changeRequest).subscribe({
      next: (x) => {
        setShowCircularProgress(false);
        if (x.changeResult === ChangeResult.AddressChangeCompleted) {
          setEditContactInformationSuccessMessage('Residential address successfully changed');
        } else if (x.changeResult === ChangeResult.AddressChangePending) {
          setEditContactInformationNeutralMessage('A request has been submitted to change your Residential address');
        } else {
          throw `Unexpected change result ${x.changeResult}`;
        }

        history.replace('/myprofile/contactinfo');
      },
      error: () => setShowErrorMessageToUser(true),
    });

    return () => subscription.unsubscribe();
  }, [physicalAddressProxy, history]);

  if (showErrorMessageToUser) {
    return <ErrorMessageToUser />;
  }

  return (
    <div>
      <h1>Summary of Changes</h1>
      <div className="profile-box">
        {showCircularProgress ? <CircularProgress data-cy="circular-progress" className="loading-animation" size={40} /> : null}
        <Row>
          <Col sm={6}>
            <h3 className="small-bottom-padding">Old Residential Address:</h3>
            {oldResidentialAddress ? (
              <ul className="plain-list">
                <li>
                  <strong>In Care Of: </strong>
                  {oldResidentialAddress.inCareOf}
                </li>
                <li>{oldResidentialAddress.line1}</li>
                <li>{oldResidentialAddress.line2}</li>
                <li>
                  {oldResidentialAddress.city}, {oldResidentialAddress.state} {oldResidentialAddress.zipcode}
                </li>
                <br />
                <li>
                  <strong>Locality:</strong> {oldLocalityCode} {oldLocalityName}
                </li>
                <li>
                  <strong>Cluster:</strong> {oldClusterCode} {oldClusterName}
                </li>
              </ul>
            ) : null}
          </Col>
          <Col sm={6}>
            <h3 className="small-bottom-padding">New Residential Address:</h3>
            {newAddress ? (
              <ul className="plain-list">
                <li>
                  <strong>In Care Of: </strong>
                  {newAddress.inCareOf}
                </li>
                <li>{newAddress.line1}</li>
                {newAddress.line2 ? <li>{newAddress.line2}</li> : null}
                <li>
                  {newAddress.city}, {newAddress.state} {newAddress.zipcode}
                </li>
                <br />
                <li>
                  <strong>Locality:</strong> {newAddress.newBlc} {newAddress.newLocalityName}
                </li>
                <li>
                  <strong>Cluster:</strong> {newAddress.newClusterCode} {newAddress.newClusterName}
                </li>
              </ul>
            ) : null}
          </Col>
        </Row>
        <br />
        {requestModel.selectedHouseholdMemberIdns && requestModel.selectedHouseholdMemberIdns.length > 0 ? (
          <Row>
            <Col sm={12}>
              <h4>Household members whose residential and mailing address will be updated:</h4>
              {requestModel.selectedHouseholdMemberIdns.map((idn, index) => (
                <div key={index}>
                  <ul className="plain-list">
                    <li>
                      {newAddress?.mailingNotChangingForHouseholdMemberIdns?.includes(idn) ? (
                        <>
                          <strong>*</strong>{' '}
                        </>
                      ) : (
                        <></>
                      )}
                      {eligibleMembers.map((member) => (member.idn === idn ? member.personName : null))}
                    </li>
                  </ul>
                </div>
              ))}
              {newAddress?.mailingNotChangingForHouseholdMemberIdns &&
              newAddress.mailingNotChangingForHouseholdMemberIdns.length > 0 ? (
                <p data-cy="separate-mailing-address-explanation">
                  <strong>*</strong> Household members with a separate mailing address. Their mailing address will NOT be updated
                  through this request, only their Residential Address will be updated.
                </p>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        ) : null}
      </div>
      <div className="medium-top-margin end">
        <Row end="xs" className="medium-top-margin no-padding">
          <Col sm={2} className="no-padding">
            <input type="submit" value="Back" className="secondary-button" data-cy="backsubmit" onClick={goToPreviousPage} />
          </Col>
          <Col sm={2} className="no-padding">
            <input type="submit" value="Save" className="primary-button" data-cy="nextsubmit" onClick={submitChangeRequest} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withRouter(withContext(SummaryPage));
