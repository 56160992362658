import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';
import SafeStringifyAxiosError from '../../Utilities/SafeStringifyAxiosError';

export default function getRegionClusterDirectory(token, regionName) {
  return GlobalAPICall.get(process.env.REACT_APP_API_URL + '/api/RegionClusterDirectory/' + regionName, {
    headers: { Authorization: 'Bearer ' + token },
  })
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      if (err.response.status === 400) {
        return Promise.reject({
          message: SafeStringifyAxiosError(err),
        });
      } else {
        return Promise.reject({
          message: 'Regional cluster is currently unavailable. Please come back later.',
        });
      }
    });
}
