import React, { FC, useState, useEffect } from 'react';
import FeatureKey from './FeatureKey';
import FeatureLocalSetting from './FeatureLocalSetting';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FlashOffIcon from '@material-ui/icons/FlashOff';
import FlashAutoIcon from '@material-ui/icons/FlashAuto';
import FlashOnIcon from '@material-ui/icons/FlashOn';

type Props = {
  featureKey: FeatureKey;
};

const FeatureFlagToggle: FC<Props> = ({ featureKey }) => {
  const [offButton, setOffButton] = useState(false);
  const [autoButton, setAutoButton] = useState(true);
  const [onButton, setOnButton] = useState(false);

  function SetOff(reload: boolean) {
    sessionStorage.setItem(`FeatureSetting:${featureKey}`, FeatureLocalSetting.ForcedOff);
    setOffButton(true);
    setAutoButton(false);
    setOnButton(false);
    if (reload) {
      window.location.reload();
    }
  }

  function SetAuto(reload: boolean) {
    sessionStorage.setItem(`FeatureSetting:${featureKey}`, FeatureLocalSetting.UseCloud);
    setOffButton(false);
    setAutoButton(true);
    setOnButton(false);
    if (reload) {
      window.location.reload();
    }
  }

  function SetOn(reload: boolean) {
    sessionStorage.setItem(`FeatureSetting:${featureKey}`, FeatureLocalSetting.ForcedOn);
    setOffButton(false);
    setAutoButton(false);
    setOnButton(true);
    if (reload) {
      window.location.reload();
    }
  }

  useEffect(() => {
    const currentSetting = sessionStorage.getItem(`FeatureSetting:${featureKey}`);
    if (currentSetting === FeatureLocalSetting.ForcedOff) {
      SetOff(false);
    }
    if (!currentSetting || currentSetting === FeatureLocalSetting.UseCloud) {
      SetAuto(false);
    }
    if (currentSetting === FeatureLocalSetting.ForcedOn) {
      SetOn(false);
    }
  });

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <>
            <Tooltip title={'OFF: ' + FeatureLocalSetting.ForcedOff} aria-label={'OFF: ' + FeatureLocalSetting.ForcedOff}>
              <IconButton onClick={() => SetOff(true)}>
                <FlashOffIcon fontSize={offButton ? 'large' : 'small'} />
              </IconButton>
            </Tooltip>
            <Tooltip title={'AUTO: ' + FeatureLocalSetting.UseCloud} aria-label={'AUTO: ' + FeatureLocalSetting.UseCloud}>
              <IconButton onClick={() => SetAuto(true)}>
                <FlashAutoIcon fontSize={autoButton ? 'large' : 'small'} />
              </IconButton>
            </Tooltip>
            <Tooltip title={'ON: ' + FeatureLocalSetting.ForcedOn} aria-label={'ON: ' + FeatureLocalSetting.ForcedOn}>
              <IconButton onClick={() => SetOn(true)}>
                <FlashOnIcon fontSize={onButton ? 'large' : 'small'} />
              </IconButton>
            </Tooltip>
          </>
        }
        label={
          'Feature ' +
          (offButton ? FeatureLocalSetting.ForcedOff : autoButton ? FeatureLocalSetting.UseCloud : FeatureLocalSetting.ForcedOn) +
          ': ' +
          featureKey
        }
      />
    </FormGroup>
  );
};

export default FeatureFlagToggle;
