import useCustomHookForm from "../../Hooks/forms/use-custom-hook-form";
import {
  CustomFieldFormType
} from "../../Hooks/forms/enums/custom-field-form-type";
import React from "react";
import {Button} from "@mui/material";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import theme from "../../styles/theme";
import {IMaskInput} from "react-imask";
import {CustomField} from "../../Hooks/forms/type/custom-field";


export interface ContactInformationResponseForm {
  homePhone: string;
  cellPhone: string;
  workPhone: string;
  faxPhone: string;
  email: string;
  alternativeEmail: string;
}

export interface ContactInformationProps {
  callbackContactInformation: (response: ContactInformationResponseForm) => void
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, any>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        variant="outlined"
        mask="(#00) 000-0000"
        minLength={14}
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);
export const contactInformationFieldsArray:CustomField<any>[] =  [
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'customTextContactInformation',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <h3 className="text-left">Contact Information</h3>,
    cssClassName: 'col-lg-12'
  },
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'phoneSection',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <p className="text-left">
      <span className="font-weight-bold">Phone</span>
    </p>,
    cssClassName: 'col-lg-12'
  },
  {
    type: CustomFieldFormType.Mask,
    formControlName: 'homePhone',
    label: 'Home phone',
    placeholder: 'Ex: (222)-222-2222',
    help: '',
    cssClassName: 'col-lg-3 p-2',
    validators: {
      minLength:{
        value: 14,
        message: 'Please fill the number Ex: (222)-222-2222'
      }
    },
    inputComponent: TextMaskCustom as any,
  },
  {
    type: CustomFieldFormType.Mask,
    formControlName: 'cellPhone',
    label: 'Cell phone',
    placeholder: 'Ex: (222)-222-2222',
    help: '',
    cssClassName: 'col-lg-3 p-2',
    validators: {
      minLength:{
        value: 14,
        message: 'Please fill the number Ex: (222)-222-2222'
      }
    },
    inputComponent: TextMaskCustom as any,
  },
  {
    type: CustomFieldFormType.Mask,
    formControlName: 'workPhone',
    label: 'Work phone',
    placeholder: 'Ex: (222)-222-2222',
    help: '',
    cssClassName: 'col-lg-3 p-2',
    validators: {
      minLength:{
        value: 14,
        message: 'Please fill the number Ex: (222)-222-2222'
      }
    },
    inputComponent: TextMaskCustom as any,
  },
  {
    type: CustomFieldFormType.Mask,
    formControlName: 'faxPhone',
    label: 'Fax phone',
    placeholder: 'Ex: (222)-222-2222',
    help: '',
    cssClassName: 'col-lg-3 p-2',
    validators: {
      minLength:{
        value: 14,
        message: 'Please fill the number Ex: (222)-222-2222'
      }
    },
    inputComponent: TextMaskCustom as any,
  },
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'emailSection',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <p className="text-left">
      <span className="font-weight-bold">Email</span>
    </p>,
    cssClassName: 'col-lg-12'
  },
  {
    type: CustomFieldFormType.Email,
    formControlName: 'email',
    label: 'Email',
    placeholder: 'Ex: example@domain.com',
    help: '',
    validators: {
      required: 'Field email is required'
    },
    cssClassName: 'col-lg-6 p-2'
  },
  {
    type: CustomFieldFormType.Email,
    formControlName: 'emailAlternative',
    label: 'Alternative Email',
    placeholder: 'Ex: example@domain.com',
    help: '',
    cssClassName: 'col-lg-6 p-2'
  },
];
export default function ContactInformationForm(props: ContactInformationProps) {
  const [fields, useFormObject] = useCustomHookForm<ContactInformationResponseForm, string | number>({
    customFieldsArray: contactInformationFieldsArray,
    initialValues: {
      homePhone: '',
      cellPhone: '',
      workPhone:'',
      faxPhone:'',
      email:'',
      alternativeEmail:'',
    },
  });
  const {handleSubmit, formState: {errors}} = useFormObject;
  const onSubmit = props.callbackContactInformation;
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit) as never}>

        <div className="row">
          {fields}
        </div>

        <div className="">
          <Button size="small"
                  disabled={Object.keys(errors).length > 0}
                  type="submit">
            Continue
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft/>
            ) : (
              <KeyboardArrowRight/>
            )}
          </Button>
        </div>
      </form>
    </>
  )
}
