import useCustomHookForm from "../../Hooks/forms/use-custom-hook-form";
import {
  CustomFieldFormType
} from "../../Hooks/forms/enums/custom-field-form-type";
import React from "react";
import { Button } from "@mui/material";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import theme from "../../styles/theme";
import { Suffix } from "./suffix";
import { UsStates } from "./us-states";
import { Countries } from "./countries";
import dayjs from "dayjs";
import { CustomField } from "../../Hooks/forms/type/custom-field";
import { name } from "../../Utilities/GeneralRegEx";

export interface PersonalInformationResponseForm {
  title: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  nickname: string;
  dateOfBirth: undefined | dayjs.Dayjs,
  gender: string;
  cityOfBirth: string;
  stateOfBirth: string;
  countryOfBirth: string;
}

export interface PersonalInformationProps {
  callbackPersonalInformation: (response: PersonalInformationResponseForm) => void
}
export const PersonalInformationFieldsArray: CustomField<any>[] = [
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'personalInfoNameSection',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <h3 className="text-left">Current Personal Information</h3>,
    cssClassName: 'col-lg-12'
  },
  {
    type: CustomFieldFormType.Select,
    formControlName: 'title',
    label: 'Title',
    placeholder: 'Ex: Ms',
    help: '',
    select: {
      options: [
        {
          id: 'Mr',
          label: 'Mr',
          value: 'Mr'
        },
        {
          id: 'Ms',
          label: 'Ms',
          value: 'Ms'
        },
        {
          id: 'Miss',
          label: 'Miss',
          value: 'Miss'
        },
        {
          id: 'Mrs',
          label: 'Mrs',
          value: 'Mrs'
        },
        {
          id: 'Dr',
          label: 'Dr',
          value: 'Dr'
        },
        {
          id: 'Hon',
          label: 'Hon',
          value: 'Hon'
        }
      ],
      multiple: false
    },
    cssClassName: 'col-lg-4 p-2',
  },
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'personalInfoTitleSpace',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <></>,
    cssClassName: 'col-lg-8'
  },
  {
    type: CustomFieldFormType.Text,
    formControlName: 'firstName',
    label: 'First Name',
    placeholder: 'Ex: John',
    help: '',
    validators: {
      required: 'First Name should be valid',
      pattern: {
        pattern: name,
        message: 'First name should be valid'
      },
    },
    cssClassName: 'col-lg-4 p-2'
  },
  {
    type: CustomFieldFormType.Text,
    formControlName: 'middleName',
    label: 'Middle Name',
    placeholder: 'Ex: Erick',
    help: '',
    validators: {
      pattern: {
        pattern: name,
        message: 'Middle name should be valid'
      },
    },
    cssClassName: 'col-lg-4 p-2'
  },
  {
    type: CustomFieldFormType.Text,
    formControlName: 'lastName',
    label: 'Last Name',
    placeholder: 'Ex: Doe',
    help: '',
    validators: {
      required: 'Last Name should be valid',
      pattern: {
        pattern: name,
        message: 'Last name should be valid'
      },
    },
    cssClassName: 'col-lg-4 p-2'
  },
  {
    type: CustomFieldFormType.Select,
    formControlName: 'suffix',
    label: 'Suffix',
    placeholder: 'Ex: Jr/Sr/I/II/III',
    help: '',
    select: {
      options: Suffix,
      multiple: false
    },
    cssClassName: 'col-lg-4 p-2'
  },
  {
    type: CustomFieldFormType.Text,
    formControlName: 'nickname',
    label: 'Nickname',
    placeholder: 'Ex: Jdoe',
    help: '',
    cssClassName: 'col-lg-4 p-2'
  },
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'personalInfoSuffixNicknameSpace',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <></>,
    cssClassName: 'col-lg-4'
  },
  {
    type: CustomFieldFormType.Date,
    formControlName: 'dateOfBirth',
    label: 'Date of Birth',
    placeholder: 'Ex: mm/dd/yyyy',
    help: '',
    cssClassName: 'col-lg-4 p-2',
    validators: {
      required: 'Date of birth should be valid',
    },
    overrideFields: {
      date: {
        minDate: dayjs('1900-01-01'),
        errorMessage: 'Please fill a valid date of birth'
      }
    }
  },
  {
    type: CustomFieldFormType.Select,
    formControlName: 'gender',
    label: 'Gender',
    placeholder: 'Ex: Male/Female',
    help: '',
    select: {
      options: [
        {
          id: 'Male',
          label: 'Male',
          value: 'Male'
        },
        {
          id: 'Female',
          label: 'Female',
          value: 'Female'
        },
      ],
      multiple: false
    },
    validators: {
      required: 'Gender should be valid'
    },
    cssClassName: 'col-lg-4 p-2'
  },
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'personalInfoDateGenderSpace',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <></>,
    cssClassName: 'col-lg-4'
  },
  {
    type: CustomFieldFormType.Text,
    formControlName: 'cityOfBirth',
    label: 'City of Birth',
    placeholder: 'Ex: City',
    help: '',
    cssClassName: 'col-lg-4 p-2'
  },
  {
    type: CustomFieldFormType.Select,
    formControlName: 'stateOfBirth',
    label: 'State of Birth',
    placeholder: 'Ex: Florida',
    help: '',
    select: {
      options: UsStates(),
      multiple: false
    },
    cssClassName: 'col-lg-4 p-2'
  },
  {
    type: CustomFieldFormType.Select,
    formControlName: 'countryOfBirth',
    label: 'Country of Birth',
    placeholder: 'Ex: United States of America',
    help: '',
    select: {
      options: Countries,
      multiple: false
    },
    validators: {
      required: 'Please select Country of Birth'
    },
    cssClassName: 'col-lg-4 p-2'
  },
];
export default function PersonalInformationForm(props: PersonalInformationProps) {
  const [fields, useFormObject] = useCustomHookForm<PersonalInformationResponseForm, string | number>({
    customFieldsArray: PersonalInformationFieldsArray,
    initialValues: {
      title: '',
      firstName: '',
      lastName: '',
      middleName: '',
      suffix: '',
      nickname: '',
      dateOfBirth: undefined,
      gender: '',
      cityOfBirth: '',
      countryOfBirth: '',
      stateOfBirth: '',
    },
  });
  const { handleSubmit, formState: { errors } } = useFormObject;
  const onSubmit = props.callbackPersonalInformation;
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit) as never}>

        <div className="row">
          {fields}
        </div>

        <div className="">
          <Button size="small"
            disabled={Object.keys(errors).length > 0}
            type="submit">
            Continue
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        </div>
      </form>
    </>
  )
}
