import React, { useEffect, useState } from 'react';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import CancelIcon from '@material-ui/icons/Cancel';
import withContext from '../../ContextAPI/Context_HOC';
import FeatureKey from '../../FeatureFlag/FeatureKey';
import { withOptimizely, WithOptimizelyProps } from '@optimizely/react-sdk';
export interface SpeechSynthesisProps {
  text: string;
  mockSpeechSynthesisUtterance?: any;
  context: any;
  featureFlagMockEnabled?: boolean;
}
export function SpeechSynthesis(props: SpeechSynthesisProps & WithOptimizelyProps) {
  const { featureFlagMockEnabled } = props;
  const textSize = 200;
  const [currentTextPosition] = useState(0);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [featureFlagIsEnabled, setFeatureFlagIsEnabled] = useState(featureFlagMockEnabled ?? false);
  const [isPaused, setIsPaused] = useState(false);
  const [msg] = useState<SpeechSynthesisUtterance>(
    props.mockSpeechSynthesisUtterance ? new props.mockSpeechSynthesisUtterance() : new SpeechSynthesisUtterance()
  );
  const [voices, setVoices] = useState<SpeechSynthesisVoice[]>([]);
  const [voiceSelected, setVoiceSelected] = useState<SpeechSynthesisVoice>();
  const [text] = useState(props.text);
  const handleNewSlice = (currentLocalTextPosition: number) => {
    const localMsg = props.mockSpeechSynthesisUtterance
      ? new props.mockSpeechSynthesisUtterance()
      : new SpeechSynthesisUtterance();
    if (voiceSelected) {
      localMsg.voice = voiceSelected;
    }
    if (currentLocalTextPosition < text.length - 1) {
      const sliceLenght = currentLocalTextPosition + textSize;
      const sliceLenghtIsLarger = sliceLenght > text.length - 1;
      let slice = '';
      if (sliceLenghtIsLarger) {
        slice = text.slice(currentLocalTextPosition, text.length - 1);
      } else {
        slice = text.slice(currentLocalTextPosition, currentLocalTextPosition + textSize);
      }
      const match = slice.match(/,|\.|;/gi);
      let indexOfLastSpace = 0;
      if (match) {
        indexOfLastSpace = slice.lastIndexOf(match[match.length - 1]);
      } else {
        indexOfLastSpace = slice.lastIndexOf(' ');
      }
      let current = 0;
      if (indexOfLastSpace >= 0) {
        const sliceLength = currentLocalTextPosition + indexOfLastSpace;
        localMsg.text = text.slice(currentLocalTextPosition, sliceLength);
        if (sliceLength + textSize < text.length - 1) {
          current = sliceLength;
        } else {
          localMsg.text = text.slice(currentLocalTextPosition, text.length - 1);
          current = text.length - 1;
        }
      } else {
        localMsg.text = text.slice(currentLocalTextPosition, text.length - 1);
      }
      setIsSpeaking(true);
      window.speechSynthesis.speak(localMsg);
      localMsg.addEventListener('end', () => {
        handleNewSlice(current);
      });
    } else {
      localMsg.text = text.slice(currentLocalTextPosition, text.length - 1);
      window.speechSynthesis.speak(localMsg);
      localMsg.addEventListener('end', () => {
        setIsPaused(false);
        setIsSpeaking(false);
      });
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      const windowVoices = window.speechSynthesis.getVoices();
      if (windowVoices.length > 0) {
        const filteredVoices = windowVoices.filter((a) => a.lang.includes('en'));
        setVoiceSelected(filteredVoices[0]);
        setVoices(filteredVoices);
        clearInterval(interval);
      }
    }, 1000);
    const featureFlagValidator = async () => {
      try {
        if (props?.context?.featureFlagIsEnabledFunction) {
          const isEnabled = await props.context.featureFlagIsEnabledFunction(FeatureKey.SpeechSynthesIsEnabled, props.optimizely);
          setFeatureFlagIsEnabled(isEnabled);
        }
      } catch (e) {
        console.info('Not enabled');
      }
    };
    featureFlagValidator();
    return () => {
      window.speechSynthesis.cancel();
    };
  }, []);
  useEffect(() => {
    if (voiceSelected) {
      msg.voice = voiceSelected;
      window.speechSynthesis.cancel();
      setIsPaused(false);
      setIsSpeaking(false);
    }
  }, [voiceSelected]);
  if (!voiceSelected) {
    return null;
  }

  if (!featureFlagIsEnabled) {
    return null;
  }
  return (
    <div
      style={{
        border: '1px solid black',
        maxWidth: '250px',
        padding: '3px',
        borderRadius: '6px',
      }}
    >
      <span data-cy={'icon-speak'}></span>🗣️{' '}
      <button
        data-cy={'play-pause-button'}
        className={'primary-button p-0 '}
        onClick={(e) => {
          e.preventDefault();
          if (isPaused) {
            window.speechSynthesis.resume();
            setIsPaused(false);
            setIsSpeaking(true);
          } else {
            if (isSpeaking) {
              window.speechSynthesis.pause();
              setIsSpeaking(false);
              setIsPaused(true);
            } else {
              if (text.length <= textSize) {
                msg.text = text.slice(0, text.length - 1);
                window.speechSynthesis.speak(msg);
                setIsSpeaking(true);
              } else {
                handleNewSlice(currentTextPosition);
              }
            }
          }
        }}
      >
        {isSpeaking ? <PauseIcon /> : <PlayArrowIcon />}
      </button>
      <button
        data-cy={'stop-button'}
        className={'primary-button p-0 ml-1'}
        onClick={(e) => {
          e.preventDefault();
          window.speechSynthesis.cancel();
          setIsSpeaking(false);
          setIsPaused(false);
        }}
      >
        <CancelIcon />
      </button>
      <select
        className={'primary-button p-0 ml-1 pt-1 pb-1'}
        data-cy={'select-button'}
        style={{ fontSize: '0.7em', maxWidth: '150px' }}
        value={voiceSelected?.name}
        onChange={(event) => {
          const windowVoices = window.speechSynthesis.getVoices();
          setVoiceSelected(windowVoices.find((a) => a.name === event.target.value));
        }}
      >
        {voices.map((voice) => {
          return (
            <option key={voice.name} value={voice.name}>
              {voice.name}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default withOptimizely(withContext(SpeechSynthesis));
