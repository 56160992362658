import React from 'react';
import { NavLink } from 'react-router-dom';

const EachPrayerList = (props) => {
  return (
    <div>
      <h1 data-cy="title">{props.prayertype}</h1>
      <ul className="plain-list">
        {Object.keys(props.prayerdata).map((i, key) => {
          let urllevel = '';
          for (var j = 0; j < props.prayerdata[i].prayer_category.value.length; j++) {
            urllevel = urllevel.concat('/', props.prayerdata[i].prayer_category.value[j].codename);
          }
          return (
            <li key={key} data-cy="prayerlist">
              <NavLink
                data-cy={`prayerlink${key}`}
                to={{
                  pathname: `/prayer${urllevel}/${props.prayerdata[i].url_slug.value}`,
                }}
              >
                {props.prayerdata[i].base__title.value}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default EachPrayerList;
