import ContentProviderEndpoint from '../../Content/ContentProviderEndpoint';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HomeCarousel } from '../../Kentico/DigitalDesign/home_carousel';

export default class HomeCarouselProxy {
  digitalDesignContent: ContentProviderEndpoint;
  homeCarouselsSubject: ReplaySubject<HomeCarousel[]>;
  getHomeCarouselsWasCalled: boolean;

  constructor(digitalDesignContent: ContentProviderEndpoint) {
    this.digitalDesignContent = digitalDesignContent;
    this.homeCarouselsSubject = new ReplaySubject<HomeCarousel[]>(1);
    this.getHomeCarouselsWasCalled = false;
  }

  getHomeCarousels(): Observable<HomeCarousel[]> {
    if (!this.getHomeCarouselsWasCalled) {
      this.getHomeCarouselsWasCalled = true;
      this.digitalDesignContent
        .items<HomeCarousel>()
        .type('home_carousel')
        .orderByAscending('elements.order')
        .limitParameter(19)
        .toObservable()
        .pipe(map((x) => x.items))
        .subscribe(this.homeCarouselsSubject);
    }

    return this.homeCarouselsSubject.asObservable();
  }
}
