import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import RetirementIcon from '../../assets/img/PlannedGiving/Retirement.png';

function Retirement() {
  return (
    <Row>
      <Col sm={1}>
        <img src={RetirementIcon} alt="Retirement" className="fund-logo" />
      </Col>
      <Col sm={11}>
        <h1>Individual Retirement Account (IRA) Charitable Rollover</h1>
        <p>
          The IRA Charitable Rollover allows believers <u>age 70 1/2</u> and older to make a <u>direct</u> contribution from their
          IRA to the National Spiritual Assembly as a non-taxable event.
        </p>
        <ul className="plain-list">
          <li>
            <strong>Download: </strong>
            <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Planned-Giving/IRA-Charitable-Rollover-Updated.pdf">
              IRA Charitable Rollover
            </a>
          </li>
          <li>
            <strong>Download: </strong>
            <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Planned-Giving/IRA-Rollover-Instructions.pdf">
              Information to Make a Direct Transfer of IRA Charitable Rollover (Age 70 ½ and older)
            </a>
          </li>
        </ul>
      </Col>
    </Row>
  );
}

export default Retirement;
