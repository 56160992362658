import { map } from 'rxjs/operators';
import AuthService from '../../Login/AuthService';
import { CommunityDirectoryModelEnriched } from '../Models/CommunityDirectoryModelEnriched';
import { Observable } from 'rxjs';
import { CommunityDirectoryModel } from '@usbnc/data-api-proxies';

export default class AssemblyGroupsEndpoint {
  private authService: AuthService;
  private apiCaller: any;
  private apiUrl: string | undefined;

  constructor(authService: AuthService, apiCaller: any, apiUrl: string | undefined) {
    this.authService = authService;
    this.apiCaller = apiCaller;
    this.apiUrl = apiUrl;
    this.getassemblygroups = this.getassemblygroups.bind(this);
  }

  getassemblygroups(token: string | null): Observable<CommunityDirectoryModelEnriched[]> {
    const observable = this.apiCaller
      .getObservable(process.env.REACT_APP_API_URL + '/api/CommunityDirectory', {
        headers: { Authorization: 'bearer ' + token },
      })

      .pipe(
        map((x: { data: CommunityDirectoryModel[] }) =>
          x.data.map<CommunityDirectoryModelEnriched>((x) => {
            const clusterName = x.cluster ? x.cluster.regionalCode : 'Unknown';
            const stateName = x.address ? x.address.state : '';
            return { ...x, clusterName, stateName };
          })
        )
      );

    return observable;
  }
}
