import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import withContext from '../ContextAPI/Context_HOC';

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkdisplay: [],
      allMessages: [],
      feastmessages: [],
      feasturlname: [],
    };
  }

  componentDidMount() {
    let datestring = '';
    let taxonomyterm = '';
    this.subscription = this.props.context.digitalDesignContent
      .items()
      .type('cover_letter')
      .orderParameter('elements.base__datepublished', 'desc')
      .limitParameter(30)
      .toObservable()
      .subscribe(async (response) => {
        if (!response.isEmpty) {
          let links = [];
          response.items.forEach((x, key) => {
            datestring = x.base__datepublished.rawData.value.substring(0, 4);
            taxonomyterm = x.cover_letter.value[0].codename;
            links.push(
              <li key={key}>
                <Link
                  data-cy={`coverletterlink${key}`}
                  to={{
                    pathname: `/guidance/${taxonomyterm}/${x.url_slug.value}-${datestring}`,
                  }}
                >
                  {new Date(x.base__datepublished.value).toLocaleDateString('en-US', this.props.context.dateOptions)} -{' '}
                  {x.base__title.value}
                </Link>
              </li>
            );
          });
          this.setState({ linkdisplay: links });
        }
      });
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }
  render() {
    return (
      <div data-cy="mainMessagesdisplaylistlink">
        <h1>Most Recent Messages</h1>
        <p>
          You may access previous messages from the <Link to="/guidance/uhj">Universal House of Justice</Link> and the{' '}
          <Link to="/guidance/national_spiritual_assembly">National Spiritual Assembly</Link>.
        </p>
        <br />
        <ul> {this.state.linkdisplay}</ul>
      </div>
    );
  }
}

export default withContext(Messages);
