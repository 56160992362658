import React, { FunctionComponent } from 'react';
import FeatureFlagChooser from '../FeatureFlag/FeatureFlagChooser';
import FeatureKey from '../FeatureFlag/FeatureKey';
import Thermometer from './Thermometer';
import withContext from '../ContextAPI/Context_HOC';
import { Observable, ReplaySubject } from 'rxjs';
import type FundStatus from './FundStatus';

const readySubject = new ReplaySubject<boolean>(1);
const readyObservable = readySubject.asObservable();

const ThermometerChooser: FunctionComponent<{
  context: {
    fundProxy: Observable<FundStatus>;
    fundService: Observable<FundStatus>;
  };
}> = ({ context: { fundProxy, fundService } }) => {
  return (
    <FeatureFlagChooser featureKey={FeatureKey.GetFundStatusFromOCS} readySubject={readySubject}>
      <Thermometer fundStatusProvider={fundProxy} readyObservable={readyObservable} />
      <Thermometer fundStatusProvider={fundService} readyObservable={readyObservable} />
    </FeatureFlagChooser>
  );
};

export default withContext(ThermometerChooser);
