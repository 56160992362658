import React from 'react';
import PropTypes from 'prop-types';
import withContext from '../ContextAPI/Context_HOC';
import NoMatch from '../Routers/NoMatch';
const WritingComponent = (props) => {
  if (props.details === '') return <NoMatch />;
  return (
    <div>
      <h1 data-cy="title" className="title">
        {props.details.base__title.value}
      </h1>
      <p data-cy="author">by {props.details.author.value}</p>
      <hr />
      <div data-cy="body-text" dangerouslySetInnerHTML={{ __html: props.details.base__body.value }} />
      <p data-cy="copyright">{props.details.copyright.text}</p>
    </div>
  );
};

export default withContext(WritingComponent);
WritingComponent.propTypes = {
  props: PropTypes.shape({
    details: PropTypes.shape({
      author: PropTypes.shape({ text: PropTypes.string }),
      base__title: PropTypes.shape({ value: PropTypes.string }),
      base__body: PropTypes.shape({ value: PropTypes.string }),
      copyright: PropTypes.shape({ text: PropTypes.string }),
    }),
  }),
};
