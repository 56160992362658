export default function SortPositiveIdThenSequence(a: any, b: any) {
  if (a.id < 0 && b.id > 0) {
    return 1;
  }

  if (a.displaySequence === b.displaySequence) {
    return 0;
  }

  return a.displaySequence > b.displaySequence ? 1 : -1;
}
