import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import FlickrGallery from './FlickrGallery';

class NatCon2019 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenId: null,
    };
  }
  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }
  setHiddenId(id) {
    this.setState({
      hiddenId: id,
    });
  }
  render() {
    return (
      <div>
        <FlickrGallery apiKey="13d8e319087a91a92dc7d59d1b44490b" setId="72157682557186175" />
        <div>
          <h1>National Convention 2017</h1>
          <p>
            Photos from the Convention available at{' '}
            <a
              href="https://www.flickr.com/photos/bahaius/collections/72157681276677070/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Flickr
            </a>
            .
          </p>
          <p>
            Below are stories on the Convention from <em>The American Bahá’í</em>
          </p>
        </div>
        <hr />
        <Row>
          <Col sm={12} md={8}>
            <img alt="Voting" src="https://c1.staticflickr.com/3/2814/34186976262_ca204e0d28_q.jpg" />
            <h4 className="small-top-padding">National Spiritual Assembly for 2017–2018 elected</h4>
            <p>
              On the third day of the 109th U.S. Bahá’í National Convention, April 29, 2017, delegates elected nine members to
              serve on the National Spiritual Assembly of the Bahá’ís of the United States until the National Convention of 2018.
              All 171 delegates cast ballots, 169 of them in person. As announced during the early afternoon session of the
              Convention, members elected are (with names in alphabetical order):
            </p>
            <ul className="plain-list">
              <li>Muin Afnani</li>
              <li>Fariba Aghdasi</li>
              <li>Kenneth E. Bowers</li>
              <li>Juana C. Conrad</li>
              <li>S. Valerie Dana</li>
              <li>Robert C. Henderson</li>
              <li>Jacqueline Left Hand Bull</li>
              <li>Kevin Trotter</li>
              <li>David F. Young</li>
            </ul>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  The election took place Saturday morning after half an hour’s devotions in the sanctified auditorium of the
                  Bahá’í House of Worship. Delegates took their place downstairs in Foundation Hall, with guests witnessing from
                  the back rows of seats and from a viewing room with a video feed.
                </p>
                <p>
                  “We will accompany each other in carrying out our sacred task and we will do that by maintaining the prayerful
                  atmosphere we just left,” said the Convention chair, David Young, before ensuring that every delegate in
                  attendance had a ballot. The chair then read instructions for conducting the balloting with order and dignity.
                </p>
                <p>
                  The team of tellers read prayers and selections relevant to the sanctity and nature of Bahá’í elections —
                  including the oft-cited mandate of the Guardian to choose “those who can best combine the necessary qualities of
                  unquestioned loyalty, of selfless devotion, of a well-trained mind, of recognized ability and mature
                  experience.”
                </p>
                <p>
                  Several minutes’ silence followed, except for the muted rustling of papers, as each delegate filled the ballot
                  with nine names.
                </p>
                <p>
                  When ballots were completed, the Convention secretary, Muin Afnani, called the roll, and the delegates advanced
                  in alphabetical order to drop their ballots into the clear plastic box at the front of the hall. More than 30
                  delegates were exercising that duty for the first time.
                </p>
                <p>
                  Many faces were stern and purposeful; others beaming and transcendent; others celebratory. A few kissed their
                  envelopes just before dropping them in the box. On behalf of the two delegates voting by mail, the chief teller
                  dropped in each ballot.
                </p>
                <p>
                  When 171 ballots had been cast, the secretary announced, “Congratulations, friends, we have 100 percent
                  participation.” The entire room rose in a warm standing ovation. A delegate began singing a simple setting of a
                  passage from Bahá’u’lláh — “Arise for the triumph of My Cause, and, through the power of thine utterance, subdue
                  the hearts of men” and others gradually joined her.
                </p>
              </div>
            )}
            <hr />
            <img alt="Counselor speaking" src="https://c1.staticflickr.com/5/4180/34024967070_dd0c0f7d9e_q.jpg" />
            <h4 className="small-top-padding">
              Counselors end Convention with perspectives on seeing truth and seeing potential
            </h4>
            <p>
              Seek truth, speak truth, hear truth, and act on it. Really believe that every human being on the planet has a part
              to play in the advancement of human civilization, and act on it.
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  During the closing session of the 109th Bahá’í National Convention on April 30, members of the Continental Board
                  of Counselors urged delegates and guests — and by extension the entire national Bahá’í community — to consider
                  these principles.
                </p>
                <p>
                  <strong>A spectrum of things that are true</strong>
                </p>
                <p>
                  Counselor Sonlla Heern reflected that part of delegates’ preparation during the pre-Convention deepening was the
                  observation that “the heart of consultation is the seeking of truth, and striving to speak truth, and to hear
                  truth when it’s spoken to us” — a stark contrast to much of the discourse in what some media experts have called
                  a “post-truth era.”
                </p>
                <p>
                  Over the Convention’s four days, Heern said, consultation and reports revealed some matter-of-fact truths: that
                  our national community has a little more than 77,000 Bahá’ís with known addresses; that some 15,000 friends of
                  the Faith are engaged in community-building processes nationwide; that the annual National Fund goal was
                  exceeded; that neighborhood teaching efforts are active in hundreds of clusters.
                </p>
                <p>
                  She also drew some qualitative truths: that the message of Bahá’u’lláh is being brought deep into some
                  neighborhoods; that our community is “collectively enhancing its capacity” to accompany young people in service,
                  to share the Faith in urban and rural settings, to engage in various forms of discourse and social action as
                  appropriate, and to prepare to celebrate the bicentenary of the Birth of Bahá’u’lláh in “creative and befitting
                  ways.”
                </p>
                <p>
                  “The Bahá’í community is learning how to learn. This is a truth that we can state with certainty.” But these are
                  only parts of the larger truth, she said, and other things that rang true to her are that much of humanity is
                  suffering under the yoke of oppression, and that our Bahá’í communities themselves “are not perfectly nurturing
                  and are not perfectly free from prejudice and oppressive attitudes.” This is placed in perspective, she said, by
                  Bahá’u’lláh’s statement that the most grievous oppression is “that a soul seeking the truth, and wishing to
                  attain unto the knowledge of God, should know not where to go for it and from whom to seek it.”
                </p>
                <p>
                  The Riḍván letter of the Universal House of Justice also helps us “step back” from the small truths we encounter
                  in our own lives and take a larger view. Yes, that letter acknowledges, souls are caught in “confusion,
                  distrust, and cloudiness,” and, in Shoghi Effendi’s words, in “vain disputes, … petty calculations, … transient
                  passions.” It is also true, that letter states, that “Every contribution Bahá’ís make to the life of their
                  society is aimed at fostering unity”; that centers of intense community-building activity are “proving to be
                  fertile ground for collective transformation”; that “the swift progress being witnessed in some places is yet to
                  appear in others,” and yet the number of intensive programs of growth and participation in the activities of the
                  Plan are both on the increase.
                </p>
                <p>
                  From her own life, Heern shared a few stories of the purest truths coming from the mouths of children, whose
                  “vision has yet to be clouded by untruth.” One of the most striking was about a child for whom “kindergarten was
                  a little daunting” and who was having troubles with the social environment. Asked what he thought he could do
                  about it, he thought for a second, took a breath, and said, “So powerful is the light of unity that it can
                  illuminate the whole earth.” “It dawned on him what he needed to do,” Heern said, and she exhorted us all to
                  make than kind of effort to apply the essential truths in the Writings and guidance. “Do your small part to
                  spread that truth throughout the world, and to encourage others to do so.”
                </p>
                <p>
                  <strong>Looking at everyone as a protagonist</strong>
                </p>
                <p>
                  As delegates prepared to fan back out to all parts of the country, Counselor Nwandi Lawson exhorted them to
                  encourage a way of viewing “everyone we meet, regardless … of any social context”: that “This person is actually
                  a protagonist who has the right and the responsibility to contribute to an ever-advancing civilization.”
                </p>
                <p>
                  To the extent Bahá’ís fail to envision people they encounter as protagonists, most likely those people’s
                  involvement will begin and end as spectators. “Really, everyone should be in the scene,” she said, as the
                  Universal House of Justice has pointed out that “every person on the planet has some role to play.”
                </p>
                <p>
                  Our planning for bicentennial observations of the Birth of Bahá’u’lláh are a case in point. How can a local
                  event “empower an individual who participates to leave the experience as a protagonist?” Questions we can ask
                  ourselves on that line include: How can we build relationships with people we may never see again? How can such
                  a Holy Day event help a guest “begin to discern a path of service?” Can people be invited to join us in reading,
                  in singing, in helping children, in contributing some other way?
                </p>
                <p>
                  One effective method she observed at the opening of the House of Worship in Chile last fall was giving each
                  participant a few small prayer books and the encouragement to share them with people before they left. Even if
                  they only knew one or two lines of Spanish, those participants knew “they have a voice, … they have some mission
                  upon leaving.”
                </p>
                <p>
                  Still, after such a first contact, the process has to continue. When people are receptive, they need to know of
                  opportunities not only for spiritual education of children, moral empowerment of teens, building the devotional
                  character of the community and so forth — but also to be encouraged to “envision themselves as contributing.”
                </p>
                <p>
                  She turned to a story of growth in a cluster that had been mentioned earlier in the Convention. A swift increase
                  in activity had been precipitated by homefront pioneers thinking of young people they encountered as potential
                  leaders in the community-building process — then going forward and starting conversations with them, saying, “We
                  need you.” Along the way they were connected with people who accompanied them in building their capacities,
                  encouraged to mentor junior youth and children, and eventually empowered to accompany others who in turn brought
                  many others into the process.
                </p>
                <p>
                  “At any of these points the story can end if you take the protagonist out of the scene,” she said. “If no one
                  ever invites them in they could be spectators.” To let our hearts “burn with loving kindness” for all we meet,
                  as ‘Abdu’l-Bahá encouraged, “is a wonderful first step.” The next step, though, is to let them know “we also
                  wish to see them advance,” helping our friends and acquaintances see their value to this process for themselves,
                  and how they can contribute.
                </p>
              </div>
            )}
            <hr />
            <img alt="Van Gilmer" src="https://c1.staticflickr.com/3/2864/34363052325_52e6226eea_q.jpg" />
            <h4 className="small-top-padding">Consultation reflects appreciation of the bicentenary as a process</h4>
            <p>
              Delegates to the 109th U.S. Bahá’í National Convention were of one mind with many voices as they consulted Sunday,
              April 30, on preparations for the bicentenary of the Birth of Bahá’u’lláh. Echoing the words sung by Van Gilmer at
              the session’s start, delegates in effect asked, <em>“What tongue can voice my thanks, voice my thanks to Thee?”</em>
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  Speaker after speaker told of a commitment where they live to ensure that the bicentenary celebration is not
                  only meaningful to everyone who attends but part of a process before, during and after to acquaint people of all
                  ages and backgrounds with the life and message of the Blessed Beauty and with activities in which they can
                  engage to build community.
                </p>
                <p>
                  Imaginative approaches in clusters, communities and neighborhoods were shared. And delegates signaled they
                  recognize the importance of planning how to follow up with those whose curiosity and wonder will be sparked by
                  the bicentenary.
                </p>
                <ul>
                  <li>
                    <em>Texas:</em> An individual’s reading of reality determined that most neighbors, friends and co-workers
                    would not attend a large centralized celebration, so she will be opening her home for people to come to
                    someplace they’ll be comfortable.
                  </li>
                  <li>
                    <em>Illinois:</em> Participation in discussions surrounding a film on race unity generated a list of about 500
                    people who will be invited to a celebration that will feature original music and the lighting of an
                    observatory dome.
                  </li>
                  <li>
                    <em>California:</em> The bicentenary will be pushed as far into San Diego neighborhoods as possible, with a
                    large-scale event at a museum that can hold up to 1,000 people and smaller celebrations in the approximately
                    150 core activities.
                  </li>
                  <li>
                    <em>South Carolina:</em> A Local Spiritual Assembly consultation on helping Bahá’ís become more comfortable in
                    talking about Bahá’u’lláh and the bicentenary led to devotional gatherings being used to practice stories.
                  </li>
                  <li>
                    <em>Washington:</em> A planned 200 home visits in the coming months will help focus all core activities on the
                    bicentenary and its tandem approach of an interactive child-friendly event with activity booths and a more
                    formal celebration.
                  </li>
                  <li>
                    <em>Florida:</em> It’s “all about yes” in a cluster that plans to “celebrate now, celebrate then, celebrate
                    later” with picnics, dinners and other celebratory events.
                  </li>
                  <li>
                    <em>Arizona:</em> Concern was raised that a cluster’s plans do not involve children and junior youths and that
                    will reflect badly on the community in the eyes of potential attendees.
                  </li>
                  <li>
                    <em>Florida:</em> A unity committee created after two people brought the idea to Local Assemblies will be
                    pulling human and financial resources together and, between now and the bicentenary, enhancing the quality and
                    reach of every type of involvement in the life of society.
                  </li>
                  <li>
                    <em>California:</em> A cemetery’s plan to conduct tours of the gravesites of well-known people has given the
                    Bahá’ís the idea of holding reverent observances where believers are buried.
                  </li>
                  <li>
                    <em>Colorado:</em> Addressing the concern previously voiced about children and junior youths, an idea is to
                    interview young people about what the bicentenary means to them, make a video of that and share it.
                  </li>
                  <li>
                    <em>Maryland:</em> A recent conversation with a returning pilgrim brought home how the cycles leading to the
                    two bicentenaries are springboards for activities in the cycles following them, with invitees needing to feel
                    they agents of personal and societal transformation and involved in that process.
                  </li>
                  <li>
                    <em>Texas:</em> Sessions surrounding the dedication of the Bahá’í House of Worship in Chile provide an example
                    of how celebratory events and the community-building process can blend harmoniously, with ample space devoted
                    to reflection and artistic expression of that sharing.
                  </li>
                  <li>
                    <em>California:</em> Planning meetings spent whittling down a large number of ideas led participants to the
                    realization that the celebration need not be compressed into one weekend but stretched over two years.
                  </li>
                  <li>
                    <em>Tennessee:</em> Reasoning that a birthday should have presents, believers and their friends are lending
                    their talents in such areas as crochet and quilt making and are creating opportunities for children to make
                    gifts.
                  </li>
                  <li>
                    <em>Wisconsin:</em> A heightened spirit and collaboration surrounding the bicentenary has resulted in greater
                    enthusiasm for planting roots deep in the cluster by reaching out to residents of focus neighborhoods.
                  </li>
                  <li>
                    <em>Virginia:</em> At all of a community’s Feasts, a portion will be devoted to a refresher on Ruhi Book 4,
                    The Twin Manifestations, focused on reading stories about Bahá’u’lláh in anticipation of opportunities to
                    share those stories with acquaintances in person and through social media.
                  </li>
                  <li>
                    <em>Arizona:</em> Integrating worship and service is the aim in communities surrounding the Native American
                    Bahá’í Institute so capacity can be built to share the lives of the Twin Luminaries in words and songs adapted
                    to residents’ cultural and religious backgrounds.
                  </li>
                  <li>
                    <em>Maryland:</em> A series of firesides on Bahá’u’lláh’s vision of the oneness of humanity is an outcome of
                    studying the Feb. 25 letter “America and the Five Year Plan” from the National Spiritual Assembly.
                  </li>
                  <li>
                    <em>Illinois:</em> To help believers think about how they can make the bicentenary celebratory and
                    artistically expressive, a reflection gathering had a birthday party theme in which participants made origami
                    gift boxes.
                  </li>
                  <li>
                    <em>Colorado:</em> Telling fellow airplane passengers about the bicentenary is an example of how chance
                    acquaintances also can be invited.
                  </li>
                  <li>
                    <em>South Carolina:</em> Cultivating the ability to speak in “sound bites” of a sentence or two will come in
                    handy as media cover the bicentenary.
                  </li>
                </ul>
              </div>
            )}
            <hr />
            <img alt="Secretary’s report" src="https://c1.staticflickr.com/5/4168/34283129976_771b7bf49c_q.jpg" />
            <h4 className="small-top-padding">Secretary’s report revolves around major letter on America and the Plan</h4>
            <p>
              As the 109th U.S. Bahá´í National Convention convened, the National Spiritual Assembly had already presented the
              nation’s Bahá’ís with a vision of how we are advancing the transformation of all of society, not just our community,
              through labors within the framework of action in the Five Year Plan. That is expressed in the Assembly’s Feb. 25,
              2017, letter to the American Bahá’í community. (That letter is included in the Annual Report — see the link in the
              Resources box — and{' '}
              <a href="https://s3.amazonaws.com/bahai-messages/nsa/2017/20170225_nsa_america-five-year-plan.pdf">
                available to download
              </a>{' '}
              separately).
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  It took months to collect the thoughts and consult on them before that letter was released. “Every single word
                  has been reviewed multiple times by every single member of the National Spiritual Assembly and it does express
                  the heart and intent of our National Spiritual Assembly,” noted David Young, chair of the U.S. Bahá’í National
                  Convention and an Assembly member, on the gathering’s second day. “And as you know it is the first of more to
                  come.”
                </p>
                <p>
                  So the Convention report by the National Assembly’s secretary began with a simple reading of that letter — with
                  its observations on the condition of U.S. society, its reminder that the oneness of humanity is at the center of
                  the Faith’s message and purpose, its expression that “The evils of racism, materialism, and moral decadence will
                  be eradicated only by a love that is translated into action,” and not only the potential of that action but the
                  beneficial effects it is showing. Convention participants greeted the reading with a warm and extended ovation.
                </p>
                <p>
                  Then Secretary Kenneth Bowers shared glimpses into the powerful process that led to that letter with the
                  “beloved friends” who were gathered “to take up the business of advancing the Kingdom of God.”
                </p>
                <p>
                  In recent years, certain incidents of racially motivated violence against African Americans have “caused a great
                  deal of dismay” and prompted questions of “how we need to respond as Bahá’ís,” he said. A Feast letter in summer
                  2016 shared some thoughts, but the National Assembly yearned to generate a “proper response” born of a
                  well-informed “unity of mind.”
                </p>
                <p>
                  So in each monthly meeting, the body set aside time to review conditions in American society, the capacities
                  being developed in the Bahá’í community, and how these things reflect the guidance and elucidations of
                  Bahá’u’lláh, ‘Abdu’l-Bahá, Shoghi Effendi and the Universal House of Justice.
                </p>
                <p>
                  The Assembly’s members sensed, he said, that as far as our community’s development has come in recent years, it
                  was also time to “challenge ourselves as a community fully to understand the totality of the House of Justice’s
                  vision” expressed in its letters to the Bahá’ís of the world: not only the need to build capacity for
                  devotional, educational and teaching activities in our own sphere, but also by stages to engage with the whole
                  world in developing a just and peaceful world.
                </p>
                <p>
                  In particular, it seemed our community had inadvertently created a separation between the activities of recent
                  Five Year Plans and the need to work for unity between races — “as if to suggest that there isn’t a direct
                  connection” between the Faith’s essential spiritual mission and its aim of working for justice.
                </p>
                <p>
                  In November, the Assembly felt the obligation to share its draft with the Universal House of Justice, along with
                  some proposed strategies for follow-up communication. The House’s first response? Pleasure and encouragement.
                </p>
                <p>
                  But as the ideas in the letter deserved “further exploration,” the Assembly was asked to send four of its
                  members to the Bahá’í World Center for consultations as soon as possible. Those consultations, over three days,
                  also involved three members of the House itself, as well as three from the Continental Board of Counselors for
                  the Americas and two Counselors from the International Teaching Center. This gathering, and the time spent on
                  it, was “a token of love [and] generosity” in its own right, Bowers said.
                </p>
                <p>
                  From its analysis of those consultations, the National Assembly reaffirmed its sense that “the United States is
                  entering a period of great importance and ferment right now” — on the brink of a nationwide epoch of spiritual
                  seeking and questioning reminiscent of the late 1960s and early ‘70s, which saw great responsiveness when the
                  teachings of Bahá’u’lláh were offered to humanity.
                </p>
                <p>
                  Moreover, as Bahá’ís and our collaborators build strengths to address this spirit of seeking, we see an
                  “acceleration in the negative forces associated with a declining order,” making our capacity for applying the
                  healing remedy of the Revelation of Bahá’u’lláh of that much more visible value.
                </p>
                <p>
                  “The framework for action (in this Plan) is very wide,” he said, encompassing “a range of activities that can be
                  harmonized with each other … mutually reinforcing, mutually enriching.” House members, he added, repeatedly used
                  the words “bold” and “audacious” in describing how we should respond.
                </p>
                <p>
                  And three basic lines of effort describe the full circle of those activities: expansion and consolidation of the
                  Bahá’í community, social action to benefit humanity, and participation in the discourses of society.
                </p>
                <p>
                  Several themes emerged from the discussion at the World Center as being important to the message the National
                  Assembly wanted to convey to the Bahá’ís:
                </p>
                <ul>
                  <li>
                    <strong>Use of language. </strong>“A question for Bahá’ís is, how do we create a language that reflects the
                    spirit of the Revelation of Bahá’u’lláh that avoids the creation of antagonism … avoids a sense of otherness,”
                    and validates the noblest of our aspirations. Our language also should be grounded in real-life experience, so
                    that we not only express Bahá’í principles but also share what it looks like and what our community is
                    learning.
                  </li>
                  <li>
                    <strong>Expansion and consolidation. </strong>As the community-building processes have advanced, we are seeing
                    “glimmerings of the potentialities” within them. These are most abundant in advanced clusters where hundreds
                    are already accompanying thousands in the process of realizing their spiritual destiny, as well as the 375
                    U.S. clusters that have passed the second milestone of development.
                  </li>
                  <li>
                    <strong>Teaching about the principle of race unity. </strong>
                    The House of Justice encouraged learning about how to converse on race in the context of teaching the Faith,
                    not just in the public discourse. This may well result at some future time in a set of training materials that
                    build on the foundation that has been set by current institute courses.{' '}
                  </li>
                  <li>
                    <strong>False dichotomies. </strong>Bowers recalled a key instance where thoughtful application of the House’s
                    guidance dissolved a dichotomy: after institute courses were recognized as a valuable learning tool for many
                    seekers, not just for enrolled Bahá’ís, much of our community needed some time — along with encouragement from
                    House letters — to come back to an understanding that firesides and other direct teaching of the Faith have an
                    essential place alongside the institute process. The challenge, he said, is to reach a balance where they
                    reinforce each other; for example, if someone is enthusiastic about attending firesides, and it becomes
                    evident that person is “truly interested in systematic study … maybe it’s time for that person to enter the
                    sequence of courses.”
                  </li>
                  <li>
                    <strong>The importance of enrollments. </strong>While the House has in recent years counseled us that
                    immediate interest in becoming a Bahá’í is not the only indication that a soul is receptive — and that many
                    people can work alongside us to build community without formally becoming members — “the purpose of community
                    life … [is to provide] opportunities for every soul, according to their inclinations … to come closer to
                    Bahá’u’lláh.” This implies that interested souls need to be given an avenue to embrace the Faith —
                    particularly important in this year when we are inviting humanity to celebrate the 200th anniversary of the
                    Birth of Bahá’u’lláh along with us.
                  </li>
                  <li>
                    <strong>Social action. </strong>It’s envisioned that Bahá’í-inspired social action will “arise naturally from
                    the movement of populations toward Bahá’u’lláh,” Bowers said, and the junior youth program is providing early
                    examples. However, useful initiatives are also being born of individual effort and collaboration with
                    like-minded groups. In either case, he noted, there is no need to let the limits of the Bahá’í community’s
                    resources constrain those efforts.{' '}
                  </li>
                  <li>
                    <strong>Race unity discourse. </strong>An awareness emerged that while discourse can be exercised on many
                    topics, “There’s no issue where, when you scratch the surface, you don’t find the issue of race.” This is one
                    of the motivations behind the National Assembly’s intention of holding a national conference on race unity, as
                    expressed in the Feb. 25 letter. But the Universal House of Justice has encouraged a learning process —
                    informed at least in part by Bahá’í community experiences, many of which can be brought to light through a
                    series of smaller gatherings. In the meantime, the national Office of Public Affairs has been developing a
                    space for discourse on faith and race that involves a variety of faith groups and promises to be rich in
                    learning.
                  </li>
                  <li>
                    <strong>Coherence of effort. </strong>Various agencies of the Assembly have been creating spaces for
                    reflection and learning that go beyond a narrow mandate in their process of generating knowledge. For example,
                    the permanent Bahá’í schools are maintaining their traditional function of deepening Bahá’ís’ knowledge about
                    the fundamentals of the Faith. But they are also conducting processes that bring people from the community of
                    interest into “several days of immersion in an atmosphere of prayer, of devotion, of meaningful conversation.”
                    Enduring friendships are forming, and some participants are embracing the Faith — more firmly realizing a
                    vision of the Guardian that the schools can be spaces for teaching the Faith directly.
                  </li>
                  <li>
                    <strong>Internet and social media. </strong>National communications offices are collaborating to learn in
                    several clusters about how social media can be used to engage interest on a local level, especially among
                    young people. Much as with the national media campaign at the turn of the 21st century, “the level of
                    receptivity in United States seems to be way beyond our current capacity to respond” — but our capacity to
                    learn what to do about it has advanced, and plans are forming to replicate these local campaigns in more
                    clusters.
                  </li>
                  <li>
                    <strong>Involvement of Local Assemblies. </strong>An ongoing series of gatherings about Local Spiritual
                    Assemblies and the spiritual health of the community is aimed at gathering knowledge and reflections that
                    could lead to additional training materials for Assemblies.
                  </li>
                </ul>
                <p>
                  The secretary also mentioned other efforts that are adding to the National Assembly’s understanding of the
                  “totality” of the Plan as expressed by the Supreme Institution: the operation of the Learning Desk in creating
                  learning spaces and sharing its analyses; the Wilmette Institute’s success in helping participants gain college
                  credit through an agreement with an accredited seminary; and gatherings that are helping bring thought leaders
                  and scholars in contact with Bahá’í thought, such as the Arab American Conference and those of the Association
                  of Friends of Persian Culture, the Association for Bahá’í Studies, and the Bahá’í Chair for World Peace.
                </p>
                <p>
                  In the discussion at the World Center, Bowers said it struck him that a Universal House of Justice member
                  offered a reminder of Shoghi Effendi’s admonition that a duty of Bahá’í institutions is to “help the friends
                  walk the path between extreme orthodoxy and irresponsible freedom” — in the interest of building a tapestry of
                  community life that is orderly and well-designed but incorporates the contributions of all.
                </p>
                <p>
                  A follow-up communication from the House of Justice — informing the final draft of the Feb. 25 letter —
                  emphasized the unique opportunities for Bahá’ís in America to distinguish not only our conversations but our
                  pattern of action by putting unity in diversity into practice.
                </p>
                <p>
                  “The potentials are enormous,” Bowers said. “We have to move forward, friends, with great focus. Only the Faith
                  holds the ultimate solution for the problems of our country and of humanity as a whole.”
                </p>
                <p>
                  He recalled his younger days when he took part in mass teaching in the South in the 1970s, often with the
                  accompaniment of Hands of the Cause. In retrospect, he acknowledged, that work was done with little
                  understanding of how to consolidate the new communities. “But there was energy, there was love, there was
                  confidence, there was hope, and that rubbed off on a 10-year-old kid.
                </p>
                <p>
                  “We have this opportunity again,” he asserted. One difference, though, is that thanks to the processes put in
                  place over the past two decades, the Bahá’í community as a whole is in a position to grow our community “in a
                  far more mature and stable manner.”
                </p>
                <p>
                  Even as we build on a foundation created through many early heroic efforts, we continue to need to address
                  admonitions from the Guardian conveyed to North American believers in 1953: the “most important teaching work”
                  would be befriending and spreading the light of the Revelation among minority populations, especially African
                  Americans and American Indians. “We can also add the populations of immigrants that the House of Justice has
                  called to our attention,” Bowers noted.
                </p>
                <p>
                  He concluded with an anecdote often told by one of those heroic pioneers, ‘Alí Nakhjavání — now a former member
                  of the Universal House of Justice. When a believer asked ‘Abdu’l-Bahá how Bahá’ís should be, He replied that
                  some would be like flowers: beautiful to behold because of the gifts of light, water and soil. But He added that
                  he hoped Bahá’ís would be more like rays of the sun, giving indiscriminately wherever we land.
                </p>
                <p>“Let’s spread that light and that sunshine,” Bowers said.</p>
                <p>
                  In consultation following the presentation, delegates expressed hope and gladness in the light of the National
                  Assembly’s intention of bringing race unity to the fore, in teaching as well as discourse. “I’ve never [before]
                  cried three times hearing a secretary’s report,” said one veteran delegate.
                </p>
                <p>
                  A member of the National Assembly spoke of the importance of “letting our inner lives reflect the teachings of
                  the Blessed Perfection” — for example, ensuring that not only our Bahá’í activities but our circles of friends
                  include people of many backgrounds.
                </p>
                <p>
                  Another delegate raised the point that even as we talk about the social reality of racial divides, it is
                  essential not to deviate from the principle that humanity is in physical and spiritual aspects a single race.
                </p>
                <p>
                  And consultation closed with anecdotes of efforts to empower young people of color to speak and contribute — and
                  the rewards coming from them — and the singing of the venerable civil rights anthem, “We Shall Overcome.”
                </p>
              </div>
            )}
            <hr />
            <img alt="Treasurer’s report" src="https://c1.staticflickr.com/3/2844/33946606380_f05cdf0a02_q.jpg" />
            <h4 className="small-top-padding">Treasurer’s report brings jubilation and challenges</h4>
            <p>
              One sentiment prevailed in the wake of the annual report of the National Spiritual Assembly’s treasurer on the
              second day of the U.S. Bahá’í National Convention: Our Bahá’í community needs to keep challenging itself to dedicate
              its time, energy, love and material resources to “discharge our obligations to a tortured and bewildered humanity.”
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  Within minutes after Treasurer Juana Conrad announced a $36 million goal for contributions to the National
                  Bahá’í Fund in the upcoming year — $1 million higher than for the fiscal year just ending — the Convention
                  delegates, by show of hands, recommended that the Assembly set the new goal higher still.
                </p>
                <p>
                  In one sense it was a jubilant movement to build on a thrilling success. We had collectively smashed what had
                  been the highest-ever annual goal, with $36.4 million already tabulated by the morning of April 28 and more to
                  be counted before the fiscal year’s end on April 30. Cheers and loud applause greeted that announcement in the
                  Convention.
                </p>
                <p>
                  “We thank all of you who have sacrificed and helped us with this goal,” Conrad said, essentially addressing all
                  the nation’s Bahá’ís. “Your generosity brought tears to the eyes of the National Spiritual Assembly.”
                </p>
                <p>
                  From another angle, it represented an aspiration to rise closer to a level of dedication that is worthy of the
                  needs and opportunities before the Bahá’ís. One delegate, after the treasurer’s report, said it would take even
                  more money than that to “help us grow the Faith.” Other delegates offered suggestions relevant to supporting
                  bicentenary activities and the National Archives.
                </p>
                <p>
                  The treasurer expressed another facet of the challenge before us: While giving to the Fund has risen steadily
                  through the 2010s, the number of believers contributing directly has gradually declined from a 2015 peak of just
                  over 14,000 — even as the dollar amount per contributor remains on the increase.
                </p>
                <p>
                  It is also “concerning” that most of that decline is among contributors age 50 and younger, with 78 percent of
                  direct individual contributions coming from believers of Baby Boomer age or older. Research has found that young
                  adults may tend to “pre-judge their contribution … thinking that if it’s too small it’s not worthy,” she noted.
                </p>
                <p>
                  Charitable giving among Americans in general is seeing virtually the same trends, she noted. Still, “it is
                  imperative that we ensure that the coming generations feel connected to the Funds of the Faith.”
                </p>
                <p>
                  The Fund Development arm of the Office of the Treasurer, Conrad noted, continues to learn about raising
                  awareness of the power of universal participation, sacrifice, and generosity at whatever level. And as one tool
                  for that purpose, she encouraged all Bahá’í communities to consider using the “Young, Broke and Spiritual”
                  deepening material.
                </p>
                <p>
                  Still, the general mood of the report, and delegates’ consultation afterward, was upbeat. “The overall picture
                  that emerges,” she said, “is that of a devoted, sacrificial community of contributors … that needs to grow in
                  size.”
                </p>
                <p>Among other statistics regarding contributions:</p>
                <ul>
                  <li>
                    Estate bequests in the past year have accounted for about $2 million of the total of regular contributions.
                  </li>
                  <li>
                    About 56 percent of contributions — and rising — are coming electronically through the Online Contribution
                    System and the Mobile Giving link (www.bahai.us/fund). The latter alone has been a conduit for $1.8 million in
                    contributions since its launch three years ago.
                  </li>
                  <li>
                    63 percent of the 1,011 Local Spiritual Assemblies are enrolled in the Local Fund Online program, which allows
                    believers to contribute electronically to their local Funds — and on May 1 many believers will also have the
                    option of contributing to Funds overseen by nearby Assemblies.
                  </li>
                  <li>
                    The number of believers who contribute directly to the National Fund 11–19 times in a year is still
                    increasing.
                  </li>
                  <li>
                    Direct contributions to the regional branches of the National Fund — essentially, the funds at the disposal of
                    the 12 Regional Bahá’í Councils — continue to grow steadily, but in the last complete fiscal year the general
                    National Fund still had to underwrite Council activities by $3.8 million.
                  </li>
                </ul>
                <p>
                  The Councils’ expenditures are at the heart of the Plan, as they support “numerous full- and part-time
                  volunteers who are essentially homefront pioneering throughout this nation,” assisting specific localities and
                  neighborhoods with core activities, teaching, home visits and more, the treasurer said.
                </p>
                <p>
                  But there are more areas where funds are needed for teaching and community building. The Continental Bahá’í Fund
                  supports the activity of members of the Continental Board of Counselors and the Auxiliary Boards, and Conrad
                  noted that the national offices “will be talking to you more and more about the work of the Continental Fund.”
                  Believers participating directly in this Fund have increased by 34 percent since 2011, representing a 9 percent
                  rise in contributions per year.
                </p>
                <p>
                  Teaching and education encompass more than 40 percent of all outlays from the general National Fund. This
                  includes not only the Regional Councils’ activity but also the regional training institutes; the Office of
                  Education and Schools and permanent Bahá’í schools and institutes; and five learning sites for the junior youth
                  spiritual empowerment program.
                </p>
                <p>
                  Other major areas of expenditure include efforts to defend the beleaguered Bahá’ís in Iran, through public
                  affairs activities and educational radio and video programs; information technologies; property maintenance;
                  publishing and communications; and capacity building in such areas as Assembly development, community
                  administration, Fund development, and learning efforts concerning discourse and the community-building process
                  itself.
                </p>
                <p>
                  Major projects being contemplated include a national conference on race unity (as mentioned briefly in the
                  Assembly’s Feb. 25 letter to the national community) and a study of how to upgrade the National Archives to
                  better store relics and records and provide scholars with access to historical knowledge.
                </p>
                <p>
                  “The Faith in America is growing in complexity and capacity. To build on these amazing efforts, a stable and
                  growing economic foundation is crucial,” Conrad said.
                </p>
                <p>
                  As Bahá’ís, it is our privilege and duty to use our material resources — even to sacrifice our comfort — to
                  strengthen what Shoghi Effendi called “the surest way of lifting once and for all time the burden of hunger and
                  misery from mankind … through the System of Bahá’u’lláh.” She concluded: “When we put our minds to achieve all
                  the goals of our Plan, we will assuredly receive confirmations from the Blessed Beauty.”
                </p>
              </div>
            )}
            <hr />
            <img alt="Huququllah presentation" src="https://c1.staticflickr.com/5/4161/34279434901_0afe17c4cf_q.jpg" />
            <h4 className="small-top-padding">Obeying the Right of God is a ‘conscience-driven act,’ trustees say</h4>
            <p>
              Guidance on the Right of God is copious — from Bahá’u’lláh, ‘Abdu’l-Bahá, the Guardian and the Universal House of
              Justice. All emphasize the importance of abiding by that sacred Law; Bahá’u’lláh’s Writings state that “to purify
              one’s riches and earthly possessions according to that which is prescribed by God” is third in importance only to
              recognition of God and constancy in His Cause.
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  But when it comes time for an individual to apply the Law in personal life, the guidance also tells us it is “a
                  conscience-driven act,” noted Mashiyyat Rahmani, treasurer of the Board of Trustees of Ḥuqúqu’lláh in the United
                  States, during the Board’s presentation at the U.S. Bahá’í National Convention on April 29.
                </p>
                <p>
                  For any believer who has resolved to calculate and perhaps pay an obligation to Ḥuqúqu’lláh, he said, “it’s up
                  to you to decide what [money one possesses is] excess and what’s not, and what’s a want vs. a need.”
                </p>
                <p>
                  And “due to all of the requests from the last couple of Conventions,” he reaffirmed that easier methods of
                  payment have recently been made available, including credit or debit card transactions alongside electronic bank
                  transfer.
                </p>
                <p>
                  Robert Malouf, secretary of the Board, began the presentation by introducing fellow members attending the
                  Convention: Danita Brown, chair; Shoori Samandari Loghmanee; and Rahmani.
                </p>
                <p>Board members not present were Shannon Javid, Manoocher Mofidi and Catherine E. Schmitz.</p>
                <p>
                  The secretary then read a letter to the Counselors, National Spiritual Assembly and delegates gathered at the
                  Convention that places the Law of the Right of God in perspective as inseparable from “the holy prescription” to
                  assuage the ills of humanity, “administered through the agency of ‘Abdu’l-Bahá’s Divine Plan.”
                </p>
                <p>
                  The letter cites a statement within the March 1, 2017, letter of the Universal House of Justice that following
                  the Law is “an indispensable discipline to bring one’s priorities into balance, purify whatever wealth one
                  possesses, and ensure that the share which is the Right of God provides for the greater good.”
                </p>
                <p>
                  It encapsulates the process of obedience to the Law, saying that it “begins and ends with the love of
                  Bahá’u’lláh. In between are learning, understanding, assessing one’s wealth, and, if prescribed by the law,
                  payment. It is a process at once humbling, edifying, confirming and joyous.”
                </p>
                <p>
                  And it acknowledges the collaboration of other Bahá’í institutions in promoting Ḥuqúqu’lláh education among the
                  believers, including the Counselors and National Assembly, as well as Auxiliary Board members and Local
                  Assemblies who work together with the Board’s representatives.
                </p>
                <p>
                  Rahmani, opening the session to questions from delegates, noted that the Ḥuqúqu’lláh office in the Chicago area,
                  created only three years ago, has grown in personnel. It has been assisting believers in several countries in
                  addition to the United States in processing Ḥuqúq payments.
                </p>
                <p>
                  The electronic payment system, he said, “has been working very efficiently” and a growing number of Bahá’ís of a
                  range of ages have been using it.” He added that an app is in the works to facilitate contributions through
                  mobile devices.
                </p>
                <p>Questions from delegates dealt with:</p>
                <ul>
                  <li>
                    <strong>The use of the proceeds.</strong> Rahmani reminded the Bahá’ís that in keeping with Bahá’u’lláh’s
                    instruction, Ḥuqúqu’lláh funds go directly to the Head of the Faith, the Universal House of Justice. And while
                    the House of Justice oversees such projects as “the building of Houses of Worship all over the world, creating
                    schools and education systems” and other beneficial projects, he also speculated that the development of the
                    institution is intertwined with the mystical influence of the Revelation on the prosperity of society in
                    general. Malouf later cited numerous excerpts from sacred and authoritative writings that express the
                    “absolute breadth” of uses to which the Head of the Faith should apply those funds.
                  </li>
                  <li>
                    <strong>Fees associated with credit or debit card payment.</strong> The House of Justice has directed the
                    Board serving the United States to absorb any fees associated with electronic payment, Rahmani said, adding
                    that there are smaller fees associated with online “bill pay” features offered by banks. But practically every
                    transaction has associated costs, even when Bahá’ís make payment in the form of stocks and bonds. If you use a
                    credit card, he reasoned, “at some point you have to pay your credit card, so why not … [offer] payment in the
                    purest form?”
                  </li>
                  <li>
                    <strong>Accounting for payments.</strong> From the receipt of payment to transfer to the Bahá’í World Center,
                    Rahmani said, the funds are monitored daily and “literally every penny is accounted for.” He said the office
                    has recently received a check equivalent to 3 cents whose sender is unknown, and they are working to find out
                    the source of that payment.
                  </li>
                  <li>
                    <strong>Tax deductibility.</strong> The treasurer reaffirmed that the Board is a nonprofit organization and
                    all payments are deductible for purposes of U.S. federal income tax.
                  </li>
                  <li>
                    <strong>Educational opportunities.</strong> More than 90 representatives of the Board are serving the
                    institution of the Right of God nationwide. If an Assembly or group would like a representative to facilitate
                    a deepening, one can be located either through the Ḥuqúqu’lláh website or through contact with a member of the
                    Board.
                  </li>
                </ul>
                <p>
                  Prompted for a story of sacrifice, Rahmani shared that one Bahá’í on a fixed income recently asked a Board
                  member about the logistics of making a payment, even though she does not have excess income. “She’s just getting
                  barely money to make ends meet but still wants to express her love to her Lord,” he said. “These are things that
                  really touch one’s heart.”
                </p>
              </div>
            )}
            <hr />
            <img alt="Delegate speaking" src="https://c1.staticflickr.com/5/4170/34049818770_59313508f5_q.jpg" />
            <h4 className="small-top-padding">Learning isn’t a piece of cake, but consultation shows process is sweet</h4>
            <p>
              How is the learning process like a confection? Counselor Nwandi Lawson provided the answer in introducing
              consultation on engaging in a process of learning on Saturday evening, April 29, at the 109th U.S. Bahá’í National
              Convention.
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  Lawson invited her audience to envision a homemade cake on one hand and something hastily microwaved in the
                  other. The latter just isn’t the same.
                </p>
                <p>
                  Likewise, she said, if Bahá’ís and collaborators skip steps in building community, they do not learn what is
                  needed for the cluster to flow systematically along a continuum of growth and development and, as a result, risk
                  stagnation.
                </p>
                <p>
                  Lawson also urged that the entire population of an area be considered to be potential collaborators. And she
                  said there are vital roles to play in the learning process for all three protagonists in the Plan: individuals,
                  communities and institutions.
                </p>
                <p>
                  Delegate consultation on the topic brought out many ways these protagonists are making great strides as their
                  experiences, study and reflection lead to insights and more effective action than ever.
                </p>
                <p>Some topics:</p>
                <ul>
                  <li>
                    <em>Georgia:</em> Young adults and their children have returned to activities, and participation in
                    observances has soared, since a cluster’s two Local Spiritual Assemblies met to study guidance on preparation
                    for the bicentenaries and decided to form committees for Holy Days, outreach and special programs.
                  </li>
                  <li>
                    <em>Indiana:</em> Deepening remains vitally important and the Wilmette Institute can aid that process
                    tremendously, with courses offered at three levels of participation.
                  </li>
                  <li>
                    <em>Washington:</em> Likewise, the Institute for Studies in Global Prosperity (for young adults) and the
                    Association for Bahá’í Studies (for all ages, but especially professionals and academics) raise capacity to
                    contribute to discourse.
                  </li>
                  <li>
                    <em>California:</em> Studying core documents from 1996 on advancing the process of entry by troops has brought
                    new understanding of why clusters engaged in the same activities progress at different rates.
                  </li>
                  <li>
                    <em>New Mexico:</em> Just as a ship cannot turn until it’s moving, the act of inviting people to an observance
                    and then following up on interest expressed by attendees has generated much learning in a cluster.
                  </li>
                  <li>
                    <em>California:</em> Close collaboration with Auxiliary Board members has brought unity of thought and greater
                    vision, particularly regarding support for service volunteers and for the regional branch of the National
                    Fund.
                  </li>
                  <li>
                    <em>Texas:</em> New agencies and resources have arisen from the division of an area into additional clusters,
                    as well as more concerted teaching efforts including deputization of a youth in service and rental of an
                    apartment to provide a home for junior youth group meetings.
                  </li>
                  <li>
                    <em>Oregon:</em> The practices relating to Ruhi Book 10, Building Vibrant Communities, have accelerated
                    putting learning into action, with each study circle session akin to a mini-reflection gathering.
                  </li>
                  <li>
                    <em>North Carolina:</em> A reservoir cluster’s learning and resources are being shared most widely when
                    believers from milestone 1 clusters travel there to participate in activities.
                  </li>
                  <li>
                    <em>South Carolina:</em> The initiative known as South Carolina Enterprise prepared its youth participants to
                    engage in Charleston’s healing following the mass slaying in an African American church.
                  </li>
                  <li>
                    <em>Texas:</em> Re-watching the Frontiers of Learning video helps increase understanding of how outreach in
                    focus neighborhoods fuels learning and progress.
                  </li>
                  <li>
                    <em>North Carolina:</em> A need is seen for materials relating to homosexuality and for practice in conversing
                    with people of that orientation and their families.
                  </li>
                  <li>
                    <em>Arizona:</em> A weekly youth gathering that was established with no set aim has evolved into a space for
                    reflection and planning.
                  </li>
                  <li>
                    <em>Massachusetts:</em> Teams have been formed to address the two prongs of collective teaching in
                    neighborhoods and individual teaching each believe lives, with the gains coming in applying what’s being
                    learned in youth initiatives to other spheres of activity.
                  </li>
                </ul>
              </div>
            )}
            <hr />
            <img alt="Delegate speaking" src="https://c1.staticflickr.com/5/4161/33978243280_a60a341ae7_q.jpg" />
            <h4 className="small-top-padding">Discourses of society topic sparks rich consultation</h4>
            <p>
              The message to delegates consulting on the discourses of society at the 109th U.S. Bahá’í National Convention on
              Saturday, April 29, was simple and clear: The processes of community building — expansion and consolidation, social
              action, and discourse — are integral and coherent.
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  Counselor Sonlla Heern delivered that explanation, noting that if you have trouble separating one element from
                  another it’s because they’re fundamentally connected.
                </p>
                <p>
                  After all, she said, when the teachings of Bahá’u’lláh fire people’s imagination, it’s only natural they will
                  want to apply them right away to the ills of society and share them in discussion spaces.
                </p>
                <p>
                  And that can occur at any point along a cluster’s path, said Heern. But the more robust a community-building
                  process, the more sophisticated and far-reaching that participation in discourses can be.
                </p>
                <p>Taking up the consultation, delegates shared a number of discourse initiatives and approaches.</p>
                <p>
                  They also considered issues relating to the development of discourses and the attitudes and preparation
                  necessary to be effective.
                </p>
                <p>Topics included:</p>
                <ul>
                  <li>
                    <em>Vermont/New Hampshire:</em> Non-involvement in political talk during book club meetings has led to the
                    opportunity to share tenets of the Faith and prompted club members to express appreciation for the
                    information.
                  </li>
                  <li>
                    <em>Washington:</em> Bahá’ís trained in facilitation would more easily be able to improve the level of
                    discourse in meetings of community organizations.
                  </li>
                  <li>
                    <em>New York:</em> Devotional gatherings are a powerful means to attract and retain the interest of African
                    Americans because so many are responsive to prayer.
                  </li>
                  <li>
                    <em>Florida:</em> Careful thought went into involvement with an interfaith organization to ensure that its
                    aims weren’t political, and that affiliation has resulted in service to the homeless, ex-offenders, and people
                    who would benefit from alternatives to arrest or incarceration.
                  </li>
                  <li>
                    <em>Tennessee:</em> After a Bahá’í center was opened to English language classes for immigrants, a
                    university-sponsored seminar on refugees was held there, with a Bahá’í on the panel, and a memorial service
                    for a Muslim.
                  </li>
                  <li>
                    <em>Illinois:</em> Inviting a speaker to return periodically enhances the continuity and quality of discourse
                    on a topic, such as one on race.
                  </li>
                  <li>
                    <em>Oregon:</em> It’s important to invite people who are involved, or their children are involved, in core
                    activities to take ownership of community building by being part of the planning and outreach.
                  </li>
                </ul>
              </div>
            )}
            <hr />
            <img alt="Delegate speaking" src="https://c1.staticflickr.com/5/4189/34270935862_9abfc9bf38_q.jpg" />
            <h4 className="small-top-padding">Social action consultation galvanized by House of Justice letter</h4>
            <p>
              Fresh guidance from the Universal House of Justice on involvement in social action and the discourses of society in
              relation to activism in the larger society galvanized consultation on social action Friday evening, April 28, at the
              109th U.S. Bahá’í National Convention.
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  Delegates spoke to the coherence of social action with the other elements of the framework for action, echoing
                  the Supreme Body’s call for social action and discourse to arise organically from the process of expansion and
                  consolidation.
                </p>
                <p>
                  As noted in case after case, Bahá’ís and collaborators are learning the needs and aspirations of people in
                  neighborhoods they serve and are walking alongside those new friends to apply understandings and skills from the
                  institute process to societal ills.
                </p>
                <p>Among topics that emerged during consultation:</p>
                <ul>
                  <li>
                    <em>California:</em> As social issues outside the Faith mount, Bahá’ís will need to better educate their
                    children on such issues and prepare them to serve in the Administrative Order.
                  </li>
                  <li>
                    <em>Texas:</em> A Bahá’í community’s willingness to open its center for meetings of an organization led to a
                    symposium there that drew a capacity crowd and the appointment of two believers to a task force on dismantling
                    institutional racism.
                  </li>
                  <li>
                    <em>Massachusetts:</em> The introduction of Bahá’í teachings aimed at bringing the human family together has a
                    catalytic effect on social action in the larger community as it empowers people to act with our accompaniment
                    in a way that transforms lives and society.
                  </li>
                  <li>
                    <em>New Mexico:</em> Studying the history of the Faith helps young people approach social action from the
                    perspective informed by the writings.
                  </li>
                  <li>
                    <em>California:</em> The Los Angeles Bahá’í community is holding a legal clinic for graduates of the junior
                    youth spiritual empowerment program who are undocumented and want to attend college but are in danger of being
                    deported.
                  </li>
                  <li>
                    <em>Massachusetts:</em> In the wake of a hate crime against people of homosexual orientation, Needham Bahá’ís
                    organized an interfaith prayer vigil that raised participation to a spiritual level and led to Bahá’ís being
                    invited by the Roman Catholic archbishop to become involved with a follow-up initiative.
                  </li>
                  <li>
                    <em>California:</em> A Bahá’í who serves as a school principal was rebuffed in trying to bring the junior
                    youth program into the school, began to approach school betterment in a different way that resulted in greater
                    school spirit, and created an atmosphere in which the junior youth program ultimately was accepted.
                  </li>
                  <li>
                    <em>Nebraska:</em> An isolated believer is educating his children to go beyond dependence and independence to
                    interdependence.
                  </li>
                  <li>
                    <em>Washington:</em> A professor has found that he has to reframe such concepts as oneness and service for
                    people who are trapped in a worldview of social contest and offer an approach of constructive resilience for
                    those who don’t see an alternative to violence or resistance.
                  </li>
                </ul>
              </div>
            )}
            <hr />
            <img alt="Delegate speaking" src="https://c1.staticflickr.com/3/2844/34342832295_e2a491a652_q.jpg" />
            <h4 className="small-top-padding">Delegates ‘buckle up’ to act in year ahead on expansion and consolidation</h4>
            <p>
              “Buckle Up,” a motto adopted by Northern Plains States Bahá’ís, fit niftily the attitude of delegates to the 109th
              U.S Bahá’í National Convention who consulted on the topic of expansion and consolidation on Friday, April 28.
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  Delegates signaled they are girding their loins for the coming year to acquaint people of all backgrounds and
                  strata with the life and teachings of Bahá’u’lláh and involve those new friends in the work of transforming
                  individual lives and society.
                </p>
                <p>
                  Counselor Nwandi Lawson prefaced the consultation on expansion and consolidation with some thoughts on a
                  systematic community-building process that’s built to last for generations, unlike “hashtag” movements that come
                  and go.
                </p>
                <p>
                  As experience is gained and shared and it leads to ever more effective action, she said, this empowering process
                  is giving participants and observers glimpses into the future that reinforce how practical and attainable its
                  goals are.
                </p>
                <p>
                  Delegates, in their consultation, shared what is being learned in clusters at all points along the continuum of
                  growth and what challenges and opportunities are being met head on.
                </p>
                <p>
                  Woven throughout were concerns and optimism about reaching people of color and native peoples with the healing
                  message in a way that’s loving and respectful of culture and the impact oppression continues to have on their
                  lives.
                </p>
                <p>Some of the topics delegates broached:</p>
                <ul>
                  <li>
                    <em>Michigan:</em> A need to study how barriers to unity presented by the reality of racial segregation —
                    “chocolate cities and vanilla suburbs” — in the neighborhoods we serve can be overcome.
                  </li>
                  <li>
                    <em>South Carolina:</em> Encouragement to reach all strata, recognizing that within ethnic groups are people
                    of different income and educational levels.
                  </li>
                  <li>
                    <em>Illinois:</em> The idea of a Bahá’í chair similar to that on world peace that would study racial harmony
                    in an academic environment.
                  </li>
                  <li>
                    <em>Florida:</em> The value that disseminating audio of the Hand of the Cause Dorothy Baker speaking at an
                    Intercontinental Conference about teaching people of color would hold.
                  </li>
                  <li>
                    <em>North Carolina:</em> How outreach at a historically black university has resulted in several students
                    becoming involved with the junior youth spiritual empowerment program and one volunteering a period of
                    service.
                  </li>
                  <li>
                    <em>Arizona:</em> The need for homefront pioneers and mobile resources to help build and strengthen programs
                    of growth in rural clusters.
                  </li>
                  <li>
                    <em>Virginia:</em> A plea that not just words but a spirit and love be shared in spiritual conversations with
                    people we meet.
                  </li>
                  <li>
                    <em>Illinois:</em> That putting the most experienced tutors in Ruhi Book 1 study circles would give
                    participants a stronger foundation and encourage them to continue with the sequence of courses.
                  </li>
                  <li>
                    <em>Pennsylvania:</em> How a single conversation with a restaurant server in an area with no believers pointed
                    visiting Bahá’ís toward a neighborhood of people receptive to the junior youth program, and conversations on a
                    university campus resulted in three students studying to become animators.
                  </li>
                  <li>
                    <em>New York:</em> The impact in a cluster of likening the institute process to a powerful machine that needs
                    continuous fuel in the form of study circle participants to gain momentum and break through the “cloudiness”
                    of society.
                  </li>
                  <li>
                    <em>Texas:</em> A need for Bahá’í communities to examine themselves for readiness to accept into their midst
                    young people who look and speak in a way that is beyond the comfort zone of many believers.
                  </li>
                  <li>
                    <em>Minnesota:</em> How older believers who didn’t envision themselves serving in a neighborhood stepped up
                    when it became clear that bringing in teachers from another area was not sustainable.
                  </li>
                  <li>
                    <em>Ohio:</em> Efforts in that state and Arizona to aid Iranian newcomers, many of them economic refugees, and
                    provide Persian-language spiritual education of all ages.
                  </li>
                  <li>
                    <em>Idaho:</em> “Buckle Up,” the rallying cry adopted by the Northern Plains States after participation in an
                    inter-institutional meeting — for areas with vast space and few people — fueled plans for the next cycles of
                    activity, especially in the region’s first cluster with an Area Teaching Committee.
                  </li>
                  <li>
                    <em>California:</em> How more open communication among institutions and agencies empowered people in a cluster
                    to take initiative, with the result that an approach to law enforcement launched conversations on race unity
                    and study circles in the police station.
                  </li>
                </ul>
              </div>
            )}
            <hr />
            <img alt="Ridvan message" src="https://c1.staticflickr.com/5/4188/34167008202_3dc3eacd02_q.jpg" />
            <h4 className="small-top-padding">Riḍván message captures the imaginations of Counselors, delegates</h4>
            <p>
              Counselor Sonlla Heern took delegates to the 109th U.S. Bahá’í National Convention on a journey through the 2017
              Riḍván message of the Universal House of Justice, then her colleague Nwandi Lawson lingered at one stop along that
              glorious tour. (
              <strong>
                <em>A link to download that message is at the upper right.</em>
              </strong>
              )
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  <strong>Overview of the message</strong>
                  <br />
                  Heern reminded her listeners of last year’s message, in which the Supreme Body said what Bahá´ís and
                  collaborators achieved to that point had prepared them to reap spiritual fruits over the next five years if they
                  commit wholeheartedly to the effort.
                </p>
                <p>
                  One year into this Plan, she said, the House of Justice, right from the opening line — “See how the community of
                  the Greatest Name arises!” — has signaled its joy with how that effort is proceeding.
                </p>
                <p>
                  The message goes on to review those accomplishments to date, said Heern, allowing the believers to step back
                  from their own experiences and see signs of progress across the globe that might not yet be visible where they
                  live.
                </p>
                <p>
                  Then, she said, the House of Justice shifts our attention to the coming year, proclaiming that our “earnest
                  striving … to extend the reach” of Bahá’u’lláh’s Faith would constitute the most fitting offering to the Blessed
                  Beauty on the occasion of the 200th anniversary of His birth.
                </p>
                <p>
                  If that weren’t inspiration enough, said Heern, the Supreme Body proceeds to say this year “presents the single
                  greatest worldwide opportunity there has ever been for connecting hearts to Bahá’u’lláh” and urges all to seize
                  every opportunity to acquaint others with “His life and sublime mission.”
                </p>
                <p>
                  And it places this effort against the backdrop of a world situation growing more dire every day, she noted, a
                  “cloudiness” that can pierced only to the extent Bahá’ís and their collaborators exhibit certain qualities to
                  the world.
                </p>
                <p>
                  Heern said in that pursuit, the House of Justice writes, no distraction from the “foam and spray” of a
                  “turbulent and divided society” can be allowed to occupy the Plan’s protagonists, saying their
                  community-building endeavors offer “a potent example of what unity can achieve.”
                </p>
                <p>
                  An example of the impact of those core activities can be seen in Heern’s own cluster, she said. At a gathering
                  to plan the steps leading up to commemoration of the bicentenary, young people — registered as Bahá’ís or not —
                  stood to identify themselves with the Bahá’í community and describe how their lives, and the lives of many
                  around them, have been changed in fundamental and lasting ways.
                </p>
                <p>
                  <strong>Looking to the year ahead</strong>
                  <br />
                  How more and more souls can similarly be awakened in the year ahead, particularly in the months leading up to
                  the bicentenary, was on Lawson’s mind as she began her remarks.
                </p>
                <p>
                  She recalled attending another country’s National Convention and witnessing how that year’s Riḍván message
                  evoked feelings leading to goals and plans to reach them.
                </p>
                <p>
                  The exploration of how to do that, which she said followed naturally, resembled an FAQ, or a set of frequently
                  asked questions. So what is our FAQ for this message of the House of Justice? How can Bahá’ís and collaborators
                  offer a most fitting gift to the Blessed Beauty?
                </p>
                <p>
                  Fortunately, said Lawson, guidance from the Supreme Body has washed over the community of the Greatest Name and
                  communities have begun to tackle those questions.
                </p>
                <p>
                  Wonderful approaches are emerging from those explorations, she said, that will result not only in appropriate
                  events to celebrate the bicentenary but also in activities that involve increasing numbers of neighbors and can
                  be sustained and grow over the years to come.
                </p>
                <p>
                  In some places, for example, each Plan participant is being asked to list 10 people with whom he or she might
                  share the gift of Bahá’u’lláh’s life and teachings. In others, wonderful stories about the lives of the Twin
                  Luminaries are being researched and, just as important, people are learning how to tell those stories.
                  Everywhere, efforts are being redoubled to build relationships with multitudes of people and bring them into the
                  community-building process.
                </p>
                <p>
                  Questions being asked in these regards, Lawson said, include: How can we sustain relationships with new friends
                  before and after the celebrations? How can we invite people in a way that will bring about wide participation?
                  What are the elements of a program that would convey the importance of these two Luminaries arising in the
                  world?
                </p>
                <p>
                  Such questions, she said, are leading to plans and much learning. And what is coming out of this process
                  promises to touch people for generations to come.
                </p>
                <p>
                  <strong>Experience and learning across the nation</strong>
                  <br />
                  In consultation that followed the Counselors’ remarks on the Riḍván message, delegates demonstrated that the
                  Supreme Body’s call to acquaint “every kind of person” with the life and transformative teachings of Bahá’u’lláh
                  had struck home.
                </p>
                <p>
                  Some comments centered on activity surrounding plans to commemorate the bicentenaries of the Birth of
                  Bahá’u’lláh and, two years later, of the Birth of the Báb (a full session devoted to preparation for the
                  bicentenaries took place later in Convention):
                </p>
                <ul>
                  <li>
                    <em>California:</em> Service to the homeless, the infirm, the lonely and community organizations is building
                    relationships that will continue beyond the celebrations.
                  </li>
                  <li>
                    <em>Massachusetts/New Hampshire:</em> Storytelling workshops are empowering people to speak about the Twin
                    Luminaries.
                  </li>
                  <li>
                    <em>Virginia:</em> A Bahá’í envisions that invitees approach the place of celebration in a dramatic fashion,
                    bearing paper lanterns lighted with battery-operated candles.{' '}
                  </li>
                </ul>
                <p>
                  Other delegates zeroed in on how, “viewed aright, this year presents the greatest worldwide opportunity there
                  has ever been for connecting hearts to Bahá’u’lláh.”
                </p>
                <ul>
                  <li>
                    <em>Vermont/New Hampshire:</em> Two simple posters, one representing the decay of society and one about
                    community building, are being used in conversations.{' '}
                  </li>
                  <li>
                    <em>California:</em> Participants in neighborhood devotionals are expressing their appreciation for a spirit
                    of oneness not felt elsewhere.
                  </li>
                  <li>
                    <em>Ohio:</em> A renewed effort is needed to find spaces everywhere for spiritual conversation so
                    opportunities to reach specific populations are fully realized.
                  </li>
                  <li>
                    <em>Virginia:</em> Accompaniment is a powerful motivator, as evidenced when a Bahá’í driving to speak to a
                    parent about the junior youth spiritual empowerment program pulled over and prayed over the phone with another
                    Bahá’í for the confidence to proceed with the visit.
                  </li>
                  <li>
                    <em>Florida:</em> The Central Figures have provided guidance and example for reaching out to the African
                    American population, which has great capacity and with God’s teachings for today will be able to fully play
                    its destined role in building the Kingdom.
                  </li>
                </ul>
              </div>
            )}
            <hr />
            <img alt="Delegate deepening" src="https://c1.staticflickr.com/5/4184/33500749683_c85ba40944_q.jpg" />
            <h4 className="small-top-padding">Convention opens in wake of delegate deepening, devotions</h4>
            <p>
              A panoramic view of Chicagoland encircled delegates to the 109th U.S. Bahá’í National Convention on Thursday
              morning, April 27, as they deepened, in a session facilitated by Counselors Sonlla Heern and Nwandi Lawson, on the
              duties they would begin to discharge that afternoon.
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  During that morning of preparation at a lofty hotel conference room in Skokie, Illinois, Heern said she was
                  struck, in reading the second unit of Ruhi Book 10, by the example used of consultation among the disciples of
                  Jesus — consultation characterized by simple statements of truth and acknowledgements of that truth.
                </p>
                <p>
                  That’s a standard to which delegates should strive in their consultation over the weekend, said Heern, as they
                  share experiences where they live but always in the context of a national forward movement.
                </p>
                <p>
                  Lawson recalled her days as a political and sports reporter. At their best, both arenas spotlight teamwork in
                  pursuit of a goal. Too often, though, these processes disintegrate into self-interest.
                </p>
                <p>
                  By comparison, she said, the integrative processes in which Bahá’ís are engaged lead to pivotal moments of
                  understanding and a growing ability to apply what we’ve learned. Individual and collective reflection plays a
                  key role, and National Convention is one such opportunity to glimpse where the framework for action is taking
                  the Cause and society as a whole.
                </p>
                <p>
                  Two letters from the Universal House of Justice outlining delegates’ responsibilities were studied in small
                  groups evoking the intimacy of the National Convention in Trinidad from which Heern had just come.
                </p>
                <p>
                  In a March 25, 2007, letter to the Bahá’ís of the world, the Supreme Body wrote that the System of Bahá’u’lláh
                  confers on each Bahá’í a “high privilege” to select, “as a responsible citizen of the new world being brought
                  into existence,” the composition of its institutions.
                </p>
                <p>
                  And in a May 16, 2013, letter to the delegates gathered at Bahá’í National Conventions, the House of Justice
                  noted that their consultations are “unique” among all the “spaces that allow for the exchange of views” in that
                  National Convention “brings together the National Spiritual Assembly and elected representatives from all parts
                  of the country” and this dimension calls for “thoughtful attention.”
                </p>
                <p>
                  Delegates were urged, in studying these letters, to pause after each paragraph, inhale, perhaps take notes then
                  move on. Questions relating to the concepts in each letter were provided for delegates to discuss in their small
                  groups then in plenary.
                </p>
                <p>
                  After a bus ride to the nearby Bahá’í House of Worship, delegates were spiritually transported by a devotional
                  program in the Temple Auditorium.
                </p>
                <p>
                  With the words “What tongue can voice my thanks, voice my thanks to Thee” echoing in their hearts, they then
                  proceeded to Foundation Hall for the opening of Convention.
                </p>
                <p>
                  Formal seating of delegates by roll call led to the election of a chair for the four-day gathering, David F.
                  Young, and secretary, Muin Afnani.
                </p>
                <p>
                  The organization of Convention and its agenda also were addressed. Among the procedures adopted by delegates
                  were the length of time allotted to each speaker during consultation sessions; the protocol for suggestions and
                  recommendations to the National Spiritual Assembly; and the addition of a period of reflection to the
                  Convention’s closing session.
                </p>
                <p>
                  Reading of the Ridván 2017 message of the Universal House of Justice was followed by a sharing of perspectives
                  on the message by the Counselors present and delegate consultation on its themes.
                </p>
                <p>
                  This busy day would come to a most fitting end back in the Auditorium with an observance of the Ninth Day of
                  Ridván.
                </p>
              </div>
            )}
            <hr />
            <img alt="House of Worship fountain" src="https://c1.staticflickr.com/3/2854/33983719552_d25371e495_q.jpg" />
            <h4 className="small-top-padding">A year into the Plan, twin duties await Convention delegates</h4>
            <p>
              With the experiences of the first year of this Five Year Plan fresh in memory, delegates to the 109th U.S. Bahá’í
              National Convention convene Thursday, April 27, at the Bahá’í House of Worship in Wilmette, Illinois.
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>Two principal tasks await them:</p>
                <ul>
                  <li>
                    Prayerfully elect the nine members of the National Spiritual Assembly that will guide the affairs of the
                    Bahá’í Faith in this country over the coming year.
                  </li>
                  <li>Inform the incoming National Assembly through consultation on the progress of the Faith.</li>
                </ul>
                <p>
                  The delegates, elected last October in their home units, also will hear reports on the past year’s activities
                  and gain insights from the Counselors present.
                </p>
                <p>Day by day, here is how Convention is set to unfold.</p>
                <p>
                  <strong>THURSDAY</strong>
                </p>
                <p>
                  The Counselors will facilitate a deepening by delegates on the purposes of National Convention and their role in
                  it. Thus prepared, the delegates will be transported from their hotel to the nine-sided Temple on the shores of
                  Lake Michigan.
                </p>
                <p>
                  Convention will begin in Foundation Hall with the seating of delegates in a roll call by the temporary
                  Convention secretary. The first order of business: Elect Convention officers, approve the agenda, and adopt
                  procedures for consultation.
                </p>
                <p>
                  After delegates hear a recitation of the Riḍván message of the Universal House of Justice, the Counselors will
                  give thoughts on the message and delegates will explore their understanding of its guidance.
                </p>
                <p>An evening observance of the Ninth Day of Riḍván will provide a bridge to Convention’s second day.</p>
                <p>
                  <strong>FRIDAY</strong>
                </p>
                <p>
                  Presentations by the National Assembly’s secretary and treasurer expanding on key points in that body’s Annual
                  Report will be followed by questions from and consultation by delegates.
                </p>
                <p>
                  The day’s remaining sessions will be devoted to consultation on themes that address the challenges and
                  opportunities facing the American Bahá’í community in the coming year.
                </p>
                <p>
                  <strong>SATURDAY</strong>
                </p>
                <p>
                  Delegates will cast their ballots for the incoming National Assembly. After the balloting delegates will have a
                  further opportunity to consult.
                </p>
                <p>
                  If the weather cooperates, a Convention photograph will be taken on the upper steps of the Bahá’í House of
                  Worship. Otherwise, it will be snapped inside Foundation Hall.
                </p>
                <p>The rest of the day will see:</p>
                <ul>
                  <li>The tellers’ report of the balloting results.</li>
                  <li>A presentation by the trustees of Bahá’í Ḥuqúqu’lláh in the United States.</li>
                  <li>Presentation of the newly elected National Spiritual Assembly.</li>
                  <li>More consultation.</li>
                </ul>
                <p>
                  <strong>SUNDAY</strong>
                </p>
                <p>Convention wraps up with final opportunities to consult and to hear from the Counselors.</p>
              </div>
            )}
            <hr />
            <img alt="Q&A" src="https://c1.staticflickr.com/3/2872/34010649781_e8182ccbab_q.jpg" />
            <h4 className="small-top-padding">Questions and answers about National Convention</h4>
            <p>
              <strong>Q. What are the purposes of National Convention?</strong> <br />
              A. The Universal House of Justice, in an April 4, 1971, letter from the Universal House of Justice to our National
              Spiritual Assembly, explained, “[T]he primary purpose in holding a convention is to enable the delegates to meet
              together, deliberate freely upon the affairs of the community, and cast their ballots. Other considerations such as
              allowing the rank and file of believers to attend the Convention or follow its deliberations are secondary in
              importance. Obviously it is within the discretion of your National Assembly to decide upon these secondary matters.”
              The balloting to which the letter refers is for the nine members of the National Assembly that will serve for the
              coming year.
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  <strong>Q. When is National Convention?</strong> <br />
                  A. Most years, National Convention is held on a weekend during the 12-day Festival of Riḍván that doesn’t
                  conflict with the election of Local Spiritual Assemblies. Every five years, National Convention is held in late
                  May. In those years the Universal House of Justice is elected at the Bahá’í World Center in Haifa, Israel. Since
                  that election takes place in April, the National Convention is held in May to enable the National Assembly
                  members to attend the International Convention.
                </p>
                <p>
                  <strong>Q. Where is National Convention held?</strong> <br />
                  A. For the past several years, all sessions of the National Convention have been held at the Bahá’í House of
                  Worship in Wilmette, Illinois. In the past, the National Convention was often held in a large hotel meeting
                  space to allow a large number of Bahá’ís to attend. Mostly for budgetary reasons, Convention sessions are now
                  held at the Bahá’í House of Worship.
                </p>
                <p>
                  <strong>Q. Who can attend National Convention?</strong> <br />
                  A. The small number of seats in Foundation Hall at the Bahá’í House of Worship greatly limits the number of
                  people who can attend. Delegates are guaranteed seats, as are members of the Continental Board of Counselors and
                  the National Spiritual Assembly. The remainder of seats in Foundation Hall are filled by some members of the
                  Auxiliary Boards for Protection and Propagation and the Regional Bahá’í Councils, a small number of the Bahá’ís
                  serving at the Bahá’í National Center and guests such as delegates’ family members.
                </p>
                <p>
                  <strong>Q. How are National Convention delegates elected, and who is eligible to serve?</strong> <br />
                  A. The number of delegates allocated to the U.S. Bahá’í National Convention was set by the Guardian, Shoghi
                  Effendi, at 171. At Unit Convention in early October, the Bahá’ís in most geographical units elect one believer
                  to be the delegate from that unit. A few units, because of the large number of believers they contain, elect
                  more than one delegate. Any adult Bahá’í in good standing who lives in the unit is eligible to serve as a
                  delegate, except members of the Continental Board of Counselors. If an Auxiliary Board member is elected as a
                  delegate, he or she must decide whether to serve as delegate or remain on the Auxiliary Board. If the elected
                  delegate cannot serve, the National Spiritual Assembly chooses his or her replacement. The results of the unit
                  convention elections in October must be confirmed by the National Spiritual Assembly and delegates are
                  officially “seated” within 1-2 months.
                </p>
                <p>
                  <strong>Q. How do delegates prepare for National Convention?</strong> <br />
                  A. To assist new and returning delegates in preparing for participation in the Convention, they are provided
                  with many writing from the Faith relating to Convention, including a booklet titled National Convention: A
                  Statement and Compilation of Extracts from the Bahá’í Writings Prepared by the Research Department of the
                  Universal House of Justice. Election materials, the National Spiritual Assembly’s Annual Report, and a
                  preliminary Convention agenda also are provided to delegates. On the morning National Convention begins, a
                  deepening for delegates on the significance and purpose of the National Convention is facilitated by members of
                  the Continental Board of Counselors.
                </p>
                <p>
                  <strong>Q. Who may speak at National Convention?</strong> <br />
                  A. The privilege of the floor is extended to delegates, members of the Continental Board of Counselors, and
                  members of the National Spiritual Assembly. Also able to speak when invited to are Bahá’ís who deliver reports,
                  such as representatives of the Board of Trustees for Huqúqu’lláh, or possess information that will inform the
                  delegates’ consultation, such as a visiting Counselor member of the International Teaching Center.
                </p>
                <p>
                  <strong>Q. Who may serve as a National Convention officer?</strong> <br />
                  A. Any delegate present for the proceedings may serve as Convention chair or secretary. The body of delegates
                  elects the officers by secret ballot in the opening session of the Convention.
                </p>
                <p>
                  <strong>Q. How is the National Convention agenda decided?</strong> <br />
                  A. A preliminary agenda, guided by the purposes of National Convention and themes of the current Plan, is
                  presented by the National Spiritual Assembly to the delegates. Delegates are free to propose changes to the
                  agenda and have them adopted by the body of delegates.
                </p>
                <p>
                  <strong>Q. How is consultation conducted at National Convention?</strong> <br />
                  A. The exact manner of consultation, such as how long each delegate may speak or whether preference will be
                  given to delegates who have not yet spoken, is decided by the body of delegates at the beginning of Convention.
                  Each delegate has a number and raises that number to be recognized by the Convention secretary. The secretary
                  lists the delegates in order of recognition and that list can be seen by all on monitors placed on the wall
                  above the stage. When the time is near for a delegate to speak, he or she moves to one of the microphones on the
                  stage and awaits his or her turn. When delegates speak they may offer insights on the topic of consultation for
                  that time period and offer a suggestion or recommendation to the National Spiritual Assembly for its
                  consideration. Suggestions require no vote of the body of delegates. A recommendation that receives a second is
                  consulted on specifically and the body of delegates decides by show of hands whether to pass it along to the
                  National Assembly. When a recommendation is proposed, delegates who wish to speak to that recommendation are
                  listed and come to a microphone in the order they were recognized.
                </p>
                <p>
                  <strong>Q. How is the National Spiritual Assembly elected?</strong> <br />
                  A. On Saturday morning of National Convention, the delegates gather — in a session monitored by the members of
                  the Continental Board of Counselors present and witnessed by registered non-delegates — to vote prayerfully by
                  secret ballot for the nine members of the National Spiritual Assembly. A ballot that contains more or fewer than
                  nine names is invalid. A vote for a person not eligible for election is not counted. Delegates who cannot attend
                  Convention may vote by absentee ballot. The nine individuals receiving the highest number of votes serve on the
                  National Assembly for one year.
                </p>
                <p>
                  <strong>Q. How are National Spiritual Assembly election results reported at National Convention?</strong> <br />
                  A. The manner of reporting the results, beyond the names of the nine elected members of the National Spiritual
                  Assembly, is decided by the body of delegates on the first day of Convention.
                </p>
              </div>
            )}
          </Col>
          <Col sm={12} md={4}>
            <div className="grey-box">
              <h3>Resources </h3>
              <p>
                <a href="https://s3.amazonaws.com/bahai-messages/nsa/2017/20170429_results-election-national-spiritual-assembly.pdf">
                  Election Announcement of the National Spiritual Assembly
                </a>
              </p>
              <p>
                <a href="https://s3.amazonaws.com/natcon2017.bahai.us/docs/20170419_UHJ-ridvan-2017-message_english.pdf">
                  Riḍván 2017 Message from the Universal House of Justice
                </a>
              </p>
              <p>
                <a href="https://s3.amazonaws.com/natcon2017.bahai.us/docs/2017-NSA-annual-report.pdf">
                  Riḍván 2017 Annual Report
                </a>
              </p>
              <p>
                <a href="https://s3.amazonaws.com/natcon2017.bahai.us/docs/20170429-109th-US-Bahai-national-convention-message-from-delegates.pdf">
                  109th US Bahá’í National Convention message from delegates (PDF)
                </a>
              </p>
              <p>
                <a href="https://s3.amazonaws.com/natcon2017.bahai.us/docs/20170429-109th-US-Bahai-national-convention-message-from-delegates.docx">
                  109th US Bahá’í National Convention message from delegates (DOC)
                </a>
              </p>
              <p>
                <a href="https://s3.amazonaws.com/bahai-messages/uhj/2017/20170511_UHJ-Response-to-109th-Convention-Delegates-Letter.pdf">
                  Supreme Body Responds to Convention Delegates (PDF)
                </a>
              </p>
              <p>
                <a href="https://s3.amazonaws.com/bahai-web/tab/forms/NatCon2017-fund-devlopment-brochure.pdf">
                  National Treasurer’s Report (Brochure)
                </a>
              </p>
              <p>
                <a href="https://s3.amazonaws.com/bahai-web/tab/forms/NatCon2017-treasurers-presentation.pdf">
                  National Treasurer’s Report (Full Presentation)
                </a>
              </p>
              <h4>National Convention Coverage Archives</h4>
              <ul className="plain-list">
                <li>
                  <Link to="./natcon-2016">2016</Link>
                </li>
              </ul>
              <h4>Supreme Body on Social Action and Public Discourse:</h4>
              <p>
                <a href="https://s3.amazonaws.com/bahai-messages/nsa/2017/20170503_NSA-on_UHJ-April-27-Social-Action-Letter.pdf">
                  Cover Letter from the National Spiritual Assembly (PDF)
                </a>
              </p>
              <p>
                <a href="https://s3.amazonaws.com/bahai-messages/uhj/2017/20170427_UHJ-guidance-on-social-action-and-public-discourse.pdf">
                  Social Action and Public Discourse Letter from the Universal House of Justice (PDF)
                </a>
              </p>
              <h4>Secretary’s Report</h4>
              <iframe
                title="Secretary’s Report"
                src="https://player.vimeo.com/video/216093190"
                width="305"
                height="172"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              ></iframe>
              <a
                href="https://player.vimeo.com/external/216093190.hd.mp4?s=4315e88a64c3a6337bd7a434a980c6442526556e&profile_id=174&download=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                download video
              </a>
              <h4>Treasurer’s Report</h4>
              <iframe
                title="Treasurer’s Report"
                src="https://player.vimeo.com/video/215356029"
                width="305"
                height="172"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              ></iframe>
              <a
                href="https://player.vimeo.com/external/215356029.hd.mp4?s=f72f4631a1384277339bd9798835f1a7afe57f2b&profile_id=174&download=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                download video
              </a>
              <hr />
              <iframe
                title="other sound clips"
                width="300px"
                height="1150px"
                scrolling="no"
                frameBorder="no"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/320747982%3Fsecret_token%3Ds-mQHzL&amp;color=5db2ce&amp;auto_play=false&amp;hide_related=false&amp;show_comments=false&amp;show_user=false&amp;show_reposts=false"
              ></iframe>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default NatCon2019;
