import React, { useState, useEffect } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import { Link } from 'react-router-dom';
import WebsiteUpdatesListOrRecentEndpoint from './Utilities/WebsiteUpdatesListOrRecentEndpoint';
import { Row, Col } from 'react-flexbox-grid';
import DataCleansing from './DataCleansing';

const WebsiteUpdatesList = (props) => {
  const [websiteData, setWebsiteData] = useState([
    {
      base__title: { value: '' },
      base__datepublished: { value: '' },
      base__body: { value: '', resolvedData: { html: '' } },
      url_slug: { value: '' },
      feature_image: {
        value: [
          {
            url: '',
            description: '',
          },
        ],
      },
    },
  ]);
  useEffect(() => {
    const subscription = WebsiteUpdatesListOrRecentEndpoint(props, setWebsiteData);
    return () => {
      subscription.unsubscribe();
    };
  }, [props]);

  return (
    <div>
      <h1>Website Updates</h1>
      {websiteData.map((item, key) => {
        return (
          <div key={key} className="top-margin">
            <Row>
              <Col sm={2}>
                <Link className="plain-link news-sidebar-titles" to={{ pathname: `/website-updates/${item.url_slug.value}` }}>
                  <img
                    className="thumbnail-img website-updates-img"
                    key={key}
                    src={item.feature_image.value[0].url}
                    alt={item.feature_image.value[0].description}
                  />
                </Link>
              </Col>
              <Col sm={10}>
                <h3>
                  <Link className="plain-link news-sidebar-titles" to={{ pathname: `/website-updates/${item.url_slug.value}` }}>
                    {item.base__title.value}
                  </Link>
                </h3>
                <p className="very-small-top-padding">
                  {item.base__datepublished.value !== ''
                    ? new Date(item.base__datepublished.value).toLocaleDateString('en-US', props.context.dateOptions)
                    : null}
                </p>
                <div className="very-small-top-padding">{item.base__body.value !== '' ? DataCleansing(item) : null}</div>
              </Col>
            </Row>
            {item.base__body.value !== '' ? <hr /> : null}
          </div>
        );
      })}
    </div>
  );
};

export default withContext(WebsiteUpdatesList);
