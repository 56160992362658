import React, { FunctionComponent, CSSProperties } from 'react';
import type { HomeCarousel } from '../Kentico/DigitalDesign/home_carousel';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import classNames from 'classnames';
import { replaceBaseUrlLinks } from '../Utilities/UtilityFunctions';

const getTitleColorAndHeaderOptions = (textColor: string): HTMLReactParserOptions => ({
  replace: (domNode) => {
    if (domNode.name === 'p') {
      domNode.attribs = { ...domNode.attribs, style: `color: ${textColor}`, class: `carousel-header-text` };
      return domNode;
    }
  },
});

const getTextColorOptions = (textColor: string): HTMLReactParserOptions => ({
  replace: (domNode) => {
    if (domNode.name === 'p') {
      domNode.attribs = { ...domNode.attribs, style: `color: ${textColor}`, class: `carousel-body-text` };
      return domNode;
    }
    if (domNode.name === 'a') {
      domNode.attribs = { ...domNode.attribs, style: `color: ${textColor}`, class: `carousel-body-text` };
      return domNode;
    }
  },
});

const HomeCarouselItem: FunctionComponent<{ homeCarousel: HomeCarousel }> = ({ homeCarousel }) => {
  const textColor = homeCarousel.overlayTextColor.value ?? '#ffffff';

  const setTitleColorAndHeaderOptions = getTitleColorAndHeaderOptions(textColor);
  const titleRawHtml = homeCarousel.formattedtitleTitle.resolveHtml();
  const title = parse(titleRawHtml, setTitleColorAndHeaderOptions);

  const setTextColor = getTextColorOptions(textColor);
  const bodyRawHtml = replaceBaseUrlLinks(homeCarousel.body.resolveHtml());

  const body = parse(bodyRawHtml, setTextColor);

  const displayOnLeft = homeCarousel.overlayLocation.value[0].codename === 'left';

  const textBoxStyle: CSSProperties = {
    backgroundColor: homeCarousel.overlayBackgroundColor.value as string & {},
    opacity: (homeCarousel.overlayBackgroundOpacityPercent.value ?? 80) / 100,
  };

  const imageClassNames = classNames({
    'home-carousel-image': true,
    'home-carousel-image-left': displayOnLeft,
    'home-carousel-image-right': !displayOnLeft,
  });

  const textBoxClassNames = classNames({
    'home-carousel-textbox': true,
    'home-carousel-textbox-left': displayOnLeft,
    'home-carousel-textbox-right': !displayOnLeft,
  });

  return (
    <div>
      <div className="home-carousel-wrapper">
        <div className="home-carousel-image-wrapper">
          <img src={homeCarousel.image.value[0].url} className={imageClassNames} alt="Carousel Placeholder" />
        </div>
        <div className={textBoxClassNames} style={textBoxStyle}>
          <div className="home-carousel-content">
            {title}
            {body}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeCarouselItem;
