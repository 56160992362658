//Use class NOT className for css since this directly uses html
export default function (data) {
  if (data.citation) {
    return `<div class="citation">${data.citation.resolveHtml()}</div>`;
  } else if (data.head_of_the_faith_quote) {
    return `<div class="glsa-uhj-quote">${data.head_of_the_faith_quote.resolveHtml()}</div><div class="citation">${data.inner_citation.resolveHtml()}</div>`;
  } else if (data.nsa_quote) {
    return `<div class="glsa-nsa-quote">${data.nsa_quote.resolveHtml()}</div>`;
  }
}
