import React from 'react';
import { Row, Col } from 'react-flexbox-grid';

const MyProfileComp = ({ details }) => {
  return (
    <div>
      <h4>Contact Information</h4>
      <Row className="small-bottom-padding">
        <Col sm={4}>
          <p className="faded-text small-text">First Name</p>
          <p>
            <strong>{details.firstName}</strong>
          </p>
        </Col>
        <Col sm={4}>
          <p className="faded-text small-text">Middle Name</p>
          <p>
            <strong>{details.middleName}</strong>
          </p>
        </Col>
        <Col sm={4}>
          <p className="faded-text small-text">Last Name</p>
          <p>
            <strong>{details.lastName}</strong>
          </p>
        </Col>
      </Row>
      <Row className="small-bottom-padding">
        <Col sm={4}>
          <p className="faded-text small-text">Primary Email</p>
          <p className="wrap-long-words">
            <strong>{details.email}</strong>
          </p>
        </Col>
        <Col sm={4}>
          <p className="faded-text small-text">Alternative Email</p>
          <p className="wrap-long-words">
            <strong>{details.alternateEmail}</strong>
          </p>
        </Col>
      </Row>
      <Row className="small-bottom-padding">
        <Col sm={4}>
          <p className="faded-text small-text">Cell Phone</p>
          <p>
            <strong>{details.cellPhone}</strong>
          </p>
        </Col>
        <Col sm={4}>
          <p className="faded-text small-text">Home Phone</p>
          <p>
            <strong>{details.homePhone}</strong>
          </p>
        </Col>
        <Col sm={4}>
          <p className="faded-text small-text">Work Phone</p>
          <p>
            <strong>{details.workPhone}</strong>
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default MyProfileComp;
