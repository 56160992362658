import React, { FunctionComponent } from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const PasswordViewControl: FunctionComponent<{
  showPassword: boolean;
  setShowPassword: (newValue: boolean) => void;
  datacy: string;
}> = ({ showPassword, setShowPassword, datacy }) => (
  <InputAdornment position="end">
    <IconButton
      aria-label="toggle password visibility"
      data-cy={datacy}
      onMouseDown={() => setShowPassword(true)}
      onMouseUp={() => setShowPassword(false)}
      onMouseLeave={() => setShowPassword(false)}
      onTouchStart={() => setShowPassword(true)}
      onTouchEnd={() => setShowPassword(false)}
      onTouchCancel={() => setShowPassword(false)}
      onTouchMove={() => setShowPassword(false)}
      edge="end"
      tabIndex="-1"
    >
      {showPassword ? <Visibility data-cy={datacy + '_visibility'} /> : <VisibilityOff data-cy={datacy + '_visibility_off'} />}
    </IconButton>
  </InputAdornment>
);
export default PasswordViewControl;
