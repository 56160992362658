import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText, Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';
import withContext from '../ContextAPI/Context_HOC';

class GlsaDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      glsadata: {
        chapterTerms: [],
      },
    };
  }

  updateSelected(selectedIndex) {
    this.setState({ selected: selectedIndex });
  }

  componentDidMount() {
    this.props.context.glsaProxy.getChapters().subscribe((x) => {
      this.setState({ glsadata: x });
    });
  }

  render() {
    const { selected, glsadata } = this.state;
    return (
      <List>
        {glsadata.chapterTerms.slice().map((chapterTerm, index) => (
          <span key={index}>
            <ListItem data-cy="glsadrawerlist" onClick={() => this.updateSelected(index)} selected={selected === index}>
              <Link
                data-cysublev="subdrawerlink"
                data-cy={`glsadrawerlink${index}`}
                to={glsadata.urlsByCodename[chapterTerm.codename].url}
              >
                <ListItemText className="subdrawer-list-item" primary={glsadata.urlsByCodename[chapterTerm.codename].title} />
              </Link>
            </ListItem>
            <Divider />
          </span>
        ))}
      </List>
    );
  }
}

GlsaDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withContext(GlsaDrawer);
