import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Grid } from 'react-flexbox-grid';
import ContextProvider from './ContextAPI/ContextProvider';
import Routers from '../src/Routers/Routers';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/css/App.css';
import FeatureFlagProvider from './FeatureFlag/FeatureFlagProvider';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import OneSignalHandler from './OneSignal/OneSignalHandler';

ReactGA.initialize(process.env.REACT_APP_GA_UA, {
  debug: false,
  titleCase: false,
  gaOptions: {
    userId: '',
  },
});

const history = createBrowserHistory();
history.listen((location) => {
  const page = location.pathname + location.search;
  ReactGA.set({ page: page });
  ReactGA.pageview(page);
});

ReactDOM.render(
  <ContextProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FeatureFlagProvider>
        <Router basename="/community" history={history}>
          {' '}
          {/* This line <Router... is updated at build time by ModifyHomepage.ps1 */}
          <Grid fluid className="App">
            <Routers />
            <OneSignalHandler />
          </Grid>
        </Router>
      </FeatureFlagProvider>
    </LocalizationProvider>
  </ContextProvider>,

  document.getElementById('root')
);
