import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import React, { useState } from 'react';
import { Column } from 'material-table';
import { DatagridPropsCustom } from './interfaces/datagrid-props-custom';
import { HuquqResponse } from './HuquqRep';

type HuquqRepMobileTableProps = {
  columns: { columns: (Column<HuquqResponse> & DatagridPropsCustom)[] };
  filteredData: HuquqResponse[];
  defaultmessage: JSX.Element;
};
export default function HuquqRepMobileTable(props: HuquqRepMobileTableProps) {
  const { columns, filteredData, defaultmessage } = props;
  const [numberOfRows, setNumberOfRows] = useState(10);
  if (columns.columns.length === 0) {
    return null;
  }
  const displayMessage = () => {
    if (filteredData.length === 0) {
      return <div className="message-red">{defaultmessage}</div>;
    }
    return null;
  };
  return (
    <>
      {displayMessage()}
      <Table key={1}>
        <Thead>
          <Tr>
            {columns?.columns.length > 0 &&
              columns?.columns.map((column, index) => {
                return <Th key={(column.field as string) + index + 'mobile-table'}>{column.title}</Th>;
              })}
          </Tr>
        </Thead>
        <Tbody>
          {filteredData
            ?.filter((a, index) => index < numberOfRows)
            .map((dataRow, indexDataRow) => {
              return (
                <Tr key={(dataRow.name as string) + indexDataRow + 'mobile-table-tr'}>
                  {columns?.columns?.map((col, index) => (
                    <Td key={(dataRow.name as string) + index + (col.title as string) + 'mobile-table-td'}>
                      {col.render ? col.render(dataRow, 'row') : dataRow[col.field as keyof HuquqResponse]}
                    </Td>
                  ))}
                </Tr>
              );
            })}
        </Tbody>
      </Table>

      <div className="row">
        <div className="col-xl-12">
          <div className="spaced">
            {numberOfRows <= filteredData.length && (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setNumberOfRows(numberOfRows + 10);
                }}
                className="primary-button w-100"
              >
                {' '}
                Load More{' '}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
