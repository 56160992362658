import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import withContext from '../ContextAPI/Context_HOC';

const FAQ = (props) => {
  const [linkdisplay, setLinkdisplay] = useState([]);
  const [faqItems, setFaqItems] = useState([]);
  const [faqProxy] = useState(props.context.faqProxy);

  useEffect(() => {
    const observable = faqProxy.getItems();
    const subscription = observable.subscribe((x) => setFaqItems(x));
    return () => {
      subscription.unsubscribe();
    };
  }, [faqProxy]);

  useEffect(() => {
    if (faqProxy) {
      let links = [];
      let count = 0;
      let totaltaxonomies = [];
      if (faqItems) {
        faqItems.forEach((x) => {
          for (let i = 0; i < x.faq_taxonomy.value.length; i++) {
            if (totaltaxonomies.indexOf(x.faq_taxonomy.value[i].name) === -1) {
              totaltaxonomies.push(x.faq_taxonomy.value[i].name);
            }
          }
        });

        let totaltaxonomiesSorted = totaltaxonomies.sort();
        totaltaxonomiesSorted.forEach((x) => {
          links.push(
            <h3 className={'large-top-padding'} key={count}>
              {x}
            </h3>
          );

          count++;
          faqItems.forEach((y) => {
            for (let i = 0; i < y.faq_taxonomy.value.length; i++) {
              if (x === y.faq_taxonomy.value[i].name) {
                links.push(
                  <li className="indented-list-item" key={count}>
                    <Link
                      data-cy={`faqlink${count}`}
                      to={{
                        pathname: `/faq/${y.faq_taxonomy.value[i].codename}/${y.url_slug.value}`,
                      }}
                    >
                      {y.base__title.value + ` (${y.faq_taxonomy.value[i].name})`}{' '}
                    </Link>
                  </li>
                );
                count++;
              }
            }
          });
        });

        setLinkdisplay(links);
      }
    }
  }, [faqItems]);

  return (
    <div>
      <h1>Frequently Asked Questions</h1>
      <ul className="plain-list">{faqProxy && linkdisplay}</ul>
    </div>
  );
};

export default withContext(FAQ);
