import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Tooltip } from '@material-ui/core';
import { Row, Col } from 'react-flexbox-grid';
import Breadcrumbs from '../Breadcrumbs';
import classNames from 'classnames';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import withContext from '../../ContextAPI/Context_HOC';
import AdminTools from './AdminTools';
import Search from './Search';
import FeedbackLink from './FeedbackLink';
import WebsiteUpdatesLink from './WebsiteUpdatesLink';
import ProfileNavBar from './ProfileNavBar';
import PancakeDrawer from './PancakeDrawer';
import ErrorBoundary from '../../Error/ErrorBoundary';
import AlertBanner from './AlertBanner';

const drawerWidth = 240;
const gregorianDate = new Date().toLocaleDateString('en-US', {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
});
const styles = (theme) => ({
  root: {
    width: '100%',
  },
  nested: {
    paddingLeft: theme.spacing(4),
    fontSize: theme.fontSize * 1,
  },
  drawerWidth: {
    width: '300px',
  },
  toolBar: {
    minHeight: '80px',
  },
  appBar: {
    backgroundImage: 'linear-gradient(#01252b, #19464A)',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    backgroundImage: 'linear-gradient(#01252b, #19464A)',
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  left: {
    textAlign: 'left',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  leftShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    paddingBottom: '0px',
    paddingTop: '5px',
    fontSize: '2.3rem',
  },
  breadcrumbsShow: {
    marginTop: '-20px',
    marginBottom: '10px',
  },
  dateline: {
    marginTop: '80px',
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
});

class NavBarOld extends React.Component {
  constructor(props) {
    super(props);
    this.pancakeDrawerOpenerButtonRef = React.createRef();
    this.state = {
      open: false,
      anchorEl: null,
      mobileMoreAnchorEl: null,
      bahaiDate: null,
    };
  }

  componentDidUpdate(prepProps) {
    if (prepProps.context.isDrawerOpen && !this.props.context.isDrawerOpen) {
      this.pancakeDrawerOpenerButtonRef.current.focus();
    }
  }

  handleDrawerOpen = () => {
    this.props.context.toggleDrawerMenu();
  };
  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };
  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleMobileMenuClose);
    this.calendarDateSubscription = this.props.context.calendarService
      .getToday()
      .subscribe((res) => this.setState({ bahaiDate: res.bahaiDate }));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleMobileMenuClose);
    if (this.calendarDateSubscription) {
      this.calendarDateSubscription.unsubscribe();
    }
  }

  render() {
    const { classes } = this.props;
    const { open } = this.state;
    const displayBreadcrumbs = !!this.props.context.displayBreadcrumbs;
    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.props.context.isDrawerOpen,
          })}
        >
          <Toolbar disableGutters={!open} className={classes.toolBar}>
            <Row className={'center' && classes.root}>
              <Col
                lg={1}
                md={1}
                sm={1}
                xs={1}
                onClick={this.handleDrawerOpen}
                className={classNames(classes.menu, 'pointer-finger inline-vertical-center')}
              >
                <Tooltip title="Menu" aria-label="menu" role="menu">
                  <IconButton
                    data-cy="pancake-icon"
                    color="inherit"
                    aria-label="Open Menu"
                    className={classNames(classes.menuButton, open && 'hide')}
                    ref={this.pancakeDrawerOpenerButtonRef}
                  >
                    <MenuIcon data-cy="drawer-menu" fontSize="large" />
                  </IconButton>
                </Tooltip>
                <p className="white-text xs-text">MENU</p>
              </Col>
              <Col lg={2} md={1} sm={1} xs={2} className="inline-vertical-center">
                <span className="mobile-hide">
                  <ErrorBoundary>
                    <Search />
                  </ErrorBoundary>
                </span>
                <Link to="/search-results" className="navbarItem center mobile-show small-top-padding">
                  <SearchIcon className="white-text" fontSize="large" />
                  <p className="white-text xs-text">SEARCH</p>
                </Link>
              </Col>
              <Col lg={6} md={6} sm={6} xs={1}>
                <div className="flexNavBar mobile-starlogo">
                  <Link className="plain-link" to="/">
                    <span data-cy="logo" aria-label="Home" role="button" className="starlogo" />
                    <p className="white-text xs-text mobile-show tablet-hide">HOME</p>
                  </Link>
                  <Link className="plain-link" to="/">
                    <h3 className="logo-text tablet-show logo-text-header">Bahá’ís of the United States</h3>
                  </Link>
                </div>
                <ErrorBoundary>
                  <p className="white-text tablet-show center logo-text-description">
                    {gregorianDate}
                    {this.state.bahaiDate ? (
                      <>
                        |
                        <span title={'Based on sunset at ' + this.state.bahaiDate.basedOnLocation}>
                          {this.state.bahaiDate.basedOnLocation !== 'home address' ? <>*&nbsp;</> : null}
                          {this.state.bahaiDate.day}&nbsp;
                          {this.state.bahaiDate.monthExtendedAsciiAndStylizedQuotes}&nbsp;
                          {this.state.bahaiDate.year} B.E.
                        </span>
                      </>
                    ) : null}
                    |{' '}
                    <a
                      className="white-text"
                      href="https://bahai-messages.s3.amazonaws.com/uhj/2014/1211_UHJ_50-Yr_Table_of_Dates/20141211_UHJ_50-Yr_Table_of_Dates_(English).pdf"
                    >
                      50-Year Badíʻ Calendar
                    </a>
                  </p>
                </ErrorBoundary>
              </Col>
              <Col lg={3} md={4} sm={4} xs={7} className="inline-vertical-center flexNavBar">
                <ErrorBoundary>
                  <FeedbackLink />
                </ErrorBoundary>
                <ErrorBoundary>
                  <WebsiteUpdatesLink />
                </ErrorBoundary>
                <ErrorBoundary>
                  <AdminTools />
                </ErrorBoundary>
                <ErrorBoundary>
                  <ProfileNavBar />
                </ErrorBoundary>
              </Col>
            </Row>
          </Toolbar>
        </AppBar>
        <AlertBanner />
        <div
          className={classNames(
            classes.left,
            { [classes.leftShift]: this.props.context.isDrawerOpen },
            { [classes.breadcrumbsShow]: displayBreadcrumbs },
            { hide: !displayBreadcrumbs }
          )}
        >
          <Breadcrumbs />
        </div>
        <ErrorBoundary>
          <PancakeDrawer />
        </ErrorBoundary>
        <div
          className={classNames(
            classes.left,
            { [classes.leftShift]: this.props.context.isDrawerOpen },
            { hide: displayBreadcrumbs },
            { show: !displayBreadcrumbs }
          )}
        ></div>
      </div>
    );
  }
}

NavBarOld.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withRouter(withContext(withStyles(styles, { withTheme: true })(NavBarOld)));
