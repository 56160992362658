import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Tooltip } from '@material-ui/core';
import { NewReleases } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';

const WebsiteUpdatesLink = () => {
  return (
    <Link to="/website-updates">
      <Tooltip title="What’s New" aria-label="What’s New" role="link">
        <div className="navbarItem small-top-padding">
          <IconButton className="white navbarIcon">
            <NewReleases />
          </IconButton>
          <p className="white-text xs-text">UPDATES</p>
        </div>
      </Tooltip>
    </Link>
  );
};
export default withRouter(WebsiteUpdatesLink);
