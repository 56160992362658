import React from 'react';

function InquiryServices() {
  return (
    <div>
      <h1>Inquiry Services and Bahá’í Information</h1>
      <p>
        <a href="https://ivrweb.usbnc.org:443/" target="_blank" rel="noopener noreferrer">
          Login
        </a>{' '}
        to Seeker Response System
      </p>
      <hr />
      <p>
        The National Inquiry Services desk (also known as “Baha’i Information”) operates under the Office of Communications and
        coordinates responses to interested souls who submit web inquiries through the public websites,{' '}
        <a href="https://www.bahai.us" target="_blank" rel="noopener noreferrer">
          bahai.us
        </a>
        , and{' '}
        <a href="https://www.bahaiteachings.org" target="_blank" rel="noopener noreferrer">
          bahaiteachings.org
        </a>
        ; call the toll free phone line at (800) 22-UNITE (<a href="tel:800-228-6483">800-228-6483</a>); or fill out interest
        forms at the Bahai House of Worship.
      </p>
      <p>
        Information provided by each inquirer is maintained in the seeker response system / SRS database and enables direct follow
        up efforts at the regional and cluster levels.
      </p>
      <p>
        To learn more about how the system operates within your region, contact your Regional Baha’i Council or National Inquiry
        Services at (800) 22-UNITE (<a href="tel:800-228-6483">800-228-6483</a>) or email{' '}
        <a href="mailto:Bahai-info@Bahai.us">Bahai-info@Bahai.us</a>.
      </p>
    </div>
  );
}

export default InquiryServices;
