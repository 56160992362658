import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';
import { AxiosResponse } from 'axios';

/**
 * @typedef TokenResponse
 * @property {string} data The token.
 */

/**
 * Get App Token for password resets
 * @return {Promise<TokenResponse>} A promise to the token response.
 */
export default function createAppToken() {
  return GlobalAPICall.post(
    `${process.env.REACT_APP_AUTH_API_URL}/api/AppToken`,
    {
      UserName: 'Bahai Site Admin',
      Password: 'Ba#ai$it3A4p9z',
      AppName: 'BahaiSite',
    },
    {
      headers: {
        'Content-type': 'application/json',
      },
    }
  )
    .then((res: AxiosResponse<string>) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject({
        message: 'Our services are currently unavailable. Please come back later.',
        status: err.response?.status ?? err,
      });
    });
}
