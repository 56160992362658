import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RightArrow from '../assets/img/rightArrow-map.png';
import Legend from '../assets/img/legend-map.png';
import Location from '../assets/img/location-map.png';
import SearchFor from '../assets/img/searchfor-map.png';
import { Row, Col } from 'react-flexbox-grid';

const MapInstruction = () => {
  return (
    <div className="full-width">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography component={'span'}>
            <h4>My Profile Map Instructions</h4>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component={'span'}>
            <p>Map centers on your Bahá’í Locality at start.</p>
            <p>
              <strong>Pan the map</strong> by dragging with a mouse.
            </p>
            <p>
              <strong>Zoom in/out</strong> by scrolling the mouse or clicking the ‘Plus/Minus’ symbols.
            </p>
            <br />
            <Row>
              <Col sm={3} md={2}>
                <img src={RightArrow} alt="Right Arrow" className="full-width" />
              </Col>
              <Col sm={9} md={10}>
                <p>To understand the symbols on the map, go to the Legend.</p>
                <p>Click the arrows on the upper left corner of the map.</p>
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={5} md={4} lg={3}>
                <img src={Legend} alt="Legend" className="full-width" />
              </Col>
              <Col sm={7} md={8} lg={9}>
                <p>
                  Click on the name of a map layer to <strong>see the symbols.</strong> You may{' '}
                  <strong>turn layer symbols on and off</strong> by clicking the check marks in the boxes next to the layer names.
                </p>
                <br />
                <p>Note that Bahá’í Localities do not display when zoomed out too far in order to avoid slow draw times.</p>
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={5} md={4}>
                <img src={Location} alt="Location" className="full-width" />
              </Col>
              <Col sm={7} md={8}>
                <p>
                  Click on the map to <strong> see more information about a location.</strong>
                </p>
                <p>The popup window displays three pages of information for this locality:</p>
                <p className="left-margin"> 1) Local Community (with contact information for LSA or Registered Group);</p>
                <p className="left-margin"> 2) Cluster (with contact information for Auxiliary Board Members);</p>
                <p className="left-margin"> 3) Electoral Unit (with some information about the Unit).</p>
                <p>
                  On each page of the popup window, you can <strong>see the full extent</strong> of the feature by clicking on
                  ‘Zoom to’.
                </p>
                <br />
              </Col>
            </Row>
            <p>
              <strong>Bahá’í Center information </strong>is also available when clicking on the green diamond shapes.
            </p>
            <p>To close the popup window, click on the ‘X’ at the upper right corner of the popup.</p>
            <br />
            <Row>
              <Col sm={5} md={4}>
                <img src={SearchFor} alt="Search For a Location" className="full-width" />
              </Col>
              <Col sm={7} md={8}>
                <p>
                  <strong>Search for a location</strong>
                </p>
                <p>
                  In the upper right corner box,
                  <strong> enter address or placeinformation. </strong>
                </p>
              </Col>
            </Row>
            <p>
              This can be a full or partial address, just the city name, a zip code, or a state. The map will center on the search
              result.
            </p>
            <p>Click on the new location to get the information popup windows.</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />
    </div>
  );
};
export default MapInstruction;
