import { map } from 'rxjs/operators';

export default class HuquqService {
  constructor(authService, apiCaller, apiUrl) {
    this.authService = authService;
    this.apiCaller = apiCaller;
    this.apiUrl = apiUrl;
    this.getDeputyTrustees = this.getDeputyTrustees.bind(this);
    this.getRepresentatives = this.getRepresentatives.bind(this);
  }

  getDeputyTrustees() {
    const token = this.authService.getToken();
    const observable = this.apiCaller
      .getObservable(this.apiUrl + `/api/huququllah/DeputyTrustees`, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        map((x) => {
          return x.data.contents;
        })
      );

    return observable;
  }

  getRepresentatives(region) {
    const regionUrlPart = region ? `/${region}` : '';
    const token = this.authService.getToken();
    const observable = this.apiCaller
      .getObservable(this.apiUrl + `/api/huququllah/Representatives` + regionUrlPart, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        map((x) => {
          return x.data.contents;
        })
      );

    return observable;
  }
}
