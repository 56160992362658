import React, { useCallback, useState, useEffect } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import { InputLabel, FormControl, OutlinedInput, CircularProgress } from '@material-ui/core';
import PasswordViewControl from '../Utilities/PasswordViewControl';
import useForm from '../Utilities/useForm';
import { passwordValidator, getMustMatchOtherFieldValidator } from '../Utilities/useFormValidations';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';

const validationSchema = {
  oldPassword: { required: true },
  newPassword: {
    required: true,
    validator: passwordValidator,
  },
  confirmPassword: {
    required: true,
    validator: getMustMatchOtherFieldValidator('newPassword', 'This field must match the "New Password" field'),
  },
};

const initialState = {
  oldPassword: { value: '' },
  newPassword: { value: '' },
  confirmPassword: { value: '' },
};

const ChangePassword = (props) => {
  const setPageTitle = props.setPageTitle;
  useEffect(() => setPageTitle('Change Your Password'), [setPageTitle]);
  const changePasswordProxy = props.context.changePasswordProxy;
  const [unrecoverableError, setUnrecoverableError] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCircularProgress, setShowCircularProgress] = useState(false);
  const [messageToUser, setMessageToUser] = useState(null);

  const submitCallback = useCallback(
    (state, processErrors) => {
      const data = {
        oldPassword: state.oldPassword.value,
        newPassword: state.newPassword.value,
        confirmPassword: state.confirmPassword.value,
      };

      setShowCircularProgress(true);
      setMessageToUser(null);

      const subscription = changePasswordProxy.set(data).subscribe({
        next: () => {
          setMessageToUser(
            <p data-cy="success-message" className="success-message wider">
              Successfully Updated!
            </p>
          );
          setShowCircularProgress(false);
        },
        error: (e) => {
          if (e?.response?.status === 400 && e?.response?.data) {
            const unprocessedErrors = processErrors(e.response.data);
            const message =
              unprocessedErrors !== '' ? unprocessedErrors : `Please check the highlighted field(s) and resolve the error(s).`;
            setMessageToUser(
              <p data-cy="unsuccess-message" className="error-message wider">
                {message}
              </p>
            );
            setShowCircularProgress(false);
          } else {
            setUnrecoverableError(true);
          }
        },
      });

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      return () => subscription?.unsubscribe();
    },
    [changePasswordProxy]
  );

  // Action to take if useForm determines form is invalid
  const onInvalidSubmitAttempt = useCallback(() => {
    setMessageToUser(
      <p data-cy="unsuccess-message" className="error-message wider">
        Please check the highlighted field(s) and resolve the error(s).
      </p>
    );
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const { state, handleValidationOnChange, handleOnSubmit } = useForm(
    initialState,
    validationSchema,
    submitCallback,
    onInvalidSubmitAttempt
  );

  return (
    <div>
      {unrecoverableError ? (
        <ErrorMessageToUser />
      ) : (
        <span>
          {showCircularProgress ? <CircularProgress className="loading-animation" size={40} /> : messageToUser}
          <div className="profile-box">
            <h3>Change your Password</h3>
            <div>
              <ul className="plain-list small-top-margin">
                <li>Passwords must be at least 8 characters long and contain at least 3 of the following:</li>
                <li>One lower case letter</li>
                <li>One UPPER case letter</li>
                <li>One number</li>
                <li>One special character such as !@$%^*</li>
                <li>It will NOT accept spaces or the following 3 characters # &amp; &lt;</li>
              </ul>
              <form noValidate autoComplete="off" className="small-bottom-margin">
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Current Password</InputLabel>
                  <OutlinedInput
                    required
                    type={showOldPassword ? 'text' : 'password'}
                    value={state.oldPassword.value}
                    name="oldPassword"
                    data-cy="oldPasswordField"
                    onChange={handleValidationOnChange}
                    endAdornment={
                      <PasswordViewControl
                        showPassword={showOldPassword}
                        setShowPassword={setShowOldPassword}
                        datacy="currentPasswordEye"
                      />
                    }
                    labelWidth={132}
                  />
                  {state.oldPassword.error && (
                    <p className="form-field-error" data-cy="oldPassword_field_error">
                      {state.oldPassword.error}
                    </p>
                  )}
                </FormControl>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password" className="small-top-margin">
                    New Password
                  </InputLabel>
                  <OutlinedInput
                    required
                    type={showNewPassword ? 'text' : 'password'}
                    value={state.newPassword.value}
                    name="newPassword"
                    className="small-top-margin"
                    data-cy="newPasswordField"
                    onChange={handleValidationOnChange}
                    endAdornment={
                      <PasswordViewControl
                        showPassword={showNewPassword}
                        setShowPassword={setShowNewPassword}
                        datacy="newPasswordEye"
                      />
                    }
                    labelWidth={108}
                  />
                  {state.newPassword.error && (
                    <p className="form-field-error" data-cy="newPassword_field_error">
                      {state.newPassword.error}
                    </p>
                  )}
                </FormControl>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password" className="small-top-margin">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    required
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={state.confirmPassword.value}
                    name="confirmPassword"
                    className="small-top-margin"
                    data-cy="confirmPasswordField"
                    onChange={handleValidationOnChange}
                    endAdornment={
                      <PasswordViewControl
                        showPassword={showConfirmPassword}
                        setShowPassword={setShowConfirmPassword}
                        datacy="confirmPasswordEye"
                      />
                    }
                    labelWidth={132}
                  />
                  {state.confirmPassword.error && (
                    <p className="form-field-error" data-cy="confirmPassword_field_error">
                      {state.confirmPassword.error}
                    </p>
                  )}
                </FormControl>
              </form>
            </div>
          </div>
          <div className="medium-top-margin end">
            <input onClick={handleOnSubmit} data-cy="submit" type="submit" value="Save" className="primary-button" />
          </div>
        </span>
      )}
    </div>
  );
};

export default withContext(ChangePassword);
