import React from 'react';

function Review() {
  return (
    <div>
      <h1>Review</h1>
      <p>
        The Office assists Bahá’í authors and artists in ensuring that what they publish and produce represents the Bahá’í Faith
        accurately and with dignity, and is timely. Review is carried out in a consultative and educational spirit.
      </p>
      <p>
        <a href="tel:847-425-7961">(847) 425-7961</a>
        <br />
        <a href="mailto:review@usbnc.org">review@usbnc.org</a>
      </p>
      <p>
        <em>
          Looking for{' '}
          <a target="_top" href="#rvw-faq">
            Frequently Asked Questions
          </a>
          ?
        </em>
      </p>
      <hr />
      <blockquote>
        <p>
          The Faith is as yet in its infancy. Despite its emergence from obscurity, even now the vast majority of the human race
          remains ignorant of its existence; moreover, the vast majority of its adherents are relatively new Bahá’ís. The change
          implied by this new stage in its evolution is that whereas heretofore this tender plant was protected in its obscurity
          from the attention of external elements, it has now become exposed. This exposure invites close observation, and that
          observation will eventually lead to opposition in various quarters. So, far from adopting a carefree attitude, the
          community must be conscious of the necessity to present a correct view of itself and an accurate understanding of its
          purpose to a largely skeptical public. A greater effort, a greater care must now be exercised to ensure its protection
          against the malice of the ignorant and the unwisdom of its friends.
        </p>
      </blockquote>
      <p>
        A Statement by the Universal House of Justice, dated December 29, 1988, published as{' '}
        <em>Individual Rights and Freedoms in the World Order of Bahá’u’lláh</em>, p. 19
      </p>
      <hr />
      <p>
        Bahá’í review is a requirement that was established by ’Abdu’l-Bahá as a &quot;temporary measure,&quot; and continued by
        the Guardian and the Universal House of Justice to the present time. Its purpose is to protect the Faith during this early
        stage in its development, when it can easily be misunderstood by the public. The Office assists Bahá’í authors and artists
        in ensuring that what they publish and produce represents the Bahá’í Faith accurately and with dignity, and is timely.
        Review is carried out in a consultative and educational spirit.
      </p>
      <p>The National Spiritual Assembly’s Office of Review is responsible for reviewing:</p>
      <ul>
        <li>literature for broad or national distribution, and</li>
        <li>all audio-visual materials and music (e.g., CDs, DVDs).</li>
      </ul>
      <p>
        <strong>Local Spiritual Assemblies are responsible for reviewing:</strong>
      </p>
      <ul>
        <li>literature that is for local use only, and </li>
        <li>
          all &quot;special materials&quot; produced by individuals residing in their jurisdiction (such as artwork and graphic
          creations, greeting cards, T-shirts, crafts, jewelry, simple calendars, etc.), be they for local or national
          distribution.
        </li>
      </ul>
      <p>
        In general, materials posted online, such as blog posts, personal websites, etc., are not subject to review. However, if
        an electronic publication rises to the level of formality of an electronic book or an article in an online journal, Bahá’í
        review will apply and the item should be forwarded to the Review Office prior to posting.
      </p>
      <p>
        Literature is to be reviewed by the National Spiritual Assembly in whose jurisdiction the work is to be first published. A
        manuscript should be reviewed before it is submitted to a publisher, a CD before it is produced.
      </p>
      <p>
        Further guidance on Bahá’í review is available in Chapter 9 of <strong>Guidelines for Local Spiritual Assemblies</strong>.
        Regarding use of photographs and portraits of ’Abdu’l-Bahá, see the two letters on this subject from the National
        Spiritual Assembly in 2011, dated August 16 and November 21.
      </p>
      <p>
        If an author or artist wishes to ask the Bahá’í Distribution Service to consider selling their item, they should contact
        BDS directly after the item has been published or produced, providing a copy of the review approval email.
      </p>
      <p>
        Questions may be directed to the Review Office at the Bahá’í National Center, by phone to{' '}
        <a href="tel:847-425-7961">(847) 425-7961</a> or by email to <a href="mailto:review@usbnc.org">review@usbnc.org</a>.
      </p>
      <hr />
      <h3 id="rvw-faq">Frequently Asked Questions</h3>
      <p>
        <strong>How do I submit literature or a CD for review?</strong>
      </p>
      <p>
        Literature should be sent as a document attached to an email, preferably as a Word document but other formats can be
        accommodated. Tracks for a CD can be sent as mp3 files attached to an email, or a CD or DVD can be sent via a free service
        such as Drop Box or You Send It.
      </p>
      <hr id="QA2" />
      <p>
        <strong>How long does review take?</strong>
      </p>
      <p>
        The Review Office aims to complete the review of a book-length manuscript within 2 - 3 months, an article within 4 -6
        weeks, and a CD within 4 weeks. This can vary depending on the complexity of the item. If an author or artist has a near
        publishing deadline, every effort is made to accommodate that schedule, but it is best to submit an item as early as
        possible to allow sufficient time for review.
      </p>
      <hr id="QA3" />
      <p>
        <strong>Are there guidelines for setting Sacred Text to music?</strong>
      </p>
      <p>
        Yes, the Guardian provided guidance with respect to not taking too much liberty in varying Sacred Text when using passages
        as lyrics to a song. Further information is available from the Review Office.
      </p>
    </div>
  );
}

export default Review;
