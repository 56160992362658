import React from 'react';
import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import { ArrowDownward } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import CustomSortFunc from './Utilities/TableFunctions/CustomSortFunc';

const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
};

function Counselors() {
  const useStyles = makeStyles({
    '@global tbody tr:nth-child(odd)': {
      background: '#cccccc',
    },
    '@global tbody tr:nth-child(even)': {
      background: 'white',
    },
  });
  const classes = useStyles();
  const { defaultmessage } = 'Currently loading data. Please wait several seconds!';

  const columns = [
    {
      title: 'Full Name',
      field: 'name',
      datacy: 'name',
      defaultSort: 'asc',
      customSort: (a, b) => CustomSortFunc(a, b),
    },
    {
      title: 'Email',
      field: 'email',
      render: (rowData) => <a href={'mailto:' + rowData.email}>{rowData.email}</a>,
    },
    {
      title: 'Phone',
      field: 'phone',
      render: (rowData) => <a href={'tel:' + rowData.phone}>{rowData.phone}</a>,
    },
  ];

  const data = [
    {
      name: 'Ayafor Temengye Ayafor',
      email: 'aayafor@cbcamericas.org ',
      phone: '',
    },
    {
      name: 'Mr. Louis Boddy',
      email: 'lboddy@cbcamericas.org',
      phone: '',
    },
    {
      name: 'Ms. Natasha Bruss',
      email: 'nbruss@cbcamericas.org',
      phone: '480-334-1581',
    },
    {
      name: 'Mrs. Farah Guchani-Rosenberg',
      email: 'fgrosenberg@cbcamericas.org',
      phone: '561-252-4945',
    },
    {
      name: 'Mrs. Sonlla Maria Heern',
      email: 'sonlla@cbcamericas.org',
      phone: '541-760-7189',
    },
    {
      name: 'Ms. Nwandi Lawson',
      email: 'nwandi@cbcamericas.org',
      phone: '404-822-6986',
    },
    {
      name: 'Dr. Borna Noureddin',
      email: 'bornan@cbcamericas.org',
      phone: '778-839-1234/604-447-1244',
    },
    {
      name: 'Mr. William Silva',
      email: 'bsilva@cbcamericas.org',
      phone: '',
    },
  ];
  return (
    <div data-cy="displaydirectory">
      <h1>Continental Counselors Residing in North America</h1>
      <h3>Current term of service concluding in 2025</h3>
      <MaterialTable
        title=""
        columns={columns}
        data={data}
        icons={tableIcons}
        className={classes}
        options={{
          thirdSortClick: false,
          paging: false,
          search: false,
          toolbar: false,
          headerStyle: {
            backgroundColor: '#8c8c8c',
            border: '1px #9b9e9e',
            fontWeight: 'bold',
          },
        }}
        localization={{
          body: {
            emptyDataSourceMessage: [defaultmessage],
          },
        }}
      />
    </div>
  );
}

export default Counselors;
