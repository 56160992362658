import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import TextField from '@material-ui/core/TextField';
import { MenuItem, InputLabel, FormControl, Select } from '@material-ui/core';
import { zipcodeFormat } from '../Utilities/GeneralRegEx';
import { BaseMyProfileState } from './EditContactInfo';

export const validationStateSchema = {
  mailingAddressCareOf: {
    required: false,
    validator: null,
  },
  mailingAddressLine1: {
    required: true,
    validator: null,
  },
  mailingAddressLine2: {
    required: false,
    validator: null,
  },
  mailingAddressLine3: {
    required: false,
    validator: null,
  },
  mailingAddressCity: {
    required: true,
    validator: null,
  },
  mailingAddressState: {
    required: true,
    validator: null,
  },
  mailingAddressZipCode: {
    required: true,
    validator: {
      regEx: zipcodeFormat,
      error: 'Invalid zip code format.',
    },
  },
  mailingAddressContinueWithUnverifiedAddress: {
    required: false,
    validator: null,
  },
};

export interface EditMailingAddressProps extends BaseMyProfileState {
  disable: boolean;
  isMailingAddressUnverified: boolean;
}

const EditMailingAddress = (props: EditMailingAddressProps) => {
  const details = props.details;
  const isMailingAddressUnverified = props.isMailingAddressUnverified;

  const selectMailList = details.stateOptions.value.map((item: { key: string; value: string }, key: string) => (
    <MenuItem key={key} value={item.key}>
      {item.value}
    </MenuItem>
  ));

  return (
    <div>
      <div>
        <h4 className="medium-left-padding small-top-padding">Mailing Address</h4>
        <Row className="small-bottom-padding">
          <Col sm={4}>
            <TextField
              inputProps={{ 'data-cy': 'inputmailingAddressCareOf', maxLength: '40' }}
              error={!!details.mailingAddressCareOf.error}
              id="mailingAddressCareOf"
              name="mailingAddressCareOf"
              label="In Care Of"
              aria-label="In Care Of"
              type="text"
              value={details.mailingAddressCareOf.value}
              onChange={props.handleChange}
              margin="dense"
              fullWidth={true}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Col>
        </Row>
        <Row className="small-bottom-padding">
          <Col sm={4}>
            <TextField
              required
              id="mailingAddressLine1"
              name="mailingAddressLine1"
              label="Street Address"
              aria-label="Street Address *"
              inputProps={{ 'data-cy': 'inputmailingAddressLine1', maxLength: '40' }}
              error={!!details.mailingAddressLine1.error || isMailingAddressUnverified}
              type="text"
              value={details.mailingAddressLine1.value}
              onChange={props.handleChange}
              margin="dense"
              fullWidth={true}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            {details.mailingAddressLine1.error && (
              <p className="form-field-error" data-cy="mailingAddressLine1_Required">
                {details.mailingAddressLine1.error}
              </p>
            )}
          </Col>
          <Col sm={4}>
            <TextField
              inputProps={{ 'data-cy': 'inputmailingAddressLine2', maxLength: '40' }}
              error={!!details.mailingAddressLine2.error || isMailingAddressUnverified}
              id="mailingAddressLine2"
              name="mailingAddressLine2"
              label="Address line two"
              aria-label="Address line two"
              type="text"
              value={details.mailingAddressLine2.value}
              onChange={props.handleChange}
              margin="dense"
              fullWidth={true}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Col>
          <Col sm={4}>
            <TextField
              inputProps={{ 'data-cy': 'inputmailingAddressLine3', maxLength: '40' }}
              error={!!details.mailingAddressLine3.error || isMailingAddressUnverified}
              id="mailingAddressLine3"
              name="mailingAddressLine3"
              label="Address line three"
              aria-label="Address line three"
              type="text"
              value={details.mailingAddressLine3.value ?? ''}
              onChange={props.handleChange}
              margin="dense"
              fullWidth={true}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <TextField
              required
              inputProps={{ 'data-cy': 'inputmailingAddressCity', maxLength: '40' }}
              id="mailingAddressCity"
              name="mailingAddressCity"
              label="City"
              aria-label="City"
              error={!!details.mailingAddressCity.error || isMailingAddressUnverified}
              type="text"
              value={details.mailingAddressCity.value}
              onChange={props.handleChange}
              margin="dense"
              fullWidth={true}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            {details.mailingAddressCity.error && (
              <p className="form-field-error" data-cy="mailingAddressCity_Required">
                {details.mailingAddressCity.error}
              </p>
            )}
          </Col>
          <Col sm={4}>
            <FormControl variant="outlined" margin="dense" className="min-width-full" data-cy="form">
              <InputLabel id="state">State *</InputLabel>
              <Select
                required
                labelId="state"
                label="State *"
                value={details.mailingAddressState.value}
                onChange={props.handleChange}
                inputProps={{
                  name: 'mailingAddressState',
                  'data-cy': 'inputmailingAddressState',
                }}
                error={
                  (props.disable && details.mailingAddressState.value === '') ||
                  (props.disable && !!details.mailingAddressState.error) ||
                  isMailingAddressUnverified
                }
              >
                <MenuItem value="" disabled>
                  <em>Please select a state</em>
                </MenuItem>
                {selectMailList}
              </Select>
            </FormControl>
            {details.mailingAddressState.error && (
              <p className="form-field-error" data-cy="mailingAddressState_Required">
                {details.mailingAddressState.error}
              </p>
            )}
          </Col>
          <Col sm={4}>
            <TextField
              required
              inputProps={{ 'data-cy': 'inputmailingAddressZipCode', maxLength: '40' }}
              id="mailingAddressZipCode"
              name="mailingAddressZipCode"
              label="Zip Code"
              aria-label="Zip Code"
              error={!!details.mailingAddressZipCode.error || isMailingAddressUnverified}
              type="text"
              value={details.mailingAddressZipCode.value}
              onChange={props.handleChange}
              margin="dense"
              fullWidth={true}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            {details.mailingAddressZipCode.error && (
              <p className="form-field-error" data-cy="mailingAddressZipCode_Required">
                {details.mailingAddressZipCode.error}
              </p>
            )}
          </Col>
        </Row>
        {isMailingAddressUnverified ? (
          <Row>
            <Col>
              <p className="form-field-error" data-cy="mailingAddressContinueWithUnverifiedAddress_Message">
                We were not able to verify your address.
              </p>
              <label className="form-field-error" aria-label="Please continue with the address I provided">
                <input
                  id="mailingAddressContinueWithUnverifiedAddress"
                  name="mailingAddressContinueWithUnverifiedAddress"
                  type="checkbox"
                  data-cy="mailingAddressContinueWithUnverifiedAddress"
                  checked={details.mailingAddressContinueWithUnverifiedAddress.value}
                  onChange={props.handleChange}
                ></input>{' '}
                Please continue with address I provided
              </label>
            </Col>
          </Row>
        ) : null}
      </div>
    </div>
  );
};

export default EditMailingAddress;
