const emailRegex = /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z0-9](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+$/;

export default function ForgotPasswordValidation(
  userid: string | null,
  email: string | null,
  dontHaveAccessToEmail: boolean,
  newEmail: string | null,
  lastName: string | null
) {
  let message: any[] = [];
  if (!userid) {
    message.push('Please enter Bahá’í ID.');
  }

  if (dontHaveAccessToEmail) {
    if (!newEmail) {
      message.push('Please enter new email address.');
    } else if (!emailRegex.test(newEmail)) {
      message.push("Invalid email format in 'New Email' field.");
    }
    if (!lastName) {
      message.push('Please enter last name.');
    }
  } else {
    if (!email) {
      message.push('Please enter email address.');
    } else if (!emailRegex.test(email)) {
      message.push('Invalid email format.');
    }
  }

  if (message[0]) {
    message = message.join(' ') as any;

    return Promise.reject({ message: message });
  }

  return false;
}
