import React from 'react';
import { Link } from 'react-router-dom';

function Directories() {
  return (
    <div>
      <h1>Directories</h1>
      <ul className="plain-list">
        <li>
          <Link to="/directories/huququllah-trustees">
            Deputy Trustees &amp; Representatives of Ḥuqúqu’lláh in the United States
          </Link>
        </li>
        <li>
          <Link to="/directories/counselors">Continental Board of Counselors</Link>
        </li>
        <li>
          <Link to="/directories/abm-directory">Auxiliary Board Members</Link>
        </li>
        <li>
          <Link to="/directories/councils">Regional Bahá’í Councils</Link>
        </li>
        <li>
          <Link to="/directories/national">National Offices</Link>
        </li>
        <li>
          <Link to="/directories/assemblies-and-groups">Assemblies and Groups</Link>
        </li>
      </ul>
    </div>
  );
}

export default Directories;
