import React from 'react';
import PropTypes from 'prop-types';
import Downloads from '../Common-Components/Downloads';
import { Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import withContext from '../ContextAPI/Context_HOC';

const EachCoverLetterDisplay = (props) => {
  let datestring = '';
  let links = [];
  let display = '';
  let attachments = '';
  if (props.details !== '')
    if (props.details.attachments.value.length === 0) {
      attachments = '';
    } else {
      attachments = (
        <span>
          <hr />
          <h4 className="sidebar-headers">Attachments</h4>
          <div>
            {Object.keys(props.details.attachments.value).map((key) => (
              <Downloads key={key} links={props.details.attachments.value[key]} />
            ))}
          </div>
        </span>
      );
    }
  const { linked_items } = props.details;
  if (linked_items !== undefined && !linked_items.isEmpty) {
    for (var i = 0; i < linked_items.value.length; i++) {
      if (
        linked_items.value[i].base__datepublished === undefined ||
        linked_items.value[i].url_slug === undefined ||
        linked_items.value[i].base__title === undefined
      ) {
        break;
      }
      datestring = linked_items.value[i].base__datepublished.rawData.value.substring(0, 4);
      const languagePart = linked_items.value[i].system.language !== 'en-US' ? `-${linked_items.value[i].system.language}` : '';
      links.push(
        <li key={i}>
          <Link
            data-cy={`link${i}`}
            to={`/guidance/${linked_items.value[i].messages.value[0].codename}/${props.details.url_slug.value}-${datestring}${languagePart}/${linked_items.value[i].url_slug.value}-${datestring}${languagePart}`}
            key={i}
          >
            {linked_items.value[i].base__title.value}
          </Link>
        </li>
      );
    }
  }

  if (Object.keys(props.details).length !== 0) {
    let displayIntroduction = '';
    if (props.details.introduction.value !== '<p><br></p>') {
      displayIntroduction = (
        <div>
          <p
            data-cy="intro-text"
            className="cover-letter-intro"
            dangerouslySetInnerHTML={{
              __html: props.details.introduction.value,
            }}
          />
        </div>
      );
    }
    datestring = props.details.base__datepublished.rawData.value.substring(0, 4);
    display = (
      <div>
        <h1 data-cy="title">{props.details.base__title.value}</h1>
        <p data-cy="date" className="date">
          {new Date(props.details.base__datepublished.value).toLocaleDateString('en-US', props.context.dateOptions)}
        </p>
        <hr />
        <Row>
          <Col sm={12} md={8}>
            {displayIntroduction}
            <p
              data-cy="body-text"
              dangerouslySetInnerHTML={{
                __html: props.details.base__body.value,
              }}
            />
          </Col>
          <Col sm={12} md={4}>
            <h4 className="sidebar-headers">Full Letter(s)</h4>
            <ul className="plain-list">{links}</ul>
            {attachments}
          </Col>
        </Row>
      </div>
    );
  }
  return <div>{display}</div>;
};

export default withContext(EachCoverLetterDisplay);

EachCoverLetterDisplay.propTypes = {
  props: PropTypes.shape({
    details: PropTypes.shape({
      base__title: PropTypes.shape({ value: PropTypes.string }),
      base__body: PropTypes.shape({ value: PropTypes.string }),
      base__datepublished: PropTypes.shape({ value: PropTypes.string }),
    }),
  }),
};
