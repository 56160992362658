import React from 'react';
import { Link } from 'react-router-dom';
import ProfileFunction from '../Profiles/Utilities/ProfileFunction';
import withContext from '../ContextAPI/Context_HOC';
import { OptimizelyFeature } from '@optimizely/react-sdk';

function Elections(props) {
  const auth = props.context.authService;
  const profilefn = new ProfileFunction();
  const decodedToken = auth.getDecodedToken();
  const isdelegate = profilefn.isPersonDelegate(decodedToken);
  const isadmin = profilefn.isAdmin(decodedToken);

  return (
    <div>
      <h1>Elections</h1>
      <ul className="plain-list">
        <li>
          <Link to="/elections/rbc-elections">Regional Bahá’í Council Elections</Link>
        </li>
        <li>
          <Link to="/elections/ridvan-elections">Ridvan Elections</Link>
        </li>
        <li>
          <Link to="/elections/unit-convention">Delegate Elections/Unit Conventions</Link>
        </li>
        <li>
          <Link to="/elections/by-elections">By-Elections</Link>
        </li>
        <li>
          <Link to="/elections/natcon-resources">National Convention Resources</Link>
        </li>
        <OptimizelyFeature feature="delegate_page">
          {(isEnabled) =>
            (isEnabled && isdelegate) || isadmin ? (
              <li>
                <Link to="/elections/delegates">Delegate Resources</Link>
              </li>
            ) : null
          }
        </OptimizelyFeature>
      </ul>
    </div>
  );
}

export default withContext(Elections);
