import React from 'react';
import GlobalAPICall from '../GlobalComponents/GlobalAPICall';
import AuthService from '../Login/AuthService';
import FundService from '../Fund/FundService';
import HuquqService from '../Directories/Utilities/HuquqService';
import AbmDirectoryEndpoint from '../Directories/Utilities/AbmDirectoryEndpoint';
import AssemblyGroupsEndpoint from '../Directories/Utilities/AssemblyGroupsEndpoint';
import CalendarService from '../Home/Utilities/CalendarService';
import ContentProviderEndpoint from '../Content/ContentProviderEndpoint';
import ContentProviderEnvironment from '../Content/ContentProviderEnvironment';
import DigitalDesignTypeResolvers from '../Kentico/DigitalDesignTypeResolvers';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import RegionalCouncilService from '../Directories/Utilities/RegionalCouncilService';
import OverseasValidationEndpoint from '../Login/Utilities/OverseasValidationEndpoint';
import ContentResolver from '../Content/ContentResolver';
import MyProfileProxy from '../Profiles/Utilities/MyProfileProxy';
import MailingAddressProxy from '../Profiles/Utilities/MailingAddressProxy';
import PhysicalAddressProxy from '../Profiles/Utilities/PhysicalAddressProxy';
import EmergencyContactProxy from '../Profiles/Utilities/EmergencyContactProxy';
import SecurityQAndAProxy from '../Profiles/Utilities/SecurityQAndAProxy';
import ChangePasswordProxy from '../Profiles/Utilities/ChangePasswordProxy';
import UnitConventionProxy from '../Profiles/Utilities/UnitConventionProxy';
import EmailProxy from '../Profiles/Utilities/EmailProxy';
import SecurityEmailProxy from '../Profiles/Utilities/SecurityEmailProxy';
import FaqProxy from '../FAQ/Utilities/FaqProxy';
import GlsaProxy from '../Glsa/Utilities/GlsaProxy';
import { ApplicationInsightsService } from '../ApplicationInsights/ApplicationInsightsService';
import RegistrationProxy from '../Profiles/Utilities/RegistrationProxy';
import LookupProxy from '../Profiles/Utilities/LookupProxy';
import HouseholdProxy from '../Profiles/Utilities/HouseholdProxy';
import HomeBlockProxy from '../Home/Utilities/HomeBlockProxy';
import HomeCarouselProxy from '../Home/Utilities/HomeCarouselProxy';
import NatConProxy from '../News/Utilities/NatConProxy';
import NatConCarouselProxy from '../News/Utilities/NatConCarouselProxy';
import FundProxy from '../Fund/FundProxy';
import FeatureFlagIsEnabled from '../FeatureFlag/FeatureFlagIsEnabled';
import PreviouslyEnrolledService from '../public/PreviouslyEnrolled/PreviouslyEnrolledService';
import DigitalIdCardService from '../Profiles/DigitalIdCardService';
import { ApplicationInsightsContainer } from '../ApplicationInsights/ApplicationInsightsServiceInterface';
import IToken from '../Login/IToken';
import { Location as HistoryLocation } from 'history';
import { ContextProviderState } from './ContextProviderState';

export const AppContext = React.createContext({});

//NOTE: make sure to add the context variable here before using it in any other component
class ContextProvider extends React.Component<any, ContextProviderState> {
  myProfileProxy;
  _mounted = false;
  applicationInsightsContainer: ApplicationInsightsContainer;
  applicationInsightsInitializer: (browserHistory: any) => void;
  Auth: AuthService;
  mailingAddressProxy: MailingAddressProxy;
  physicalAddressProxy: PhysicalAddressProxy;
  emergencyContactProxy: EmergencyContactProxy;
  securityQAndAProxy: SecurityQAndAProxy;
  changePasswordProxy: ChangePasswordProxy;
  unitConventionProxy: UnitConventionProxy;
  securityEmailProxy: SecurityEmailProxy;
  emailProxy: EmailProxy;
  contentProviderEnvironment: ContentProviderEnvironment;
  glsaProxy: GlsaProxy;
  faqProxy: FaqProxy;
  registrationProxy: RegistrationProxy;
  lookupProxy: LookupProxy;
  householdProxy: HouseholdProxy;
  homeBlockProxy: HomeBlockProxy;
  homeCarouselProxy: HomeCarouselProxy;
  natConProxy: NatConProxy;
  natConCarouselProxy: NatConCarouselProxy;
  fundProxy: FundProxy;

  constructor(props: {} | Readonly<{}>) {
    super(props);

    if (process.env.REACT_APP_SENTRYACTIVE !== 'false') {
      Sentry.init({
        dsn: process.env.REACT_APP_DSN_CODE,
        integrations: [new Integrations.BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 1.0,
      });
    }

    ApplicationInsightsService.setInstrumentationKey(process.env.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY ?? '');
    this.applicationInsightsInitializer = ApplicationInsightsService.initialize;
    this.applicationInsightsContainer = ApplicationInsightsService.applicationInsightsContainer;
    this.Auth = new AuthService(this.applicationInsightsContainer);
    this.myProfileProxy = new MyProfileProxy(this.Auth as IToken);
    this.mailingAddressProxy = new MailingAddressProxy(this.Auth);
    this.physicalAddressProxy = new PhysicalAddressProxy(this.Auth as IToken, this.myProfileProxy);
    this.emergencyContactProxy = new EmergencyContactProxy(this.Auth);
    this.securityQAndAProxy = new SecurityQAndAProxy(this.Auth);
    this.changePasswordProxy = new ChangePasswordProxy(this.Auth);
    this.unitConventionProxy = new UnitConventionProxy(this.Auth as IToken);
    this.securityEmailProxy = new SecurityEmailProxy(
      this.Auth as IToken,
      process.env.REACT_APP_AUTH_API_URL + '/api/securityemail',
      process.env.REACT_APP_AUTH_API_URL + '/api/securityemail/confirmation/'
    );
    this.emailProxy = new EmailProxy(
      this.Auth as IToken,
      process.env.REACT_APP_AUTH_API_URL + '/api/Email',
      process.env.REACT_APP_AUTH_API_URL + '/api/Email/Confirm',
      process.env.REACT_APP_AUTH_API_URL + '/api/Email/GetChild',
      process.env.REACT_APP_AUTH_API_URL + '/api/Email/Child'
    );
    const huquqService = new HuquqService(this.Auth, GlobalAPICall, process.env.REACT_APP_API_URL);
    const abmDirectoryEndpoint = new AbmDirectoryEndpoint(this.Auth, GlobalAPICall, process.env.REACT_APP_API_URL);
    const digitalIdCardService = new DigitalIdCardService(this.Auth, GlobalAPICall, process.env.REACT_APP_API_URL ?? '');
    const assemblyGroupsEndpoint = new AssemblyGroupsEndpoint(this.Auth, GlobalAPICall, process.env.REACT_APP_API_URL);
    const calendarService = new CalendarService(this.Auth, GlobalAPICall, process.env.REACT_APP_API_URL ?? '');
    this.contentProviderEnvironment = new ContentProviderEnvironment();
    const digitalDesignContent = new ContentProviderEndpoint(
      this.Auth,
      process.env.REACT_APP_API_URL ?? '',
      process.env.REACT_APP_KENTICO_ID_DIGITAL_DESIGN ?? '',
      this.contentProviderEnvironment,
      DigitalDesignTypeResolvers
    );
    const prayerContent = new ContentProviderEndpoint(
      this.Auth,
      process.env.REACT_APP_API_URL ?? '',
      process.env.REACT_APP_KENTICO_ID_BAHAI_PRAYERS ?? '',
      this.contentProviderEnvironment,
      []
    );
    const glsaContent = new ContentProviderEndpoint(
      this.Auth,
      process.env.REACT_APP_API_URL ?? '',
      process.env.REACT_APP_KENTICO_ID_GLSA ?? '',
      this.contentProviderEnvironment,
      []
    );
    this.glsaProxy = new GlsaProxy(glsaContent);
    const contentResolver = new ContentResolver(digitalDesignContent, prayerContent, this.glsaProxy);
    const fundService = new FundService(digitalDesignContent);
    this.faqProxy = new FaqProxy(digitalDesignContent, glsaContent);
    this.registrationProxy = new RegistrationProxy(this.Auth as IToken, process.env.REACT_APP_API_URL ?? '');
    this.lookupProxy = new LookupProxy(this.Auth as IToken, process.env.REACT_APP_API_URL ?? '');
    this.householdProxy = new HouseholdProxy(this.Auth as IToken, process.env.REACT_APP_API_URL ?? '');
    this.homeBlockProxy = new HomeBlockProxy(digitalDesignContent);
    this.homeCarouselProxy = new HomeCarouselProxy(digitalDesignContent);
    this.natConProxy = new NatConProxy(digitalDesignContent);
    this.natConCarouselProxy = new NatConCarouselProxy(digitalDesignContent);
    this.fundProxy = new FundProxy(this.Auth as IToken, process.env.REACT_APP_API_URL ?? '');

    const regionalCouncilService = new RegionalCouncilService(this.Auth, GlobalAPICall, process.env.REACT_APP_API_URL);

    const previouslyEnrolledService = new PreviouslyEnrolledService(
      this.Auth,
      GlobalAPICall,
      process.env.REACT_APP_API_URL ?? ''
    );

    this.updateState = this.updateState.bind(this);

    this.state = {
      overseasValidationEndpoint: OverseasValidationEndpoint,
      contentResolver: contentResolver,
      authService: this.Auth,
      fundService: fundService,
      huquqService: huquqService,
      abmDirectoryEndpoint: abmDirectoryEndpoint,
      previouslyEnrolledService: previouslyEnrolledService,
      digitalIdCardService: digitalIdCardService,
      assemblyGroupsEndpoint: assemblyGroupsEndpoint,
      calendarService: calendarService,
      digitalDesignContent: digitalDesignContent,
      prayerContent: prayerContent,
      glsaContent: glsaContent,
      regionalCouncilService: regionalCouncilService,
      prayerTaxonomy: [],
      coverLetterTaxonomy: [],
      collectionsTaxonomy: [],
      storyTagsTaxonomy: [],
      faqTaxonomy: [],

      isDrawerOpen: false,
      isMessagesActiveDrawer: false,
      displayBreadcrumbs: true,

      glsaSubLinkIds: [],
      glsaSectLinkIds: [],
      newsLinkIds: [],
      webLinkIds: [],
      updatedName: '',
      updatedEmail: '',
      redirectUrl: { pathname: '' } as HistoryLocation,
      setredirectUrl: (newItems: HistoryLocation) => {
        this.setState({ redirectUrl: newItems });
      },
      setUpdatedName: (newItems: string) => {
        this.updateState({ updatedName: newItems });
      },
      setUpdatedEmail: (newItems: string) => {
        this.updateState({ updatedEmail: newItems });
      },

      dateOptions: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC',
      },
      physicalAddressMessage: '',
      mailingAddressMessage: '',
      profileUpdateMessage: '',
      emergencyContactMessage: '',
      isHuqquqRepVisible: false,

      setprofileUpdateMessage: (newItems: string) => {
        this.updateState({ profileUpdateMessage: newItems });
      },
      setemergencyContactMessage: (newItems: string) => {
        this.updateState({ emergencyContactMessage: newItems });
      },
      setmailingAddressMessage: (newItems: string) => {
        this.updateState({ mailingAddressMessage: newItems });
      },
      setphysicalAddressMessage: (newItems: string) => {
        this.updateState({ physicalAddressMessage: newItems });
      },
      showBreadcrumbs: () => {
        this.updateState({ displayBreadcrumbs: true });
      },
      hideBreadcrumbs: () => {
        this.updateState({ displayBreadcrumbs: false });
      },
      setCollectionsTaxonomy: (newItems: []) => {
        this.updateState({ collectionsTaxonomy: newItems });
      },
      setStoryTagsTaxonomy: (newItems: []) => {
        this.updateState({ storyTagsTaxonomy: newItems });
      },
      setPrayerTaxonomy: (newItems: []) => {
        this.updateState({ prayerTaxonomy: newItems });
      },
      setFaqTaxonomy: (newItems: []) => {
        this.updateState({ faqTaxonomy: newItems });
      },
      toggleDrawerMenu: () => {
        this.updateState({ isDrawerOpen: !this.state.isDrawerOpen });
      },
      closeDrawerMenu: () => {
        this.updateState({ isDrawerOpen: false });
      },
      setglsaSubLinkIds: (newItems: []) => {
        this.updateState({ glsaSubLinkIds: newItems });
      },
      setglsaSectLinkIds: (newItems: []) => {
        this.updateState({ glsaSectLinkIds: newItems });
      },
      setNewsLinkIds: (newItems: []) => {
        this.updateState({ newsSubLinkIds: newItems });
      },
      setWebUpdateLinkIds: (newItems: []) => {
        this.updateState({ setWebUpdateSubLinkIds: newItems });
      },
      setHuqquqRepVisiblity: () => {
        this.updateState({ isHuqquqRepVisible: !this.state.isHuqquqRepVisible });
      },

      createBosErrorDisplayMessage: undefined,
      setCreateBosErrorDisplayMessage: (newCreateBosErrorDisplayMessage: string) =>
        this.updateState({
          createBosErrorDisplayMessage: newCreateBosErrorDisplayMessage,
        }),
      createBosPrefillBahaiId: undefined,
      setCreateBosPrefillBahaiId: (newCreateBosPrefillBahaiId: string) =>
        this.updateState({ createBosPrefillBahaiId: newCreateBosPrefillBahaiId }),
      editContactInformationSuccessMessage: undefined,
      setEditContactInformationSuccessMessage: (newEditContactInformationSuccessMessage: string) =>
        this.updateState({ editContactInformationSuccessMessage: newEditContactInformationSuccessMessage }),
      editContactInformationNeutralMessage: undefined,
      setEditContactInformationNeutralMessage: (newEditContactInformationNeutralMessage: string) =>
        this.updateState({ editContactInformationNeutralMessage: newEditContactInformationNeutralMessage }),
      clickedIToChildRegistrationDisclosures: false,
      firstNameFromChildRegistrationDisclosures: '',
      lastNameFromChildRegistrationDisclosures: '',
      setClickedIToChildRegistrationDisclosures: (firstName: string, lastName: string) =>
        this.updateState({
          clickedIToChildRegistrationDisclosures: true,
          firstNameFromChildRegistrationDisclosures: firstName,
          lastNameFromChildRegistrationDisclosures: lastName,
        }),
      childRegistrationSuccessMessage: null,
      setChildRegistrationSuccessMessage: (newChildRegistrationSuccessMessage: string) =>
        this.updateState({
          childRegistrationSuccessMessage: newChildRegistrationSuccessMessage,
        }),
    };
  }

  updateState(data: ContextProviderState | any) {
    if (this._mounted) {
      this.setState(data);
    }
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          myProfileProxy: this.myProfileProxy,
          mailingAddressProxy: this.mailingAddressProxy,
          physicalAddressProxy: this.physicalAddressProxy,
          emergencyContactProxy: this.emergencyContactProxy,
          securityQAndAProxy: this.securityQAndAProxy,
          changePasswordProxy: this.changePasswordProxy,
          unitConventionProxy: this.unitConventionProxy,
          emailProxy: this.emailProxy,
          securityEmailProxy: this.securityEmailProxy,
          faqProxy: this.faqProxy,
          glsaProxy: this.glsaProxy,
          applicationInsightsInitializer: this.applicationInsightsInitializer,
          applicationInsightsContainer: this.applicationInsightsContainer,
          registrationProxy: this.registrationProxy,
          lookupProxy: this.lookupProxy,
          householdProxy: this.householdProxy,
          contentProviderEnvironment: this.contentProviderEnvironment,
          homeBlockProxy: this.homeBlockProxy,
          homeCarouselProxy: this.homeCarouselProxy,
          natConProxy: this.natConProxy,
          natConCarouselProxy: this.natConCarouselProxy,
          fundProxy: this.fundProxy,
          featureFlagIsEnabledFunction: FeatureFlagIsEnabled,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
export default ContextProvider;
