import { useState, useEffect, useCallback } from 'react';
import SafeStringify from './SafeStringify';

const getFieldError = (fieldSchema, fieldValue, fieldInitialValue, state) => {
  if (fieldSchema.required) {
    if (!fieldValue) {
      return 'This is a required field.';
    }
  }

  if (fieldSchema.nonRemovable) {
    if (fieldInitialValue?.length > 0 && !(fieldValue?.length > 0)) {
      return fieldSchema.nonRemovableMessage ?? 'This field cannot be removed, only changed.';
    }
  }

  if (fieldSchema.validator !== null && typeof fieldSchema.validator === 'object') {
    if (fieldValue && !fieldSchema.validator.regEx.test(fieldValue)) {
      return fieldSchema.validator.error;
    }
  }

  if (fieldSchema.validator !== null && typeof fieldSchema.validator === 'function') {
    const validationResult = fieldSchema.validator(fieldValue, state);
    if (validationResult && validationResult !== '') {
      return validationResult;
    }
  }

  return '';
};

const validateField = (fieldName, fieldSchema, fieldValue, setState, state) => {
  const fieldInitialValue = state[fieldName].initialValue;
  const fieldError = getFieldError(fieldSchema, fieldValue, fieldInitialValue, state);
  setState((prevState) => ({
    ...prevState,
    [fieldName]: {
      value: fieldValue,
      initialValue: fieldInitialValue,
      error: fieldError,
    },
  }));
};

const validateFields = (fieldNameSchemaValues, setState, state) => {
  let errorFound = false;
  const newState = fieldNameSchemaValues.reduce((accumulator, item) => {
    const fieldSchema = item.schema;
    const fieldValue = item.value;
    const fieldInitialValue = item.initialValue;
    const fieldError = getFieldError(fieldSchema, fieldValue, fieldInitialValue, state);
    if (!errorFound && fieldError) {
      errorFound = true;
    }
    return {
      ...accumulator,
      [item.name]: {
        value: fieldValue,
        initialValue: fieldInitialValue,
        error: fieldError,
      },
    };
  }, {});

  setState((prevState) => ({ ...prevState, ...newState }));

  return errorFound;
};

function useForm(stateSchema, validationSchema = {}, callback, onInvalidSubmitAttemptCallback) {
  const [state, setState] = useState(stateSchema);
  const [disable, setDisable] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitCalled, setIsSubmitCalled] = useState(undefined);

  // Disable button in initial render.
  useEffect(() => {
    setDisable(false);
  }, []);
  // For every changed in our state this will be fired
  // To be able to disable the button

  // Used to disable submit button if there's an error in state
  // or the required field in state has no value.
  // Wrapped in useCallback to cached the function to avoid intensive memory leaked
  // in every re-render in component
  const validateState = useCallback(() => {
    const fieldNameSchemaValues = Object.keys(validationSchema).map((key) => {
      const fieldName = key;
      const fieldSchema = validationSchema[key];
      const fieldValue = state[key].value ?? '';
      const fieldInitialValue = state[key].initialValue;
      return {
        name: fieldName,
        schema: fieldSchema,
        value: fieldValue,
        initialValue: fieldInitialValue,
      };
    });

    const hasErrorInState = validateFields(fieldNameSchemaValues, setState, state);

    if (!hasErrorInState) {
      setIsSubmitCalled(true);
    }
    setIsDirty(true);
    return hasErrorInState;
  }, [state, validationSchema]);

  const processErrors = useCallback(
    (serializableErrorFromApi) => {
      const accumulatedValues = Object.entries(serializableErrorFromApi).reduce(
        (accumulator, [errorKey, errorValue]) => {
          const initialStateMatchingKey = Object.keys(state).find(
            (initialStateKey) => initialStateKey.toUpperCase() === errorKey.toUpperCase()
          );
          if (initialStateMatchingKey) {
            const stateField = state[initialStateMatchingKey];
            const errorMessage = SafeStringify(errorValue);
            accumulator.newState[initialStateMatchingKey] = {
              ...stateField,
              error: errorMessage,
            };
          } else {
            const messageValue = SafeStringify(errorValue);
            const newUnprocessedError =
              errorKey === 'message' || errorKey === 'Message'
                ? errorValue
                : `The field "${errorKey}" has the following error "${messageValue}". `;
            accumulator.unprocessedError += newUnprocessedError;
          }
          return accumulator;
        },
        { newState: {}, unprocessedError: '' }
      );

      const newState = accumulatedValues.newState;
      setState((prevState) => ({ ...prevState, ...newState }));
      return accumulatedValues.unprocessedError;
    },
    [state]
  );

  useEffect(() => {
    if (isDirty) {
      setDisable(validateState());
      setIsDirty(false);
    }
  }, [state, isDirty, validateState]);

  // Used to handle every changes in every input
  const handleValidationOnChange = useCallback(
    (event) => {
      const name = event.target.name;
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      validateField(name, validationSchema[name], value, setState, state);
    },
    [validationSchema, state]
  );

  const handleOnSubmit = useCallback(
    (event) => {
      event.preventDefault();

      // Make sure that validateState returns false
      // Before calling the submit callback function
      if (!validateState()) {
        setIsSubmitCalled(true);
        callback(state, processErrors);
      } else {
        setIsSubmitCalled(false);
        if (onInvalidSubmitAttemptCallback) {
          onInvalidSubmitAttemptCallback();
        }
      }
    },
    [state, validateState, callback, onInvalidSubmitAttemptCallback, processErrors]
  );

  return {
    setState,
    state,
    disable,
    handleValidationOnChange,
    handleOnSubmit,
    isSubmitCalled,
    isDirty,
  };
}

export default useForm;
