import { Alert } from '@mui/material';
import React from 'react';
import type { CustomField } from './type/custom-field';

export enum CustomHookFormErrorAlertType {
  MinLength = 'minLength',
  MaxLength = 'maxLength',
  Required = 'required',
  Min = 'min',
  Max = 'max',
  Pattern = 'pattern',
  Url = 'url',

  Email = 'email',
}
export interface UseCustomHookFormErrorAlertProps {
  type: CustomHookFormErrorAlertType;
  field: CustomField<unknown>;
  errors?: { [key: string]: { type?: string; message?: string } | any };
}
export function UseCustomHookFormErrorAlert(props: UseCustomHookFormErrorAlertProps) {
  const { type, field, errors } = props;
  return <Alert severity="warning">{CustomErrorMessage(type, field, errors)}</Alert>;
}
export function CustomErrorMessage(
  type: CustomHookFormErrorAlertType,
  field: CustomField<unknown>,
  errors?: { [key: string]: { type?: string; message?: string } | any }
) {
  let message = '';
  switch (type) {
    case CustomHookFormErrorAlertType.Required:
      message = `Field ${field.label} is required`;
      break;
    case CustomHookFormErrorAlertType.MinLength:
      message = field.validators?.minLength?.message
        ? field.validators?.minLength?.message
        : `The minimum number of characters is ${field.validators?.minLength?.value}`;
      break;
    case CustomHookFormErrorAlertType.MaxLength:
      message = field.validators?.maxLength?.message
        ? field.validators?.maxLength?.message
        : `The maximum number of characters is ${field.validators?.maxLength?.value}`;
      break;
    case CustomHookFormErrorAlertType.Min:
      message = `The minimum number valid is ${field.validators?.min?.value}`;
      break;
    case CustomHookFormErrorAlertType.Max:
      message = `The maximum number valid is ${field.validators?.max?.value}`;
      break;
    case CustomHookFormErrorAlertType.Email:
      message = `Field should be a valid email. ${field.validators?.email?.message}`;
      break;
    case CustomHookFormErrorAlertType.Url:
      message = `Field should be a valid URL. ${field.validators?.url?.message}`;
      break;
    case CustomHookFormErrorAlertType.Pattern:
      message = `Field is not correct. ${field.validators?.pattern?.message}`;
      break;
    default:
      message = 'Error in field';
      if (errors) {
        if (errors[field.formControlName].message) {
          message = errors[field.formControlName].message;
        }
      }
      break;
  }
  return message;
}
