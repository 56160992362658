import React from 'react';
import { Row, Col } from 'react-flexbox-grid';

const DomesticResidentialAddress = ({ details }) => {
  return (
    <div>
      <Row className="small-bottom-padding">
        <Col sm={12}>
          <p className="faded-text small-text">In Care Of</p>
          <p>
            <strong>{details.physicalAddressCareOf}</strong>
          </p>
        </Col>
      </Row>
      <Row className="small-bottom-padding">
        <Col sm={3}>
          <p className="faded-text small-text">Street Address</p>
          <p>
            <strong>{details.physicalAddressLine1}</strong>
          </p>
        </Col>
        {details.physicalAddressLine2 ? (
          <Col sm={3}>
            <p className="faded-text small-text">Address Line two</p>
            <p>
              <strong>{details.physicalAddressLine2}</strong>
            </p>
          </Col>
        ) : null}
        {details.physicalAddressLine3 ? (
          <Col sm={3}>
            <p className="faded-text small-text">Address Line three</p>
            <p>
              <strong>{details.physicalAddressLine3}</strong>
            </p>
          </Col>
        ) : null}
      </Row>
      <Row>
        <Col sm={3}>
          <p className="faded-text small-text">City</p>
          <p>
            <strong>{details.physicalAddressCity}</strong>
          </p>
        </Col>
        <Col sm={3}>
          <p className="faded-text small-text">State</p>
          <p>
            <strong>{details.physicalAddressState}</strong>
          </p>
        </Col>
        <Col sm={3}>
          <p className="faded-text small-text">Zip Code</p>
          <p>
            <strong>{details.physicalAddressZipCode}</strong>
          </p>
        </Col>
      </Row>
    </div>
  );
};
export default DomesticResidentialAddress;
