import React from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import withContext from '../../ContextAPI/Context_HOC';

const notes = [
  {
    id: 1,
    text: 'Feast of ‘Ilm',
    linkEn: 'https://bahai-web.s3.amazonaws.com/Treasury/22.12%2BIlm%2BEnglish.pdf',
    linkFa: 'https://bahai-web.s3.amazonaws.com/Treasury/22.12%2BIlm%2BPersian.pdf',
  },
  {
    id: 2,
    text: 'Feast of Ma<u>sh</u>íyyat',
    linkEn: 'https://bahai-web.s3.amazonaws.com/Treasury/22.11%2BMashiyyat%2BEnglish.pdf',
    linkFa: 'https://bahai-web.s3.amazonaws.com/Treasury/22.11%2BMashiyyat%2BPersian.pdf',
  },
  {
    id: 3,
    text: 'Feast of ‘Izzat',
    linkEn: 'https://bahai-web.s3.amazonaws.com/Treasury/22.10%2BIzzat%2BEnglish.pdf',
    linkFa: 'https://bahai-web.s3.amazonaws.com/Treasury/22.10%2BIzzat%2BPersian.pdf',
  },
  {
    id: 4,
    text: 'Feast of Asmá’',
    linkEn: 'https://bahai-web.s3.amazonaws.com/Treasury/22.09%2BAsma%2BEnglish.pdf',
    linkFa: 'https://bahai-web.s3.amazonaws.com/Treasury/22.09%2BAsma%2BPersian.pdf',
  },
  {
    id: 5,
    text: 'Feast of Kamál',
    linkEn: 'https://bahai-web.s3.amazonaws.com/Treasury/22.08%2BKamal%2BEnglish.pdf',
    linkFa: 'https://bahai-web.s3.amazonaws.com/Treasury/22.08%2BKamal%2BPersian.pdf',
  },
  {
    id: 6,
    text: 'Feast of Kalimát',
    linkEn: 'https://bahai-web.s3.amazonaws.com/Treasury/22.07%2BKalimat%2BEnglish.pdf',
    linkFa: 'https://bahai-web.s3.amazonaws.com/Treasury/22.07%2BKalimat%2BPersian.pdf',
  },
  {
    id: 7,
    text: 'Feast of Raḥmat',
    linkEn: 'https://bahai-web.s3.amazonaws.com/Treasury/22.06%2BRahmat%2BEnglish.pdf',
    linkFa: 'https://bahai-web.s3.amazonaws.com/Treasury/22.06%2BRahmat%2BPersian.pdf',
  },
  {
    id: 8,
    text: 'Feast of Núr',
    linkEn: 'https://bahai-web.s3.amazonaws.com/Treasury/22.05%2BNur%2BEnglish.pdf',
    linkFa: 'https://bahai-web.s3.amazonaws.com/Treasury/22.05%2BNur%2BPersian.pdf',
  },
  {
    id: 9,
    text: 'Feast of ‘Aẓamat',
    linkEn: 'https://bahai-web.s3.amazonaws.com/Treasury/22.04%2BAzamat%2BEnglish.pdf',
    linkFa: 'https://bahai-web.s3.amazonaws.com/Treasury/22.04%2BAzamat%2BPersian.pdf',
  },
];

const FundResources = () => {
  return (
    <div>
      <h2 className="yellowBackgroundTextStyle">Ways To Stay Up to Date on the Bahá’í Fund</h2>
      <div className="containerStyle">
        <div className="boxStyle">
          <iframe
            src="https://player.vimeo.com/video/1012191607?h=2089eeef16"
            width="100%"
            height="310"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Vimeo video player"
          />
          <p>
            <strong>September 5, 2024 Town Hall Webinar Recording</strong>
            <br />
            <small>Please do not copy, share, or distribute this video or any sections of the video</small>
          </p>
          <h3>Stay Connected</h3>
          <p>
            Text: 847–733–3472 and in the message box write “Treasurer”
            <br />
            Have questions? After signing up, text 847–733–3472 and in the message box write your question.
          </p>
        </div>
        <div className="boxStyle">
          <h3>National Fund Update</h3>
          <p>
            <strong>For the year ending April 30, 2025. Contributions as of October 15, 2024:</strong>
            <br />
            <br />
            <strong>National Fund Goal: $50 Million</strong>
            <br />
            <strong className="red-text">$16,663,984</strong>
            <br />
            <br />
            <strong>Continental Fund goal: $1 Million</strong>
            <br />
            <strong className="red-text">$214,928</strong>
            <br />
            <br />
            <strong>International Fund</strong>
            <br />
            <strong className="red-text">$791,162</strong>
            <br />
            <br />
            <strong>Shrine of ‘Abdu’l-Bahá Fund</strong> (since inaguaration of the Fund)
            <br />
            <strong className="red-text">$37,940,745</strong>
          </p>
          <a
            className="home-button small-bottom-margin full-width center"
            href="https://ocs.bahai.us/ocs/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Online Contribution System
          </a>
          <a
            className="home-button small-bottom-margin full-width center"
            href="https://www.bahaitreasurer.us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Office of the Treasurer
          </a>
        </div>
      </div>
      <hr />
      <div className="containerStyle">
        <div className="boxStyle">
          <h3>Treasurers Notes</h3>
          <p>Feast of Qudrat/Power</p>
          <p>
            Treasurers Notes are sent out every nineteen days to be read at Feast. Treasurer Notes includes updates on the Bahá’í
            Funds and other Fund related activities.
          </p>
          <div className="containerStyle">
            <a
              className="home-button small-bottom-margin center"
              href="https://bahai-web.s3.amazonaws.com/Treasury/22.13%2BQudrat%2BEnglish.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Treasurers Notes (English)
            </a>
            <a
              className="home-button small-bottom-margin center"
              href="https://bahai-web.s3.amazonaws.com/Treasury/22.13%2BQudrat%2BPersian.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Treasurers Notes (Persian)
            </a>
          </div>

          <Table>
            <thead>
              <TableRow>
                <th className="cellStyle">
                  <strong>Past Notes</strong>
                </th>
                <th className="cellStyle" />
                <th className="cellStyle" />
              </TableRow>
            </thead>
            <tbody>
              {notes.map((note) => (
                <TableRow key={note.id}>
                  <td className="cellStyle" dangerouslySetInnerHTML={{ __html: note.text }} />
                  <td className="cellStyle">
                    (
                    <a href={note.linkEn}>
                      <u>English</u>
                    </a>
                    )
                  </td>
                  <td className="cellStyle">
                    (
                    <a href={note.linkFa}>
                      <u>Persian</u>
                    </a>
                    )
                  </td>
                </TableRow>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="boxStyle">
          <img
            src="https://bahai-web.s3.amazonaws.com/Treasury/TreasurersNotesImage.png"
            alt="Bahai Fund"
            className="home-image"
          />
        </div>
      </div>
    </div>
  );
};

export default withContext(FundResources);
