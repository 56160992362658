import React, { useState, useEffect } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import { withRouter } from 'react-router-dom';
import { withStyles, alpha } from '@material-ui/core/styles';
import DropDown from './DropDown';
import { CircularProgress } from '@material-ui/core';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';
import { HashLink as Link } from 'react-router-hash-link';
import SearchIcon from '@material-ui/icons/Search';
import { InputBase } from '@material-ui/core';
import QueryString from 'query-string';

const styles = (theme) => ({
  search: {
    position: 'relative',
    borderRadius: '3px',
    maxWidth: '200px',
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
  },
  searchIcon: {
    width: theme.spacing(6),
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(6),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
});

const MainGlsa = ({ context: { glsaProxy }, history, classes }) => {
  const [showCircularProgress, setShowCircularProgress] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [linkDisplay, setLinkDisplay] = useState([]);
  const [searchString, setSearch] = useState('');

  useEffect(() => {
    const subscription = glsaProxy.getChapters().subscribe({
      next: (x) => {
        const dynamiclinks = x.chapterTerms.map((chapterValue, chapterKey) => {
          const chapterUrl = x.urlsByCodename[chapterValue.codename];
          const chapterRollupRevisedDate = x.urlsByCodename[chapterValue.codename].revisedDate;
          const sections = chapterValue.terms.map((sectValue) => {
            const sectionUrl = x.urlsByCodename[sectValue.codename].url;
            const sectionRevisedDate = x.urlsByCodename[sectValue.codename].revisedDate;
            const subSections = sectValue.terms.map((subSectValue) => {
              const subSectionUrl = x.urlsByCodename[subSectValue.codename].url;
              const subSectionRevisedDate = x.urlsByCodename[subSectValue.codename].revisedDate;
              return {
                url: subSectionUrl,
                title: subSectValue.name,
                subSectionRevisedDate: subSectionRevisedDate,
              };
            });
            return {
              url: sectionUrl,
              title: sectValue.name,
              subSections: subSections,
              sectionRevisedDate: sectionRevisedDate,
            };
          });
          return (
            <DropDown
              chapterName={chapterValue.name}
              chapterUrl={chapterUrl.url}
              sections={sections}
              key={chapterKey}
              chapterKey={chapterKey}
              chapterRollupRevisedDate={chapterRollupRevisedDate}
            />
          );
        });
        setLinkDisplay(dynamiclinks);
        setShowCircularProgress(false);
      },
      error: (e) => {
        console.error(e);
        setErrorOccurred(true);
      },
    });

    return () => subscription.unsubscribe();
  }, [glsaProxy, setShowCircularProgress, setErrorOccurred, setLinkDisplay]);

  const submitSearch = (e) => {
    e.preventDefault();
    const queryParameters = { searchString: searchString, excludes: ['UHJ', 'NSA', 'Feast', 'FAQ', 'Prayers', 'Writings'] };
    const queryStringified = QueryString.stringify(queryParameters);
    history.push({
      pathname: '/search-results',
      search: queryStringified,
    });
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div>
      {errorOccurred ? <ErrorMessageToUser /> : null}
      {showCircularProgress ? <CircularProgress className="loading-animation" size={40} /> : null}
      {linkDisplay.length > 0 ? (
        <div data-cy="mainGlsadisplaylistlink">
          <h1>Guidelines for LSAs</h1>
          <p>
            Originally produced in 1989, Guidelines for Local Spiritual Assemblies or GLSA, formerly known as{' '}
            <em>Developing Distinctive Bahá’í Communities: Guidelines for Local Spiritual Assemblies</em>, is a standard reference
            for authoritative guidance and national policies on facilitating the administration of community life by Local
            Spiritual Assemblies. The National Spiritual Assembly encourages all Local Assemblies to access and use this manual in
            preparation for and during Assembly meetings to clarify and deepen their understanding of relevant guidance necessary
            for their work.
          </p>
          <p>
            <em>Guidelines for Local Spiritual Assemblies</em> is available in electronic form only and is periodically updated.
            Please note that you have the National Assembly’s permission to make copies of portions of the manual for the use of
            your Local Assembly. Should you have any questions about the GLSA, please feel free to contact the Office of Community
            Administration by email at <a href="mailto:community@usbnc.org">community@usbnc.org</a> or by telephone at{' '}
            <a href="tel:847-733-3593">847-733-3593</a>.
          </p>
          <br />
          <h6>
            <strong>
              To download the original PDF documents please visit <Link to="/resources/assembly-references/#GLSA">this page</Link>
              .
            </strong>
            To learn more about how to navigate this version please read{' '}
            <Link to="/website-updates/online-version-of-guidelines-for-lsas">this article</Link>.
          </h6>
          <hr />
          <form data-cy="search" className={classes.search} onSubmit={submitSearch}>
            <div className={classes.searchIcon} onClick={submitSearch}>
              <SearchIcon data-cy="searchicon" />
            </div>
            <InputBase
              data-cy="searchbar"
              placeholder="Search Guidance…"
              aria-label="Search"
              role="search"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              value={searchString}
              id="searchinput"
              onChange={handleSearch}
            />
          </form>
          {linkDisplay}
          <br />
          <Link
            data-cy="suplementsguideline"
            to="/resources/assembly-references/#SupplementsGuideLine"
            className="small-left-margin"
          >
            Supplements to Guidelines
          </Link>
        </div>
      ) : null}
    </div>
  );
};

export default withRouter(withContext(withStyles(styles)(MainGlsa)));
