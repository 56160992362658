import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText, Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';
import withContext from '../ContextAPI/Context_HOC';

class MessageDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: null, messagesTaxonomy: [], countOfMessagesFromContent: 0 };
  }
  updateSelected(selectedIndex) {
    this.setState({ selected: selectedIndex });
  }
  componentDidMount() {
    this.props.context.digitalDesignContent
      .taxonomy('cover_letter')
      .toObservable()
      .subscribe(async (response) => {
        if (!response.isEmpty) {
          this.setState({
            messagesTaxonomy: response.taxonomy.terms.slice(),
            countOfMessagesFromContent: response.taxonomy.terms.length,
          });
        }
      });
  }
  render() {
    const { selected } = this.state;
    return (
      <List>
        {this.state.messagesTaxonomy.map((messages, index) => (
          <span key={index}>
            <ListItem data-cy="letterdrawerlist" onClick={() => this.updateSelected(index)} selected={selected === index}>
              <Link data-cysublev="subdrawerlink" data-cy={`letterdrawerlink${index}`} to={`/guidance/${messages.codename}`}>
                <ListItemText className="subdrawer-list-item" primary={messages.name} />
              </Link>
            </ListItem>
            <Divider />
          </span>
        ))}
        <ListItem
          data-cy="letterdrawer-nineyearplan"
          onClick={() => this.updateSelected(this.state.countOfMessagesFromContent + 0)}
          selected={selected === this.state.countOfMessagesFromContent + 0}
        >
          <Link data-cysublev="subdrawerlink-nineyearplan" to="/nine-year-plan">
            <ListItemText className="subdrawer-list-item" primary="Nine Year Plan" />
          </Link>
        </ListItem>
        <Divider />
        <ListItem
          data-cy="letterdrawer-nineyearplanpioneering"
          onClick={() => this.updateSelected(this.state.countOfMessagesFromContent + 1)}
          selected={selected === this.state.countOfMessagesFromContent + 1}
        >
          <Link data-cysublev="subdrawerlink-nineyearplanpioneering" to="/nine-year-plan-pioneering">
            <ListItemText className="subdrawer-list-item" primary="Pioneering in the Nine Year Plan" />
          </Link>
        </ListItem>
        <Divider />
        <ListItem
          data-cy="letterdrawerlist"
          onClick={() => this.updateSelected(this.state.countOfMessagesFromContent + 2)}
          selected={selected === this.state.countOfMessagesFromContent + 2}
        >
          <Link data-cysublev="subdrawerlink" to="/call-to-action">
            <ListItemText className="subdrawer-list-item" primary="Call to Action" />
          </Link>
        </ListItem>
      </List>
    );
  }
}

MessageDrawer.propTypes = {
  details: PropTypes.shape({
    class: PropTypes.string,
    messageTaxonomy: PropTypes.array,
  }),
  classes: PropTypes.object.isRequired,
};

export default withContext(MessageDrawer);
