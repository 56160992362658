import React from 'react';

function MaintenanceMessageToUser() {
  return (
    <div className="center form-style" data-cy="maintenance-message-to-user">
      Currently performing maintenance. Please check back later.
    </div>
  );
}

export default MaintenanceMessageToUser;
