import React, { Component } from 'react';
import withContext from '../../ContextAPI/Context_HOC';
import { Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import Education from '../../assets/img/FundEducation/Education.png';
import SacredObligation from '../../assets/img/FundEducation/SacredObligation.png';
import PrayersAndWritings from '../../assets/img/FundEducation/PrayersAndWritings.png';
import Videos from '../../assets/img/FundEducation/EducationalVideos.png';
import Stewardship from '../../assets/img/FundEducation/Stewardship.png';
import SacredTrust from '../../assets/img/FundEducation/SacredTrust.png';
// import FundFun from '../../assets/img/FundEducation/HowToMakeTheFundFun.png';

class FundEducation extends Component {
  render() {
    return (
      <Row>
        <Col xs={12} md={6}>
          <Row>
            <Col sm={2}>
              <img src={Education} alt="Education" className="fund-logo" />
            </Col>
            <Col sm={10}>
              <h1>Education on the Bahá’í Fund</h1>
              <p>
                Contributions from its members fuel the functioning of the Bahá’í Faith. These contributions are a sacrificial
                gift of love. Small and large, they combine to form the foundation of the activities and administration of the
                Bahá’í Faith. There is a place for every Bahá’í to play their part, regardless of economic status or age.
              </p>
              <blockquote>
                “That the heart is the throne, in which the Revelation of God the All-Merciful is centered, is attested by the
                holy utterances which We have formerly revealed.”
              </blockquote>
              <p>
                Bahá’u’lláh, <em>Gleanings</em>
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <Link to="/resources/treasurers-tools/fund-education/sacred-obgligation" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={SacredObligation} alt="Sacred Obligation" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Understanding Our Sacred Obligation and The National Bahá’í Fund Goals</h4>
                <p>Individual, Community, Institution and Fund breakdown, Description of the Funds, Contribution Forms</p>
              </Col>
            </Row>
          </Link>
          <br />
          <Link to="/resources/treasurers-tools/fund-education/prayers-and-writings" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={PrayersAndWritings} alt="Prayers and Writings" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Prayer, Writings, Inspirational Stories on the Bahá’í Fund, and Resources</h4>
                <p>Prayer, and Connecting the Community to the Fund</p>
              </Col>
            </Row>
          </Link>
          <br />
          <Link to="/resources/treasurers-tools/fund-education/fund-videos" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={Videos} alt="Fund Videos" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Videos on the Fund</h4>
                <p>
                  Fundraising Ideas, Family, Educational, Inspirational, Treasurer Videos, Current National Campaign, Workshops
                  and Events, Talks, Planned Giving, Conferences, Will and Testament.
                </p>
              </Col>
            </Row>
          </Link>
          <br />
          <a
            href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/Stewardship-and-Development-Manual-Revised-2021.pdf"
            className="plain-link"
          >
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={Stewardship} alt="Stewardship" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Stewardship and Development (PDF)</h4>
              </Col>
            </Row>
          </a>
          <br />
          <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/sacred-trust.pdf" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={SacredTrust} alt="Sacred Trust" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Sacred Trust (PDF)</h4>
              </Col>
            </Row>
          </a>
          {/* <Link to="/resources/treasurers-tools/fund-education/how-to-make-the-fund-fun" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={Fun} alt="How to make the Fund fun" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>How to Make the Fund Fun!</h4>
              </Col>
            </Row>
          </Link> */}
        </Col>
      </Row>
    );
  }
}

export default withContext(FundEducation);
