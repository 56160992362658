import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';
import withContext from '../../ContextAPI/Context_HOC';
import Tools from '../../assets/img/TreasurersTools/TreasurersTools.png';
import Accounting from '../../assets/img/TreasurersTools/Accounting.png';
import Audit from '../../assets/img/TreasurersTools/Audit.png';
import FormsAndDocs from '../../assets/img/TreasurersTools/FormsAndDocs.png';
// import HowToVideos from '../../assets/img/TreasurersTools/HowToVideos.png';
import Education from '../../assets/img/TreasurersTools/Education.png';
import FundBulletin from '../../assets/img/TreasurersTools/FundBulletin.png';
// import TreasurersGeneral from '../../assets/img/TreasurersGeneral.png';
import Contact from '../../assets/img/TreasurersTools/Contact.png';

class TreasurersTools extends Component {
  render() {
    return (
      <Row>
        <Col xs={12} md={6}>
          <Row>
            <Col sm={2}>
              <img src={Tools} alt="Treasurer’s Tools" className="fund-logo" />
            </Col>
            <Col sm={10}>
              <h1>Treasurer’s Tools</h1>
            </Col>
          </Row>
          <p>
            To stay connected to Treasurer information and upcoming Important dates, text{' '}
            <a className="plain-link" href="sms://+18477333472;?&body=Treasurer%3A%20">
              847-733-3472
            </a>{' '}
            and in the message box write &ldquo;Treasurer&ldquo;. Have questions? After signing up, text 847–733–3472 and in the
            message box write your question.
          </p>
          <h3>Announcements</h3>
          <p>
            <strong>Local Spiritual Assembly Financial Audit</strong> - Due 7/31/22
          </p>
          <p>
            <strong>New Treasurers Welcome Webinar</strong> - Coming in June
          </p>
          <p>
            <strong>Annual Report</strong> - Due by Ridván
          </p>
        </Col>
        <Col xs={12} md={6}>
          <Link to="/resources/treasurers-tools/accounting" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={Accounting} alt="Accounting" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Accounting</h4>
                <p>
                  Accounting and Budget Worksheet Instructions, Financial Report Form, Accounting and Budget Worksheet for LSAs,
                  Bank Balance Confirmation Form
                </p>
              </Col>
            </Row>
          </Link>
          <br />
          <Link to="/resources/treasurers-tools/audit" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={Audit} alt="Audit" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Audit</h4>
                <p>Audit Worksheet, Statement on Audit of LSA, Audit Benchmark Policy for LSAs</p>
              </Col>
            </Row>
          </Link>
          <br />
          <Link to="/resources/treasurers-tools/treasurers-forms" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={FormsAndDocs} alt="Treasurer Forms" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Treasurer Forms and Downloadable Documents</h4>
                <p>
                  FAQ for the Local Fund Online Program, How to Apply for an EIN, 501(c)(3) Feb 2018 NSA Group Tax Exempt Confirm,
                  501(c)(3) Feb 2018 NSA IRS Tax Exempt Affidavit , Treasurer check list, LSA Liability Insurance Info, Insurance
                  Certificate Request, Making a Loan to the NSA{' '}
                </p>
              </Col>
            </Row>
          </Link>
          <br />
          {/* <Link to="/resources/treasurers-tools/" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={HowToVideos} alt="Treasurer Videos" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Treasurer How to Videos</h4>
                <p> How to pull a report, How to prepare for LSA, How to Prepare for an audit, how to prepare for Feast, Workshops and Events,Feast videos, Community Fund Education.</p>
              </Col>
            </Row>
          </Link>
          <br /> */}
          <Link to="/resources/treasurers-tools/fund-education" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={Education} alt="Fund Education" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Education on the Bahá’í Fund</h4>
                <p>
                  Understanding Our Sacred Obligation and The National Bahá’í Fund Goals, Stewardship and Development, Stories,
                  Quotes, Writings and Prayers on the fund, Videos on the Fund
                </p>
              </Col>
            </Row>
          </Link>
          <br />
          <a target="_blank" rel="noopener noreferrer" href="https://bahaitreasurer.us/feast-resourses" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={FundBulletin} alt="Bahá’í Fund Bulletin" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Bahá’í Fund Bulletin</h4>
                <p> News, Announcements and Education on the Bahá’í Fund</p>
              </Col>
            </Row>
          </a>
          <br />
          <Link to="/resources/treasurers-tools/contact-us" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={Contact} alt="Questions or Concerns" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Question or Concerns?</h4>
                <p>Contact us</p>
              </Col>
            </Row>
          </Link>
        </Col>
      </Row>
    );
  }
}

export default withContext(TreasurersTools);
