import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText, Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';
import withContext from '../ContextAPI/Context_HOC';

const FaqDrawer = (props) => {
  const [selected, setSelected] = useState();
  const [faqTaxonomy, setFaqTaxonomy] = useState([]);
  const [contextFaq] = useState(props.context.faqProxy);

  useEffect(() => {
    let taxonomySubscription;
    if (contextFaq) {
      const taxonomy$ = contextFaq.getTaxonomy();
      taxonomySubscription = taxonomy$.subscribe((x) => {
        setFaqTaxonomy(x);
        if (x.length > 0) {
          props.context.setFaqTaxonomy(faqTaxonomy);
          setSelected(0);
        }
      });
    }

    return () => {
      if (taxonomySubscription) {
        taxonomySubscription.unsubscribe();
      }
    };
  }, [contextFaq]);

  return (
    <List>
      {faqTaxonomy.length > 0
        ? faqTaxonomy.map((faq, index) => (
            <span key={index}>
              <ListItem
                data-cy="faqdrawerlist"
                key={index}
                onClick={() => {
                  props.context.setFaqTaxonomy(faqTaxonomy.filter((f) => f.codename === faq.codename));
                  setSelected(index);
                }}
                selected={selected === index}
              >
                <Link data-cysublev="subdrawerlink" data-cy={`faqdrawerlink${index}`} to={`/faq/${faq.codename}`}>
                  <ListItemText primary={faq.name} className="subdrawer-list-item" />
                </Link>
              </ListItem>
              <Divider />
            </span>
          ))
        : null}
    </List>
  );
};

FaqDrawer.propTypes = {
  details: PropTypes.shape({
    class: PropTypes.string,
    faqTaxonomy: PropTypes.array,
  }),
};

export default withContext(FaqDrawer);
