import React, { useState, useEffect } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import { Column } from 'material-table';
import Expansion from './Utilities/TableFunctions/Expansion';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';
import { Drawer } from '@material-ui/core';
import AssemblyGroupsEndpoint from './Utilities/AssemblyGroupsEndpoint';
import AssemblyGroupsTable from './AssemblyGroupsTable';
import useFilterComponent, { FieldFormFilter, FieldFormFilterType, FilterFormParameter } from './useFilterComponent';
import AssemblyGroupsMobileTable from './AssemblyGroupsMobileTable';
import { DatagridPropsCustom } from './interfaces/datagrid-props-custom';
import { Col, Row } from 'react-flexbox-grid';
export type Assembly = {
  blc: string | null;
  localityPhone?: string | null;
  email?: string | null;
  website?: string | null;
  name: string | null;
  bahaiLocalityType: string | null;
  address: {
    city: string | null;
    state: string | null;
    zipcode: string | null;
  };
  region: string | null;
  electoralUnit: string | null;
  clusterName: string | null;
  stateName: string | null;
};
type FilterResponse = {
  name: string | string[];
  bahaiLocalityType: string[];
  blc: string | string[];
  clusterName: string | string[];
  electoralUnit: string | string[];
  stateName: string[];
  region: string[];
};
export type AssemblyData = Assembly;
const AssemblyGroups = (props: { context: { assemblyGroupsEndpoint: AssemblyGroupsEndpoint } }) => {
  const token = sessionStorage.getItem('id_token');

  const [filtersOpened, setFiltersOpened] = useState(false);
  const [defaultmessage, setDefaultmessage] = useState<JSX.Element>(<p>Currently loading data. Please wait several seconds!</p>);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<AssemblyData[]>([]);
  const [filteredData, setFilteredData] = useState<AssemblyData[]>([]);
  const [filteredDataCache, setFilteredDataCache] = useState<AssemblyData[]>([]);
  const [filterFieldsArray, setFilterFieldsArray] = useState<FieldFormFilter[]>([]);

  const filterCallback = (filterValues: FilterFormParameter) => {
    setLoading(true);
    const filteredValuesLocal = filterDataWithValues(filterValues as FilterResponse);
    setTimeout(() => {
      if (filteredValuesLocal.length !== filteredDataCache.length) {
        setFilteredData(filteredValuesLocal);
      } else {
        if (data.length === filteredValuesLocal.length) {
          setFilteredData(filteredDataCache);
        }
      }
      setFiltersOpened(false);
      setLoading(false);
    }, 100);
  };

  const [filterComponent] = useFilterComponent({ fields: filterFieldsArray, filterCallback });
  const [columns, setColumns] = React.useState<{ columns: (Column<AssemblyData> & DatagridPropsCustom<AssemblyData>)[] }>({
    columns: [],
  });

  useEffect(() => {
    const observable = props.context.assemblyGroupsEndpoint.getassemblygroups(token);
    const subscription = observable.subscribe({
      next: function (data) {
        const sortedData = data
          .map((dataRow) => {
            return {
              blc: dataRow.blc,
              name: dataRow.name,
              bahaiLocalityType: dataRow.bahaiLocalityType,
              address: {
                line1: dataRow.address?.line1,
                line2: dataRow.address?.line2,
                city: dataRow.address?.city,
                state: dataRow.address?.state,
                zipcode: dataRow.address?.zipcode,
              },
              region: dataRow.region,
              electoralUnit: dataRow.electoralUnit,
              clusterName: dataRow.clusterName,
              stateName: dataRow.stateName,
              localityPhone: dataRow.localityPhone,
              email: dataRow.email,
              website: dataRow.website,
            } as AssemblyData;
          })
          .sort((a: AssemblyData, b: AssemblyData) => {
            if (a.name && b.name) {
              return (((a.name as any) > b.name) as any) ? 1 : -1;
            }
            return 1;
          });
        setFilteredDataCache(sortedData);
        setFilteredData(sortedData);
        setData(sortedData);

        createFilterData(sortedData);
        setDefaultmessage(<p>No results found with the current criteria.</p>);
      },
      error: () => {
        setDefaultmessage(<ErrorMessageToUser />);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [props.context.assemblyGroupsEndpoint, token]);

  const createFilters = (dataUpToDate: AssemblyData[]) => {
    const bahaiLocalityTypeSet = new Set<string>();
    const regionSet = new Set<string>();
    const stateSet = new Set<string>();
    dataUpToDate.forEach((columnData) => {
      if (columnData.bahaiLocalityType) {
        bahaiLocalityTypeSet.add(columnData.bahaiLocalityType as string);
      }
      if (columnData.region) {
        regionSet.add(columnData.region as string);
      }
      if (columnData.stateName) {
        stateSet.add(columnData.stateName as string);
      }
    });
    return { bahaiLocalityTypeSet, regionSet, stateSet };
  };
  const createFilterData = (dataUpToDate: AssemblyData[]) => {
    const { bahaiLocalityTypeSet, regionSet, stateSet } = createFilters(dataUpToDate);
    const createOrUpdateNameFilter = (filterArray: FieldFormFilter[]) => {
      const index = filterArray.findIndex((a) => a.filterFieldName === 'name');
      const actualValues: FieldFormFilter = {
        filterFieldName: 'name',
        filterLabel: 'Name & Address',
        type: FieldFormFilterType.text,
      };
      if (index < 0) {
        filterArray.push(actualValues);
      } else {
        filterArray[index] = actualValues;
      }
      return filterArray;
    };
    const createOrUpdateBahaiLocalityTypeFilter = (filterArray: FieldFormFilter[], set: Set<string>) => {
      const index = filterArray.findIndex((a) => a.filterFieldName === 'bahaiLocalityType');
      const actualValues: FieldFormFilter = {
        filterFieldName: 'bahaiLocalityType',
        filterLabel: 'Type',
        type: FieldFormFilterType.select,
        select: {
          filterOptions: Array.from(set).sort(),
        },
      };
      if (index < 0) {
        filterArray.push(actualValues);
      } else {
        filterArray[index] = actualValues;
      }
      return filterArray;
    };
    const createOrUpdateBlcFilter = (filterArray: FieldFormFilter[]) => {
      const index = filterArray.findIndex((a) => a.filterFieldName === 'blc');
      const actualValues: FieldFormFilter = {
        filterFieldName: 'blc',
        filterLabel: 'Locality Code',
        type: FieldFormFilterType.text,
      };
      if (index < 0) {
        filterArray.push(actualValues);
      } else {
        filterArray[index] = actualValues;
      }
      return filterArray;
    };
    const createOrUpdateClusterNameFilter = (filterArray: FieldFormFilter[]) => {
      const index = filterArray.findIndex((a) => a.filterFieldName === 'clusterName');
      const actualValues: FieldFormFilter = {
        filterFieldName: 'clusterName',
        filterLabel: 'Cluster Code',
        type: FieldFormFilterType.text,
      };
      if (index < 0) {
        filterArray.push(actualValues);
      } else {
        filterArray[index] = actualValues;
      }
      return filterArray;
    };
    const createOrUpdateElectoralUnitFilter = (filterArray: FieldFormFilter[]) => {
      const index = filterArray.findIndex((a) => a.filterFieldName === 'electoralUnit');
      const actualValues: FieldFormFilter = {
        filterFieldName: 'electoralUnit',
        filterLabel: 'EU Code',
        type: FieldFormFilterType.text,
      };
      if (index < 0) {
        filterArray.push(actualValues);
      } else {
        filterArray[index] = actualValues;
      }
      return filterArray;
    };
    const createOrUpdateRegionFilter = (filterArray: FieldFormFilter[], set: Set<string>) => {
      const index = filterArray.findIndex((a) => a.filterFieldName === 'region');
      const actualValues: FieldFormFilter = {
        filterFieldName: 'region',
        filterLabel: 'Region',
        type: FieldFormFilterType.select,
        select: {
          filterOptions: Array.from(set).sort(),
        },
      };
      if (index < 0) {
        filterArray.push(actualValues);
      } else {
        filterArray[index] = actualValues;
      }
      return filterArray;
    };
    const createOrUpdateStateNameFilter = (filterArray: FieldFormFilter[], set: Set<string>) => {
      const index = filterArray.findIndex((a) => a.filterFieldName === 'stateName');
      const actualValues: FieldFormFilter = {
        filterFieldName: 'stateName',
        filterLabel: 'State',
        type: FieldFormFilterType.select,
        select: {
          filterOptions: Array.from(set).sort(),
        },
      };
      if (index < 0) {
        filterArray.push(actualValues);
      } else {
        filterArray[index] = actualValues;
      }
      return filterArray;
    };
    setColumns({
      columns: [
        {
          title: 'Name & Address',
          field: 'name',
          width: 320,
          valueGetter: (params) => (params.row.name ? params.row.name : ''),
          sortComparator: (v1, v2) => (v1 as string).localeCompare(v2 as string),
          render: (rowData: AssemblyData) => <Expansion rowData={rowData} compName={'AssemblyGroups'} />,
        },
        {
          title: 'Type',
          field: 'bahaiLocalityType',
          width: 220,
          valueGetter: (params) => (params.row.bahaiLocalityType ? params.row.bahaiLocalityType : ''),
          sortComparator: (v1, v2) => (v1 as string).localeCompare(v2 as string),
          render: (rowData: AssemblyData) => rowData.bahaiLocalityType,
        },
        {
          title: 'Locality Code',
          field: 'blc',
          valueGetter: (params) => (params.row.blc ? params.row.blc : ''),
          sortComparator: (v1, v2) => (v1 as string).localeCompare(v2 as string),
        },
        {
          title: 'Cluster Code',
          field: 'clusterName',
          valueGetter: (params) => (params.row.clusterName ? params.row.clusterName : ''),
          sortComparator: (v1, v2) => (v1 as string).localeCompare(v2 as string),
        },
        {
          title: 'EU Code',
          field: 'electoralUnit',
          valueGetter: (params) => (params.row.electoralUnit ? params.row.electoralUnit : ''),
          sortComparator: (v1, v2) => (v1 as string).localeCompare(v2 as string),
        },
        {
          title: 'Region',
          field: 'region',
          width: 220,
          valueGetter: (params) => (params.row.region ? params.row.region : ''),
          sortComparator: (v1, v2) => (v1 as string).localeCompare(v2 as string),
        },
        {
          title: 'State',
          field: 'stateName',
          valueGetter: (params) => (params.row.stateName ? params.row.stateName : ''),
          sortComparator: (v1, v2) => (v1 as string).localeCompare(v2 as string),
        },
      ],
    });

    setFilterFieldsArray((filterArray) => {
      filterArray = createOrUpdateNameFilter(filterArray);
      filterArray = createOrUpdateBahaiLocalityTypeFilter(filterArray, bahaiLocalityTypeSet);
      filterArray = createOrUpdateBlcFilter(filterArray);
      filterArray = createOrUpdateClusterNameFilter(filterArray);
      filterArray = createOrUpdateElectoralUnitFilter(filterArray);
      filterArray = createOrUpdateRegionFilter(filterArray, regionSet);
      filterArray = createOrUpdateStateNameFilter(filterArray, stateSet);
      return filterArray;
    });
  };

  const filterDataWithValues = (filterValues: FilterResponse) => {
    const existNameFilter = typeof filterValues.name === 'string' && filterValues.name && filterValues.name.length > 0;
    const existBahaiLocalityTypeFilter = filterValues.bahaiLocalityType && filterValues.bahaiLocalityType.length > 0;
    const existBlcFilter = typeof filterValues.blc === 'string' && filterValues.blc && filterValues.blc.length > 0;
    const existClusterNameFilter =
      typeof filterValues.clusterName === 'string' && filterValues.clusterName && filterValues.clusterName.length > 0;
    const existElectoralUnitFilter =
      typeof filterValues.electoralUnit === 'string' && filterValues.electoralUnit && filterValues.electoralUnit.length > 0;
    const existRegionFilter = Array.isArray(filterValues.region) && filterValues.region && filterValues.region.length > 0;
    const existStateNameFilter =
      Array.isArray(filterValues.stateName) && filterValues.stateName && filterValues.stateName.length > 0;
    const existAnyFilter =
      existNameFilter ||
      existBahaiLocalityTypeFilter ||
      existBlcFilter ||
      existClusterNameFilter ||
      existElectoralUnitFilter ||
      existRegionFilter ||
      existStateNameFilter;
    if (existAnyFilter) {
      const filteredDataCacheArray: Assembly[] = [];
      for (let i = 0; i < filteredDataCache.length; i++) {
        const record = filteredDataCache[i];
        let validRecords = true;
        let atLeastOneFilteredValue = false;
        if (existNameFilter) {
          if (typeof record.name === 'string' && record.name && record.name.length > 0) {
            if (record.name.toLowerCase) {
              const isValid =
                record.name?.toLowerCase().indexOf((filterValues.name as string).toLowerCase()) > -1 ||
                (record.address?.city as string)?.toLowerCase().indexOf((filterValues.name as string).toLowerCase()) > -1 ||
                (record.address?.state as string)?.toLowerCase().indexOf((filterValues.name as string).toLowerCase()) > -1 ||
                (record.address?.zipcode as string)?.toLowerCase().indexOf((filterValues.name as string).toLowerCase()) > -1;
              if (isValid) {
                atLeastOneFilteredValue = true;
              }
              validRecords = validRecords && isValid;
            }
          }
        }
        if (existBahaiLocalityTypeFilter) {
          if (record.bahaiLocalityType && record.bahaiLocalityType.length > 0) {
            const isValid = filterValues.bahaiLocalityType.some(
              (areaStateFilterValues) => record.bahaiLocalityType === areaStateFilterValues
            );
            if (isValid) {
              atLeastOneFilteredValue = true;
            }
            validRecords = validRecords && isValid;
          }
        }
        if (existBlcFilter) {
          if (typeof record.blc === 'string' && record.blc && record.blc.length > 0) {
            if (record.blc.toLowerCase) {
              const isValid = record.blc?.toLowerCase().indexOf((filterValues.blc as string).toLowerCase()) > -1;
              if (isValid) {
                atLeastOneFilteredValue = true;
              }
              validRecords = validRecords && isValid;
            }
          }
        }
        if (existClusterNameFilter) {
          if (typeof record.clusterName === 'string' && record.clusterName && record.clusterName.length > 0) {
            if (record.clusterName.toLowerCase) {
              const isValid = record.clusterName?.toLowerCase().indexOf((filterValues.clusterName as string).toLowerCase()) > -1;
              if (isValid) {
                atLeastOneFilteredValue = true;
              }
              validRecords = validRecords && isValid;
            }
          }
        }
        if (existElectoralUnitFilter) {
          if (typeof record.electoralUnit === 'string' && record.electoralUnit && record.electoralUnit.length > 0) {
            if (record.electoralUnit.toLowerCase) {
              const isValid =
                record.electoralUnit?.toLowerCase().indexOf((filterValues.electoralUnit as string).toLowerCase()) > -1;
              if (isValid) {
                atLeastOneFilteredValue = true;
              }
              validRecords = validRecords && isValid;
            }
          }
        }
        if (existRegionFilter) {
          if (record.region && record.region.length > 0) {
            const isValid = filterValues.region.some((areaStateFilterValues) => record.region === areaStateFilterValues);
            if (isValid) {
              atLeastOneFilteredValue = true;
            }
            validRecords = validRecords && isValid;
          }
        }
        if (existStateNameFilter) {
          if (record.stateName && record.stateName.length > 0) {
            const isValid = filterValues.stateName.some((states) => record.stateName === states);
            if (isValid) {
              atLeastOneFilteredValue = true;
            }
            validRecords = validRecords && isValid;
          }
        }
        if (validRecords && atLeastOneFilteredValue) {
          filteredDataCacheArray.push(record);
        }
      }
      return filteredDataCacheArray;
    } else {
      return filteredDataCache;
    }
  };

  return (
    <div>
      <h1>Assemblies and Groups</h1>

      <Drawer anchor="right" open={filtersOpened} onClose={() => setFiltersOpened(!filtersOpened)} keepMounted={true}>
        <div>
          <h1 className="cluster-contact-title">Assembly Groups</h1>

          {filterComponent}
        </div>
      </Drawer>
      <Row>
        <Col lgOffset={9} lg={3} md={4} mdOffset={8} sm={12}>
          <div className="spaced">
            <button
              className="primary-button  w-100"
              data-cy={'open-filters'}
              onClick={(e) => {
                e.preventDefault();
                setFiltersOpened(!filtersOpened);
              }}
            >
              Open filters
            </button>
          </div>
        </Col>
      </Row>
      {loading && <p>Data is loading</p>}
      <div>
        <div className="show-table">
          <p>You can search and filter the data:</p>
          <div data-cy="displaydirectory">
            <AssemblyGroupsTable
              defaultmessage={defaultmessage}
              columns={columns}
              filteredData={filteredData}
            ></AssemblyGroupsTable>
          </div>
        </div>
        <div className="hide-table">
          <p>You can search and filter the data:</p>
          {columns.columns.length > 0 && (
            <AssemblyGroupsMobileTable
              filteredData={filteredData}
              columns={columns}
              defaultmessage={defaultmessage}
            ></AssemblyGroupsMobileTable>
          )}
        </div>
      </div>
    </div>
  );
};

export default withContext(AssemblyGroups);
