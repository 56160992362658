import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { withRouter } from 'react-router-dom';

const FeedbackLink = () => {
  return (
    <a
      href="https://concourse.atlassian.net/servicedesk/customer/portal/11/group/38/create/133"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Tooltip title="Feedback" aria-label="feedback" role="link">
        <div className="navbarItem white-text small-top-padding">
          <IconButton color="inherit" className="navbarIcon">
            <HelpOutlineOutlinedIcon />
          </IconButton>
          <p className="white-text xs-text">FEEDBACK</p>
        </div>
      </Tooltip>
    </a>
  );
};
export default withRouter(FeedbackLink);
