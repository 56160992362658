import React, { useEffect, useState } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';
import { Row, Col } from 'react-flexbox-grid';
import { withRouter, Link } from 'react-router-dom';
import { dateStringToDisplayDate, buildFullName } from '../Utilities/UtilityFunctions';
import { of, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CircularProgress } from '@material-ui/core';

const ChildView = (props) => {
  const childIndexString = props.match.params.childIndex;
  const history = props.history;
  const childIndex = parseInt(childIndexString);

  const setPageTitle = props.setPageTitle;
  useEffect(() => setPageTitle('Child Information'), [setPageTitle]);
  const emailProxy = props.context.emailProxy;

  const [badErrorMessage, setBadErrorMessage] = useState(null);
  const [showCircularProgress, setShowCircularProgress] = useState(true);
  const [childInformation, setChildInformation] = useState({});
  const [emailInformation, setEmailInformation] = useState({});

  useEffect(() => {
    if (!(childIndex >= 0)) {
      history.push('/myprofile/household');
    }
  }, [childIndex, history]);

  const myProfileProxy = props.context.myProfileProxy;
  const householdProxy = props.context.householdProxy;

  useEffect(() => {
    const householdChildmembersObservable = householdProxy.membersUnder15YearsOld();
    const childInformationObservable = householdChildmembersObservable.pipe(
      switchMap((childMembersResponse) => {
        const selectedChild = childMembersResponse[childIndex];
        const selectedChildIdn = selectedChild?.idn;

        if (!(selectedChildIdn >= 0)) {
          history.push('/myprofile/household');
          return of({});
        }
        return myProfileProxy.getChild(selectedChildIdn);
      })
    );

    const childEmailInformationObservable = householdChildmembersObservable.pipe(
      switchMap((childMembersResponse) => {
        const selectedChild = childMembersResponse[childIndex];
        const selectedChildIdn = selectedChild?.idn;

        if (!(selectedChildIdn >= 0)) {
          history.push('/myprofile/household');
          return of({});
        }
        return emailProxy.getChild(selectedChildIdn);
      })
    );

    const combinedObservables = combineLatest([childInformationObservable, childEmailInformationObservable]);

    const subscription = combinedObservables.subscribe({
      next: ([childInformationResponse, childEmailResponse]) => {
        setChildInformation(childInformationResponse);
        setEmailInformation(childEmailResponse);
        setShowCircularProgress(false);
      },
      error: () => {
        setBadErrorMessage(<ErrorMessageToUser />);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [myProfileProxy, householdProxy, childIndex, history, emailProxy]);

  const parent1FullName = childInformation.parentSelf ? buildFullName(childInformation.parentSelf) : 'Loading...';
  const parent2FullName = childInformation.parentOther?.id > 0 ? buildFullName(childInformation.parentOther) : '';

  return (
    <div>
      {badErrorMessage ?? (
        <div>
          {showCircularProgress ? (
            <CircularProgress data-cy="child-view-circular-progress" className="loading-animation" size={40} />
          ) : null}
          <div className="profile-box">
            <h4>Parent/Legal Guardian Information</h4>
            <Row between="sm">
              <Col sm={4}>
                <p>Parent/Legal Guardian 1</p>
              </Col>
              <Col sm={3}>
                <p>{childInformation.parentSelf?.id ?? 'Loading ...'}</p>
              </Col>
              <Col sm={5}>
                <p>
                  <strong>{parent1FullName}</strong>
                </p>
              </Col>
            </Row>
            {childInformation.parentOther?.id > 0 ? (
              <Row between="sm">
                <Col sm={4} className="inline-vertical-center">
                  <p>Parent/Legal Guardian 2</p>
                </Col>
                <Col sm={3}>
                  <p>{childInformation.parentOther?.id ?? ''}</p>
                </Col>
                <Col sm={5}>
                  <p>
                    <strong>{parent2FullName}</strong>
                  </p>
                </Col>
              </Row>
            ) : null}
            <div className="profile-background">
              <h4>Residential Address</h4>
              <Row className="small-bottom-padding">
                <Col sm={4}>
                  <p className="faded-text small-text">In Care Of</p>
                  <p>
                    <strong>{childInformation.physicalAddress?.careOf ?? ''}</strong>
                  </p>
                </Col>
              </Row>
              <Row className="small-bottom-padding">
                <Col sm={4}>
                  <p className="faded-text small-text">Street Address</p>
                  <p>
                    <strong>{childInformation.physicalAddress?.line1 ?? ''}</strong>
                  </p>
                </Col>
                <Col sm={4}>
                  <p className="faded-text small-text">Address Line two</p>
                  <p>
                    <strong>{childInformation.physicalAddress?.line2 ?? ''}</strong>
                  </p>
                </Col>
                <Col sm={4}>
                  <p className="faded-text small-text">Address Line three</p>
                  <p>
                    <strong>{childInformation.physicalAddress?.line3 ?? ''}</strong>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <p className="faded-text small-text">City</p>
                  <p>
                    <strong>{childInformation.physicalAddress?.city ?? ''}</strong>
                  </p>
                </Col>
                <Col sm={4}>
                  <p className="faded-text small-text">State</p>
                  <p>
                    <strong>{childInformation.physicalAddress?.state ?? ''}</strong>
                  </p>
                </Col>
                <Col sm={4}>
                  <p className="faded-text small-text">Zip Code</p>
                  <p>
                    <strong>{childInformation.physicalAddress?.zipCode ?? ''}</strong>
                  </p>
                </Col>
              </Row>
              {childInformation.mailingAddress?.pkAddress !== childInformation.physicalAddress?.pkAddress ? (
                <div>
                  <h4>Mailing Address</h4>
                  <Row className="small-bottom-padding">
                    <Col sm={4}>
                      <p className="faded-text small-text">In Care Of</p>
                      <p>
                        <strong>{childInformation.mailingAddress?.careOf ?? ''}</strong>
                      </p>
                    </Col>
                  </Row>
                  <Row className="small-bottom-padding">
                    <Col sm={4}>
                      <p className="faded-text small-text">Street Address</p>
                      <p>
                        <strong>{childInformation.mailingAddress?.line1 ?? ''}</strong>
                      </p>
                    </Col>
                    <Col sm={4}>
                      <p className="faded-text small-text">Address Line two</p>
                      <p>
                        <strong>{childInformation.mailingAddress?.line2 ?? ''}</strong>
                      </p>
                    </Col>
                    <Col sm={4}>
                      <p className="faded-text small-text">Address Line three</p>
                      <p>
                        <strong>{childInformation.mailingAddress?.line3 ?? ''}</strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <p className="faded-text small-text">City</p>
                      <p>
                        <strong>{childInformation.mailingAddress?.city ?? ''}</strong>
                      </p>
                    </Col>
                    <Col sm={4}>
                      <p className="faded-text small-text">State</p>
                      <p>
                        <strong>{childInformation.mailingAddress?.state ?? ''}</strong>
                      </p>
                    </Col>
                    <Col sm={4}>
                      <p className="faded-text small-text">Zip Code</p>
                      <p>
                        <strong>{childInformation.mailingAddress?.zipCode ?? ''}</strong>
                      </p>
                    </Col>
                  </Row>
                </div>
              ) : null}
              <Row>
                <Col>
                  {childInformation.requestNoMail ? (
                    <p className="medium-left-padding">You have requested No Mail by Post</p>
                  ) : (
                    <p className="medium-left-padding">
                      <em className="small-text">
                        Your child’s mail is sent to the address above. You may update your mailing address on the Contact Info
                        page or by contacting the Membership Office at{' '}
                        <a href="mailto:membership@usbng.org">membership@usbnc.org</a>.
                      </em>
                    </p>
                  )}
                </Col>
              </Row>
            </div>
            <h4 className="medium-top-padding">Child’s Name</h4>
            <Row className="small-bottom-padding" between="xs">
              <Col sm>
                <p className="faded-text small-text">Title</p>
                <p>
                  <strong>{childInformation.title ?? ''}</strong>
                </p>
              </Col>
              <Col sm={3}>
                <p className="faded-text small-text">First Name</p>
                <p>
                  <strong>{childInformation.firstName ?? ''}</strong>
                </p>
              </Col>
              <Col sm={3}>
                <p className="faded-text small-text">Middle Name</p>
                <p>
                  <strong>{childInformation.middleName ?? ''}</strong>
                </p>
              </Col>
              <Col sm={3}>
                <p className="faded-text small-text">Last Name</p>
                <p>
                  <strong>{childInformation.lastName}</strong>
                </p>
              </Col>
              <Col xs>
                <p className="faded-text small-text">Suffix</p>
                <p>
                  <strong>{childInformation.suffix ?? ''}</strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <p className="faded-text small-text">Known as/Nickname</p>
                <p>
                  <strong>{childInformation.nickName ?? ''}</strong>
                </p>
              </Col>
            </Row>
            <div className="profile-background">
              <h4>Personal Information</h4>
              <Row>
                <Col sm={6}>
                  <Row>
                    <Col sm={5} className="profile-alignment inline-vertical-center">
                      <p className="faded-text small-text">Gender</p>
                    </Col>
                    <Col sm={7}>
                      <p>
                        <strong>{childInformation.gender}</strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={5} className="profile-alignment inline-vertical-center">
                      <p className="faded-text small-text">Date of Birth</p>
                    </Col>
                    <Col sm={7}>
                      <p>
                        <strong>
                          {childInformation.birthDate?.length > 0 ? dateStringToDisplayDate(childInformation.birthDate) : ''}
                        </strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={5} className="profile-alignment inline-vertical-center">
                      <p className="faded-text small-text">City of Birth</p>
                    </Col>
                    <Col sm={7}>
                      <p>
                        <strong>{childInformation.cityOfBirth ?? ''}</strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={5} className="profile-alignment inline-vertical-center">
                      <p className="faded-text small-text">State of Birth</p>
                    </Col>
                    <Col sm={7}>
                      <p>
                        <strong>{childInformation.stateOfBirth ?? ''}</strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={5} className="profile-alignment inline-vertical-center">
                      <p className="faded-text small-text">Country of Birth</p>
                    </Col>
                    <Col sm={7}>
                      <p>
                        <strong>{childInformation.countryOfBirth}</strong>
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col sm={6}>
                  <p className="faded-text small-text">Race / Ethnicity</p>
                  {childInformation.raceEthnicitySelections
                    ? childInformation.raceEthnicitySelections.map((raceEthnicitySelection, raceEthnicitySelectionIndex) => (
                        <p key={raceEthnicitySelectionIndex}>
                          <strong>{raceEthnicitySelection}</strong>
                        </p>
                      ))
                    : 'none'}
                </Col>
              </Row>
            </div>
            <h4 className="medium-top-padding">Emergency Contact Information</h4>
            <Row>
              <Col sm={6}>
                <p className="faded-text small-text">Name</p>
                <p>
                  <strong>{childInformation.emergencyContact?.emergencyContactName ?? ''}</strong>
                </p>
              </Col>
              <Col sm={6}>
                <p className="faded-text small-text">Relationship</p>
                <p>
                  <strong>{childInformation.emergencyContact?.emergencyContactRelationship ?? ''}</strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <p className="faded-text small-text">Email</p>
                <p>
                  <strong>{childInformation.emergencyContact?.emergencyContactEmail ?? ''}</strong>
                </p>
              </Col>
              <Col sm={6}>
                <p className="faded-text small-text">Phone</p>
                <p>
                  <strong>{childInformation.emergencyContact?.emergencyContactPhone ?? ''}</strong>
                </p>
              </Col>
            </Row>
            <div className="profile-background">
              <h4>Membership information</h4>
              <Row>
                <Col sm={5} className="profile-alignment inline-vertical-center">
                  <p className="faded-text small-text">Parent Sign Date</p>
                </Col>
                <Col sm={7}>
                  <p>
                    <strong>
                      {childInformation.parentSignDate?.length > 0
                        ? dateStringToDisplayDate(childInformation.parentSignDate)
                        : ''}
                    </strong>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={5} className="profile-alignment inline-vertical-center">
                  <p className="faded-text small-text">Recognition Date</p>
                </Col>
                <Col sm={7}>
                  <p>
                    <strong>
                      {childInformation.recognitionDate?.length > 0
                        ? dateStringToDisplayDate(childInformation.recognitionDate)
                        : ''}
                    </strong>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={5} className="profile-alignment inline-vertical-center">
                  <p className="faded-text small-text">Recognition Type</p>
                </Col>
                <Col sm={7}>
                  <p>
                    <strong>{childInformation.recognitionType ?? ''}</strong>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={5} className="profile-alignment inline-vertical-center">
                  <p className="faded-text small-text">Recognition Community</p>
                </Col>
                <Col sm={7}>
                  <p>
                    <strong>{childInformation.recognitionCommunity ?? ''}</strong>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={5} className="profile-alignment inline-vertical-center">
                  <p className="faded-text small-text">Member Type</p>
                </Col>
                <Col sm={7}>
                  <p>
                    <strong>{childInformation.memberType ?? ''}</strong>
                  </p>
                </Col>
              </Row>
              {childInformation.isRefugee ? (
                <Row>
                  <Col sm={5} className="profile-alignment inline-vertical-center">
                    <p className="faded-text small-text">Status</p>
                  </Col>
                  <Col sm={7}>
                    <p>
                      <strong>Child is Refugee</strong>
                    </p>
                  </Col>
                </Row>
              ) : null}
            </div>
            <h4 className="medium-top-padding">Language Preference</h4>
            <Row>
              <Col sm={12}>
                <p>
                  <strong>{childInformation.languagePreference}</strong>
                </p>
              </Col>
            </Row>
            <div className="profile-background">
              <h4>Phone Numbers</h4>
              <Row>
                <Col sm={4}>
                  <p className="faded-text small-text">Home Phone</p>
                  <p>
                    <strong>{childInformation.homePhone ?? ''}</strong>
                  </p>
                </Col>
                <Col sm={4}>
                  <p className="faded-text small-text">Cell Phone</p>
                  <p>
                    <strong>{childInformation.cellPhone ?? ''}</strong>
                  </p>
                </Col>
              </Row>
            </div>
            <h4 className="medium-top-padding">Email</h4>
            <Row>
              <Col sm={4}>
                <p className="faded-text small-text">Email</p>
                <p>
                  <strong>{emailInformation.email ?? ''}</strong>
                </p>
                {emailInformation.emailPending && (
                  <p className="small-text confirmation">
                    <em>The following email address is waiting for you to confirm: {emailInformation.emailPending}.</em>
                  </p>
                )}
              </Col>
              <Col sm={8} className="small-text inline-vertical-center">
                <p>
                  <em>This address is used for correspondence from Bahá’í agencies.</em>
                </p>
              </Col>
            </Row>
          </div>
          <div className="medium-top-margin end">
            <Link to={`/myprofile/edit-child-information/${childIndex}`} data-cy="editbutton" className="primary-button">
              Edit
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(withContext(ChildView));
