import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { NavLink, withRouter } from 'react-router-dom';
import ProfileFunction from './Utilities/ProfileFunction';
import withContext from '../ContextAPI/Context_HOC';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';
import OverseasProfile from './OverseasContactInfo';
import EditContactInfo from './EditContactInfo';
import PersonalInfo from './PersonalInfo';
import MembershipInfo from './MembershipInfo';
import HouseholdInfo from './HouseholdInfo';
import ChildRegistration from './ChildRegistration';
import ChildView from './ChildView';
import ChildEdit from './ChildEdit';
import SecurityQuestions from './SecurityQuestions';
import ChangePassword from './ChangePassword';
import EmailSettings from './EmailSettings';
import AuxiliaryBoard from './AuxiliaryBoard';
import UnitConvComp from './UnitConvComp';
import HuquqRep from './HuquqRep';
import MapInstruction from './MapInstruction';
import ChildRegDisclosures from './ChildRegDisclosures';
import EditResidentialController from './EditResidential/00_EditResidentialController';
import ClusterInformation from './ClusterInformation';
import { SwipeableDrawer } from '@mui/material';
import DigitalIdCard from './DigitalIdCard';

const Profile = (props) => {
  const [errorSuccessMessage, setErrorSuccessMessage] = useState('');
  const profilefn = new ProfileFunction();
  const auth = props.context.authService;
  const decodedToken = auth.getDecodedToken();
  const token = auth.getToken();

  const profileinfo = profilefn.getprofileinfo(decodedToken);
  const [name, setName] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const contentNameFromUrls = props.location.pathname;
  const [digitalIdCardOpen, setDigitalIdCardOpen] = useState(false);

  useEffect(() => {
    if (errorSuccessMessage) {
      const timeout = setTimeout(() => setErrorSuccessMessage(''), 5000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [errorSuccessMessage]);

  useEffect(() => {
    const isWrongRedirectUrl =
      !profileinfo.blc &&
      contentNameFromUrls !== '/myprofile/security-QA' &&
      contentNameFromUrls !== '/myprofile/email-settings' &&
      contentNameFromUrls !== '/myprofile/changepassword' &&
      contentNameFromUrls !== '/myprofile/overseas'
        ? true
        : false;

    if (!profileinfo.blc && contentNameFromUrls === '/myprofile') {
      props.history.push('/myprofile/overseas');
    }

    if (contentNameFromUrls === '/myprofile' && profileinfo.blc) {
      setPageTitle('');
    }

    if (contentNameFromUrls === '/myprofile/overseas' && profileinfo.blc) {
      props.history.push('/myprofile/contactinfo');
    }

    if (isWrongRedirectUrl) {
      props.history.push('/myprofile/overseas');
    }
  }, [contentNameFromUrls, profileinfo.blc, props.history, props.context]);

  const myProfileProxy = props.context.myProfileProxy;
  useEffect(() => {
    const observable = myProfileProxy.get();
    myProfileProxy.refresh();
    const subscription = observable.subscribe({
      next: (res) => setName(`${res.firstName ?? ''} ${res.lastName ?? ''}`),
      error: () => setErrorMessage(<ErrorMessageToUser />),
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [myProfileProxy, contentNameFromUrls]);

  return (
    <div>
      <Row className="top-margin">
        <Col sm={3} className="profile-alignment">
          <h1>My Profile{pageTitle !== '' ? ':' : null}</h1>
        </Col>
        <Col sm={9} className="start">
          <h1>{pageTitle}</h1>
        </Col>
      </Row>
      <div>{errorMessage}</div>
      <Row top="xs">
        <Col md={3}>
          <div className="grey-box" data-cy="ProfileNav">
            <Row>
              <Col xs={12} sm={6} className="profile-alignment">
                <p>Name</p>
              </Col>
              <Col xs={12} sm={6} className="inline-vertical-center">
                <p data-cy="myprofilename" className="text-ellipsis" title={name ?? profileinfo.entityName}>
                  <strong>{name ?? profileinfo.entityName}</strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} className="profile-alignment">
                <p>Bahá’í ID</p>
              </Col>
              <Col xs={12} sm={6} className="inline-vertical-center">
                <p>
                  <strong>{profileinfo.bid}</strong>
                </p>
              </Col>
            </Row>
            {profileinfo.blc ? (
              <Row>
                <Col xs={12} sm={6} className="profile-alignment">
                  <p>Locality</p>
                </Col>
                <Col xs={12} sm={6} className="inline-vertical-center">
                  <p>
                    <strong>{profileinfo.blc}</strong>
                  </p>
                </Col>
              </Row>
            ) : null}
            {profileinfo.eucode ? (
              <Row>
                <Col xs={12} sm={6} className="profile-alignment">
                  <p>Electoral Unit</p>
                </Col>
                <Col xs={12} sm={6} className="inline-vertical-center">
                  <p>
                    <strong>{profileinfo.eucode}</strong>
                  </p>
                </Col>
              </Row>
            ) : null}
            {profileinfo.cluster ? (
              <Row>
                <Col xs={12} sm={6} className="profile-alignment">
                  <p>Cluster</p>
                </Col>
                <Col xs={12} sm={6} className="inline-vertical-center">
                  <p>
                    <strong>{profileinfo.cluster}</strong>
                  </p>
                </Col>
              </Row>
            ) : null}
            {profileinfo.region ? (
              <>
                <Row>
                  <Col xs={12} sm={6} className="profile-alignment">
                    <p>Region</p>
                  </Col>
                  <Col xs={12} sm={6} className="inline-vertical-center">
                    <p>
                      <strong>{profileinfo.region}</strong>
                    </p>
                  </Col>
                </Row>
                <hr />
              </>
            ) : null}
            {profileinfo.blc ? (
              <p className={contentNameFromUrls === '/myprofile' ? 'end small-top-margin profile-link' : 'end small-top-margin'}>
                <NavLink className="plain-link" to="/myprofile">
                  Bahá’í Community Maps
                </NavLink>
              </p>
            ) : null}
            <SwipeableDrawer
              anchor="bottom"
              open={digitalIdCardOpen}
              onClose={() => setDigitalIdCardOpen(false)}
              onOpen={() => undefined}
            >
              <DigitalIdCard context={props.context} setDigitalIdCardOpen={setDigitalIdCardOpen} />
            </SwipeableDrawer>
            <p className={'end small-top-margin'}>
              <NavLink
                className="plain-link"
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  setDigitalIdCardOpen(true);
                }}
              >
                Digital ID Card
              </NavLink>
            </p>

            <hr />
            {profileinfo.blc ? (
              <span>
                <p className={contentNameFromUrls === '/myprofile/contactinfo' ? 'end profile-link' : 'end'}>
                  <NavLink className="plain-link" data-cy="updateinfoLink" to="/myprofile/contactinfo">
                    Contact Information
                  </NavLink>
                </p>
                <p className={contentNameFromUrls === '/myprofile/personalinfo' ? 'end profile-link' : 'end'}>
                  <NavLink className="plain-link" to="/myprofile/personalinfo">
                    Personal Information
                  </NavLink>
                </p>
                <p className={contentNameFromUrls === '/myprofile/membershipinfo' ? 'end profile-link' : 'end'}>
                  <NavLink className="plain-link" to="/myprofile/membershipinfo">
                    Membership Information
                  </NavLink>
                </p>
                <p className={contentNameFromUrls === '/myprofile/household' ? 'end profile-link' : 'end'}>
                  <NavLink className="plain-link" to="/myprofile/household">
                    Household Information
                  </NavLink>
                </p>
                <span data-cy="ChildReg">
                  {
                    <p
                      className={
                        contentNameFromUrls === '/myprofile/child-registration' ||
                        contentNameFromUrls === '/myprofile/child-registration-agreement'
                          ? 'end profile-link'
                          : 'end'
                      }
                    >
                      <NavLink className="plain-link" to="/myprofile/child-registration-agreement">
                        Child Registration
                      </NavLink>
                    </p>
                  }
                </span>
              </span>
            ) : (
              <p className={contentNameFromUrls === '/myprofile/overseas' ? 'end profile-link' : 'end'}>
                <NavLink className="plain-link" data-cy="OverseasLink" to="/myprofile/overseas">
                  Contact Information
                </NavLink>
              </p>
            )}
            <hr />
            {!profileinfo.entityName ? (
              <p className={contentNameFromUrls === '/myprofile/security-QA' ? 'end profile-link' : 'end'}>
                <NavLink className="plain-link" data-cy="updatesecurityLink" to="/myprofile/security-QA">
                  Change Security Questions
                </NavLink>
              </p>
            ) : null}
            <p className={contentNameFromUrls === '/myprofile/changepassword' ? 'end profile-link' : 'end'}>
              <NavLink className="plain-link" data-cy="updatepasswordLink" to="/myprofile/changepassword">
                Change Your Password
              </NavLink>
            </p>
            <p className={contentNameFromUrls === '/myprofile/email-settings' ? 'end profile-link' : 'end'}>
              <NavLink className="plain-link" to="/myprofile/email-settings">
                Change Email Settings
              </NavLink>
            </p>
            {profileinfo.blc ? (
              <span>
                <hr />
                <p className={contentNameFromUrls === '/myprofile/AuxiliaryBoard' ? 'end profile-link' : 'end'}>
                  <NavLink className="plain-link" to="/myprofile/AuxiliaryBoard">
                    Auxiliary Board Members
                  </NavLink>
                </p>
                <p className={contentNameFromUrls === '/myprofile/cluster-information' ? 'end profile-link' : 'end'}>
                  <NavLink className="plain-link" to="/myprofile/cluster-information">
                    Cluster information
                  </NavLink>
                </p>
                <p className={contentNameFromUrls === '/myprofile/UnitConvention' ? 'end profile-link' : 'end'}>
                  <NavLink className="plain-link" data-testid="unit-convention-root-links" to="/myprofile/UnitConvention">
                    Unit Convention Information
                  </NavLink>
                </p>
                <p className={contentNameFromUrls === '/myprofile/huququllah' ? 'end profile-link' : 'end'}>
                  <NavLink className="plain-link" data-testid="huquq-root-links" to="/myprofile/huququllah">
                    My Ḥuqúqu’lláh Contacts
                  </NavLink>
                </p>
              </span>
            ) : null}
          </div>
        </Col>
        <Col md={9}>
          {errorSuccessMessage}
          {profileinfo.blc ? (
            contentNameFromUrls === '/myprofile/contactinfo' ? (
              <EditContactInfo
                history={props.history}
                inputProps={{ setErrorSuccess: setErrorSuccessMessage }}
                setPageTitle={setPageTitle}
              />
            ) : contentNameFromUrls === '/myprofile/personalinfo' ? (
              <PersonalInfo setPageTitle={setPageTitle} />
            ) : contentNameFromUrls === '/myprofile/membershipinfo' ? (
              <MembershipInfo setPageTitle={setPageTitle} blc={profileinfo.blc} token={token} />
            ) : contentNameFromUrls === '/myprofile/household' ? (
              <HouseholdInfo setPageTitle={setPageTitle} token={token} />
            ) : contentNameFromUrls === '/myprofile/child-registration-agreement' ? (
              <ChildRegDisclosures setPageTitle={setPageTitle} />
            ) : contentNameFromUrls === '/myprofile/child-registration' ? (
              <ChildRegistration setPageTitle={setPageTitle} />
            ) : contentNameFromUrls?.startsWith('/myprofile/child-information/') ? (
              <ChildView setPageTitle={setPageTitle} />
            ) : contentNameFromUrls?.startsWith('/myprofile/edit-child-information/') ? (
              <ChildEdit setPageTitle={setPageTitle} />
            ) : contentNameFromUrls === '/myprofile/security-QA' ? (
              <SecurityQuestions setPageTitle={setPageTitle} />
            ) : contentNameFromUrls === '/myprofile/email-settings' ? (
              <EmailSettings setPageTitle={setPageTitle} />
            ) : contentNameFromUrls === '/myprofile/changepassword' ? (
              <ChangePassword setPageTitle={setPageTitle} />
            ) : contentNameFromUrls === '/myprofile/AuxiliaryBoard' ? (
              <AuxiliaryBoard setPageTitle={setPageTitle} blc={profileinfo.blc} token={token} />
            ) : contentNameFromUrls === '/myprofile/cluster-information' ? (
              <ClusterInformation setPageTitle={setPageTitle} blc={profileinfo.blc} token={token} />
            ) : contentNameFromUrls === '/myprofile/UnitConvention' ? (
              <UnitConvComp setPageTitle={setPageTitle} />
            ) : contentNameFromUrls === '/myprofile/huququllah' ? (
              <HuquqRep setPageTitle={setPageTitle} region={profileinfo.region} />
            ) : contentNameFromUrls === '/myprofile/edit-residential-address' ? (
              <EditResidentialController setPageTitle={setPageTitle} />
            ) : (
              <span>
                <MapInstruction />
                <iframe
                  title="MyProfileMap"
                  className="grey-box"
                  data-cy="map"
                  src={`${process.env.REACT_APP_MAP_API_URL}${process.env.REACT_APP_MAP_API_LOCALITY_QUERY_PREFIX}${profileinfo.blc}`}
                  height="600px"
                  width="100%"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                />
              </span>
            )
          ) : contentNameFromUrls === '/myprofile/security-QA' ? (
            <SecurityQuestions setPageTitle={setPageTitle} />
          ) : contentNameFromUrls === '/myprofile/email-settings' ? (
            <EmailSettings setPageTitle={setPageTitle} />
          ) : contentNameFromUrls === '/myprofile/changepassword' ? (
            <ChangePassword setPageTitle={setPageTitle} />
          ) : contentNameFromUrls === '/myprofile/overseas' ? (
            <OverseasProfile setPageTitle={setPageTitle} />
          ) : (
            <OverseasProfile setPageTitle={setPageTitle} />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(withContext(Profile));
