import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import withContext from '../../ContextAPI/Context_HOC';
import PlannedGivingIcon from '../../assets/img/FundDev/PlannedGiving.png';
import CharitableGiftAnnuities from '../../assets/img/PlannedGiving/CharitableGiftAnnuities.png';
import WillsAndBequests from '../../assets/img/PlannedGiving/WillsAndBequests.png';
import Securities from '../../assets/img/PlannedGiving/Securities.png';
import Retirement from '../../assets/img/PlannedGiving/Retirement.png';
import DonorAdvisedFunds from '../../assets/img/PlannedGiving/DonorAdvisedFunds.png';
import RealEstate from '../../assets/img/PlannedGiving/RealEstate.png';
import Contact from '../../assets/img/PlannedGiving/PlannedGivingContact.png';

class PlannedGiving extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={3} lg={2}>
                <img src={PlannedGivingIcon} alt="Baha’i Fund" className="fund-logo" />
              </Col>
              <Col xs={9} lg={10}>
                <h1>Planned Giving</h1>
              </Col>
            </Row>
            <p>
              There are ways one can give to the Bahá’í Fund, both in this realm of existence and in the next. This page will find
              links to information on the most popular ways we can give to the Fund through Planned Giving.
            </p>
          </Col>
          <Col xs={12} md={6}>
            <a href="https://s3.amazonaws.com/bahai-web/tab/forms/treas-legacy-through-planned-giving.pdf" className="plain-link">
              <Row className="fund-rows">
                <Col xs={3} lg={2}>
                  <img src={PlannedGivingIcon} alt="Leaving a Legacy" className="fund-icons" />
                </Col>
                <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                  <h4>Leaving a Legacy to the Bahá’í Faith Through Planned Giving (PDF)</h4>
                </Col>
              </Row>
            </a>
            <br />
            <Link to="/resources/planned-giving/annuities" className="plain-link">
              <Row className="fund-rows">
                <Col xs={3} lg={2}>
                  <img src={CharitableGiftAnnuities} alt="Charitable Gift Annuities" className="fund-icons" />
                </Col>
                <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                  <h4>Charitable Gift Annuities (CGA)</h4>
                </Col>
              </Row>
            </Link>
            <br />
            <Link to="/resources/planned-giving/bequests" className="plain-link">
              <Row className="fund-rows">
                <Col xs={3} lg={2}>
                  <img src={WillsAndBequests} alt="Bequests and Wills" className="fund-icons" />
                </Col>
                <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                  <h4>Bequests/Wills</h4>
                </Col>
              </Row>
            </Link>
            <br />
            <Link to="/resources/planned-giving/securities" className="plain-link">
              <Row className="fund-rows">
                <Col xs={3} lg={2}>
                  <img src={Securities} alt="Securities" className="fund-icons" />
                </Col>
                <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                  <h4>Securities</h4>
                </Col>
              </Row>
            </Link>
            <br />
            <Link to="/resources/planned-giving/retirement" className="plain-link">
              <Row className="fund-rows">
                <Col xs={3} lg={2}>
                  <img src={Retirement} alt="Individual Retirement Account (IRA) Charitable Rollover" className="fund-icons" />
                </Col>
                <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                  <h4>Individual Retirement Account (IRA) Charitable Rollover</h4>
                </Col>
              </Row>
            </Link>
            <br />
            <Link to="/resources/planned-giving/donor-funds" className="plain-link">
              <Row className="fund-rows">
                <Col xs={3} lg={2}>
                  <img src={DonorAdvisedFunds} alt="Donor Advised Funds" className="fund-icons" />
                </Col>
                <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                  <h4>Donor Advised Funds</h4>
                </Col>
              </Row>
            </Link>
            <br />
            <Link to="/resources/planned-giving/real-estate" className="plain-link">
              <Row className="fund-rows">
                <Col xs={3} lg={2}>
                  <img src={RealEstate} alt="Real Estate" className="fund-icons" />
                </Col>
                <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                  <h4>Real Estate</h4>
                </Col>
              </Row>
            </Link>
            <br />
            <Link to="/resources/planned-giving/planned-giving-contact" className="plain-link">
              <Row className="fund-rows">
                <Col xs={3} lg={2}>
                  <img src={Contact} alt="Contact Us" className="fund-icons" />
                </Col>
                <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                  <h4>Questions and Concerns</h4>
                  <p>Contact Us</p>
                </Col>
              </Row>
            </Link>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withContext(PlannedGiving);
