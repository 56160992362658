import React from 'react';
import { withStyles, FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';
import { Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';

const styles = () => ({
  '@global': {
    '#root': {
      background: 'radial-gradient(ellipse at center 32%, #0a5b55, #01252b)',
    },
    '#content': {
      background: 'transparent',
    },
  },
});

class ClusterValidationForm extends React.Component<any, any> {
  render() {
    let optionlist = this.props.clusterdata
      .sort((a: any, b: any) => a.name.localeCompare(b.name))
      .map((cluster: any, index: any) => (
        <MenuItem data-cy={cluster.code} key={index} value={cluster.code}>
          {cluster.name}
        </MenuItem>
      ));

    return (
      <Row around="xs" className="faded-background-medium">
        <Col xs={12} className="form-style wide-form">
          <form onSubmit={this.props.handleSubmit}>
            <h3>Create an Account</h3>
            {this.props.displayMessage}
            <FormControl variant="outlined" className="large-dropdown">
              <InputLabel htmlFor="cluster" id="cluster">
                Cluster
              </InputLabel>
              <Select
                value={this.props.selectvalue}
                onChange={this.props.handleChange}
                data-cy="cluster"
                name={this.props.name}
                id="cluster"
                labelId="cluster"
                label="Cluster"
              >
                <MenuItem value="">
                  <em>Please select your cluster</em>
                </MenuItem>
                {optionlist}
              </Select>
            </FormControl>
            <Row around="xs" className="top-margin row-reverse">
              <Col className="w-auto">
                <input
                  data-cy="ClusterValidationButton"
                  type="submit"
                  value="Next"
                  id="ClusterValidationButton"
                  className="primary-button"
                />
              </Col>
              <Col className="w-auto">
                {this.props?.history?.goBack ? (
                  <>
                    <button className="secondary-button" onClick={this.props.cancelHandler} tabIndex={1}>
                      Cancel
                    </button>
                    <button className="secondary-button small-left-margin" onClick={this.props.history.goBack} tabIndex={1}>
                      Back
                    </button>
                  </>
                ) : (
                  <Link data-cy="cancel-button" className="secondary-button" to="/login" tabIndex={1}>
                    Cancel
                  </Link>
                )}
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ClusterValidationForm);
