import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import withContext from '../ContextAPI/Context_HOC';
import parse from 'html-react-parser';
import Downloads from '../Common-Components/Downloads';
import { ContentItem } from '@kentico/kontent-delivery';
import { ContextProviderInterface } from '../ContextAPI/ContextProviderInterface';

interface AtricleProps {
  details: ContentItem;
  context: ContextProviderInterface;
}

class Article extends React.Component<AtricleProps> {
  render() {
    const details = this.props.details;
    let x = parse(details.base__body.resolvedData.html);
    const hasImage = !!details?.featured_image?.value?.length;
    const hasAttachment = !!details?.attachments?.value?.length;
    const attachments = details?.attachments?.value;

    if (!Array.isArray(x)) {
      x = Array.of(x);
    }

    return (
      <div>
        {hasImage ? (
          <Row className="top-margin">
            <Col sm={5}>
              <h1>{details.base__title.value}</h1>
              <p>{new Date(details.base__datepublished.value).toLocaleDateString('en-US', this.props.context.dateOptions)}</p>
            </Col>
            <Col sm={7}>
              {hasImage ? (
                <img
                  className="cover-img end"
                  src={details.featured_image.value[0].url}
                  alt={details.featured_image.value[0].description}
                />
              ) : null}
            </Col>
          </Row>
        ) : (
          <Row className="top-margin">
            <Col sm={12}>
              <h1>{details.base__title.value}</h1>
              <p>{new Date(details.base__datepublished.value).toLocaleDateString('en-US', this.props.context.dateOptions)}</p>
            </Col>
          </Row>
        )}
        <br />
        {hasAttachment ? (
          <Row>
            <Col sm={12} md={8}>
              {x.map((elem, key) => {
                return (
                  <div key={key} data-cy="body-text">
                    {elem}
                  </div>
                );
              })}
            </Col>
            <Col sm={12} md={4}>
              <span>
                <h4 className="sidebar-headers">Attachments</h4>
                <div>
                  {attachments.map((attachment: { name: string; url: string; description: string }, index: number) => (
                    <Downloads key={index} links={attachment} />
                  ))}
                </div>
              </span>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col sm={12}>
              {x.map((elem, key) => {
                return (
                  <div key={key} data-cy="body-text">
                    {elem}
                  </div>
                );
              })}
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default withContext(Article);
