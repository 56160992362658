import React, { useState, useEffect } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import { TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { buildFormalFullName } from '../Utilities/UtilityFunctions';

const ChildRegDisclosures = (props) => {
  const myProfileProxy = props.context.myProfileProxy;

  const [showCircularProgress, setShowCircularProgress] = useState(true);
  const [loggedInUsersFullName, setLoggedInUsersFullName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const setPageTitle = props.setPageTitle;
  useEffect(() => setPageTitle('Child Registration'), [setPageTitle]);

  useEffect(() => {
    const subscription = myProfileProxy.get().subscribe((profileResponse) => {
      const fullName = buildFormalFullName(profileResponse);
      setLoggedInUsersFullName(fullName);
      setShowCircularProgress(false);
    });

    return () => subscription?.unsubscribe();
  }, [myProfileProxy]);

  return (
    <div>
      {showCircularProgress ? (
        <CircularProgress data-cy="child-register-disclosures-circular-progress" className="loading-animation" size={40} />
      ) : (
        <div>
          <div className="profile-box">
            <h3>Disclosures and Agreements</h3>
            <div className="inline-vertical-center">
              I <strong>{loggedInUsersFullName}</strong> hereby attest that I am the parent or legal guardian with custodial
              capacity of
              <span className="medium-left-padding">
                <TextField
                  inputProps={{ 'data-cy': 'FirstName' }}
                  id="FirstName"
                  name="FirstName"
                  label="Child First Name"
                  aria-label="Child First Name"
                  margin="dense"
                  maxLength="40"
                  InputLabelProps={{ shrink: true }}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />{' '}
                <TextField
                  inputProps={{ 'data-cy': 'LastName' }}
                  id="LastName"
                  name="LastName"
                  label="Child Last Name"
                  aria-label="Child Last Name"
                  margin="dense"
                  maxLength="40"
                  InputLabelProps={{ shrink: true }}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </span>
            </div>
            <div>By registering my child in the Bahá’í Faith membership database, I understand that:</div>
            <ul className="no-left-margin no-bottom-padding">
              <li>
                This child will be associated with my household (if you are registering a child that does not live with you,
                please contact the Membership Office at <a href="mailto:membership@usbnc.org">membership@usbnc.org</a>)
              </li>
              <li>My child will not receive a Bahá’í ID card until they reach 15 years of age</li>
              <li>
                If the “Requested No Mail” box is checked, the following will not be sent to your child:
                <ul className="no-left-margin small-top-padding no-bottom-padding">
                  <li>Letter welcoming your child into the Bahá’í Faith</li>
                  <li>
                    Complimentary copies of <a href="https://brilliantstarmagazine.org/">Brilliant Star</a> magazine (for ages
                    7-12)
                  </li>
                  <li>
                    A “Youth Acknowledgment” letter, which includes a Bahá’í ID card and is sent a few months before your child’s
                    15th birthday
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="medium-top-margin end">
            <Link
              onClick={() => props.context.setClickedIToChildRegistrationDisclosures(firstName?.trim(), lastName?.trim())}
              to="/myprofile/child-registration"
              className="primary-button"
              data-cy="inputagreement"
            >
              I Agree
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default withContext(ChildRegDisclosures);
