import React, { useState, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import BoundariedRoute from '../Error/BoundariedRoute';
import DisplaySectPage from './DisplaySectPage';
import GlsaSubSection from './GlsaSubSection';
import withContext from '../ContextAPI/Context_HOC';
import GlsaRichTextResolver from './Utilities/GlsaRichTextResolver';
import { CircularProgress } from '@material-ui/core';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';
import NoMatch from '../Routers/NoMatch';
import { switchMap, map } from 'rxjs/operators';
import { of } from 'rxjs';

const GlsaSection = ({
  context: { glsaProxy, glsaContent },
  match: {
    params: { id, itemid },
  },
}) => {
  const [showCircularProgress, setShowCircularProgress] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [showNoMatch, setShowNoMatch] = useState(false);
  const [sectionData, setSectionData] = useState();
  const [linkData, setLinkData] = useState();
  const [allTaxonomies, setAllTaxonomies] = useState();
  const [matchingUrl, setMatchingUrl] = useState({});

  useEffect(() => {
    const subscription = glsaProxy
      .getChapters()
      .pipe(
        switchMap((taxonomyStuff) => {
          const matchingUrl = taxonomyStuff.urlsByCodename[itemid];
          if (!matchingUrl) {
            return of({ noMatch: true });
          }
          setMatchingUrl(matchingUrl);
          return glsaContent
            .items()
            .type('section')
            .containsFilter('elements.chapters', [itemid])
            .orderParameter('elements.base__revised_date', 'desc')
            .queryConfig({
              richTextResolver: (data) => {
                //Resolving any Kentico components in the content item
                return GlsaRichTextResolver(data);
              },
            })
            .toObservable()
            .pipe(
              map((contentStuff) => {
                const matchingSectionItem = contentStuff.items[0];
                return { taxonomyStuff, matchingSectionItem };
              })
            );
        })
      )
      .pipe(
        switchMap((x) => {
          if (x.matchingSectionItem?.base__body?.links?.length > 0) {
            const links = x.matchingSectionItem.base__body.links;
            return glsaProxy.getLinkContentItems(links).pipe(
              map((linkContentItems) => {
                return { ...x, linkContentItems };
              })
            );
          } else {
            return of({ ...x, linkContentItems: [] });
          }
        })
      )
      .subscribe({
        next: (x) => {
          if (x.noMatch) {
            setShowNoMatch(true);
            setShowCircularProgress(false);
            return;
          }
          setSectionData(x.matchingSectionItem);
          setLinkData(x.linkContentItems);
          setAllTaxonomies(x.taxonomyStuff);
          setShowCircularProgress(false);
        },
        error: (e) => {
          console.error(e);
          setErrorOccurred(true);
          setShowCircularProgress(false);
        },
      });

    return () => subscription.unsubscribe();
  }, [glsaProxy, setShowCircularProgress, setErrorOccurred, setShowNoMatch, glsaContent, itemid]);

  return (
    <div>
      {errorOccurred ? <ErrorMessageToUser /> : null}
      {showCircularProgress ? <CircularProgress className="loading-animation" size={40} /> : null}
      {showNoMatch ? <NoMatch /> : null}
      {allTaxonomies ? (
        <div>
          <Switch>
            <BoundariedRoute
              exact
              path={`/glsa/${id}/${itemid}`}
              render={(props) => (
                <DisplaySectPage
                  {...props}
                  sectionData={sectionData}
                  allTaxonomies={allTaxonomies}
                  linkData={linkData}
                  matchingUrl={matchingUrl}
                />
              )}
            />
            <BoundariedRoute
              path={`/glsa/${id}/${itemid}/:subSectId`}
              render={(props) => <GlsaSubSection {...props} allTaxonomies={allTaxonomies} />}
            />
          </Switch>
        </div>
      ) : null}
    </div>
  );
};

export default withContext(GlsaSection);
