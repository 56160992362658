import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import BoundariedRoute from '../Error/BoundariedRoute';
import EachPrayerList from './EachPrayerList';
import withContext from '../ContextAPI/Context_HOC';
import SecondLevel from './SecondLevel';
import NoMatch from '../Routers/NoMatch';

class PrayerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prayerlist: [],
      prayerdata: [],
      display: '',
      wrongPath: false,
      errorOccurred: false,
    };
    this.capitalizeFirstLetter = this.capitalizeFirstLetter.bind(this);
    this.prayerDataLoad = this.prayerDataLoad.bind(this);
  }
  prayerDataLoad() {
    this.subscription = this.props.context.prayerContent
      .items()
      .type('prayer')
      .containsFilter('elements.prayer_category', [this.props.match.params.id])
      .orderParameter('elements.base__title', 'asc')
      .toObservable()
      .subscribe({
        next: async (response) => {
          if (!response.isEmpty) {
            this.setState({ prayerdata: response.items });
          } else {
            this.setState({ prayerdata: [], wrongPath: true });
          }
        },
        error: async () => {
          this.setState({ prayerdata: [], errorOccurred: true });
        },
      });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.prayerDataLoad();
    }
  }
  componentDidMount() {
    this.prayerDataLoad();
  }
  componentWillUnmount() {
    this.subscription.unsubscribe();
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  render() {
    let prayertype = this.props.match.params.id.split('_');
    let newarr = [];
    for (var i = 0; i < prayertype.length; i++) {
      newarr.push(prayertype[i].replace(prayertype[i][0], prayertype[i][0].toUpperCase()));
    }
    prayertype = newarr.join(' ');
    return (
      <div data-cy="prayerpage">
        {this.state.errorOccurred === true ? (
          <div>
            An error has occurred while loading. Our team has been notified and is working to fix it. Please check back later.
          </div>
        ) : (
          <Switch>
            {this.state.wrongPath === true ? (
              <BoundariedRoute component={NoMatch} />
            ) : (
              <BoundariedRoute
                exact
                path={`/prayer/${this.props.match.params.id}`}
                render={(props) => <EachPrayerList {...props} prayertype={prayertype} prayerdata={this.state.prayerdata} />}
              />
            )}
            {this.state.wrongPath === true ? (
              <BoundariedRoute component={NoMatch} />
            ) : (
              <BoundariedRoute path={`/prayer/${this.props.match.params.id}/:itemid`} component={SecondLevel} />
            )}
            {this.state.wrongPath === true ? (
              <BoundariedRoute component={NoMatch} />
            ) : (
              <BoundariedRoute path={`/prayer/${this.props.match.params.id}/:itemid/:myid`} component={SecondLevel} />
            )}
          </Switch>
        )}
      </div>
    );
  }
}
export default withContext(PrayerList);
