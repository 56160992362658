//Use class NOT className for css since this directly uses html
export default function (data) {
  return data.image.value.length > 0
    ? `<div class=${data.alignment.value[0].name}>
      <img src=${data.image.value[0].url} alt=${data.image.value[0].description}/>
      <figcaption class="caption" style="width:${
        data.image.rawData.value[0].width
      }px; padding-top:5px"> ${data.caption.resolveHtml()} </figcaption>
    </div> `
    : null;
}
