import {
  passwordNotAllowedSpecialCharacters,
  upperCaseLetters,
  lowerCaseLetters,
  numbers,
  passwordAllowedSpecialCharacters,
} from './GeneralRegEx';

/**
 * Validate new password meets minimum requirements
 * @example
 * const validationSchema = {
 *   Password: {
 *     required: true,
 *     validator: passwordValidator
 *   }
 * }
 */
export const passwordValidator = (fieldValue) => {
  if (!fieldValue || fieldValue === '') {
    return '';
  }

  if (passwordNotAllowedSpecialCharacters.test(fieldValue)) {
    return 'Spaces and the characters # (pound or hash tag), & (ampersand) and < (less than symbol) are not allowed';
  }

  let message = [];

  if (fieldValue?.length < 8) {
    const remainingCount = 8 - fieldValue?.length;
    const plural = remainingCount !== 1 ? 's' : '';
    message.push(`${remainingCount} more character${plural}`);
  }

  let count = 0;
  let atLeastOneMessages = [];
  if (upperCaseLetters.test(fieldValue) === true) {
    count++;
  } else {
    atLeastOneMessages.push('"one UPPER case letter"');
  }

  if (lowerCaseLetters.test(fieldValue) === true) {
    count++;
  } else {
    atLeastOneMessages.push('"one lower case letter"');
  }

  if (numbers.test(fieldValue) === true) {
    count++;
  } else {
    atLeastOneMessages.push('"one number"');
  }

  if (passwordAllowedSpecialCharacters.test(fieldValue) === true) {
    count++;
  } else {
    atLeastOneMessages.push('"one special character"');
  }

  if (count < 3) {
    const remainingCount = 3 - count;
    message.push(`at least ${remainingCount} more of ${atLeastOneMessages.join(' or ')}`);
  }

  if (message.length) {
    return 'Still need ' + message.join(' and ');
  }

  return '';
};

/**
 * Show error message if value does not match other field
 * @example
 * const validationSchema = {
 *   newPassword: {
 *     required: true,
 *   },
 *   confirmPassword: {
 *     required: true,
 *     validator: getMustMatchOtherFieldValidator("newPassword", 'This field must match the "New Password" field')
 *   },
 * }
 */
export const getMustMatchOtherFieldValidator = (otherFieldName, message) => (fieldValue, state) => {
  if (fieldValue && fieldValue !== '' && state[otherFieldName].value && state[otherFieldName].value !== '') {
    if (fieldValue !== state[otherFieldName].value) {
      return message;
    }
  }
  return '';
};
