import React from 'react';
import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import {
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  FirstPage,
  LastPage,
  Remove,
  ViewColumn,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const tableIcons = {
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Filter: forwardRef(() => <div />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function National() {
  const useStyles = makeStyles({
    '@global tbody tr:nth-child(odd)': {
      background: '#cccccc',
    },
    '@global tbody tr:nth-child(even)': {
      background: 'white',
    },
  });

  const classes = useStyles();
  const { defaultmessage } = 'Currently loading data. Please wait several seconds!';

  const columns = [
    { title: 'Office', field: 'name', defaultSort: 'asc' },
    {
      title: 'Email',
      field: 'email',
      render: (rowData) => <a href={'mailto:' + rowData.email}>{rowData.email}</a>,
    },
    {
      title: 'Phone',
      field: 'phone',
      render: (rowData) => <a href={'tel:' + rowData.phone}>{rowData.phone}</a>,
    },
  ];
  const data = [
    {
      name: 'Archives',
      email: 'archives@usbnc.org',
      phone: '(847) 853-2372',
    },
    {
      name: 'Assembly Development',
      email: 'oad@usbnc.org',
      phone: '(847) 733-3593',
    },
    {
      name: 'Bosch Bahá’í School',
      email: 'bosch@usbnc.org',
      phone: '(831) 423-3387',
    },
    {
      name: 'Brilliant Star',
      email: 'brilliant@usbnc.org',
      phone: '(847) 733-3409',
    },
    {
      name: 'Communications',
      email: 'ooc@usbnc.org',
      phone: '(847) 733-3418',
    },
    {
      name: 'Community Administration',
      email: 'community@usbnc.org',
      phone: '(847) 733-3485',
    },
    {
      name: 'Education & Schools',
      email: 'schools@usbnc.org',
      phone: '(847) 733-3492',
    },
    {
      name: 'Green Acre Bahá’í School',
      email: 'greenacre@usbnc.org',
      phone: '(207) 439-7200',
    },
    {
      name: 'House of Worship',
      email: 'how@usbnc.org',
      phone: '(847) 853-2300',
    },
    {
      name: 'Human Resources',
      email: 'hrm@usbnc.org',
      phone: '(847) 733-3427',
    },
    {
      name: 'Ḥuqúqu’lláh',
      email: 'support@ushuquq.org',
      phone: '(847) 733-3478',
    },
    {
      name: 'Information Technology',
      email: 'bnohelpdesk@usbnc.org',
      phone: '(847) 733-3567',
    },
    {
      name: 'Inquiry Services/Bahá’í Information',
      email: 'bahai-info@usbnc.org',
      phone: '(800) 228-6483',
    },
    {
      name: 'Office of Pioneering',
      email: 'pioneer@usbnc.org',
      phone: '(847) 733-3510',
    },
    {
      name: 'Learning Desk',
      email: 'learningdesk@usbnc.org',
      phone: '(847) 733-3595',
    },
    {
      name: 'Legal',
      email: 'legal@usbnc.org',
      phone: '(847) 733-3576',
    },
    {
      name: 'Louhelen Bahá’í School',
      email: 'louhelen@usbnc.org',
      phone: '(810) 653-5033',
    },
    {
      name: 'Media Services',
      email: 'media@usbnc.org',
      phone: '(847) 853-2346',
    },
    {
      name: 'Membership & Records',
      email: 'membership@usbnc.org',
      phone: '(847) 733-3445',
    },
    {
      name: 'National Convention Desk',
      email: 'nationalconvention@usbnc.org',
      phone: '(847) 733-3523',
    },
    {
      name: 'National Statistics Office',
      email: 'nso@usbnc.org',
      phone: '(847) 733-3403',
    },
    {
      name: 'Persian Public Information Office',
      email: 'persian@usbnc.org',
      phone: '(847) 733-3528',
    },
    {
      name: 'Public Affairs (Washington D.C.)',
      email: 'usnsa-opa@usbnc.org',
      phone: '(202) 833-8990',
    },
    {
      name: 'Publishing Trust',
      email: 'bds@usbnc.org',
      phone: '(847) 853-7899',
    },
    {
      name: 'Regional Bahá’í Council Liaison',
      email: 'RBCLiaison@usbnc.org',
      phone: '(847) 733-3537',
    },
    {
      name: 'Review',
      email: 'review@usbnc.org',
      phone: '(847) 425-7961',
    },
    {
      name: 'Secretariat',
      email: 'secretariat@usbnc.org',
      phone: '(847) 733-3537',
    },
    {
      name: 'Social Action Desk',
      email: 'socialactiondesk@usbnc.org',
      phone: '(919) 869-4904',
    },
    {
      name: 'The American Bahá’í',
      email: 'tab@usbnc.org',
      phone: '(847) 733-3428',
    },
    {
      name: 'Treasurer',
      email: 'finance@usbnc.org',
      phone: '(847) 733-3472',
    },
    {
      name: 'Unit Convention',
      email: 'unitconvention@usbnc.org',
      phone: '(847) 733-3537',
    },
    {
      name: 'Wilmette Institute',
      email: 'learn@wilmetteinstitute.org',
      phone: '(847) 733-3425',
    },
  ];
  return (
    <div data-cy="displaydirectory">
      <h1 className="bottom-padding">Bahá’í National Center Offices</h1>
      <p className="bottom-padding">
        1233 Central Street
        <br />
        Evanston, IL 60201-1611
        <br />
        <strong>Phone:</strong> (847) 733-3400
        <br />
      </p>
      <MaterialTable
        title=""
        columns={columns}
        data={data}
        icons={tableIcons}
        className={classes}
        options={{
          thirdSortClick: false,
          paging: false,
          search: false,
          toolbar: false,
          headerStyle: {
            backgroundColor: '#8c8c8c',
            border: '1px #9b9e9e',
            fontWeight: 'bold',
          },
          sorting: false,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: [defaultmessage],
          },
        }}
      />
    </div>
  );
}

export default National;
