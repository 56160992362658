export default function FormatUpdatedDate(revisedDate) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const date = new Date(revisedDate);
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  return `Updated ${month} ${year}`;
}
