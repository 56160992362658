import React, { useState, useEffect } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import { Row, Col } from 'react-flexbox-grid';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';
import { CircularProgress } from '@material-ui/core';

const MembershipInfo = (props) => {
  const [actualDayOfMonth, setActualDayOfMonth] = useState(1);
  const [errorMessage, setErrorMessage] = useState(null);
  const [profileData, setProfileData] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [showCircularProgress, setShowCircularProgress] = useState(true);
  const setPageTitle = props.setPageTitle;
  useEffect(() => setPageTitle('Membership Information'), [setPageTitle]);
  const myProfileProxy = props.context.myProfileProxy;
  useEffect(() => {
    const observable = myProfileProxy.get();
    const subscription = observable.subscribe({
      next: (res) => {
        setProfileData(res);
        setActualDayOfMonth(res.declarationDayOfMonthSelected);
        setShowCircularProgress(false);
      },
      error: () => setErrorMessage(<ErrorMessageToUser />),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [myProfileProxy, setErrorMessage, setShowCircularProgress]);

  const handleChange = (e) => {
    setSuccessMessage('');
    setActualDayOfMonth(e.target.value);
  };

  let maxValidDay;

  if (profileData.declarationDate) {
    let declDate = new Date(profileData.declarationDate);
    let csDate = new Date(profileData.counterSignDate);
    if (
      profileData.counterSignDate &&
      declDate.getFullYear() === csDate.getFullYear() &&
      declDate.getMonth() === csDate.getMonth()
    ) {
      maxValidDay = csDate.getUTCDate();
    } else {
      maxValidDay = new Date(declDate.getFullYear(), declDate.getMonth() + 1, 0).getDate();
    }
  }

  const listOfDaysOfMonth = profileData.declarationDaysOfMonthOptions
    ? profileData.declarationDaysOfMonthOptions
        .filter((x) => x <= maxValidDay)
        .map((day) => (
          <MenuItem key={day} value={day}>
            {day}
          </MenuItem>
        ))
    : null;

  function onSubmitForm() {
    let updatedPersonalInfo = {
      declarationDayOfMonthSelected: actualDayOfMonth,
    };

    setShowCircularProgress(true);
    const myProfileProxySetSubscription = myProfileProxy.set(updatedPersonalInfo).subscribe({
      next: () => {
        setShowCircularProgress(false);
        setSuccessMessage(
          <p data-cy="success-message" className="success-message wider">
            Successfully Updated!
          </p>
        );
        setErrorMessage(null);
      },
      error: (err) => {
        const response = err.response || err;
        setShowCircularProgress(false);
        if (response.status === 400) {
          setErrorMessage(<p className="center error-message">{response.data?.message || response.message}</p>);
        } else {
          setErrorMessage(<ErrorMessageToUser />);
        }
      },
    });
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    return () => {
      if (myProfileProxySetSubscription) {
        myProfileProxySetSubscription.unsubscribe();
      }
    };
  }
  return (
    <div>
      {successMessage}
      {errorMessage}
      {showCircularProgress ? <CircularProgress className="loading-animation" size={40} /> : null}
      <div className="profile-box">
        <p className="red-text very-small-left-margin">
          Please note: In years past, declaration dates were often entered generically (first day of the month) rather than with
          the exact date. You may change the day of the month if you know it. If the month and/or year are incorrect, please email
          the correct information to the membership office (<a href="mailto:membership@usbnc.org">membership@usbnc.org</a>)
        </p>
        {profileData ? (
          <Row className="large-top-padding">
            <Col sm={6}>
              <Row>
                <Col sm={6} className="small-top-padding">
                  <p>Declaration Date </p>
                </Col>
                <Col sm={6}>
                  <p className="small-top-padding">
                    <strong>{profileData.declarationDate}</strong>
                  </p>
                </Col>
              </Row>
              <Row className="medium-top-padding">
                <Col sm={6}>
                  <p>Countersign Date </p>
                </Col>
                <Col sm={6}>
                  <p>
                    <strong>{profileData.counterSignDate}</strong>
                  </p>
                </Col>
              </Row>
              <Row className="medium-top-padding">
                <Col sm={6}>
                  <p>Recognition Date</p>
                </Col>
                <Col sm={6}>
                  <p>
                    <strong>{profileData.recognitionDate}</strong>
                  </p>
                </Col>
              </Row>
              <Row className="medium-top-padding">
                <Col sm={6}>
                  <p>Recognition Type</p>
                </Col>
                <Col sm={6}>
                  <p>
                    <strong>{profileData.recognitionType}</strong>
                  </p>
                </Col>
              </Row>
              <Row className="medium-top-padding">
                <Col sm={6}>
                  <p>Recognition Community</p>
                </Col>
                <Col sm={6}>
                  <p>
                    <strong>{profileData.recognitionBlc}</strong>
                  </p>
                </Col>
              </Row>
              <Row className="medium-top-padding">
                <Col sm={6}>
                  <p>Member Type</p>
                </Col>
                <Col sm={6}>
                  <p>
                    <strong>{profileData.memberType}</strong>
                  </p>
                </Col>
              </Row>
            </Col>
            {profileData.declarationDate ? (
              <Col sm={6}>
                <Row>
                  <Col sm={7} className="small-top-padding">
                    <p>Day Of Month You Declared</p>
                  </Col>
                  <Col sm={5}>
                    <FormControl variant="outlined" margin="dense" data-cy="form">
                      <Select
                        value={actualDayOfMonth ?? ''}
                        data-cy="actualDayOfMonthSelect"
                        onChange={(e) => handleChange(e)}
                        inputProps={{
                          name: 'actualDayOfMonth',
                          'data-cy': 'inputdaysofmonth',
                        }}
                      >
                        {listOfDaysOfMonth}
                      </Select>
                    </FormControl>
                  </Col>
                </Row>
              </Col>
            ) : null}
          </Row>
        ) : null}
      </div>
      {profileData.declarationDate ? (
        <div className="medium-top-margin end">
          <input
            type="submit"
            value="Save"
            className="primary-button"
            data-cy="inputsubmit"
            onClick={(e) => {
              onSubmitForm(e);
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default withContext(MembershipInfo);
