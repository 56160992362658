import ContentProviderEndpoint from '../../Content/ContentProviderEndpoint';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NatconArticle } from '../../Kentico/DigitalDesign/natcon_article';
import ImageCaptionResolver from '../../News/Resolver/ImageCaptionResolver';

export default class NatConProxy {
  digitalDesignContent: ContentProviderEndpoint;
  natConsSubject: Map<string, ReplaySubject<NatconArticle[]>>;
  homeCarouselsSubject: ReplaySubject<NatconArticle[]>;
  getHomeCarouselsWasCalled: boolean;

  constructor(digitalDesignContent: ContentProviderEndpoint) {
    this.digitalDesignContent = digitalDesignContent;
    this.natConsSubject = new Map<string, ReplaySubject<NatconArticle[]>>();
    this.homeCarouselsSubject = new ReplaySubject<NatconArticle[]>(1);
    this.getHomeCarouselsWasCalled = false;
  }

  getNatCons(natConTaxonomy: string): Observable<NatconArticle[]> {
    const queryConfigParam: any = {
      richTextResolver: (data: any) => {
        return ImageCaptionResolver(data);
      },
    };
    const subject = this.natConsSubject.get(natConTaxonomy);
    if (!subject) {
      const newSubject = new ReplaySubject<NatconArticle[]>(1);
      this.natConsSubject.set(natConTaxonomy, newSubject);
      this.digitalDesignContent
        .items<NatconArticle>()
        .type('natcon_article')
        .anyFilter('elements.natcon', [natConTaxonomy])
        .orderByDescending('elements.baseDatepublished')
        .queryConfig(queryConfigParam)
        .toObservable()
        .pipe(map((x) => x.items))
        .subscribe(newSubject);
      return newSubject;
    } else {
      return subject;
    }
  }
}
