import React, { Component } from 'react';
import { Switch, Redirect, withRouter } from 'react-router-dom';
import BoundariedRoute from '../Error/BoundariedRoute';
import withContext from '../ContextAPI/Context_HOC';
import Styles from '../GlobalComponents/Styles';
import CalendarFormats from '../GlobalComponents/CalendarFormats';
import Login from '../Login/Login';
import Logout from '../Login/Logout';
import CreateBOSForm from '../Login/CreateBOS/CreateBOSForm';

import CreateBOSStep1 from '../Login/CreateBOSWizard/CreateBOSStep1';
import CreateBOSStep2 from '../Login/CreateBOSWizard/CreateBOSStep2';
import CreateBOSStep3 from '../Login/CreateBOSWizard/CreateBOSStep3';

import ForgotPassword from '../Login/ForgotPassword';
import SingleSignOnRedirect from '../Login/SingleSignOnRedirect';
import PasswordResetWithQuestions from '../Login/PasswordResetWithQuestions/PasswordResetWithQuestions';
import NoMatch from './NoMatch';
import NavBar from '../Navigation/NavBar/NavBar';
import Footer from '../GlobalComponents/Footer';

import Home from '../Home/Home';
import SearchResults from '../Search/SearchResults';
import WebsiteUpdatesArticle from '../WebsiteUpdates/WebsiteUpdatesArticle';
import WebsiteUpdatesList from '../WebsiteUpdates/WebsiteUpdatesList';

import Profile from '../Profiles/Profile';
import EditResidentialAddress from '../Profiles/EditResidentialAddress';
import ProfileFunction from '../Profiles/Utilities/ProfileFunction';
import SecurityEmailConfirmation from '../Profiles/SecurityEmailConfirmation';
import EmailConfirmation from '../Profiles/EmailConfirmation';
import SummaryPage from '../Profiles/EditResidential/99_SummaryPage';

import Messages from '../Messages/Messages';
import LetterList from '../Messages/LetterList';
import NineYearPlan from '../Messages/NineYearPlan';
import NineYearPlanPioneering from '../Messages/NineYearPlanPioneering';
import CallToAction from '../Messages/CallToAction';

import FAQ from '../FAQ/FAQ';
import FaqList from '../FAQ/FaqList';

import ThemeList from '../News/ThemeList';
import News from '../News/News';
import NatCon2019 from '../Resources/NatCon2019';
import NatCon2018 from '../Resources/NatCon2018';
import NatCon2017 from '../Resources/NatCon2017';
import NatCon2016 from '../Resources/NatCon2016';
import NatCon2022 from '../News/NatCon/NatCon2022';
import NatCon2023 from '../News/NatCon/NatCon2023';
import NatCon2024 from '../News/NatCon/NatCon2024';
import NatConArticle from '../News/NatCon/NatConArticle';
import NatConList from '../News/NatCon/Generic/NatConList';
import NatConGeneric from '../News/NatCon/Generic/NatConGeneric';
import StoriesForTheAmericanBahai from '../Resources/StoriesForTheAmericanBahai';

import Elections from '../Elections/Elections';
import Ridvan from '../Elections/Ridvan';
import UnitCon from '../Elections/UnitCon';
import RBCelections from '../Elections/RBCelections';
import Delegates from '../Elections/Delegates';
import ByElections from '../Elections/ByElections';
import NatConResources from '../Elections/NatCon';

import Directories from '../Directories/Directories';
import AbmDirectory from '../Directories/AbmDirectory';
import AssemblyGroups from '../Directories/AssemblyGroups';
import Counselors from '../Directories/Counselors';
import National from '../Directories/National';
import RegionalCouncils from '../Directories/RegionalCouncils';
import California from '../Regions/California';
import HuquqRep from '../Directories/HuquqRep';
import HuquqTrustees from '../Directories/HuquqTrustees';
import HuquqResources from '../Huququllah/HuquqResources';

import Resources from '../Resources/Resources';
import UnitedStates from '../Resources/ServiceOpportunities/UnitedStates';
import International from '../Resources/ServiceOpportunities/International';
import WorldCenter from '../Resources/ServiceOpportunities/WorldCenter';
import LSA from '../Resources/LSA';
import Membership from '../Resources/Membership';
import NationalStatistics from '../Resources/NationalStatisticsOffice';
import PublicAffairs from '../Resources/PublicAffairs';
import Review from '../Resources/Review';
import InquiryServices from '../Resources/InquiryServices';
import AnnualReports from '../Resources/AnnualReports';

import FundResources from '../Resources/Fund/FundResources';
import FundArchivesProject from '../Resources/Fund/FundArchivesProject';
import FundEvents from '../Resources/Fund/FundEvents';
import FundVideos from '../Resources/Fund/FundVideos';
import FundEducation from '../Resources/Fund/FundEducation';
import FundStories from '../Resources/Fund/FundStories';
import SacredObligation from '../Resources/Fund/SacredObligation';
import PrayersAndWritings from '../Resources/Fund/PrayersAndWritings';
import TreasurersTools from '../Resources/TreasurersTools/TreasurersTools';
import TreasurersForms from '../Resources/TreasurersTools/TreasurersForms';
import TreasurersGeneral from '../Resources/TreasurersTools/TreasurersGeneral';
import TreasurersAccounting from '../Resources/TreasurersTools/TreasurersAccounting';
import TreasurersAudit from '../Resources/TreasurersTools/TreasurersAudit';
import TreasurersContact from '../Resources/TreasurersTools/TreasurersContact';
import PlannedGiving from '../Resources/PlannedGiving/PlannedGiving';
import Annuities from '../Resources/PlannedGiving/Annuities';
import BequestsAndWills from '../Resources/PlannedGiving/BequestsAndWills';
import Securities from '../Resources/PlannedGiving/Securities';
import Retirement from '../Resources/PlannedGiving/Retirement';
import DonorFunds from '../Resources/PlannedGiving/DonorFunds';
import RealEstate from '../Resources/PlannedGiving/RealEstate';
import PlannedGivingContact from '../Resources/PlannedGiving/PlannedGivingContact';

import MainGlsa from '../Glsa/MainGlsa';
import ChapterGlsa from '../Glsa/ChapterGlsa';

import Prayers from '../Prayers/Prayers';
import PrayerList from '../Prayers/PrayerList';
import Writings from '../Writings/Writings';
import WritingDisplayCall from '../Writings/WritingDisplayCall';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsightsService } from '../ApplicationInsights/ApplicationInsightsService';
import ReactGA from 'react-ga';
import UpdateRegisterForm from '../public/PreviouslyEnrolled/UpdateRegisterForm';
import PrivacyPolicy from '../PrivacyPolicy';
import JobListing from '../public/JobListing/JobListing';

class Routers extends Component {
  constructor(props) {
    super(props);
    this.profilefn = new ProfileFunction();
    const { history } = this.props;
    this.props.context.setredirectUrl(history.location);
    const { applicationInsightsInitializer } = this.props.context;
    applicationInsightsInitializer(history);
  }

  componentDidUpdate(prevProps) {
    const currentPage = prevProps.location.pathname + prevProps.location.search;
    const nextPage = window.location.pathname + window.location.search;
    const page = window.location.pathname + window.location.search;
    if (currentPage !== nextPage) {
      ReactGA.set({ page: page });
      ReactGA.pageview(page);
    }
  }

  render() {
    const currentlyLoggedIn = this.props.context.authService.loggedIn();
    const userId = this.props.context.authService.loggedIn()
      ? this.profilefn.getprofileinfo(this.props.context.authService.getDecodedToken()).bid
      : '';

    ReactGA.set({ userId });

    return (
      <div>
        {currentlyLoggedIn ? (
          <div>
            <NavBar />
            <div id="content" className={this.props.context.isDrawerOpen === true ? 'contentShift' : 'content'}>
              <Switch>
                <BoundariedRoute path="/" component={Home} exact={true} />
                <BoundariedRoute path="/search-results" component={SearchResults} exact={true} />
                <BoundariedRoute path="/styles" component={Styles} exact={true} />
                <BoundariedRoute path="/calendar-formats" component={CalendarFormats} exact={true} />

                <BoundariedRoute path="/myprofile" component={Profile} exact={true} />
                <BoundariedRoute path="/profile">
                  <Redirect to="/myprofile" />
                </BoundariedRoute>
                <BoundariedRoute path="/myprofile/overseas" component={Profile} exact={true} />
                <BoundariedRoute path="/myprofile/contactinfo" component={Profile} exact={true} />
                <BoundariedRoute path="/myprofile/address-summary" component={SummaryPage} exact={true} />
                <BoundariedRoute path="/myprofile/edit-residential-address" component={Profile} exact={true} />
                <BoundariedRoute path="/myprofile/updateinfo">
                  <Redirect to="/myprofile/contactinfo" />
                </BoundariedRoute>
                <BoundariedRoute path="/myprofile/edit-address" component={EditResidentialAddress} exact={true} />
                <BoundariedRoute path="/myprofile/personalinfo" component={Profile} exact={true} />
                <BoundariedRoute path="/myprofile/membershipinfo" component={Profile} exact={true} />
                <BoundariedRoute path="/myprofile/household" component={Profile} exact={true} />
                <BoundariedRoute path="/myprofile/childregistration">
                  <Redirect
                    to={`/SingleSignOnRedirect/UnityWeb?ReturnUrl=%2FSelfService%2FChildRegistrationSS.aspx%3FR%3D${userId}`}
                  />
                </BoundariedRoute>
                <BoundariedRoute path="/myprofile/child-registration" component={Profile} exact={true} />
                <BoundariedRoute path="/myprofile/child-registration-agreement" component={Profile} exact={true} />
                <BoundariedRoute path="/myprofile/child-information" exact={true}>
                  <Redirect to="/myprofile/household" />
                </BoundariedRoute>
                <BoundariedRoute path="/myprofile/child-information/:childIndex" component={Profile} exact={true} />
                <BoundariedRoute path="/myprofile/edit-child-information" exact={true}>
                  <Redirect to="/myprofile/household" />
                </BoundariedRoute>
                <BoundariedRoute path="/myprofile/edit-child-information/:childIndex" component={Profile} exact={true} />
                <BoundariedRoute path="/myprofile/security-QA" component={Profile} exact={true} />
                <BoundariedRoute path="/myprofile/changepassword" component={Profile} exact={true} />
                <BoundariedRoute path="/myprofile/email-settings" component={Profile} exact={true} />
                <BoundariedRoute path="/myprofile/auxiliaryboard" component={Profile} exact={true} />
                <BoundariedRoute path="/myprofile/cluster-information" component={Profile} exact={true} />
                <BoundariedRoute path="/myprofile/UnitConvention" component={Profile} exact={true} />
                <BoundariedRoute path="/myprofile/huququllah" component={Profile} exact={true} />
                <BoundariedRoute
                  path="/website-updates/:id"
                  exact={true}
                  render={(props) => <WebsiteUpdatesArticle {...props} />}
                />
                <BoundariedRoute path="/website-updates" component={WebsiteUpdatesList} exact={true} />

                <BoundariedRoute path="/guidance/:id" exact={false} render={(props) => <LetterList {...props} />} />
                <BoundariedRoute path="/guidance" component={Messages} exact={true} />
                <BoundariedRoute path="/nine-year-plan" component={NineYearPlan} exact={true} />
                <BoundariedRoute path="/nine-year-plan-pioneering" component={NineYearPlanPioneering} exact={true} />
                <BoundariedRoute path="/call-to-action" component={CallToAction} exact={true} />

                <BoundariedRoute path="/faq/:id" exact={false} render={(props) => <FaqList {...props} />} />
                <BoundariedRoute path="/faq" component={FAQ} exact={true} />

                <BoundariedRoute path="/news" component={News} exact={true} />
                <BoundariedRoute path="/news/natcon-2022" component={NatCon2022} exact={true} />
                <BoundariedRoute
                  path="/news/natcon-2022/:id"
                  exact={false}
                  render={(props) => <NatConArticle {...props} natConTaxonomy="national_convention_2022" />}
                />
                <BoundariedRoute path="/news/natcon-2023" component={NatCon2023} exact={true} />
                <BoundariedRoute
                  path="/news/natcon-2023/:id"
                  exact={false}
                  render={(props) => <NatConArticle {...props} natConTaxonomy="national_convention_2023" />}
                />
                <BoundariedRoute path="/news/natcon-2024" component={NatCon2024} exact={true} />
                <BoundariedRoute
                  path="/news/natcon-2024/:id"
                  exact={false}
                  render={(props) => <NatConArticle {...props} natConTaxonomy="national_convention_2024" />}
                />
                <BoundariedRoute path="/news/national-conventions" component={NatConList} exact={true} />
                <BoundariedRoute
                  path="/news/national-conventions/:year/:id"
                  exact={false}
                  render={(props) => (
                    <NatConArticle {...props} natConTaxonomy={`national_convention_${props?.match?.params.year}`} />
                  )}
                />
                <BoundariedRoute path="/news/national-conventions/:year" component={NatConGeneric} exact={false} />

                <BoundariedRoute path="/news/:id" exact={false} component={ThemeList} />
                <BoundariedRoute path="/news/:id/:itemid" exact={false} component={ThemeList} />

                <BoundariedRoute path="/elections" component={Elections} exact={true} />
                <BoundariedRoute path="/elections/ridvan-elections" component={Ridvan} exact={true} />
                <BoundariedRoute path="/elections/rbc-elections" component={RBCelections} exact={true} />
                <BoundariedRoute path="/elections/unit-convention" component={UnitCon} exact={true} />
                <BoundariedRoute path="/elections/by-elections" component={ByElections} exact={true} />
                <BoundariedRoute path="/unit-convention">
                  <Redirect to="/elections/unit-convention" />
                </BoundariedRoute>
                <BoundariedRoute path="/elections/natcon-resources" component={NatConResources} exact={true} />
                <BoundariedRoute path="/elections/delegates" component={Delegates} exact={true} />
                <BoundariedRoute path="/delegate-info">
                  <Redirect to="/elections/delegates" />
                </BoundariedRoute>
                <BoundariedRoute path="/resources/delegates">
                  <Redirect to="/elections/delegates" />
                </BoundariedRoute>

                <BoundariedRoute path="/directories" component={Directories} exact={true} />
                <BoundariedRoute path="/directories/abm-directory" component={AbmDirectory} exact={true} />
                <BoundariedRoute path="/directories/assemblies-and-groups" component={AssemblyGroups} exact={true} />
                <BoundariedRoute path="/directories/counselors" component={Counselors} exact={true} />
                <BoundariedRoute path="/directories/national" component={National} exact={true} />
                <BoundariedRoute path="/directories/councils" component={RegionalCouncils} exact={true} />
                <BoundariedRoute path="/directories/councils/california" component={California} exact={true} />
                <BoundariedRoute path="/directories/huququllah-representatives" component={HuquqRep} exact={true} />
                <BoundariedRoute path="/directories/huququllah-representatives/:id" component={HuquqRep} exact={true} />
                <BoundariedRoute path="/directories/huququllah-trustees" component={HuquqTrustees} exact={true} />

                <BoundariedRoute path="/resources" component={Resources} exact={true} />
                <BoundariedRoute path="/resources/assembly-references" component={LSA} exact={true} />
                <BoundariedRoute path="/resources/annual-reports" component={AnnualReports} exact={true} />
                <BoundariedRoute path="/resources/inquiry-services" component={InquiryServices} exact={true} />
                <BoundariedRoute path="/resources/membership" component={Membership} exact={true} />
                <BoundariedRoute path="/resources/national-statistics-office" component={NationalStatistics} exact={true} />
                <BoundariedRoute path="/resources/public-affairs" component={PublicAffairs} exact={true} />
                <BoundariedRoute path="/forms/public-affairs.html">
                  <Redirect to="/resources/public-affairs" />
                </BoundariedRoute>
                <BoundariedRoute path="/resources/review" component={Review} exact={true} />
                <BoundariedRoute path="/resources/service-opportunities" component={UnitedStates} exact={true} />
                <BoundariedRoute path="/service-opportunities">
                  <Redirect to="/resources/service-opportunities" />
                </BoundariedRoute>
                <BoundariedRoute path="/resources/international" component={International} exact={true} />
                <BoundariedRoute path="/service-opportunities/pioneering.html">
                  <Redirect to="/resources/international" />
                </BoundariedRoute>
                <BoundariedRoute path="/resources/world-center" component={WorldCenter} exact={true} />
                <BoundariedRoute
                  path="/resources/stories-for-the-american-bahai"
                  component={StoriesForTheAmericanBahai}
                  exact={true}
                />
                <BoundariedRoute path="/submission-guidelines">
                  <Redirect to="/resources/stories-for-the-american-bahai" />
                </BoundariedRoute>
                <BoundariedRoute path="/resources/submission-guidelines">
                  <Redirect to="/resources/stories-for-the-american-bahai" />
                </BoundariedRoute>
                <BoundariedRoute path="/resources/fund" component={FundResources} />
                <BoundariedRoute path="/nationalbahaifund/Home.html">
                  <Redirect to="/resources/fund" />
                </BoundariedRoute>
                <BoundariedRoute path="/fund">
                  <Redirect to="/resources/fund" />
                </BoundariedRoute>
                <BoundariedRoute path="/resources/fund/archives-project" component={FundArchivesProject} exact={true} />
                <BoundariedRoute path="/resources/fund/upcoming-events" component={FundEvents} exact={true} />
                <BoundariedRoute
                  path="/resources/treasurers-tools/fund-education/fund-videos"
                  component={FundVideos}
                  exact={true}
                />
                <BoundariedRoute path="/resources/treasurers-tools" component={TreasurersTools} exact={true} />
                <BoundariedRoute path="/resources/treasurers-tools/accounting" component={TreasurersAccounting} exact={true} />
                <BoundariedRoute path="/resources/treasurers-tools/audit" component={TreasurersAudit} exact={true} />
                <BoundariedRoute path="/resources/treasurers-tools/treasurers-forms" component={TreasurersForms} exact={true} />
                <BoundariedRoute path="/resources/treasurers-tools/fund-education" component={FundEducation} exact={true} />
                <BoundariedRoute
                  path="/resources/treasurers-tools/fund-education/sacred-obgligation"
                  component={SacredObligation}
                  exact={true}
                />
                <BoundariedRoute
                  path="/resources/treasurers-tools/fund-education/prayers-and-writings"
                  component={PrayersAndWritings}
                  exact={true}
                />
                <BoundariedRoute
                  path="/resources/treasurers-tools/fund-education/prayers-and-writings/fund-stories"
                  component={FundStories}
                  exact={true}
                />
                <BoundariedRoute path="/resources/treasurers-tools/contact-us" component={TreasurersContact} exact={true} />
                <BoundariedRoute
                  path="/resources/treasurers-tools/treasurers-general"
                  component={TreasurersGeneral}
                  exact={true}
                />
                <BoundariedRoute path="/resources/planned-giving" component={PlannedGiving} exact={true} />
                <BoundariedRoute path="/forms/planned-giving.html">
                  <Redirect to="/resources/planned-giving" />
                </BoundariedRoute>
                <BoundariedRoute path="/resources/planned-giving/annuities" component={Annuities} exact={true} />
                <BoundariedRoute path="/resources/planned-giving/bequests" component={BequestsAndWills} exact={true} />
                <BoundariedRoute path="/resources/planned-giving/securities" component={Securities} exact={true} />
                <BoundariedRoute path="/resources/planned-giving/retirement" component={Retirement} exact={true} />
                <BoundariedRoute path="/resources/planned-giving/donor-funds" component={DonorFunds} exact={true} />
                <BoundariedRoute path="/resources/planned-giving/real-estate" component={RealEstate} exact={true} />
                <BoundariedRoute
                  path="/resources/planned-giving/planned-giving-contact"
                  component={PlannedGivingContact}
                  exact={true}
                />
                <BoundariedRoute path="/forms">
                  <Redirect to="/resources" />
                </BoundariedRoute>

                <BoundariedRoute path="/resources/natcon-2019" component={NatCon2019} exact={true} />
                <BoundariedRoute path="/natcon2019">
                  <Redirect to="/resources/natcon-2019" />
                </BoundariedRoute>

                <BoundariedRoute path="/resources/natcon-2018" component={NatCon2018} exact={true} />
                <BoundariedRoute path="/natcon2018">
                  <Redirect to="/resources/natcon-2018" />
                </BoundariedRoute>

                <BoundariedRoute path="/resources/natcon-2017" component={NatCon2017} exact={true} />
                <BoundariedRoute path="/natcon2017">
                  <Redirect to="/resources/natcon-2017" />
                </BoundariedRoute>

                <BoundariedRoute path="/resources/natcon-2016" component={NatCon2016} exact={true} />
                <BoundariedRoute path="/natcon2016">
                  <Redirect to="/resources/natcon-2016" />
                </BoundariedRoute>

                <BoundariedRoute path="/huququllah-resources" component={HuquqResources} exact={false} />
                <BoundariedRoute path="/huququllah">
                  <Redirect to="/huququllah-resources" />
                </BoundariedRoute>

                <BoundariedRoute path="/prayer" component={Prayers} exact={true} />
                <BoundariedRoute path="/prayer/:id" exact={false} render={(props) => <PrayerList {...props} />} />

                <BoundariedRoute path="/glsa" component={MainGlsa} exact={true} />
                <BoundariedRoute path="/glsa/:id" component={ChapterGlsa} exact={false} />

                <BoundariedRoute path="/writings" component={Writings} exact={true} />
                <BoundariedRoute path="/writings/:id" component={WritingDisplayCall} exact={true} />
                <BoundariedRoute path="/writing">
                  <Redirect to="/writings" />
                </BoundariedRoute>
                <BoundariedRoute path="/writing/:id">
                  <Redirect to="/writings/:id" />
                </BoundariedRoute>

                <BoundariedRoute path="/SingleSignOnRedirect/:site">
                  <SingleSignOnRedirect />
                </BoundariedRoute>

                <BoundariedRoute path="/logout">
                  <Logout />
                </BoundariedRoute>

                <BoundariedRoute path="/login">
                  <Redirect to="/" />
                </BoundariedRoute>
                <BoundariedRoute path="/createbos">
                  <Redirect to="/" />
                </BoundariedRoute>
                <BoundariedRoute path="/forgot">
                  <Redirect to="/" />
                </BoundariedRoute>
                <BoundariedRoute path="/forgotquestions">
                  <Redirect to="/" />
                </BoundariedRoute>
                <BoundariedRoute path="/securityemailconfirmation">
                  <Redirect to="/" />
                </BoundariedRoute>
                <BoundariedRoute path="/emailconfirmation">
                  <Redirect to="/" />
                </BoundariedRoute>
                <BoundariedRoute component={NoMatch} />
              </Switch>
            </div>
            <Footer />
          </div>
        ) : (
          <Switch>
            <BoundariedRoute path="/logout">
              <Logout />
            </BoundariedRoute>
            <BoundariedRoute path="/calendar-formats" component={CalendarFormats} exact={true} />
            <BoundariedRoute path="/login" component={Login} exact={true} />
            <BoundariedRoute path="/previously-enrolled" component={UpdateRegisterForm} exact={true} />
            <BoundariedRoute path="/job-listing" component={JobListing} exact={true} />
            <BoundariedRoute path="/privacy-policy" component={PrivacyPolicy} exact={true} />

            <BoundariedRoute path="/createbos" component={CreateBOSForm} exact={true} />
            <BoundariedRoute path="/createbos/:temporaryCode" component={CreateBOSForm} exact={true} />
            <BoundariedRoute path="/forgot" component={ForgotPassword} exact={true} />
            <BoundariedRoute path="/forgotquestions/:temporaryCode" component={PasswordResetWithQuestions} />
            <BoundariedRoute path="/securityemailconfirmation/:temporaryCode" component={SecurityEmailConfirmation} />

            <BoundariedRoute path="/emailconfirmation/:temporaryCode" component={EmailConfirmation} />

            <BoundariedRoute path="/createboswizard/step1" component={CreateBOSStep1} exact={true} />
            <BoundariedRoute path="/createboswizard/step2" component={CreateBOSStep2} exact={true} />
            <BoundariedRoute path="/createboswizard/step3" component={CreateBOSStep3} exact={true} />

            <BoundariedRoute>
              <Redirect to="/login" />
            </BoundariedRoute>
          </Switch>
        )}
      </div>
    );
  }
}
export default withRouter(withContext(withAITracking(ApplicationInsightsService.reactPlugin, Routers)));
