import React from 'react';
import { Link } from 'react-router-dom';

function Resources() {
  return (
    <div>
      <h1>Resources</h1>
      <div className="grey-box">
        <h3>
          <Link to="/resources/assembly-references">Assembly and Community References</Link>
        </h3>
        <p>
          This page provides a variety of resources to assist Local Spiritual Assemblies, communities, and individuals. Guidelines
          for Local Spiritual Assemblies: Developing Distinctive Bahá’í Communities is the standard reference for current guidance
          to assist Local Assemblies and facilitates their efforts to apply spiritual and administrative principles in
          consultation. The Local Spiritual Assembly and the Community-Building Process is a compilation of references from
          messages of the Universal House of Justice 1996-2016 regarding the role of Assemblies.
        </p>
        <p>
          Additionally, the page contains the most commonly used forms and resources related to Bahá’í law, marriage, divorce,
          domestic violence, Bahá’í funerals, protection of the Faith, and Assembly functioning.
        </p>
      </div>
      <div className="grey-box">
        <h3>
          <Link to="/resources/annual-reports">Annual Reports</Link>
        </h3>
      </div>
      <div className="grey-box">
        <h3>
          <a href="http://www.bahaicenterassistance.org/" target="_blank" rel="noopener noreferrer">
            Bahá’í Center Assistance
          </a>
        </h3>
      </div>
      <div className="grey-box">
        <h3>
          <Link to="/resources/inquiry-services">Inquiry Services</Link>
        </h3>
        <p>
          The National Inquiry Services desk (also known as “Baha’i Information”) operates under the Office of Communications and
          coordinates responses to interested souls who submit web inquiries through the public websites,{' '}
          <a href="https://www.bahai.us" target="_blank" rel="noopener noreferrer">
            bahai.us
          </a>
          , and{' '}
          <a href="https://www.bahaiteachings.org" target="_blank" rel="noopener noreferrer">
            bahaiteachings.org
          </a>
          ; call the toll free phone line at (800) 22-UNITE (800-228-6483); or fill out interest forms at the Bahai House of
          Worship.
        </p>
      </div>
      <div className="grey-box">
        <h3>
          <Link to="/resources/membership">Membership</Link>
        </h3>
        <p>
          The most commonly used forms and resources for Assemblies and registered groups, such as registration cards, election
          forms, and international transfer information.
        </p>
      </div>
      <div className="grey-box">
        <h3>
          <Link to="/resources/national-statistics-office">National Statistics Office</Link>
        </h3>
        <p>
          Information and resources related to the management of statistics and relationships of the Bahá’í community pertaining
          to the Statistical Report Program (SRP).
        </p>
      </div>
      <div className="grey-box">
        <h3>
          <Link to="/resources/public-affairs">Public Affairs</Link>
        </h3>
        <p>
          Information and resources related to the development and management of the relationships of the Bahá’í community with
          governments, organizations of civil society, the media, prominent people, and the public.
        </p>
      </div>
      <div className="grey-box">
        <h3>
          <Link to="/resources/review">Review</Link>
        </h3>
        <p>
          The Office assists Bahá’í authors and artists in ensuring that what they publish and produce represents the Bahá’í Faith
          accurately and with dignity, and is timely. Review is carried out in a consultative and educational spirit.
        </p>
      </div>
      <div className="grey-box">
        <h3>
          <Link to="/resources/service-opportunities">Service Opportunities</Link>
        </h3>
        <p>A listing of service opportunities available at the Bahá’í National Center, World Center, and Internationally.</p>
      </div>
      <div className="grey-box">
        <h3>
          <Link to="/resources/stories-for-the-american-bahai">
            Stories for <em>The American Bahá’í</em>
          </Link>
        </h3>
      </div>
      <div className="grey-box">
        <h3>
          <a href="https://bahaitreasurer.us/" target="_blank" rel="noopener noreferrer">
            Treasurer’s Tools
          </a>
        </h3>
        <p>Information and resources for LSA treasurers.</p>
      </div>
    </div>
  );
}

export default Resources;
