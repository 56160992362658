import React from 'react';

const MembershipNote = () => {
  return (
    <p className="red-text">
      If you have any questions or need to update any information on this page please contact the Membership Office (
      <a href="mailto:membership@usbnc.org">membership@usbnc.org</a>) at the U.S. Bahá’í National Center.
    </p>
  );
};

export default MembershipNote;
