//Use class NOT className for css since this directly uses html

export default function (data) {
  const icontype = data.icontype.value[0].name;

  if (icontype === 'Search') {
    return `<div>
            <p>SearchIcon</p>
            <figcaption >${data.caption.resolveHtml()} </figcaption>
           </div>`;
  } else if (icontype === 'FeedBack') {
    return `<div>
             <p>FeedBackIcon</p>
             <figcaption  >${data.caption.resolveHtml()} </figcaption>
            </div>`;
  } else if (icontype === 'Updates') {
    return `<div>
             <p>UpdatesIcon</p>
             <figcaption  >${data.caption.resolveHtml()} </figcaption>
            </div>`;
  } else if (icontype === 'Tools') {
    return `<div>
             <p>ToolsIcon</p>
             <figcaption  >${data.caption.resolveHtml()} </figcaption>
            </div>`;
  } else if (icontype === 'Profile') {
    return `<div>
            <p>ProfileIcon</p>
            <figcaption  >${data.caption.resolveHtml()} </figcaption>
           </div>`;
  }
}
