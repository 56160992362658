import React, { useState } from 'react';
import { Link, withRouter } from "react-router-dom";
import withContext from "../../ContextAPI/Context_HOC";
import { withStyles } from "@material-ui/core";
import {
  Alert,
  Box,
  Button,
  MobileStepper,
  Modal,
  Snackbar,
  useTheme
} from '@mui/material';
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import AcceptanceForm, { AcceptanceFieldsArray, AcceptanceRadio, AcceptanceResponseForm } from "./AcceptanceForm";
import PersonalInformationForm, { PersonalInformationFieldsArray, PersonalInformationResponseForm } from "./PersonalInformationForm";
import ContactInformationForm, { contactInformationFieldsArray, ContactInformationResponseForm } from "./ContactInformationForm";
import AddressForm, {
  AddressFieldsArray,
  AddressRadio,
  AddressResponseForm,
  MailingAddressDifferentArray
} from "./AddressForm";
import DemographicForm, { DemographicFieldsArray, DemographicResponseForm } from "./DemographicForm";
import VerificationForm, { VerificationFieldsArray, VerificationResponseForm } from "./VerificationForm";
import SummaryPage from "./SummaryPage";
import {
  CustomFieldFormType
} from "../../Hooks/forms/enums/custom-field-form-type";
import { Dayjs } from "dayjs";
import PreviouslyEnrolledService, {
  PreviouslyEnrolledPayload,
  PreviouslyEnrolledResponse
} from "./PreviouslyEnrolledService";
import { Observable, Subscription } from "rxjs";
import Typography from "@material-ui/core/Typography";
import { CustomField } from '../../Hooks/forms/type/custom-field';
import { AxiosError } from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-flexbox-grid";
const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const styles = () => ({
  '@global': {
    '#root': {
      background: 'radial-gradient(ellipse at center 32%, #0a5b55, #01262C)',
    },
    '#content': {
      background: 'transparent',
    },
    'span.MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
      color: 'red',
    },
    'span.MuiFormControlLabel-asterisk': {
      color: 'red',
    },
    'MuiFormControlLabel-asterisk': {
      color: 'red',
    },
  },
});

enum Step {
  Acceptance,
  PersonalInformation,
  ContactInformation,
  Address,
  Demographic,
  Summary
}

export interface UpdateRegisterFormProps {
  activeStepProp?: number;
  context: any;
}

export type CompleteForm = AcceptanceResponseForm &
  PersonalInformationResponseForm &
  ContactInformationResponseForm &
  AddressResponseForm &
  DemographicResponseForm &
  VerificationResponseForm;
const allFieldsDefinition: CustomField<any>[] = [
  {
    type: CustomFieldFormType.GroupLabel,
    formControlName: 'acceptanceFieldsArrayGroupLabel',
    label: 'Acceptance',
    placeholder: '',
    help: '',
    cssClassName: 'col-lg-12'
  },
  ...AcceptanceFieldsArray,
  {
    type: CustomFieldFormType.GroupLabel,
    formControlName: 'personalInformationFieldsArrayGroupLabel',
    label: 'Personal Information',
    placeholder: '',
    help: '',
    cssClassName: 'col-lg-12'
  },
  ...PersonalInformationFieldsArray,
  {
    type: CustomFieldFormType.GroupLabel,
    formControlName: 'contactInformationFieldsArrayGroupLabel',
    label: 'Contact Information',
    placeholder: '',
    help: '',
    cssClassName: 'col-lg-12'
  },
  ...contactInformationFieldsArray,
  {
    type: CustomFieldFormType.GroupLabel,
    formControlName: 'AddressFieldsArrayGroupLabel',
    label: 'Residential Address',
    placeholder: '',
    help: '',
    cssClassName: 'col-lg-12'
  },
  ...AddressFieldsArray,
  ...MailingAddressDifferentArray,
  {
    type: CustomFieldFormType.GroupLabel,
    formControlName: 'demographicFieldsArrayGroupLabel',
    label: 'Demographic Information',
    placeholder: '',
    help: '',
    cssClassName: 'col-lg-12'
  },
  ...DemographicFieldsArray,
  {
    type: CustomFieldFormType.GroupLabel,
    formControlName: 'verificationFieldsArrayGroupLabel',
    label: 'Verification Information',
    placeholder: '',
    help: '',
    cssClassName: 'col-lg-12'
  },
  ...VerificationFieldsArray,
].filter(
  a => a.type !== CustomFieldFormType.JSXElement
)

export function UpdateRegisterForm(props: UpdateRegisterFormProps) {
  const { handleSubmit } = useForm({
    mode: 'all',
  })
  const [errorMessage, setErrorMessage] = useState('')
  const token = sessionStorage.getItem('id_token');
  const [completeFormInfo, setCompleteFormInfo] = useState<CompleteForm | {}>({})
  const { activeStepProp } = props;
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(activeStepProp ? activeStepProp : Step.Acceptance);
  const [formValid, setFormValid] = useState(false);
  const [showFinalModal, setShowFinalModal] = useState(false);
  const [showSnackbarError, setShowSnackbarError] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('')

  const handleNext = (response: AcceptanceResponseForm |
    PersonalInformationResponseForm |
    ContactInformationResponseForm |
    AddressResponseForm |
    DemographicResponseForm |
    VerificationResponseForm
  ) => {
    setCompleteFormInfo({ ...completeFormInfo, ...response, recaptcha: recaptchaToken })
    const AcceptanceResponse = response as AcceptanceResponseForm
    const personalInformationResponse = response as PersonalInformationResponseForm
    const contactInformationResponse = response as ContactInformationResponseForm
    const addressResponse = response as AddressResponseForm
    const demographicResponse = response as DemographicResponseForm
    const verificationResponse = response as VerificationResponseForm
    AcceptanceResponse;
    personalInformationResponse;
    contactInformationResponse;
    addressResponse;
    demographicResponse;
    verificationResponse;
    if (activeStep !== Step.Summary) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === Step.Summary) {
      setFormValid(true)
    }
    if (formValid) {
      saveInformation();
    }
  };

  const saveInformation = () => {
    const completeForm = (completeFormInfo as CompleteForm)
    Object.keys(completeForm)
      .forEach(
        (key) => {
          const value = completeForm[key as keyof CompleteForm]
          if (value === '') {
            delete completeForm[key as keyof CompleteForm]
          }
        }
      )
    const data: PreviouslyEnrolledPayload = {
      GoogleReCaptchaResponse: recaptchaToken as string,
      EmailParameters: {
        State: completeForm.radioAcceptanceForm,
        FirstName: completeForm.firstName,
        LastName: completeForm.lastName,
        Title: completeForm.title,
        MiddleName: completeForm.middleName,
        NickName: completeForm.nickname,
        Suffix: completeForm.suffix,
        MailCareOf: completeForm.addressMailingInCareOf as string,
        MailAddress: completeForm.isMailingAddressDifferent ? {
          City: (completeForm.isMailingAddressDifferent ? completeForm.addressMailingCity : undefined) as string,
          StateId: (completeForm.isMailingAddressDifferent ? completeForm.addressMailingState : undefined) as string,
          AddressLine1: (completeForm.isMailingAddressDifferent ? completeForm.addressMailingLine1 : undefined) as string,
          AddressLine2: (completeForm.isMailingAddressDifferent ? completeForm.addressMailingLine2 : undefined) as string,
          ZipCode: (completeForm.isMailingAddressDifferent ? completeForm.addressMailingZipCode : undefined) as string,
        } : undefined,
        PhysicalCareOf: completeForm.addressCurrentInCareOf,
        PhysicalAddress: {
          City: completeForm.addressCurrentCity,
          StateId: completeForm.addressCurrentState,
          AddressLine1: completeForm.addressCurrentLine1,
          AddressLine2: completeForm.addressCurrentLine2,
          ZipCode: completeForm.addressCurrentZipCode,
        },
        Races: [],
        Ethnicities: [
          completeForm.ethnicityAmericanIndianOrAlaskaNative ? 'American Indian or Alaska Native' : '',
          completeForm.ethnicityHispanicOrLatino ? 'Hispanic or Latino' : '',
          completeForm.ethnicityAsian ? 'Asian' : '',
          completeForm.ethnicityMiddleEastern ? 'Middle Eastern' : '',
          completeForm.ethnicityBlackOrAfricanAmerican ? 'Black or African American' : '',
          completeForm.ethnicityPersian ? 'Persian' : '',
          completeForm.ethnicityNativeHawaiianOtherPacific ? 'Native Hawaiian or Other Pacific Islander' : '',
          completeForm.ethnicityWhite ? 'White' : '',
        ].filter(a => a !== ''),
        OtherEthnicities: [
          completeForm.ethnicityOtherResponseOne,
          completeForm.ethnicityOtherResponseTwo,
          completeForm.ethnicityOtherResponseThree,
          completeForm.ethnicityOtherResponseFour
        ].filter(a => a),
        PKIdn: Number(completeForm.bahaiId),
        Gender: completeForm.gender,
        BirthDate: (completeForm.dateOfBirth as Dayjs).format('MM-DD-YYYY'),
        HomePhone: completeForm.homePhone?.replace(" ", "").replace("(", "").replace(")", "").replace("-", ""),
        WorkPhone: completeForm.workPhone?.replace(" ", "").replace("(", "").replace(")", "").replace("-", ""),
        CellPhone: completeForm.cellPhone?.replace(" ", "").replace("(", "").replace(")", "").replace("-", ""),
        FaxPhone: completeForm.faxPhone?.replace(" ", "").replace("(", "").replace(")", "").replace("-", ""),
        Email: completeForm.email,
        AlternateEmail: completeForm.alternativeEmail,
        CountryOfBirth: completeForm.countryOfBirth,
        CityOfBirth: completeForm.cityOfBirth,
        StateOfBirth: completeForm.stateOfBirth,
        IsEnrolledPreviously: completeForm.radioAcceptanceForm === AcceptanceRadio.update ? true : false,
        EnrolledInCity: completeForm.originallyEnrolledCity,
        EnrolledInState: completeForm.originallyEnrolledState,
        EnrolledInCountry: completeForm.originallyEnrolledCountry,
        EnrolledName: completeForm.bahaiName,
        Comments: completeForm.additionalInformation,
        PhysicalAddressAppliesTo: completeForm.addressCurrentRadio === AddressRadio.Individual ? AddressRadio.Individual : AddressRadio.Household,
        MailingAddressAppliesTo: completeForm.addressCurrentRadio === AddressRadio.Individual ? AddressRadio.Individual : AddressRadio.Household,
      }
    }
    const observable: Observable<PreviouslyEnrolledResponse> = (
      props.context.previouslyEnrolledService as PreviouslyEnrolledService
    )
      .savePreviouslyEnrolledForm(data, token as string);
    const subscription: Subscription = observable.subscribe({
      next: () => {
        setShowFinalModal(true);
        subscription?.unsubscribe()
      },
      error: (error: AxiosError<{ [key in string]: string[] }>) => {
        setShowFinalModal(false);
        subscription?.unsubscribe()
        let message = 'There was an error from the server.\n';
        const status = error.response ? error.response.status : 500;
        if (status >= 400 && status < 500) {
          if (error.response) {
            Object.keys(error?.response.data).forEach(
              (key) => {
                if (error.response) {
                  error.response.data[key].forEach(
                    (errorData) => {
                      message = message + `${errorData}. `
                    }
                  )
                }
                message = message + `\n`
              }
            )
            setErrorMessage(message)
          }
        }
        else {
          setErrorMessage('There was an error from the server, please try again later.')
        }
        setShowSnackbarError(true)
      }
    })
  }

  const handleBack = () => {
    if (activeStep === Step.Summary) {
      setFormValid(false)
    }
    if (activeStep !== Step.Summary) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
    if (!formValid && activeStep === Step.Summary) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <>
      <Snackbar open={showSnackbarError} onClose={() => setShowSnackbarError(false)} autoHideDuration={6000}>
        <Alert onClose={() => setShowSnackbarError(false)} severity="error" variant="filled" sx={{ width: '100%' }}>
          <pre style={{ color: 'white' }}>{errorMessage}</pre>
        </Alert>
      </Snackbar>
      <Modal open={showFinalModal} aria-labelledby="modal-modal-title" aria-describedby="Thank You message">
        <Box sx={modalStyle} className="text-center">
          <Typography id="modal-modal-title" variant="h6" component="h2">Thank you for providing the information requested</Typography>
          <Typography>
            Your membership record will be updated accordingly by the Membership and Records office at the Bahá’í National Center, and you Regional Statistics Officer will be notified.
          </Typography>
          <div className="m-2">
            <Link to="/login" data-cy="editbutton" className="primary-button w-100">Go to Login Page</Link>
          </div>
        </Box>
      </Modal>
      <div className="center">
        <div data-cy="logo" className="starlogo star-margin" />
        <h2 className="logo-text">Bahá’ís of the United States</h2>
        <Row around="xs" className="faded-background-large">
          <Col xs={12} className="form-style wider">
            <h2>Update My Information</h2>
            <div data-cy="neutral-message" className="physicaladdress-box neutral-message wider">
              <p className="no-margin">
                If you have created a Bahá’í Online Services account, you may update your information in <Link to="/myprofile">My Profile</Link>. You can create an account on the <Link to="/login">login page</Link>.
              </p>
            </div>
            <div>
              <div style={{ display: activeStep === Step.Acceptance ? 'inherit' : 'none' }}>
                <AcceptanceForm callbackAcceptance={handleNext}></AcceptanceForm>
              </div>
              <div style={{ display: activeStep === Step.PersonalInformation ? 'inherit' : 'none' }}>
                <PersonalInformationForm callbackPersonalInformation={handleNext}></PersonalInformationForm>
              </div>
              <div style={{ display: activeStep === Step.ContactInformation ? 'inherit' : 'none' }}>
                <ContactInformationForm callbackContactInformation={handleNext}></ContactInformationForm>
              </div>
              <div style={{ display: activeStep === Step.Address ? 'inherit' : 'none' }} className="text-left">
                <AddressForm callbackAddress={handleNext}></AddressForm>
              </div>
              <div style={{ display: activeStep === Step.Demographic ? 'inherit' : 'none' }} className="text-left">
                <DemographicForm callbackDemographic={handleNext}></DemographicForm>
              </div>
              <div style={{ display: activeStep === Step.Summary && !formValid ? 'inherit' : 'none' }} className="text-left">
                <VerificationForm callbackSummary={handleNext}></VerificationForm>
              </div>
              <div style={{ display: activeStep === Step.Summary && formValid ? 'inherit' : 'none' }} className="text-left">
                <h3>Confirm your information and click finish</h3>
                {formValid && <SummaryPage form={completeFormInfo as CompleteForm} fields={allFieldsDefinition} />}
                {formValid &&
                  <Row>
                    <Col sm={12}>
                      <div className="mt-3 mb-3">
                        <form
                          style={{
                            margin: '0 auto',
                            width: '304px'
                          }}
                          onSubmit={handleSubmit(handleNext as any)}
                        >
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_CODE as string}
                            onChange={(token) => token ? setRecaptchaToken(token) : setRecaptchaToken('')}
                          />

                          {recaptchaToken === '' && <Alert className="mt-3 mb-3" severity="warning">Please fill Recaptcha to continue</Alert>}
                          <Button size="small"
                            type="submit" sx={{ width: '100%' }}
                            disabled={(typeof recaptchaToken) !== 'string' || recaptchaToken === '' || !recaptchaToken}
                          >
                            Finish
                            {theme.direction === 'rtl' ? (
                              <KeyboardArrowLeft />
                            ) : (
                              <KeyboardArrowRight />
                            )}
                          </Button>
                        </form>
                      </div>
                    </Col>
                  </Row>
                }
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <MobileStepper variant="progress" steps={6} position="static" activeStep={activeStep} sx={{ maxWidth: 400, flexGrow: 1 }}
                nextButton={
                  <Button size="small" onClick={handleNext as () => void}
                    disabled={true}>
                    Finish
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button size="small" onClick={handleBack}
                    disabled={activeStep === Step.Acceptance}>
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Back
                  </Button>
                }
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}


export default withRouter(withContext(withStyles(styles, { withTheme: true })(UpdateRegisterForm)));
