import React, { FC, useState, useEffect, useRef } from 'react';
import type FeatureKey from './FeatureKey';
import FeatureLocalSetting from './FeatureLocalSetting';
import { withOptimizely, WithOptimizelyProps } from '@optimizely/react-sdk';
import type { Subject } from 'rxjs';

type FixedSizeArray<N extends number, T, M extends string = '0'> = {
  readonly [k in M]: any;
} & { length: N } & ReadonlyArray<T>;

type Props = {
  featureKey: FeatureKey;
  readySubject?: Subject<boolean>;
  children: FixedSizeArray<2, JSX.Element>;
} & WithOptimizelyProps;

/**
 * If the feature is ON then the first component will be display,
 * otherwise the second component is displayed.
 *
 * @component
 * @param featureKey The feature to use when choosing component
 * @returns
 */
const FeatureFlagChooser: FC<Props> = ({ featureKey, readySubject, children, optimizely }) => {
  const [displayNew, setDisplayNew] = useState(false);
  const [ready, setReady] = useState(false);
  const mounted = useRef<boolean>(true);

  useEffect(() => {
    if (ready) {
      readySubject?.next(displayNew);
    }
  }, [ready, readySubject, displayNew]);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const currentSetting = sessionStorage.getItem(`FeatureSetting:${featureKey}`);
    if (currentSetting === FeatureLocalSetting.ForcedOff) {
      setDisplayNew(false);
      setReady(true);
      return;
    }
    if (currentSetting === FeatureLocalSetting.ForcedOn) {
      setDisplayNew(true);
      setReady(true);
      return;
    }
    if (!optimizely) {
      console.error('Optimizely client is required');
      return;
    }

    const asyncFunction = async () => {
      await optimizely.onReady();
      const featureIsEnabled = optimizely.isFeatureEnabled(featureKey);
      if (mounted.current) {
        setDisplayNew(featureIsEnabled);
        setReady(true);
      }
    };

    asyncFunction();
  }, [featureKey, optimizely]);

  if (displayNew) {
    return <>{children[0]}</>;
  } else {
    return <>{children[1]}</>;
  }
};

// Repeat documentation here so it shows up in intellisense when consuming this component
/**
 * If the feature is ON then the first component will be display,
 * otherwise the second component is displayed.
 *
 * @component
 * @param featureKey The feature to use when choosing component
 * @returns
 */
export default withOptimizely(FeatureFlagChooser);
