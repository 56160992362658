export default function addressvalidation(data) {
  let message = [];

  if (
    data.line1 === '' ||
    data.line1 === null ||
    data.city === '' ||
    data.city === null ||
    data.city === undefined ||
    data.state === '' ||
    data.state === undefined ||
    data.zipCode === ''
  ) {
    message.push('Please enter your first line of address, city, state, and zip code. ');
    // return Promise.reject("Please enter your first line of address, city, state, and zip code.");
  }
  //Regular expression to check if the zip code has been entered with the first 5 digits or complete zip code.
  if (/^\d{5}(-\d{4})?$/.test(data.zipCode) === false && data.zipCode !== '') {
    message.push('Invalid zip code.');
    // return Promise.reject("Invalid zip code. Please enter first 5 digits of your zip code or your complete zip code");
  }
  if (message[0] !== undefined) {
    message = message.join('');
    return Promise.reject(message);
  }
  return false;
}
