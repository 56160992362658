import React from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

export default function FaqParserOptions(body, linkData) {
  const options = {
    replace: (attribs) => {
      if (attribs.attribs !== undefined && attribs.name === 'a' && linkData) {
        const url = linkData[attribs.attribs['data-item-id']];
        if (url !== '' && url !== null) {
          return <Link to={url}>{attribs.children[0].data}</Link>;
        }
      }
    },
  };
  const x = parse(body, options);
  return x;
}
