import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';
export default function PasswordResetWithQuestionsEndpoint(
  token: any,
  temporaryCode: string,
  answers: Answers[],
  newPassword?: string
) {
  return GlobalAPICall.post(
    process.env.REACT_APP_AUTH_API_URL + '/api/ForgotPassword/ResetWithQuestions',
    {
      temporaryCode: temporaryCode,
      newPassword: newPassword,
      answers: answers,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    }
  )
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      if (err.response.status === 400) {
        return Promise.reject({
          status: err.response.status,
          message: SafeStringifyAxiosError(err),
        });
      } else {
        return Promise.reject({
          status: err.response.status,
          message: 'Our services are currently unavailable. Please come back later.',
        });
      }
    });
}
import SafeStringifyAxiosError from '../../Utilities/SafeStringifyAxiosError';
import { Answers } from './Answers';
