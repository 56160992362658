export enum CustomFieldFormType {
  Text = 'text',
  Password = 'password',
  Email = 'email',
  Url = 'url',
  Tel = 'tel',
  Date = 'date',
  DateTime = 'datetime-local',
  Textarea = 'textarea',
  Select = 'select',
  Number = 'number',
  File = 'file',
  Mask = 'mask',
  Radio = 'radio',
  JSXElement = 'jsxElement',
  Checkbox = 'checkbox',
  GroupLabel = 'groupLabel',
  // Separator = 'separator',
}
