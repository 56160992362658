import decode, { JwtPayload } from 'jwt-decode';
import { ReplaySubject } from 'rxjs';
import GlobalAPICall from '../GlobalComponents/GlobalAPICall';
import ProfileFunction from '../Profiles/Utilities/ProfileFunction';
import SafeStringifyAxiosError from '../Utilities/SafeStringifyAxiosError';
import { CancelToken } from 'axios';
import { ApplicationInsightsContainer } from '../ApplicationInsights/ApplicationInsightsServiceInterface';

export default class AuthService {
  tokenSubject = new ReplaySubject(1);
  applicationInsightsContainer: ApplicationInsightsContainer | null = null;

  constructor(applicationInsightsContainer: ApplicationInsightsContainer) {
    this.applicationInsightsContainer = applicationInsightsContainer;
    if (this.loggedIn()) {
      this.tokenSubject.next(this.getToken());
    }
  }

  login(UserName: string, Password: string, setCanceller?: CancelToken) {
    if (UserName === '' || Password === '') {
      return Promise.reject('Please enter username and password');
    } else {
      return GlobalAPICall.post(
        process.env.REACT_APP_AUTH_API_URL + '/api/token',
        {
          UserName: UserName,
          Password: Password,
          AppName: 'bahaisite',
        },
        null,
        setCanceller
      )
        .then((res) => {
          this.setToken(res.data);
          return Promise.resolve(res.data);
        })
        .catch((err) => {
          return Promise.reject(SafeStringifyAxiosError(err));
        });
    }
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken();
    const loggedInResult = !!token && !this.isTokenExpired(token);
    if (this.applicationInsightsContainer?.applicationInsights) {
      if (loggedInResult) {
        const decodedToken = this.getDecodedToken();
        const profileInfo = new ProfileFunction().getprofileinfo(decodedToken);
        this.applicationInsightsContainer.applicationInsights.setAuthenticatedUserContext(profileInfo.bid);
      } else {
        this.applicationInsightsContainer.applicationInsights.clearAuthenticatedUserContext();
      }
    }

    return loggedInResult;
  }

  isTokenExpired(token: string) {
    try {
      const decoded: JwtPayload = decode(token);
      if (decoded.exp) {
        if (decoded.exp < Date.now() / 1000) {
          return true;
        }
      }
      return false;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  setToken(idToken: string) {
    // Saves user token to localStorage
    sessionStorage.setItem('id_token', idToken);
    const decodedToken = decode(idToken);
    sessionStorage.setItem('token_decode', JSON.stringify(decodedToken));
    const profileInfo = new ProfileFunction().getprofileinfo(decodedToken);
    this.applicationInsightsContainer?.applicationInsights.setAuthenticatedUserContext(profileInfo.bid);
    this.tokenSubject.next(idToken);
  }

  getToken() {
    // Retrieves the user token from localStorage
    return sessionStorage.getItem('id_token');
  }
  getTokenObservable() {
    return this.tokenSubject.asObservable();
  }
  getDecodedToken() {
    const tokenDecodeFromSessionStorage = sessionStorage.getItem('token_decode');
    if (tokenDecodeFromSessionStorage) {
      const parsedTokenDecodeFromSessionStorage = JSON.parse(tokenDecodeFromSessionStorage);
      return parsedTokenDecodeFromSessionStorage;
    }
    return null;
  }
  logout() {
    // Clear user token and profile data from localStorage
    this.applicationInsightsContainer?.applicationInsights.clearAuthenticatedUserContext();
    sessionStorage.removeItem('id_token');
    sessionStorage.removeItem('token_decode');
    this.tokenSubject.next(null);
  }
}
