import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class HomeCarousel extends ContentItem {
  public overlayBackgroundOpacityPercent: Elements.NumberElement;
  public order: Elements.NumberElement;
  public overlayBackgroundColor: Elements.CustomElement;
  public formattedtitleTitle: Elements.RichTextElement;
  public overlayTextColor: Elements.CustomElement;
  public image: Elements.AssetsElement;
  public body: Elements.RichTextElement;
  public overlayLocation: Elements.MultipleChoiceElement;
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'overlay_background_opacity_percent') {
          return 'overlayBackgroundOpacityPercent';
        }
        if (elementName === 'overlay_background_color') {
          return 'overlayBackgroundColor';
        }
        if (elementName === 'formattedtitle__title') {
          return 'formattedtitleTitle';
        }
        if (elementName === 'overlay_text_color') {
          return 'overlayTextColor';
        }
        if (elementName === 'overlay_location') {
          return 'overlayLocation';
        }
        return elementName;
      },
    });
  }
}
