import React, { useState, useEffect, FunctionComponent } from 'react';
import EditAddressPage from './01_EditAddressPage';
import HouseholdSelectionPage from './03_HouseholdSelectionPage';
import AddressVerificationPage from './02_AddressVerificationPage';
import SummaryPage from './99_SummaryPage';
import type PhysicalAddressChangeResponseModel from '../Utilities/PhysicalAddressChangeResponseModel';
import type PhysicalAddressChangeRequestModel from '../Utilities/PhysicalAddressChangeRequestModel';
import MovingPageDirection from './MovingPageDirection';

/**
 * Page to display. This enum also determines the order in which pages are displayed.
 */
enum Page {
  EditAddress,
  AddressVerification,
  HouseholdSelection,
  Summary,
}

export type Props = {
  setPageTitle: (title: string) => void;
};

const EditResidentialController: FunctionComponent<Props> = ({ setPageTitle }) => {
  useEffect(() => setPageTitle('Update My Residential Address'), [setPageTitle]);
  const [currentPage, setCurrentPage] = useState<Page>(Page.EditAddress);
  const [movingPageDirection, setMovingPageDirection] = useState<MovingPageDirection>(MovingPageDirection.Forward);
  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
    setMovingPageDirection(MovingPageDirection.Forward);
  };
  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
    setMovingPageDirection(MovingPageDirection.Backward);
  };
  const [newAddress, setNewAddress] = useState<PhysicalAddressChangeResponseModel>();
  const [requestModel, setRequestModel] = useState<PhysicalAddressChangeRequestModel>({
    inCareOf: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    zipcode: '',
    toScrub: true,
    validateOnly: true,
    selectedHouseholdMemberIdns: [],
  });

  return (
    <div>
      <div>
        {(() => {
          switch (currentPage) {
            case Page.EditAddress:
              return (
                <EditAddressPage
                  goToNextPage={goToNextPage}
                  setNewAddress={setNewAddress}
                  requestModel={requestModel}
                  setRequestModel={setRequestModel}
                />
              );
            case Page.AddressVerification:
              return (
                <AddressVerificationPage
                  goToNextPage={goToNextPage}
                  goToPreviousPage={goToPreviousPage}
                  newAddress={newAddress}
                  setNewAddress={setNewAddress}
                  requestModel={requestModel}
                  setRequestModel={setRequestModel}
                />
              );
            case Page.HouseholdSelection:
              return (
                <HouseholdSelectionPage
                  goToNextPage={goToNextPage}
                  goToPreviousPage={goToPreviousPage}
                  requestModel={requestModel}
                  setRequestModel={setRequestModel}
                  setNewAddress={setNewAddress}
                  movingPageDirection={movingPageDirection}
                />
              );
            case Page.Summary:
              return <SummaryPage goToPreviousPage={goToPreviousPage} newAddress={newAddress} requestModel={requestModel} />;
            default:
              return null;
          }
        })()}
      </div>
    </div>
  );
};
export default EditResidentialController;
