import React from 'react';
import PropTypes from 'prop-types';

const EachPrayerDisplay = ({ details }) => (
  <div>
    <div>
      <h1 data-cy="subject">{details.base__title.value}</h1>
      {details.authors.value.length !== 0 ? <p data-cy="author"> By {details.authors.value[0].name}</p> : null}
      <hr />
      <p data-cy="body-text" dangerouslySetInnerHTML={{ __html: details.base__body.value }} />
    </div>
    <hr />
  </div>
);

export default EachPrayerDisplay;

EachPrayerDisplay.propTypes = {
  details: PropTypes.shape({
    base__title: PropTypes.shape({ value: PropTypes.string }),
    base__body: PropTypes.shape({ value: PropTypes.string }),
    author: PropTypes.shape({ value: PropTypes.string }),
  }),
};
