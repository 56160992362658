import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';

const Emails = (props) => {
  const details = props.details;

  return (
    <div>
      <Row>
        <h4 className="medium-left-padding w-auto">Current Email Settings</h4>
        <Col sm={6}>
          <Link to="/myprofile/email-settings">Change My Email Settings</Link>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <p className="faded-text small-text">Primary Email</p>
          <p className="wrap-long-words">
            <strong>{details.primaryEmail}</strong>
          </p>
        </Col>

        <Col sm={4}>
          <p className="faded-text small-text">Alternate Email</p>
          <p className="wrap-long-words">
            <strong>{details.alternateEmail}</strong>
          </p>
        </Col>

        <Col sm={4}>
          <p className="faded-text small-text">Security Email</p>
          <p className="wrap-long-words">
            <strong>{details.securityEmail}</strong>
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default Emails;
