import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Homeblock extends ContentItem {
  public baseTitle: Elements.TextElement;
  public readMoreFreeText: Elements.RichTextElement;
  public formattedtitleTitle: Elements.RichTextElement;
  public readMoreAsset: Elements.AssetsElement;
  public row: Elements.NumberElement;
  public halfBlockImage: Elements.AssetsElement;
  public column: Elements.MultipleChoiceElement;
  public readMoreLinkedItem: Elements.LinkedItemsElement<ContentItem>;
  public baseBahaiOrPublicContent: Elements.MultipleChoiceElement;
  public baseDatepublished: Elements.DateTimeElement;
  public baseListenhtml: Elements.TextElement;
  public baseBody: Elements.RichTextElement;
  public moveToPreviousAnnouncementsOn: Elements.DateTimeElement;
  public fullBlockImage: Elements.AssetsElement;
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'base__title') {
          return 'baseTitle';
        }
        if (elementName === 'read_more_free_text') {
          return 'readMoreFreeText';
        }
        if (elementName === 'formattedtitle__title') {
          return 'formattedtitleTitle';
        }
        if (elementName === 'read_more_asset') {
          return 'readMoreAsset';
        }
        if (elementName === 'half_block_image') {
          return 'halfBlockImage';
        }
        if (elementName === 'read_more_linked_item') {
          return 'readMoreLinkedItem';
        }
        if (elementName === 'base__bahai_or_public_content') {
          return 'baseBahaiOrPublicContent';
        }
        if (elementName === 'base__datepublished') {
          return 'baseDatepublished';
        }
        if (elementName === 'base__listenhtml') {
          return 'baseListenhtml';
        }
        if (elementName === 'base__body') {
          return 'baseBody';
        }
        if (elementName === 'move_to_previous_announcements_on') {
          return 'moveToPreviousAnnouncementsOn';
        }
        if (elementName === 'full_block_image') {
          return 'fullBlockImage';
        }
        return elementName;
      },
    });
  }
}
