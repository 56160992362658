import React from 'react';
import { Link } from 'react-router-dom';
import withContext from '../ContextAPI/Context_HOC';

function displayCustomMessage(title) {
  if (title.toUpperCase() === 'UHJ®') {
    return (
      <div>
        <p>
          On our UHJ&reg; page, we have made available the Universal House of Justice’s guidance and teachings through its
          messages. These letters touch on many subjects, including the growth and vibrancy of the Bahá’í community, its efforts
          to contribute to the life of society, and the progress of specific projects and plans. A number of the more recent
          messages are included here. We invite you to access these communications through the links below—also available to
          download and print—and to enjoy their instruction and guidance.
        </p>
        <p>
          You can access messages related to the Nine Year Plan on <Link to="/nine-year-plan">this page</Link> and information
          related to Pioneering in the Nine Year Plan on <Link to="/nine-year-plan-pioneering">this page</Link>.
        </p>
        <br />
      </div>
    );
  } else if (title === 'National Spiritual Assembly') {
    return (
      <div>
        <p>
          We are pleased to direct your attention to a series of special messages from the National Spiritual Assembly, a Call to
          Action, available on <Link to="/call-to-action">this page</Link>.
        </p>
      </div>
    );
  }
  return null;
}

const List = (props) => {
  let datevalue = '';
  return (
    <div data-cy="displaylistlink">
      <h1 data-cy="listtitle">{props.lettertitle}</h1>
      {displayCustomMessage(props.lettertitle)}
      <ul>
        {Object.keys(props.letterdata).map((i, key) => {
          datevalue = props.letterdata[i].base__datepublished.rawData.value.substring(0, 4);
          return (
            <li key={key}>
              <Link
                data-cy={`link${key}`}
                to={{
                  pathname: `/guidance/${props.lettertype}/${props.letterdata[i].url_slug.value}-${datevalue}`,
                }}
              >
                {new Date(props.letterdata[i].base__datepublished.value).toLocaleDateString('en-US', props.context.dateOptions)} -{' '}
                {props.letterdata[i].base__title.value}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default withContext(List);
