import React, { useState, useEffect } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import FaqParserOptions from './Utilities/FaqParserOptions';
import { CircularProgress } from '@material-ui/core';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';

const FAQs = ({ context, faqContentItem }) => {
  const [showCircularProgress, setShowCircularProgress] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [displayBody, setDisplayBody] = useState();
  const contentResolver = context.contentResolver;
  const allLinks = faqContentItem.base__body.links.map((link) => {
    return { codename: link.codename, linkId: link.linkId };
  });
  const contentProjectEnum = faqContentItem.project;

  useEffect(() => {
    const populateLinkData = async () => {
      try {
        const allLinkCodenamesLength = allLinks.length;
        if (allLinkCodenamesLength) {
          const tempLinkData = {};
          for (let i = 0; i < allLinkCodenamesLength; i++) {
            const currentLink = allLinks[i];
            const resolvedUrl = await contentResolver.ResolveUrl(contentProjectEnum, currentLink.codename);
            tempLinkData[currentLink.linkId] = resolvedUrl;
          }
          const parsedBody = FaqParserOptions(faqContentItem.base__body.resolvedData.html, tempLinkData);
          setDisplayBody(parsedBody);
        } else {
          const parsedBody = FaqParserOptions(faqContentItem.base__body.resolvedData.html);
          setDisplayBody(parsedBody);
        }
      } catch {
        setErrorOccurred(true);
      } finally {
        setShowCircularProgress(false);
      }
    };

    populateLinkData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {errorOccurred ? <ErrorMessageToUser /> : null}
      {showCircularProgress ? <CircularProgress className="loading-animation" size={40} /> : null}
      {displayBody ? (
        <div>
          <h2 data-testid="title" data-cy="title" className="title">
            {faqContentItem.base__title.value}
          </h2>
          <br />
          <div data-testid="body-text" data-cy="body-text">
            {displayBody}
          </div>
          <hr />
        </div>
      ) : null}
    </div>
  );
};

export default withContext(FAQs);
