import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import GiftAnnuities from '../../assets/img/PlannedGiving/CharitableGiftAnnuities.png';

function Annuities() {
  return (
    <Row>
      <Col sm={1}>
        <img src={GiftAnnuities} alt="Charitable Gift Annuities" className="fund-logo" />
      </Col>
      <Col sm={11}>
        <h1>Charitable Gift Annuities (CGA)</h1>
        <p>
          The charitable gift annuity is a contract between you, as the donor, and the National Spiritual Assembly, providing
          advantages for both. Under a gift annuity arrangement:
        </p>
        <ul>
          <li>
            Donor transfers funds to the National Spiritual Assembly in the form of a check, securities, wire transfer, etc.
          </li>
          <li>Your funds are invested with other annuity funds that are held separately from the National Bahá’í Fund</li>
          <li>Lifetime quarterly annuity payments are made to the annuitant</li>
          <li>Remainder of the annuity funds are transferred to the National Bahá’í Fund after the passing of the annuitant</li>
        </ul>
        <ul className="plain-list">
          <li>
            <strong>Download: </strong>
            <a href="https://s3.amazonaws.com/bahai-web/tab/forms/pg-application-for-gift-annuity.doc">
              Printable Gift Annuity Application
            </a>
          </li>
          <li>
            <strong>Download: </strong>
            <a href="https://s3.amazonaws.com/bahai-web/forms/Treasurer/Planned-Giving/charitable-gift-annuity-brochure.pdf">
              Printable Charitable Gift Annuity Brochure
            </a>
          </li>
        </ul>
      </Col>
    </Row>
  );
}

export default Annuities;
