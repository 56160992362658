import WebsiteUpdatesRichTextResolver from './WebsiteUpdatesRichTextResolver';

export default function WebsiteUpdatesArticleEndpoint(props, setHook) {
  const subscription = props.context.digitalDesignContent
    .items()
    .type(['website_update_article'])
    .limitParameter(5)
    .orderParameter('elements.base__datepublished', 'desc')
    .equalsFilter('elements.url_slug', [props.match.params.id])
    .queryConfig({
      urlSlugResolver: (link, text) => {
        let pushthis = props.context.webLinkIds;
        if (pushthis.indexOf(link.linkId) === -1) {
          pushthis.push(link.linkId);
          props.context.setWebUpdateLinkIds(pushthis);
        }
        return {
          asHtml: `<a value=${link.linkId} href=/website-updates>${text.linkText}</a>`,
        };
      },
      richTextResolver: (data) => {
        return WebsiteUpdatesRichTextResolver(data);
      },
    })
    .toObservable()
    .subscribe(async (response) => {
      response.items[0].base__body.resolveHtml();
      if (!response.isEmpty) {
        setHook(response.items);
      }
    });
  return subscription;
}
