import React, { useState, useEffect } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import { CircularProgress, withStyles } from '@material-ui/core';
import { Row, Col } from 'react-flexbox-grid';
import { NavLink } from 'react-router-dom';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';
import SecurityEmailProxy from './Utilities/SecurityEmailProxy';

const styles = () => ({
  '@global': {
    '#root': {
      background: 'radial-gradient(ellipse at center 32%, #0a5b55, #01252b)',
    },
    '#content': {
      background: 'transparent',
    },
  },
});
export interface EmailConfirmationProps {
  context: { emailProxy: SecurityEmailProxy };
  match: { params: { temporaryCode: string } };
}
const EmailConfirmation = (props: EmailConfirmationProps) => {
  const errorMessage = 'This link is no longer valid.';
  const emailProxy = props.context.emailProxy;
  const [messageToUser, setMessageToUser] = useState(<></>);
  const [showCircularProgress, setShowCircularProgress] = useState(true);
  const [unrecoverableError, setUnrecoverableError] = useState(false);
  const tempCode = props.match.params.temporaryCode;

  useEffect(() => {
    const subscription = emailProxy.confirm(tempCode).subscribe({
      next: (x: { data: { message: string } }) => {
        setMessageToUser(
          <p className="success-message wider" data-cy="success-message">
            {x.data.message}
          </p>
        );
        setShowCircularProgress(false);
      },
      error: (e: { response: { status: number; data: { message: string } } }) => {
        if (e?.response?.status === 400 && e?.response?.data) {
          setMessageToUser(
            <p className="error-message wider" data-cy="unsuccess-message">
              {errorMessage}
            </p>
          );
          setShowCircularProgress(false);
        } else {
          setUnrecoverableError(true);
        }
      },
    });

    return () => subscription.unsubscribe();
  }, [emailProxy, tempCode]);

  return (
    <div className="center">
      <div data-cy="logo" className="starlogo star-margin" />
      <h2 data-cy="logotext" className="logo-text">
        Bahá’ís of the United States
      </h2>
      <div className="center form-style top-margin">
        <h3>Change Email Confirmation</h3>
        {unrecoverableError ? (
          <ErrorMessageToUser />
        ) : (
          <div>
            <span>
              <Row around="xs" className="small-top-margin row-reverse">
                <Col>{showCircularProgress ? <CircularProgress className="loading-animation" size={40} /> : messageToUser}</Col>
              </Row>
            </span>
          </div>
        )}
        <div>
          <span>
            <p data-cy="loginlink">
              <NavLink to="/login">&lt; back to login</NavLink>
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default withContext(withStyles(styles, { withTheme: true })(EmailConfirmation));
