import { map } from 'rxjs/operators';
import { tryFormatPhone } from '../../Utilities/UtilityFunctions';

export default class RegionalCouncilService {
  constructor(authService, apiCaller, apiUrl) {
    this.authService = authService;
    this.apiCaller = apiCaller;
    this.apiUrl = apiUrl;
  }

  getRegionalCouncils() {
    const token = this.authService.getToken();
    const observable = this.apiCaller
      .getObservable(this.apiUrl + `/api/regionalcouncil/all`, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        map((x) => {
          return x.data.contents;
        })
      )
      .pipe(
        map((x) => {
          return x.map((y) => {
            const formattedPhone = tryFormatPhone(y.contact?.phone);
            return { formattedPhone, ...y };
          });
        })
      );

    return observable;
  }
}
