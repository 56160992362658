import type { CustomField } from '../type/custom-field';
import { CustomFieldFormType } from '../enums/custom-field-form-type';

export type CustomFormRule = {
  [key in string]:
    | {
        value?: string | number | RegExp | boolean;
        message?: string;
        maxValidateFn?: (value: never) => boolean | string;
        minValidateFn?: (value: never) => boolean | string;
        required?: (valor: never) => boolean;
      }
    | string
    | number
    | true;
};
export default function CreateRules<CustomFieldValues>(customField: CustomField<CustomFieldValues>) {
  const rules: CustomFormRule = {};
  if (customField.validators) {
    if (customField.validators.required && customField.type !== CustomFieldFormType.Number) {
      rules.required = typeof customField.validators.required === 'string' ? customField.validators.required : 'Error';
    }
    if (customField.validators.minLength) {
      rules.minLength = {
        value: customField.validators.minLength.value,
        message: customField.validators.minLength.message,
      };
    }
    if (customField.validators.maxLength) {
      rules.maxLength = {
        value: customField.validators.maxLength.value,
        message: customField.validators.maxLength.message,
      };
    }
    if (customField.validators.max) {
      rules.max = customField.validators.max.value;
      rules.validate = {
        ...(rules.validate as CustomFormRule),
        maxValidateFn: customField.validators.max.validationFn,
      };
      rules.valueAsNumber = true;
    }
    if (customField.validators.min) {
      rules.min = customField.validators.min.value;
      rules.validate = {
        ...(rules.validate as CustomFormRule),
        minValidateFn: customField.validators.min.validationFn,
      };
      rules.valueAsNumber = true;
    }
    if (customField.validators.pattern) {
      rules.pattern = {
        value: customField.validators.pattern.pattern,
        message: customField.validators.pattern.message,
      };
    }
    if (customField.validators.url) {
      rules.url = {
        message: customField.validators.url.message,
      };
    }
    if (customField.validators.email) {
      rules.email = {
        message: customField.validators.email.message,
      };
    }
    if (customField.type === CustomFieldFormType.Number) {
      rules.valueAsNumber = true;
      if (customField.validators.required) {
        rules.validate = {
          ...(rules.validate as CustomFormRule),
          required: (value: never) => {
            return !Number.isNaN(Number(value)) && (Number(value) > 0 || Number(value) <= 0);
          },
        };
      }
    }
  }
  return rules;
}
