import React, { useState, useEffect } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import BoundariedRoute from '../Error/BoundariedRoute';
import withContext from '../ContextAPI/Context_HOC';
import FaqMainList from './FaqMainList';
import DisplayFaq from './DisplayFaq';
import NoMatch from '../Routers/NoMatch';

const FaqList = ({ context, match }) => {
  const [wrongPath, setWrongPath] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const history = useHistory();
  const [subscriptionArray, setSubscriptionArray] = useState([]);
  const [faqId, setFaqId] = useState(match.params.id);

  const refreshFaqItems = () => {
    const subscriptionItems = context.faqProxy.getItems(faqId).subscribe((faqList) => {
      if (faqList.length > 0) {
        setFaqList(faqList);
      } else {
        setWrongPath(true);
      }
    });
    setSubscriptionArray((prevState) => {
      return [...prevState, subscriptionItems];
    });
  };

  useEffect(() => {
    refreshFaqItems();
    const historyListenSubscription = history.listen((location) => {
      if (!location.pathname.startsWith('/faq/')) return;

      const id = location.pathname.replace('/faq/', '').split('/')[0];
      const subFaqProxy = context.faqProxy.getItems(id).subscribe({
        next: (faqList) => {
          if (faqList.length > 0) {
            setFaqList(faqList);
          } else {
            setWrongPath(true);
          }
        },
      });
      setSubscriptionArray(subFaqProxy);
      setFaqId(id);
    });
    return () => {
      if (historyListenSubscription) {
        if (historyListenSubscription()) {
          historyListenSubscription();
        }
      }
      subscriptionArray.forEach((subsItem) => subsItem.unsubscribe());
    };
  }, []);

  return (
    <div>
      <Switch>
        {wrongPath === true ? (
          <BoundariedRoute component={NoMatch} />
        ) : (
          <BoundariedRoute
            exact
            path={`/faq/${faqId}`}
            render={(props) => <FaqMainList {...props} faqdata={faqList} faqtype={faqId} faqtitle={faqId.replace(/_/g, ' ')} />}
          />
        )}
        <BoundariedRoute path={`/faq/${faqId}/:itemid`} render={(props) => <DisplayFaq {...props} contenttype={'faq'} />} />
      </Switch>
    </div>
  );
};

export default withContext(FaqList);
