import React from 'react';
import { withStyles, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { Row, Col } from 'react-flexbox-grid';
import { Link, NavLink } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ReCAPTCHA from 'react-google-recaptcha';
import { OptimizelyFeature } from '@optimizely/react-sdk';
import FeatureKey from '../../FeatureFlag/FeatureKey';

const styles = () => ({
  '@global': {
    '#root': {
      background: 'radial-gradient(ellipse at center 32%, #0a5b55, #01252b)',
    },
    '#content': {
      background: 'transparent',
    },
  },
});

function ZipIdValidationForm(props: any) {
  const recaptchaCode = process.env.REACT_APP_RECAPTCHA_CODE;

  if (props.showCircularProgress) {
    return (
      <Row around="xs" className="faded-background-large">
        <CircularProgress className="loading-animation" size={40} />
      </Row>
    );
  }

  return (
    <Row around="xs" className="faded-background-large">
      <Col xs={12} sm={6} className="form-style short-form-height">
        <h4>A Bahá’í Online Services Account allows you to:</h4>
        <br />
        <ul className="plain-list">
          <li>- Contribute online through OCS</li>
          <li>- Search Guidance Library</li>
          <li>- Update your personal information</li>
          <br />
          <a href="https://vimeo.com/510778014/f3a00e8606" target="_blank" rel="noopener noreferrer">
            <li>
              <PlayArrowIcon />
              Create an Account Tutorial
            </li>
          </a>
          <br />
          <div className="center">
            Already have an account?
            <p>
              <NavLink to="/login">Click here to Login</NavLink>
            </p>
          </div>
        </ul>
      </Col>
      <Col xs={12} sm={6} className="form-style short-form-height">
        <form onSubmit={props.handleZipIdForm}>
          <br />
          <h3>Create an Account</h3>
          {props.displayMessage}
          <TextField
            required
            inputProps={{ 'data-cy': 'bid' }}
            id="bid"
            name="bid"
            label="US Bahá’í ID Number"
            aria-label="US Bahá’í ID Number"
            type="text"
            value={props.BIDstate}
            onChange={props.handleChange}
            fullWidth={true}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          {props.onlyListBahaiIdField ? null : (
            <div>
              <TextField
                required={!props.dontHaveZipCheck}
                inputProps={{ 'data-cy': 'zip' }}
                id="zip"
                name="zip"
                label="Zip Code"
                aria-label="Zip Code"
                type="text"
                value={props.Zipstate}
                onChange={props.handleChange}
                fullWidth={true}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="dontHaveZipCheck"
                    color="default"
                    checked={props.dontHaveZipCheck}
                    onChange={props.handleChange}
                    inputProps={{ 'data-cy': 'donthavezip' } as any}
                  />
                }
                label="I don’t have a zip code"
              />
            </div>
          )}
          <OptimizelyFeature feature={FeatureKey.RecaptchaEnabledForAuth}>
            {(isEnabled) =>
              isEnabled ? (
                <Row center="xs" className="small-bottom-padding">
                  <Col className="w-auto">
                    {recaptchaCode && <ReCAPTCHA size="compact" sitekey={recaptchaCode} onChange={props.handleRecaptcha} />}
                  </Col>
                </Row>
              ) : (
                <></>
              )
            }
          </OptimizelyFeature>
          <span className="center">
            <Row around="xs" className="row-reverse">
              <Col className="w-auto">
                <input data-cy="zipidsubmitbutton" type="submit" value="Next" id="zipidsubmitbutton" className="primary-button" />
              </Col>
              <Col className="w-auto">
                {props?.history?.goBack ? (
                  <button className="secondary-button small-left-margin" onClick={props.history.goBack}>
                    Back
                  </button>
                ) : (
                  <Link data-cy="cancel-button" className="secondary-button" to="/login">
                    Cancel
                  </Link>
                )}
              </Col>
            </Row>
          </span>
          <br />
        </form>
      </Col>
    </Row>
  );
}

export default withStyles(styles, { withTheme: true })(ZipIdValidationForm);
