import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';
import SafeStringifyAxiosError from '../../Utilities/SafeStringifyAxiosError';
import { AxiosResponse } from 'axios';

export interface OverseasValidationAllowedValues {
  value: 'AL-006';
  displayName: 'AL Birmingham Area (AL-006)';
}
export interface OverseasValidationEndpointContent {
  allowedValues: OverseasValidationAllowedValues[] | null;
  displaySequence: number;
  id: number;
  regex: string;
  text: string;
}

export interface OverseasValidationEndpointResponse {
  contents: OverseasValidationEndpointContent[];
  requiredCorrectAnswerCount: number;
  requiredCorrectSecurityAnswerCount: number;
}

export default function OverseasValidationEndpoint(token: string, temporaryCode?: string) {
  const url = temporaryCode
    ? process.env.REACT_APP_AUTH_API_URL + '/api/ForgotPassword/ResetWithQuestions/' + temporaryCode
    : process.env.REACT_APP_AUTH_API_URL + '/api/registration/step1';

  return GlobalAPICall.get(url, {
    headers: temporaryCode
      ? {}
      : {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
  })
    .then((res: AxiosResponse<OverseasValidationEndpointResponse>) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      if (err.response?.status === 400) {
        return Promise.reject({
          status: err.response.status,
          message: SafeStringifyAxiosError(err),
        });
      } else {
        return Promise.reject({
          status: err.response?.status,
          message: 'Our services are currently unavailable. Please come back later.',
        });
      }
    });
}
