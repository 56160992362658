import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import TextField from '@material-ui/core/TextField';
import { phoneFormat } from '../Utilities/GeneralRegEx';
import { COMMON_VALIDATORS } from '../Utilities/validators';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import { BaseMyProfileState } from './EditContactInfo';

export const validationStateSchema = {
  firstName: {
    required: false,
    validator: COMMON_VALIDATORS.name(),
  },
  middleName: {
    required: false,
    validator: null,
  },
  lastName: {
    required: true,
    validator: COMMON_VALIDATORS.name(),
  },
  cellPhone: {
    required: false,
    validator: {
      regEx: phoneFormat,
      error: 'Invalid phone format.',
    },
  },
  homePhone: {
    required: false,
    validator: {
      regEx: phoneFormat,
      error: 'Invalid phone format.',
    },
  },
  workPhone: {
    required: false,
    validator: {
      regEx: phoneFormat,
      error: 'Invalid phone format.',
    },
  },
  title: { required: false },
  suffix: {
    required: false,
  },
  nickName: {
    required: false,
    validator: COMMON_VALIDATORS.maxLength(25, 'NickName'),
  },
};

export interface EditMyProfileProps extends BaseMyProfileState {
  staticState: {
    suffix: JSX.Element | JSX.Element[];
    title: JSX.Element | JSX.Element[];
  };
}

const EditMyProfile = (props: EditMyProfileProps) => {
  const details = props.details;
  const staticState = props.staticState;
  function onNameChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const trimmedValue = event.target.value.trim();
    if (!trimmedValue) {
      event.target.value = trimmedValue;
    }
    props.handleChange(event);
  }

  return (
    <div>
      <h4 className="medium-left-padding small-top-padding">Contact Information</h4>
      <Row className="small-bottom-padding">
        <Col sm={4}>
          <TextField
            inputProps={{ 'data-cy': 'inputfirstName', maxLength: '40' }}
            id="firstName"
            name="firstName"
            label="First Name"
            aria-label="First Name"
            error={!!details.firstName.error}
            type="text"
            value={details.firstName.value}
            onChange={onNameChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          {details.firstName.error && (
            <p className="form-field-error" data-cy="inputfirstName_Required">
              {details.firstName.error}
            </p>
          )}
        </Col>
        <Col sm={4}>
          <TextField
            inputProps={{ 'data-cy': 'inputmiddleName', maxLength: '40' }}
            id="middleName"
            name="middleName"
            label="Middle Name"
            aria-label="Middle Name"
            type="text"
            value={details.middleName.value}
            onChange={onNameChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Col>
        <Col sm={4}>
          <TextField
            required
            inputProps={{ 'data-cy': 'inputlastName', maxLength: '40' }}
            id="lastName"
            name="lastName"
            label="Last Name"
            aria-label="Last Name"
            error={!!details.lastName.error}
            type="text"
            value={details.lastName.value}
            onChange={onNameChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          {details.lastName.error && (
            <p className="form-field-error" data-cy="inputlastName_Required">
              {details.lastName.error}
            </p>
          )}
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <FormControl variant="outlined" margin="dense" className="medium-dropdown" fullWidth={true} data-cy="form">
            <InputLabel id="Title" className="small-text">
              Title
            </InputLabel>
            <Select
              error={!!details.title.error}
              value={details.title.value}
              onChange={props.handleChange}
              label="Title"
              labelId="Title"
              inputProps={{ name: 'title', 'data-cy': 'Title' }}
            >
              {staticState.title}
            </Select>
          </FormControl>
          {details.title.error && (
            <p className="form-field-error" data-cy="Title_field_error">
              {details.title.error}
            </p>
          )}
        </Col>
        <Col sm={4}>
          <FormControl variant="outlined" margin="dense" className="medium-dropdown" fullWidth={true} data-cy="form">
            <InputLabel id="Suffix" className="small-text">
              Suffix
            </InputLabel>
            <Select
              error={!!details.suffix.error}
              value={details.suffix.value}
              onChange={props.handleChange}
              label="Suffix"
              labelId="Suffix"
              inputProps={{ name: 'suffix', 'data-cy': 'Suffix' }}
            >
              {staticState.suffix}
            </Select>
          </FormControl>
          {details.suffix.error && (
            <p className="form-field-error" data-cy="Suffix_field_error">
              {details.suffix.error}
            </p>
          )}
        </Col>
        <Col sm={4}>
          <TextField
            inputProps={{ 'data-cy': 'NickName', maxLength: '25' }}
            id="NickName"
            name="nickName"
            label="Known as/Nickname"
            aria-label="Known as/Nickname"
            type="text"
            error={!!details.nickName.error}
            value={details.nickName.value}
            onChange={props.handleChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          {details.nickName.error && (
            <p className="form-field-error" data-cy="NickName_field_error">
              {details.nickName.error}
            </p>
          )}
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <TextField
            inputProps={{ 'data-cy': 'inputcellPhone', maxLength: '40' }}
            id="cellPhone"
            name="cellPhone"
            label="Cell Phone"
            aria-label="Cell Phone"
            error={!!details.cellPhone.error}
            type="text"
            value={details.cellPhone.value}
            onChange={props.handleChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          {details.cellPhone.error && (
            <p className="form-field-error" data-cy="inputcellPhone_Format">
              {details.cellPhone.error}
            </p>
          )}
        </Col>
        <Col sm={4}>
          <TextField
            inputProps={{ 'data-cy': 'inputhomePhone', maxLength: '40' }}
            id="homePhone"
            name="homePhone"
            label="Home Phone"
            aria-label="Home Phone"
            error={!!details.homePhone.error}
            type="text"
            value={details.homePhone.value}
            onChange={props.handleChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          {details.homePhone.error && (
            <p className="form-field-error" data-cy="inputhomePhone_Format">
              {details.homePhone.error}
            </p>
          )}
        </Col>
        <Col sm={4}>
          <TextField
            inputProps={{ 'data-cy': 'inputworkPhone', maxLength: '40' }}
            id="workPhone"
            name="workPhone"
            label="Work Phone"
            aria-label="Work Phone"
            error={!!details.workPhone.error}
            type="text"
            value={details.workPhone.value}
            onChange={props.handleChange}
            margin="dense"
            fullWidth={true}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          {details.workPhone.error && (
            <p className="form-field-error" data-cy="inputworkPhone_Format">
              {details.workPhone.error}
            </p>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EditMyProfile;
