export class NatConProperties {
  title?: string;
  photoLink?: string;
  videos: NatConVideoProperties[];
  resources: NatConResource[];
  audios: NatConAudioProperties[];
  archives: NatConResource[];

  get sidebarContentAvailable(): boolean {
    return !!(this.videos?.length || this.resources?.length || this.audios?.length || this.archives?.length);
  }

  public constructor(year?: string) {
    switch (year) {
      case '2022':
        this.title = '114th US Bahá’í National Convention: April 21 – 24, 2022';
        this.photoLink = 'https://www.flickr.com/photos/bahaius/collections/72157720600186911/';
        this.videos = [
          {
            title: 'Secretary’s Report',
            videoLink: 'https://player.vimeo.com/video/702556483?h=a038cdc647',
            audioLink:
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1257403156&color=%2336717a&inverse=false&auto_play=false&show_user=false',
            downloadLink: 'https://vimeo.com/usbahai/download/702556483/03a598006c',
          },
          {
            title: 'Treasurer’s Report',
            videoLink: 'https://player.vimeo.com/video/702257856?h=0c0dd100ae',
            downloadLink: 'https://vimeo.com/usbahai/download/702257856/46ae05d679',
          },
          {
            title: 'Ḥuqúqu’lláh Presentation',
            videoLink: 'https://player.vimeo.com/video/704258078?h=af966267b0',
          },
        ];
        this.resources = [
          {
            title: 'Nine Year Plan documents',
            link: '/nine-year-plan',
          },
          {
            title: 'Ridvan 2022 Message from the Supreme Body',
            link: '/guidance/uhj/ridvan-2022-message-from-the-supreme-body-2022',
          },
          {
            title: 'Ridván 2022 Annual Report',
            link: 'https://bahai-web.s3.amazonaws.com/NatCon/2022/2022-Annual-Report.pdf',
          },
          {
            title: 'Letter from the 114th US Bahá’í National Convention',
            link:
              '/guidance/national_spiritual_assembly/letter-from-the-114th-us-baha-i-national-convention-2022/letter-from-the-114th-us-baha-i-national-convention-2022',
          },
          {
            title: 'Election of the National Spiritual Assembly',
            link: '/guidance/national_spiritual_assembly/election-of-the-national-spiritual-assembly-2022',
          },
        ];
        this.audios = [
          {
            title: 'Thursday Consultation',
            links: [
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1257605635&color=%2336717a&inverse=false&auto_play=false&show_user=false',
            ],
          },
          {
            title: 'Friday Consultation',
            links: [
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258821058&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258012570&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258012558&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258821229&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258821253&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258012531&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258012525&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258012513&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258012511&color=%2336717a&inverse=false&auto_play=false&show_user=false',
            ],
          },
          {
            title: 'Saturday Consultation',
            links: [
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258025002&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258024984&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258024966&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258823893&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258823812&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258823752&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258024897&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258024888&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258823779&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258024867&color=%2336717a&inverse=false&auto_play=false&show_user=false',
            ],
          },
          {
            title: 'Sunday Consultation',
            links: [
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258825945&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258026658&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258026649&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258825978&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258825963&color=%2336717a&inverse=false&auto_play=false&show_user=false',
            ],
          },
        ];
        this.archives = [
          {
            title: 'National Convention 2019',
            link: '/resources/natcon-2019',
          },
          {
            title: 'National Convention 2018',
            link: '/resources/natcon-2018',
          },
          {
            title: 'National Convention 2017',
            link: '/resources/natcon-2017',
          },
        ];
        break;
      case '2023':
        this.title = '115th US Bahá’í National Convention: May 18 – 21, 2023';
        this.photoLink = 'https://www.flickr.com/photos/bahaius/collections/72157721746069947/';
        this.videos = [
          {
            title: 'Secretary’s Report',
            videoLink: 'https://player.vimeo.com/video/828498465?h=0ffb5f9edc',
            downloadLink:
              'https://player.vimeo.com/progressive_redirect/playback/828498465/rendition/1080p/file.mp4?loc=external&log_user=0&signature=7fb2015f27dd105cab68dc3f91a4088ead2264e335c93415cf4e1357bdbc1fdc',
          },
          {
            title: 'Treasurer’s Report',
            videoLink: 'https://player.vimeo.com/video/828504023?h=3e3e408818',
            downloadLink:
              'https://player.vimeo.com/progressive_redirect/playback/828504023/rendition/1080p/file.mp4?loc=external&signature=94143f10a7b39ed0807a79df5917fdf00da3aa4f93537fc9a41d3458eb9e7088',
          },
        ];
        this.resources = [
          {
            title: 'Nine Year Plan documents',
            link: '/nine-year-plan',
          },
          {
            title: 'Ridvan 2023 Message from the Supreme Body',
            link: '/guidance/uhj/ridvan-2023-message-from-the-supreme-body-2023/ridvan-2023-message-from-the-supreme-body-2023',
          },
          {
            title: 'Ridván 2023 Annual Report',
            link:
              '/guidance/national_spiritual_assembly/national-spiritual-assembly-shares-ridvan-2023-annual-report-2023/national-spiritual-assembly-shares-ridvan-2023-annual-report-2023',
          },
          {
            title: 'Universal House of Justice Responds to Message from 2023 National Convention',
            link: '/guidance/uhj/universal-house-of-justice-responds-to-message-from-2023-national-convention-2023',
          },
        ];
        this.audios = [
          {
            title: 'Remarks from the Continental Counselors',
            links: [
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527047419&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527047104&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527047449&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527047368&color=%2336717a&inverse=false&auto_play=false&show_user=false',
            ],
          },
          {
            title: 'Administration',
            links: [
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527039316&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527039292&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527039244&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527039217&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527039184&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527039172&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527039136&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527039115&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527039085&color=%2336717a&inverse=false&auto_play=false&show_user=false',
            ],
          },
          {
            title: 'Love',
            links: [
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527049726&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527049684&color=%2336717a&inverse=false&auto_play=false&show_user=false',
            ],
          },
          {
            title: 'Race',
            links: [
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527050737&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527050716&color=%2336717a&inverse=false&auto_play=false&show_user=false',
            ],
          },
          {
            title: 'Social Action',
            links: [
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527052213&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527052138&color=%2336717a&inverse=false&auto_play=false&show_user=false',
            ],
          },
          {
            title: 'Youth',
            links: [
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527054226&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527054202&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527054148&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527054064&color=%2336717a&inverse=false&auto_play=false&show_user=false',
              'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527053986&color=%2336717a&inverse=false&auto_play=false&show_user=false',
            ],
          },
        ];
        this.archives = [
          {
            title: 'National Convention 2022',
            link: '/news/national-conventions/2022',
          },
          {
            title: 'National Convention 2019',
            link: '/resources/natcon-2019',
          },
          {
            title: 'National Convention 2018',
            link: '/resources/natcon-2018',
          },
          {
            title: 'National Convention 2017',
            link: '/resources/natcon-2017',
          },
        ];
        break;
      case '2024':
        this.photoLink = undefined;
        this.videos = [];
        this.resources = [
          {
            title: 'Nine Year Plan documents',
            link: '/nine-year-plan',
          },
          {
            title: 'Ridvan 2024 Message from the Supreme Body',
          },
          {
            title: 'Ridván 2024 Annual Report',
          },
        ];
        this.audios = [];
        this.archives = [
          {
            title: 'National Convention 2023',
            link: '/news/national-conventions/2023',
          },
          {
            title: 'National Convention 2022',
            link: '/news/national-conventions/2022',
          },
          {
            title: 'National Convention 2019',
            link: '/resources/natcon-2019',
          },
          {
            title: 'National Convention 2018',
            link: '/resources/natcon-2018',
          },
          {
            title: 'National Convention 2017',
            link: '/resources/natcon-2017',
          },
        ];
        break;
      default:
        this.photoLink = '';
        this.videos = [];
        this.resources = [];
        this.audios = [];
        this.archives = [];
        break;
    }
  }
}

export class NatConVideoProperties {
  title: string;
  videoLink: string;
  audioLink?: string;
  downloadLink?: string;
}

export class NatConResource {
  title: string;
  link?: string;
}

export class NatConAudioProperties {
  title: string;
  links: string[];
}
