import React, { FunctionComponent, useEffect, useState, ReactNode } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import type HomeCarouselProxy from './Utilities/HomeCarouselProxy';
import Slider, { Settings } from 'react-slick';
import HomeCarouselItem from './HomeCarouselItem';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { SlickBackButton, SlickForwardButton } from '../Utilities/Slick/buttons';

const HomeCarousel: FunctionComponent<{
  context: { homeCarouselProxy: HomeCarouselProxy };
}> = ({ context: { homeCarouselProxy } }) => {
  const [homeCarouselImages, setHomeCarouselImages] = useState([] as JSX.Element[]);

  useEffect(() => {
    const homeCarouselProxyGetHomeCarouselsSubscription = homeCarouselProxy.getHomeCarousels().subscribe((x) => {
      const displayImages = x.map((contentItem, index) => <HomeCarouselItem key={index} homeCarousel={contentItem} />);
      setHomeCarouselImages(displayImages);
    });

    return () => homeCarouselProxyGetHomeCarouselsSubscription.unsubscribe();
  }, [homeCarouselProxy]);

  const buildOverallList = (dots: ReactNode) => {
    return <ul className="home-carousel-dots">{dots}</ul>;
  };

  const buildIndividualListItem = () => {
    return (
      <span role="img" className="home-carousel-dot" aria-label="Carousel slide number indicator">
        <FiberManualRecordIcon />
      </span>
    );
  };

  const sliderSettings: Settings = {
    dotsClass: 'home-carousel-slick-dots slick-dots',
    appendDots: buildOverallList,
    customPaging: buildIndividualListItem,
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    nextArrow: <SlickForwardButton />,
    prevArrow: <SlickBackButton />,
  };

  return (
    <div className="carousel-container">
      <Slider {...sliderSettings}>{homeCarouselImages}</Slider>
    </div>
  );
};

export default withContext(HomeCarousel);
