import React from 'react';
import { Row, Col } from 'react-flexbox-grid';

const InternationalMailingAddress = ({ details }) => {
  const countryDisplay = !details.blc;

  return (
    <div>
      <Row className="small-bottom-padding">
        <Col sm={12}>
          <p className="faded-text small-text">In Care Of</p>
          <p>
            <strong>{details.mailingAddressCareOf}</strong>
          </p>
        </Col>
      </Row>

      {details.mailingAddressLine1 ? (
        <Row className="small-bottom-padding">
          <Col sm={12}>
            <p>
              <strong>{details.mailingAddressLine1}</strong>
            </p>
          </Col>
        </Row>
      ) : null}

      {details.mailingAddressLine2 ? (
        <Row className="small-bottom-padding">
          <Col sm={12}>
            <p>
              <strong>{details.mailingAddressLine2}</strong>
            </p>
          </Col>
        </Row>
      ) : null}

      {details.mailingAddressLine3 ? (
        <Row className="small-bottom-padding">
          <Col sm={12}>
            <p>
              <strong>{details.mailingAddressLine3}</strong>
            </p>
          </Col>
        </Row>
      ) : null}

      {details.mailingAddressLine4 ? (
        <Row className="small-bottom-padding">
          <Col sm={12}>
            <p>
              <strong>{details.mailingAddressLine4}</strong>
            </p>
          </Col>
        </Row>
      ) : null}

      {details.mailingAddressLine5 ? (
        <Row className="small-bottom-padding">
          <Col sm={12}>
            <p>
              <strong>{details.mailingAddressLine5}</strong>
            </p>
          </Col>
        </Row>
      ) : null}
      {details.mailingAddressCountry && countryDisplay ? (
        <Row>
          <Col sm={12}>
            <p>
              <strong>{details.mailingAddressCountry}</strong>
            </p>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};
export default InternationalMailingAddress;
