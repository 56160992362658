import type FeatureKey from './FeatureKey';
import { InternalFeatures } from './FeatureKey';
import FeatureLocalSetting from './FeatureLocalSetting';
import type { ReactSDKClient } from '@optimizely/react-sdk';

const FeatureFlagIsEnabled: (featureKey: FeatureKey, optimizely: ReactSDKClient | null) => Promise<boolean> = async (
  featureKey,
  optimizely
) => {
  if (!optimizely) {
    console.error('USBNC: Optimizely client is NOT ready');
    return false;
  }

  const currentSetting = sessionStorage.getItem(`FeatureSetting:${featureKey}`);
  if (currentSetting === FeatureLocalSetting.ForcedOff) {
    return false;
  }
  if (currentSetting === FeatureLocalSetting.ForcedOn) {
    return true;
  }

  //do not look for internal features in optimizely
  if (InternalFeatures.includes(featureKey)) {
    return false;
  }

  if (!optimizely) {
    throw 'Optimizely client is required';
  }

  await optimizely.onReady();
  const featureIsEnabled = optimizely.isFeatureEnabled(featureKey);
  return featureIsEnabled;
};

export default FeatureFlagIsEnabled;
