import React, { Component } from 'react';
import withContext from '../../ContextAPI/Context_HOC';

class AlertBanner extends Component {
  render() {
    return (
      <div className="center">
        <p className={this.props.context.isDrawerOpen === true ? 'alert-banner-withMenu' : 'alert-banner'}>
          Letters Regarding Excused Absences:{' '}
          <a
            className="white-text"
            href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/20240101+-+Excused+absence+Holy+Days+-+Employers.pdf"
          >
            Employers 2024
          </a>
          ,{' '}
          <a
            className="white-text"
            href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/20230101-Excused-absence-Holy-Days-Students.pdf"
          >
            School year 2023-24
          </a>
          ,{' '}
          <a
            className="white-text"
            href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/20240101+-+Excused+absence+Holy+Days+-+Students.pdf"
          >
            School year 2024-25
          </a>
        </p>
      </div>
    );
  }
}

export default withContext(AlertBanner);
