import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import withContext from '../ContextAPI/Context_HOC';
import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import { ArrowDownward, Check, ChevronLeft, ChevronRight, FirstPage, LastPage, Remove, ViewColumn } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import CustomSortFunc from './Utilities/TableFunctions/CustomSortFunc';
import ErroMessageToUser from '../Error/ErrorMessageToUser';
import HuquqTopImg from '../assets/img/Huquq-top-img.jpg';

const tableIcons = {
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Filter: forwardRef(() => <div />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const HuquqTrustees = (props) => {
  const [defaultmessage, setDefaultmessage] = useState('Currently loading data. Please wait several seconds!');
  const useStyles = makeStyles({
    '@global tbody tr:nth-child(odd)': {
      background: '#cccccc',
    },
    '@global tbody tr:nth-child(even)': {
      background: 'white',
    },
  });
  const classes = useStyles();
  const [data, setData] = useState([]);
  useEffect(() => {
    const subscription = props.context.huquqService.getDeputyTrustees().subscribe({
      next: (x) => setData(x),
      error: () => setDefaultmessage(<ErroMessageToUser />),
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [props.context.huquqService]);

  const [columns] = React.useState({
    columns: [
      {
        title: 'Full Name',
        field: 'name',
        defaultSort: 'asc',
        customSort: (a, b) => CustomSortFunc(a, b),
      },
      {
        title: 'Phone',
        field: 'cell',
        render: (rowData) => <a href={'tel:' + rowData.cell}>{rowData.cell}</a>,
      },
      {
        title: 'Email',
        field: 'email',
        filterPlaceholder: 'Email',
        render: (rowData) => (
          <div>
            {rowData.email !== null ? (
              <a href={'mailto:' + rowData.email}>
                {rowData.email}
                <br />
              </a>
            ) : null}
            {rowData.email2 !== null ? <a href={'mailto:' + rowData.email2}>{rowData.email2}</a> : null}
          </div>
        ),
      },
    ],
  });

  return (
    <div>
      <div className="center">
        <img src={HuquqTopImg} alt="The Deputy Trustees of Ḥuqúqu’lláh in the United States" className="wider full-width-view" />
      </div>
      <h1 className="bottom-padding">Board of Trustees of Ḥuqúqu’lláh in the United States</h1>
      <div data-cy="displaydirectory">
        <p>
          <Link to="huququllah-representatives">Directory of Representatives of Ḥuqúqu’lláh</Link>
        </p>
        <p>
          <Link to="/huququllah-resources">Ḥuqúqu’lláh Information</Link>
        </p>
      </div>
      <MaterialTable
        title=""
        columns={columns.columns}
        data={data}
        icons={tableIcons}
        className={classes}
        options={{
          thirdSortClick: false,
          paging: false,
          toolbar: false,
          headerStyle: {
            backgroundColor: '#8c8c8c',
            border: '1px #9b9e9e',
            fontWeight: 'bold',
          },
          sorting: true,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: [defaultmessage],
          },
        }}
      />
    </div>
  );
};

export default withContext(HuquqTrustees);
