import validateIdZipcode from './IdZipcodeValidation';
import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';
import SafeStringifyAxiosError from '../../Utilities/SafeStringifyAxiosError';
export default function submitIdZipcode(token: string, Id: string, Zipcode: string, recatpcha: string) {
  const IdZipcodemessage = validateIdZipcode(Zipcode);

  if (IdZipcodemessage !== false) {
    return IdZipcodemessage;
  }
  return GlobalAPICall.post(
    process.env.REACT_APP_AUTH_API_URL + '/api/registration/step1',
    {
      //('https://auth-test.usbnc.org/api/registration/step1', {
      UserName: Id,
      ZipCode: Zipcode,
      GoogleReCaptchaResponse: recatpcha,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    }
  )
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      if (err.response.status === 400) {
        return Promise.reject({
          status: err.response.status,
          message: SafeStringifyAxiosError(err),
        });
      } else {
        return Promise.reject({
          status: err.response.status,
          message: 'Our services are currently unavailable. Please come back later.',
        });
      }
    });
}
