import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import withContext from '../ContextAPI/Context_HOC';

class Writings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkdisplay: [],
    };
  }
  componentDidMount() {
    this.subscription = this.props.context.digitalDesignContent
      .items()
      .type('writing')
      .elementsParameter(['base__title', 'url_slug', 'base__datepublished', 'author'])
      .orderParameter('elements.base__datepublished', 'desc')
      .limitParameter(30)
      .toObservable()
      .subscribe((response) => {
        if (!response.isEmpty) {
          let links = [];
          let authors = [];
          response.items.forEach((x) => {
            if (authors.indexOf(x.author.text) === -1) {
              authors.push(x.author.text);
            }
          });
          authors.forEach((y) => {
            response.items.forEach((x, key) => {
              if (x.author.text === y) {
                links.push(
                  <li key={key}>
                    <Link data-cy={`writingslink${key}`} to={{ pathname: `/writings/${x.url_slug.value}` }}>
                      {x.base__title.value} - ({x.author.value})
                    </Link>
                  </li>
                );
              }
            });
          });
          this.setState({ linkdisplay: links });
        }
      });
  }
  componentWillUnmount() {
    this.subscription.unsubscribe();
  }
  render() {
    return (
      <div data-cy="mainWritingsdisplaylistlink">
        <h1>Selected Writings</h1>
        <ul className="plain-list"> {this.state.linkdisplay}</ul>
        <hr />
        <h3>Spanish Translations of Bahá’í Writings</h3>
        <p>
          The International Panel for Spanish Translations of Bahá’í Literature, appointed by the Universal House of Justice,
          provides approved, revised, and updated translations of authoritative Bahá’í texts at{' '}
          <a href="https://www.bahaipanel.org/" target="_blank" rel="noopener noreferrer">
            https://www.bahaipanel.org
          </a>
          .
        </p>
      </div>
    );
  }
}
export default withContext(Writings);
