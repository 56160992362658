import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class NatconArticle extends ContentItem {
  public featuredImage: Elements.AssetsElement;
  public baseTitle: Elements.TextElement;
  public pinnedArticle: Elements.MultipleChoiceElement;
  public natcon: Elements.TaxonomyElement;
  public urlSlug: Elements.UrlSlugElement;
  public articleThumbnail: Elements.AssetsElement;
  public introBlurb: Elements.RichTextElement;
  public baseBahaiOrPublicContent: Elements.MultipleChoiceElement;
  public baseDatepublished: Elements.DateTimeElement;
  public baseListenhtml: Elements.TextElement;
  public baseBody: Elements.RichTextElement;
  constructor() {
    super({
      propertyResolver: (elementName: string) => {
        if (elementName === 'featured_image') {
          return 'featuredImage';
        }
        if (elementName === 'base__title') {
          return 'baseTitle';
        }
        if (elementName === 'pinned_article') {
          return 'pinnedArticle';
        }
        if (elementName === 'url_slug') {
          return 'urlSlug';
        }
        if (elementName === 'article_thumbnail') {
          return 'articleThumbnail';
        }
        if (elementName === 'intro_blurb') {
          return 'introBlurb';
        }
        if (elementName === 'base__bahai_or_public_content') {
          return 'baseBahaiOrPublicContent';
        }
        if (elementName === 'base__datepublished') {
          return 'baseDatepublished';
        }
        if (elementName === 'base__listenhtml') {
          return 'baseListenhtml';
        }
        if (elementName === 'base__body') {
          return 'baseBody';
        }
        return elementName;
      },
    });
  }
}
