import ContentProviderEndpoint from './ContentProviderEndpoint';
import { ContentItem } from '@kentico/kontent-delivery';
import GlsaProxy from '../Glsa/Utilities/GlsaProxy';

export enum ContentProjectEnum {
  DigitalDesign = '8ee801bf-7b90-4b93-9f60-80c7b82354ba',
  BahaiPrayers = 'b1ec290a-bc9e-003d-7d1b-acc92b04fcef',
  GLSA = 'bb420ebf-8486-0024-0873-f3cf321ee90e',
}

function resolveCoverLetter(item: ContentItem): string {
  const coverLetterItem = item;
  const institutionUrlPart = item.cover_letter.value[0].codename;
  const coverLetterYear = coverLetterItem.base__datepublished.rawData.value.substring(0, 4);
  const coverLetterUrlSlug = coverLetterItem.url_slug.value;
  const languagePart = coverLetterItem.system.language === 'en-US' ? '' : `-${coverLetterItem.system.language}`;
  const resolvedUrl = `/guidance/${institutionUrlPart}/${coverLetterUrlSlug}-${coverLetterYear}${languagePart}`;
  return resolvedUrl;
}

function resolveFAQ(item: ContentItem): string {
  const faqItem = item;
  const faqUrlSlug = faqItem.url_slug.value;
  const faqTaxonomy = faqItem.faq_taxonomy.value[0].codename;
  const resolvedUrl = `/faq/${faqTaxonomy}/${faqUrlSlug}`;
  return resolvedUrl;
}

function resolveFeastMessage(item: ContentItem): string {
  const feastMessage = item;
  const feastMessageCover = feastMessage.linkeditemssection.value[0];
  const feastMessageYear = feastMessage.base__datepublished.rawData.value.substring(0, 4);
  const feastMessageUrlSlug = feastMessage.url_slug.value;
  const feastMessageCoverYear = feastMessageCover.base__datepublished.rawData.value.substring(0, 4);
  const feastMessageCoverUrlSlug = feastMessageCover.url_slug.value;
  const resolvedUrl = `/guidance/feast_message/${feastMessageCoverUrlSlug}-${feastMessageCoverYear}/${feastMessageUrlSlug}-${feastMessageYear}`;
  return resolvedUrl;
}

function resolveLetter(item: ContentItem): string {
  const letterItem = item;
  const coverLetterItem = letterItem.coverletterlink.value[0];
  const letterYear = letterItem.base__datepublished.rawData.value.substring(0, 4);
  const letterUrlSlug = letterItem.url_slug.value;
  const letterLanguagePart = letterItem.system.language === 'en-US' ? '' : `-${letterItem.system.language}`;
  const institutionUrlPart = coverLetterItem.cover_letter.value[0].codename;
  const coverLetterYear = coverLetterItem.base__datepublished.rawData.value.substring(0, 4);
  const coverLetterUrlSlug = coverLetterItem.url_slug.value;
  const coverLetterLanguagePart = coverLetterItem.system.language === 'en-US' ? '' : `-${coverLetterItem.system.language}`;
  const resolvedUrl = `/guidance/${institutionUrlPart}/${coverLetterUrlSlug}-${coverLetterYear}${coverLetterLanguagePart}/${letterUrlSlug}-${letterYear}${letterLanguagePart}`;
  return resolvedUrl;
}

function resolveNewsArticle(item: ContentItem): string {
  const newsArticle = item;
  const newsArticleUrlSlug = newsArticle.url_slug.value;
  const newsTaxonomy = newsArticle.news.value[0].codename;
  const resolvedUrl = `/news/${newsTaxonomy}/${newsArticleUrlSlug}`;
  return resolvedUrl;
}

function resolvePrayer(item: ContentItem): string {
  const urlSlug = item.url_slug.value;
  const prayerCategories = item.prayer_category.value.reduce(
    (accumulator: string, currentValue: { codename: string }) => `${accumulator}/${currentValue.codename}`,
    ''
  );
  const resolvedUrl = `/prayer${prayerCategories}/${urlSlug}`;
  return resolvedUrl;
}

function resolveWriting(item: ContentItem): string {
  const urlSlug = item.url_slug.value;
  const resolvedUrl = `/writing/${urlSlug}`;
  return resolvedUrl;
}

export default class ContentResolver {
  digitalDesignContent: ContentProviderEndpoint;
  prayerContent: ContentProviderEndpoint;
  glsaProxy: GlsaProxy;

  constructor(digitalDesignContent: ContentProviderEndpoint, prayerContent: ContentProviderEndpoint, glsaProxy: GlsaProxy) {
    this.digitalDesignContent = digitalDesignContent;
    this.prayerContent = prayerContent;
    this.glsaProxy = glsaProxy;
  }

  async resolveProjectDigitalDesign(systemCodeName: string, lang: string): Promise<string> {
    const response = await this.digitalDesignContent
      .item(systemCodeName)
      .languageParameter(lang)
      .elementsParameter([
        'base__datepublished',
        'url_slug',
        'coverletterlink',
        'linkeditemssection',
        'cover_letter',
        'faq_taxonomy',
        'news',
      ])
      .toPromise();

    switch (response.item.system.type) {
      case 'cover_letter':
        return resolveCoverLetter(response.item);
      case 'faq':
        return resolveFAQ(response.item);
      case 'feast_message':
        return resolveFeastMessage(response.item);
      case 'letter':
        return resolveLetter(response.item);
      case 'news_article':
        return resolveNewsArticle(response.item);
      default:
        throw new Error(
          `Not Implemented: Type ${response.item.system.type} of systemCodeName "${systemCodeName}" and language ${lang} is not implemented`
        );
    }
  }

  async resolveProjectBahaiPrayers(systemCodeName: string, lang: string): Promise<string> {
    const response = await this.prayerContent
      .item(systemCodeName)
      .languageParameter(lang)
      .elementsParameter(['url_slug', 'prayer_category'])
      .toPromise();

    switch (response.item.system.type) {
      case 'prayer':
        return resolvePrayer(response.item);
      case 'writing':
        return resolveWriting(response.item);
      default:
        throw new Error(
          `Not Implemented: Type ${response.item.system.type} of systemCodeName "${systemCodeName}" and language ${lang} is not implemented`
        );
    }
  }

  async resolveGLSA(systemCodeName: string, lang: string): Promise<string> {
    return (await this.glsaProxy.buildUrlFromContentItemCodename(systemCodeName, lang).toPromise()) as string;
  }

  async ResolveUrl(project: ContentProjectEnum, systemCodeName: string, language: string | undefined): Promise<string> {
    const lang = language ?? 'en-US';

    switch (project) {
      case ContentProjectEnum.DigitalDesign:
        return await this.resolveProjectDigitalDesign(systemCodeName, lang);
      case ContentProjectEnum.BahaiPrayers:
        return await this.resolveProjectBahaiPrayers(systemCodeName, lang);
      case ContentProjectEnum.GLSA:
        return await this.resolveGLSA(systemCodeName, lang);
      default:
        throw new Error(
          `Not Implemented: Project ${project} with systemCodeName "${systemCodeName}" and language ${lang} is not implemented`
        );
    }
  }
}
