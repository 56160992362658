import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsightsContainer, ApplicationInsightsServiceInterface } from './ApplicationInsightsServiceInterface';

let reactPlugin: ReactPlugin | null = null;
const applicationInsightsContainer = {} as ApplicationInsightsContainer;
let key: string | undefined = undefined;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, applicationInsights: Object, initialize: Function}} - Object
 */
const createApplicationInsightsService = () => {
  /**
   * Set instrumentation key
   * @param {string} instrumentationKey - Application Insights Instrumentation Key
   */
  const setInstrumentationKey = (instrumentationKey: string) => (key = instrumentationKey);

  /**
   * Initialize the Application Insights class
   * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
   * @return {void}
   */
  const initialize = (browserHistory: any) => {
    if (!key) {
      throw new Error('Instrumentation instrumentationKey is required. Call setInstrumentationKey first.');
    }
    if (!browserHistory) {
      throw new Error('Could not initialize Telemetry Service');
    }

    reactPlugin = new ReactPlugin();

    applicationInsightsContainer.applicationInsights = new ApplicationInsights({
      config: {
        instrumentationKey: key,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history: browserHistory,
          },
        },
      },
    });

    applicationInsightsContainer.applicationInsights.loadAppInsights();
  };

  return {
    reactPlugin,
    applicationInsightsContainer,
    initialize,
    setInstrumentationKey,
  };
};

export const ApplicationInsightsService = createApplicationInsightsService() as ApplicationInsightsServiceInterface;
