import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';
import { map } from 'rxjs/operators';

export default function getabmcontact(token, id) {
  const observable = GlobalAPICall.getObservable(process.env.REACT_APP_API_URL + `/api/AbmContact/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  }).pipe(
    map((x) => {
      return x.data;
    })
  );
  return observable;
}
