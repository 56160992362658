import React from 'react';

function ByElections() {
  return (
    <div>
      <h1>Local Spiritual Assembly By-elections</h1>
      <br />
      <ul className="plain-list">
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/20240911+NSA+to+all+LSAs+-+Reporting+by-elections.pdf">
            11 Sep 2024 Letter from the NSA to all LSAs about reporting by-elections (PDF)
          </a>
        </li>
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/Instructions+on+reporting+by-elections.pdf">
            Reporting By-elections of Local Spiritual Assemblies <em>updated September 2024</em> (PDF)
          </a>
        </li>
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/By-Election+form.pdf">By-election form (PDF)</a>
        </li>
      </ul>
    </div>
  );
}

export default ByElections;
