export default function WebsiteUpdatesListOrRecentEndpoint(props, setHook) {
  const subscription = props.context.digitalDesignContent
    .items()
    .type(['website_update_article'])
    .limitParameter(6)
    .orderParameter('elements.base__datepublished', 'desc')
    .toObservable()
    .subscribe(async (response) => {
      if (!response.isEmpty) {
        setHook(response.items);
      }
    });
  return subscription;
}
