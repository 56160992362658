import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import withContext from '../ContextAPI/Context_HOC';
import HtmlLinkReplacer from './Utilities/HtmlLinkReplacer';
import { NavLink } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import FormatUpdatedDate from './Utilities/FormatUpdatedDate';
import SpeechSynthesis from '../PWA/speech-synthesis/SpeechSynthesis';

const DisplaySubSectPage = ({ subSectionData, allTaxonomies, linkData, matchingUrl }) => {
  const [showPlayer, setShowPlayer] = useState(false);
  const htmlLinkReplacer = new HtmlLinkReplacer(linkData, allTaxonomies.urlsByCodename);
  const options = {
    replace: (attribs) => {
      if (attribs.attribs !== undefined && attribs.name === 'a' && linkData.length > 0) {
        return htmlLinkReplacer.TryReplace(attribs);
      }
    },
  };

  subSectionData.base__body.resolveHtml();
  useEffect(() => {
    setShowPlayer(true);
  }, []);
  const x = parse(subSectionData.base__body.resolvedData.html, options);
  return (
    <div>
      {showPlayer && window.speechSynthesis && (
        <SpeechSynthesis
          text={
            document.getElementById('glsaPartOne')?.textContent +
            ' ' +
            document.getElementById('glsaPartDate')?.textContent +
            ' ' +
            document.getElementById('glsaPartTwo')?.textContent
          }
        />
      )}
      <h1 id="glsaPartOne" data-cy="title" className="title">
        {subSectionData.base__title.value}
      </h1>
      <p>
        <em id="glsaPartDate">{FormatUpdatedDate(subSectionData.base__revised_date.value)}</em>
      </p>
      <hr />
      <NavLink to={matchingUrl.prevUrl} data-cy="sectionnavprevious">
        <ExpandLess className="large-text" />
      </NavLink>
      <div data-cy="body-text" id="glsaPartTwo">
        {x}
      </div>
      <NavLink to={matchingUrl.nextUrl} data-cy="sectionnavnext">
        <ExpandMore className="large-text" />
      </NavLink>
    </div>
  );
};

export default withContext(DisplaySubSectPage);
