import { forEach } from 'lodash';
import { name } from '../../src/Utilities/GeneralRegEx';
export interface Validator {
  regEx: RegExp;
  error: string;
}

export const COMMON_VALIDATORS = {
  name: (errorMessage?: string, regEx?: RegExp) => {
    return {
      regEx: regEx ? regEx : name,
      error: errorMessage ? errorMessage : 'Invalid name format.',
    };
  },
  maxLength: (maxLength: number, fieldName?: string) => {
    return (value?: string | string[]) => {
      let invalid = false;
      if (Array.isArray(value)) {
        forEach(value, (arrVal) => {
          if (lengthInvalid(arrVal)) {
            invalid = true;
          }
        });
      } else {
        if (lengthInvalid(value)) {
          invalid = true;
        }
      }
      return invalid ? `${fieldName ?? 'This field'} must be a string with a maximum length of ${maxLength}.` : '';

      function lengthInvalid(val?: string) {
        return val && val.length > maxLength;
      }
    };
  },
};
