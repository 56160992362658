import React, { FunctionComponent, useEffect } from 'react';
import AuthService from '../Login/AuthService';
import withContext from '../ContextAPI/Context_HOC';
import { withRouter } from 'react-router-dom';

const Logout: FunctionComponent<{
  context: {
    authService: AuthService;
    setredirectUrl: (_: { pathname: string }) => void;
  };
  history: {
    replace: (_: string) => void;
  };
}> = ({ context: { authService, setredirectUrl }, history: { replace } }) => {
  useEffect(() => {
    setredirectUrl({ pathname: '' });
    authService.logout();
    replace('/');
  }, []);

  return (
    <div>
      <p>Logging out...</p>
    </div>
  );
};

export default withRouter(withContext(Logout));
