import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import WillsAndBequests from '../../assets/img/PlannedGiving/WillsAndBequests.png';

function Bequests() {
  return (
    <Row>
      <Col sm={1}>
        <img src={WillsAndBequests} alt="Bequests and Wills" className="fund-logo" />
      </Col>
      <Col sm={11}>
        <h1>Bequests and Wills</h1>
        <p>
          A bequest is a gift made through the believer’s Will to the Bahá’í Faith. Every Bahá’í has the opportunity to make a
          bequest. Throughout the years, the Institutions of the Bahá’í Faith have benefited from the generosity of a great many
          believers who, in meeting their obligation to have a Will and Testament, have also chosen to leave a portion of their
          material resources to the Bahá’í Faith.
        </p>
        <ul className="plain-list">
          <li>
            <strong>Download: </strong>
            <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Planned-Giving/Writing-Will-Testament-20210502.pdf">
              The Writing of a Will and Testament Booklet
            </a>{' '}
            (
            <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Planned-Giving/Application-of-Bahai-burial-laws-For-Covid19.pdf">
              Additional Guidance on Applying Burial Law During Coronavirus Health Crisis
            </a>
            ) /{' '}
            <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Planned-Giving/the-writing-of-a-will-Persian-2020.pdf">
              Persian Translation
            </a>
          </li>
          <li>
            <strong>Download: </strong>
            <a href="https://s3.amazonaws.com/bahai-web/tab/forms/pg-notice-of-bequest.pdf">
              Notice of Bequest to the Baha’i Faith
            </a>
          </li>
          <li>
            <strong>Download: </strong>
            <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Planned-Giving/Will-and-Testament-with-the-Greatest-Name.pdf">
              Greatest Name Template for Will and Testament
            </a>{' '}
            (
            <em>
              It is acceptable to place the Greatest Name symbol (Arabic calligraphy) on the top of one’s Will and Testament
            </em>
            )
          </li>
          <li>
            <strong>Download: </strong>
            <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Planned-Giving/beneficiary-and-bequest-info-website.doc">
              Information Needed When Designating the National Spiritual Assembly as Beneficiary or Leaving a Bequest
            </a>
          </li>
        </ul>
      </Col>
    </Row>
  );
}

export default Bequests;
