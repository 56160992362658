import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import withContext from '../ContextAPI/Context_HOC';
import { OptimizelyFeature } from '@optimizely/react-sdk';

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkdisplay: [],
    };
  }
  componentDidMount() {
    this.subscription = this.props.context.digitalDesignContent
      .items()
      .types(['news_article'])
      .elementsParameter(['base__title', 'url_slug', 'base__datepublished', 'news'])
      .orderParameter('elements.base__datepublished', 'desc')
      .toObservable()
      .subscribe(async (response) => {
        if (!response.isEmpty) {
          let links = [];
          let taxonomies = [];
          let count = 0;
          response.items.forEach((x) => {
            for (let i = 0; i < x.news.value.length; i++) {
              if (taxonomies.indexOf(x.news.value[i].name) === -1) {
                taxonomies.push(x.news.value[i].name);
              }
            }
          });
          taxonomies = taxonomies.sort();
          taxonomies.forEach((x) => {
            links.push(
              <h3 className={'large-top-padding'} key={count}>
                {x}
              </h3>
            );
            count++;
            response.items.forEach((y) => {
              for (let i = 0; i < y.news.value.length; i++) {
                if (x === y.news.value[i].name) {
                  links.push(
                    <li className="indented-list-item" key={count}>
                      <Link
                        data-cy={`newslink${count}`}
                        to={{
                          pathname: `/news/${y.news.value[i].codename}/${y.url_slug.value}`,
                        }}
                      >
                        {y.base__title.value}{' '}
                      </Link>
                    </li>
                  );
                  count++;
                }
              }
            });
          });
          this.setState({ linkdisplay: links });
        }
      });
  }
  componentWillUnmount() {
    this.subscription.unsubscribe();
  }
  render() {
    return (
      <div>
        <h1>News</h1>
        <ul className="plain-list">{this.state.linkdisplay}</ul>
        <h3>National Convention</h3>
        <ul className="plain-list">
          <OptimizelyFeature feature="natcon">
            {(isEnabled) =>
              isEnabled ? (
                <li className="indented-list-item">
                  <Link to="/news/natcon-2024">National Convention 2024</Link>
                </li>
              ) : null
            }
          </OptimizelyFeature>
          <li className="indented-list-item">
            <Link to="/news/natcon-2023">National Convention 2023</Link>
          </li>
          <li className="indented-list-item">
            <Link to="/news/natcon-2022">National Convention 2022</Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default withContext(News);
