import useCustomHookForm from "../../Hooks/forms/use-custom-hook-form";
import {
  CustomFieldFormType
} from "../../Hooks/forms/enums/custom-field-form-type";
import React from "react";
import {Button} from "@mui/material";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import theme from "../../styles/theme";
import {CustomField} from "../../Hooks/forms/type/custom-field";

export enum AcceptanceRadio {
  update='Update',
  withdrewAndRejoin='Withdrawn'
}
export interface AcceptanceResponseForm {
  radioAcceptanceForm:AcceptanceRadio;
}
export interface AcceptanceFormProps {
  callbackAcceptance: (response: AcceptanceResponseForm) => void
}
export const AcceptanceFieldsArray:CustomField<any>[] = [
  {
    type: CustomFieldFormType.Radio,
    formControlName: 'radioAcceptanceForm',
    label: 'I would like to:',
    placeholder: 'Ex: Radio',
    help: '',
    radio: {
      options: [
        {
          value: AcceptanceRadio.update,
          label: 'update my contact information',
          id: '0'
        },
        {
          value: AcceptanceRadio.withdrewAndRejoin,
          label: 're-enroll as a Bahá\'í',
          id: '1'
        }
      ],
    },
    validators: {
      required: "Required type of update"
    }
  }
];
export default function AcceptanceForm(props:AcceptanceFormProps){

  const [fields, useFormObject] = useCustomHookForm<AcceptanceResponseForm, string | number>({
    customFieldsArray: AcceptanceFieldsArray,
    initialValues: {
      radioAcceptanceForm: AcceptanceRadio.update
    },
  });
  const {handleSubmit, formState: {errors}} = useFormObject;
  const onSubmit = props.callbackAcceptance;
  return (
    <form onSubmit={handleSubmit(onSubmit) as never}>
      <div className="d-flex flex-column text-left">
        {fields}
      </div>
      <div className="">
        <Button size="small" disabled={Object.keys(errors).length > 0} type="submit">
          Continue
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft/>
            ) : (
            <KeyboardArrowRight/>
          )}
        </Button>
      </div>
    </form>
  )
}
