import React, { FunctionComponent, useState, useEffect } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { NavLink } from 'react-router-dom';
import type { Homeblock } from '../Kentico/DigitalDesign/homeblock';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import withContext from '../ContextAPI/Context_HOC';
import type ContentResolver from '../Content/ContentResolver';
import { ContentProjectEnum } from '../Content/ContentResolver';
import BlockSize from './BlockSize';
import { dateStringToDisplayDate, replaceBaseUrlLinks } from '../Utilities/UtilityFunctions';

const replaceParagraphWithHeader4: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode.name === 'p') {
      domNode.name = 'h4';
      return domNode;
    }
  },
};

const HomeBlockItem: FunctionComponent<{
  homeBlock: Homeblock;
  size: BlockSize;
  context: { contentResolver: ContentResolver };
}> = ({ homeBlock, size, context: { contentResolver } }) => {
  const [navLink, setNavLink] = useState<string>();
  const [readMoreFreeTextDisplay, setReadMoreFreeTextDisplay] = useState<JSX.Element | JSX.Element[] | null>(null);
  const [displayFullBlockImageDialog, setDisplayFullBlockImageDialog] = useState(false);
  const formattedTitleRawHtml = homeBlock.formattedtitleTitle.resolveHtml();
  const formattedTitle = parse(formattedTitleRawHtml, replaceParagraphWithHeader4);
  const bodyRawHtml = replaceBaseUrlLinks(homeBlock.baseBody.resolveHtml());
  const body = parse(bodyRawHtml);
  const isoDateString = homeBlock.baseDatepublished?.rawData?.value;
  const publishedDateString = dateStringToDisplayDate(isoDateString, true);
  const readMoreLinkedItem = homeBlock.readMoreLinkedItem?.value[0];
  const readMoreFreeText = homeBlock.readMoreFreeText;
  const readMoreAsset = homeBlock.readMoreAsset;
  const image = homeBlock.fullBlockImage?.value[0];
  const sideImage = homeBlock.halfBlockImage?.value[0];
  const listenHtml = homeBlock.baseListenhtml?.value ? (
    <div data-cy="listen-section-container" dangerouslySetInnerHTML={{ __html: homeBlock.baseListenhtml.value }} />
  ) : null;

  useEffect(() => {
    if (readMoreLinkedItem) {
      contentResolver
        .ResolveUrl(ContentProjectEnum.DigitalDesign, readMoreLinkedItem.system.codename, readMoreLinkedItem.system.language)
        .then((newNavLink) => setNavLink(newNavLink))
        .catch((e) => console.error(e));
    }
  }, [contentResolver, readMoreLinkedItem]);

  useEffect(() => {
    if (!readMoreLinkedItem && readMoreFreeText) {
      const readMoreFreeTextResolvedRawHtml = readMoreFreeText.resolveHtml();
      const readMoreFreeTextResolvedElement = parse(readMoreFreeTextResolvedRawHtml);
      setReadMoreFreeTextDisplay(readMoreFreeTextResolvedElement);
    }
  }, [readMoreLinkedItem, readMoreFreeText]);

  useEffect(() => {
    if (!readMoreLinkedItem && !readMoreFreeText && readMoreAsset && readMoreAsset.value?.length) {
      setNavLink(readMoreAsset.value[0].url);
    }
  }, [readMoreLinkedItem, readMoreFreeText, readMoreAsset]);

  return (
    <div className="home-box">
      {size === BlockSize.Half ? (
        <span>
          {formattedTitle}
          <p className="small-text">{publishedDateString}</p>
          {body}
          {listenHtml}
          {navLink ? (
            <p className="end small-negative-top-margin">
              <NavLink to={navLink}>Read More</NavLink>
            </p>
          ) : null}
          <div className="end small-negative-top-margin">{readMoreFreeTextDisplay}</div>
        </span>
      ) : (
        <span>
          <Row>
            <Col sm={10} className="no-padding">
              {formattedTitle}
            </Col>
            <Col sm={2} className="end no-padding">
              <p className="small-text">{publishedDateString}</p>
            </Col>
          </Row>
          {sideImage ? (
            <Row>
              <Col sm={3}>
                <span>
                  <img src={sideImage.url} alt={sideImage.description ?? ''} className="home-image-side small-bottom-margin" />
                </span>
              </Col>
              <Col sm={9}>{body}</Col>
            </Row>
          ) : (
            body
          )}
          {image ? (
            <span className="home-block-image-container">
              <img
                src={image.url}
                onClick={() => setDisplayFullBlockImageDialog(true)}
                alt={image.description ?? ''}
                className="home-image small-bottom-margin zoom-in"
              />
              {displayFullBlockImageDialog && (
                <dialog className="dialog" open onClick={() => setDisplayFullBlockImageDialog(false)}>
                  <img
                    className="image zoom-out"
                    src={image.url}
                    onClick={() => setDisplayFullBlockImageDialog(false)}
                    alt={image.description ?? ''}
                  />
                </dialog>
              )}
            </span>
          ) : null}
          {listenHtml}
          {navLink ? (
            <p className="end small-negative-top-margin">
              <NavLink to={navLink}>Read More</NavLink>
            </p>
          ) : null}
          <div className="end small-negative-top-margin">{readMoreFreeTextDisplay}</div>
        </span>
      )}
    </div>
  );
};

export default withContext(HomeBlockItem);
