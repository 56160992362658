import React from 'react';
import createAppToken from '../Utilities/AppTokenEndpoint';
import submitIdZipcode from '../Utilities/IdZipcodeEndpoint';
import withContext from '../../ContextAPI/Context_HOC';
import SafeStringify from '../../Utilities/SafeStringify';
import { withOptimizely } from '@optimizely/react-sdk';
import ZipIdValidationForm from '../CreateBOS/ZipIdValidationForm';
import CreateBOSBaseStep, { Step } from './CreateBOSBaseStep';
import CreateBOSHeader from './CreateBOSHeader';

class CreateBOSStep1 extends CreateBOSBaseStep {
  constructor(props: any) {
    super(props, true);
    this._step = Step.ZipId;
    this.handleZipId = this.handleZipId.bind(this);
    this.handleRecaptcha = this.handleRecaptcha.bind(this);
  }

  handleRecaptcha(token: string | null) {
    this.saveStateToStorage({
      googleReCaptchaResponse: token || '',
      displayMessage: <></>,
    });
  }

  handleZipId(e: any) {
    e.preventDefault();
    if (this.state.temporaryCode) {
      this.saveStateToStorage({
        currentStep: Step.SecurityQuestions,
        dontHaveZipCheck: true,
      });
      return;
    }

    const recaptchaCode = process.env.REACT_APP_RECAPTCHA_CODE;
    if (!this.state.bypassRecaptcha && recaptchaCode && !this.state.googleReCaptchaResponse) {
      this.saveStateToStorage({
        processing: false,
        displayMessage: (
          <p className="error-message" data-cy="error-message">
            Please fill Recaptcha to continue.
          </p>
        ),
      });
      return;
    }

    if (this.state.dontHaveZipCheck) {
      this.saveStateToStorage({
        displayMessage: '',
        currentStep: Step.AdditionalQuestions,
        dontHaveZipCheck: true,
      });
    } else {
      createAppToken()
        .then((res: any) => {
          this.saveStateToStorage({ displayMessage: '' }, () => {
            submitIdZipcode(res.data, this.state.bid, this.state.zip, this.state.googleReCaptchaResponse)
              .then((res) => {
                this.saveStateToStorage({
                  displayMessage: '',
                  clusterdata: res.data,
                  currentStep: Step.AdditionalQuestions,
                });
              })
              .catch((res) => {
                if (res.message === 'Answer additional questions to register for an account.') {
                  this.saveStateToStorage({
                    displayMessage: '',
                    dontHaveZipCheck: true,
                    currentStep: Step.AdditionalQuestions,
                  });
                } else {
                  this.saveStateToStorage({
                    displayMessage: <p className="center error-message">{res.message ? SafeStringify(res.message) : null}</p>,
                  });
                }
              });
          });
        })
        .catch((err: any) => {
          this.saveStateToStorage({
            displayMessage: <p className="center error-message">{err.message ? SafeStringify(err.message) : null}</p>,
          });
        });
    }
  }

  render() {
    return (
      <div className="center">
        <CreateBOSHeader></CreateBOSHeader>
        <ZipIdValidationForm
          BIDstate={this.state.bid}
          Zipstate={this.state.zip}
          dontHaveZipCheck={this.state.dontHaveZipCheck}
          handleChange={this.handleChange}
          handleZipIdForm={this.handleZipId}
          handleRecaptcha={this.props.bypassRecaptcha ? undefined : this.handleRecaptcha}
          displayMessage={this.state.displayMessage}
          onlyListBahaiIdField={!!this.state.temporaryCode}
          showCircularProgress={this.state.showCircularProgress}
          history={this.props.history}
        />
      </div>
    );
  }
}
export default withContext(withOptimizely(CreateBOSStep1));
