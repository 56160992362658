import { map } from 'rxjs/operators';
import ContentProviderEndpoint from '../Content/ContentProviderEndpoint';

export default class FundService {
  contentProvider = new ContentProviderEndpoint();

  constructor(contentProvider) {
    this.contentProvider = contentProvider;
  }

  getStatus() {
    const observable = this.contentProvider
      .item('thermometer')
      .toObservable()
      .pipe(
        map((response) => {
          const fundStatus = {
            asOfDate: response.item.as_of_date.rawData.value,
            currentAmount: response.item.current_amount_in_dollars.value,
            goalAmount: response.item.goal_amount_in_dollars.value,
            goalYear: response.item.goal_year.value?.getUTCFullYear(),
          };
          return fundStatus;
        })
      );

    return observable;
  }
}
