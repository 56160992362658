import React, { FunctionComponent, useEffect, useState } from 'react';
import withContext from '../../../ContextAPI/Context_HOC';
import NatConProxy from '../../Utilities/NatConProxy';
import parse from 'html-react-parser';
import { NatconArticle } from '../../../Kentico/DigitalDesign/natcon_article';
import { byPinnedThenByDatePublished } from '../../Utilities/NatConUtils';
import { Col, Row } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import NatConGenericCarousel from './NatConGenericCarousel';
import { NatConProperties } from '../../Utilities/NatConProperties';

const NatConGeneric: FunctionComponent<{
  match: { params: { year: string } };
  context: { natConProxy: NatConProxy };
}> = ({ match, context: { natConProxy } }) => {
  const [natconArticles, setNatconArticles] = useState([] as NatconArticle[]);
  const natConProps = new NatConProperties(match.params.year);

  useEffect(() => {
    const natConProxyGetNatConsSubscription = natConProxy
      .getNatCons(`national_convention_${match.params.year}`)
      .subscribe((x) => {
        const articlesSorted = [...x].sort(byPinnedThenByDatePublished);
        setNatconArticles(articlesSorted);
      });
    return () => natConProxyGetNatConsSubscription.unsubscribe();
  }, [natConProxy, match.params.year]);

  return (
    <div>
      {<NatConGenericCarousel match={match} />}
      <h1>{natConProps.title || `US Bahá’í National Convention ${match.params.year}`}</h1>
      {natConProps.photoLink && (
        <h4>
          More photos from the Convention available at{' '}
          <a href={natConProps.photoLink} target="_blank" rel="noopener noreferrer">
            Flickr
          </a>
          .
        </h4>
      )}
      <h4>
        Below are stories on the Convention from <em>The American Bahá’í</em>
      </h4>
      <hr />
      <Row>
        <Col sm={12} md={8}>
          {natconArticles.map((article, index) => {
            const introBlurbHtml = article.introBlurb.resolveHtml();
            const intro = parse(introBlurbHtml);
            const thumbnailUrl = article.articleThumbnail?.value?.length > 0 ? article.articleThumbnail.value[0].url : null;
            const thumbnailDescription =
              article.articleThumbnail?.value?.length > 0 ? article.articleThumbnail.value[0].description : '';
            return (
              <React.Fragment key={index}>
                <Link
                  to={{ pathname: `/news/national-conventions/${match.params.year}/${article.system.codename}` }}
                  className="plain-link plain-hover"
                  data-testid={`natconarticle${index}`}
                >
                  <Row>
                    <Col sm={3}>
                      {thumbnailUrl ? <img src={thumbnailUrl} alt={thumbnailDescription} className="thumbnail-img" /> : null}
                    </Col>
                    <Col sm={9}>
                      <h3>{article.baseTitle.value}</h3>
                      <div>{intro}</div>
                    </Col>
                  </Row>
                </Link>
                <hr />
              </React.Fragment>
            );
          })}
        </Col>
        <Col sm={12} md={4}>
          {natConProps.sidebarContentAvailable && (
            <div className="sidebar-box">
              {natConProps.videos.map((video, index) => {
                return (
                  <div key={index}>
                    <h4>{video.title}</h4>
                    <iframe
                      title={video.title}
                      src={video.videoLink}
                      width="100%"
                      height="166"
                      style={{ border: 0 }}
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowFullScreen
                    />
                    <br />
                    {video.audioLink && (
                      <iframe
                        width="100%"
                        height="20"
                        style={{ border: 0, overflow: 'hidden' }}
                        allow="autoplay"
                        src={video.audioLink}
                      />
                    )}
                    {video.downloadLink && (
                      <p>
                        <a href={video.downloadLink} target="_blank" rel="noopener noreferrer">
                          Download the video
                        </a>
                      </p>
                    )}
                    <hr />
                  </div>
                );
              })}
              {!!natConProps.resources?.length && (
                <>
                  <h4>Resources</h4>
                  <ul className="no-margin">
                    {natConProps.resources.map((resource, index) => {
                      return (
                        <li className="small-bottom-padding" key={index}>
                          {resource.link && resource.link.startsWith('/') && <Link to={resource.link}>{resource.title}</Link>}
                          {resource.link && !resource.link.startsWith('/') && <a href={resource.link}>{resource.title}</a>}
                          {!resource.link && <>{`${resource.title} (Coming soon)`} </>}
                        </li>
                      );
                    })}
                  </ul>
                  <hr />
                </>
              )}
              {!!natConProps.audios?.length && (
                <>
                  {natConProps.audios.map((audio, index) => {
                    return (
                      <div key={index}>
                        <h4>{audio.title}</h4>
                        {audio.links.map((link, i) => {
                          return (
                            <iframe
                              key={i}
                              width="100%"
                              height="20"
                              style={{ border: 0, overflow: 'hidden' }}
                              allow="autoplay"
                              src={link}
                            />
                          );
                        })}
                        <hr />
                      </div>
                    );
                  })}
                </>
              )}
              {!!natConProps.archives?.length && (
                <>
                  <h4>National Convention Coverage Archives</h4>
                  <ul className="plain-list">
                    {natConProps.archives.map((resource, index) => {
                      return (
                        <li className="small-bottom-padding" key={index}>
                          {resource.link?.startsWith('/') && <Link to={resource.link}>{resource.title}</Link>}
                          {!resource.link?.startsWith('/') && <a href={resource.link}>{resource.title}</a>}
                        </li>
                      );
                    })}
                  </ul>
                  <hr />
                </>
              )}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};
export default withContext(NatConGeneric);
