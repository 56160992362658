import { map, filter, switchMap } from 'rxjs/operators';
import AuthService from '../../Login/AuthService';
import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';
import { BahaiCalendarFormats, SpecialDayDisplay, TodayResponseModel } from '@usbnc/data-api-proxies';

export default class CalendarService {
  authService: AuthService;
  apiCaller: typeof GlobalAPICall;
  apiUrl: string;

  constructor(authService: AuthService, apiCaller: typeof GlobalAPICall, apiUrl: string) {
    this.authService = authService;
    this.apiCaller = apiCaller;
    this.apiUrl = apiUrl;
    this.getUpcomingDates = this.getUpcomingDates.bind(this);
  }

  getUpcomingDates(days: number, setHook: Function) {
    const subscription = this.apiCaller
      .getObservable(this.apiUrl + `/api/calendar/UpcomingDates/${days}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        map((x) => {
          return x.data.contents as SpecialDayDisplay[];
        })
      )
      .subscribe((x) => setHook(x));

    return subscription;
  }

  getStringFormats() {
    const observable = this.apiCaller
      .getObservable(this.apiUrl + `/api/calendar/StringFormats`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        map((x) => {
          return x.data.contents as BahaiCalendarFormats[];
        })
      );

    return observable;
  }

  getToday() {
    return this.authService
      .getTokenObservable()
      .pipe(filter((token) => !!token))
      .pipe(
        switchMap((token) =>
          this.apiCaller.getObservable(this.apiUrl + `/api/calendar/Today`, {
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          })
        )
      )
      .pipe(
        map((x) => {
          return x.data as TodayResponseModel;
        })
      );
  }
}
