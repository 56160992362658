import React, { useState, FunctionComponent, useEffect, useCallback, FormEvent } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import type PhysicalAddressChangeResponseModel from '../Utilities/PhysicalAddressChangeResponseModel';
import type PhysicalAddressChangeRequestModel from '../Utilities/PhysicalAddressChangeRequestModel';
import type { FormSchemaModelType, ValidationStateSchemaType } from '../../Utilities/useFormTypes';
import { CircularProgress } from '@material-ui/core';
import type IPhysicalAddressProxy from '../Utilities/IPhysicalAddressProxy';
import useForm from '../../Utilities/useForm';
import ErrorMessageToUser from '../../Error/ErrorMessageToUser';
import withContext from '../../ContextAPI/Context_HOC';

/**
 * When arriving to this page from previous page (moving foward) then newAddress.newBlc
 * will be empty because it was initialized empty in the proxy error section of the previous page.
 *
 * When arriving to this page from next page (moving backward) then requestModel.toScrub
 * will be false because it was set to false on this page the first time it was displayed.
 */
function isVerified(requestModel: PhysicalAddressChangeRequestModel, newAddress: PhysicalAddressChangeResponseModel | undefined) {
  return !!(newAddress?.newBlc?.length ?? 0) && requestModel.toScrub;
}

type FormFields = {};

const validationStateSchema: ValidationStateSchemaType<FormFields> = {};

const buildInitialState: () => FormSchemaModelType<FormFields> = () => {
  return {};
};

export type PropsContext = {
  physicalAddressProxy: IPhysicalAddressProxy;
};

export type Props = {
  goToNextPage: () => void;
  goToPreviousPage: () => void;
  newAddress: undefined | PhysicalAddressChangeResponseModel;
  requestModel: PhysicalAddressChangeRequestModel;
  setRequestModel: React.Dispatch<React.SetStateAction<PhysicalAddressChangeRequestModel>>;
  setNewAddress: (newAddress: undefined | PhysicalAddressChangeResponseModel) => void;
  context: PropsContext;
};

const AddressVerificationPage: FunctionComponent<Props> = ({
  goToNextPage,
  goToPreviousPage,
  newAddress,
  requestModel,
  setRequestModel,
  setNewAddress,
  context: { physicalAddressProxy },
}) => {
  const addressVerified = isVerified(requestModel, newAddress);
  const addressNotVerified = !addressVerified;
  const [continueWithAddressProvided, setContinueWithAddressProvided] = useState(!requestModel.toScrub);
  const disableNextButton = addressNotVerified && !continueWithAddressProvided;
  const [showErrorMessageToUser, setShowErrorMessageToUser] = useState(false);
  const [showCircularProgress, setShowCircularProgress] = useState(false);
  const [messageToUser, setMessageToUser] = useState<string | undefined>();

  const submitCallback = useCallback(
    (_: any, processErrors: (serializableErrorFromApi: object) => string) => {
      setShowCircularProgress(true);
      const changeRequest: PhysicalAddressChangeRequestModel = { ...requestModel, toScrub: !continueWithAddressProvided };

      physicalAddressProxy.set(changeRequest).subscribe({
        next: (responseModel) => {
          setRequestModel(changeRequest);
          setNewAddress(responseModel);
          goToNextPage();
        },
        error: (err) => {
          if (err.response?.status === 400) {
            const unprocessedErrors = processErrors(err.response.data);
            if (unprocessedErrors === 'The field "Address" has the following error "Address must be different.". ') {
              setMessageToUser('Address must be different.');
              setShowCircularProgress(false);
              return;
            }

            if (unprocessedErrors) {
              setMessageToUser(unprocessedErrors);
              setShowCircularProgress(false);
              return;
            }
          }

          setShowCircularProgress(false);
          setShowErrorMessageToUser(true);
        },
      });

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    [physicalAddressProxy, continueWithAddressProvided]
  );

  const {
    handleOnSubmit,
  }: {
    handleOnSubmit: (e: FormEvent<HTMLInputElement>) => void;
  } = useForm(buildInitialState(), validationStateSchema, submitCallback);

  useEffect(() => {
    setRequestModel((prev) => ({ ...prev, toScrub: !continueWithAddressProvided }));
  }, [continueWithAddressProvided, setRequestModel]);

  if (showErrorMessageToUser) {
    return <ErrorMessageToUser />;
  }

  return (
    <div>
      {showCircularProgress ? (
        <CircularProgress data-cy="circular-progress" className="loading-animation" size={40} />
      ) : (
        <>
          {messageToUser ? (
            <p data-cy="unsuccess-message" className="error-message wider">
              {messageToUser}
            </p>
          ) : null}
          <div className="profile-box">
            {addressVerified ? (
              <div>
                <p>
                  <strong>
                    We were able to verify your address as shown below. If you would like to proceed, please click Next.
                  </strong>
                </p>
              </div>
            ) : (
              <div>
                <p className="error-message min-width-full center">
                  <strong>We are not able to verify your address</strong>
                </p>
                <p>
                  Please go back and re-examine your address for errors or select the option below to continue with the address
                  you provided.
                </p>
              </div>
            )}
            <hr />
            {newAddress ? (
              <div>
                <p className="small-bottom-padding">
                  <strong>In Care Of :</strong> {newAddress.inCareOf}
                </p>
                <p>{newAddress.line1}</p>
                {newAddress.line2 ? <p>{newAddress.line2}</p> : null}
                <p>
                  {newAddress.city}, {newAddress.state} {newAddress.zipcode}
                </p>
                {addressNotVerified ? (
                  <>
                    <hr />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="continueWithAddressProvided"
                          name="continueWithAddressProvided"
                          color="default"
                          checked={continueWithAddressProvided}
                          onChange={(e) => setContinueWithAddressProvided(e.target.checked)}
                          data-cy="continuewithaddressprovided"
                        />
                      }
                      label="Please continue with the address I provided."
                    />
                  </>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className="medium-top-margin end">
            <Row end="xs" className="medium-top-margin">
              <Col sm={2} className="no-padding">
                <input type="button" value="Back" className="secondary-button" data-cy="backsubmit" onClick={goToPreviousPage} />
              </Col>
              <Col sm={2} className="no-padding">
                <input
                  disabled={disableNextButton}
                  type="submit"
                  value="Next"
                  className="primary-button"
                  data-cy="nextsubmit"
                  onClick={(e) => {
                    handleOnSubmit(e);
                  }}
                />
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
};
export default withContext(AddressVerificationPage);
