import ForgotPasswordValidation from './ForgotPasswordValidation';
import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';

export default function ForgotPasswordEndpoint(
  apptoken: string,
  userid?: string,
  email?: string,
  noAccessToCurrentEmail?: boolean,
  newEmail?: string,
  lastName?: string,
  googleReCaptchaResponse?: string
) {
  const trimmedUserId = userid ? userid.trim() : null;
  const trimmedEmail = email ? email.trim() : null;
  const sanitizedNoAccessToCurrentEmail = !!noAccessToCurrentEmail;
  const trimmedNewEmail = newEmail ? newEmail.trim() : null;
  const trimmedLastName = lastName ? lastName.trim() : null;
  const validationmessage = ForgotPasswordValidation(
    trimmedUserId,
    trimmedEmail,
    sanitizedNoAccessToCurrentEmail,
    trimmedNewEmail,
    trimmedLastName
  );
  if (validationmessage) {
    return validationmessage;
  }

  return GlobalAPICall.post(
    `${process.env.REACT_APP_AUTH_API_URL}/api/ForgotPassword/step1`,
    {
      userName: trimmedUserId,
      email: trimmedEmail,
      noAccessToCurrentEmail: sanitizedNoAccessToCurrentEmail,
      newEmail: trimmedNewEmail,
      lastName: trimmedLastName,
      googleReCaptchaResponse: googleReCaptchaResponse,
    },
    {
      headers: {
        Authorization: 'Bearer ' + apptoken,
        'Content-Type': 'application/json',
      },
    }
  )
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      if (err.response !== undefined && err.response.status === 400) {
        return Promise.reject({
          message: err.response.data,
        });
      } else {
        return Promise.reject({
          message: 'Our services are currently unavailable. Please come back later.',
        });
      }
    });
}
