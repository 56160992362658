import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import Videos from '../../assets/img/FundEducation/EducationalVideos.png';

function FundVideos() {
  return (
    <Row>
      <Col sm={1}>
        <img src={Videos} alt="Videos" className="fund-logo" />
      </Col>
      <Col sm={11}>
        <h1>Fund Development Videos</h1>
        <p>
          <a target="_blank" rel="noopener noreferrer" href="https://vimeo.com/356003544/b6510b3bc2">
            What the Fund Can Do with One Dollar
          </a>
          : Ways in which every dollar of the Fund can be used to support Bahá’u’lláh’s vision. (3 minutes)
        </p>
        <p>
          <a target="_blank" rel="noopener noreferrer" href="https://vimeo.com/384591129/bf5ea3aab4">
            What is Persian Bahá’í Media Service?
          </a>{' '}
          An informational video about PersianBMS, its mission, and how you can help support it. (3 minutes)
        </p>
        <p>
          <a target="_blank" rel="noopener noreferrer" href="https://vimeo.com/295229419/1e90cd77e1">
            The Life of Your Contribution
          </a>
          : A series that features projects that the National Fund supports. (7 minutes)
        </p>
        <p>
          <a target="_blank" rel="noopener noreferrer" href="https://vimeo.com/215073603">
            Universal Participation
          </a>
          : What universal participation means, illustrated by the story of Haji Amin. (2 minutes)
        </p>
        <p>
          <a target="_blank" rel="noopener noreferrer" href="https://vimeo.com/215070940">
            The Master’s Carriage Ride
          </a>
          : A story of ‘Abdu’l-Baha’s sacrificial and generous ways. (1.5 minutes)
        </p>
        <p>
          <a target="_blank" rel="noopener noreferrer" href="https://vimeo.com/215068774">
            The King and the Dervish
          </a>
          : A story of detachment. (2 minutes)
        </p>
        <p>
          <a target="_blank" rel="noopener noreferrer" href="https://vimeo.com/365606451/6f99b8c9d6">
            The Family Fund Newscast, Episode 3: Generosity
          </a>{' '}
          One of our videos for kids. This episode features stories from real kids about how they view generosity! (4.5 minutes)
        </p>
        <p>
          <a target="_blank" rel="noopener noreferrer" href="https://vimeo.com/473579487/e68d0d77c2">
            The Family Fund Newscast, Episode 4: Truthfulness
          </a>{' '}
          One of our videos for kids. This episode features the story of what happened when Fred Mortensen met ‘Abdu’l-Baha! (5.5
          minutes)
        </p>
      </Col>
    </Row>
  );
}

export default FundVideos;
