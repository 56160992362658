import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';
import { map } from 'rxjs/operators';
import { ClusterContact } from '../interfaces/ClusterContact';
import { Observable } from 'rxjs';

export default function getClusterDirectoryByClusterCode(token: string, clusterCode: string): Observable<ClusterContact[]> {
  return GlobalAPICall.getObservable(process.env.REACT_APP_API_URL + `/api/ClusterContact/${clusterCode}`, {
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  }).pipe(
    map((x) => {
      return x.data as ClusterContact[];
    })
  );
}
