import React, { Component } from 'react';
import { extractLetterPartsFromUrl } from '../Utilities/UtilityFunctions';
import withContext from '../ContextAPI/Context_HOC';
import { Switch } from 'react-router-dom';
import BoundariedRoute from '../Error/BoundariedRoute';
import EachCoverLetterDisplay from './EachCoverLetterDisplay';
import DisplayLetter from './DisplayLetter';
import NoMatch from '../Routers/NoMatch';

class DisplayCoverLetter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calldata: {},
      display: <div>Loading...</div>,
      displayDrawer: 'none',
      wrongPath: false,
    };
  }
  componentDidMount() {
    const { coverid } = this.props.match.params;
    const coverLetterParts = extractLetterPartsFromUrl(coverid);
    const urlslug = coverLetterParts.urlSlug;
    const dateFilter = parseInt(coverLetterParts.year);
    const language = coverLetterParts.language?.length === 0 ? 'en-US' : coverLetterParts.language;
    this.subscription = this.props.context.digitalDesignContent
      .items()
      .type('cover_letter')
      .languageParameter(language)
      .elementsParameter([
        'base__title',
        'url_slug',
        'base__datepublished',
        'messages',
        'linked_items',
        'introduction',
        'base__body',
        'attachments',
      ])
      .equalsFilter('elements.url_slug', urlslug)
      .rangeFilter('elements.base__datepublished', dateFilter, dateFilter + 1)
      .orderParameter('elements.base__datepublished', 'desc')
      .toObservable()
      .subscribe(async (response) => {
        if (!response.isEmpty) {
          let display = (
            <Switch>
              <BoundariedRoute
                exact
                path={`/guidance/:typeid/:coverid`}
                render={(props) => <EachCoverLetterDisplay {...props} details={response.firstItem} />}
              />
              <BoundariedRoute path={`/guidance/:typeid/:coverid/:messageid`} component={DisplayLetter} />
            </Switch>
          );
          this.setState({ display });
        } else {
          this.setState({ display: <BoundariedRoute component={NoMatch} /> });
        }
      });
  }
  componentWillUnmount() {
    this.subscription.unsubscribe();
  }
  render() {
    return <div>{this.state.display}</div>;
  }
}
export default withContext(DisplayCoverLetter);
