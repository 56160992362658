import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';
import { filter, switchMap } from 'rxjs/operators';

export default class MailingAddressProxy {
  constructor(authService) {
    this.authService = authService;
  }

  set(data) {
    return this.authService
      .getTokenObservable()
      .pipe(filter((token) => !!token))
      .pipe(
        switchMap((token) =>
          GlobalAPICall.postObservable(process.env.REACT_APP_API_URL + `/api/address/mailing`, data, {
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          })
        )
      );
  }

  remove() {
    return this.authService
      .getTokenObservable()
      .pipe(filter((token) => !!token))
      .pipe(
        switchMap((token) =>
          GlobalAPICall.deleteObservable(process.env.REACT_APP_API_URL + `/api/address`, {
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          })
        )
      );
  }
}
