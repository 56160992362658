import React, { Component } from 'react';
import withContext from '../../ContextAPI/Context_HOC';
import { Row, Col } from 'react-flexbox-grid';
import Contact from '../../assets/img/PlannedGiving/PlannedGivingContact.png';
import Telephone from '../../assets/img/PlannedGiving/Telephone.png';
import Email from '../../assets/img/PlannedGiving/Email.png';
import Address from '../../assets/img/PlannedGiving/Address.png';

class PlannedGivingContact extends Component {
  render() {
    return (
      <Row>
        <Col xs={12} md={6}>
          <Row>
            <Col sm={2}>
              <img src={Contact} alt="Contact Us" className="fund-logo" />
            </Col>
            <Col sm={10}>
              <h1>Questions and Concerns</h1>
              <h3>Contact Us...</h3>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <a href="tel:847-733-3569" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={Telephone} alt="Telephone" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Planned Giving Office: 847-733-3569</h4>
              </Col>
            </Row>
          </a>
          <br />
          <a href="mailto:plannedgiving@usbnc.org" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={Email} alt="Email" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>plannedgiving@usbnc.org</h4>
              </Col>
            </Row>
          </a>
          <br />
          <Row className="fund-rows">
            <Col xs={3} lg={2}>
              <img src={Address} alt="Address" className="fund-icons" />
            </Col>
            <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
              <h4>Bahá’í National Center Offices</h4>
              <h4>1233 Central Street</h4>
              <h4>Evanston, IL 60201-1611</h4>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default withContext(PlannedGivingContact);
