import React from 'react';
import HomeBlockItem from '../HomeBlockItem';
import BlockSize from '../BlockSize';
import type { Homeblock } from '../../Kentico/DigitalDesign/homeblock';
import { Row, Col } from 'react-flexbox-grid';

export function ByRowThenColumnThenMoveToPreviousDate(a: Homeblock, b: Homeblock) {
  if (!a.row.value) {
    return 1;
  }
  if (!b.row.value) {
    return -1;
  }
  if (a.row.value > b.row.value) {
    return 1;
  }
  if (a.row.value < b.row.value) {
    return -1;
  }
  if (!a.column.value) {
    return 1;
  }
  if (!b.column.value) {
    return -1;
  }
  if (a.column.value[0].codename === 'full' && b.column.value[0].codename !== 'full') {
    return -1;
  }
  if (a.column.value[0].codename === 'right' && b.column.value[0].codename === 'left') {
    return 1;
  }
  if (a.column.value[0].codename === 'left' && b.column.value[0].codename === 'right') {
    return -1;
  }
  if (!a.moveToPreviousAnnouncementsOn.value) {
    return 1;
  }
  if (!b.moveToPreviousAnnouncementsOn.value) {
    return -1;
  }
  if (a.moveToPreviousAnnouncementsOn.value > b.moveToPreviousAnnouncementsOn.value) {
    return -1;
  }
  if (a.moveToPreviousAnnouncementsOn.value < b.moveToPreviousAnnouncementsOn.value) {
    return 1;
  }
  return 0;
}

export function ToOrganizedBlocks(accumulator: Homeblock[][], currentHomeblock: Homeblock) {
  const previousRow = accumulator[accumulator.length - 1];

  if (
    previousRow?.length === 1 &&
    previousRow[0].row.value === currentHomeblock.row.value &&
    currentHomeblock?.column?.value &&
    (currentHomeblock.column.value[0].codename === 'left' || currentHomeblock.column.value[0].codename === 'right')
  ) {
    if (previousRow[0].column.value[0].codename === 'left' && currentHomeblock.column.value[0].codename === 'right') {
      previousRow.push(currentHomeblock);
      return accumulator;
    }

    if (previousRow[0].column.value[0].codename === 'right' && currentHomeblock.column.value[0].codename === 'left') {
      previousRow.unshift(currentHomeblock);
      return accumulator;
    }
  }

  if (accumulator.length === 9) {
    return accumulator;
  }

  accumulator.push([currentHomeblock]);

  return accumulator;
}

export function ToJsxElements(currentRow: Homeblock[], index: number) {
  if (currentRow.length === 2) {
    return (
      <Row key={index + Date.now()}>
        <Col xs={12} md={6} className="homeblock-right-padding">
          <HomeBlockItem homeBlock={currentRow[0]} size={BlockSize.Half} />
        </Col>
        <Col xs={12} md={6} className="homeblock-left-padding">
          <HomeBlockItem homeBlock={currentRow[1]} size={BlockSize.Half} />
        </Col>
      </Row>
    );
  } else {
    return (
      <div key={index + Date.now()}>
        <HomeBlockItem homeBlock={currentRow[0]} size={BlockSize.Full} />
      </div>
    );
  }
}
