import React, { ChangeEvent, useEffect } from 'react';
import { ServiceOpportunitiesFlat } from '../../Resources//ServiceOpportunities/Model/service_opportunities_flat';
import withContext from '../../ContextAPI/Context_HOC';
import ContentProviderEndpoint from '../../Content/ContentProviderEndpoint';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Link, withRouter} from 'react-router-dom';
import DisplayResourcesList from '../../Resources//DisplayResourcesList';
import ServiceOpsRichTextResolver from '../../Resources//Utilities/ServiceOpsRichTextResolver';
import ServiceOpsFlatter from '../../Resources/Utilities/ServiceOpsFlatter';
import { sortBy } from 'lodash';
import { ServiceOpportunities } from '../../Kentico/DigitalDesign/service_opportunities';
import {withStyles} from '@material-ui/core';
import {Col, Row} from 'react-flexbox-grid';

const styles = () => ({
  '@global': {
    '#root': {
      background: 'radial-gradient(ellipse at center 32%, #0a5b55, #01262C)',
    },
    '#content': {
      background: 'transparent',
    },
    'span.MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
      color: 'red',
    },
    'span.MuiFormControlLabel-asterisk': {
      color: 'red',
    },
    'MuiFormControlLabel-asterisk': {
      color: 'red',
    },
  },
});

export interface JobListingProps {
  context: {
    digitalDesignContent: ContentProviderEndpoint;
  };
}
export function JobListing(props: JobListingProps) {
  const [value, setValue] = React.useState<Number>(0);
  const [displayErrorMessage, setDisplayErrorMessage] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [jobListingList, setJobListingList] = React.useState<ServiceOpportunitiesFlat[]>([]);
  const contentProvider = props.context.digitalDesignContent;

  useEffect(() => {
    const subscription = contentProvider
      .items<ServiceOpportunities>({
        draftContent: '/api/content/public_preview',
        content: '/api/content/public',
      })
      .type('service_opportunities')
      .containsFilter('elements.service_opportunities_taxonomy', ['united_states'])
      .elementsParameter([
        'base__title',
        'base__body',
        'base__datepublished',
        'department',
        'location',
        'document_download',
        'position_type',
      ])
      .toObservable()
      .subscribe(
        {
          next:(response) => {
            setIsLoading(false);
            setDisplayErrorMessage(false);
            if (!response.isEmpty) {
              const itemsResolved = ServiceOpsRichTextResolver(response.items);
              const itemsResolvedFlattened = ServiceOpsFlatter(itemsResolved);
              const itemsResolvedFlattenedSorted = sortBy(
                itemsResolvedFlattened,
                'isSummerPosition',
                'department.value',
                'base__title.value'
              );
              setJobListingList(itemsResolvedFlattenedSorted);
            }
          },
          error:()=>{
            setDisplayErrorMessage(true);
            setIsLoading(false);
          }
        });
    return () => subscription.unsubscribe();
  }, [contentProvider]);

  const handleChange = (event: ChangeEvent<{}>, newValue: Number) => {
    setValue(newValue);
  };

  return (
    <>

      <div>
        <div className="center">
          <div data-cy="logo" className="starlogo star-margin" />
          <h2 className="logo-text">Bahá’ís of the United States</h2>
        </div>
        <div className="w-100">
          <Row around="lg" className="faded-background-large wider">
            <Col xs={12}  className="form-style wider w-100">

              <h1 data-cy={'job-listing-title'}>Job Listing</h1>
              <Paper>
                <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
                  <Tab label="United States" component={Link} data-cy="job-listing" to="./job-listing" />
                </Tabs>
              </Paper>
              <br/>
              {isLoading && <h2 data-cy="job-listing-no-jobs"> Loading...</h2>}
              {jobListingList.length == 0 && !isLoading  && <h2 data-cy="job-listing-no-jobs"> No Jobs Found</h2>}
              {displayErrorMessage && <h2 data-cy="job-listing-error-message">Error fetching jobs</h2>}
              <div className="text-justify">
                {DisplayResourcesList({ dataArray: jobListingList, type: 'Job' })}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default withRouter(withContext(withStyles(styles, { withTheme: true })(JobListing)));
