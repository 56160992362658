import React, { Component } from 'react';
import { Link, Switch } from 'react-router-dom';
import BoundariedRoute from '../Error/BoundariedRoute';
import PrayerDisplay from './PrayerDisplay';
import withContext from '../ContextAPI/Context_HOC';

class SecondLevelLinkList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calldata: [],
      linkdisplay: [],
      wrongPath: false,
    };
  }
  componentDidMount() {
    this.subscription = this.props.context.prayerContent
      .items()
      .type('prayer')
      .containsFilter('elements.prayer_category', [this.props.secondtaxonomycodename])
      .toObservable()
      .subscribe(async (response) => {
        if (!response.isEmpty) {
          let links = [];
          response.items.forEach((x, key) => {
            links.push(
              <li key={key} data-cy="prayerlist">
                <Link
                  data-cy={`prayerlink${key}`}
                  to={{
                    pathname: `/prayer/${this.props.firsttaxonomycodename}/${this.props.secondtaxonomycodename}/${x.url_slug.value}`,
                  }}
                >
                  {x.base__title.value}
                </Link>
              </li>
            );
          });
          this.setState({ linkdisplay: links });
        } else {
          this.setState({ wrongPath: true });
        }
      });
  }
  //I have didupdate lifecyle so that the page update whenever a user clicks between sub menus
  componentDidUpdate(prevProps) {
    if (prevProps.secondtaxonomycodename !== this.props.secondtaxonomycodename) {
      this.subscription = this.props.context.prayerContent
        .items()
        .type('prayer')
        .containsFilter('elements.prayer_category', [this.props.secondtaxonomycodename])
        .toObservable()
        .subscribe(async (response) => {
          if (!response.isEmpty) {
            let links = [];
            response.items.forEach((x, key) => {
              links.push(
                <li key={key} data-cy="prayerlist">
                  <Link
                    data-cy={`prayerlink${key}`}
                    to={{
                      pathname: `/prayer/${this.props.firsttaxonomycodename}/${this.props.secondtaxonomycodename}/${x.url_slug.value}`,
                    }}
                  >
                    {x.base__title.value}
                  </Link>
                </li>
              );
            });
            this.setState({ linkdisplay: links });
          } else {
            this.setState({ wrongPath: true });
          }
        });
    }
  }
  componentWillUnmount() {
    this.subscription.unsubscribe();
  }
  render() {
    let prayertype = this.props.secondtaxonomycodename.split('_');
    let newarr = [];
    for (var i = 0; i < prayertype.length; i++) {
      if (prayertype[i] !== '') newarr.push(prayertype[i].replace(prayertype[i][0], prayertype[i][0].toUpperCase()));
    }
    newarr = newarr.join(' ');
    return (
      <div>
        <Switch>
          <BoundariedRoute
            exact
            path={`/prayer/:firstlevelid/:seclevelid`}
            render={() => (
              <div>
                <h1 data-cy="title">{newarr}</h1>
                <ul className="plain-list">{this.state.linkdisplay}</ul>
              </div>
            )}
          />
          <BoundariedRoute
            path={`/prayer/:firstlevelid/:seclevelid/:urlslugid`}
            render={(props) => <PrayerDisplay {...props} />}
          />
        </Switch>
      </div>
    );
  }
}
export default withContext(SecondLevelLinkList);
