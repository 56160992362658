import React, { useEffect } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import withContext from '../../ContextAPI/Context_HOC';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
import ServiceOpsRichTextResolver from '../Utilities/ServiceOpsRichTextResolver';

function WorldCenter(props) {
  const [value, setValue] = React.useState(2);
  const [serviceOpsList, setServiceOpsList] = React.useState([]);
  const contentProvider = props.context.digitalDesignContent;

  useEffect(() => {
    const subscription = contentProvider
      .items()
      .type('service_opportunities')
      .containsFilter('elements.service_opportunities_taxonomy', ['world_center'])
      .elementsParameter(['base__title', 'base__body', 'base__datepublished', 'department', 'location', 'document_download'])
      .orderParameter('elements.base__datepublished', 'desc')
      .toObservable()
      .subscribe(async (response) => {
        if (!response.isEmpty) {
          setServiceOpsList(ServiceOpsRichTextResolver(response.items));
        }
      });
    return () => subscription.unsubscribe();
  }, [contentProvider]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <h1>World Center Service Opportunities</h1>
      <Paper>
        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
          <Tab label="United States" component={Link} data-cy="us-opportunities" to="/resources/service-opportunities" />
          <Tab label="International" component={Link} data-cy="international-opportunities" to="/resources/international" />
          <Tab label="World Center" component={Link} data-cy="world-center-opportunities" to="/resources/world-center" />
        </Tabs>
      </Paper>
      <br />
      {serviceOpsList.map((job, key) => (
        <div key={key}>
          <Row>
            <Col sm={2}>
              <h3>{job.base__title.value} </h3>
            </Col>
            <Col sm={9}>
              <div>{job.base__body.value}</div>
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );
}

export default withContext(WorldCenter);
