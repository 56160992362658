import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import withContext from '../ContextAPI/Context_HOC';
import { withStyles, InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';
import getRegionClusterDirectory from '../Directories/Utilities/getRegionClusterDirectory';
import getClusterDirectory from '../Directories/Utilities/getClusterDirectory';
import SafeStringify from '../Utilities/SafeStringify';

const styles = (theme) => ({
  clusterMapContainer: {
    marginBottom: '20px',
    '& img': {
      maxWidth: '100%',
    },
  },
  messages: {
    marginTop: '20px',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '10px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

class California extends React.Component {
  constructor(props) {
    super(props);
    this.auth = this.props.context.authService;
    this.state = {
      token: this.auth.getToken(),
      rdata: [],
      cdata: [],
      regionData: [],
      clusterData: [],
      regClusterErr: '',
      clusterOptionList: [],
      clusterSelectValue: '',
      clusterDirErr: '',
      subRegionName: '',

      croles: [],
      clusterMapImageSource: '',
      localitiesText: '',
      atcSecretaries: [],
      atcMembers: [],
      studyCircleCoordinators: [],
      juniorYouthCoordinators: [],
      childrensClassCoordinators: [],
      abmsForPropagation: [],
      abmsForProtection: [],
      otherContacts: [],
      showRbcInfo: false,
      openClusterDropDown: true,
    };

    this.subregionOnChange = this.subregionOnChange.bind(this);
    this.clusterOnChange = this.clusterOnChange.bind(this);
    this.makeRoles = this.makeRoles.bind(this);
    this.makeLocalities = this.makeLocalities.bind(this);
  }

  subregionOnChange(event) {
    const regionName = 'California';
    const subregionCode = event.target.value;
    const clusterCodePrefix = 'CA-' + subregionCode;
    const thisClass = this;
    getRegionClusterDirectory(this.state.token, regionName)
      .then(function (response) {
        let clusterOptionList = response.data
          .filter(function (cluster) {
            return cluster.clusterCode.startsWith(clusterCodePrefix);
          })
          .map(function (cluster, index) {
            return (
              <MenuItem data-cy={`cluster${index}`} key={cluster.clusterCode} value={cluster.clusterCode}>
                {cluster.clusterName}
              </MenuItem>
            );
          });
        thisClass.setState({
          subRegionName: event.target.value,
          clusterOptionList: clusterOptionList,
          clusterSelectValue: '',
          showRbcInfo: false,
          openClusterDropDown: false,
        });
      })
      .catch((err) => {
        thisClass.setState({
          clusterErr: <p className="error-message">{err.message ? SafeStringify(err.message) : null}</p>,
          clusterSelectValue: '',
          showRbcInfo: false,
        });
      });
  }

  clusterOnChange(event) {
    var clusterId = event.target.value;
    var clusterMapImageSource = this.buildClusterMapImageSource(clusterId);
    this.setState(
      {
        clusterSelectValue: clusterId,
        clusterMapImageSource: clusterMapImageSource,
        showRbcInfo: false,
      },
      function () {
        const thisClass = this;
        getClusterDirectory(this.state.token, clusterId)
          .then(function (response) {
            thisClass.makeRoles(response.data.clusterRoles);
            thisClass.makeLocalities(response.data.clusterLocalities);
          })
          .catch((err) => {
            this.setState({
              clusterDirErr: <p className="error-message">{err.message ? SafeStringify(err.message) : null}</p>,
            });
          });
      }
    );
  }

  makeRoles(clusterRoles) {
    var atcSecretaries = [];
    var atcMembers = [];
    var studyCircleCoordinators = [];
    var juniorYouthCoordinators = [];
    var childrensClassCoordinators = [];
    var abmsForPropagation = [];
    var abmsForProtection = [];
    var otherContacts = [];
    const thisClass = this;

    clusterRoles.forEach(function (role) {
      switch (role.position) {
        case 'Area Teaching Committee Secretary':
          atcSecretaries.push(thisClass.displayRole(role));
          break;
        case 'Area Teaching Committee Member':
          atcMembers.push(thisClass.displayRole(role));
          break;
        case 'Study Circle Coordinator':
          studyCircleCoordinators.push(thisClass.displayRole(role));
          break;
        case 'Junior Youth Coordinator':
          juniorYouthCoordinators.push(thisClass.displayRole(role));
          break;
        case 'Children’s Class Coordinator':
          childrensClassCoordinators.push(thisClass.displayRole(role));
          break;
        case 'Auxiliary Board Member for Propagation':
          abmsForPropagation.push(thisClass.displayRole(role));
          break;
        case 'Auxiliary Board Member for Protection':
          abmsForProtection.push(thisClass.displayRole(role));
          break;
        default:
          otherContacts.push(thisClass.displayRole(role));
      }
    });

    this.setState({
      atcSecretaries: atcSecretaries,
      atcMembers: atcMembers,
      studyCircleCoordinators: studyCircleCoordinators,
      juniorYouthCoordinators: juniorYouthCoordinators,
      childrensClassCoordinators: childrensClassCoordinators,
      abmsForPropagation: abmsForPropagation,
      abmsForProtection: abmsForProtection,
      otherContacts: otherContacts,
      showRbcInfo: true,
    });
  }

  displayRole(role) {
    if (role.position !== 'Homefront Pioneer') {
      const emailSection = role.email ? (
        <span>
          <br />
          <a href={'mailto:' + role.email}>{role.email}</a>
        </span>
      ) : (
        <span />
      );
      const phoneSection = role.phone ? (
        <span>
          <br />
          <a href={'tel:' + role.phone}>{role.phone}</a>
        </span>
      ) : (
        <span />
      );
      return (
        <span key={role.firstName + role.lastName + role.position}>
          <p>
            {role.firstName} {role.lastName}
            <br />
            {role.position}
            {emailSection}
            {phoneSection}
          </p>
        </span>
      );
    }
  }
  makeLocalities(clusterLocalities) {
    const localitiesText = clusterLocalities
      .map(function (locality) {
        return locality.name;
      })
      .join(', ');
    this.setState({
      localitiesText: localitiesText,
    });
  }

  buildClusterMapImageSource(clusterId) {
    var clusterIdAlphasOnly = clusterId.replace(/\s|-/g, '');
    var imageSource = 'https://s3.amazonaws.com/bahai-data-api/maps/cluster/' + clusterIdAlphasOnly + '.jpg';
    return imageSource;
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <h2>Regional Bahá’í Council of California</h2>
        <p>
          <em>Note: First select the Subregion then select its related cluster.</em>
        </p>
        <Row>
          <Col sm={12} md={6}>
            <form className={classes.root} id="select-form" autoComplete="off">
              <FormControl variant="outlined" className="large-dropdown">
                <InputLabel htmlFor="region-simple" id="subregion">
                  Subregion
                </InputLabel>
                <Select
                  data-cy="subregion"
                  labelId="subregion"
                  label="Subregion"
                  value={this.state.subRegionName}
                  onChange={this.subregionOnChange}
                  inputProps={{
                    name: 'Please select a subregion',
                    id: 'region-simple',
                  }}
                >
                  <MenuItem data-cy="firstSub" key={1} value="NC">
                    Northern Coastal
                  </MenuItem>
                  <MenuItem key={2} value="NI">
                    Northern Inland
                  </MenuItem>
                  <MenuItem key={3} value="SE">
                    Southeast
                  </MenuItem>
                  <MenuItem key={4} value="SW">
                    Southwest
                  </MenuItem>
                </Select>
              </FormControl>
            </form>
          </Col>
          <Col sm={12} md={6}>
            <div id="cluster-select-area">
              {this.state.regClusterErr}
              <FormControl variant="outlined" className="large-dropdown">
                <InputLabel htmlFor="region-simple" id="clusters">
                  Clusters
                </InputLabel>
                <Select
                  data-cy="clusterSelect"
                  labelId="clusters"
                  label="Clusters"
                  value={this.state.clusterSelectValue}
                  onChange={this.clusterOnChange}
                  native={this.state.openClusterDropDown}
                  inputProps={{
                    name: 'Please Select a Cluster',
                    id: 'region-simple',
                  }}
                >
                  {this.state.clusterOptionList}
                </Select>
              </FormControl>
            </div>
          </Col>
        </Row>
        <br />
        {this.state.clusterDirErr}
        <Row id="loading" />
        <Row id="rbcInfo" style={{ display: this.state.showRbcInfo ? null : 'none' }}>
          <Col sm={12} md={6}>
            <div className={classes.clusterMapContainer}>
              <img src={this.state.clusterMapImageSource} alt="Map of cluster" id="map-image" />
            </div>
          </Col>
          <Col sm={12} md={6} className="contacts">
            <h5>Localities</h5>
            <Row>
              <Col sm={12} md={6}>
                <div data-cy="locality-list" id="locality-list">
                  <p>{this.state.localitiesText}</p>
                </div>
              </Col>
            </Row>
            <hr />
            <h5>Area Teaching Committee</h5>
            <Row>
              <Col sm={12} md={6}>
                <div data-cy="secretary" id="secretary">
                  {this.state.atcSecretaries}
                </div>
                <div data-cy="member" id="member">
                  {this.state.atcMembers}
                </div>
              </Col>
            </Row>
            <hr />
            <h5>Training Institute</h5>
            <Row>
              <Col sm={12} md={6}>
                <div data-cy="sc-coordinator" id="sc-coordinator">
                  {this.state.studyCircleCoordinators}
                </div>
                <div data-cy="cc-coordinator" id="cc-coordinator">
                  {this.state.childrensClassCoordinators}
                </div>
              </Col>
              <Col sm={12} md={6}>
                <div data-cy="jy-coordinator" id="jy-coordinator">
                  {this.state.juniorYouthCoordinators}
                </div>
              </Col>
            </Row>
            <hr />
            <h5>Auxiliary Board</h5>
            <Row>
              <Col sm={12} md={6}>
                <div data-cy="protection" id="protection">
                  {this.state.abmsForProtection}
                </div>
              </Col>
              <Col sm={12} md={6}>
                <div data-cy="propagation" id="propagation">
                  {this.state.abmsForPropagation}
                </div>
              </Col>
            </Row>
            <hr />
            <h5>Other</h5>
            <Row>
              <Col sm={12} md={6}>
                <div data-cy="other" id="other">
                  {this.state.otherContacts}
                </div>
              </Col>
            </Row>
            {
              <span>
                <p data-cy="homefrontpioneer">
                  Names and contact information for Homefront pioneers in each cluster can be obtained from your Area Teaching
                  Committee or the California Regional Council.
                </p>
              </span>
            }
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={12}>
            <ul>
              <li>
                <a href="https://bahai-messages.s3.amazonaws.com/California/Letter-to-Community-February-2022.pdf">
                  Letter to Community February 2022
                </a>
              </li>
              <li>
                <a href="https://s3.amazonaws.com/bahai-messages/California/Letter_to_Region_October_2021.pdf">
                  Letter to Region October 2021
                </a>
              </li>
              <li>
                <a href="https://s3.amazonaws.com/bahai-messages/California/CALI-council-bicentennial-letter.pdf">
                  Council Bicentenial Letter
                </a>
              </li>
              <li>
                <a href="https://s3.amazonaws.com/bahai-messages/California/CALI-council-letter-to-unit-conventions.pdf">
                  Council Letter to Unit Convention October 2017
                </a>
              </li>
              <li>
                <a href="https://s3.amazonaws.com/bahai-messages/California/CALI-council-letter-to-communities-nov17.pdf">
                  Council Letter to Communities November 2017
                </a>
              </li>
              <li>
                <a href="https://s3.amazonaws.com/bahai-messages/California/CALI-council-regional-fund-annual-letter-2021.pdf">
                  Council Annual Fund Letter 2021
                </a>
              </li>
              <li>
                <a href="https://bahai-messages.s3.amazonaws.com/California/California-Region-Cluster-Agencies-August-2022.pdf">
                  California Region Cluster Agencies
                </a>{' '}
                - <em>updated August 2022</em>
              </li>
              <li>
                <a href="https://s3.amazonaws.com/bahai-messages/California/CALI-council-letter-to-community-may18.pdf">
                  Council Letter to Communities May 2018
                </a>
              </li>
              <li>
                <a href="https://s3.amazonaws.com/bahai-messages/California/CALI-funds-guidance-for-cluster-agencies.pdf">
                  Cluster Funds - Guidance for Cluster Agencies
                </a>
              </li>
              <li>
                <a href="https://s3.amazonaws.com/bahai-messages/California/Letter_to_Community-July18.pdf">
                  Council Letter to the Community July 2018
                </a>
              </li>
              <li>
                <a href="https://s3.amazonaws.com/bahai-messages/California/California-Council-Regional-Fund-Annual-Letter-2018.pdf">
                  Regional Fund Annual Letter 2018
                </a>
              </li>
              <li>
                <a href="https://s3.amazonaws.com/bahai-messages/California/Process-of-Growth-in-California-Region.pdf">
                  Process of Growth in California February 2019
                </a>
              </li>
              <li>
                <a href="https://s3.amazonaws.com/bahai-messages/California/Teaching-Conferences-2019.pdf">
                  Teaching Conferences 2019
                </a>
              </li>
              <li>
                <a href="https://bahai-messages.s3.amazonaws.com/California/2023-2024+-+Q3+-+Update+on+Contributions+to+the+Regional+Fund.pdf">
                  Update on Contributions to the Regional Fund - Q3 2024
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    );
  }
}
export default withContext(withStyles(styles, { withTheme: true })(California));
