import React from 'react';
import parse from 'html-react-parser';
import withContext from '../ContextAPI/Context_HOC';
import { NavLink } from 'react-router-dom';
import HtmlLinkReplacer from './Utilities/HtmlLinkReplacer';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import FormatUpdatedDate from './Utilities/FormatUpdatedDate';

const getLastChapterTaxonomy = (chaptersElement) => chaptersElement?.value[chaptersElement.value.length - 1];

const getMatchingTaxonomyTerm = (allTaxonomies, sectionData) => {
  const lastChapterTaxonomy = getLastChapterTaxonomy(sectionData.chapters);
  var chapterTermsLength = allTaxonomies.chapterTerms.length;
  for (let i = 0; i < chapterTermsLength; i++) {
    const chapterTerm = allTaxonomies.chapterTerms[i];
    const sectionTerm = chapterTerm.terms.find((sectionTerm) => {
      return sectionTerm.codename === lastChapterTaxonomy?.codename;
    });
    if (sectionTerm) {
      return sectionTerm;
    }
  }
};

const DisplaySectPage = ({ sectionData, allTaxonomies, linkData, matchingUrl }) => {
  const sectionTaxonomy = getMatchingTaxonomyTerm(allTaxonomies, sectionData);

  const subsectlinks = sectionTaxonomy?.terms.map((term, key) => (
    <li key={key}>
      <NavLink data-cy={`subSectionLink${key}`} to={allTaxonomies.urlsByCodename[term.codename].url}>
        {term.name}
      </NavLink>
      <em className="small-left-margin">{FormatUpdatedDate(allTaxonomies.urlsByCodename[term.codename].revisedDate)}</em>
    </li>
  ));

  const htmlLinkReplacer = new HtmlLinkReplacer(linkData, allTaxonomies.urlsByCodename);
  const options = {
    replace: (attribs) => {
      if (attribs.attribs !== undefined && attribs.name === 'a' && linkData.length > 0) {
        return htmlLinkReplacer.TryReplace(attribs);
      }
    },
  };
  sectionData.base__body.resolveHtml();
  const x = parse(sectionData.base__body.resolvedData.html, options);
  return (
    <div>
      <h1 data-cy="title" className="title">
        {sectionData.base__title.value}
      </h1>
      {!x.length ? (
        <p>
          <em>{FormatUpdatedDate(sectionData.base__revised_date.value)}</em>
        </p>
      ) : null}
      <hr />
      <NavLink to={matchingUrl.prevUrl} data-cy="sectionnavprevious">
        <ExpandLess className="large-text" />
      </NavLink>
      <ul className="plain-list">{subsectlinks}</ul>
      {subsectlinks.length > 0 ? <hr /> : null}
      {x.length > 0 ? (
        <p>
          <em>{FormatUpdatedDate(sectionData.base__revised_date.value)}</em>
        </p>
      ) : null}
      <br />
      <div data-cy="body-text">{x}</div>
      <NavLink to={matchingUrl.nextUrl} data-cy="sectionnavnext">
        <ExpandMore className="large-text" />
      </NavLink>
    </div>
  );
};

export default withContext(DisplaySectPage);
