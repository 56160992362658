import React, { useEffect, useState } from 'react';
import FAQs from './FAQs';
import NoMatch from '../Routers/NoMatch';
import withContext from '../ContextAPI/Context_HOC';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CircularProgress } from '@material-ui/core';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';
import { ContentProjectEnum } from '../Content/ContentResolver';

const DisplayFaq = (props) => {
  const [showCircularProgress, setShowCircularProgress] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const context = props.context;
  const contentType = props.contenttype;
  const digitalDesignContent = context.digitalDesignContent;
  const glsaContent = context.glsaContent;
  const urlParameter = props.match?.params?.itemid;
  const [faqContentItem, setFaqContentItem] = useState();

  useEffect(() => {
    const subscription = digitalDesignContent
      .items()
      .type(contentType)
      .equalsFilter('elements.url_slug', urlParameter)
      .orderParameter('elements.base__datepublished', 'desc')
      .toObservable()
      .pipe(
        switchMap((digitalDesignResponse) => {
          if (!digitalDesignResponse.isEmpty) {
            digitalDesignResponse.project = ContentProjectEnum.DigitalDesign;
            return of(digitalDesignResponse);
          } else {
            return glsaContent
              .items()
              .type(contentType)
              .equalsFilter('elements.url_slug', urlParameter)
              .orderParameter('elements.base__datepublished', 'desc')
              .toObservable()
              .pipe(
                map((glsaResponse) => {
                  glsaResponse.project = ContentProjectEnum.GLSA;
                  return glsaResponse;
                })
              );
          }
        })
      )
      .subscribe({
        next: (response) => {
          if (!response.isEmpty) {
            const firstItem = response.items[0];
            firstItem.base__body.resolveHtml();
            firstItem.project = response.project;
            setFaqContentItem(firstItem);
          }
          setShowCircularProgress(false);
        },
        error: () => {
          setShowCircularProgress(false);
          setErrorOccurred(true);
        },
      });

    return () => subscription.unsubscribe();
  }, [context, contentType, digitalDesignContent, glsaContent, urlParameter]);

  return (
    <div data-cy="faqpage">
      {errorOccurred ? <ErrorMessageToUser /> : null}
      {showCircularProgress ? (
        <CircularProgress className="loading-animation" size={40} />
      ) : faqContentItem ? (
        <FAQs faqContentItem={faqContentItem} />
      ) : (
        <NoMatch />
      )}
    </div>
  );
};

export default withContext(DisplayFaq);
