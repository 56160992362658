import React, { useState, useEffect } from 'react';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';
import { CircularProgress } from '@material-ui/core';
import withContext from '../ContextAPI/Context_HOC';
import { NavLink, withRouter } from 'react-router-dom';

const UnitConvComp = (props) => {
  const setPageTitle = props.setPageTitle;
  useEffect(() => setPageTitle('Delegate Election Info'), [setPageTitle]);
  const unitConventionProxy = props.context.unitConventionProxy;
  const [showCircularProgress, setShowCircularProgress] = useState(true);
  const [details, setDetails] = useState();
  const [unrecoverableError, setUnrecoverableError] = useState(false);
  const linkToRedirectToOBS = props.history.createHref({
    pathname: '/SingleSignOnRedirect/OBS',
  });

  const displayDetails = (details) => {
    const conventiontime = details
      ? details.conventionBeginDate !== details.conventionEndDate
        ? [
            <li key={1}>
              {' '}
              {details.conventionBeginDate}, {details.conventionBeginTime} - {details.conventionEndDate},{' '}
              {details.conventionEndTime}{' '}
            </li>,
            <li key={4}>Registration starts at: {details.registrationBeginTime}</li>,
          ]
        : [
            <li key={2}>{details.conventionBeginDate}</li>,
            <li key={3}>
              {details.conventionBeginTime} - {details.conventionEndTime}
            </li>,
            <li key={4}>Registration starts at: {details.registrationBeginTime}</li>,
          ]
      : null;
    return (
      <div>
        <p>
          This year’s delegate elections will take place at in-person Unit Convention gatherings. The Online Balloting System
          (OBS) should be available again for casting ballots, both absentee and in person, using your Bahá’í Online Services
          Account.{' '}
          <strong>
            Online voting will open September 10 and close immediately before tallying of votes begins on the date shown below for
            your Unit Convention.
          </strong>
        </p>
        <button className="primary-button small-bottom-margin" onClick={() => window.open(linkToRedirectToOBS)}>
          Access Online Balloting System
        </button>
        <br />
        <h4>Convention Address Information:</h4>
        <ul className="plain-list">
          <li>{details.conventionSiteName}</li>
          {conventiontime}
          {details.conventionStreetAddress1 ? (
            <span>
              <li>{details.conventionStreetAddress1}</li>
              <li>
                {details.conventionCity}, {details.conventionState} {details.conventionZipCode}
              </li>
            </span>
          ) : null}
        </ul>
        <h4>Address for absentee ballots:</h4>
        <ul className="plain-list">
          <li>{details.hostAssemblyName}</li>
          {details.hostAssemblyInCareOf ? (
            <li>
              <strong>In Care Of:</strong> {details.hostAssemblyInCareOf}
            </li>
          ) : null}
          {details.hostAssemblyStreetAddress1 ? (
            <span>
              <li>{details.hostAssemblyStreetAddress1}</li>
              {details.hostAssemblyStreetAddress2 ? <li>{details.hostAssemblyStreetAddress2}</li> : null}
              <li>
                {details.hostAssemblyCity}, {details.hostAssemblyState} {details.hostAssemblyZipCode}
              </li>
            </span>
          ) : null}
        </ul>
        <h4>Convention Coordinator contact information:</h4>
        <ul className="plain-list">
          <li>{details.conventionContactName}</li>
          {details.conventionContactEmail ? (
            <li>
              <strong>Email:</strong> {details.conventionContactEmail}
            </li>
          ) : null}
          {details.conventionContactPhoneNumber ? (
            <li>
              <strong>Phone:</strong> {details.conventionContactPhoneNumber}
            </li>
          ) : null}
        </ul>
        <h4>Hosting Assembly contact information:</h4>
        <ul className="plain-list">
          <li>{details.secretaryName}</li>
          {details.secretaryEmail ? (
            <li>
              <strong>Email:</strong> {details.secretaryEmail}
            </li>
          ) : null}
          {details.secretaryPhoneNumber ? (
            <li>
              <strong>Phone:</strong> {details.secretaryPhoneNumber}
            </li>
          ) : null}
        </ul>
      </div>
    );
  };

  useEffect(() => {
    const subscription = unitConventionProxy.get().subscribe({
      next: (data) => {
        setDetails(data);
        setShowCircularProgress(false);
      },
      error: () => setUnrecoverableError(true),
    });
    return () => subscription.unsubscribe();
  }, [unitConventionProxy, setDetails, setUnrecoverableError, setShowCircularProgress]);

  const euCodeAndName = details ? (
    <p>
      {details.euCode} {details.euName}
    </p>
  ) : null;

  return (
    <div>
      {unrecoverableError ? (
        <ErrorMessageToUser />
      ) : (
        <div data-cy="unit-convention-info">
          {showCircularProgress ? (
            <CircularProgress data-cy="profile-unit-convention-circular-progress" className="loading-animation" size={40} />
          ) : (
            <div>
              {euCodeAndName}
              {details?.returnMessage?.message ? <h4>{details.returnMessage.message}</h4> : displayDetails(details)}
              <br />
              <NavLink className="plain-link" to="/elections/unit-convention">
                Election Resources
              </NavLink>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withRouter(withContext(UnitConvComp));
