import React from 'react';
import ls from 'localstorage-slim';
import { PropertyAccessor } from '../../Shared/Types/PropertyAccessor';
import FeatureKey from '../../FeatureFlag/FeatureKey';

declare var window: any;
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

export const storageKey = 'community_site_create_bosa_state';
const timeToStoreState = 60 * 60; //one hour

export enum Step {
  ZipId = 1,
  AdditionalQuestions,
  SecurityQuestions,
}
export interface CreateBOSState extends PropertyAccessor {
  temporaryCode?: string;
  showCircularProgress: boolean;
  currentStep: Step;
  displayMessage: JSX.Element | string;
  success: boolean;
  bid: string | number;
  zip: string | number;
  dontHaveZipCheck: boolean;

  clusterdata: [];
  clustervalue: [];
  overseasQuestionData: [];

  accountIdn: string | number;
  securityQuestions: [];

  email: string;
  confirmEmail: string;
  password: string;
  confirmPassword: string;
  questionOne: string;
  questionTwo: string;
  questionThree: string;
  questionOneAns: string;
  questionTwoAns: string;
  questionThreeAns: string;
  googleReCaptchaResponse: string;
  bypassRecaptcha: true;
}

class CreateBOSBaseStep extends React.Component<any, any> {
  _mounted = true;
  canceling = false;
  _step: Step;

  constructor(props: any, initState = false) {
    super(props);
    const displayMessage = this.props.context.createBosErrorDisplayMessage ? (
      <p data-cy="preset-error-messsage" className="center error-message">
        {this.props.context.createBosErrorDisplayMessage}
      </p>
    ) : (
      ''
    );
    const bid = this.props.context.createBosPrefillBahaiId ?? '';
    const temporaryCode = this.props.match?.params?.temporaryCode;

    const data = ls.get(storageKey);
    data
      ? (this.state = { ...data, showCircularProgress: false })
      : initState
      ? (this.state = {
          temporaryCode: temporaryCode,
          showCircularProgress: !!temporaryCode,
          currentStep: Step.ZipId,
          displayMessage: displayMessage,
          success: false,
          bid: bid,
          zip: '',
          dontHaveZipCheck: false,

          clusterdata: [],
          clustervalue: [],
          overseasQuestionData: [],

          accountIdn: '',
          securityQuestions: [],

          email: '',
          confirmEmail: '',
          password: '',
          confirmPassword: '',
          questionOne: '',
          questionTwo: '',
          questionThree: '',
          questionOneAns: '',
          questionTwoAns: '',
          questionThreeAns: '',
          googleReCaptchaResponse: '',
          bypassRecaptcha: true,
        } as CreateBOSState)
      : null;

    if (!this.state) {
      this.props.history.goBack();
    }

    this.handleChange = this.handleChange.bind(this);
    this.saveStateToStorage = this.saveStateToStorage.bind(this);
    this.clearStorage = this.clearStorage.bind(this);
    this.goToStep = this.goToStep.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  componentDidMount() {
    this.props.context
      .featureFlagIsEnabledFunction(FeatureKey.RecaptchaEnabledForAuth, this.props.optimizely)
      .then((result: boolean) => {
        if (result) {
          this.saveStateToStorage({
            bypassRecaptcha: false,
          });
        }
      });
  }

  componentWillUnmount() {
    this._mounted = false;
    if (this.props.context.createBosErrorDisplayMessage) {
      this.props.context.setCreateBosErrorDisplayMessage(undefined);
    }
    if (this.props.context.createBosPrefillBahaiId) {
      this.props.context.setCreateBosPrefillBahaiId(undefined);
    }
  }

  handleChange(e: any) {
    const newState = {
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.type === 'text' ? e.target.value?.trim() : e.target.value,
    };
    this.saveStateToStorage(newState, function () {});
  }

  saveStateToStorage(newState: PropertyAccessor, callback?: (() => void) | undefined) {
    const self = this;
    if (this.canceling) return;

    if (this._mounted) {
      this.setState(newState, saveToStorage);
    } else {
      saveToStorage();
    }

    function saveToStorage() {
      ls.set(storageKey, { ...self.state, displayMessage: '' }, { ttl: timeToStoreState }); //time to live in seconds

      if (callback) callback();

      if (newState.currentStep) {
        self.goToStep(newState.currentStep);
      }
    }
  }
  clearStorage() {
    ls.remove(storageKey);
  }
  goToStep(step: number) {
    this.props.history.push(`/createboswizard/step${step}`);
  }
  cancel() {
    this.canceling = true;
    this.clearStorage();

    this.props.history.go(-this._step);
  }
}
export default CreateBOSBaseStep;
