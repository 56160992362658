import React from 'react';
import ZipIdValidationForm from './ZipIdValidationForm';
import ClusterValidationForm from './ClusterValidationForm';
import CreateAccount from '../Utilities/CreateAccount';
import createAppToken from '../Utilities/AppTokenEndpoint';
import submitIdZipcode from '../Utilities/IdZipcodeEndpoint';
import submitCluster from '../Utilities/ClusterEndpoint';
import setupAccount from '../Utilities/SetupAccountEndpoint';
import withContext from '../../ContextAPI/Context_HOC';
import OverseasValidationForm from '../Utilities/OverseasValidationForm';
import OverseasValidationEndpoint from '../Utilities/OverseasValidationEndpoint';
import GetSecurityQuestionsWithTemporaryCodeEndpoint from '../Utilities/GetSecurityQuestionsWithTemporaryCodeEndpoint';
import SafeStringify from '../../Utilities/SafeStringify';
import { withOptimizely } from '@optimizely/react-sdk';
import FeatureKey from '../../FeatureFlag/FeatureKey';
import { CreateBOSState } from '../CreateBOSWizard/CreateBOSBaseStep';

declare var window: any;
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

class CreateBOSForm extends React.Component<any, any> {
  _mounted = true;
  constructor(props: any) {
    super(props);
    const displayMessage = this.props.context.createBosErrorDisplayMessage ? (
      <p data-cy="preset-error-messsage" className="center error-message">
        {this.props.context.createBosErrorDisplayMessage}
      </p>
    ) : (
      ''
    );
    const bid = this.props.context.createBosPrefillBahaiId ?? '';

    const temporaryCode = this.props.match?.params?.temporaryCode;

    this.state = {
      temporaryCode: temporaryCode,
      showCircularProgress: !!temporaryCode,
      currentStep: 1,
      displayMessage: displayMessage,
      success: false,
      bid: bid,
      zip: '',
      dontHaveZipCheck: false,

      clusterdata: [],
      clustervalue: [],
      overseasQuestionData: [],

      accountIdn: '',
      securityQuestions: [],

      email: '',
      confirmEmail: '',
      password: '',
      confirmPassword: '',
      questionOne: '',
      questionTwo: '',
      questionThree: '',
      questionOneAns: '',
      questionTwoAns: '',
      questionThreeAns: '',
      googleReCaptchaResponse: '',
      bypassRecaptcha: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleZipId = this.handleZipId.bind(this);
    this.handleClusterChange = this.handleClusterChange.bind(this);
    this.submitCluster = this.submitCluster.bind(this);
    this.submitOverseasData = this.submitOverseasData.bind(this);
    this.submitSetupAcc = this.submitSetupAcc.bind(this);
    this.handleRecaptcha = this.handleRecaptcha.bind(this);
  }

  componentDidMount() {
    if (this.state.temporaryCode) {
      createAppToken()
        .then((res: any) => {
          GetSecurityQuestionsWithTemporaryCodeEndpoint(res.data, this.state.temporaryCode)
            .then((res) => {
              if (this._mounted) {
                this.setState({
                  showCircularProgress: false,
                  securityQuestions: res.data.securityQuestionList,
                  email: res.data.email,
                  confirmEmail: res.data.email,
                });
              }
            })
            .catch((err) => {
              if (this._mounted) {
                this.setState({
                  showCircularProgress: false,
                  displayMessage: <p className="center error-message">{SafeStringify(err.message)}</p>,
                });
              }
            });
        })
        .catch((err: any) => {
          if (this._mounted) {
            this.setState({
              showCircularProgress: false,
              displayMessage: <p className="center error-message">{err.message ? SafeStringify(err.message) : null}</p>,
            });
          }
        });
    }
    this.props.context
      .featureFlagIsEnabledFunction(FeatureKey.RecaptchaEnabledForAuth, this.props.optimizely)
      .then((result: boolean) => {
        if (result) {
          this.setState({
            bypassRecaptcha: false,
          });
        }
      });
  }

  componentWillUnmount() {
    this._mounted = false;
    if (this.props.context.createBosErrorDisplayMessage) {
      this.props.context.setCreateBosErrorDisplayMessage(undefined);
    }
    if (this.props.context.createBosPrefillBahaiId) {
      this.props.context.setCreateBosPrefillBahaiId(undefined);
    }
  }

  handleRecaptcha(token: string | null) {
    this.setState({
      googleReCaptchaResponse: token || '',
      displayMessage: <></>,
    });
  }

  handleChange(e: any) {
    const newState = {
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.type === 'text' ? e.target.value?.trim() : e.target.value,
    };
    this.setState(newState, function () {});
  }
  handleZipId(e: any) {
    e.preventDefault();
    if (this.state.temporaryCode) {
      this.setState({
        currentStep: this.state.currentStep + 2,
        dontHaveZipCheck: true,
      });
      return;
    }

    const recaptchaCode = process.env.REACT_APP_RECAPTCHA_CODE;
    if (!this.state.bypassRecaptcha && recaptchaCode && !this.state.googleReCaptchaResponse) {
      this.setState({
        processing: false,
        displayMessage: (
          <p className="error-message" data-cy="error-message">
            Please fill Recaptcha to continue.
          </p>
        ),
      });
      return;
    }

    if (this.state.dontHaveZipCheck) {
      this.setState({
        displayMessage: '',
        currentStep: this.state.currentStep + 1,
        dontHaveZipCheck: true,
      });
    } else {
      createAppToken()
        .then((res: any) => {
          this.setState({ displayMessage: '' }, () => {
            submitIdZipcode(res.data, this.state.bid, this.state.zip, this.state.googleReCaptchaResponse)
              .then((res) => {
                this.setState({
                  displayMessage: '',
                  clusterdata: res.data,
                  currentStep: this.state.currentStep + 1,
                });
              })
              .catch((res) => {
                if (res.message === 'Answer additional questions to register for an account.') {
                  this.setState({
                    displayMessage: '',
                    dontHaveZipCheck: true,
                    currentStep: this.state.currentStep + 1,
                  });
                } else {
                  this.setState({
                    displayMessage: <p className="center error-message">{res.message ? SafeStringify(res.message) : null}</p>,
                  });
                }
              });
          });
        })
        .catch((err: any) => {
          this.setState({
            displayMessage: <p className="center error-message">{err.message ? SafeStringify(err.message) : null}</p>,
          });
        });
    }
  }

  handleClusterChange(e: any) {
    this.setState({ [e.target.name]: e.target.value }, function () {});
  }

  submitOverseasData(e: any) {
    e.preventDefault();
    const answers: any[] = [];
    for (let i = -100; i <= 100; ++i) {
      if (this.state[`overseasAnswer_${i}`]) {
        answers.push({
          questionId: i,
          answer: this.state[`overseasAnswer_${i}`],
        });
      }
    }

    const answerCount = answers.length;
    if (answerCount === 0) {
      this.setState({
        displayMessage: (
          <p className="center error-message">{`No questions have been answered. Please answer at least ${this.state.overseasRequiredCorrectAnswerCount} questions`}</p>
        ),
      });
      return;
    }
    if (answerCount < this.state.overseasRequiredCorrectAnswerCount) {
      if (answerCount === 1) {
        this.setState({
          displayMessage: (
            <p className="center error-message">{`Only 1 question has been answered. Please answer at least ${this.state.overseasRequiredCorrectAnswerCount} questions`}</p>
          ),
        });
      } else {
        this.setState({
          displayMessage: (
            <p className="center error-message">{`Only ${answerCount} questions have been answered. Please answer at least ${this.state.overseasRequiredCorrectAnswerCount} questions`}</p>
          ),
        });
      }
      return;
    }

    this.setState({ showCircularProgress: true }, () =>
      createAppToken()
        .then((res: any) => {
          OverseasValidationEndpoint(res.data, this.state.bid, answers)
            .then(async (res) => {
              this.setState({
                showCircularProgress: false,
                displayMessage: '',
                accountIdn: res.data.accountIdentifier,
                securityQuestions: res.data.securityQuestionList,
                currentStep: this.state.currentStep + 1,
              });
            })
            .catch((err) => {
              this.setState({
                showCircularProgress: false,
                displayMessage: <p className="center error-message">{err.message ? SafeStringify(err.message) : null}</p>,
              });
            });
        })
        .catch((err: any) => {
          this.setState({
            showCircularProgress: false,
            displayMessage: <p className="center error-message">{err.message ? SafeStringify(err.message) : null}</p>,
          });
        })
    );
  }

  submitCluster(e: any) {
    e.preventDefault();

    if (!this.state.clustervalue || !this.state.clustervalue.length) {
      this.setState({
        displayMessage: <p className="center error-message">No cluster was selected</p>,
      });
      return;
    }

    createAppToken()
      .then((res: any) => {
        submitCluster(res.data, this.state.bid, this.state.zip, this.state.clustervalue)
          .then(async (res) => {
            this.setState({
              displayMessage: '',
              accountIdn: res.data.accountIdentifier,
              securityQuestions: res.data.securityQuestionList,
              currentStep: this.state.currentStep + 1,
            });
          })
          .catch((err) => {
            this.setState({
              displayMessage: <p className="center error-message">{err.message ? SafeStringify(err.message) : null}</p>,
            });
          });
      })
      .catch((err: any) => {
        this.setState({
          displayMessage: <p className="center error-message">{err.message ? SafeStringify(err.message) : null}</p>,
        });
      });
  }

  submitSetupAcc(e: any) {
    e.preventDefault();
    let userinput = {
      email: this.state.email,
      confirmEmail: this.state.confirmEmail,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      question1Id: this.state.questionOne,
      question1Answer: this.state.questionOneAns,
      question2Id: this.state.questionTwo,
      question2Answer: this.state.questionTwoAns,
      question3Id: this.state.questionThree,
      question3Answer: this.state.questionThreeAns,
    };

    createAppToken()
      .then((res: any) => {
        setupAccount(res.data, this.state.bid, this.state.accountIdn, userinput, this.state.temporaryCode)
          .then(async (res) => {
            this.setState({
              displayMessage: (
                <p className="center success-message">
                  {res.data?.message ? SafeStringify(res.data.message) : SafeStringify(res.data)}
                </p>
              ),
              success: true,
            });
          })
          .catch((err) => {
            this.setState({
              displayMessage: <p className="center error-message">{err.message ? SafeStringify(err.message) : null}</p>,
            });
          });
      })
      .catch((err: any) => {
        this.setState({
          displayMessage: <p className="center error-message">{err.message ? SafeStringify(err.message) : null}</p>,
        });
      });
  }

  render() {
    switch (this.state.currentStep) {
      case 1:
      default:
        return (
          <div className="center">
            <div data-cy="logo" className="starlogo star-margin" />
            <h2 data-cy="logotext" className="logo-text">
              Bahá’ís of the United States
            </h2>
            <ZipIdValidationForm
              BIDstate={this.state.bid}
              Zipstate={this.state.zip}
              dontHaveZipCheck={this.state.dontHaveZipCheck}
              handleChange={this.handleChange}
              handleZipIdForm={this.handleZipId}
              handleRecaptcha={this.props.bypassRecaptcha ? undefined : this.handleRecaptcha}
              displayMessage={this.state.displayMessage}
              onlyListBahaiIdField={!!this.state.temporaryCode}
              showCircularProgress={this.state.showCircularProgress}
            />
          </div>
        );
      case 2:
        if (this.state.dontHaveZipCheck) {
          return (
            <div className="center">
              <div data-cy="logo" className="starlogo star-margin" />
              <h2 data-cy="logotext" className="logo-text">
                Bahá’ís of the United States
              </h2>
              <OverseasValidationForm
                model={this.state as CreateBOSState}
                title="Create an Account"
                handleSubmit={this.submitOverseasData}
                displayMessage={this.state.displayMessage}
                handleChange={this.handleChange}
                createAppTokenPromise={createAppToken}
                showCircularProgress={this.state.showCircularProgress}
              />
            </div>
          );
        } else {
          return (
            <div className="center">
              <div data-cy="logo" className="starlogo star-margin" />
              <h2 data-cy="logotext" className="logo-text">
                Bahá’ís of the United States
              </h2>
              <ClusterValidationForm
                handleChange={this.handleChange}
                name="clustervalue"
                selectvalue={this.state.clustervalue}
                clusterdata={this.state.clusterdata}
                handleSubmit={this.submitCluster}
                displayMessage={this.state.displayMessage}
              />
            </div>
          );
        }
      case 3:
        return (
          <div className="center">
            <div data-cy="logo" className="starlogo star-margin" />
            <h2 data-cy="logotext" className="logo-text">
              Bahá’ís of the United States
            </h2>
            <CreateAccount
              displayMessage={this.state.displayMessage}
              success={this.state.success}
              questiondata={this.state.securityQuestions}
              handleChange={this.handleChange}
              handleSubmit={this.submitSetupAcc}
              emailValue={this.state.email}
              emailname="email"
              confirmEmailValue={this.state.confirmEmail}
              confirmEmailname="confirmEmail"
              passwordValue={this.state.password}
              passwordname="password"
              confirmPasswordValue={this.state.confirmPassword}
              confirmPasswordname="confirmPassword"
              questionOneValue={this.state.questionOne}
              questionOneName={'questionOne'}
              questionTwoValue={this.state.questionTwo}
              questionTwoName={'questionTwo'}
              questionThreeValue={this.state.questionThree}
              questionThreeName={'questionThree'}
              questionOneAns={this.state.questionOneAns}
              questionOneAnsName={'questionOneAns'}
              questionTwoAns={this.state.questionTwoAns}
              questionTwoAnsName={'questionTwoAns'}
              questionThreeAns={this.state.questionThreeAns}
              questionThreeAnsName={'questionThreeAns'}
            />
          </div>
        );
      case 4:
        return <div className="center">{this.state.displayMessage}</div>;
    }
  }
}
export default withContext(withOptimizely(CreateBOSForm));
