import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import FlickrGallery from './FlickrGallery';
import NatConDropDown from './NatConDropDown';
import { Link } from 'react-router-dom';

class NatCon2019 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenId: null,
    };
    this.display = {
      election: (
        <div>
          <p>
            As the newly elected National Assembly was formally introduced to the delegates Saturday evening, Bowers assured the
            U.S. community that the Assembly prays for it at every chance under the dome of the holiest House of Worship, and
            asked for the community’s prayers in return. He assured the delegates that their consultation at the gathering had
            already been “the most focused, the most substantive, the most helpful insights in any Convention that we’ve
            experienced so far.” And he expressed the Assembly’s trust that despite any limitations the members may feel in their
            capacities, “Bahá’u’lláh … will show us the way.”
          </p>
        </div>
      ),

      summoningFaith: (
        <div>
          <p>
            “Spiritual forces have been released to help foster the transformation of our souls, so that we can carry out our
            single most important task any human being can carry out,” said Counselor Mark Sisson — the movement of populations
            toward the teachings of Bahá’u’lláh.
          </p>
          <p>
            Virtually all the hundreds of Convention attendees stood when the Counselor called for recognition of people who are
            helping serve the Cause in a variety of roles: whether as a tutor, children’s class teacher, junior youth or youth
            facilitator; a Spiritual Assembly, Area Teaching Committee or Regional Council member; training institute board member
            or coordinator; a teacher or promoter of the Cause, or even “a quickener of mankind.”
          </p>
          <p>“Look at yourselves. This represents merely the tip of the iceberg,” he said.</p>
          <p>
            One formidable challenge identified by the Universal House of Justice for this “band of believers committed to the
            service of Bahá’u’lláh” in this Five Year Plan, he said, is to train a “sustained focus on nurturing growth and
            building capacity” and develop the “discipline to reflect on action and learn from experience” so that we can raise up
            and accompany “an expanding nucleus of individuals in each place.”
          </p>
          <p>
            All this, he noted, in a world where a process of disintegration of society is proceeding at the same time as we work
            to build a new order, bit by bit.
          </p>
          <p>
            It helps that the most urgent task of homefront pioneering been made clear by the Universal House of Justice. Sisson
            said, “Go into those communities and call upon the friends and families that live there to help you help them, help
            one another, to build a world community. Awakening them, as has been awakened in you, a desire to transform the world,
            and know that the means to do it is in our hands. All we have to do is apply it.”{' '}
          </p>
          <p>
            As we intensify our efforts together, we are reminded to be focused, disciplined, loving, patient and kind with each
            other.{' '}
          </p>
          <p>
            And it is vital to call on the force of faith, reminiscent of the Letters of the Living in the time of the Báb and the
            early North American believers whom ‘Abdu’l-Bahá asked to increase their efforts a thousandfold.
          </p>
          <p>
            “Even though we feel that we’ve given all that we have, we know that by virtue of the fact that Bahá’u’lláh is calling
            upon us to do more, then there is more. There is more in us. We will discover it when we arise. It’s not important
            that we know absolutely how everything is going to turn out. It is necessary for us to have faith,” he said.
          </p>
          <p>
            In her turn, Counselor Farah Guchani-Rosenberg also praised the atmosphere of the “beautiful four days” of the
            Convention, and the preparation that fostered it — a sign in itself, she said, of “maturity and building capacity.”{' '}
          </p>
          <p>
            In fact, the thoughtful and deep quality of delegates’ discourse throughout the gathering showed that “our capacity to
            reflect, as a Bahá’í community, clearly has advanced” — not just in national spaces, but at every level from families
            and neighborhoods to clusters, groupings, and regional spaces.{' '}
          </p>
          <p>
            But regardless of what reflection spaces any of us may take advantage of, she indicated, we all have access to a
            number of tools for raising our capacities to carry out the Five Year Plan.
          </p>
          <p>
            “We all know that our identity as a Bahá’í is to teach,” she said. “Teaching is not a thing we do or an activity that
            we count. This is what makes us Bahá’í. … Now, more than ever before, we have so much to work with.”
          </p>
          <p>
            For instance, even after we finish institute courses, it can be incredibly useful to keep referring to the content of
            the Ruhi books. “There are so many conversations around each one of these courses,” depending on seekers’ needs, she
            said. Many people may be attracted to ideas on the life of the spirit from Book 1; parents can appreciate thoughts on
            building children’s character from Book 3, Teaching Children’s Classes, Grade 1; and young people often show interest
            in the themes of book 5, Releasing the Powers of Junior Youth.{' '}
          </p>
          <p>
            Two films recently released by the Bahá’í World Center are proving powerful as well. Light to the World, followed by
            discussion with Bahá’ís, has helped people in several regions move “totally to another level” from inquiry to
            confirmed belief. After viewing the newer film A Widening Embrace, the friends might want to “take just parts of it
            and incorporate into a theme” for conversation.
          </p>
          <p>
            And one tool the Counselors have been asked particularly to emphasize, she said, is The Bahá’ís, the newly revised
            magazine-style introduction to the Faith.
          </p>
          <p>
            “This magazine … is designed in such a way that you can utilize every part of it,” she said. Its language offers a
            potent way to describe not only the fundamental teachings of Bahá’u’lláh, but also what Bahá’í communities are doing
            around the world, including the children’s education and junior youth programs, capacity building, devotional life,
            social action efforts and more.{' '}
          </p>
          <p>
            “Now we have all of these tools to use and to incorporate into our firesides, into our study circles, into our
            devotional gatherings,” she said.{' '}
          </p>
          <p>
            Side by side with the educational process that can bring children into classes by age 5 and through the complete
            training sequence by age 17 or 18, she said, these tools are adding to “our learning about how to become more
            systematic and intensify the process” of individual transformation and community building.
          </p>
          <p>
            “Every one of us is in love with the act of teaching,” she said, even if “sometimes we are not conscious about it
            because it is so much a part of who we are, our Bahá’í identity.”
          </p>
          <p>
            She reminded the friends of the guidance of Shoghi Effendi that “there is nothing that brings success in the Faith
            like service … the magnet which draws the divine confirmations.” And further: “Self-sacrifice, courage, indomitable
            hope and confidence are the characteristics … [that] cannot but fix the attention of the public and lead them to
            enquire what, in a world so hopelessly chaotic and bewildered, leads these people to be so assured, so confident, so
            full of devotion?”
          </p>
        </div>
      ),

      involveSociety: (
        <div>
          <p>
            Delegate after delegate raised awareness of a world of opportunities available to Bahá’ís and friends wherever they
            live, whatever their age and regardless of their circumstances.
          </p>
          <p>Examples:</p>
          <ul>
            <li>
              Seminars offered by the Institute for Studies in Global Prosperity and working groups sponsored by the Association
              for Bahá’í Studies prepare young people for contributing effectively to discourses in their professional and
              academic lives
            </li>
            <li>
              Neighborhood associations and institutions, such as municipal departments and police, are resources for involvement
              in improving life for residents
            </li>
            <li>Interfaith organizations are more and more open to Bahá’í members and ideas for bringing people together</li>
            <li>
              Older Bahá’ís can forge friendships in the community by volunteering in such arenas as telling stories to children
              at the library and hosting film or karaoke nights
            </li>
            <li>Discourse is possible with grassroots affiliates of national organizations serving diverse populations</li>
            <li>
              Courses on the Faith and teachings of Bahá’u’lláh can be offered in community colleges and other educational venues.
            </li>
          </ul>
          <p>A wealth of topics also came to the fore in a pair of open consultation sessions.</p>
          <p>They included:</p>
          <ul>
            <li>
              Showing students an alternative to modes of activism that avoid talk of unity — because they associate it with the
              dominant culture suppressing dissent
            </li>
            <li>Reversing the trend of few young people enrolling as Bahá’ís</li>
            <li>Likening paternalism to teaching a person to fish your way</li>
            <li>Urging completion of the practices associated with books of the Ruhi training sequence</li>
            <li>Seeking courses for Local Spiritual Assemblies on befriending people of a broader sexual orientation </li>
            <li>
              Seeing a role for Local Assemblies in tackling structures in the wider community that perpetuate institutional
              racism{' '}
            </li>
            <li>Needing sessions on building capacity to teach the Faith</li>
            <li>
              Advocating an update to the 1992 statement The Vision of Race Unity that substitutes racial prejudice for racism in
              defining the “most vital and challenging issue”
            </li>
            <li>Sharing how the scientific fact that race is a construct can be used in teaching about oneness</li>
            <li>Similarly, how humanity’s twofold moral purpose can be brought into discussion in a number of arenas</li>
            <li>Asking for prayers as another hurricane season nears.</li>
          </ul>
        </div>
      ),

      race: (
        <div>
          <p>
            The flurry of insights steered delegates onto a path of focused consultation concerning what the Guardian, Shoghi
            Effendi, called “the most vital and challenging issue” facing America.
          </p>
          <p>
            Delegates arose to thoughtfully and lovingly address the principles and framework for action of the Faith that, as
            applied over time, will lead America out of its morass of inequities.
          </p>
          <p>Drawing on Shoghi Effendi’s messages, they spoke of:</p>
          <ul>
            <li>
              Ensuring that those who previously have not been full participants in society be front and center in carrying out
              the instruments of the community-building process
            </li>
            <li>
              The comfort that the “sweet” Feast letters of the National Spiritual Assembly provide for those who are reeling from
              daily assaults on their humanity and very lives
            </li>
            <li>
              The three “weapons” of rectitude of conduct, a chaste and holy life, and elimination of prejudice that “we’ll need
              to bring the World Order of Bahá’u’lláh to fruition”
            </li>
            <li>
              The balance of exercising the restraint those principles imply and giving every consideration to hearing the voices
              of those long-oppressed
            </li>
            <li>
              The “twin S’s of superiority and suspicion,” saying “each of us has to think about our S and how we can connect our
              hearts with Bahá’u’lláh”
            </li>
            <li>
              The opportunity to grow “from strength to strength” as people of different backgrounds are brought together,
              discover their oneness, work through their biases, then tell the story so others can benefit.
            </li>
          </ul>
          <p>
            Counselor Mark Sisson urged a total focus on the unifying work ahead. “That doesn’t mean we ignore what is happening
            in the world around us, but that we do what we do as a response to that.”
          </p>
          <p>
            And he noted, “Who is having the conversation” on oneness “is what matters,” including “children, junior youth and
            their parents.”
          </p>
          <p>
            They love the teachings and are being transformed, said Sisson, lending assurance that “those coming behind us will
            take us to the next level.”
          </p>
          <p>
            He said the Universal House of Justice “is asking us to look into the future. The path is well laid and the guidance,”
            which “didn’t stop with the Guardian’s Advent of Divine Justice,” will “help us follow it.”
          </p>
        </div>
      ),

      huququllah: (
        <div>
          <p>
            Rather than explaining directly, the Board members attending the Convention on May 26, 2018, let the learning shine
            from stories, recited by Ḥuqúqu’lláh representatives, of two believers whose insights flowed in two directions:
          </p>
          <p>
            <strong>From outward action to inner condition.</strong> One Bahá’í told of studying guidance on the law of the Right
            of God and, despite feeling that “I would continually lose the inner essence of this law,” was inspired immediately to
            calculate the obligation. This believer delved into financial statements, budgeting and making a spreadsheet, but was
            frustrated in attempts to determine how much was owed to Ḥuqúq. Finally, “I opened my prayer book to the Ḥuqúqu’lláh
            section. As I read the prayers tears began to fill my eyes. … I felt the reply: The number doesn’t matter, only the
            intention.” With relief at being freed of that limitation, the believer said, “A number popped into my head unbidden,
            so this is the number I used. … The process of Ḥuqúqu’lláh is a divine mystery. Through this we learn about ourselves
            and our own limitations, and we learn how God transcends such limitations.”{' '}
          </p>
          <p>
            <strong>From inner condition to outward action.</strong> The story was shared of a family that lacked wealth but
            wanted dearly to make a Ḥuqúq payment. Knowing the family’s tight financial condition, a representative refused it
            (not understanding at the time that any believer is free to make an offering regardless of economic circumstances).
            The head of the household was disappointed, but happily returned a few months later. After looking at their own
            situation honestly, members of the family changed some spending behaviors so that they could save bit by bit. The
            believer making the payment declared that “we are truly eligible. … We are delighted to make our first payment.”
          </p>
          <p>
            These stories, introduced by Board member Behrad Majidi, are just two illustrations of how different believers
            experience spiritual progress through interaction with the law of Ḥuqúqu’lláh.
          </p>
          <p>
            Other members of the Board of Trustees at the Convention were Danita Brown, Melissa Fargnoli, Robert Malouf and
            Mashiyyat Rahmani. They conveyed the regards of absent board members Shannon Javid and Catherine (Kitty) Schmitz.
          </p>
          <p>
            Brown and Malouf began the presentation with a recitation of a letter from the Board to this Convention that includes
            ample guidance from the Universal House of Justice about the “far-reaching implications” of the Right of God for the
            spiritual commitment of individual believers to the Cause, for the purification of their possessions, for the overall
            spiritualization of humanity, and for the use of material resources to benefit humanity.
          </p>
          <p>
            Paralleling a theme of the stories that shared on stage, the letter expresses the Board’s delight that as we “march
            forward” toward the goals of the Five Year Plan, Bahá’ís are “well aware that our outward acts are directly affected
            by our inner condition, and our inner condition influenced by our outward action.” It tells of many friends engaging
            in group study of the sacred and authoritative writings on the “multidimensional, magnificent” law of the Right of
            God. “It is hoped,” the letter states, “that during the years immediately ahead, many more venues, especially those
            for youth and young adults entering the work force, will be established to study a law so sacred,” and as avenues for
            the benefit of “those thirsty to gain deeper insights into this law.”
          </p>
          <p>
            Board members noted that communities and individuals may pursue that learning by contacting one of the 96 Ḥuqúqu’lláh
            representatives across the country. Several representatives were at the Convention as delegates or guests, and they
            were asked to stand and be recognized.
          </p>
          <p>
            Another story was shared of how inspiration for more widespread observance of the Right of God welled up at the
            National Convention in 1984, years before the Universal House of Justice applied the law universally. A narrative by
            Javidukht Khadem was read, relating how her husband, the Hand of the Cause Zikrullah Khadem — attending that
            Convention as a special guest — received an inspiration that “completely transformed” him. He spoke to the delegates
            eloquently about the importance of the law of Ḥuqúqu’lláh, then mentioned how in the early 20th century the Bahá’ís of
            North America had petitioned ‘Abdu’l-Bahá for permission to build a House of Worship.
          </p>
          <p>
            Soon enough, the delegates got together a scroll with signatures petitioning the Universal House of Justice to apply
            the law of the Right of God within the U.S. Bahá’í community. The story relates how the House of Justice’s reply said
            it was not yet time, but that it would arrange to translate guidance into English as a preparatory step. Mrs. Khadem
            concluded, “I have no doubt that Mr. Khadem’s spirit is exhilarated in the Abhá Kingdom … [at this] maturation of the
            Cause in the West.”
          </p>
          <p>
            Fargnoli commented, “We now have the privilege of this law. … We’re deepening our understanding as we move forward,”
            and she expressed the Board’s confidence that the U.S. community will progress in putting that understanding into
            practice.
          </p>
          <p>
            Rahmani had the privilege of bringing loving greetings from the Counselors, National Assembly members and delegates at
            Alaska’s Bahá’í National Convention, which he had attended only the day before.
          </p>
          <p>
            As he has many years, he took the lectern to respond to questions from delegates. A significant share of his responses
            dealt with means of payment that have become available recently. “We now have greater automation with our Ḥuqúq
            payment systems and we have a centralized office in Chicago,” he said, introducing the office’s two staff members.
          </p>
          <p>
            That automation allows online payment through the huquq.us website, as well as credit card payment. These measures
            have opened participation to many people who might not otherwise have taken that step. But he lightheartedly
            cautioned, “One should realize that it’s simply a tool; you shouldn’t be borrowing money to be paying Ḥuqúq. … We let
            Baha be the judge if you’re getting free flights” from use of the card.
          </p>
          <p>
            He also acknowledged that the past year has seen an increase in requests for representatives to travel to local
            communities for education on the law. He added, “As more individuals are participating to pay the Ḥuqúq they also
            become more generous to contribute to the Bahá’í Funds as well.”
          </p>
          <p>
            In response to a question about how the Universal House of Justice uses that money, Rahmani noted that such resources
            have been making the building of the new Houses of Worship possible. However, “When we give money to the Beloved we
            don’t necessarily ask how He’s going to spend this money.”
          </p>
          <p>
            Enlarging on that response, Malouf shared some of the copious guidance available in the compilation on the Right of
            God. Two aims for use of those resources particularly struck him, he said: “The spiritualization of humanity and the
            transformation of society. Who can tell me what this Plan is about if it’s not about those two things?”
          </p>
        </div>
      ),

      capacity: (
        <div>
          <p>Topics included:</p>
          <ul>
            <li>Engaging in home visits to better serve community members</li>
            <li>
              Building on the empowerment neighborhoods experienced when planning and carrying out celebrations for the
              bicentenary of the birth of Bahá’u’lláh
            </li>
            <li>
              Staying atop statistics on where and how many core activities exist to ensure that money and other resources are
              allocated in the right amounts
            </li>
            <li>Participating in workshops conducted by national and regional institutions</li>
            <li>Maintaining properties that are dignified and useful</li>
            <li>Facilitating the participation of young people in training and service</li>
            <li>Becoming aware of issues in those youths’ lives and seeking solutions</li>
            <li>Not just supporting the community-building process, but engaging in it</li>
            <li>Sharpening the tools of reading the community’s reality</li>
            <li>Aiding the development of cluster agencies</li>
            <li>Reaching out to populations such as African Americans, American Indians and immigrants</li>
            <li>Expeditiously vetting individuals who work with minors</li>
            <li>
              Following up in a timely manner with residents who contact the National Spiritual Assembly’s Office of Inquiry
              Services
            </li>
            <li>Similarly, greeting Bahá’ís and friends who come to study and channeling them into paths of service</li>
            <li>
              Arranging immediate deepenings on letters from the Universal House of Justice and the National Assembly and
              adjusting agendas to reflect their priorities
            </li>
            <li>Helping community organizations build their capacity for service</li>
            <li>Aiding the settlement of homefront pioneers.</li>
          </ul>
          <p>
            National Assembly member Fariba Aghdasi lent an insight into how that institution improved its capacity to remain
            abreast of developments at the grass roots by appointing a Learning Desk that travels to communities across the land
            and analyzes their progress alongside the local Bahá’ís.
          </p>
        </div>
      ),

      balloting: (
        <div>
          <p>
            Called one by one to the transparent ballot box at the front of Foundation Hall in the Bahá’í House of Worship in
            Wilmette, Illinois, the 163 men and women who voted in person ranged from young adults to veteran campaigners. Their
            faces were of many hues, representing the races, cultures and nationalities of nearly every continent.
          </p>
          <p>
            The diversity was of temperament as well. As each dropped a sealed ballot into the box, some faces were beaming, some
            stern, some wrapped in thought. Some strode up briskly while others evidently savored the spirit, and a few reverently
            kissed the envelope on the way.
          </p>
          <p>
            All reflected the spiritual significance of the moment. Some walked away with open emotion, others with quiet
            satisfaction, others wreathed in smiles. When it was finished the room was filled with the quiet singing of
            “Alláh-u-Abhá.”
          </p>
          <p>
            Swati Shevate Justice of Greensboro, North Carolina, welled up in tears in the minutes after her first-ever
            participation in National Convention balloting. “When I was [elected in October] to be a delegate at the National
            Convention, at first I could not believe this. I was so overwhelmed,” she recalled. “I have been preparing and
            praying, but … I couldn’t keep it together after we finished voting and chanted ‘Alláh-u-Abhá.’” It took her back to
            years past, when as a volunteer at the Bahá’í World Center she witnessed the election of the Universal House of
            Justice. Balloting for the National Spiritual Assembly “had the same kind of power, the same kind of impact. … I’m so
            grateful for this honor.”
          </p>
          <p>
            Another first-time delegate, Greg Hatayama of Denver, said, “I feel a little bit of relief, actually. There was a lot
            of tension and thought … the weightiness of the task was difficult to sustain for the last few days.” Past delegates,
            he added, had helped him prepare well for what to expect.
          </p>
          <p>
            On the other side of the coin, it’s the 28th year as a delegate for Joel Nizin of Ridgewood, New Jersey, and he
            prepares every year by spending several months considering whose names he might place on the ballot, as well as the
            points he might bring up in consultation. “It’s not just a few days and a few weeks. … Usually within a day or two of
            the election I get down to the final nine.” He makes a point of hanging out with new delegates every year, he added.
          </p>
          <p>
            The balloting opened the third day of this year’s Convention. A hush fell over the delegates and guests as the chair
            called the gathering to order and called for a “prayerful attitude seeking divine guidance and confirmation.”{' '}
          </p>
          <p>
            Instructions for voting were read, and the six delegates who served as tellers were asked to ensure that all delegates
            present had blank ballots and pens or pencils.{' '}
          </p>
          <p>
            Guidance from Shoghi Effendi, Guardian of the Faith, was read reminding all of the prayerful attitude required for
            voting, the criteria for choosing whom to vote for, the desired qualifications for Assembly members, and the
            seriousness of the task of voting.
          </p>
          <p>
            After prayers, the delegates had several minutes to write nine names on their ballots, in silence broken only by the
            air circulation. The hush continued as the names of the 171 delegates were called in alphabetical order and their
            ballots cast. The chief teller placed the ballots of absent ones who had submitted them in advance.
          </p>
          <p>
            Full-hearted applause, with all in the room standing, followed the announcement that all 171 delegates had voted. The
            tellers then brought the box to a secure place for counting of the ballots.
          </p>
          <p>
            “Every Assembly elected in that rarefied atmosphere is appointed of God,” said David Young, chair of the Convention,
            paraphrasing the assurance of the Guardian.
          </p>
        </div>
      ),

      secretary: (
        <div>
          <p>
            Now the U.S. Bahá’í community has a chance to exercise the same degree of heroic energy within its own borders, said
            Kenneth Bowers, secretary of the National Spiritual Assembly of the Bahá’ís of the United States, in his presentation
            to the 110th Bahá’í National Convention on May 25, 2018.
          </p>
          <p>
            “If we succeed in every cluster, from the humblest beginnings we can create a flourishing tapestry of community life,”
            he said. Far from being satisfied with merely offering activities, he urged, let Bahá’í individuals and communities
            work to realize Bahá’u’lláh’s vision “in terms of hearts and souls entering community life and making contributions to
            the uplifting of society.”
          </p>
          <p>
            Like all National Assembly members at the National Convention, Bowers brought abundant spirit, stories and experiences
            from the international gathering, April 29–May 2 in Haifa, Israel.
          </p>
          <p>
            More than 160 national Bahá’í communities sent delegates to that convention to consult and to elect the Universal
            House of Justice in a “spirit of love, of purity of motive. … What a demonstration, friends, of the power of
            Bahá’u’lláh.”
          </p>
          <p>
            It was especially astounding that this took place only 65 years after Shoghi Effendi, the Guardian, called on Bahá’ís
            in Iran, the United States, and a few other countries to spread the light of Bahá’u’lláh’s Revelation to all the
            world’s nations and territories.
          </p>
          <p>
            And he assured delegates it is just one of many signs of progress that can serve to inspire the U.S. community to
            action.
          </p>
          <p>
            For one thing, our common framework and vocabulary for growth are helping people and communities in every part of the
            world take action to transform the human race, heart by heart.
          </p>
          <p>
            The capacities and activities of this Five Year Plan are taking shape as “building blocks for the re-creation of
            society,” particularly in countries where larger social structures have collapsed. In neighborhoods and villages
            everywhere, “those who suffer from prejudice, who lack hope, whose potentials are not being fulfilled” are crying for
            this kind of empowerment, he noted.
          </p>
          <p>
            Another lesson is that in recent years, the functions of National Spiritual Assemblies and other Bahá’í institutions
            have changed radically. They are facilitating people’s empowerment, creating environments where learning can take
            place and are coordinating and channeling energies.
          </p>
          <p>
            The United States is not alone in being immersed in “materialism and moral corruption” in the political, social and
            media realms, Bowers said. The three major letters the National Assembly issued in 2017–2018 acknowledge these
            conditions, along with the gross racial divisions and injustices that have come to the surface, and strive to frame
            our response as Bahá’ís.
          </p>
          <p>
            Fortunately, people around the world are proving receptive to the message of Bahá’u’lláh, and the stream of humanity
            that joined us in the celebration of the bicentenary of Bahá’u’lláh’s birth demonstrates that. “The world is ready for
            Bahá’u’lláh. We must believe this and this must be reflected in our teaching work,” he said.
          </p>
          <p>
            And even as a greater variety of people take ownership of their spiritual transformation and lives of service, they
            will take ownership in their own way. Bowers shared a story of a young group of Pacific Islanders living in Sydney,
            Australia, who have been active in a community-building effort that grew from a couple of homefront pioneers in their
            neighborhood to a program of growth that encompasses some three thousand people.
          </p>
          <p>
            That group of youth defied the low expectations that go with the poverty, crime and hopelessness of their
            surroundings, and earned their own money to take a pilgrimage trip to ‘Akká and Haifa, Israel. They are now “on the
            path toward making outstanding contributions. … In front of our eyes the promise of Christ is being realized — the
            meek are inheriting the earth.”
          </p>
          <p>
            This is one of many results of the heroic worldwide pioneering Bahá’ís undertook to fulfill the earlier stages of the
            Divine Plan set out by ‘Abdu’l-Bahá a century ago. Now, the House of Justice has signaled, we are in a new chapter of
            the Plan.
          </p>
          <p>
            From his reading of the Riḍván 2018 message from the Supreme Institution, Bowers posed five questions for the
            delegates to ponder in their consultation — and by extension for the entire national community:
          </p>
          <ul>
            <li>
              How do we learn to approach the process of growth in terms of learning and building capacity, rather than simply as
              a set of activities? In assessing any cluster’s stage of development, the numbers of activities and people involved
              can be a useful indicator. But that kind of growth is impossible unless an increasing number of people develop such
              capacities as having spiritually significant conversations, interacting with the word of God, and accompanying each
              other in learning and service.
            </li>
            <li>
              What are we learning about working with receptive populations in particular neighborhoods? Crucial elements of this
              question include making long-term commitments to support these populations, mobilizing young people in such efforts,
              and forming “structures of accompaniment that help maintain momentum in these neighborhoods.”{' '}
            </li>
            <li>
              How do we raise our effectiveness in teaching all across each cluster? The most successful clusters, he said, make
              room for collective or team approaches to teaching and accompaniment — both in focus neighborhoods and in other
              areas of the cluster. An attitude of learning and sensitivity to the needs of seekers is essential here, and such
              approaches have great potential to raise “a far more profound appreciation of the content of the institute sequence”
              — for instance, realizing how to make use of principles discussed in the Ruhi Institute books on service and on
              teaching the Cause. An experiment in using social media, being overseen by the National Assembly, is reconfirming
              lessons learned in past years: that people receptive to Bahá’í teachings can be found fairly easily — but we have to
              be careful not to generate so much response that it “outstrips” the capacity in any given cluster to welcome people
              into life of the community.
            </li>
            <li>
              How do we increase the coherence of our efforts? As one example he suggested that devotional gatherings might “link
              to the spirit of service” or become a “forum to undertake meaningful conversations” on issues of concern such as
              racial justice and equality of women and men. On a larger scale, Bowers noted that the National Assembly’s Office of
              Public Affairs has embarked on a deep study of the issue of race in America that includes finding out what kinds of
              conversations are already going on, how the Bahá’ís might organize spaces and collaborate with like-minded
              organizations, and ultimately what power our community-building activities might gain to become “an arsenal in the
              assault on injustice in our country.” This is the kind of learning and experience that can make a race unity
              conference, as envisioned in the Feb. 25, 2017, letter of the National Assembly, meaningful as a step in translating
              Bahá’í ideals into reality.
            </li>
            <li>
              How do we become involved in the life of society in a way that reinforces legitimate efforts for justice in society,
              while avoiding the trap of divisive discourse? This issue has caused great anxiety, especially noticeable among
              young people, he said. How, then, in a country “coming apart at the seams,” can we focus on the slow work of
              creating a just society?
            </li>
          </ul>
          <p>
            “We are a community of great resources, devoted members, and an astounding variety of talents,” he told the
            Convention.
          </p>
          <p>
            He asked for prayers for those who arise, in response to recent National Assembly letters, to move as homefront
            pioneers to new locales and to focus on serving particular populations such as African Americans, American Indians,
            and new immigrants.
          </p>
          <p>
            Recalling letters from the Guardian in the mid-20th century, he echoed their call for a spirit of “living sacrifice,”
            paralleling the example of present-day Bahá’ís in Iran, that can honor the station of North America’s Bahá’ís as
            spiritual descendants of the Dawn-breakers who rose to the call of the Báb.
          </p>
          <p>He concluded: “The receptivity is here, the spirit is here, the path is clear, and the work is before us.”</p>
          <p>
            Following the report, delegates brought up questions and comments regarding opportunities to support the movement for
            race amity, keeping in touch with Bahá’ís who have moved out of contact with local communities, following the changing
            trends among people who are looking for religious community, and viewing conditions and opportunities in our world
            with a spiritual vision.
          </p>
        </div>
      ),

      treasurer: (
        <div>
          <p>
            “It was from the rank and file of the believers,” she enthused — a spiritual step beyond past years when such
            victories often came with the aid of large individual gifts.
          </p>
          <p>
            Indeed, in her presentation to the 110th U.S. Bahá’í National Convention on May 25, 2018, she could report that direct
            contributions, for the year ending April 2018, came from the highest-ever number of individuals: a total surpassing
            15,600.
          </p>
          <p>
            But the title of her presentation, ”The Zenith of Our Potential,” was a hint that there is more to achieve. After all,
            this title harks to a passage from the Dec. 8, 2017, letter of the National Spiritual Assembly:{' '}
          </p>
          <p>
            This year by no means represented the zenith of our potential as promulgators of this Cause. Rather, it fortified us
            for bolder and more effective teaching efforts in the years ahead.{' '}
          </p>
          <p>Certainly, some specific needs will come with those bolder efforts in the next few years. </p>
          <p>
            The Riḍván 2018 message of the Universal House of Justice calls for staging a series of conferences in upcoming years.
            The momentum of expansion and consolidation is increasing in some key clusters, raising the need for buildings to
            accommodate training institute activities. The Assembly’s call for homefront pioneers will generate needs for some
            pioneers to be supported with housing or small stipends. Renovations are foreseen for the House of Worship, and the
            National Bahá’í Archives must be moved from the Temple into a facility that better fits the function. And of course a
            national conference on race unity is only a few years away.
          </p>
          <p>
            With those needs in mind, Conrad sought input from the delegates regarding just how high to increase the contribution
            goal in upcoming years. Later in the Convention, the following goals were announced: $38 million for fiscal 2018–19,
            $40 million for 2019–20, and $43 million for 2020–21.
          </p>
          <p>
            “It’s a big step,” Conrad said, but added, “We believe that the United States Bahá’í community is more than capable of
            achieving these goals.”{' '}
          </p>
          <p>The treasurer shared a number of reasons for that optimism. </p>
          <p>
            Significantly, this past year local Bahá’í communities offered hundreds of events in celebration of the 200th
            anniversary of the birth of Bahá’u’lláh. Total attendance was around 125,000, two-thirds of which were guests of the
            Bahá’ís, representing “one of the largest efforts to acquaint our friends with the Revelation of Bahá’u’lláh in the
            history of our country.”{' '}
          </p>
          <p>
            But ultimately, this large-scale local expenditure didn’t hinder believers and institutions from contributing a total
            of $36.5 million to the National Fund. “You surprised us all!” she said.{' '}
          </p>
          <p>
            Looking forward, “Steadfast and generous giving from Bahá’ís throughout the country, coupled with careful stewardship
            by the institutions, allows us to be ready for large-scale opportunities for growth.”
          </p>
          <p>
            About 63 percent of unrestricted contributions to the National Fund came directly from individuals, 28 percent from
            institutions, and 9 percent from Bahá’í-owned entities, she noted.{' '}
          </p>
          <p>Conrad reflected on three main indicators of the health of the fund:</p>
          <p>
            <strong>Direct giving.</strong> While complete figures were still unavailable, the amount directly given by individual
            believers in the fiscal year’s first 10 months was comparable to the amount given for the entire previous year.
          </p>
          <p>
            <strong>Universal participation.</strong> The number of contributors rose by 5 percent over the previous year, and 21
            percent over the past five years. Meanwhile, participation totals in the International and Continental Funds were each
            up by close to 20 percent.
          </p>
          <p>
            <strong>Regularity.</strong> The total contributed through regular giving — from individuals and institutions who
            contribute at least once a month — has been on a steady increase, and this past year it was about double the total for
            fiscal 2009–10. Participation in the Bahá’í Funds has been found to follow the general trend that people who give
            regularly give twice as much over their lifetimes as those who are less regular.{' '}
          </p>
          <p>
            The biggest concern emerges from a look ahead. “The vast majority of our contributions come from Baby Boomers, who
            also comprise the majority of our community,” she noted. The Assembly has been well aware for years that this age
            group will likely have less giving power as more and more retire.{' '}
          </p>
          <p>
            “We are addressing it in various ways,” she said. “It is imperative for the health of the Faith that young people are
            engaged in the life of their communities,” and participation in Bahá’í Funds is part and parcel of this engagement.
          </p>
          <p>
            One line of effort puts the power in the hands of Bahá’ís nationwide, she noted. The Young, Broke, & Spiritual
            deepening program has reached well over 200 young people this year, thanks to collaboration of the Office of Fund
            Development with Regional Bahá’í Councils and regional training institutes. Many who have participated in that
            program, as well as the Lifeblood deepening, have been trained as facilitators to pass the inspiration forward.
          </p>
          <p>Among other facets of the treasurer’s report:</p>
          <ul>
            <li>
              Planned giving grew by 72 percent from the previous year. Bequests and charitable IRA rollovers increased
              significantly, charitable gift annuities and stock/securities contributions more than doubled, and a growing number
              are interested in donor-advised funds.{' '}
            </li>
            <li>
              About 40 percent of Fund expenses are in the areas of teaching and expansion, including money for the Regional
              Councils, training institutes, the three permanent Bahá’í schools, the Native American Bahá’í Institute and five
              learning sites for the junior youth program. Growing initiatives include the call for many more homefront pioneers
              and outreaches by the permanent schools in community building and public discourse.{' '}
            </li>
            <li>
              Direct contributions to the Regional Funds rose to nearly $1.35 million, their highest-ever level, inching them
              closer to the day when each one will be fully supported by the Bahá’ís in its region.
            </li>
            <li>
              The communications work amounts to 8 percent of Fund expenses. In addition to websites and magazine operations,
              notable projects include an expanded Office of Inquiry Services, helping nurture those who inquire about the Faith
              through the web, phone and other avenues; and a pilot project for reaching interested souls through social media.{' '}
            </li>
            <li>
              The work to defend persecuted Bahá’ís in Iran and other sensitive countries, primarily through the Office of Public
              Affairs and the Persian Bahá’í Media Service, makes up 5 percent of the budget.{' '}
            </li>
            <li>
              The financial foundation for the Bahá’í Chair for World Peace at the University of Maryland continues to strengthen,
              with significant contributions this past year from the Universal House of Justice as well as the National Assembly.
              Its endowment fund has grown to $2 million, with a target of $5 million.{' '}
            </li>
            <li>
              Numerous improvements are scheduled for the Bahá’í House of Worship in Wilmette, including interior and exterior
              lighting and improvements in Foundation Hall, in addition to the envisioned Archives transition.{' '}
            </li>
            <li>
              Properties operations add up to 8 percent of the budget. While fuel costs made some operations more expensive, some
              significant savings in energy usage and landscaping have come from efforts to make buildings and grounds “greener.”
              These include replacement of high-energy light bulbs with LED units, updates to heating and air conditioning
              facilities, and transition of several gardens to perennial plantings. Particularly touching was that the entire
              Properties staff — mostly friends of the Faith — volunteered to launder their own uniforms, saving some $20,000 for
              the year.{' '}
            </li>
            <li>
              Capacity-building expenditures include the offices of Community Administration and Assembly Development, which
              accompany Assemblies in administering justice; the Learning Desk, which takes “a bird’s eye view” of
              community-building processes nationwide; support for the Institute for Studies in Global Prosperity and its
              discourse seminars; and the Fund Development Office.{' '}
            </li>
          </ul>
          <p>
            Developments for the upcoming year will include the introduction of a mobile application for the Online Contribution
            System, a logical development considering that the existing web-based mobile giving link now brings in triple what it
            did when it was introduced in 2015.{' '}
          </p>
          <p>
            Also, the first National Treasurer’s Forum in many years will be held Sept. 20–22, with programs to accompany
            treasurers, Local Assemblies and other institutions of various sizes and experience levels.{' '}
          </p>
          <p>
            “We know the world is in turmoil; just turn on the news,” Conrad said. “How in need of the message of Bahá’u’lláh the
            world is.” She quoted the Riḍván message as evoking the “fearlessness, consecration and detachment from all save God”
            shown by the early followers of the Báb, and the “bright, bright hope” of the House of Justice that this example will
            inspire us all to action.
          </p>
          <p>
            Following the report, delegates posed questions and comments that praised the National Assembly for the “gift” of
            being asked to assume greater responsibility, and even asked for the increase in the annual goal to be accelerated;
            clarified understanding of some details in the financial reports printed in the Annual Report; emphasized the
            responsibility of upcoming generations to maintain the health of the Bahá’í Funds, even as they face daunting
            expenses; possible approaches to reconnecting with Bahá’ís currently out of touch with the community; and stages of
            maturation of the worldwide community as envisioned in the Riḍván message.
          </p>
          <p>
            <em>
              <a href="http://Bit.do/natconto2018" target="_blank" rel="noopener noreferrer">
                Click here to see the slideshow
              </a>{' '}
              that accompanied the 2018 treasurer’s report.
            </em>
          </p>
        </div>
      ),

      granimator: (
        <div>
          <p>
            A delegate used the term to describe older Bahá’ís and friends who develop relationships with junior youths and
            contribute their time and talents to support the junior youth spiritual empowerment program.
          </p>
          <p>
            And it turns out the experience isn’t isolated to that unit. Another delegate told how granimators’ role extends even
            to filling in for missing animators of junior youth groups.
          </p>
          <p>Consultation on “Strengthening the Training Institute” also delved into:</p>
          <ul>
            <li>Helping remove barriers to service for youths who have weighty challenges in their personal lives</li>
            <li>The need to analyze why some people continue through the sequence of training courses and some don’t</li>
            <li>
              How schoolteachers and administrators can be approached so the junior youth program can be introduced to potential
              participants and animators
            </li>
            <li>Helping homefront pioneers see how their efforts are central to community building in their new neighborhood</li>
            <li>
              The impact of Bahá’í parents engaging the families of their children’s friends to stimulate interest in service
            </li>
            <li>
              Approaches to using Ruhi Books 8, The Covenant of Bahá’u’lláh, to increase participation in community building, and
              10, Building Vibrant Communities, to transform Local Spiritual Assembly consultation
            </li>
            <li>
              Iranian Bahá’ís who completed the Ruhi courses in Persian retaking them in English to increase their effectiveness
              as tutors
            </li>
            <li>Encouragement as the key to effective accompaniment</li>
            <li>How Ruhi book refreshers can jump-start an increase in participation</li>
            <li>How reflection gatherings for each core activity have identified needs more clearly</li>
            <li>Fostering learning at the intersection of core activities and social action</li>
            <li>
              Empowering youths in Ruhi Book 5, Releasing the Powers of Junior Youth, to gain tutoring experience by helping
              others who newly enter the course
            </li>
            <li>Exposing local police to the junior youth program to aid their understanding of its potency</li>
          </ul>
        </div>
      ),

      clusterMovement: (
        <div>
          <p>
            Yet, not even halfway through the consultation, though the insights shared to that point were “exciting,” in the words
            of Counselor Farah Guchani-Rosenberg, the number of delegates wishing to speak to the topic had been depleted.
          </p>
          <p>
            That prompted Muin Afnani, member of the National Spiritual Assembly, to express concern and detail two issues — the
            number of clusters needing to advance to the next stage of development during this Plan and the number of homefront
            pioneers it will take to fuel much of that progress — that begged further exploration by delegates.
          </p>
          <p>
            Guchani-Rosenberg arose as well to urge further sharing that would underscore the needs and challenges facing American
            Bahá’ís and spotlight the efforts that have been undertaken to date.{' '}
          </p>
          <p>
            “Every time we meet in Haifa [at the Bahá’í World Center] the very first topic of consultation is where we are,” she
            related. “How many clusters have advanced to the next stage?”{' '}
          </p>
          <p>
            Guchani-Rosenberg detailed the “sheer numbers” of advancing clusters — more than 600 — that our national community has
            “committed to present as a gift” at Plan’s end. Too, as a child of a pioneering family herself, she could attest to
            how transformative homefront pioneering will be for those who arise and the communities where they settle.
          </p>
          <p>
            And the delegates responded. With quotations from the Universal House of Justice and the International Teaching Center
            fresh in their minds, they had already addressed a number of aspects of the effort to move clusters along the path of
            development.
          </p>
          <p>They spoke of:</p>
          <ul>
            <li>Growing the nucleus of community builders — or planting the seeds of one where none existed</li>
            <li>The relationships that begin and ultimately flourish with residents of focus neighborhoods</li>
            <li>
              Networks of friends of the Faith that can be constructed within the arts, interfaith and professional communities
            </li>
            <li>The pairing of experienced community builders with Bahá’ís and friends in less-advanced clusters</li>
            <li>The process for identifying, training and deploying homefront pioneers</li>
            <li>
              Efforts since the 200th anniversary of the birth of Bahá’u’lláh in October to nurture the interest of people who
              attended celebrations
            </li>
            <li>Capacity building within institutions and agencies of the Faith</li>
            <li>
              The need for homefront pioneers to settle both in areas striving to reach Milestones 1 or 2 and in newly opened
              neighborhoods of Milestone 2 and 3 clusters
            </li>
            <li>The joy of seeing your service as a mobile tutor or other resource have a domino effect on activity</li>
            <li>
              Similarly, how a conversation with one person can lead to his or her transformation and that of an entire
              neighborhood or community
            </li>
            <li>The impact on cluster progress of refinements in how institutions and agencies function</li>
            <li>How Local Spiritual Assemblies can be further connected with the needs and rhythm of community building</li>
            <li>
              The importance of exhibiting pure love in the accompaniment of particularly young people who are dealing with
              weighty issues in their lives.
            </li>
          </ul>
          <p>
            “Exciting” insights, to be sure. And after Afnani and Guchani-Rosenberg’s comments, more poured forth — in some cases
            taking consultation into new areas and in others addressing questions raised earlier.
          </p>
          <p>They dealt with:</p>
          <ul>
            <li>
              An advanced cluster’s imaginative strategy for doubling the number of core activities by the bicentenary of the
              birth of the Báb in fall 2019
            </li>
            <li>Nurturing the interest of people from populations that had been assumed largely unreceptive to the teachings</li>
            <li>How teaching teams can increase the effectiveness of their collaboration</li>
            <li>The cascade of outcomes from well-planned cluster retreats and youth camps</li>
            <li>Support that would enable homefront pioneers to remain at their posts</li>
            <li>
              Guidance for retirees seeking avenues of service and young people seeking to combine higher education with a period
              of service
            </li>
            <li>What a community has learned from developing a relationship with recovering addicts</li>
            <li>
              The importance of channeling prospective pioneers into posts that match their skills and the level of ongoing
              accompaniment they’ll need
            </li>
            <li>The patience and persistence required for efforts in many neighborhoods to bear fruit</li>
            <li>How one’s service can be elevated by seeing it in a way akin to homefront pioneering</li>
            <li>
              The role of returned international pioneers in stimulating interest in homefront pioneering and aiding how those who
              arise can approach their service
            </li>
            <li>
              An innovative arrangement of working part time and spending the remainder of the week serving in a nearby cluster
            </li>
            <li>Avenues of service for people who have physical or other limitations.</li>
          </ul>
        </div>
      ),

      ridvanMessage: (
        <div>
          <p>
            Recitation of the Riḍván 2018 message of the Universal House of Justice, screening of the new film A Widening Embrace
            from the Bahá’í World Center, and remarks by Counselors Farah Guchani-Rosenberg and Mark Sisson provided
            confidence-inspiring images of progress in the movement of populations toward Bahá’u’lláh.
          </p>
          <p>
            <strong>Progress made in clusters all along the path of development</strong>
          </p>
          <p>
            Guchani-Rosenberg gained illuminating insights from the 12th International Bahá’í Convention, held only weeks earlier
            in the Holy Land. That gathering left her “heart and soul echoing the words of ‘Abdu’l-Bahá: ‘O Bahá’u’lláh! What hast
            Thou done?’”
          </p>
          <p>
            There, she heard of clusters where tens of thousands of people are being brought into Bahá’í-initiated activities, and
            nations where programs for children and junior youth are being incorporated into school systems.{' '}
          </p>
          <p>
            And from every corner of the world, she said, evidence showed the 200th anniversary of the birth of the Blessed Beauty
            stimulating new capacities to engage people in meaningful conversation and nurture their interest.
          </p>
          <p>
            But the Riḍván message makes abundantly clear that in clusters at every level of development, progress comes when
            people grow in their understanding of the Plan, and when they learn — through action and reflection — to advance its
            aims more and more effectively, said Guchani-Rosenberg.
          </p>
          <p>
            That’s equally true, she said, in towns and villages in Milestone 1 clusters, whose programs of growth are at an early
            stage, and in neighborhoods in more-advanced Milestone 2 and 3 clusters. Bahá’is and friends are learning how to build
            the capacity of their neighbors for service and empower those souls to take ownership of their community-building
            effort and their own spiritual and material development.
          </p>
          <p>
            And the Counselor urged that, as the bicentenary of the birth of the Báb approaches, we celebrate the example of
            heroes and heroines of His time and strive to reflect their qualities of courage and sacrifice.
          </p>
          <p>
            “It is the hope of the Universal House of Justice,” she said, “that through the remainder of this Plan the same love
            that spurred the Báb’s disciples to suffuse the divine light will inspire us all to great deeds.”{' '}
          </p>
          <p>
            <strong>Film shows ripple effect of grassroots experiences</strong>
          </p>
          <p>
            Sisson introduced a screening of A Widening Embrace, which uses the words of participants at the grass roots to tell
            how the community-building process is transforming individuals and communities around the world. This film released by
            the Universal House of Justice is yet another instrument for realization that “the unity of mankind is not just a
            conception but a reality,” he noted.
          </p>
          <p>
            It demonstrates, he said, that as people in places as far flung as Papua New Guinea and Zambia speak from experience
            on the ground, they are shaping action around the world.
          </p>
          <p>
            “We are becoming that organism, that unified entity made up of small parts that gives of itself to become something
            entirely different,” he said, but to truly achieve that we must “balance what’s important and get rid of everything
            else.”
          </p>
          <p>
            How? The Riḍván message of the Universal House of Justice is “giving us every means possible to change course and
            accelerate our efforts. … We have to go through it line by line and translate it into meaningful, collective action.”
          </p>
          <p>
            In short, said Sisson, the Supreme Body “has given the planet the path to follow, and we just have to walk it and get
            and get others to walk it with us.”
          </p>
          <p>
            <strong>Consultation on Riḍván message takes focus</strong>
          </p>
          <p>
            Delegates commenting on the Riḍván message paid particular attention to how Bahá’í institutions can engage in the Plan
            at a higher level.
          </p>
          <p>
            They asked how Local Spiritual Assemblies’ ability to align the rhythm of community life with the rhythm of the
            cluster can be fostered.
          </p>
          <p>
            And many delegates noted that Assembly engagement is most effective when members follow up their study of guidance
            with action in the field, quite often in the form of home visits.
          </p>
          <p>Delegates also reflected on the Riḍván message’s passages about social action and public discourse.</p>
          <p>
            After one asked about communities where such efforts have emerged from the core activities, others brought forward
            examples of early learning from relatively new initiatives and spoke of seminars through which understanding of the
            process is being spread.
          </p>
        </div>
      ),

      conventionOpening: (
        <div>
          <p>
            Led by Counselors Farah Guchani-Rosenberg and Mark Sisson, the delegate deepening took place on a hotel’s upper floor,
            with a view of of the Bahá’í House of Worship some four miles away.
          </p>
          <p>
            Sisson spoke to the the dual roles delegates will play: electing the members of the incoming National Spiritual
            Assembly, and enriching its perspective on issues pertaining to the Faith’s advancement.
          </p>
          <p>
            And he did that through the lens of the recently concluded 12th International Bahá’í Convention. He said our National
            Assembly has laid the groundwork for National Convention to “emulate to the extent possible that same spirit of
            sacredness — different from anything we have in the world today.”
          </p>
          <p>
            Speaking of how reverently the International Convention delegates cast their ballots, Sisson implored delegates to
            National Convention to likewise swim against society’s current and elect nine people whose qualities and
            characteristics will erect “something we’ve never seen before” along humanity’s path.
          </p>
          <p>
            Particularly struck by the Counselor’s words was first-time delegate Lee Artis Ratcliff of Oakland, California. In
            talking later with The American Bahá’í he voiced the hope that one day the electoral process and elevated level of
            conversation Sisson described will be mirrored in larger society.
          </p>
          <p>
            Guchani-Rosenberg and Sisson helped delegates further explore these concepts through discussion of several questions
            related to the duties they would assume that afternoon. Two letters from the Universal House of Justice aided the
            discourse: March 25, 2007, on the Bahá’í electoral process, and May 16, 2013, on the unique nature of National
            Convention.
          </p>
          <p>
            After being transported to the gleaming Temple on the shores of Lake Michigan and taking part in a devotional program
            in the Auditorium, delegates were greeted and seated in Foundation Hall by the temporary Convention chair and
            secretary.
          </p>
          <p>
            Proceedings continued with the election of permanent Convention officers — David F. Young, chair, and Muin Afnani,
            secretary — and the adoption of an agenda and procedures for the four days of consultation.
          </p>
          <p>
            Later, the Riḍván 2018 message of the Universal House of Justice was recited, with a Counselor commenting and
            delegates consulting on the letter’s themes afterward. The Convention was treated to a screening of A Widening
            Embrace, a new film from the Bahá’í World Center about the global unfoldment of the community-building process.
          </p>
        </div>
      ),

      conventionSchedule: (
        <div>
          <p>
            Only weeks after the Universal House of Justice was elected in the Holy Land, the 171 National Convention delegates
            elected last October in home units from coast to coast will take up two principal tasks:
          </p>
          <ul>
            <li>
              Prayerfully elect the nine members of the National Spiritual Assembly that will guide the affairs of the Bahá’í
              Faith in this country over the coming year.
            </li>
            <li>Inform the incoming National Assembly, through consultation, on the progress of the Faith.</li>
          </ul>
          <p>Here, in brief, is the agenda.</p>
          <p>
            <strong>THURSDAY</strong>
            <br />
            The day will start with a deepening for delegates, conducted by members of the Continental Board of Counselors, on the
            purposes of National Convention and delegates’ role in it. After being transported to the House of Worship — now
            gleaming in the daylight — delegates will ascend to the Temple Auditorium for a devotional program.
            <br />
            Once in Foundation Hall, delegates will be seated and proceed to address the organization of Convention. They will
            elect Convention officers, approve the agenda and adopt procedures for consultation.
            <br />
            Recitation of the Riḍván message of the Universal House of Justice will be followed by thoughts of the Counselors
            present on the message and delegate consultation on topics related to it. That evening a newly released film about the
            community-building efforts of Bahá’ís and friends worldwide will be viewed.
          </p>
          <p>
            <strong>FRIDAY</strong>
            <br />
            Presentations by the National Assembly’s secretary and treasurer expanding on key points in that body’s Annual Report
            will be followed by questions from delegates.
            <br />
            Two consultation topics are slated to be addressed by delegates as the day’s proceedings continue: “Movement of
            Clusters along the Continuum of Development” and “Strengthening the Training Institute.”
          </p>
          <p>
            <strong>SATURDAY</strong>
            <br />
            Solemn balloting for the National Assembly will be overseen by the Counselors and observed by Convention visitors.
            <br />
            The trustees of Bahá’í Ḥuqúqu’lláh in the United States will make a presentation and answer questions.
            <br />
            Consultation will continue, with “Enhancing Institutional Capacity” as the scheduled topic. The Convention photograph
            will be taken outside the Temple or, if the weather is inclement, inside Foundation Hall.
          </p>
          <p>
            <strong>SUNDAY</strong>
            <br />
            Consultation will continue, with “Involvement in the Life of Society” on the agenda as one topic. To conclude
            Convention, the Counselors will offer final remarks.
          </p>
        </div>
      ),

      qa: (
        <div>
          <p>
            <strong>Q. What are the purposes of National Convention? </strong>
            <br />
            A. The Universal House of Justice, in an April 4, 1971, letter from the Universal House of Justice to our National
            Spiritual Assembly, explained, &quot;[T]he primary purpose in holding a convention is to enable the delegates to meet
            together, deliberate freely upon the affairs of the community, and cast their ballots. Other considerations such as
            allowing the rank and file of believers to attend the Convention or follow its deliberations are secondary in
            importance. Obviously it is within the discretion of your National Assembly to decide upon these secondary
            matters.&quot; The balloting to which the letter refers is for the nine members of the National Assembly that will
            serve for the coming year.
          </p>
          <p>
            <strong>Q. When is National Convention? </strong>
            <br />
            A. Most years, National Convention is held on an extended weekend during the 12-day Festival of Riḍván that doesn’t
            conflict with the election of Local Spiritual Assemblies. But every five years — this year is one of them — National
            Convention is held in late May. Those are the years when the Universal House of Justice is elected at the Bahá’í World
            Center in Haifa, Israel; since that election takes place in April, the National Convention is held in May to enable
            the National Assembly’s members to attend the International Convention.
          </p>
          <p>
            <strong>Q. Where is National Convention held? </strong>
            <br />
            A. For nearly two decades, all sessions of the National Convention have been held at the Bahá’í House of Worship in
            Wilmette, Illinois. Prior to this, the National Convention was often held in a large hotel meeting space to allow a
            large number of Bahá’ís to attend. Mostly for budgetary reasons, Convention sessions are now held at the Bahá’í House
            of Worship.
          </p>
          <p>
            <strong>Q. Who can attend National Convention? </strong>
            <br />
            A. The small number of seats in Foundation Hall at the Bahá’í House of Worship greatly limits the number of people who
            can attend. Delegates are guaranteed seats, as are members of the Continental Board of Counselors and the National
            Spiritual Assembly. The remainder of seats in Foundation Hall are filled by some members of the Auxiliary Boards for
            Protection and Propagation and the Regional Bahá’í Councils; a small number of Bahá’í National Center staff members;
            and guests such as delegates’ family members.
          </p>
          <p>
            <strong>Q. How are National Convention delegates elected, and who is eligible to serve? </strong>
            <br />
            A. The number of delegates allocated to the U.S. Bahá’í National Convention was set by the Guardian, Shoghi Effendi,
            at 171. At Unit Convention in early October, the Bahá’ís in most geographical units elect one believer to be the
            delegate from that unit. A few units, because of the large number of believers they contain, elect more than one
            delegate. Any adult Bahá’í in good standing who lives in the unit is eligible to serve as a delegate, except members
            of the Continental Board of Counselors. If an Auxiliary Board member is elected as a delegate, he or she must decide
            whether to serve as delegate or remain on the Auxiliary Board. If the elected delegate cannot serve, the National
            Spiritual Assembly chooses his or her replacement. The results of the unit convention elections in October must be
            confirmed by the National Spiritual Assembly and delegates are officially recognized within one to two months.
          </p>
          <p>
            <strong>Q. How do delegates prepare for National Convention? </strong>
            <br />
            A. To assist new and returning delegates in preparing for participation in the Convention, they are provided with many
            writings from the Faith relating to Convention. Election materials, the National Spiritual Assembly’s Annual Report,
            and a preliminary Convention agenda also are provided to delegates. On the morning National Convention begins, a
            deepening for delegates on the significance and purpose of the National Convention is facilitated by members of the
            Continental Board of Counselors.
          </p>
          <p>
            <strong>Q. Who may speak at National Convention? </strong>
            <br />
            A. The privilege of the floor is extended to delegates, members of the Continental Board of Counselors, and members of
            the National Spiritual Assembly. Also able to speak when invited to are Bahá’ís who deliver reports, such as
            representatives of the Board of Trustees for Ḥuqúqu’lláh, or possess information that will inform the delegates’
            consultation, such as a visiting Counselor member of the International Teaching Center.
          </p>
          <p>
            <strong>Q. Who may serve as a National Convention officer? </strong>
            <br />
            A. Any delegate present for the proceedings may serve as Convention chair or secretary. The body of delegates elects
            the officers by secret ballot in the opening session of the Convention.
          </p>
          <p>
            <strong>Q. How is the National Convention agenda decided? </strong>
            <br />
            A. A preliminary agenda, guided by the purposes of National Convention and themes of the current Plan, is presented by
            the National Spiritual Assembly to the delegates. Delegates are free to propose changes to the agenda and have them
            adopted by the body of delegates.
          </p>
          <p>
            <strong>Q. How is consultation conducted at National Convention? </strong>
            <br />
            A. The exact manner of consultation, such as how long each delegate may speak or whether preference will be given to
            delegates who have not yet spoken, is decided by the body of delegates at the beginning of Convention. Each delegate
            has a number and raises that number to be recognized by the Convention secretary. The secretary lists the delegates in
            order of recognition and that list can be seen by all on monitors placed on the wall above the stage. When the time is
            near for a delegate to speak, he or she moves to the front of Foundation Hall and awaits his or her turn, at which
            point he or she stands at a lectern and speaks at a microphone. Delegates may offer insights on the topic of
            consultation for that time period and offer a suggestion or recommendation to the National Spiritual Assembly for its
            consideration. Suggestions require no vote of the body of delegates. A recommendation is consulted on and the body of
            delegates decides by show of hands whether to pass it along to the National Assembly.
          </p>
          <p>
            <strong>Q. How is the National Spiritual Assembly elected? </strong>
            <br />
            A. On Saturday morning of National Convention, the delegates gather — in a session monitored by the members of the
            Continental Board of Counselors present and witnessed by registered non-delegates — to vote prayerfully by secret
            ballot for the nine members of the National Spiritual Assembly. A ballot that contains more or fewer than nine names
            is invalid. A vote for a person ineligible for election is not counted. Delegates who cannot attend Convention may
            vote by absentee ballot. The nine individuals receiving the highest numbers of votes serve on the National Assembly
            for one year.
          </p>
          <p>
            <strong>Q. How are National Spiritual Assembly election results reported at National Convention? </strong>
            <br />
            A. The manner of reporting the results, beyond the names of the nine elected members of the National Spiritual
            Assembly, is decided by the body of delegates on the first day of Convention.
          </p>
        </div>
      ),
    };
  }
  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }
  setHiddenId(id) {
    this.setState({
      hiddenId: id,
    });
  }
  render() {
    return (
      <div>
        <FlickrGallery apiKey="13d8e319087a91a92dc7d59d1b44490b" setId="72157664713183767" />
        <div>
          <h1>National Convention 2018</h1>
          <p>
            Photos from the Convention available at{' '}
            <a
              href="https://www.flickr.com/photos/bahaius/collections/72157697221553095/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Flickr
            </a>
            .
          </p>
          <p>
            Below are stories on the Convention from <em>The American Bahá’í</em>
          </p>
        </div>
        <hr />
        <Row>
          <Col sm={12} md={8}>
            <h4 className="small-top-padding">National Spiritual Assembly election results announced</h4>
            <p>
              In balloting on May 26, 2018, delegates to the 110th U.S. Bahá’í National Convention, gathered at the Bahá’í House
              of Worship in Wilmette, Illinois, elected nine members to the National Spiritual Assembly of the Bahá’ís of the
              United States to serve for the upcoming year. The Assembly met the same day to elect officers. Below are the members
              elected, in order of delegate votes received:{' '}
            </p>
            <ul className="plain-list">
              <li>Kenneth E. Bowers, secretary</li>
              <li>David F. Young, chair</li>
              <li>Muin Afnani, vice chair</li>
              <li>Jacqueline Left Hand Bull</li>
              <li>Kevin Trotter</li>
              <li>Fariba Aghdasi</li>
              <li>Juana C. Conrad, treasurer</li>
              <li>Robert C. Henderson</li>
              <li>S. Valerie Dana</li>
            </ul>
            <NatConDropDown chapterKey={0} display={this.display.election} />
            <hr />
            <img alt="Counselor Comments" src="https://c1.staticflickr.com/1/886/28520322358_e363e68ca2_q.jpg" />
            <h4 className="small-top-padding">
              Counselors’ final comments encourage us in summoning faith, capacity and resources
            </h4>
            <p>
              With strengthened capacities and resources, a growing ability to learn from our actions, and unity regarding the
              direction of our efforts, our national Bahá’í community is in an extraordinary position to carry forward the Divine
              Plan given to us by ‘Abdu’l-Bahá, two members of the Continental Board of Counselors assured delegates and guests on
              the last day of the 110th U.S. Bahá’í National Convention, May 27, 2018.
            </p>
            <NatConDropDown chapterKey={1} display={this.display.summoningFaith} />
            <hr />
            <img alt="Delegate speaking" src="https://c1.staticflickr.com/1/898/27522567837_15c73804c3_q.jpg" />
            <h4 className="small-top-padding">Brief but rich consultation explores involvement in society </h4>
            <p>
              Consultation on “Involvement in the Life of Society” on Sunday, May 27, 2018, the final morning of the 110th U.S.
              Bahá’í National Convention, was just as rich as the session was brief.
            </p>
            <NatConDropDown chapterKey={2} display={this.display.involveSociety} />
            <hr />
            <img alt="Delegate speaking" src="https://c2.staticflickr.com/2/1743/42336771262_4edbe63796_q.jpg" />
            <h4 className="small-top-padding">Consultation on race brings struggles, confidence to the surface </h4>
            <p>
              Heartfelt perspectives on Bahá’ís and race welled up during what began as open consultation on Saturday morning, May
              26, 2018, at the 110th U.S. Bahá’í National Convention.
            </p>
            <NatConDropDown chapterKey={3} display={this.display.race} />
            <hr />
            <img alt="Huququllah presentation" src="https://c2.staticflickr.com/2/1755/27518794237_9f4cc33036_q.jpg" />
            <h4 className="small-top-padding">Ḥuqúqu’lláh presentation focuses on meeting of the spiritual and practical</h4>
            <p>
              The sacredness of individual observance of the law of the Right of God, and how it knits together the practical with
              the mystical, were at the center of the presentation by the Board of Trustees of Ḥuqúqu’lláh in the United States at
              the 110th U.S. Bahá’í National Convention.
            </p>
            <NatConDropDown chapterKey={4} display={this.display.huququllah} />
            <hr />
            <img alt="Delegate speaking" src="https://c2.staticflickr.com/2/1754/27515863037_3897ff6d1e_q.jpg" />
            <h4 className="small-top-padding">Delegates consult on how institutions build their own and others’ capacity </h4>
            <p>
              Whether addressing the local, regional or national level, delegates consulting on “Enhancing Institutional Capacity”
              on Saturday, May 26, 2018, at the 110th U.S. National Bahá’í Convention described a dual purpose to that effort:
              build capacity within institutions, so those institutions can be in a position to help individuals, the community
              and other institutions and agencies do the same.
            </p>
            <NatConDropDown chapterKey={5} display={this.display.capacity} />
            <hr />
            <img alt="voting" src="https://c2.staticflickr.com/2/1732/27695505477_c146b26fc4_q.jpg" />
            <h4 className="small-top-padding">Balloting reflects the Bahá’í community’s spirituality, diversity, character</h4>
            <p>
              When delegates to the 110th U.S. Bahá’í National Convention carried out the sacred duty of casting ballots to elect
              the National Spiritual Assembly of the Bahá’ís of the United States on May 26, 2018, they showed the diversity of
              the national Bahá’í community in many ways.
            </p>
            <NatConDropDown chapterKey={6} display={this.display.balloting} />
            <hr />
            <img alt="Secretary’s report" src="https://c1.staticflickr.com/1/893/28477691598_d7d29e326c_q.jpg" />
            <h4 className="small-top-padding">Secretary’s report calls for fresh approaches in spirit of past heroism</h4>
            <p>
              The breathtaking “spectrum of human diversity” at this spring’s International Bahá’í Convention is a triumphant
              product of the sacrificial movement, more than half a century ago, of hundreds of believers to countries and
              territories where no Bahá’ís were living.
            </p>
            <NatConDropDown chapterKey={7} display={this.display.secretary} />
            <hr />
            <img alt="Treasurer’s report" src="https://c2.staticflickr.com/2/1737/41628684554_1508fe3fbd_q.jpg" />
            <h4 className="small-top-padding">Treasurer looks beyond Fund victory to even more ambitious efforts</h4>
            <p>
              It’s not just the winning of the $36 million goal for contributions to the National Bahá’í Fund during the past
              year, according to Treasurer Juana Conrad. It’s the number of people who helped win it.
            </p>
            <NatConDropDown chapterKey={8} display={this.display.treasurer} />
            <hr />
            <img alt="Delegate speaking" src="https://c2.staticflickr.com/2/1749/41652114764_e4667b6bee_q.jpg" />
            <h4 className="small-top-padding">
              What’s a ‘granimator’? Consultation on training institutes brings up the concept
            </h4>
            <p>
              Do you know what a “granimator” is? Most 110th U.S. Bahá’í National Convention attendees didn’t until the word
              cropped up in focused consultation on “Strengthening the Training Institute” Friday evening, May 25, 2018.
            </p>
            <NatConDropDown chapterKey={9} display={this.display.granimator} />
            <hr />
            <img alt="Delegate speaking" src="https://c2.staticflickr.com/2/1730/42296880522_8081a978a5_q.jpg" />
            <h4 className="small-top-padding">Consultation on movement of clusters makes the most of a second wind</h4>
            <p>
              The movement of clusters along the continuum of development is so important that it was assigned first position
              among focused consultation topics at this 110th U.S. Bahá’í National Convention and given two time blocks covering
              the entire afternoon of Friday, May 25.
            </p>
            <NatConDropDown chapterKey={10} display={this.display.clusterMovement} />
            <hr />
            <img alt="Counselor speaking" src="https://c2.staticflickr.com/2/1728/40527026720_046fe3db1a_q.jpg" />
            <h4 className="small-top-padding">
              Counselors point to Riḍván message and film as instruments of understanding and action
            </h4>
            <p>
              If delegates and visitors to the 110th U.S. Bahá’í National Convention had any trouble visualizing where their
              community-building efforts can lead, plenty of clarity was provided on Thursday, May 24, the first day of
              proceedings.
            </p>
            <NatConDropDown chapterKey={11} display={this.display.ridvanMessage} />
            <hr />
            <img alt="Delegate deepening" src="https://c1.staticflickr.com/1/873/28459976068_037e9aa3c4_q.jpg" />
            <h4 className="small-top-padding">Delegate deepening leads into Convention opening</h4>
            <p>
              Echoes of the joyous Declaration of the Báb commemoration the evening before were in the air as delegates to the
              110th U.S. Bahá’í National Convention gathered on Thursday, May 24, for a preparation session and the start of
              formal proceedings.
            </p>
            <NatConDropDown chapterKey={12} display={this.display.conventionOpening} />
            <hr />
            <img alt="Wilmette House of Worship" src="https://c1.staticflickr.com/1/947/27411068347_4b18141561_q.jpg" />
            <h4 className="small-top-padding">National Convention to be held May 24–27</h4>
            <p>
              A breathtaking sight — the Bahá’í House of Worship in its illuminated nighttime glory — will greet delegates to the
              110th U.S. Bahá’í National Convention who arrive in time to attend a commemoration of the Declaration of the Báb two
              hours after sunset on Wednesday, May 23. The celebration is sure to set a joyous tone for four days of Convention
              proceedings, convening the next afternoon in the Temple’s Foundation Hall.
            </p>
            <NatConDropDown chapterKey={13} display={this.display.conventionSchedule} />
            <hr />
            <img alt="Q&A" src="https://c1.staticflickr.com/1/878/42280169601_7199e70b81_q.jpg" />
            <h4 className="small-top-padding">Answers to frequently asked questions about Convention</h4>
            <NatConDropDown chapterKey={14} display={this.display.qa} />
          </Col>
          <Col sm={12} md={4}>
            <div className="grey-box">
              <h3 className="light">Resources </h3>
              <p>
                <a href="https://s3.amazonaws.com/natcon2018.bahai.us/docs/2018-NatCon-delegate-demographics.pdf">
                  Demographics of Delegates to the 2018 Bahá’í National Convention
                </a>
              </p>
              <p>
                <a href="https://s3.amazonaws.com/bahai-messages/uhj/2018/20180430_UHJ-ridvan-2018-message_english.pdf">
                  Universal House of Justice Ridván 2018 Message
                </a>
              </p>
              <p>
                <a href="https://s3.amazonaws.com/bahai-messages/nsa/2018/2018-Annual-Report.pdf">2018 Annual Report</a>
              </p>
              <p>
                <a href="https://s3.amazonaws.com/bahai-messages/nsa/2018/20180526_Letter-from-Delegates-to-the-Universal-House-of-Justice.pdf">
                  Message to Supreme Body from 110th U.S. Bahá’í National Convention
                </a>
              </p>
              <p>
                <a href="https://s3.amazonaws.com/bahai-messages/uhj/2018/20180605_UHJ-responds-to-message-from-110th-US-convention.pdf">
                  Supreme Body Responds to Convention Delegates
                </a>
              </p>
              <h4>National Convention Coverage Archives</h4>
              <ul className="plain-list">
                <li>
                  <Link to="./natcon-2017">2017</Link>
                </li>
                <li>
                  <Link to="./natcon-2016">2016</Link>
                </li>
              </ul>
              <h4>Secretary’s Report</h4>
              <iframe
                title="Secretary’s Report"
                src="https://player.vimeo.com/video/271936171"
                width="100%"
                height="172"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              ></iframe>
              <a
                href="https://player.vimeo.com/external/271936171.hd.mp4?s=a6790b82190e958b6adde6028a7a8c8f82326764&profile_id=174&download=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                download high definition video
              </a>{' '}
              <br />
              <a
                href="https://player.vimeo.com/external/271936171.sd.mp4?s=ba931c77eb059100d06cd8fb2674b0a54d67bfc7&profile_id=164&download=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                download regular video
              </a>
              <h4>Treasurer’s Report</h4>
              <iframe
                title="Treasurer’s Report"
                src="https://player.vimeo.com/video/271945575"
                width="100%"
                height="172"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              ></iframe>
              <a
                href="https://player.vimeo.com/external/271945575.hd.mp4?s=3482a66cc2b999ab217eee65f34aefd91720bfea&profile_id=174&download=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                download high definition video
              </a>{' '}
              <br />
              <a
                href="https://player.vimeo.com/external/271945575.sd.mp4?s=d7dfd68aad1d86818803fbdf82eac8f28d61d1d3&profile_id=164&download=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                download regular video
              </a>
              <h5>A Model of Spiritually based Elections</h5>
              <iframe
                title="A Model of Spiritually based Elections"
                src="https://www.youtube.com/embed/em2jvivPy24?ecver=1"
                width="305"
                height="172"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
              ></iframe>
              <hr />
              <h5>Counselors’ Statements</h5>
              <iframe
                title="Counselors’ Statements"
                width="100%"
                height="300"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/531404769&color=%230066cc&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"
              ></iframe>
              <h5>Race Discourse</h5>
              <iframe
                title="Race Discourse"
                width="100%"
                height="300"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/531405882&color=%230066cc&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"
              ></iframe>
              <h5>Movement of Clusters Along the Continuum of Development</h5>
              <iframe
                title="Movement of Clusters"
                width="100%"
                height="300"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/531405675&color=%230066cc&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"
              ></iframe>
              <h5>Strengthening the Training Institute</h5>
              <iframe
                title="Strengthening the Training Institute"
                width="100%"
                height="300"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/531405030&color=%230066cc&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"
              ></iframe>
              <h5>Youth and Junior Youth</h5>
              <iframe
                title="Youth and Junior Youth"
                width="100%"
                height="300"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/531409431&color=%230066cc&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"
              ></iframe>
              <h5>Involvement in the Life of Society</h5>
              <iframe
                title="Involvement in the Life of Society"
                width="100%"
                height="300"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/531409101&color=%230066cc&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"
              ></iframe>
              <h5>Miscellaneous</h5>
              <iframe
                title="Miscellaneous"
                width="100%"
                height="300"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/531405372&color=%230066cc&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"
              ></iframe>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default NatCon2019;
