import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { TextField, InputLabel, FormControl, OutlinedInput, Select, MenuItem, CircularProgress } from '@material-ui/core';
import PasswordViewControl from '../Utilities/PasswordViewControl';
import withContext from '../ContextAPI/Context_HOC';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';
import useForm from '../Utilities/useForm';

const validationSchema = {
  question1Id: { required: true },
  question1Answer: { required: true },
  question2Id: { required: true },
  question2Answer: { required: true },
  question3Id: { required: true },
  question3Answer: { required: true },
  password: { required: true },
};

const initialState = {
  question1Id: { value: '' },
  question1Answer: { value: '' },
  question2Id: { value: '' },
  question2Answer: { value: '' },
  question3Id: { value: '' },
  question3Answer: { value: '' },
  password: { value: '' },
};

const SecurityQuestions = (props) => {
  const setPageTitle = props.setPageTitle;
  useEffect(() => setPageTitle('Change Security Questions'), [setPageTitle]);
  const securityQAndAProxy = props.context.securityQAndAProxy;
  const [allQuestions, setAllQuestions] = useState([]);
  const [unrecoverableError, setUnrecoverableError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCircularProgress, setShowCircularProgress] = useState(true);
  const [messageToUser, setMessageToUser] = useState(null);

  const submitCallback = useCallback(
    (state) => {
      const data = {
        question1Id: state.question1Id.value,
        question1Answer: state.question1Answer.value,
        question2Id: state.question2Id.value,
        question2Answer: state.question2Answer.value,
        question3Id: state.question3Id.value,
        question3Answer: state.question3Answer.value,
        password: state.password.value,
      };

      setShowCircularProgress(true);
      setMessageToUser(null);

      const subscription = securityQAndAProxy.set(data).subscribe({
        next: () => {
          setMessageToUser(
            <p className="success-message" data-cy="success-message">
              Successfully Updated!
            </p>
          );
          setShowCircularProgress(false);
        },
        error: (e) => {
          if (e?.response?.status === 400 && e?.response?.data) {
            const message = e.response.data.message ?? JSON.stringify(e.response?.data);
            setMessageToUser(
              <p data-cy="unsuccess-message" className="error-message wider">
                {message}
              </p>
            );
            setShowCircularProgress(false);
          } else {
            setUnrecoverableError(true);
          }
        },
      });

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      return () => subscription?.unsubscribe();
    },
    [securityQAndAProxy]
  );

  const { state, handleValidationOnChange, handleOnSubmit, isSubmitCalled, disable } = useForm(
    initialState,
    validationSchema,
    submitCallback
  );

  useEffect(() => {
    const subscription = securityQAndAProxy.get().subscribe({
      next: (x) => {
        setAllQuestions(x.data);
        setShowCircularProgress(false);
      },
      error: () => setUnrecoverableError(true),
    });

    return () => subscription.unsubscribe();
  }, [securityQAndAProxy, setAllQuestions, setUnrecoverableError]);

  useEffect(() => {
    if ((!isSubmitCalled && disable) || isSubmitCalled === false) {
      setMessageToUser(
        <p data-cy="unsuccess-message" className="error-message wider">
          Please check the highlighted field(s) and resolve the error(s).
        </p>
      );
    }
  }, [isSubmitCalled, disable]);

  function onAnswerValueChange(event) {
    const trimmedValue = event.target.value.trim();
    if (!trimmedValue) {
      event.target.value = trimmedValue;
    }
    handleValidationOnChange(event);
  }

  return (
    <div>
      {unrecoverableError ? (
        <ErrorMessageToUser />
      ) : (
        <span>
          {showCircularProgress ? (
            <CircularProgress data-cy="security-questions-circular-progress" className="loading-animation" size={40} />
          ) : (
            messageToUser
          )}
          <div className="profile-box">
            <h4>Please enter the following information:</h4>
            <Row>
              <Col sm={6} className="small-top-margin">
                <FormControl variant="outlined" className="min-width-full extra-large-dropdown" data-cy="form">
                  <InputLabel id="securityQuestion1Label">Security Question 1</InputLabel>
                  <Select
                    required
                    label="Security Question 1"
                    labelId="securityQuestion1Label"
                    name="question1Id"
                    value={state.question1Id.value}
                    onChange={handleValidationOnChange}
                    error={!!state.question1Id.error}
                    data-cy="question1"
                  >
                    <MenuItem value="" disabled>
                      <em>Please select your question</em>
                    </MenuItem>
                    {allQuestions
                      .filter(
                        (question) =>
                          question.key.toString() !== state.question2Id.value.toString() &&
                          question.key.toString() !== state.question3Id.value.toString()
                      )
                      .map((question, index) => (
                        <MenuItem key={index} value={question.key} data-cy={`question1_item${question.key}`}>
                          {question.question}
                        </MenuItem>
                      ))}
                  </Select>
                  {state.question1Id.error && (
                    <p className="form-field-error" data-cy="question1Id_Required">
                      {state.question1Id.error}
                    </p>
                  )}
                </FormControl>
              </Col>
              <Col sm={6} className="small-top-margin">
                <TextField
                  required
                  inputProps={{
                    'data-cy': 'question1Answer',
                    autoComplete: 'off',
                  }}
                  id="question1Answer"
                  type="text"
                  label="Security Answer 1"
                  fullWidth={true}
                  variant="outlined"
                  name="question1Answer"
                  value={state.question1Answer.value}
                  onChange={onAnswerValueChange}
                  error={!!state.question1Answer.error}
                />
                {state.question1Answer.error && (
                  <p className="form-field-error" data-cy="question1Answer_Required">
                    {state.question1Answer.error}
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="small-top-margin">
                <FormControl variant="outlined" className="min-width-full extra-large-dropdown">
                  <InputLabel id="securityQuestion2Label">Security Question 2</InputLabel>
                  <Select
                    required
                    label="Security Question 2"
                    labelId="securityQuestion2Label"
                    data-cy="question2"
                    name="question2Id"
                    value={state.question2Id.value}
                    onChange={handleValidationOnChange}
                    error={!!state.question2Id.error}
                  >
                    <MenuItem value="" disabled>
                      <em>Please select your question</em>
                    </MenuItem>
                    {allQuestions
                      .filter(
                        (question) =>
                          question.key.toString() !== state.question1Id.value.toString() &&
                          question.key.toString() !== state.question3Id.value.toString()
                      )
                      .map((question, index) => (
                        <MenuItem key={index} value={question.key} data-cy={`question2_item${question.key}`}>
                          {question.question}
                        </MenuItem>
                      ))}
                  </Select>
                  {state.question2Id.error && (
                    <p className="form-field-error" data-cy="question2Id_Required">
                      {state.question2Id.error}
                    </p>
                  )}
                </FormControl>
              </Col>
              <Col sm={6} className="small-top-margin">
                <TextField
                  required
                  inputProps={{
                    'data-cy': 'question2Answer',
                    autoComplete: 'off',
                  }}
                  id="question2Answer"
                  type="text"
                  label="Security Answer 2"
                  fullWidth={true}
                  variant="outlined"
                  name="question2Answer"
                  value={state.question2Answer.value}
                  onChange={onAnswerValueChange}
                  error={!!state.question2Answer.error}
                />
                {state.question2Answer.error && (
                  <p className="form-field-error" data-cy="question2Answer_Required">
                    {state.question2Answer.error}
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="small-top-margin">
                <FormControl variant="outlined" className="min-width-full extra-large-dropdown">
                  <InputLabel id="securityQuestion3Label">Security Question 3</InputLabel>
                  <Select
                    required
                    label="Security Question 3"
                    labelId="securityQuestion3Label"
                    data-cy="question3"
                    name="question3Id"
                    value={state.question3Id.value}
                    onChange={handleValidationOnChange}
                    error={!!state.question3Id.error}
                  >
                    <MenuItem value="" disabled>
                      <em>Please select your question</em>
                    </MenuItem>
                    {allQuestions
                      .filter(
                        (question) =>
                          question.key.toString() !== state.question1Id.value.toString() &&
                          question.key.toString() !== state.question2Id.value.toString()
                      )
                      .map((question, index) => (
                        <MenuItem key={index} value={question.key} data-cy={`question3_item${question.key}`}>
                          {question.question}
                        </MenuItem>
                      ))}
                  </Select>
                  {state.question3Id.error && (
                    <p className="form-field-error" data-cy="question3Id_Required">
                      {state.question3Id.error}
                    </p>
                  )}
                </FormControl>
              </Col>
              <Col sm={6} className="small-top-margin">
                <TextField
                  required
                  inputProps={{
                    'data-cy': 'question3Answer',
                    autoComplete: 'off',
                  }}
                  id="question3Answer"
                  type="text"
                  label="Security Answer 3"
                  fullWidth={true}
                  variant="outlined"
                  name="question3Answer"
                  value={state.question3Answer.value}
                  onChange={onAnswerValueChange}
                  error={!!state.question3Answer.error}
                />
                {state.question3Answer.error && (
                  <p className="form-field-error" data-cy="question3Answer_Required">
                    {state.question3Answer.error}
                  </p>
                )}
              </Col>
            </Row>
            <Row around="xs" className="top-margin">
              <Col xs={12} sm={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    inputProps={{ 'data-cy': 'passwordInput' }}
                    required
                    type={showPassword ? 'text' : 'password'}
                    data-cy="passwordField"
                    endAdornment={
                      <PasswordViewControl showPassword={showPassword} setShowPassword={setShowPassword} datacy="passwordEye" />
                    }
                    labelWidth={70}
                    name="password"
                    value={state.password.value}
                    onChange={handleValidationOnChange}
                    error={!!state.password.error}
                  />
                  {state.password.error && (
                    <p className="form-field-error" data-cy="password_Required">
                      {state.password.error}
                    </p>
                  )}
                </FormControl>
              </Col>
            </Row>
          </div>
          <div className="medium-top-margin end">
            <input
              onClick={handleOnSubmit}
              data-cy="SecurityQuestionsInfoSave"
              type="submit"
              value="Save"
              className="primary-button"
            />
          </div>
        </span>
      )}
    </div>
  );
};

export default withContext(SecurityQuestions);
