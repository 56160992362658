enum FeatureKey {
  GetFundStatusFromOCS = 'get_fund_status_from_ocs',
  SearchShowsScore = 'search_shows_score',
  RecaptchaEnabledForAuth = 'enable_recaptcha_validation_for_auth_api',
  SpeechSynthesIsEnabled = 'enable_speech_synthesis',
}

export const InternalFeatures = [FeatureKey.SearchShowsScore];

export default FeatureKey;
