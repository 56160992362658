import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import withContext from '../ContextAPI/Context_HOC';
import { Column } from 'material-table';
import CustomFilterAndSearch from './Utilities/TableFunctions/CustomFilterAndSearch';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';
import HuquqTopImg from '../assets/img/Huquq-top-img.jpg';
import useFilterComponent, { FieldFormFilter, FieldFormFilterType, FilterFormParameter } from './useFilterComponent';
import { DatagridPropsCustom } from './interfaces/datagrid-props-custom';
import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { Drawer } from '@material-ui/core';
import { Col, Row } from 'react-flexbox-grid';
import HuquqRepMobileTable from './HuquqRepMobileTable';
import HuquqRepTable from './HuquqRepTable';
import Expansion from './Utilities/TableFunctions/Expansion';

export interface HuquqResponse {
  name: string;
  areas: string[];
  city: string;
  cell: string;
  email: string;
  region: string;
  tableData?: {
    id?: number;
  };
}

export interface HuquqRepProps {
  context: any;
  history: any;
  location: any;
  match: any;
  staticContext: any;
}

type FilterResponse = {
  region: string[];
  area: string[];
  name: string | string[];
  cell: string | string[];
  email: string | string[];
};

const HuquqRep = (props: HuquqRepProps) => {
  const [filtersOpened, setFiltersOpened] = useState(false);
  const [defaultmessage, setDefaultmessage] = useState(<p>Currently loading data. Please wait several seconds!</p>);
  const [filteredData, setFilteredData] = useState<HuquqResponse[]>([]);
  const [filteredDataCache, setFilteredDataCache] = useState<HuquqResponse[]>([]);
  const [filterFieldsArray, setFilterFieldsArray] = useState<FieldFormFilter[]>([]);
  const filterCallback = (filterValues: FilterFormParameter) => {
    setFilteredData(filterDataWithValues(filterValues as FilterResponse));
    setFiltersOpened(false);
  };
  const [filterComponent] = useFilterComponent({ fields: filterFieldsArray, filterCallback: filterCallback });
  const [columns, setColumns] = React.useState<{ columns: (Column<HuquqResponse> & DatagridPropsCustom)[] }>({ columns: [] });
  useEffect(() => {
    const observable = props.context.huquqService.getRepresentatives();
    const subscription = observable.subscribe({
      next: (data: HuquqResponse[]) => {
        const sortedData = data.sort((a, b) => {
          if (a.region && b.region) {
            return a.region > b.region ? 1 : -1;
          }
          return 1;
        });
        setFilteredData(JSON.parse(JSON.stringify(sortedData)));
        setFilteredDataCache(JSON.parse(JSON.stringify(sortedData)));
        createFilterData(sortedData);
        setColumns({
          columns: [
            {
              title: 'Region',
              field: 'region',
              width: 160,
              defaultSort: 'asc',
              valueGetter: (params: GridValueGetterParams<HuquqResponse, any>) => (params.row.region ? params.row.region : ''),
              sortComparator: (v1, v2) => (v1 as string).localeCompare(v2 as string),
            },
            {
              title: 'Coverage Area',
              field: 'areas',
              width: 320,
              valueGetter: (params: GridValueGetterParams<HuquqResponse, any>) =>
                params.row.areas[0] ? params.row.areas[0] : '',
              sortComparator: (v1, v2) => (v1 as string).localeCompare(v2 as string),
              render: (rowData) => <Expansion rowData={rowData} compName={'huquqRep'} />,
              customFilterAndSearch: (term, rowData) =>
                rowData.areas ? (CustomFilterAndSearch(term, rowData, 'areas') as boolean) : false,
            },
            {
              title: 'Full Name',
              field: 'name',
              filterPlaceholder: 'Full Name',
              width: 180,
              valueGetter: (params: GridValueGetterParams<HuquqResponse, any>) => (params.row.name ? params.row.name : ''),
              sortComparator: (v1, v2) => (v1 as string).localeCompare(v2 as string),
            },
            {
              title: 'Phone',
              field: 'cell',
              width: 180,
              filterPlaceholder: 'Phone',
              valueGetter: (params: GridValueGetterParams<HuquqResponse, any>) => (params.row.cell ? params.row.cell : ''),
              sortComparator: (v1, v2) => (v1 as string).localeCompare(v2 as string),
              render: (rowData) => <a href={'tel:' + rowData.cell}>{rowData.cell}</a>,
            },
            {
              title: 'Email',
              field: 'email',
              filterPlaceholder: 'Email',
              valueGetter: (params: GridValueGetterParams<HuquqResponse, any>) => (params.row.email ? params.row.email : ''),
              sortComparator: (v1, v2) => (v1 as string).localeCompare(v2 as string),
              render: (rowData) => <a href={'mailto:' + rowData.email}>{rowData.email}</a>,
            },
          ],
        });
        setDefaultmessage(<p>No results found with the current criteria.</p>);
      },
      error: () => setDefaultmessage(<ErrorMessageToUser />),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [props.context.huquqService]);

  const createFilters = (dataUpToDate: HuquqResponse[]) => {
    const regionSet = new Set<string>();
    const areasSet = new Set<string>();
    dataUpToDate.forEach((columnData) => {
      regionSet.add(columnData.region as string);
      columnData.areas.forEach((area) => {
        areasSet.add(area);
      });
    });
    return { regionSet, areasSet };
  };

  const filterDataWithValues = (filterValues: FilterResponse) => {
    const nameFilter = typeof filterValues.name === 'string' && filterValues.name && filterValues.name.length > 0;
    const cellFilter = typeof filterValues.cell === 'string' && filterValues.cell && filterValues.cell.length > 0;
    const emailFilter = typeof filterValues.email === 'string' && filterValues.email && filterValues.email.length > 0;
    const areaFilter = filterValues.area && filterValues.area.length > 0;
    const regionFilter = filterValues.region && filterValues.region.length > 0;

    if (nameFilter || cellFilter || emailFilter || areaFilter || regionFilter) {
      const filteredCacheArray = [];
      for (let i = 0; i < filteredDataCache.length; i++) {
        const record = filteredDataCache[i];
        let validRecords = true;
        let atLeastOneFilteredValue = true;
        if (nameFilter) {
          if (typeof record.name === 'string' && record.name && record.name.length > 0) {
            if (record.name.toLowerCase) {
              const isValid = record.name?.toLowerCase().indexOf((filterValues.name as string).toLowerCase()) > -1;
              if (isValid) {
                atLeastOneFilteredValue = true;
              }
              validRecords = validRecords && isValid;
            }
          }
        }
        if (cellFilter) {
          if (typeof record.cell === 'string' && record.cell && record.cell.length > 0) {
            if (record.cell.toLowerCase) {
              const isValid = record.cell?.toLowerCase().indexOf((filterValues.cell as string).toLowerCase()) > -1;
              if (isValid) {
                atLeastOneFilteredValue = true;
              }
              validRecords = validRecords && isValid;
            }
          }
        }
        if (emailFilter) {
          if (typeof record.email === 'string' && record.email && record.email.length > 0) {
            if (record.email.toLowerCase) {
              const isValid = record.email?.toLowerCase().indexOf((filterValues.email as string).toLowerCase()) > -1;
              if (isValid) {
                atLeastOneFilteredValue = true;
              }
              validRecords = validRecords && isValid;
            }
          }
        }
        if (areaFilter) {
          if (record.areas && record.areas.length > 0) {
            const isValid = filterValues.area.some((area) =>
              record.areas.some((recordArea: string) => {
                return area === recordArea;
              })
            );
            if (isValid) {
              atLeastOneFilteredValue = true;
            }
            validRecords = validRecords && isValid;
          }
        }
        if (regionFilter) {
          if (record.region && record.region.length > 0) {
            const isValid = filterValues.region.some((regionNameFilterValues) => regionNameFilterValues === record.region);
            if (isValid) {
              atLeastOneFilteredValue = true;
            }
            validRecords = validRecords && isValid;
          }
        }
        if (validRecords && atLeastOneFilteredValue) {
          filteredCacheArray.push(record);
        }
      }
      return filteredCacheArray;
    }
    return filteredDataCache;
  };

  const createFilterData = (dataUpToDate: HuquqResponse[]) => {
    const { regionSet, areasSet } = createFilters(dataUpToDate);
    const createOrUpdateNameFilter = (filterArray: FieldFormFilter[]) => {
      const index = filterArray.findIndex((a) => a.filterFieldName === 'name');
      const actualValues: FieldFormFilter = {
        filterFieldName: 'name',
        filterLabel: 'Full Name',
        type: FieldFormFilterType.text,
      };
      if (index < 0) {
        filterArray.push(actualValues);
      } else {
        filterArray[index] = actualValues;
      }
      return filterArray;
    };
    const createOrUpdateCellFilter = (filterArray: FieldFormFilter[]) => {
      const index = filterArray.findIndex((a) => a.filterFieldName === 'cell');
      const actualValues: FieldFormFilter = {
        filterFieldName: 'cell',
        filterLabel: 'Phone',
        type: FieldFormFilterType.text,
      };
      if (index < 0) {
        filterArray.push(actualValues);
      } else {
        filterArray[index] = actualValues;
      }
      return filterArray;
    };
    const createOrUpdateEmailFilter = (filterArray: FieldFormFilter[]) => {
      const index = filterArray.findIndex((a) => a.filterFieldName === 'email');
      const actualValues: FieldFormFilter = {
        filterFieldName: 'email',
        filterLabel: 'Email',
        type: FieldFormFilterType.text,
      };
      if (index < 0) {
        filterArray.push(actualValues);
      } else {
        filterArray[index] = actualValues;
      }
      return filterArray;
    };
    const createOrUpdateAreaFilter = (filterArray: FieldFormFilter[], areasSet: Set<string>) => {
      const index = filterArray.findIndex((a) => a.filterFieldName === 'area');
      const actualValues: FieldFormFilter = {
        filterFieldName: 'area',
        filterLabel: 'Coverage Area',
        type: FieldFormFilterType.select,
        select: {
          filterOptions: Array.from(areasSet).sort(),
        },
      };
      if (index < 0) {
        filterArray.push(actualValues);
      } else {
        filterArray[index] = actualValues;
      }
      return filterArray;
    };
    const createOrUpdateRegionFilter = (filterArray: FieldFormFilter[], regionSet: Set<string>) => {
      const index = filterArray.findIndex((a) => a.filterFieldName === 'region');
      const actualValues: FieldFormFilter = {
        filterFieldName: 'region',
        filterLabel: 'Region',
        type: FieldFormFilterType.select,
        select: {
          filterOptions: Array.from(regionSet).sort(),
        },
      };
      if (index < 0) {
        filterArray.push(actualValues);
      } else {
        filterArray[index] = actualValues;
      }
      return filterArray;
    };

    setFilterFieldsArray((filterArray) => {
      filterArray = createOrUpdateRegionFilter(filterArray, regionSet);
      filterArray = createOrUpdateAreaFilter(filterArray, areasSet);
      filterArray = createOrUpdateNameFilter(filterArray);
      filterArray = createOrUpdateCellFilter(filterArray);
      filterArray = createOrUpdateEmailFilter(filterArray);
      return filterArray;
    });
  };

  return (
    <div>
      <div className="center">
        <img src={HuquqTopImg} alt="The Deputy Trustees of Ḥuqúqu’lláh in the United States" className="wider full-width-view" />
      </div>
      <h1 className="bottom-padding">Ḥuqúqu’lláh Representatives</h1>
      <div data-cy="displaydirectory" className="small-top-margin">
        <p>
          <Link to="huququllah-trustees">Directory of Deputy Trustees of Ḥuqúqu’lláh</Link>
        </p>
        <p>
          <Link to="/huququllah-resources">Ḥuqúqu’lláh Information</Link>
        </p>
        <p>
          <Link to="/faq/directories/how-do-i-search-the-directories">How do I search and filter in this directory?</Link>
        </p>
      </div>
      <Drawer anchor="right" open={filtersOpened} onClose={() => setFiltersOpened(!filtersOpened)} keepMounted={true}>
        <div>
          <h1 className="cluster-contact-title">Ḥuqúqu’lláh Representatives</h1>
          {filterComponent}
        </div>
      </Drawer>
      <Row>
        <Col lgOffset={9} lg={3} md={4} mdOffset={8} sm={12}>
          <div className="spaced">
            <button
              className="primary-button  w-100"
              data-cy={'open-filters'}
              onClick={(e) => {
                e.preventDefault();
                setFiltersOpened(!filtersOpened);
              }}
            >
              Open filters
            </button>
          </div>
        </Col>
      </Row>
      <div className="show-table">
        <p>You can search and filter the data:</p>
        <div data-cy="displaydirectory">
          <HuquqRepTable defaultmessage={defaultmessage} columns={columns} filteredData={filteredData}></HuquqRepTable>
        </div>
      </div>
      <div className="hide-table">
        <p>You can search and filter the data:</p>
        <HuquqRepMobileTable filteredData={filteredData} defaultmessage={defaultmessage} columns={columns}></HuquqRepMobileTable>
      </div>
    </div>
  );
};

export default withRouter(withContext(HuquqRep));
