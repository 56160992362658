import React, { useState, useEffect, useCallback } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import PhysicalAddress from './PhysicalAddress';
import EditMailingAddress, { validationStateSchema as EditMailingAddressValidationSchema } from './EditMailingAddress';
import EditMyProfile, { validationStateSchema as EditMyProfileValidationSchema } from './EditMyProfile';
import EditEmergencyContact, { validationStateSchema as EditEmergenctContactValidationSchema } from './EditEmergencyContact';
import EditContactPreferences, {
  validationStateSchema as EditContactPreferencesValidationSchema,
} from './EditContactPreferences';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';
import useForm from '../Utilities/useForm';
import { of, throwError, combineLatest } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CircularProgress, MenuItem } from '@material-ui/core';
import Emails from './Emails';
import { keyValuesToMenuItems } from '../Utilities/UtilityFunctions';
import { PropertyAccessor } from '../Shared/Types/PropertyAccessor';

const allValidationSchemas = {
  ...EditMyProfileValidationSchema,
  ...EditMailingAddressValidationSchema,
  ...EditEmergenctContactValidationSchema,
  ...EditContactPreferencesValidationSchema,
};
const ValidationSchemasWithoutMailingAddress = {
  ...EditMyProfileValidationSchema,
  ...EditEmergenctContactValidationSchema,
  ...EditContactPreferencesValidationSchema,
};

export interface BaseMyProfileState {
  details: PropertyAccessor;
  handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement | { name?: string | undefined; value: unknown }>;
}

const EditContactInfo = (props: PropertyAccessor) => {
  const setPageTitle = props.setPageTitle;
  useEffect(() => setPageTitle('Edit Contact Information'), [setPageTitle]);
  const mailingAddressProxy = props.context.mailingAddressProxy;
  const emergencyContactProxy = props.context.emergencyContactProxy;
  const myProfileProxy = props.context.myProfileProxy;
  const emailProxy = props.context.emailProxy;
  const editContactInformationSuccessMessage = props.context.editContactInformationSuccessMessage;
  const setEditContactInformationSuccessMessage = props.context.setEditContactInformationSuccessMessage;
  const editContactInformationNeutralMessage = props.context.editContactInformationNeutralMessage;
  const setEditContactInformationNeutralMessage = props.context.setEditContactInformationNeutralMessage;
  const personId = '';

  const [showCircularProgress, setShowCircularProgress] = useState(true);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorSuccessMessage, setErrorSuccessMessage] = useState<JSX.Element | string>('');
  const [ckbMPAddressDiff, setCkbMPAddressDiff] = useState<any>({});
  const [isMailingAddressUnverified, setIsMailingAddressUnverified] = useState(false);
  const successSubmitMessage = 'Successfully Updated!';
  const [contactInfoData, setContactInfo] = useState<any>([]);
  const [emailState, setEmailState] = useState({
    alternateEmail: '',
    primaryEmail: '',
    securityEmail: '',
  });
  const [staticState, setStaticState] = useState({
    title: [
      <MenuItem selected disabled key="1">
        Loading...
      </MenuItem>,
    ],
    suffix: [
      <MenuItem selected disabled key="1">
        Loading...
      </MenuItem>,
    ],
  });
  const checkHighlightedFields = 'Please check the highlighted field(s) and resolve the error(s). ';

  useEffect(() => {
    if (editContactInformationSuccessMessage) {
      setErrorSuccessMessage(
        <p data-cy="success-message" className="success-message wider">
          {editContactInformationSuccessMessage}
        </p>
      );
      setEditContactInformationSuccessMessage(undefined);
    }
  }, [editContactInformationSuccessMessage, setEditContactInformationSuccessMessage]);

  useEffect(() => {
    if (editContactInformationNeutralMessage) {
      setErrorSuccessMessage(
        <p data-cy="neutral-message" className="neutral-message wider">
          {editContactInformationNeutralMessage}
        </p>
      );
      setEditContactInformationNeutralMessage(undefined);
    }
  }, [editContactInformationNeutralMessage, setEditContactInformationNeutralMessage]);

  const onSubmitForm = useCallback(
    (state: PropertyAccessor, processErrors: Function) => {
      const updatedMyProfileData = {
        ...contactInfoData,
        firstName: state.firstName.value,
        middleName: state.middleName.value,
        lastName: state.lastName.value,
        cellPhone: state.cellPhone.value,
        homePhone: state.homePhone.value,
        workPhone: state.workPhone.value,

        title: state.title.value,
        suffix: state.suffix.value,
        nickName: state.nickName.value,

        languagePreference: state.languagePreference.value,
        languagePreferenceOptions: state.languagePreferenceOptions.vale,
        receiveNoCalls: state.receiveNoCalls.value,
        receiveNoMail: state.receiveNoMail.value,
        receiveTab: state.receiveTab.value,
      };

      const updatedMailingAddressData = {
        ...contactInfoData,
        personId: personId,
        careOf: state.mailingAddressCareOf.value,
        line1: state.mailingAddressLine1.value,
        line2: state.mailingAddressLine2.value,
        line3: state.mailingAddressLine3.value,
        mailingAddressLine1: state.mailingAddressLine1.value,
        mailingAddressLine2: state.mailingAddressLine2.value,
        mailingAddressLine3: state.mailingAddressLine3.value,
        city: state.mailingAddressCity.value,
        state: state.mailingAddressState.value,
        stateOptions: state.stateOptions.value,
        zipCode: state.mailingAddressZipCode.value,
        AddressType: 'domestic',
        toScrub: !state.mailingAddressContinueWithUnverifiedAddress.value,
      };
      const mailingAddressChanged = !(
        updatedMailingAddressData.careOf === contactInfoData.mailingAddressCareOf &&
        updatedMailingAddressData.line1 === contactInfoData.mailingAddressLine1 &&
        updatedMailingAddressData.line2 === contactInfoData.mailingAddressLine2 &&
        updatedMailingAddressData.line3 === contactInfoData.mailingAddressLine3 &&
        updatedMailingAddressData.mailingAddressLine1 === contactInfoData.mailingAddressLine1 &&
        updatedMailingAddressData.mailingAddressLine2 === contactInfoData.mailingAddressLine2 &&
        updatedMailingAddressData.mailingAddressLine3 === contactInfoData.mailingAddressLine3 &&
        updatedMailingAddressData.city === contactInfoData.mailingAddressCity &&
        updatedMailingAddressData.state === contactInfoData.mailingAddressState &&
        updatedMailingAddressData.zipCode === contactInfoData.mailingAddressZipCode
      );

      const updatedEmergencyContactData = {
        ...contactInfoData,
        emergencyContactEmail: state.emergencyContactEmail.value,
        emergencyContactName: state.emergencyContactName.value,
        emergencyContactPhone: state.emergencyContactPhone.value,
        emergencyContactRelationship: state.emergencyContactRelationship.value,
      };

      const mailingAddressObservable = ckbMPAddressDiff.ckbMPAddressDiff
        ? mailingAddressChanged
          ? mailingAddressProxy.set(updatedMailingAddressData)
          : of(undefined)
        : mailingAddressProxy.remove();

      setErrorSuccessMessage('');

      const mailingAddressHandledObservable = mailingAddressObservable.pipe(
        catchError((err) => {
          if (err.response?.status === 400) {
            const unprocessedErrors = processErrors(err.response.data);
            if (unprocessedErrors === 'Address cannot be verified.') {
              setIsMailingAddressUnverified(true);
              setErrorSuccessMessage(
                <p className="error-message wider" data-cy="unsuccess-message">
                  {checkHighlightedFields}
                </p>
              );
            } else {
              const newMessage = `${checkHighlightedFields}${unprocessedErrors}`;
              setErrorSuccessMessage(
                <p className="error-message wider" data-cy="unsuccess-message">
                  {newMessage}
                </p>
              );
            }
          } else {
            setShowErrorMessage(true);
          }
          return throwError(err);
        })
      );

      const emergencyContactObservable = emergencyContactProxy.set(updatedEmergencyContactData).pipe(
        catchError((err) => {
          if (err.response?.status === 400) {
            const unprocessedErrors = processErrors(err.response.data);
            const newMessage = `${checkHighlightedFields}${unprocessedErrors}`;
            setErrorSuccessMessage(
              <p className="error-message wider" data-cy="unsuccess-message">
                {newMessage}
              </p>
            );
          } else {
            setShowErrorMessage(true);
          }
          return throwError(err);
        })
      );

      const myProfileProxySetObservable = myProfileProxy.set(updatedMyProfileData).pipe(
        catchError((err) => {
          if (err.response?.status === 400) {
            const unprocessedErrors = processErrors(err.response.data);
            const newMessage = `${checkHighlightedFields}${unprocessedErrors}`;
            setErrorSuccessMessage(
              <p className="error-message wider" data-cy="unsuccess-message">
                {newMessage}
              </p>
            );
          } else {
            setShowErrorMessage(true);
          }
          return throwError(err);
        })
      );

      const callMailingThenEmergencyThenMyProfile = combineLatest([
        mailingAddressHandledObservable,
        emergencyContactObservable,
        myProfileProxySetObservable,
      ]);

      setShowCircularProgress(true);
      const callMailingThenEmergencyThenMyProfileSubscription = callMailingThenEmergencyThenMyProfile.subscribe({
        next: () => {
          setIsMailingAddressUnverified(false);
          setShowCircularProgress(false);
          const message = (
            <p data-cy="success-message" className="success-message wider">
              {successSubmitMessage}
            </p>
          );
          if (props?.inputProps?.setErrorSuccess) {
            props.inputProps.setErrorSuccess(message);
            props.history.push('/myprofile/contactinfo');
          } else {
            setErrorSuccessMessage(message);
          }
        },
        error: () => setShowCircularProgress(false),
      });

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      return () => {
        if (callMailingThenEmergencyThenMyProfileSubscription) {
          callMailingThenEmergencyThenMyProfileSubscription.unsubscribe();
        }
      };
    },
    [ckbMPAddressDiff, myProfileProxy, emergencyContactProxy, mailingAddressProxy, contactInfoData]
  );

  const { setState, state, handleValidationOnChange, handleOnSubmit, disable, isSubmitCalled, isDirty } = useForm(
    undefined,
    ckbMPAddressDiff.ckbMPAddressDiff === false || ckbMPAddressDiff.ckbMPAddressDiff === undefined
      ? ValidationSchemasWithoutMailingAddress
      : allValidationSchemas,
    onSubmitForm
  );

  useEffect(() => {
    const myProfileProxyGetObservable = myProfileProxy.get();
    const emailProxyGetObservable = emailProxy.get();

    const combinedObservables = combineLatest([myProfileProxyGetObservable, emailProxyGetObservable]);

    const subscription = combinedObservables.subscribe({
      next: (combinedResponses: PropertyAccessor[]) => {
        const myProfileResponse = combinedResponses[0];
        const emailResponse = combinedResponses[1].data;

        const titles = myProfileResponse.titleOptions.map(keyValuesToMenuItems);
        const suffixes = myProfileResponse.suffixOptions.map(keyValuesToMenuItems);
        setStaticState({
          title: titles,
          suffix: suffixes,
        });
        setContactInfo(myProfileResponse);
        setState({
          firstName: { value: myProfileResponse.firstName, error: '' },
          middleName: { value: myProfileResponse.middleName, error: '' },
          lastName: { value: myProfileResponse.lastName, error: '' },
          cellPhone: { value: myProfileResponse.cellPhone, error: '' },
          homePhone: { value: myProfileResponse.homePhone, error: '' },
          workPhone: { value: myProfileResponse.workPhone, error: '' },

          title: { value: myProfileResponse.title ?? '', error: '' },
          suffix: { value: myProfileResponse.suffix ?? '', error: '' },
          nickName: { value: myProfileResponse.nickName ?? '', error: '' },

          mailingAddressCareOf: {
            value: myProfileResponse.mailingAddressCareOf,
            error: '',
          },
          mailingAddressLine1: {
            value: myProfileResponse.mailingAddressLine1,
            error: '',
          },
          mailingAddressLine2: {
            value: myProfileResponse.mailingAddressLine2,
            error: '',
          },
          mailingAddressLine3: {
            value: myProfileResponse.mailingAddressLine3,
            error: '',
          },
          mailingAddressCity: {
            value: myProfileResponse.mailingAddressCity,
            error: '',
          },
          mailingAddressState: {
            value: myProfileResponse.mailingAddressState,
            error: '',
          },
          mailingAddressZipCode: {
            value: myProfileResponse.mailingAddressZipCode,
            error: '',
          },
          mailingAddressContinueWithUnverifiedAddress: {
            value: false,
            error: '',
          },

          physicalAddressCareOf: {
            value: myProfileResponse.physicalAddressCareOf,
            error: '',
          },
          stateOptions: { value: myProfileResponse.stateOptions, error: '' },
          physicalAddressCity: {
            value: myProfileResponse.physicalAddressCity,
            error: '',
          },
          physicalAddressLine1: {
            value: myProfileResponse.physicalAddressLine1,
            error: '',
          },
          physicalAddressLine2: {
            value: myProfileResponse.physicalAddressLine2,
            error: '',
          },
          physicalAddressLine3: {
            value: myProfileResponse.physicalAddressLine3,
            error: '',
          },
          physicalAddressState: {
            value: myProfileResponse.physicalAddressState,
            error: '',
          },
          physicalAddressType: {
            value: myProfileResponse.physicalAddressType,
            error: '',
          },
          physicalAddressZipCode: {
            value: myProfileResponse.physicalAddressZipCode,
            error: '',
          },

          emergencyContactName: {
            value: myProfileResponse.emergencyContactName,
            error: '',
          },
          emergencyContactRelationship: {
            value: myProfileResponse.emergencyContactRelationship,
            error: '',
          },
          emergencyContactPhone: {
            value: myProfileResponse.emergencyContactPhone,
            error: '',
          },
          emergencyContactEmail: {
            value: myProfileResponse.emergencyContactEmail,
            error: '',
          },
          modDate: { value: myProfileResponse.modDate, error: '' },
          ckbMPAddressDiff: {
            value: !!myProfileResponse.mailingAddressId,
            error: '',
          },

          languagePreference: {
            value: myProfileResponse.languagePreference,
            error: '',
          },
          languagePreferenceOptions: {
            value: myProfileResponse.languagePreferenceOptions,
            error: '',
          },
          receiveNoCalls: {
            value: myProfileResponse.receiveNoCalls,
            error: '',
          },
          receiveNoMail: { value: myProfileResponse.receiveNoMail, error: '' },
          receiveTab: { value: myProfileResponse.receiveTab, error: '' },
        });
        setEmailState({
          alternateEmail: emailResponse.alternateEmail,
          primaryEmail: emailResponse.primaryEmail,
          securityEmail: emailResponse.securityEmail,
        });
        setShowCircularProgress(false);
        setCkbMPAddressDiff(!!myProfileResponse.mailingAddressId);
      },
      error: () => setShowErrorMessage(true),
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [myProfileProxy, setShowErrorMessage, setState, setShowCircularProgress, emailProxy]);

  function handleCheckBoxChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newState = { [e.target.name]: e.target.checked };
    setCkbMPAddressDiff(newState);
  }

  if (ckbMPAddressDiff.ckbMPAddressDiff === undefined && state)
    setCkbMPAddressDiff({ ckbMPAddressDiff: state.ckbMPAddressDiff.value });

  useEffect(() => {
    if ((isSubmitCalled === false && disable) || isSubmitCalled === false) {
      setErrorSuccessMessage(
        <p data-cy="unsuccess-message" className="error-message wider">
          Please check the highlighted field(s) and resolve the error(s).
        </p>
      );
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [isSubmitCalled, disable, isDirty]);

  return (
    <div>
      {showErrorMessage ? (
        <ErrorMessageToUser />
      ) : (
        <div>
          {showCircularProgress ? <CircularProgress className="loading-animation" size={40} /> : null}
          {errorSuccessMessage}
          {state ? (
            <div className="profile-box">
              <EditMyProfile details={state} handleChange={handleValidationOnChange} staticState={staticState} />
              <hr />
              <Emails details={emailState} />
              <hr />
              <PhysicalAddress details={state} />
              {props.context.physicalAddressMessage}
              <label aria-label="Mailing address different from residential" className="medium-left-padding small-top-padding">
                <input
                  id="ckbMPAddressDiff"
                  name="ckbMPAddressDiff"
                  type="checkbox"
                  data-cy="differentMPAddress"
                  checked={
                    ckbMPAddressDiff.ckbMPAddressDiff === undefined
                      ? state.ckbMPAddressDiff.value
                      : ckbMPAddressDiff.ckbMPAddressDiff
                  }
                  onChange={handleCheckBoxChange}
                ></input>{' '}
                Mailing Address is different from Current Residential Address
              </label>
              {ckbMPAddressDiff.ckbMPAddressDiff ||
              (state.ckbMPAddressDiff.value && ckbMPAddressDiff.ckbMPAddressDiff === undefined) ? (
                <EditMailingAddress
                  details={state}
                  disable={disable}
                  handleChange={handleValidationOnChange}
                  isMailingAddressUnverified={isMailingAddressUnverified}
                />
              ) : null}
              <hr />
              <EditEmergencyContact details={state} handleChange={handleValidationOnChange} />
              <hr />
              <EditContactPreferences details={state} handleChange={handleValidationOnChange} />
            </div>
          ) : null}
          <div className="medium-top-margin end">
            <input
              type="submit"
              value="Save"
              className="primary-button"
              data-cy="inputsubmit"
              onClick={(e) => {
                handleOnSubmit(e);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default withContext(EditContactInfo);
