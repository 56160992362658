import React from 'react';
import InternationalResidentialAddress from './InternationalResidentialAddress';
import DomesticResidentialAddress from './DomesticResidentialAddress';

const ResidentialAddressComp = ({ details }) => {
  return (
    <div className="profile-background">
      {/* Since this component is common between Overseas and US citizens residentials we need this following condition */}

      {(details.mailingAddressZipCode && details.physicalAddressType === 'Domestic') ||
      details.isMailingAddressDifferent ||
      details.institutionalMailingAddress ? (
        <h4>Current Residential Address</h4>
      ) : (
        <h4>Residential &amp; Mailing Address</h4>
      )}
      {details.physicalAddressType === 'Domestic' ? (
        <DomesticResidentialAddress details={details} />
      ) : (
        <InternationalResidentialAddress details={details} />
      )}
    </div>
  );
};

export default ResidentialAddressComp;
