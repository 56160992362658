import React from 'react';
import CreateAccount from '../Utilities/CreateAccount';
import createAppToken from '../Utilities/AppTokenEndpoint';
import setupAccount from '../Utilities/SetupAccountEndpoint';
import withContext from '../../ContextAPI/Context_HOC';
import SafeStringify from '../../Utilities/SafeStringify';
import { withOptimizely } from '@optimizely/react-sdk';
import CreateBOSBaseStep, { Step } from './CreateBOSBaseStep';
import CreateBOSHeader from './CreateBOSHeader';

class CreateBOSStep3 extends CreateBOSBaseStep {
  constructor(props: any) {
    super(props);
    this._step = Step.SecurityQuestions;
    this.handleChange = this.handleChange.bind(this);
    this.submitSetupAcc = this.submitSetupAcc.bind(this);
  }

  submitSetupAcc(e: any) {
    e.preventDefault();
    let userinput = {
      email: this.state.email,
      confirmEmail: this.state.confirmEmail,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      question1Id: this.state.questionOne,
      question1Answer: this.state.questionOneAns,
      question2Id: this.state.questionTwo,
      question2Answer: this.state.questionTwoAns,
      question3Id: this.state.questionThree,
      question3Answer: this.state.questionThreeAns,
    };

    createAppToken()
      .then((res: any) => {
        setupAccount(res.data, this.state.bid, this.state.accountIdn, userinput, this.state.temporaryCode)
          .then(async (res) => {
            this.saveStateToStorage({
              displayMessage: (
                <p className="center success-message">
                  {res.data?.message ? SafeStringify(res.data.message) : SafeStringify(res.data)}
                </p>
              ),
              success: true,
            });
            this.clearStorage();
          })
          .catch((err) => {
            this.saveStateToStorage({
              displayMessage: <p className="center error-message">{err.message ? SafeStringify(err.message) : null}</p>,
            });
          });
      })
      .catch((err: any) => {
        this.saveStateToStorage({
          displayMessage: <p className="center error-message">{err.message ? SafeStringify(err.message) : null}</p>,
        });
      });
  }

  render() {
    return (
      <div className="center">
        <CreateBOSHeader></CreateBOSHeader>
        <CreateAccount
          displayMessage={this.state.displayMessage}
          success={this.state.success}
          questiondata={this.state.securityQuestions}
          handleChange={this.handleChange}
          handleSubmit={this.submitSetupAcc}
          emailValue={this.state.email}
          emailname="email"
          confirmEmailValue={this.state.confirmEmail}
          confirmEmailname="confirmEmail"
          passwordValue={this.state.password}
          passwordname="password"
          confirmPasswordValue={this.state.confirmPassword}
          confirmPasswordname="confirmPassword"
          questionOneValue={this.state.questionOne}
          questionOneName={'questionOne'}
          questionTwoValue={this.state.questionTwo}
          questionTwoName={'questionTwo'}
          questionThreeValue={this.state.questionThree}
          questionThreeName={'questionThree'}
          questionOneAns={this.state.questionOneAns}
          questionOneAnsName={'questionOneAns'}
          questionTwoAns={this.state.questionTwoAns}
          questionTwoAnsName={'questionTwoAns'}
          questionThreeAns={this.state.questionThreeAns}
          questionThreeAnsName={'questionThreeAns'}
          history={this.props.history}
          cancelHandler={this.cancel}
        />
      </div>
    );
  }
}
export default withContext(withOptimizely(CreateBOSStep3));
