import React from 'react';

function RBCelections() {
  return (
    <div>
      <h1>Regional Bahá’í Council Elections</h1>
      <br />
      <h3>Letters from the National Spiritual Assembly</h3>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/20240711+All+LSAs+-+2024+Casting+of+Ballots+for+Regional+Baha%CC%81%E2%80%99i%CC%81+Councils.pdf">
            2024 Casting of Ballots for Regional Bahá’í Councils (PDF)
          </a>
        </li>
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/20240916+All+LSAs+-+NSA+on+participation+in+RBC+balloting+process+(English).pdf">
            National Spiritual Assembly on Participation in Regional Bahá’í Council Balloting Process (English) (PDF)
          </a>
        </li>
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/20240916+All+LSAs+-+NSA+on+participation+in+RBC+balloting+process+(Persian).pdf">
            National Spiritual Assembly on Participation in Regional Bahá’í Council Balloting Process (Persian) (PDF)
          </a>
        </li>
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/20240916+All+LSAs+-+NSA+on+participation+in+RBC+balloting+process+(Spanish).pdf">
            National Spiritual Assembly on Participation in Regional Bahá’í Council Balloting Process (Spanish) (PDF)
          </a>
        </li>
      </ul>
      <hr />
      <h3>Ballots</h3>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2022/Ballot-For-Councils-with-9-members-(English).pdf">
            Ballot – For Councils with 9 Members (English) (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2022/Ballot-For-Councils-with-9-members-(Persian).pdf">
            Ballot – For Councils with 9 Members (Persian) (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2022/Ballot-For-Councils-with-7-members-(English).pdf">
            Ballot – For Councils with 7 Members (English) (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2022/Ballot-For-Councils-with-7-members-(Persian).pdf">
            Ballot – For Councils with 7 Members (Persian) (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2022/Ballot-For-Councils-with-5-members-(English).pdf">
            Ballot – For Councils with 5 Members (English) (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2022/Ballot-For-Councils-with-5-members-(Persian).pdf">
            Ballot – For Councils with 5 Members (Persian) (PDF)
          </a>
        </li>
      </ul>
      <hr />
      <h3>Voter Instructions</h3>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/2024+RBC+Election+Voting+Instructions+(English).pdf">
            Voter Instructions (English) (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/2024+RBC+Election+Voting+Instructions+(Persian).pdf">
            Voter Instructions (Persian) (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/2024+RBC+Election+Voting+Instructions+(Spanish).pdf">
            Voter Instructions (Spanish) (PDF)
          </a>
        </li>
      </ul>
      <hr />
      <h3>Election Call Letters</h3>
      <ul className="plain-list">
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/RBC+AT+2024+Election+Call+letter.pdf">
            Letter from the Atlantic RBC (PDF)
          </a>
        </li>
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/RBC+AP+2024+Election+Call+letter.pdf">
            Letter from the Appalachia RBC (PDF)
          </a>
        </li>
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/RBC+CA+2024+Election+Call+letter.pdf">
            Letter from the California RBC (PDF)
          </a>
        </li>
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/RBC+FC+2024+Election+Call+letter.pdf">
            Letter from the Four Corners RBC (PDF)
          </a>
        </li>
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/RBC+FL+2024+Election+Call+letter.pdf">
            Letter from the Florida RBC (PDF)
          </a>
        </li>
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/RBC+HL+2024+Election+Call+letter.pdf">
            Letter from the Heartland RBC (PDF)
          </a>
        </li>
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/RBC+MW+2024+Election+Call+letter.pdf">
            Letter from the Midwest RBC (PDF)
          </a>
        </li>
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/RBC+NE+2024+Election+Call+letter.pdf">
            Letter from the Northeast RBC (PDF)
          </a>
        </li>
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/RBC+NP+2024+Election+Call+letter.pdf">
            Letter from the Northern Plains RBC (PDF)
          </a>
        </li>
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/RBC+NW+2024+Election+Call+letter+and+selected+passages.pdf">
            Letter from the Northwest RBC (PDF)
          </a>
        </li>
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/RBC+PS+2024+Election+Call+letter.pdf">
            Letter from the Prairie States RBC (PDF)
          </a>
        </li>
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/RBC+SC+2024+Election+Call+letter.pdf">
            Letter from the South Central RBC (PDF)
          </a>
        </li>
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/RBC+SE+2024+Election+Call+letter.pdf">
            Letter from the Southeast RBC (PDF)
          </a>
        </li>
      </ul>
      <hr />
      <h3>Guidance</h3>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2022/2022-Compilation-of-Guidance-The-Nature-of-Bahai-Elections-(English).pdf">
            Compilation of Guidance on the Nature of Bahá’í Elections (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2022/2022-Compilation-of-Guidance-Functions-of-RBCs-(English).pdf">
            Compilation of Guidance on the Functions of RBCs (English) (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2022/2022-Compilation-of-Guidance-Functions-of-RBCs-(Persian).pdf">
            Compilation of Guidance on the Functions of RBCs (Persian) (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2022/2022-Compilation-of-Guidance-Functions-of-RBCs-(Spanish).pdf">
            Compilation of Guidance on the Functions of RBCs (Spanish) (PDF)
          </a>
        </li>
      </ul>
      <hr />
      <h3 id="RBCbyElections">Reporting By-elections</h3>
      <ul className="plain-list">
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/20240911+NSA+to+all+LSAs+-+Reporting+by-elections.pdf">
            11 Sep 2024 Letter from the NSA to all LSAs about reporting by-elections (PDF)
          </a>
        </li>
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/Instructions+on+reporting+by-elections.pdf">
            Reporting By-elections of Local Spiritual Assemblies <em>updated September 2024</em> (PDF)
          </a>
        </li>
        <li>
          <strong>Download:</strong>{' '}
          <a href="https://bahai-web.s3.amazonaws.com/RBC_elections/2024/By-Election+form.pdf">By-election form (PDF)</a>
        </li>
      </ul>
      <hr />
      <h3>National Spiritual Assembly’s announcement of the first elections for the Regional Bahá’í Councils</h3>
      <ul className="plain-list">
        <li>
          These new institutions, assigned to the four regions designated by ‘Abdu’l-Bahá in the Tablets of the Divine Plan, will
          serve as powerful instruments for diffusing the spirit of the Faith and “catering to the requirements of spiritual
          relationships which flow from love and unity among the friends.”
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/regions/11231997-nsa-on-election.pdf">Letter (PDF)</a>
        </li>
      </ul>
      <hr />
      <h3>Universal House of Justice message calling into being the Regional Councils</h3>
      <ul className="plain-list">
        <li>
          The expansion of the Bahá’í community and the growing complexity of the issues which are facing National Spiritual
          Assemblies in certain countries have brought the Cause to a new stage in its development. They have caused us in recent
          years to examine various aspects of the balance between centralization and decentralization.
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/regions/05301997-uhj-on-regions.pdf">Letter (PDF)</a>
        </li>
      </ul>
      <hr />
      <h3>Characteristics and functions of Regional Bahá’í Councils</h3>
      <ul className="plain-list">
        <li>
          Regional Bahá’í Councils are not necessarily established universally throughout a country, but rather in those regions
          where the condition and size of the Bahá’í community indicate that such a development would be beneficial.
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/regions/05301997-uhj-regional-characteristics.pdf">Letter (PDF)</a>
        </li>
      </ul>
    </div>
  );
}

export default RBCelections;
