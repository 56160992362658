import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import withContext from '../ContextAPI/Context_HOC';
import ContentProviderEndpoint from '../Content/ContentProviderEndpoint';
import { ContentItem, SortOrder } from '@kentico/kontent-delivery';
import { Subscription } from 'rxjs';
import { PropertyAccessor } from '../Shared/Types/PropertyAccessor';
import { ParseHtml, guidanceRef } from './Utilities/HtmlParser';
import ContentResolver, { ContentProjectEnum } from '../Content/ContentResolver';

interface NineYearPlanProps {
  context: {
    digitalDesignContent: ContentProviderEndpoint;
    dateOptions: Intl.DateTimeFormatOptions;
    contentResolver: ContentResolver;
  };
}

class NineYearPlan extends Component {
  subscription: Subscription;
  props: NineYearPlanProps;
  state: {
    title?: string;
    bodyDisplay: JSX.Element[];
    linkDisplay: JSX.Element[];
  };
  constructor(props: NineYearPlanProps) {
    super(props);
    this.state = { bodyDisplay: [], linkDisplay: [] };
  }

  componentDidMount() {
    this.subscription = this.props.context.digitalDesignContent
      .items()
      .types(['special_content'])
      .equalsFilter('system.codename', 'nine_year_plan')
      .orderParameter('elements.base__datepublished', SortOrder.desc)
      .toObservable()
      .subscribe(async (response) => {
        if (!response.isEmpty && response.firstItem) {
          let links: JSX.Element[] = [];
          let count = 0;
          const contentItem = response.firstItem;
          if (!contentItem) return;

          const allLinks = contentItem.base__body.links.map((link: { codename: any; linkId: any }) => {
            return { codename: link.codename, linkId: link.linkId };
          });
          const allLinkCodenamesLength = allLinks.length;
          const tempLinkData: PropertyAccessor = {};
          if (allLinkCodenamesLength) {
            for (let i = 0; i < allLinkCodenamesLength; i++) {
              const currentLink = allLinks[i];
              const resolvedUrl = await this.props.context.contentResolver.ResolveUrl(
                ContentProjectEnum.DigitalDesign,
                currentLink.codename,
                undefined
              );
              tempLinkData[currentLink.linkId] = resolvedUrl;
            }
          }
          var baseHTML: string = contentItem.base__body.value.replace('UHJ® page', `<a href="${guidanceRef}">this page</a>`);
          const extraTitle = '<h4>Nine Year Plan</h4>';
          if (baseHTML.startsWith(extraTitle)) {
            baseHTML = baseHTML.replace(extraTitle, '');
          }

          const parsedBody = ParseHtml(baseHTML, tempLinkData);

          contentItem.linked_items.value.forEach((y: ContentItem) => {
            const datestring = y.base__datepublished.rawData.value.substring(0, 4);
            const languagePart = y.system.language !== 'en-US' ? `-${y.system.language}` : '';
            links.push(
              <li className="indented-list-item" key={count}>
                <Link
                  data-cy={`nine_year_plan_link${count}`}
                  to={{
                    pathname: `/guidance/uhj/${y.url_slug.value}-${datestring}${languagePart}`,
                  }}
                >
                  {new Date(y.base__datepublished.value).toLocaleDateString('en-US', this.props.context.dateOptions)}-
                  {y.base__title.value}{' '}
                </Link>
              </li>
            );
            count++;
          });
          this.setState({ bodyDisplay: parsedBody, title: contentItem.base__title.value, linkDisplay: links });
        }
      });
  }
  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  render() {
    return (
      <div>
        <h1 data-testid="title" data-cy="title" className="title">
          {this.state.title ?? ''}
        </h1>
        {this.state.bodyDisplay.map((elem, key) => {
          return (
            <div key={key} data-cy="body-text">
              {elem}
            </div>
          );
        })}
        <ul>{this.state.linkDisplay.length ? this.state.linkDisplay : 'Loading...'}</ul>
      </div>
    );
  }
}

export default withContext(NineYearPlan);
