import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import InternationalMailingAddress from './InternationalMailingAddress';
import DomesticMailingAddress from './DomesticMailingAddress';

const MailingAddressComp = ({ details }) => {
  return (
    <div className="medium-top-padding">
      <h4>Mailing Address</h4>
      {details.institutionalMailingAddress ? (
        <Row className="bottom-padding">
          <Col sm={12}>
            <p className="faded-text small-text">Institutional Mailing Address</p>
            <p>
              <strong>{details.institutionalMailingAddress}</strong>
            </p>
          </Col>
        </Row>
      ) : details.mailingAddressType === 'Domestic' ? (
        <DomesticMailingAddress details={details} />
      ) : (
        <InternationalMailingAddress details={details} />
      )}
    </div>
  );
};

export default MailingAddressComp;
