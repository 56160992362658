import React, { Component } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import EachPrayerDisplay from './EachPrayerDisplay';
import NoMatch from '../Routers/NoMatch';
import SpeechSynthesis from '../PWA/speech-synthesis/SpeechSynthesis';

class PrayerDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calldata: [],
      display: [],
      showPlayer: false,
    };
  }

  componentDidMount() {
    let urlcall = '';
    if (this.props.urlcall === undefined) {
      urlcall = this.props.match.params.urlslugid;
    } else {
      urlcall = this.props.urlcall;
    }
    this.subscription = this.props.context.prayerContent
      .items()
      .type('prayer')
      .equalsFilter('elements.url_slug', urlcall)
      .toObservable()
      .subscribe(async (response) => {
        if (!response.isEmpty) {
          let display = <EachPrayerDisplay details={response.items[0]} />;
          this.setState({ display: display });
          this.setState({ showPlayer: true });
        } else {
          this.setState({ display: <NoMatch /> });
        }
      });
  }
  componentWillUnmount() {
    this.subscription.unsubscribe();
  }
  render() {
    return (
      <>
        {this.state.showPlayer && window.speechSynthesis && (
          <SpeechSynthesis text={document.getElementById('prayerDisplay')?.textContent} />
        )}
        <div id="prayerDisplay">{this.state.display}</div>
      </>
    );
  }
}
export default withContext(PrayerDisplay);
