import React, { useState, useEffect } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import getabmcontact from './Utilities/AbmContactEndpoint';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';

const AuxiliaryBoard = (props) => {
  const blc = props.blc;
  const token = props.token;

  const [errorMessage, setErrorMessage] = useState('');
  const [abmContactData, setAbmContactData] = useState();
  const { defaultmessage } = useState('Currently loading data. Please wait several seconds!');

  const setPageTitle = props.setPageTitle;
  useEffect(() => setPageTitle('Auxiliary Board Members'), [setPageTitle]);
  useEffect(() => {
    const observable = getabmcontact(token, blc);
    const subscription = observable.subscribe({
      next: function (res) {
        setAbmContactData(res);
      },
      error: () => setErrorMessage(<ErrorMessageToUser />),
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [token, blc, setErrorMessage]);

  return (
    <div className="large-top-padding">
      {errorMessage}
      {abmContactData === undefined ? (
        defaultmessage
      ) : (
        <div>
          {abmContactData.map((item, index) => (
            <div key={index}>
              <div className="small-bottom-margin">
                <p>
                  {' '}
                  <strong>{item.areaName}</strong> {'('}{' '}
                  {item.role === 'Auxiliary Board Member for Protection' ? 'Protection' : 'Propagation'} {')'}{' '}
                </p>
              </div>
              <p>
                Phone Number: <a href={'tel:' + item.phoneNumber}>{item.phoneNumber}</a>
              </p>
              <p>
                Email: <a href={'mailto:' + item.email}>{item.email}</a>
              </p>
              {index < abmContactData.length - 1 ? <hr /> : null}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default withContext(AuxiliaryBoard);
