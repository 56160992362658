import { DeliveryClient, ContentItem, MultipleItemQuery, TypeResolver } from '@kentico/kontent-delivery';
import { IHttpService } from '@kentico/kontent-core';
import type AuthService from '../Login/AuthService';
import type ContentProviderEnvironment from './ContentProviderEnvironment';
interface ItemsContentProviderProps {
  draftContent: string;
  content: string;
}
export default class ContentProviderEndpoint {
  authService: AuthService;
  apiUrl: string;
  projectId: string;
  providerEnvironment: ContentProviderEnvironment;
  typeResolvers: TypeResolver[];
  httpServiceForTesting: IHttpService | undefined;

  constructor(
    authService: AuthService,
    apiUrl: string,
    projectId: string,
    providerEnvironment: ContentProviderEnvironment,
    typeResolvers: TypeResolver[],
    httpServiceForTesting?: IHttpService
  ) {
    this.authService = authService;
    this.apiUrl = apiUrl;
    this.projectId = projectId;
    this.providerEnvironment = providerEnvironment;
    this.typeResolvers = typeResolvers;
    this.httpServiceForTesting = httpServiceForTesting;
    this.items = this.items.bind(this);
    this.taxonomy = this.taxonomy.bind(this);
  }

  items<TItem extends ContentItem>(contentUrls?: ItemsContentProviderProps): MultipleItemQuery<TItem> {
    const deliveryClient = this.createDeliveryClient(contentUrls);

    return deliveryClient.items<TItem>();
  }

  item(systemCodeName: string) {
    const deliveryClient = this.createDeliveryClient();
    return deliveryClient.item(systemCodeName);
  }

  taxonomy(codename: string) {
    const deliveryClient = this.createDeliveryClient();
    return deliveryClient.taxonomy(codename);
  }

  createDeliveryClient(contentUrls?: ItemsContentProviderProps) {
    const token = this.authService.getToken();
    if (!token && !contentUrls) {
      throw new Error('Token is NOT available');
    }
    const displayDraftContent = this.providerEnvironment.isPreviewApi();
    let contentPath = '';
    if (contentUrls) {
      contentPath = displayDraftContent ? contentUrls.draftContent : contentUrls.content;
    } else {
      contentPath = displayDraftContent ? '/api/content/preview' : '/api/content/default';
    }
    const baseUrl = this.apiUrl + contentPath;
    const deliveryClient = new DeliveryClient({
      projectId: this.projectId,
      secureApiKey: token ?? '',
      globalQueryConfig: {
        useSecuredMode: true,
      },
      proxy: {
        baseUrl: baseUrl,
      },
      typeResolvers: this.typeResolvers,
      httpService: this.httpServiceForTesting,
    });

    return deliveryClient;
  }
}
