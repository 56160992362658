import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import type { NatconArticle } from '../../Kentico/DigitalDesign/natcon_article';
import withContext from '../../ContextAPI/Context_HOC';
import NatConProxy from '../Utilities/NatConProxy';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';
import NatConCarousel2022 from './NatConCarousel2022';
import { byPinnedThenByDatePublished } from '../Utilities/NatConUtils';

const NatCon2022: FunctionComponent<{
  context: { natConProxy: NatConProxy };
}> = ({ context: { natConProxy } }) => {
  const [natconArticles, setNatconArticles] = useState([] as NatconArticle[]);
  useEffect(() => {
    const natConProxyGetNatConsSubscription = natConProxy.getNatCons('national_convention_2022').subscribe((x) => {
      const articlesSorted = [...x].sort(byPinnedThenByDatePublished);
      setNatconArticles(articlesSorted);
    });
    return () => natConProxyGetNatConsSubscription.unsubscribe();
  }, [natConProxy]);
  return (
    <div>
      <NatConCarousel2022 />
      <h1>114th US Bahá’í National Convention: April 21 – 24, 2022</h1>
      <h4>
        More photos from the Convention available at{' '}
        <a href="https://www.flickr.com/photos/bahaius/collections/72157720600186911/" target="_blank" rel="noopener noreferrer">
          Flickr
        </a>
        .
      </h4>
      <h4>
        Below are stories on the Convention from <em>The American Bahá’í</em>
      </h4>
      <hr />
      <Row>
        <Col sm={12} md={8}>
          {natconArticles.map((article, index) => {
            const introBlurbHtml = article.introBlurb.resolveHtml();
            const intro = parse(introBlurbHtml);
            const thumbnailUrl = article.articleThumbnail?.value?.length > 0 ? article.articleThumbnail.value[0].url : null;
            const thumbnailDescription =
              article.articleThumbnail?.value?.length > 0 ? article.articleThumbnail.value[0].description : '';
            return (
              <Fragment key={index + Date.now() + article.urlSlug.value + article.system.id}>
                <Link
                  to={{ pathname: `/news/natcon-2022/${article.system.codename}` }}
                  className="plain-link plain-hover"
                  data-testid={`natconarticle${index}`}
                >
                  <Row>
                    <Col sm={3}>
                      {thumbnailUrl ? <img src={thumbnailUrl} alt={thumbnailDescription} className="thumbnail-img" /> : null}
                    </Col>
                    <Col sm={9}>
                      <h3>{article.baseTitle.value}</h3>
                      <div>{intro}</div>
                    </Col>
                  </Row>
                </Link>
                <hr />
              </Fragment>
            );
          })}
        </Col>
        <Col sm={12} md={4}>
          <div className="sidebar-box">
            <h4>Secretary’s Report</h4>
            <iframe
              title="Secretary’s Report"
              src="https://player.vimeo.com/video/702556483?h=a038cdc647"
              width="100%"
              height="166"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
            <br />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1257403156&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <p>
              <a href="https://vimeo.com/usbahai/download/702556483/03a598006c" target="_blank" rel="noopener noreferrer">
                Download the video
              </a>
            </p>
            <hr />
            <h4>Treasurer’s Report</h4>
            <iframe
              title="Treasurer’s Report"
              src="https://player.vimeo.com/video/702257856?h=0c0dd100ae"
              width="100%"
              height="166"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
            <p>
              <a href="https://vimeo.com/usbahai/download/702257856/46ae05d679" target="_blank" rel="noopener noreferrer">
                Download the video
              </a>
            </p>
            <hr />
            <h4>Ḥuqúqu’lláh Presentation</h4>
            <iframe
              title="Ḥuqúqu’lláh Report"
              src="https://player.vimeo.com/video/704258078?h=af966267b0"
              width="100%"
              height="166"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
            <hr />
            <h4>Resources</h4>
            <ul className="plain-list">
              <li className="small-bottom-padding">
                <Link to="/nine-year-plan">Nine Year Plan documents</Link>
              </li>
              <li className="small-bottom-padding">
                <Link to="/guidance/uhj/ridvan-2022-message-from-the-supreme-body-2022">
                  Ridvan 2022 Message from the Supreme Body
                </Link>
              </li>
              <li className="small-bottom-padding">
                <a href="https://bahai-web.s3.amazonaws.com/NatCon/2022/2022-Annual-Report.pdf">Ridván 2022 Annual Report</a>
              </li>
              <li>
                <Link to="/guidance/national_spiritual_assembly/letter-from-the-114th-us-baha-i-national-convention-2022/letter-from-the-114th-us-baha-i-national-convention-2022">
                  Letter from the 114th US Bahá’í National Convention
                </Link>
              </li>
              <li className="small-bottom-padding">
                <Link to="/guidance/national_spiritual_assembly/election-of-the-national-spiritual-assembly-2022">
                  Election of the National Spiritual Assembly
                </Link>
              </li>
            </ul>
            <hr />
            <h4>Thursday Consultation</h4>
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1257605635&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <hr />
            <h4>Friday Consultation</h4>
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258821058&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258012570&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258012558&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258821229&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258821253&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258012531&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258012525&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258012513&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258012511&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <hr />
            <h4>Saturday Consultation</h4>
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258025002&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258024984&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258024966&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258823893&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258823812&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258823752&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258024897&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258024888&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258823779&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258024867&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <hr />
            <h4>Sunday Consultation</h4>
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258825945&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258026658&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258026649&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258825978&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1258825963&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <hr />
            <h4>National Convention Coverage Archives</h4>
            <ul className="plain-list">
              <li>
                <Link to="/resources/natcon-2019">National Convention 2019</Link>
              </li>
              <li>
                <Link to="/resources/natcon-2018">National Convention 2018</Link>
              </li>
              <li>
                <Link to="/resources/natcon-2017">National Convention 2017</Link>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default withContext(NatCon2022);
