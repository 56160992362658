import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText, Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';
import withContext from '../ContextAPI/Context_HOC';

class HuquqDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: null, huquqTaxonomy: [] };
  }
  updateSelected(selectedIndex) {
    this.setState({ selected: selectedIndex });
  }

  componentDidMount() {
    this.props.context.digitalDesignContent
      .taxonomy('huquqpage')
      .toObservable()
      .subscribe(async (response) => {
        if (!response.isEmpty) {
          this.setState({ huquqTaxonomy: response.taxonomy.terms.slice() });
        }
      });
  }

  render() {
    const { selected } = this.state;
    return (
      <List>
        {this.state.huquqTaxonomy.map((huquq, index) => (
          <span key={index}>
            <ListItem
              data-cy="huquqdrawerlist"
              key={index}
              onClick={() => this.updateSelected(index)}
              selected={selected === index}
            >
              <Link
                data-cysublev="subdrawerlink"
                data-cy={`huquqdrawerlink${index}`}
                to={`/huququllah-resources/${huquq.codename}`}
              >
                <ListItemText primary={huquq.name} className="subdrawer-list-item" />
              </Link>
            </ListItem>
            <Divider />
          </span>
        ))}
        <ListItem data-cy="huquqdrawerlist" onClick={() => this.updateSelected(6)} selected={selected === 6}>
          <Link to="/news/right_of_god/a-loving-companion-haji-amin">
            <ListItemText primary="Story of Hájí Amín" className="subdrawer-list-item" />
          </Link>
        </ListItem>
      </List>
    );
  }
}

HuquqDrawer.propTypes = {
  details: PropTypes.shape({
    class: PropTypes.string,
    huquqTaxonomy: PropTypes.array,
  }),
  classes: PropTypes.object.isRequired,
};

export default withContext(HuquqDrawer);
