enum SearchExclusion {
  GLSA = 'GLSA',
  UHJ = 'UHJ',
  NSA = 'NSA',
  Feast = 'Feast',
  FAQ = 'FAQ',
  // Huququ = 'Huququ',
  Prayers = 'Prayers',
  Writings = 'Writings',
}

export const SearchExclusionDisplayName: Record<SearchExclusion, string> = {
  [SearchExclusion.GLSA]: 'GLSA',
  [SearchExclusion.UHJ]: 'UHJ®',
  [SearchExclusion.NSA]: 'NSA',
  [SearchExclusion.Feast]: 'Feast Message',
  [SearchExclusion.FAQ]: 'FAQ',
  // [SearchExclusion.Huququ]: "Ḥuqúqu’lláh",
  [SearchExclusion.Prayers]: 'Prayers',
  [SearchExclusion.Writings]: 'Writings',
};

export default SearchExclusion;
