import React, { useState, FunctionComponent, ChangeEvent, useEffect, useCallback, FormEvent } from 'react';
import withContext from '../../ContextAPI/Context_HOC';
import { Row, Col } from 'react-flexbox-grid';
import type HouseholdMemberModel from '../Utilities/HouseholdMemberModel';
import type IHouseholdProxy from '../Utilities/IHouseholdProxy';
import { CircularProgress } from '@material-ui/core';
import ErrorMessageToUser from '../../Error/ErrorMessageToUser';
import type PhysicalAddressChangeRequestModel from '../Utilities/PhysicalAddressChangeRequestModel';
import type { FormSchemaModelType, ValidationStateSchemaType } from '../../Utilities/useFormTypes';
import useForm from '../../Utilities/useForm';
import type IPhysicalAddressProxy from '../Utilities/IPhysicalAddressProxy';
import type PhysicalAddressChangeResponseModel from '../Utilities/PhysicalAddressChangeResponseModel';
import MovingPageDirection from './MovingPageDirection';

type FormFields = {};

const validationStateSchema: ValidationStateSchemaType<FormFields> = {};

const buildInitialState: () => FormSchemaModelType<FormFields> = () => {
  return {};
};

export type PropsContext = {
  physicalAddressProxy: IPhysicalAddressProxy;
  householdProxy: IHouseholdProxy;
};

export type Props = {
  goToNextPage: () => void;
  goToPreviousPage: () => void;
  requestModel: PhysicalAddressChangeRequestModel;
  setRequestModel: React.Dispatch<React.SetStateAction<PhysicalAddressChangeRequestModel>>;
  setNewAddress: (newAddress: undefined | PhysicalAddressChangeResponseModel) => void;
  movingPageDirection: MovingPageDirection;
  context: PropsContext;
};

const HouseholdSelectionPage: FunctionComponent<Props> = ({
  goToNextPage,
  goToPreviousPage,
  requestModel,
  setRequestModel,
  setNewAddress,
  movingPageDirection,
  context: { physicalAddressProxy, householdProxy },
}) => {
  const [selectedIdns, setSelectedIdns] = useState(requestModel.selectedHouseholdMemberIdns);
  const [eligibleMembers, setEligibleMembers] = useState<HouseholdMemberModel[]>([]);
  const [showErrorMessageToUser, setShowErrorMessageToUser] = useState(false);
  const [showCircularProgress, setShowCircularProgress] = useState(true);

  const submitCallback = useCallback(() => {
    setShowCircularProgress(true);
    const changeRequest: PhysicalAddressChangeRequestModel = { ...requestModel, selectedHouseholdMemberIdns: selectedIdns };
    setRequestModel(changeRequest);

    physicalAddressProxy.set(changeRequest).subscribe({
      next: (responseModel) => {
        setNewAddress(responseModel);
        goToNextPage();
      },
      error: () => {
        setShowCircularProgress(false);
        setShowErrorMessageToUser(true);
      },
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [physicalAddressProxy, selectedIdns]);

  const {
    handleOnSubmit,
  }: {
    handleOnSubmit: (e: FormEvent<HTMLInputElement>) => void;
  } = useForm(buildInitialState(), validationStateSchema, submitCallback);

  useEffect(() => {
    setShowCircularProgress(true);
    const subscription = householdProxy.members({ IsEligibleToMove: true }).subscribe({
      next: (eligibleMembersModel) => {
        if (eligibleMembersModel.length === 0) {
          if (movingPageDirection === MovingPageDirection.Forward) {
            goToNextPage();
          }
          if (movingPageDirection === MovingPageDirection.Backward) {
            goToPreviousPage();
          }
        }
        setEligibleMembers(eligibleMembersModel);
        setShowCircularProgress(false);
      },
      error: () => setShowErrorMessageToUser(true),
    });

    return () => subscription.unsubscribe();
  }, [householdProxy]);

  useEffect(() => {
    setRequestModel((prev) => ({ ...prev, selectedHouseholdMemberIdns: selectedIdns }));
  }, [setRequestModel, selectedIdns]);

  const handleHouseHoldSelection = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    const checked = e.target.checked;

    const newSelection = checked
      ? selectedIdns.indexOf(value) === -1
        ? [...selectedIdns, value]
        : [...selectedIdns]
      : selectedIdns.indexOf(value) === -1
      ? [...selectedIdns]
      : selectedIdns.filter((x) => x !== value);

    const orderedSelection = eligibleMembers.filter((x) => newSelection.includes(x.idn)).map((x) => x.idn);

    setSelectedIdns(orderedSelection);
  };

  if (showErrorMessageToUser) {
    return <ErrorMessageToUser />;
  }

  return (
    <div>
      {showCircularProgress ? (
        <CircularProgress data-cy="circular-progress" className="loading-animation" size={40} />
      ) : (
        <>
          <div className="profile-box">
            <p>Please apply this address change to myself and the following members of my household:</p>
            {eligibleMembers.map((member, index) => (
              <div key={index}>
                <Row middle="xs">
                  <Col xs={1} className="end negative-left-margin">
                    <input
                      id={`member_${index}`}
                      name={`member_${index}`}
                      type="checkbox"
                      value={member.idn}
                      data-cy={`member_${index}`}
                      checked={selectedIdns.some((hm) => hm === member.idn)}
                      onChange={handleHouseHoldSelection}
                    ></input>{' '}
                  </Col>
                  <Col xs={11}>
                    <p className="very-small-top-padding">{member.personName}</p>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
          <div className="medium-top-margin end">
            <Row end="xs" className="medium-top-margin">
              <Col sm={2} className="no-padding">
                <input type="submit" value="Back" className="secondary-button" data-cy="backsubmit" onClick={goToPreviousPage} />
              </Col>
              <Col sm={2} className="no-padding">
                <input
                  type="submit"
                  value="Next"
                  className="primary-button"
                  data-cy="nextsubmit"
                  onClick={(e) => {
                    handleOnSubmit(e);
                  }}
                />
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
};

export default withContext(HouseholdSelectionPage);
