import React from 'react';
import PropTypes from 'prop-types';
import Downloads from '../Common-Components/Downloads';
import { Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import withContext from '../ContextAPI/Context_HOC';
import NoMatch from '../Routers/NoMatch';
import { useEffect, useState } from 'react';
import DownloadNotificationModal from './DownloadNotificationModal';

const FeastLetter = (props) => {
  let datestring = '';
  let links = [];
  let coverletterdisplay = '';
  let attachments = '';

  const [showNotificationModal, setShowNotificationModal] = useState(false);

  if (props.details !== '')
    if (props.details.attachments.value.length === 0) {
      attachments = '';
    } else {
      attachments = (
        <span>
          <h4 className="sidebar-headers">Attachments</h4>
          <div>
            {Object.keys(props.details.attachments.value).map((key) => (
              <Downloads key={key} links={props.details.attachments.value[key]} />
            ))}
          </div>
        </span>
      );
    }

  const listenSection = props.details.base__listenhtml?.value ? (
    <span>
      <h4 className="sidebar-headers">Listen</h4>
      <div
        data-cy="listen-section-container"
        dangerouslySetInnerHTML={{
          __html: props.details.base__listenhtml.value,
        }}
      />
    </span>
  ) : null;

  useEffect(() => {
    async function onSave(e) {
      if ((window.navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) && e.keyCode === 83) {
        if (props.details.attachments.value.length > 0) {
          e.preventDefault();
          setShowNotificationModal(true);
        }
      }
    }
    document.addEventListener('keydown', onSave, false);
    return () => {
      document.removeEventListener('keydown', onSave, false);
    };
  });

  const linkedcoverletter = props.details.linkeditemssection;
  if (linkedcoverletter !== undefined && !linkedcoverletter.isEmpty) {
    for (var i = 0; i < linkedcoverletter.value.length; i++) {
      if (
        linkedcoverletter.value[i].base__datepublished === undefined ||
        linkedcoverletter.value[i].url_slug === undefined ||
        linkedcoverletter.value[i].base__title === undefined
      ) {
        break;
      }
      datestring = linkedcoverletter.value[i].base__datepublished.rawData.value.substring(0, 4);
      links.push(
        <li key={i}>
          <Link
            data-cy={`link${i}`}
            to={`/guidance/feast_message/${linkedcoverletter.value[i].url_slug.value}-${datestring}`}
            key={i}
          >
            {linkedcoverletter.value[i].base__title.value}
          </Link>
        </li>
      );
      coverletterdisplay = (
        <span>
          <h4 className="sidebar-headers">Cover Letter</h4>
          <ul>{links}</ul>
        </span>
      );
    }
  }

  if (props.details === '') {
    return <NoMatch />;
  }

  function hideModal(e) {
    e.persist();
    setShowNotificationModal(false);
  }

  return (
    <div data-cy="captureFeastLetter">
      <h1 data-cy="title">{props.details.base__title.value}</h1>
      <p data-cy="date">
        {new Date(props.details.base__datepublished.value).toLocaleDateString('en-US', props.context.dateOptions)}
      </p>
      <br />
      {showNotificationModal ? <DownloadNotificationModal show={showNotificationModal} handleHideModal={hideModal} /> : null}
      <Row>
        <Col sm={12} md={8}>
          <p data-cy="body-text" dangerouslySetInnerHTML={{ __html: props.details.base__body.value }} />
        </Col>
        <Col sm={12} md={4}>
          {attachments}
          {attachments && listenSection ? <hr /> : null}
          {listenSection}
          {(attachments || listenSection) && coverletterdisplay ? <hr /> : null}
          {coverletterdisplay}
        </Col>
      </Row>
    </div>
  );
};

export default withContext(FeastLetter);

FeastLetter.propTypes = {
  props: PropTypes.shape({
    details: PropTypes.shape({
      base__title: PropTypes.shape({ value: PropTypes.string }),
      base__datepublished: PropTypes.shape({ value: PropTypes.string }),
      base__body: PropTypes.shape({ value: PropTypes.string }),
    }),
  }),
};
