import React from 'react';
import { Link } from 'react-router-dom';

function Membership() {
  return (
    <div>
      <h2>Membership</h2>
      <p>
        The most commonly used forms and resources for Assemblies and registered groups, such as registration cards, election
        forms, and international transfer information.
      </p>
      <ul className="plain-list">
        <li>Membership Office</li>
        <li>Bahá’í National Center</li>
        <li>1233 Central St</li>
        <li>Evanston, IL 60201</li>
        <li>
          <a href="mailto:membership@usbnc.org">membership@usbnc.org</a>
        </li>
        <li>Phone: 847-733-3445</li>
        <li>Fax: 847-733-3543</li>
      </ul>
      <hr />
      <h3>Commonly Requested Forms</h3>
      <p>
        Mail completed forms to: Bahá’í National Center, Membership Office, 1233 Central Street, Evanston, IL 60201 or fax them to
        (847) 733–3543.
      </p>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/mem-20030106-membership-id-card-order.pdf">
            Bahá’í ID Card Order Form (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/mem-membership-data-report-2017.pdf">
            Bahá’í Membership Data Report (PDF)
          </a>{' '}
          (used by Assemblies to report address changes, deaths, etc)
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Membership/Bahai+Marriage+Report+Form+2024.pdf">
            Bahá’í Marriage Report (PDF)
          </a>{' '}
          (used by Assemblies to report marriages)
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/resources/assemblyReferences/Handbook+for+Persian-speaking+members+of+the+US+Baha%27i+community+-+01-2017+(Persian).pdf">
            Handbook for Persian-speaking members of the US Bahá’í community - 01-2017 (Persian) (PDF)
          </a>{' '}
          (راهنما برای فارسی زبانان جامعۀ بهائی آمریکا)
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Membership/PQ+first+time+arrival.pdf">
            Persian Bahá’í Membership Transfer Questionnaire (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Membership/Persian+Baha%27i+Membership+Transfer+Questionnaire+(Pending+credential+letter).pdf">
            Persian Bahá’í Membership Transfer Questionnaire (Pending credential letter) (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/mem-2009-registration-card-printing.pdf">
            Adult/Youth Registration Card in English (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/mem-2009-registration-card-printing-spanish.pdf">
            Adult/Youth Registration Card in Spanish (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Membership/Child+Reg+Form+2023.pdf">
            Junior Youth/Child Registration Card in English (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Membership/Spanish+Child+Reg+Form+2023.pdf">
            Junior Youth/Child Registration Card in Spanish (PDF)
          </a>
        </li>
      </ul>
      <hr />
      <h3>Ridván Election Information and Forms</h3>
      <p>
        For the most up to date information and forms please visit <Link to="/elections/ridvan-elections">this page</Link>.
      </p>
      <hr />
      <h3>Marriage and Birth Certificates</h3>
      <p>
        Bahá’í Marriage Certificates with gold embossing and Bahá’í Birth Certificates with copper embossing are available for
        $5.00 per certificate. To place your order please write your check payable to “Bahá’í Services Fund” and mail your request
        to: Bahá’í National Center, Membership Office, 1233 Central St., Evanston, IL 60201. We also accept phone orders at
        847-733-3445 charged to a credit card – either Visa or MasterCard.
      </p>
      <hr />
      <h3>International Transfer Information</h3>
      <p>
        When a Bahá’í moves in or out of the United States, the National Spiritual Assembly of the United States and the National
        Spiritual Assembly of the country the person is moving to or from must be notified. To be sure you have followed all of
        the necessary procedures, we have created an International Transfer Desk Fact Sheet for Bahá’í Communities.
      </p>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Membership/International+Transfer+Desk+Fact+Sheet+2024.pdf">
            International Transfer Desk Fact Sheet for Bahá’í Communities (PDF)
          </a>
        </li>
      </ul>
      <hr />
      <h3 id="mem-faq">Frequently Asked Questions</h3>
      <ul className="faq-list plain-list">
        <li>
          <a href="#QA1">How do I get a replacement ID card and how long does it take to receive it?</a>
        </li>
        <li>
          <a href="#QA2">How do I find the address of an individual that I&#8217;m trying to locate?</a>
        </li>
        <li>
          <a href="#QA3">Where do I send a Declaration card?</a>
        </li>
        <li>
          <a href="#QA4">How do I register my children?</a>
        </li>
        <li>
          <a href="#QA5">How do I get my membership information updated (e.g. Name, address, telephone number, etc.)?</a>
        </li>
        <li>
          <a href="#QA6">How do I report a death?</a>
        </li>
        <li>
          <a href="#QA7">How do I get membership supplies?</a>
        </li>
        <li>
          <a href="#QA8">What do we do when a Bahá’í registered in another country moves to our community?</a>
        </li>
        <li>
          <a href="#QA9">What if I need additional information?</a>
        </li>
      </ul>
      <hr />
      <p className="faq-question" id="QA1">
        How do I get a replacement ID card and how long does it take to receive it?
      </p>
      <p>
        Send a check for $5.00 made out to &quot;Bahá’í Services Fund&quot; to the Membership office. Please indicate that the
        check is for a replacement I.D. card and be sure to include your I.D. number, full correct name, current address, and date
        of birth. Cards are printed and mailed each week. We also accept phone orders charged to a credit card – either Visa or
        MasterCard.{' '}
      </p>
      <hr />
      <p className="faq-question" id="QA2">
        How do I find the address of an individual that I’m trying to locate?
      </p>
      <p>
        It is the policy of the National Spiritual Assembly that names and addresses of members not be disclosed to others without
        their permission. The Membership Office can forward mail or email to the intended recipient provided a current postal or
        electronic address is available.
      </p>
      <hr />
      <p className="faq-question" id="QA3">
        Where do I send a Declaration card?
      </p>
      <p>
        Please forward these cards to the Membership Office through a Local Spiritual Assembly or other authorized enrolling
        agency.
      </p>
      <hr />
      <p className="faq-question" id="QA4">
        How do I register my children?
      </p>
      <p>
        <Link to="/createboswizard/step1">Create</Link> a Bahá’í Online Services account so that you can access My Profile to
        register your children online, or you may complete and sign a Bahá’í child registration card for each child and send the
        card(s) to your Local Spiritual Assembly or the Membership Office.
      </p>
      <hr />
      <p className="faq-question" id="QA5">
        How do I get my membership information updated (e.g. Name, address, telephone number, etc.)?
      </p>
      <p>
        <Link to="/createboswizard/step1">Create</Link> a Bahá’í Online Services account so that you can access My Profile to
        update your information online. Otherwise you may ask your LSA Secretary or Membership Coordinator to submit this
        information, or you may <a href="mailto:membership@usbnc.org">contact</a> the Membership Office directly. In your
        correspondence be sure to include ID numbers, names, current and former addresses, and telephone numbers.
      </p>
      <hr />
      <p className="faq-question" id="QA6">
        How do I report a death?
      </p>
      <p>
        Please send the decedent’s name, ID number (if known), last known address, and date of death to the Membership Office.
      </p>
      <hr />
      <p className="faq-question" id="QA7">
        How do I get membership supplies?
      </p>
      <p>
        You may request Adult/Youth registration cards, Child Registration cards and other forms from the Membership Office via{' '}
        <a href="mailto:membership@usbnc.org">email</a>, mail, or phone. Many forms may also be found in the “Commonly Requested
        Forms” section of this page. Marriage Certificates ($5.00) and Birth Certificates ($5.00) may be ordered through the
        Membership Office. Please send a check paid to the order of &quot;Bahá’í Services Fund&quot; with your order. You may also
        order by phone using either a Visa or MasterCard credit card.{' '}
      </p>
      <hr />
      <p className="faq-question" id="QA8">
        What do we do when a Bahá’í registered in another country moves to our community?
      </p>
      <p>
        For detailed instructions, download{' '}
        <a href="https://bahai-web.s3.amazonaws.com/forms/Membership/International+Transfer+Desk+Fact+Sheet+2024.pdf">
          this document
        </a>{' '}
        to read the International Transfer Desk Fact sheet for Communities.
      </p>
      <hr />
      <p className="faq-question" id="QA9">
        What if I need additional information?
      </p>
      <p>For any additional information about our services:</p>
      <ul className="plain-list">
        <li>
          <strong>General Number:</strong> (847) 733&#8211;3445
        </li>
        <li>
          <strong>E-mail:</strong> <a href="mailto:membership@usbnc.org">membership@usbnc.org</a>
        </li>
      </ul>
    </div>
  );
}

export default Membership;
