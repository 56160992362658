import React, { useEffect, useState, useCallback } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import { Row, Col } from 'react-flexbox-grid';
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';
import ProfileFunction from './Utilities/ProfileFunction';
import { buildFullName, keyValuesToMenuItems, valuesToMenuItems, keyValueToMenuItem } from '../Utilities/UtilityFunctions';
import { CircularProgress } from '@material-ui/core';
import useForm from '../Utilities/useForm';
import { withRouter } from 'react-router-dom';
import { emailFormat } from '../Utilities/GeneralRegEx';
import RaceEthnicityComponent from './Utilities/RaceEthnicityComponent';
import { combineLatest } from 'rxjs';
import { COMMON_VALIDATORS } from '../../src/Utilities/validators';

const initialState = {
  Parent2Id: { value: '' },
  Title: { value: '' },
  FirstName: { value: '' },
  MiddleName: { value: '' },
  LastName: { value: '' },
  Suffix: { value: '' },
  NickName: { value: '' },
  Gender: { value: '' },
  BirthDate: { value: '' },
  CityOfBirth: { value: '' },
  StateOfBirth: { value: '' },
  CountryOfBirth: { value: '' },
  LanguagePreferenceSelection: { value: '' },
  Email: { value: '' },
  InCareOf: { value: '' },
  RequestNoMail: { value: false },
  IsRefugee: { value: false },
  RaceEthnicitySelections: { value: [] },
};
const validationSchema = {
  Parent2Id: { required: false },
  Title: { required: false },
  FirstName: {
    required: false,
    validator: COMMON_VALIDATORS.name(),
  },
  MiddleName: {
    required: false,
    validator: COMMON_VALIDATORS.name(),
  },
  LastName: {
    required: true,
    validator: COMMON_VALIDATORS.name(),
  },
  Suffix: { required: false },
  NickName: { required: false },
  Gender: { required: true },
  BirthDate: { required: true },
  CityOfBirth: { required: false },
  StateOfBirth: { required: false },
  CountryOfBirth: { required: true },
  LanguagePreferenceSelection: { required: true },
  Email: {
    required: false,
    validator: { regEx: emailFormat, error: 'Invalid email format' },
  },
  InCareOf: { required: false },
  RequestNoMail: { required: false },
  IsRefugee: { required: false },
  RaceEthnicitySelections: { required: false },
};

const checkHighlightedFields = 'Please check the highlighted field(s) and resolve the error(s). ';

const ChildRegistration = (props) => {
  useEffect(() => {
    if (!props.context.clickedIToChildRegistrationDisclosures) {
      props.history.push('child-registration-agreement');
    }
  }, [props.context.clickedIToChildRegistrationDisclosures, props.history]);

  const setPageTitle = props.setPageTitle;
  useEffect(() => setPageTitle('Child Registration'), [setPageTitle]);
  const [messageToUser, setMessageToUser] = useState(null);
  const [badErrorMessage, setBadErrorMessage] = useState(null);
  const [showCircularProgress, setShowCircularProgress] = useState(true);
  const [staticState, setStaticState] = useState({
    fullName: 'Loading...',
    anyMembers15YearsAndOlder: false,
    members15YearsAndOlder: [keyValueToMenuItem({ itemKey: '', itemValue: 'Loading...', reactKey: 0 })],
    language: [keyValueToMenuItem({ itemKey: '', itemValue: 'Loading...', reactKey: 0 })],
    title: [keyValueToMenuItem({ itemKey: '', itemValue: 'Loading...', reactKey: 0 })],
    suffix: [keyValueToMenuItem({ itemKey: '', itemValue: 'Loading...', reactKey: 0 })],
    states: [keyValueToMenuItem({ itemKey: '', itemValue: 'Loading...', reactKey: 0 })],
    countries: [keyValueToMenuItem({ itemKey: '', itemValue: 'Loading...', reactKey: 0 })],
    raceEthnicityOptions: undefined,
    address: {
      line1: 'Loading...',
      line2: '',
      line3: '',
      city: '',
      state: '',
      zip: '',
    },
  });

  const firstNameFromChildRegistrationDisclosures = props.context.firstNameFromChildRegistrationDisclosures;
  const lastNameFromChildRegistrationDisclosures = props.context.lastNameFromChildRegistrationDisclosures;
  const registrationProxy = props.context.registrationProxy;
  const myProfileProxy = props.context.myProfileProxy;
  const lookupProxy = props.context.lookupProxy;
  const householdProxy = props.context.householdProxy;
  const auth = props.context.authService;
  const setChildRegistrationSuccessMessage = props.context.setChildRegistrationSuccessMessage;
  const decodedToken = auth.getDecodedToken();
  var profileFunction = new ProfileFunction();
  const profileInfo = profileFunction.getprofileinfo(decodedToken);

  // Action to take if useForm determines fields are valid
  const submit = useCallback(
    (state, processErrors) => {
      const postChildRegistrationRequestModel = {
        Parent2Id: state.Parent2Id.value,
        Title: state.Title.value,
        FirstName: state.FirstName.value,
        MiddleName: state.MiddleName.value,
        LastName: state.LastName.value,
        Suffix: state.Suffix.value,
        NickName: state.NickName.value,
        Gender: state.Gender.value,
        BirthDate: state.BirthDate.value,
        CityOfBirth: state.CityOfBirth.value,
        StateOfBirth: state.StateOfBirth.value,
        CountryOfBirth: state.CountryOfBirth.value,
        LanguagePreferenceSelection: state.LanguagePreferenceSelection.value,
        Email: state.Email.value,
        InCareOf: state.InCareOf.value,
        RequestNoMail: state.RequestNoMail.value,
        IsRefugee: state.IsRefugee.value,
        RaceEthnicitySelections: state.RaceEthnicitySelections.value,
      };
      setShowCircularProgress(true);
      setMessageToUser(null);

      const registrationProxyRegisterChildSubscription = registrationProxy
        .registerChild(postChildRegistrationRequestModel)
        .subscribe({
          next: (apiSuccessMessage) => {
            setChildRegistrationSuccessMessage(
              <p className="success-message wider" data-cy="success-message">
                {apiSuccessMessage}
              </p>
            );
            props.history.push('household');
          },
          error: (e) => {
            if (e.response?.status === 400) {
              const unprocessedErrors = processErrors(e.response.data);
              const newMessage = `${checkHighlightedFields}${unprocessedErrors}`;
              setMessageToUser(
                <p className="error-message wider" data-cy="unsuccess-message">
                  {newMessage}
                </p>
              );
            } else {
              setBadErrorMessage(<ErrorMessageToUser />);
            }
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
            setShowCircularProgress(false);
          },
        });

      return () => {
        if (registrationProxyRegisterChildSubscription) {
          registrationProxyRegisterChildSubscription.unsubscribe();
        }
      };
    },
    [registrationProxy, props.history, setChildRegistrationSuccessMessage]
  );

  // Action to take if useForm determines form is invalid
  const onInvalidSubmitAttempt = useCallback(() => {
    setMessageToUser(
      <p data-cy="unsuccess-message" className="error-message wider">
        {checkHighlightedFields}
      </p>
    );
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const { state, setState, handleValidationOnChange, handleOnSubmit } = useForm(
    initialState,
    validationSchema,
    submit,
    onInvalidSubmitAttempt
  );

  // Get lookup values and pre-populate email field
  useEffect(() => {
    const myProfileObservable = myProfileProxy.get();
    const countriesObservable = lookupProxy.countries();
    const members15YearsAndOlderObservable = householdProxy.members15YearsAndOlder();
    const combinedObservables = combineLatest([myProfileObservable, countriesObservable, members15YearsAndOlderObservable]);
    const subscription = combinedObservables.subscribe({
      next: (res) => {
        const myProfileResponse = res[0];
        const countriesResponse = res[1];
        const members15YearsAndOlderResponse = res[2];
        const fullName = buildFullName(myProfileResponse);
        const members15YearsAndOlder =
          members15YearsAndOlderResponse?.length > 0
            ? [{ idn: '', lastName: 'None' }]
                .concat(members15YearsAndOlderResponse)
                .map((householdMember) => ({
                  key: householdMember.idn,
                  value: buildFullName(householdMember),
                }))
                .map(keyValuesToMenuItems)
            : [];
        const languages = myProfileResponse.languagePreferenceOptions.map(valuesToMenuItems);
        const titles = myProfileResponse.titleOptions.map(keyValuesToMenuItems);
        const suffixes = myProfileResponse.suffixOptions.map(keyValuesToMenuItems);
        const states = myProfileResponse.stateOptions.map(keyValuesToMenuItems);
        const countries = countriesResponse.map(keyValuesToMenuItems);
        const raceEthnicityOptions = myProfileResponse.raceEthnicityOptions;
        const address = {
          line1: myProfileResponse.physicalAddressLine1 ?? '',
          line2: myProfileResponse.physicalAddressLine2 ?? '',
          line3: myProfileResponse.physicalAddressLine3 ?? '',
          city: myProfileResponse.physicalAddressCity ?? '',
          state: myProfileResponse.physicalAddressState ?? '',
          zip: myProfileResponse.physicalAddressZipCode ?? '',
        };
        setState((prevState) => {
          if (myProfileResponse.email && prevState.Email.value === '') {
            return { ...prevState, Email: { value: myProfileResponse.email } };
          } else {
            return prevState;
          }
        });
        setStaticState({
          fullName: fullName,
          anyMembers15YearsAndOlder: members15YearsAndOlder.length > 0,
          members15YearsAndOlder: members15YearsAndOlder,
          language: languages,
          title: titles,
          suffix: suffixes,
          states: states,
          countries: countries,
          raceEthnicityOptions: raceEthnicityOptions,
          address: address,
        });
        setShowCircularProgress(false);
      },
      error: () => {
        setBadErrorMessage(<ErrorMessageToUser />);
        setShowCircularProgress(false);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [myProfileProxy, lookupProxy, householdProxy, setState]);

  // Default language preference to English
  const languageMenuitems = staticState.language;
  useEffect(() => {
    setState((prevState) => {
      if (
        prevState.LanguagePreferenceSelection.value === '' &&
        languageMenuitems.some((menuItem) => menuItem.props.value === 'English')
      ) {
        return {
          ...prevState,
          LanguagePreferenceSelection: { value: 'English' },
        };
      }
      return prevState;
    });
  }, [setState, languageMenuitems]);

  // Default country to USA
  const countryMenuItems = staticState.countries;
  useEffect(() => {
    setState((prevState) => {
      if (prevState.CountryOfBirth.value === '' && countryMenuItems.some((menuItem) => menuItem.props.value === 'USA')) {
        return { ...prevState, CountryOfBirth: { value: 'USA' } };
      }
      return prevState;
    });
  }, [setState, countryMenuItems]);

  // Default child first and last name to values from disclosure page
  useEffect(() => {
    setState((prevState) => {
      prevState.FirstName.value = firstNameFromChildRegistrationDisclosures;
      prevState.LastName.value = lastNameFromChildRegistrationDisclosures;
      return prevState;
    });
  }, [setState, firstNameFromChildRegistrationDisclosures, lastNameFromChildRegistrationDisclosures]);

  return (
    <div>
      {badErrorMessage ?? (
        <div>
          {showCircularProgress ? (
            <CircularProgress data-cy="register-child-circular-progress" className="loading-animation" size={40} />
          ) : (
            messageToUser
          )}
          <div className="profile-box">
            <h4>Parent/Legal Guardian Information</h4>
            <Row between="sm">
              <Col sm={4}>
                <p>Parent/Legal Guardian 1</p>
              </Col>
              <Col sm={3}>
                <p>{profileInfo.bid}</p>
              </Col>
              <Col sm={5}>
                <p>
                  <strong>{staticState.fullName}</strong>
                </p>
              </Col>
            </Row>
            {staticState.anyMembers15YearsAndOlder ? (
              <Row between="sm">
                <Col sm={4} className="inline-vertical-center">
                  <p>Parent/Legal Guardian 2</p>
                </Col>
                <Col sm={3}>
                  <p data-cy="Parent2Id_display">{state.Parent2Id.value}</p>
                </Col>
                <Col sm={5}>
                  <FormControl className="full-width">
                    <Select
                      inputProps={{ name: 'Parent2Id', 'data-cy': 'Parent2Id' }}
                      id="Parent2Id"
                      name="Parent2Id"
                      aria-label="Parent 2 IDN"
                      error={!!state.Parent2Id.error}
                      value={state.Parent2Id.value}
                      onChange={handleValidationOnChange}
                      variant="outlined"
                      margin="dense"
                    >
                      {staticState.members15YearsAndOlder}
                    </Select>
                  </FormControl>
                  {state.Parent2Id.error && (
                    <p className="form-field-error" data-cy="Parent2Id_field_error">
                      {state.Parent2Id.error}
                    </p>
                  )}
                </Col>
              </Row>
            ) : null}
            <hr />
            <h4>Residential Address</h4>
            <Row className="small-bottom-padding">
              <Col sm={4}>
                <TextField
                  inputProps={{ 'data-cy': 'InCareOf' }}
                  id="InCareOf"
                  name="InCareOf"
                  label="In Care Of"
                  aria-label="In Care Of"
                  type="text"
                  error={!!state.InCareOf.error}
                  value={state.InCareOf.value}
                  onChange={handleValidationOnChange}
                  margin="dense"
                  maxLength="40"
                  fullWidth={true}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                {state.InCareOf.error && (
                  <p className="form-field-error" data-cy="InCareOf_field_error">
                    {state.InCareOf.error}
                  </p>
                )}
              </Col>
            </Row>
            <Row className="small-bottom-padding">
              <Col sm={4}>
                <p className="faded-text small-text">Street Address</p>
                <p>
                  <strong>{staticState.address.line1}</strong>
                </p>
              </Col>
              <Col sm={4}>
                <p className="faded-text small-text">Address Line two</p>
                <p>
                  <strong>{staticState.address.line2}</strong>
                </p>
              </Col>
              <Col sm={4}>
                <p className="faded-text small-text">Address Line three</p>
                <p>
                  <strong>{staticState.address.line3}</strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <p className="faded-text small-text">City</p>
                <p>
                  <strong>{staticState.address.city}</strong>
                </p>
              </Col>
              <Col sm={4}>
                <p className="faded-text small-text">State</p>
                <p>
                  <strong>{staticState.address.state}</strong>
                </p>
              </Col>
              <Col sm={4}>
                <p className="faded-text small-text">Zip Code</p>
                <p>
                  <strong>{staticState.address.zip}</strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="medium-left-padding">
                  <em className="small-text">
                    This is the address of your physical residence. Mail will be sent to the Mailing Address associated with your
                    account. You may update either address on the Contact Information page or by contacting the Membership Office
                    at <a href="mailto:membership@usbng.org">membership@usbnc.org</a>.
                  </em>
                </p>
                <label aria-label="RequestNoMail" className="medium-left-padding">
                  <input
                    id="RequestNoMail"
                    name="RequestNoMail"
                    type="checkbox"
                    data-cy="RequestNoMail"
                    checked={state.RequestNoMail.value}
                    onChange={handleValidationOnChange}
                  ></input>{' '}
                  <strong>Request No Mail by Post</strong>{' '}
                  <em className="small-text">
                    (by checking this box you are requesting that your child NOT receive Brilliant Star or any other future
                    mailings)
                  </em>
                </label>
                {state.RequestNoMail.error && (
                  <p className="form-field-error" data-cy="RequestNoMail_field_error">
                    {state.RequestNoMail.error}
                  </p>
                )}
              </Col>
            </Row>
            <hr />
            <h4>Child’s Name</h4>
            <Row className="small-bottom-padding" between="xs">
              <Col sm className="dense-col-padding">
                <FormControl variant="outlined" margin="dense" className="small-dropdown">
                  <InputLabel id="Title" className="small-text">
                    Title
                  </InputLabel>
                  <Select
                    error={!!state.Title.error}
                    value={state.Title.value}
                    onChange={handleValidationOnChange}
                    label="Title"
                    labelId="Title"
                    inputProps={{ name: 'Title', 'data-cy': 'Title' }}
                  >
                    {staticState.title}
                  </Select>
                </FormControl>
                {state.Title.error && (
                  <p className="form-field-error" data-cy="Title_field_error">
                    {state.Title.error}
                  </p>
                )}
              </Col>
              <Col sm={3} className="dense-col-padding">
                <TextField
                  inputProps={{ 'data-cy': 'FirstName' }}
                  id="FirstName"
                  name="FirstName"
                  label="First Name"
                  aria-label="First Name"
                  type="text"
                  error={!!state.FirstName.error}
                  value={state.FirstName.value ?? ''}
                  onChange={handleValidationOnChange}
                  margin="dense"
                  maxLength="40"
                  fullWidth={true}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                {state.FirstName.error && (
                  <p className="form-field-error" data-cy="FirstName_field_error">
                    {state.FirstName.error}
                  </p>
                )}
              </Col>
              <Col sm={3} className="dense-col-padding">
                <TextField
                  inputProps={{ 'data-cy': 'MiddleName' }}
                  id="MiddleName"
                  name="MiddleName"
                  label="Middle Name"
                  aria-label="Middle Name"
                  type="text"
                  error={!!state.MiddleName.error}
                  value={state.MiddleName.value}
                  onChange={handleValidationOnChange}
                  margin="dense"
                  maxLength="40"
                  fullWidth={true}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                {state.MiddleName.error && (
                  <p className="form-field-error" data-cy="MiddleName_field_error">
                    {state.MiddleName.error}
                  </p>
                )}
              </Col>
              <Col sm={3} className="dense-col-padding">
                <TextField
                  required
                  inputProps={{ 'data-cy': 'LastName' }}
                  id="LastName"
                  name="LastName"
                  label="Last Name"
                  aria-label="Last Name"
                  error={!!state.LastName.error}
                  type="text"
                  value={state.LastName.value}
                  onChange={handleValidationOnChange}
                  margin="dense"
                  maxLength="40"
                  fullWidth={true}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                {state.LastName.error && (
                  <p className="form-field-error" data-cy="LastName_field_error">
                    {state.LastName.error}
                  </p>
                )}
              </Col>
              <Col xs className="dense-col-padding">
                <FormControl variant="outlined" margin="dense" className="small-dropdown">
                  <InputLabel id="Suffix" className="small-text">
                    Suffix
                  </InputLabel>
                  <Select
                    error={!!state.Suffix.error}
                    value={state.Suffix.value}
                    onChange={handleValidationOnChange}
                    label="Suffix"
                    labelId="Suffix"
                    inputProps={{ name: 'Suffix', 'data-cy': 'Suffix' }}
                  >
                    {staticState.suffix}
                  </Select>
                </FormControl>
                {state.Suffix.error && (
                  <p className="form-field-error" data-cy="Suffix_field_error">
                    {state.Suffix.error}
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={3} className="dense-col-padding">
                <TextField
                  inputProps={{ 'data-cy': 'NickName' }}
                  id="NickName"
                  name="NickName"
                  label="Known as/Nickname"
                  aria-label="Known as/Nickname"
                  type="text"
                  error={!!state.NickName.error}
                  value={state.NickName.value}
                  onChange={handleValidationOnChange}
                  margin="dense"
                  maxLength="40"
                  fullWidth={true}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                {state.NickName.error && (
                  <p className="form-field-error" data-cy="NickName_field_error">
                    {state.NickName.error}
                  </p>
                )}
              </Col>
            </Row>
            <hr />
            <h4>Personal Information</h4>
            <Row>
              <Col sm={6}>
                <Row>
                  <Col sm={5} className="profile-alignment inline-vertical-center">
                    <p>*Gender</p>
                  </Col>
                  <Col sm={7}>
                    <FormControl className="full-width">
                      <Select
                        required
                        inputProps={{ name: 'Gender', 'data-cy': 'Gender' }}
                        id="Gender"
                        name="Gender"
                        aria-label="Gender"
                        variant="outlined"
                        margin="dense"
                        className="spaced-dropdowns"
                        error={!!state.Gender.error}
                        value={state.Gender.value}
                        onChange={handleValidationOnChange}
                      >
                        <MenuItem selected disabled data-cy={`Gender_Disabled`} value=""></MenuItem>
                        <MenuItem data-cy={`Gender_Female`} value="Female">
                          Female
                        </MenuItem>
                        <MenuItem data-cy={`Gender_Male`} value="Male">
                          Male
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {state.Gender.error && (
                      <p className="form-field-error" data-cy="Gender_field_error">
                        {state.Gender.error}
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm={5} className="profile-alignment inline-vertical-center">
                    <p>*Date of Birth</p>
                  </Col>
                  <Col sm={7}>
                    <TextField
                      required
                      className="full-width"
                      inputProps={{ 'data-cy': 'BirthDate' }}
                      id="BirthDate"
                      name="BirthDate"
                      aria-label="Date of Birth"
                      type="date"
                      pattern="^\d{4}-\d{2}-\d{2}$"
                      error={!!state.BirthDate.error}
                      value={state.BirthDate.value}
                      onChange={handleValidationOnChange}
                      variant="outlined"
                      margin="dense"
                      maxLength="40"
                    />
                    {state.BirthDate.error && (
                      <p className="form-field-error" data-cy="BirthDate_field_error">
                        {state.BirthDate.error ?? null}
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm={5} className="profile-alignment inline-vertical-center">
                    <p>City of Birth</p>
                  </Col>
                  <Col sm={7}>
                    <TextField
                      inputProps={{ 'data-cy': 'CityOfBirth' }}
                      id="CityOfBirth"
                      name="CityOfBirth"
                      aria-label="City of Birth"
                      type="text"
                      error={!!state.CityOfBirth.error}
                      value={state.CityOfBirth.value}
                      onChange={handleValidationOnChange}
                      variant="outlined"
                      margin="dense"
                      maxLength="40"
                    />
                    {state.CityOfBirth.error && (
                      <p className="form-field-error" data-cy="CityOfBirth_field_error">
                        {state.CityOfBirth.error ?? null}
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm={5} className="profile-alignment inline-vertical-center">
                    <p>State of Birth</p>
                  </Col>
                  <Col sm={7}>
                    <FormControl className="full-width">
                      <Select
                        inputProps={{
                          name: 'StateOfBirth',
                          'data-cy': 'StateOfBirth',
                        }}
                        id="StateOfBirth"
                        name="StateOfBirth"
                        aria-label="State Of Birth"
                        error={!!state.StateOfBirth.error}
                        value={state.StateOfBirth.value}
                        onChange={handleValidationOnChange}
                        variant="outlined"
                        margin="dense"
                        className="spaced-dropdowns"
                      >
                        {staticState.states}
                      </Select>
                    </FormControl>
                    {state.StateOfBirth.error && (
                      <p className="form-field-error" data-cy="StateOfBirth_field_error">
                        {state.StateOfBirth.error}
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm={5} className="profile-alignment inline-vertical-center">
                    <p>*Country of Birth</p>
                  </Col>
                  <Col sm={7}>
                    <FormControl className="full-width">
                      <Select
                        required
                        inputProps={{
                          name: 'CountryOfBirth',
                          'data-cy': 'CountryOfBirth',
                        }}
                        id="CountryOfBirth"
                        name="CountryOfBirth"
                        aria-label="Country Of Birth"
                        error={!!state.CountryOfBirth.error}
                        value={state.CountryOfBirth.value}
                        onChange={handleValidationOnChange}
                        variant="outlined"
                        margin="dense"
                        className="spaced-dropdowns"
                      >
                        {staticState.countries}
                      </Select>
                    </FormControl>
                    {state.CountryOfBirth.error && (
                      <p className="form-field-error" data-cy="CountryOfBirth_field_error">
                        {state.CountryOfBirth.error}
                      </p>
                    )}
                  </Col>
                </Row>
                <br />
                <Row>
                  <label aria-label="refugee">
                    <input
                      id="IsRefugee"
                      name="IsRefugee"
                      type="checkbox"
                      data-cy="IsRefugee"
                      checked={state.IsRefugee.value}
                      onChange={handleValidationOnChange}
                    ></input>{' '}
                    This child is a refugee
                  </label>
                  {state.IsRefugee.error && (
                    <p className="form-field-error" data-cy="IsRefugee_field_error">
                      {state.IsRefugee.error}
                    </p>
                  )}
                </Row>
              </Col>
              <Col sm={6}>
                <RaceEthnicityComponent
                  raceEthnicityOptions={staticState.raceEthnicityOptions}
                  value={state.RaceEthnicitySelections.value}
                  onChange={handleValidationOnChange}
                  name="RaceEthnicitySelections"
                />
              </Col>
            </Row>
            <hr />
            <Row middle="sm">
              <Col sm={3} className="inline-vertical-center small-left-padding medium-top-padding">
                <p>Language Preference</p>
              </Col>
              <Col sm={5}>
                <FormControl className="full-width">
                  <Select
                    required
                    error={!!state.LanguagePreferenceSelection.error}
                    value={state.LanguagePreferenceSelection.value}
                    onChange={handleValidationOnChange}
                    inputProps={{
                      name: 'LanguagePreferenceSelection',
                      'data-cy': 'LanguagePreferenceSelection',
                    }}
                    variant="outlined"
                    margin="dense"
                    maxLength="40"
                    fullWidth={true}
                  >
                    {staticState.language}
                  </Select>
                </FormControl>
                {state.LanguagePreferenceSelection.error && (
                  <p className="form-field-error" data-cy="LanguagePreferenceSelection_field_error">
                    {state.LanguagePreferenceSelection.error}
                  </p>
                )}
              </Col>
            </Row>
            <hr />
            <h4>Email</h4>
            <Row>
              <Col sm={4} className="dense-col-padding">
                <TextField
                  id="Email"
                  name="Email"
                  aria-label="Email"
                  type="email"
                  variant="outlined"
                  margin="dense"
                  maxLength="40"
                  fullWidth={true}
                  inputProps={{ 'data-cy': 'Email' }}
                  InputLabelProps={{ shrink: true }}
                  error={!!state.Email.error}
                  value={state.Email.value}
                  onChange={handleValidationOnChange}
                />
                {state.Email.error && (
                  <p className="form-field-error" data-cy="Email_field_error">
                    {state.Email.error}
                  </p>
                )}
              </Col>
              <Col sm={8} className="inline-vertical-center">
                <p>
                  <em className="small-text">
                    This address is used for correspondence from Bahá’í agencies and for receiving a temporary password during the
                    reset process.
                  </em>
                </p>
              </Col>
            </Row>
          </div>
          <div className="medium-top-margin end">
            <input onClick={handleOnSubmit} type="submit" value="Save" className="primary-button" data-cy="inputsubmit" />
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(withContext(ChildRegistration));
