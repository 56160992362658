import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import RealEstateIcon from '../../assets/img/PlannedGiving/Retirement.png';

function RealEstate() {
  return (
    <Row>
      <Col sm={1}>
        <img src={RealEstateIcon} alt="Real Estate" className="fund-logo" />
      </Col>
      <Col sm={11}>
        <h1>Real Estate</h1>
        <p>Gifts of appreciated real estate have benefits similar to gifts of appreciated securities.</p>
        <ul>
          <li>
            You receive a tax deduction for the appraised value of the property. An appraisal by an independent and qualified
            appraiser must be arranged by and paid for by you, the donor.
          </li>
          <li>
            On highly appreciated properties, you avoid tax on the capital gain that you might owe if you sold the property
            outright.
          </li>
        </ul>
        <p>
          The life estate is another option for gifting real estate when the property is either a residence or a farm. The life
          estate involves gifting the property with the donor retaining use of the property for his or her lifetime. Benefits
          include:
        </p>
        <ul className="docs-list-bullet">
          <li>An immediate tax deduction for the donor based on the donor’s age, and the appraised value of the property</li>
          <li>Future estate tax exclusion</li>
          <li>Use of the property for the rest of the donor’s lifetime</li>
          <li>A generous gift to the Bahá’í Fund</li>
        </ul>
        <p>
          If a home or farm is gifted to the Bahá’í Faith as a life estate to the National Spiritual Assembly, the Assembly cannot
          be responsible for all of the routine expenses of maintenance, insurance, property taxes and repairs associated with the
          property. In addition, the National Spiritual Assembly cannot accept gifted property that is encumbered by a mortgage or
          property that is considered unsound, unmarketable, or properties that are determined to have environmental problems.{' '}
        </p>
        <p>
          Please Note: At this time the National Spiritual Assembly is not accepting real estate that is earmarked for a specific
          purpose.
        </p>
      </Col>
    </Row>
  );
}

export default RealEstate;
