import React from 'react';

function AnnualReports() {
  return (
    <div>
      <h1>Ridván Annual Reports</h1>
      <br />
      <ul className="plain-list">
        <li>
          Download:{' '}
          <a href="https://bahai-web.s3.amazonaws.com/messages/AnnualReports/2024-US-NSA-Annual-Report.pdf">
            Ridván 2024 Annual Report (PDF)
          </a>
        </li>
        <li>
          Download:{' '}
          <a href="https://bahai-web.s3.amazonaws.com/messages/AnnualReports/2023-US-NSA-Annual-Report.pdf">
            Ridván 2023 Annual Report (PDF)
          </a>
        </li>
        <li>
          Download:{' '}
          <a href="https://bahai-web.s3.amazonaws.com/messages/AnnualReports/2022-US-NSA-Annual-Report.pdf">
            Ridván 2022 Annual Report (PDF)
          </a>
        </li>
        <li>
          Download:{' '}
          <a href="https://bahai-web.s3.amazonaws.com/messages/AnnualReports/2021-US-NSA-Annual-Report.pdf">
            Ridván 2021 Annual Report (PDF)
          </a>
        </li>
        <li>
          Download:{' '}
          <a href="https://bahai-web.s3.amazonaws.com/messages/AnnualReports/2020-US-NSA-Annual-Report.pdf">
            Ridván 2020 Annual Report (PDF)
          </a>
        </li>
        <li>
          Download:{' '}
          <a href="https://bahai-web.s3.amazonaws.com/messages/AnnualReports/2019-US-NSA-Annual-Report.pdf">
            Ridván 2019 Annual Report (PDF)
          </a>
        </li>
        <li>
          Download:{' '}
          <a href="https://bahai-web.s3.amazonaws.com/messages/AnnualReports/2018-US-NSA-Annual-Report.pdf">
            Ridván 2018 Annual Report (PDF)
          </a>
        </li>
      </ul>
    </div>
  );
}

export default AnnualReports;
