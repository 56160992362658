import React from 'react';
import PropTypes from 'prop-types';
import { Grid, ClickAwayListener, Paper, Popover, IconButton, Tooltip } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import withContext from '../../ContextAPI/Context_HOC';
import { withRouter, Link } from 'react-router-dom';
import ProfileFunction from '../../Profiles/Utilities/ProfileFunction';
import ErrorMessageToUser from '../../Error/ErrorMessageToUser';

const styles = {
  center: {
    textAlign: 'center',
  },
  signOut: {
    textAlign: 'center',
    backgroundColor: '#e9edee',
    marginTop: '10px',
    padding: '10px',
  },
  paper: {
    padding: '10px 5px 5px 5px',
  },
  largeicon: {
    fontSize: '100px',
  },
};

class ProfileNavBar extends React.Component {
  constructor(props) {
    super(props);
    const profileFunction = new ProfileFunction();
    const decodedToken = this.props.context.authService.getDecodedToken();
    const profileInfo = profileFunction.getprofileinfo(decodedToken);

    this.state = {
      open: false,
      anchorEl: null,
      nameFromToken: profileInfo.name,
      email: profileInfo.email,
      entityNameFromToken: profileInfo.entityName,
    };
  }

  handleLogout() {
    this.props.context.closeDrawerMenu();
    this.props.context.setredirectUrl({ pathname: '' });
    this.props.context.authService.logout();
    this.setState({ anchorEl: null });
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleClose);
    const observable = this.props.context.myProfileProxy.get();
    this.myProfileProxyGetSubscription = observable.subscribe({
      next: (res) =>
        this.setState({
          nameFromApiResponse: `${res.firstName} ${res.lastName}`,
        }),
      error: () => this.setState({ errorMessage: <ErrorMessageToUser /> }),
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleClose);
    if (this.myProfileProxyGetSubscription) {
      this.myProfileProxyGetSubscription.unsubscribe();
    }
  }

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;
    const openProfileCircle = Boolean(anchorEl);

    return (
      <div data-cy="tooltipdiv" className="navbarItem pointer-finger small-top-padding">
        <Tooltip title="Profile" aria-label="profile">
          <div onClick={this.handleClick}>
            <IconButton
              aria-owns={openProfileCircle ? 'profile-popper' : undefined}
              aria-haspopup="true"
              color="inherit"
              data-cy="accountcircle"
              className="navbarIcon"
            >
              <AccountCircle />
            </IconButton>
            <p className="white-text xs-text">PROFILE</p>
          </div>
        </Tooltip>
        <Popover
          id="profile-popper"
          open={openProfileCircle}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Paper className={classes.paper} data-cy="popuppaper">
            <ClickAwayListener onClickAway={this.handleClose}>
              <Grid container>
                <Grid item xs={5} className={classes.center}>
                  <AccountCircle className={classes.largeicon} />
                </Grid>
                <Grid item xs={7} className={classes.center}>
                  <p data-cy="userName">
                    Welcome {this.state.entityNameFromToken ?? this.state.nameFromApiResponse ?? this.state.nameFromToken}!
                  </p>
                  <p data-cy="userEmail">{this.state.email}</p>
                  <Link className="primary-button" data-cy="myprofile" onClick={this.handleClose} to="/myprofile">
                    My Profile
                  </Link>
                </Grid>
                <Grid item xs={12} className={classes.signOut}>
                  <Link to="/login" data-cy="signout" onClick={this.handleLogout.bind(this)}>
                    Sign Out
                  </Link>
                </Grid>
              </Grid>
            </ClickAwayListener>
          </Paper>
        </Popover>
      </div>
    );
  }
}
ProfileNavBar.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withRouter(withContext(withStyles(styles, { withTheme: true })(ProfileNavBar)));
