import React from "react";
import { CustomField } from "../../Hooks/forms/type/custom-field";
import {
  CustomFieldFormType
} from "../../Hooks/forms/enums/custom-field-form-type";
import { CompleteForm } from "./UpdateRegisterForm";
import dayjs from "dayjs";

export interface SummaryPageProps {
  fields: CustomField<any>[],
  form: CompleteForm
}
export default function StepsSummary(props: SummaryPageProps) {
  const { form, fields } = props;

  const noDemographicInformation =
    form.ethnicityAmericanIndianOrAlaskaNative ||
    form.ethnicityHispanicOrLatino ||
    form.ethnicityAsian ||
    form.ethnicityMiddleEastern ||
    form.ethnicityBlackOrAfricanAmerican ||
    form.ethnicityPersian ||
    form.ethnicityNativeHawaiianOtherPacific ||
    form.ethnicityWhite ||
    form.ethnicityOther ||
    form.ethnicityOtherResponseOne ||
    form.ethnicityOtherResponseTwo ||
    form.ethnicityOtherResponseThree ||
    form.ethnicityOtherResponseFour;

  const noVerificationInformation =
    form.originallyEnrolledCity ||
    form.originallyEnrolledState ||
    form.originallyEnrolledCountry ||
    form.bahaiId ||
    form.bahaiName ||
    form.additionalInformation;

  const noMaillingAddressInformation =
    form.addressMailingZipCode ||
    form.addressMailingState ||
    form.addressMailingInCareOf ||
    form.addressMailingLine1 ||
    form.addressMailingLine2 ||
    form.addressMailingCity ||
    form.addressMailingRadio;

  const residentialAddressValue = `${form.addressCurrentCity}, ${form.addressCurrentState}, ${form.addressCurrentZipCode}`
  const mailingAddressValue = `${form.addressMailingCity}, ${form.addressMailingState}, ${form.addressMailingZipCode}`
  const hiddenFields = [
    'addressCurrentState',
    'addressCurrentZipCode',
    'addressMailingState',
    'addressMailingZipCode',
  ]
  const buildField = (field: CustomField<any>) => {
    const dataCy = 'summary' + field.formControlName;
    if (hiddenFields.includes(field.formControlName)) {
      return <></>
    }
    if (field.formControlName === 'addressCurrentCity') {
      return (
        <>
          <div className="font-weight-bold col-sm-6 right-justified" data-cy={dataCy}>City, State, ZIP Code</div>
          <div key={field.formControlName + 'display-message'}
            data-cy={field.formControlName + 'display-message'}
            className="col-sm-6">
            {residentialAddressValue}
          </div>
        </>
      )
    }
    if (field.formControlName === 'addressMailingCity') {
      return (
        <>
          <div className="font-weight-bold col-sm-6 right-justified" data-cy={dataCy}>City, State, ZIP Code</div>
          <div key={field.formControlName + 'display-message'}
            data-cy={field.formControlName + 'display-message'}
            className="col-sm-6">
            {mailingAddressValue}
          </div>
        </>
      )
    }
    if (field.type === CustomFieldFormType.Radio && field.radio) {
      const keyField = field.formControlName as keyof CompleteForm;
      const currentValue = form[keyField]
      const valueFromList = field.radio.options.find((a) => a.value === currentValue)
      return (
        <>
          <div className="font-weight-bold col-sm-6 right-justified" data-cy={dataCy}>{field.label}</div>
          <div key={field.formControlName + 'display-message'}
            data-cy={field.formControlName + 'display-message'}
            className="col-sm-6">
            {valueFromList?.label}
          </div>
        </>
      )
    }
    if (field.type === CustomFieldFormType.Select && field.select) {
      const keyField = field.formControlName as keyof CompleteForm;
      const currentValue = form[keyField]
      const valueFromList = field.select.options.find((a) => a.value === currentValue)
      return (
        <>
          <div className="font-weight-bold col-sm-6 right-justified" data-cy={dataCy}>{field.label}</div>
          <div key={field.formControlName + 'display-message'}
            data-cy={field.formControlName + 'display-message'}
            className="col-sm-6">
            {valueFromList?.label}
          </div>
        </>
      )
    }
    if (field.type === CustomFieldFormType.Date) {
      const keyField = field.formControlName as keyof CompleteForm;
      const currentValue = form[keyField]
      return (
        <>
          <div className="font-weight-bold col-sm-6 right-justified" data-cy={dataCy}>{field.label}</div>
          <div key={field.formControlName + 'display-message-date'}
            data-cy={field.formControlName + 'display-message'}
            className="col-sm-6">
            {(currentValue as dayjs.Dayjs).format('MM-DD-YYYY')}
          </div>
        </>
      )
    }
    if (field.type === CustomFieldFormType.Checkbox) {
      return (
        <>
          <div className="font-weight-bold col-sm-6 right-justified" data-cy={dataCy}>{field.label}</div>
          <div key={field.formControlName + 'display-message-date'}
            data-cy={field.formControlName + 'display-message'}
            className="col-sm-6">
            Yes
          </div>
        </>
      )
    }
    if (field.type === CustomFieldFormType.GroupLabel) {
      if (!noDemographicInformation && field.formControlName === 'demographicFieldsArrayGroupLabel') {
        return <></>
      }
      if (!noVerificationInformation && field.formControlName === 'verificationFieldsArrayGroupLabel') {
        return <></>
      }
      if (!noMaillingAddressInformation && field.formControlName === 'AddressMailingFieldsArrayGroupLabel') {
        return <></>
      }
      return (
        <>
          <h4 className="col-sm-12 text-center" data-cy={dataCy}
            key={field.formControlName}>{field.label}</h4>
        </>
      )
    }
    const keyField = field.formControlName as keyof CompleteForm;
    const currentValue = form[keyField]
    return (
      <>
        <div className="font-weight-bold col-sm-6 right-justified" data-cy={dataCy}>{field.label}</div>
        <div key={field.formControlName + 'display-message'}
          data-cy={field.formControlName + 'display-message'}
          className="col-sm-6">
          {currentValue as string}
        </div>
      </>
    )
  }
  const groupBySection = (fields: CustomField<any>[]) => {
    let groupIndex = -1;
    let result: CustomField<any>[][] = [];
    const filteredFields = fields.filter(
      (f) => {
        const keyField = f.formControlName as keyof CompleteForm;
        const currentValue = form[keyField]
        return currentValue || currentValue === null || f.type === CustomFieldFormType.GroupLabel
      });
    for (let i = 0; i < filteredFields.length; i++) {
      const f = filteredFields[i];

      if (f.type === CustomFieldFormType.GroupLabel) {
        result.push([]);
        groupIndex++;
      }
      result[groupIndex].push(f);
    }
    return result.filter(g => g.length > 1);
  }
  return (
    <div className="col">
      {
        groupBySection(fields).map((group, index) => {
          return <div className="row alternating-background" key={index}>
            {group.map(f => buildField(f))}
          </div>
        })
      }
    </div>
  )
}
