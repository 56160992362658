import React from 'react';

class CreateBOSHeader extends React.Component<any, any> {
  render() {
    return (
      <>
        <div data-cy="logo" className="starlogo star-margin" />
        <h2 data-cy="logotext" className="logo-text">
          Bahá’ís of the United States
        </h2>
      </>
    );
  }
}
export default CreateBOSHeader;
