import React from 'react';
import { Row, Col } from 'react-flexbox-grid';

const DomesticMailingAddress = ({ details }) => {
  return (
    <div>
      <Row className="bottom-padding">
        <Col sm={12}>
          <p className="faded-text small-text">In Care of</p>
          <p>
            <strong>{details.mailingAddressCareOf}</strong>
          </p>
        </Col>
      </Row>
      <Row className="bottom-padding">
        <Col sm={12}>
          <p className="faded-text small-text">Street Address</p>
          <strong>{details.mailingAddressLine1}</strong>
        </Col>
      </Row>
      {details.mailingAddressLine2 ? (
        <Row className="small-bottom-padding">
          <Col sm={12}>
            <p>
              <strong>{details.mailingAddressLine2}</strong>
            </p>
          </Col>
        </Row>
      ) : null}

      {details.mailingAddressLine3 ? (
        <Row className="small-bottom-padding">
          <Col sm={12}>
            <p>
              <strong>{details.mailingAddressLine3}</strong>
            </p>
          </Col>
        </Row>
      ) : null}

      {details.mailingAddressLine4 ? (
        <Row className="small-bottom-padding">
          <Col sm={12}>
            <p>
              <strong>{details.mailingAddressLine4}</strong>
            </p>
          </Col>
        </Row>
      ) : null}

      {details.mailingAddressLine5 ? (
        <Row className="small-bottom-padding">
          <Col sm={12}>
            <p>
              <strong>{details.mailingAddressLine5}</strong>
            </p>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col sm={4}>
          <p className="faded-text small-text">City</p>
          <p>
            <strong>{details.mailingAddressCity}</strong>
          </p>
        </Col>
        <Col sm={4}>
          <p className="faded-text small-text">State</p>
          <p>
            <strong>{details.mailingAddressState}</strong>
          </p>
        </Col>
        <Col sm={4}>
          <p className="faded-text small-text">Zip Code</p>
          <p>
            <strong>{details.mailingAddressZipCode}</strong>
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default DomesticMailingAddress;
