import GlobalAPICall from '../GlobalComponents/GlobalAPICall';
import type IToken from '../Login/IToken';
import { switchMap, filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import type FundStatus from './FundStatus';

export default class FundProxy {
  authService: IToken;
  dataApiBaseUrl: string;

  constructor(auth: IToken, dataApiBaseUrl: string) {
    this.authService = auth;
    this.dataApiBaseUrl = dataApiBaseUrl;
  }

  getStatus(): Observable<FundStatus> {
    return this.authService
      .getTokenObservable()
      .pipe(filter((token) => !!token))
      .pipe(
        switchMap((token) =>
          GlobalAPICall.getObservable(`${this.dataApiBaseUrl}/api/Fund/Status`, {
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          })
        )
      )
      .pipe(map((items) => items.data));
  }
}
