import useCustomHookForm from "../../Hooks/forms/use-custom-hook-form";
import {
  CustomFieldFormType
} from "../../Hooks/forms/enums/custom-field-form-type";
import React, {useState} from "react";
import {Alert, Button} from "@mui/material";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import theme from "../../styles/theme";
import {CustomField} from "../../Hooks/forms/type/custom-field";

export interface DemographicResponseForm {
  ethnicityAmericanIndianOrAlaskaNative: boolean;
  ethnicityHispanicOrLatino: boolean;
  ethnicityAsian: boolean;
  ethnicityMiddleEastern: boolean;
  ethnicityBlackOrAfricanAmerican: boolean;
  ethnicityPersian: boolean;
  ethnicityNativeHawaiianOtherPacific: boolean;
  ethnicityOther: boolean;
  ethnicityWhite: boolean;
  ethnicityOtherResponseOne: string;
  ethnicityOtherResponseTwo: string;
  ethnicityOtherResponseThree: string;
  ethnicityOtherResponseFour: string;
}

export interface DemographicProps {
  callbackDemographic: (response: DemographicResponseForm) => void
}
export const DemographicFieldsArray:CustomField<any>[] =[
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'customTextDemographicInformation',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <h3 className="text-left">Demographic Information</h3>,
    cssClassName: 'col-lg-12'
  },
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'demographicSection',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <p className="text-left">
      <span className="font-weight-bold">Race/Ethnicity (Check all that apply)</span>
    </p>,
    cssClassName: 'col-lg-12'
  },
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'demographicInstructionSection',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <p className="text-left">
      The growing diversity of the American Bahá&apos;í community is a source of joy and beauty. We invite
      you to share with us your racial and ethnic background by clicking on all of the following that
      apply to you. We realize this list is incomplete, and have therefore provided a text box for you to
      add other ethnic backgrounds.
    </p>,
    cssClassName: 'col-lg-12'
  },
  {
    type: CustomFieldFormType.Checkbox,
    formControlName: 'ethnicityAmericanIndianOrAlaskaNative',
    label: 'American Indian or Alaska Native',
    placeholder: '',
    help: '',
    cssClassName: 'col-lg-6 p-2'
  },
  {
    type: CustomFieldFormType.Checkbox,
    formControlName: 'ethnicityHispanicOrLatino',
    label: 'Hispanic or Latino',
    placeholder: '',
    help: '',
    cssClassName: 'col-lg-6 p-2'
  },
  {
    type: CustomFieldFormType.Checkbox,
    formControlName: 'ethnicityAsian',
    label: 'Asian',
    placeholder: '',
    help: '',
    cssClassName: 'col-lg-6 p-2'
  },
  {
    type: CustomFieldFormType.Checkbox,
    formControlName: 'ethnicityMiddleEastern',
    label: 'Middle Eastern',
    placeholder: '',
    help: '',
    cssClassName: 'col-lg-6 p-2'
  },
  {
    type: CustomFieldFormType.Checkbox,
    formControlName: 'ethnicityBlackOrAfricanAmerican',
    label: 'Black or African American',
    placeholder: '',
    help: '',
    cssClassName: 'col-lg-6 p-2'
  },
  {
    type: CustomFieldFormType.Checkbox,
    formControlName: 'ethnicityPersian',
    label: 'Persian',
    placeholder: '',
    help: '',
    cssClassName: 'col-lg-6 p-2'
  },
  {
    type: CustomFieldFormType.Checkbox,
    formControlName: 'ethnicityNativeHawaiianOtherPacific',
    label: 'Native Hawaiian or Other Pacific Islander',
    placeholder: '',
    help: '',
    cssClassName: 'col-lg-6 p-2'
  },
  {
    type: CustomFieldFormType.Checkbox,
    formControlName: 'ethnicityWhite',
    label: 'White',
    placeholder: '',
    help: '',
    cssClassName: 'col-lg-6 p-2'
  },
  {
    type: CustomFieldFormType.Checkbox,
    formControlName: 'ethnicityOther',
    label: 'Other Ethnicity (please specify)',
    placeholder: '',
    help: '',
    cssClassName: 'col-lg-12 p-2'
  },
  {
    type: CustomFieldFormType.Text,
    formControlName: 'ethnicityOtherResponseOne',
    label: 'Specify other Ethnicity 1',
    placeholder: 'Ex: Other Ethnicity',
    help: '',
    cssClassName: 'col-lg-6 p-2'
  },
  {
    type: CustomFieldFormType.Text,
    formControlName: 'ethnicityOtherResponseTwo',
    label: 'Specify other Ethnicity 2',
    placeholder: 'Ex: Other Ethnicity',
    help: '',
    cssClassName: 'col-lg-6 p-2'
  },
  {
    type: CustomFieldFormType.Text,
    formControlName: 'ethnicityOtherResponseThree',
    label: 'Specify other Ethnicity 3',
    placeholder: 'Ex: Other Ethnicity',
    help: '',
    cssClassName: 'col-lg-6 p-2'
  },
  {
    type: CustomFieldFormType.Text,
    formControlName: 'ethnicityOtherResponseFour',
    label: 'Specify other Ethnicity 4',
    placeholder: 'Ex: Other Ethnicity',
    help: '',
    cssClassName: 'col-lg-6 p-2'
  },
];
export default function DemographicForm(props: DemographicProps) {
  const [fields, useFormObject] = useCustomHookForm<DemographicResponseForm, string | number>({
    customFieldsArray: DemographicFieldsArray,
    initialValues: {
      ethnicityAmericanIndianOrAlaskaNative: false,
      ethnicityHispanicOrLatino: false,
      ethnicityAsian: false,
      ethnicityMiddleEastern: false,
      ethnicityBlackOrAfricanAmerican: false,
      ethnicityPersian: false,
      ethnicityNativeHawaiianOtherPacific: false,
      ethnicityOther: false,
      ethnicityWhite: false,
      ethnicityOtherResponseOne: '',
      ethnicityOtherResponseTwo: '',
      ethnicityOtherResponseThree: '',
      ethnicityOtherResponseFour: '',
    },
  });
  const {handleSubmit, formState: {errors, isSubmitted}} = useFormObject;
  const onSubmit = props.callbackDemographic;
  const [hasAtLeastOneEthnicity, setHasAtLeastOneEthnicity] = useState<boolean>(true)
  const validateAtLeastOneEthnicity = (fields:DemographicResponseForm)=>{
    setHasAtLeastOneEthnicity(true)
    onSubmit(fields)
  }
  return (
    <>
      <form onSubmit={handleSubmit(validateAtLeastOneEthnicity) as never}>
        <div className="row">
          {fields}
        </div>
        {isSubmitted && !hasAtLeastOneEthnicity && <Alert severity="warning">Need to choose at least one Ethnicity and if specified &quot;Other Ethnicity&quot; fill at least one of the text boxes!</Alert>}
        <div className="text-center m-2">
          <Button size="small"
                  disabled={Object.keys(errors).length > 0}
                  type="submit">
            Continue
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft/>
            ) : (
              <KeyboardArrowRight/>
            )}
          </Button>
        </div>
      </form>
    </>
  )
}
