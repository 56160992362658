import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import type { NatconArticle } from '../../Kentico/DigitalDesign/natcon_article';
import withContext from '../../ContextAPI/Context_HOC';
import NatConProxy from '../Utilities/NatConProxy';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';
import NatConCarousel2024 from './NatConCarousel2024';
import { byPinnedThenByDatePublished } from '../Utilities/NatConUtils';

const NatCon2024: FunctionComponent<{
  context: { natConProxy: NatConProxy };
}> = ({ context: { natConProxy } }) => {
  const [natconArticles, setNatconArticles] = useState([] as NatconArticle[]);
  useEffect(() => {
    const natConProxyGetNatConsSubscription = natConProxy.getNatCons('national_convention_2024').subscribe((x) => {
      const articlesSorted = [...x].sort(byPinnedThenByDatePublished);
      setNatconArticles(articlesSorted);
    });
    return () => natConProxyGetNatConsSubscription.unsubscribe();
  }, [natConProxy]);
  return (
    <div>
      <NatConCarousel2024 />
      <h1>116th US Bahá’í National Convention: April 25 – 28, 2024</h1>
      <h4>
        More photos from the Convention available at{' '}
        <a href="https://www.flickr.com/photos/bahaius/collections/72157722768523226/" target="_blank" rel="noopener noreferrer">
          Flickr
        </a>
        .
      </h4>
      <h4>
        Below are stories on the Convention from <em>The American Bahá’í</em>
      </h4>
      <hr />
      <Row>
        <Col sm={12} md={8}>
          {natconArticles.map((article, index) => {
            const introBlurbHtml = article.introBlurb.resolveHtml();
            const intro = parse(introBlurbHtml);
            const thumbnailUrl = article.articleThumbnail?.value?.length > 0 ? article.articleThumbnail.value[0].url : null;
            const thumbnailDescription =
              article.articleThumbnail?.value?.length > 0 ? article.articleThumbnail.value[0].description : '';
            return (
              <Fragment key={index + Date.now() + article.urlSlug.value + article.system.id}>
                <Link
                  to={{ pathname: `/news/natcon-2024/${article.system.codename}` }}
                  className="plain-link plain-hover"
                  key={index}
                  data-testid={`natconarticle${index}`}
                >
                  <Row>
                    <Col sm={3}>
                      {thumbnailUrl ? <img src={thumbnailUrl} alt={thumbnailDescription} className="thumbnail-img" /> : null}
                    </Col>
                    <Col sm={9}>
                      <h3>{article.baseTitle.value}</h3>
                      <div>{intro}</div>
                    </Col>
                  </Row>
                </Link>
                <hr />
              </Fragment>
            );
          })}
        </Col>
        <Col sm={12} md={4}>
          <div className="sidebar-box">
            <h4>Resources</h4>
            <ul className="no-margin">
              <li className="small-bottom-padding">
                <a
                  href="https://drive.google.com/file/d/1zpzdbtHvsnRXPm1Qgukras2AVubabY1C/view?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Swahili National Convention Report
                </a>
              </li>
              <li className="small-bottom-padding">
                <Link to="/nine-year-plan">Nine Year Plan documents</Link>
              </li>
              <li className="small-bottom-padding">
                <Link to="/guidance/uhj/ridvan-2024-message-from-the-supreme-body-2024/ridvan-2024-message-from-the-supreme-body-2024">
                  Ridvan 2024 Message from the Supreme Body
                </Link>
              </li>
              <li className="small-bottom-padding">
                <a href="https://bahai-web.s3.amazonaws.com/messages/AnnualReports/2024-US-NSA-Annual-Report.pdf">
                  Ridván 2024 Annual Report
                </a>
              </li>
            </ul>
            <hr />
            <h4>Secretary’s Report</h4>
            <iframe
              title="Secretary’s Report"
              src="https://player.vimeo.com/video/940040610?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="100%"
              height="166"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
            <p>
              <a
                href="https://player.vimeo.com/progressive_redirect/download/940040610/rendition/source/116th_us_baha%E2%80%99i_national_convention_secretary_report_2024%20%28Original%29.mp4?loc=external&signature=65223ea11a36be6342e347cba6726bf024ca391f47d12ec7cf6d82bfea8eac08"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download the video (large file)
              </a>
            </p>
            <p>
              <a
                href="https://player.vimeo.com/progressive_redirect/download/940040610/rendition/540p/116th_us_baha%E2%80%99i_national_convention_secretary_report_2024%20%28540p%29.mp4?loc=external&signature=36ead21bbefcd375524bd07964d81902ca6522673f26e1f60348ad9ca7a00b35"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download the video (small file)
              </a>
            </p>
            <iframe
              allow="autoplay"
              height="20"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1809265746%3Fsecret_token%3Ds-47NPAK9bqOC&color=%2336717a&inverse=false&auto_play=false&show_user=false"
              width="100%"
            />
            <hr />
            <h4>Treasurer’s Report</h4>
            <iframe
              title="Treasurer’s Report"
              src="https://player.vimeo.com/video/940281101?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="100%"
              height="166"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
            <p>
              <a
                href="https://player.vimeo.com/progressive_redirect/download/940281101/rendition/source/116th_us_baha%E2%80%99i_national_convention_treasurer%27s_report_2024%20%28Original%29.mp4?loc=external&signature=3b2b9242a8b8dfeb5889144241557e9858960a4fa0a5c7e67bbbd0818ec57420"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download the video (large file)
              </a>
            </p>
            <p>
              <a
                href="https://player.vimeo.com/progressive_redirect/download/940281101/rendition/540p/116th_us_baha%E2%80%99i_national_convention_treasurer%27s_report_2024%20%28540p%29.mp4?loc=external&signature=0db3111e21c74b08b53feb95ec8243c3d29fbaf0a5cd322faf13a3568f59812d"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download the video (small file)
              </a>
            </p>
            <iframe
              allow="autoplay"
              height="20"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1809757728%3Fsecret_token%3Ds-LqfRwnTia9H&color=%2336717a&inverse=false&auto_play=false&show_user=false"
              width="100%"
            />
            <hr />
            <h4>Presentation on Ḥuqúqu’lláh</h4>
            <iframe
              title="Presentation on Ḥuqúqu’lláh"
              src="https://player.vimeo.com/video/944248523?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="100%"
              height="166"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
            <hr />
            <h4>Remarks from the Continental Counselors</h4>
            <iframe
              width="100%"
              height="20"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1818459723%3Fsecret_token%3Ds-ovWgNmp4g9y&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1818460227%3Fsecret_token%3Ds-4IGAWYp4dEj&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <h4>Comments from the National Spiritual Assembly</h4>
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1823535744%3Fsecret_token%3Ds-ApP6E5zdWEy&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <h4>The Institute Process</h4>
            <iframe
              width="100%"
              height="20"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1818460587%3Fsecret_token%3Ds-WEfWMVBrgG1&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1818462126%3Fsecret_token%3Ds-a7GPY49e2o6&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1818461049%3Fsecret_token%3Ds-NE1PByDX8Cs&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1818461373%3Fsecret_token%3Ds-hBDztgi6HZq&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <h4>Educational Endeavors</h4>
            <iframe
              width="100%"
              height="20"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1818462795%3Fsecret_token%3Ds-ak2b3Wcrs65&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1818463038%3Fsecret_token%3Ds-s1o8MceYSgF&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1818463254%3Fsecret_token%3Ds-UYlYLz4GJIi&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <h4>Youth</h4>
            <iframe
              width="100%"
              height="20"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1818463524%3Fsecret_token%3Ds-nMUxiq7hRvh&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1818463746%3Fsecret_token%3Ds-0HnWPY4zK78&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1818464154%3Fsecret_token%3Ds-sRy8ZvyuUrn&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <h4>Local Spiritual Assembly</h4>
            <iframe
              width="100%"
              height="20"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1818464463%3Fsecret_token%3Ds-5Pttb2oRvXJ&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1818464685%3Fsecret_token%3Ds-5LYHsyXImK3&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <h4>Special Sessions</h4>
            <iframe
              width="100%"
              height="20"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1818465066%3Fsecret_token%3Ds-esD6qVBKEsY&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1818465402%3Fsecret_token%3Ds-yTOYffdxz1K&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <hr />
            <h4>National Convention Coverage Archives</h4>
            <ul className="plain-list">
              <li>
                <Link to="/news/natcon-2023">National Convention 2023</Link>
              </li>
              <li>
                <Link to="/news/natcon-2022">National Convention 2022</Link>
              </li>
              <li>
                <Link to="/resources/natcon-2019">National Convention 2019</Link>
              </li>
              <li>
                <Link to="/resources/natcon-2018">National Convention 2018</Link>
              </li>
              <li>
                <Link to="/resources/natcon-2017">National Convention 2017</Link>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default withContext(NatCon2024);
