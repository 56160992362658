import React, { useState, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import BoundariedRoute from '../Error/BoundariedRoute';
import withContext from '../ContextAPI/Context_HOC';
import SectGlsaLinks from './SectGlsaLinks';
import GlsaSection from './GlsaSection';
import NoMatch from '../Routers/NoMatch';
import { CircularProgress } from '@material-ui/core';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';

const ChapterGlsa = ({
  context: { glsaProxy },
  match: {
    params: { id },
  },
}) => {
  const [showCircularProgress, setShowCircularProgress] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [chapterTerm, setChapterTerm] = useState();
  const [urlsByCodename, setUrlsByCodename] = useState();
  const [showNoMatch, setShowNoMatch] = useState(false);
  const [matchingUrl, setMatchingUrl] = useState({});

  useEffect(() => {
    const subscription = glsaProxy.getChapters().subscribe({
      next: (x) => {
        const currentChapter = x.chapterTerms.find((chapterTerm) => chapterTerm.codename === id);
        const matchingUrl = x.urlsByCodename[id];
        setMatchingUrl(matchingUrl);

        if (!currentChapter) {
          setShowNoMatch(true);
          setShowCircularProgress(false);
          return;
        }
        setChapterTerm(currentChapter);
        setUrlsByCodename(x.urlsByCodename);
        setShowCircularProgress(false);
      },
      error: (e) => {
        console.error(e);
        setErrorOccurred(true);
        setShowCircularProgress(false);
      },
    });

    return () => subscription.unsubscribe();
  }, [glsaProxy, setShowCircularProgress, setErrorOccurred, setShowNoMatch, setChapterTerm, setUrlsByCodename, id]);

  return (
    <div>
      {errorOccurred ? <ErrorMessageToUser /> : null}
      {showCircularProgress ? <CircularProgress className="loading-animation" size={40} /> : null}
      {showNoMatch ? <NoMatch /> : null}
      {urlsByCodename ? (
        <div>
          <Switch>
            <BoundariedRoute
              exact
              path={`/glsa/${id}`}
              render={(props) => (
                <SectGlsaLinks
                  {...props}
                  sectionTerms={chapterTerm.terms}
                  urlsByCodename={urlsByCodename}
                  chapterTitle={chapterTerm.name}
                  matchingUrl={matchingUrl}
                />
              )}
            />
            <BoundariedRoute path={`/glsa/:id/:itemid`} component={GlsaSection} />
          </Switch>
        </div>
      ) : null}
    </div>
  );
};

export default withContext(ChapterGlsa);
