import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';
import IToken from '../../Login/IToken';
import { switchMap, take, filter, map } from 'rxjs/operators';

export default class EmailProxy {
  authService: IToken;
  apiUrl: string;
  confirmUrl: string;
  apiGetChildUrl: string;
  apiPostChildUrl: string;

  constructor(auth: IToken, apiUrl: string, confirmUrl: string, apiGetChildUrl: string, apiPostChildUrl: string) {
    this.authService = auth;
    this.apiUrl = apiUrl;
    this.confirmUrl = confirmUrl;
    this.apiGetChildUrl = apiGetChildUrl;
    this.apiPostChildUrl = apiPostChildUrl;
  }

  // Returns an observable that will continuously report the latest profile data
  get() {
    return this.authService
      .getTokenObservable()
      .pipe(filter((token) => !!token))
      .pipe(
        switchMap((token) =>
          GlobalAPICall.getObservable(this.apiUrl, {
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          })
        )
      );
  }

  getChild(childIdn: number) {
    return this.authService
      .getTokenObservable()
      .pipe(filter((token) => !!token))
      .pipe(
        switchMap((token) =>
          GlobalAPICall.postObservable(
            this.apiGetChildUrl,
            { childId: childIdn },
            {
              headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
              },
            }
          )
        )
      )
      .pipe(map((items) => items.data));
  }

  // Returns a single response observable but will also update the continuous observable from the get() method
  set(securityEmail: string, primaryEmail: string, alternateEmail: string) {
    const data = {
      alternateEmail: alternateEmail,
      alternateEmailPending: null,
      primaryEmail: primaryEmail,
      primaryEmailPending: null,
      securityEmail: securityEmail,
      securityEmailPending: null,
    };

    return this.authService
      .getTokenObservable()
      .pipe(take(1))
      .pipe(
        switchMap((token) => {
          return GlobalAPICall.postObservable(this.apiUrl, data, {
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          });
        })
      );
  }

  setChild(email: string, childId: number) {
    const data = {
      email: email,
      childId: childId,
    };

    return this.authService
      .getTokenObservable()
      .pipe(take(1))
      .pipe(
        switchMap((token) => {
          return GlobalAPICall.postObservable(this.apiPostChildUrl, data, {
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          });
        })
      )
      .pipe(map((x) => x.data));
  }

  confirm(tempCode: string) {
    return GlobalAPICall.postObservable(
      this.confirmUrl,
      { confirmationCode: tempCode },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }
}
