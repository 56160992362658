import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import withContext from '../ContextAPI/Context_HOC';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { withRouter, Link } from 'react-router-dom';
import Fund from '../assets/img/OCSHeartSign.png';

const useStyles = makeStyles({
  appStore: {
    height: '35px',
    margin: '7px 0px',
  },
  googlePlay: {
    height: '50px',
  },
  fundHeart: {
    height: '20px',
    padding: '5px 5px 0px 0px',
  },
  fundStatement: {
    display: 'inherit',
    textDecoration: 'none',
  },
});

function Footer(props) {
  const classes = useStyles();
  return (
    <div className="full-width-view center">
      <footer className={(props.context.isDrawerOpen && 'footerShift').toString()}>
        <Row>
          <Col sm className="mobile-hide start">
            <p>
              <strong>Bahá’í National Center</strong> <br />{' '}
              <Link to="/resources/service-opportunities">Service Opportunities</Link>
              <br /> <Link to="/directories/national">Contact Information</Link>
              <br />
              <a href="mailto:bnohelpdesk@usbnc.org">Technical Support</a>
            </p>
          </Col>
          <Col sm className="center">
            <Row center="xs">
              <Link to="/resources/fund" className={classes.fundStatement}>
                <img src={Fund} className={classes.fundHeart} alt="Fund Heart Logo" />
                <p>Brought to you by The National Bahá’í Fund</p>
              </Link>
            </Row>
          </Col>
          <Col sm className="mobile-hide end">
            <p>
              <strong>Bahá’í House of Worship</strong>
              <br /> 100 Linden Ave
              <br /> Wilmette, IL 60091
              <br />
              <a href="tel:18478532300">(847) 853-2300</a>
            </p>
          </Col>
        </Row>
        <br />
        <p className="xs-text medium-faded-text">&copy; 2021 National Spiritual Assembly of the Bahá’ís of the United States</p>
      </footer>
    </div>
  );
}

export default withRouter(withContext(withStyles({ withTheme: true })(Footer)));
