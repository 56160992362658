import React, { useState, useEffect } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import { ListItemText } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';
import Downloads from '../Common-Components/Downloads';
import HuquqPageEndpoint from './Utilities/HuquqPageEndpoint';
import HuquqPageTemplate from './HuquqPageTemplate';
import HuquqTopImg from '../assets/img/Huquq-top-img.jpg';
import ErroMessageToUser from '../Error/ErrorMessageToUser';

const HuquqResources = (props) => {
  const [error, setError] = useState();
  const [huquqData, setHuquqData] = useState([
    {
      system: { name: '' },
      base__title: { value: '' },
      base__body: { value: '' },
      base__datepublished: { value: '' },
      huquqpage: { value: [] },
      linked_items: { value: [] },
      attachments: { value: [] },
    },
  ]);
  const [huquqSubData, setHuquqSubData] = useState([
    {
      system: { name: '' },
      base__title: { value: '' },
      base__body: { value: '' },
      base__datepublished: { value: '' },
      huquqpage: { value: [] },
      linked_items: { value: [] },
      attachments: {
        value: [
          {
            name: '',
            type: '',
            url: '',
            description: '',
          },
        ],
      },
    },
  ]);
  const contentNameFromUrls =
    props.location.pathname === '/huququllah-resources' ? null : props.location.pathname.replace(/^\/huququllah-resources\//, '');
  const contentNameFromUrlsArray = contentNameFromUrls ? contentNameFromUrls.split('/') : null;
  const contentNameFromUrl = contentNameFromUrlsArray ? contentNameFromUrlsArray[0] : null;
  const subContentNameFromUrl = contentNameFromUrlsArray && contentNameFromUrlsArray[1] ? contentNameFromUrlsArray[1] : null;
  useEffect(() => {
    const subscription = HuquqPageEndpoint(props.context, contentNameFromUrl).subscribe({
      next: (x) => {
        if (x.items) {
          setHuquqData(x.items);
        }
      },
      error: (x) => setError(x),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [props.context, contentNameFromUrl]);

  useEffect(() => {
    const subscription = subContentNameFromUrl
      ? HuquqPageEndpoint(props.context, subContentNameFromUrl).subscribe({
          next: (x) => {
            if (x.items) {
              setHuquqSubData(x.items);
            }
          },
          error: (x) => setError(x),
        })
      : { unsubscribe: () => {} };
    return () => {
      subscription.unsubscribe();
    };
  }, [props.context, subContentNameFromUrl]);

  let attachments = '';
  if (contentNameFromUrl && huquqData[0].attachments.value.length === 0) {
    attachments = '';
  } else {
    attachments = (
      <span>
        <h4 className="sidebar-headers">Attachments</h4>
        <Row className="attachments">
          {huquqData[0].attachments.value.map((value, key) => (
            <Downloads key={key} links={value} />
          ))}
        </Row>
      </span>
    );
  }

  return (
    <div>
      {error ? (
        <ErroMessageToUser />
      ) : (
        <Row>
          <div className="center">
            <img
              src={HuquqTopImg}
              alt="The Deputy Trustees of Ḥuqúqu’lláh in the United States"
              className="wider full-width-view"
            />
          </div>
          <Col sm={12} md={8}>
            {contentNameFromUrl ? (
              <div>
                <HuquqPageTemplate data={huquqData[0]} />
              </div>
            ) : (
              <div>
                <ul className="plain-list">
                  {huquqData
                    .filter((eachValue) => eachValue.huquqpage.value.length > 0)
                    .map((eachValue, key) => (
                      <li key={key}>
                        <Link
                          data-testid="huquq-root-links"
                          to={'/huququllah-resources/' + encodeURIComponent(eachValue.system.codename)}
                        >
                          <ListItemText primary={eachValue.base__title.value} />
                        </Link>
                      </li>
                    ))}
                  <li>
                    <Link to="/news/right_of_god/a-loving-companion-haji-amin">
                      <ListItemText primary="Story of Hájí Amín" />
                    </Link>
                  </li>
                </ul>
              </div>
            )}
            {subContentNameFromUrl && (
              <div>
                <HuquqPageTemplate data={huquqSubData[0]} />
              </div>
            )}
          </Col>
          <Col sm={12} md={4} className="related">
            <h4 className="sidebar-headers">Directories</h4>
            <Link to="/directories/huququllah-trustees">
              <ListItemText primary={'Deputy Trustees'} />
            </Link>
            <Link to="/directories/huququllah-representatives">
              <ListItemText primary={'Representatives'} />
            </Link>
            {contentNameFromUrl && (
              <div>
                <hr />
                <div data-testid="attachmentslist">{attachments}</div>
              </div>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};
export default withContext(HuquqResources);
