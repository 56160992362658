import setupAccValidation from './SetupAccValidation';
import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';
import SafeStringifyAxiosError from '../../Utilities/SafeStringifyAxiosError';
export interface SetupAccountUserInput {
  email: string;
  confirmEmail: string;
  password: string;
  confirmPassword: string;
  question1Id: string;
  question1Answer: string;
  question2Id: string;
  question2Answer: string;
  question3Id: string;
  question3Answer: string;
}
export default function setupAccount(
  token: string,
  bid: string,
  accountidn: string,
  userinput: SetupAccountUserInput,
  temporaryCode?: string
) {
  const errormessage = setupAccValidation(userinput);

  if (errormessage !== false) {
    return errormessage;
  }
  return GlobalAPICall.post(
    process.env.REACT_APP_AUTH_API_URL + '/api/registration/step3',
    {
      Accountindentifier: accountidn,
      TemporaryCode: temporaryCode ?? null,
      UserName: bid,
      Email: userinput.email,
      ConfirmEmail: userinput.confirmEmail,
      Password: userinput.password,
      ConfirmPassword: userinput.confirmPassword,
      Question1Id: userinput.question1Id,
      Question1Answer: userinput.question1Answer,
      Question2Id: userinput.question2Id,
      Question2Answer: userinput.question2Answer,
      Question3Id: userinput.question3Id,
      Question3Answer: userinput.question3Answer,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    }
  )
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      if (err.response.status === 400) {
        return Promise.reject({
          status: err.response.status,
          message: SafeStringifyAxiosError(err),
        });
      } else {
        return Promise.reject({
          status: err.response.status,
          message: 'Our services are currently unavailable. Please come back later.',
        });
      }
    });
}
