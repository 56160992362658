import React, { useState, useEffect } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import { Row, Col } from 'react-flexbox-grid';
import { NavLink } from 'react-router-dom';

const HuquqRep = (props) => {
  const region = props.region;
  const { defaultmessage } = useState('Currently loading data. Please wait several seconds!');
  const [repData, setRepData] = useState([]);

  const setPageTitle = props.setPageTitle;
  useEffect(() => setPageTitle('Ḥuqúqu’lláh Contacts'), [setPageTitle]);

  useEffect(() => {
    const subscription = props.context.huquqService.getRepresentatives(region).subscribe((x) => setRepData(x));
    return () => {
      subscription.unsubscribe();
    };
  }, [props.context.huquqService, region]);
  return (
    <div>
      <Row end="sm">
        <Col sm={4}>
          <p>
            <NavLink data-cy="trusteeViewLink" className="huquq-navlink" to="/directories/huququllah-trustees">
              View Deputy Trustee Directory
            </NavLink>
          </p>
          <p>
            <NavLink data-cy="repViewLink" className="huquq-navlink" to="/directories/huququllah-representatives">
              View Representative Directory
            </NavLink>
          </p>
        </Col>
      </Row>
      <div data-cy="displaydirectory">
        {repData.length === 0 ? (
          defaultmessage
        ) : (
          <Row>
            <Col md={6} data-cy="firstCol">
              {repData.slice(0, Math.round(repData.length / 2)).map((item, index) => (
                <Row key={index} className="bottom-padding">
                  <div key={index}>
                    <p className="reduce-space-lines">
                      <strong>{item.name}</strong>
                    </p>
                    <p className="reduce-space-lines">{item.areas?.join(', ')}</p>
                    <p className="reduce-space-lines">
                      <a href={'tel:' + item.cell}>{item.cell}</a>
                    </p>
                    <p className="reduce-space-lines">
                      <a href={'mailto:' + item.email}>{item.email}</a>
                    </p>
                  </div>
                </Row>
              ))}
            </Col>
            <Col md={6} data-cy="secondCol">
              {repData.length > 1
                ? repData.slice(Math.round(repData.length / 2), repData.length).map((item, index) => (
                    <Row key={index} className="bottom-padding">
                      <div key={index}>
                        <p className="reduce-space-lines">
                          <strong>{item.name}</strong>
                        </p>
                        <p className="reduce-space-lines">{item.areas?.join(', ')}</p>
                        <p className="reduce-space-lines">
                          <a href={'tel:' + item.cell}>{item.cell}</a>
                        </p>
                        <p className="reduce-space-lines">
                          <a href={'mailto:' + item.email}>{item.email}</a>
                        </p>
                      </div>
                    </Row>
                  ))
                : null}
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default withContext(HuquqRep);
