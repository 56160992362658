import React, { Component } from 'react';
import withContext from '../../ContextAPI/Context_HOC';
import { Row, Col } from 'react-flexbox-grid';
import Contact from '../../assets/img/TreasurersTools/Contact.png';
import FAQ from '../../assets/img/TreasurersTools/FAQandInfo.png';
import Telephone from '../../assets/img/TreasurersTools/Telephone.png';
import Email from '../../assets/img/TreasurersTools/Email.png';
import Address from '../../assets/img/TreasurersTools/Address.png';
import Texting from '../../assets/img/TreasurersTools/Texting.png';

class TreasurersContact extends Component {
  render() {
    return (
      <Row>
        <Col xs={12} md={6}>
          <Row>
            <Col sm={2}>
              <img src={Contact} alt="Contact Us" className="fund-logo" />
            </Col>
            <Col sm={10}>
              <h1>Questions and Concerns</h1>
              <h3>Contact Us...</h3>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/treas-lsa-ocs-faq.pdf" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={FAQ} alt="FAQ" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>FAQ for the Local Fund Online Program (PDF)</h4>
              </Col>
            </Row>
          </a>
          <br />
          <Row className="fund-rows">
            <Col xs={3} lg={2}>
              <img src={Telephone} alt="Telephone" className="fund-icons" />
            </Col>
            <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
              <h4>
                Treasurer’s Office:{' '}
                <a href="tel:18477333472" className="plain-link">
                  (847) 733-3472
                </a>
              </h4>
            </Col>
          </Row>
          <br />
          <a href="mailto:BahaiTreasurersOffice@usbnc.org" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={Email} alt="Email" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>BahaiTreasurersOffice@usbnc.org</h4>
              </Col>
            </Row>
          </a>
          <br />
          <Row className="fund-rows">
            <Col xs={3} lg={2}>
              <img src={Texting} alt="Texting" className="fund-icons" />
            </Col>
            <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
              <h4>Text your questions and concerns to:</h4>
              <p>
                Text <a href="sms://+18477333472;?&body=Treasurer%3A%20">847-733-3472</a> and in the message box write
                &ldquo;Treasurer&rdquo;.
              </p>
            </Col>
          </Row>
          <br />
          <Row className="fund-rows">
            <Col xs={3} lg={2}>
              <img src={Address} alt="Address" className="fund-icons" />
            </Col>
            <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
              <h4>Mailing Address:</h4>
              <p>
                Bahá’í National Center Offices
                <br />
                1233 Central Street
                <br />
                Evanston, IL 60201-1611
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default withContext(TreasurersContact);
