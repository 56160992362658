import React, { Component } from 'react';
import withContext from '../../ContextAPI/Context_HOC';
import { Row, Col } from 'react-flexbox-grid';
import Audit from '../../assets/img/TreasurersTools/Audit.png';
import AuditWorksheet from '../../assets/img/TreasurersTools/AuditWorksheet.png';
import AuditStatement from '../../assets/img/TreasurersTools/AuditStatement.png';

class TreasurersAccounting extends Component {
  render() {
    return (
      <Row>
        <Col xs={12} md={6}>
          <Row>
            <Col sm={2}>
              <img src={Audit} alt="Audit" className="fund-logo" />
            </Col>
            <Col sm={10}>
              <h1>Audit</h1>
              <p>
                <strong>Note:</strong> For PDFs that you can edit on your computer, such as the Audit Worksheet Form, either
                download the file before making edits to the form or make edits within your browser and print directly.
              </p>
              <p>
                To stay connected to Treasurer information and upcoming Important dates, text 847–733–3472 and in the message box
                write &ldquo;Treasurer&rdquo;. Have questions? After signing up, text 847–733–3472 and in the message box write
                your question.
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <a
            href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/2021-2022-audit-worksheet-for-local-spiritual-assemblies.xlsx"
            className="plain-link"
          >
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={AuditWorksheet} alt="Audit Worksheet" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Audit Worksheet (XLSX)</h4>
              </Col>
            </Row>
          </a>
          <br />
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/treas-audit-selection-statement.pdf" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={AuditStatement} alt="Audit Statement" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Statement on Audit of LSA (PDF)</h4>
              </Col>
            </Row>
          </a>
          <br />
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/treas-lsa-audit-benchmark.pdf" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={Audit} alt="Benchmark" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Audit Benchmark Policy for LSAs (PDF)</h4>
              </Col>
            </Row>
          </a>
        </Col>
      </Row>
    );
  }
}

export default withContext(TreasurersAccounting);
