import React, { useState, useEffect, useCallback } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import { Row, Col } from 'react-flexbox-grid';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';
import { CircularProgress, MenuItem } from '@material-ui/core';
import RaceEthnicityComponent from './Utilities/RaceEthnicityComponent';
import useForm from '../Utilities/useForm';
import MembershipNote from './MembershipNote';
import { COMMON_VALIDATORS } from '../Utilities/validators';
import MyProfileProxy from './Utilities/MyProfileProxy';
import MyProfileResponseModel from './Utilities/MyProfileResponseModel';
import { PropertyAccessor } from '../Shared/Types/PropertyAccessor';

const initialState = {
  RaceEthnicitySelections: { value: [] },
};
const validationSchema = {
  RaceEthnicitySelections: {
    required: false,
    validator: COMMON_VALIDATORS.maxLength(50, 'Ethnicity'),
  },
};

interface PersonalInfoProps {
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  context: { myProfileProxy: MyProfileProxy };
}

const PersonalInfo = (props: PersonalInfoProps) => {
  const setPageTitle = props.setPageTitle;
  useEffect(() => setPageTitle('Personal Information'), [setPageTitle]);
  const [messageToUser, setMessageToUser] = useState<JSX.Element | null>(null);
  const [badErrorMessage, setBadErrorMessage] = useState<JSX.Element | null>(null);
  const [showCircularProgress, setShowCircularProgress] = useState(true);
  const [staticState, setStaticState] = useState<PropertyAccessor>({
    dateOfBirth: 'Loading...',
    ageGroup: '',
    cityOfBirth: 'Loading...',
    stateOfBirth: 'Loading...',
    countryOfBirth: 'Loading...',
    gender: 'Loading...',
    title: (
      <MenuItem selected disabled>
        Loading...
      </MenuItem>
    ),
    suffix: (
      <MenuItem selected disabled>
        Loading...
      </MenuItem>
    ),
    raceEthnicityOptions: undefined,
  });

  const myProfileProxy = props.context.myProfileProxy;

  const submit = useCallback(
    (state: PropertyAccessor) => {
      const newProfileData = {
        raceEthnicitySelections: state.RaceEthnicitySelections.value,
      };
      setShowCircularProgress(true);
      setMessageToUser(null);

      const myProfileProxySetRegistration = myProfileProxy.set(newProfileData as MyProfileResponseModel).subscribe({
        next: () => {
          setMessageToUser(
            <p className="success-message wider" data-cy="success-message">
              Successfully Updated!
            </p>
          );
          setShowCircularProgress(false);
        },
        error: (e) => {
          if (e.response?.status === 400 && e.response?.data) {
            const message = e.response.data.message ?? JSON.stringify(e.response?.data);
            setMessageToUser(
              <p className="error-message wider" data-cy="unsuccess-message">
                {message}
              </p>
            );
          } else {
            setBadErrorMessage(<ErrorMessageToUser />);
          }
          setShowCircularProgress(false);
        },
      });

      return () => {
        if (myProfileProxySetRegistration) {
          myProfileProxySetRegistration.unsubscribe();
        }
      };
    },
    [myProfileProxy]
  );

  const onInvalidSubmitAttempt = useCallback(() => {
    setMessageToUser(
      <p data-cy="unsuccess-message" className="error-message wider">
        Please check the highlighted field(s) and resolve the error(s).
      </p>
    );
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const { state, setState, handleValidationOnChange, handleOnSubmit } = useForm(
    initialState,
    validationSchema,
    submit,
    onInvalidSubmitAttempt
  );

  useEffect(() => {
    const observable = myProfileProxy.get();
    const subscription = observable.subscribe({
      next: (res) => {
        const raceEthnicityOptions = res.raceEthnicityOptions;
        setStaticState({
          dateOfBirth: res.dateOfBirth,
          ageGroup: res.ageGroup,
          cityOfBirth: res.cityOfBirth,
          stateOfBirth: res.stateOfBirth,
          countryOfBirth: res.countryOfBirth,
          gender: res.gender,
          raceEthnicityOptions: raceEthnicityOptions,
        });
        setState({
          RaceEthnicitySelections: { value: res.raceEthnicitySelections },
        });
        setShowCircularProgress(false);
      },
      error: () => {
        setBadErrorMessage(<ErrorMessageToUser />);
        setShowCircularProgress(false);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [myProfileProxy, setState]);

  return (
    <div>
      {badErrorMessage ?? (
        <div>
          {showCircularProgress ? (
            <CircularProgress data-cy="personal-info-circular-progress" className="loading-animation" size={40} />
          ) : (
            <div>{messageToUser}</div>
          )}
          {state ? (
            <div className="profile-box">
              <Row>
                <Col xs={12} sm={4}>
                  <p>Birthdate</p>
                </Col>
                <Col xs={12} sm={8}>
                  <p>
                    <strong>
                      {staticState.dateOfBirth} {staticState.ageGroup}{' '}
                    </strong>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={4}>
                  <p>City of Birth</p>
                </Col>
                <Col xs={12} sm={8}>
                  <p>
                    <strong>{staticState.cityOfBirth}</strong>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={4}>
                  <p>State of Birth</p>
                </Col>
                <Col xs={12} sm={8}>
                  <p>
                    <strong>{staticState.stateOfBirth}</strong>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={4}>
                  <p>Birth Country</p>
                </Col>
                <Col xs={12} sm={8}>
                  <p>
                    <strong>{staticState.countryOfBirth}</strong>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={4}>
                  <p>Gender</p>
                </Col>
                <Col xs={12} sm={8}>
                  <p>
                    <strong>{staticState.gender}</strong>
                  </p>
                </Col>
              </Row>
              <br />
              <RaceEthnicityComponent
                raceEthnicityOptions={staticState.raceEthnicityOptions}
                value={state.RaceEthnicitySelections.value}
                onChange={handleValidationOnChange}
                name="RaceEthnicitySelections"
              />
              {state.RaceEthnicitySelections.error && (
                <Row>
                  <Col sm={12}>
                    <p className="form-field-error" data-cy="RaceEthnicitySelections_field_error">
                      {state.RaceEthnicitySelections.error}
                    </p>
                  </Col>
                </Row>
              )}
              <MembershipNote />
            </div>
          ) : null}
          <div className="medium-top-margin end">
            <input type="submit" value="Save" className="primary-button" data-cy="inputsubmit" onClick={handleOnSubmit} />
          </div>
        </div>
      )}
    </div>
  );
};

export default withContext(PersonalInfo);
