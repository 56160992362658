import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import type { NatconArticle } from '../../Kentico/DigitalDesign/natcon_article';
import withContext from '../../ContextAPI/Context_HOC';
import NatConProxy from '../Utilities/NatConProxy';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';
import NatConCarousel2023 from './NatConCarousel2023';
import { byPinnedThenByDatePublished } from '../Utilities/NatConUtils';

const NatCon2023: FunctionComponent<{
  context: { natConProxy: NatConProxy };
}> = ({ context: { natConProxy } }) => {
  const [natconArticles, setNatconArticles] = useState([] as NatconArticle[]);
  useEffect(() => {
    const natConProxyGetNatConsSubscription = natConProxy.getNatCons('national_convention_2023').subscribe((x) => {
      const articlesSorted = [...x].sort(byPinnedThenByDatePublished);
      setNatconArticles(articlesSorted);
    });
    return () => natConProxyGetNatConsSubscription.unsubscribe();
  }, [natConProxy]);
  return (
    <div>
      <NatConCarousel2023 />
      <h1>115th US Bahá’í National Convention: May 18 – 21, 2023</h1>
      <h4>
        More photos from the Convention available at{' '}
        <a href="https://www.flickr.com/photos/bahaius/collections/72157721746069947/" target="_blank" rel="noopener noreferrer">
          Flickr
        </a>
        .
      </h4>
      <h4>
        Below are stories on the Convention from <em>The American Bahá’í</em>
      </h4>
      <hr />
      <Row>
        <Col sm={12} md={8}>
          {natconArticles.map((article, index) => {
            const introBlurbHtml = article.introBlurb.resolveHtml();
            const intro = parse(introBlurbHtml);
            const thumbnailUrl = article.articleThumbnail?.value?.length > 0 ? article.articleThumbnail.value[0].url : null;
            const thumbnailDescription =
              article.articleThumbnail?.value?.length > 0 ? article.articleThumbnail.value[0].description : '';
            return (
              <Fragment key={index + Date.now() + article.urlSlug.value + article.system.id}>
                <Link
                  to={{ pathname: `/news/natcon-2023/${article.system.codename}` }}
                  className="plain-link plain-hover"
                  key={index}
                  data-testid={`natconarticle${index}`}
                >
                  <Row>
                    <Col sm={3}>
                      {thumbnailUrl ? <img src={thumbnailUrl} alt={thumbnailDescription} className="thumbnail-img" /> : null}
                    </Col>
                    <Col sm={9}>
                      <h3>{article.baseTitle.value}</h3>
                      <div>{intro}</div>
                    </Col>
                  </Row>
                </Link>
                <hr />
              </Fragment>
            );
          })}
        </Col>
        <Col sm={12} md={4}>
          <div className="sidebar-box">
            <h4>Secretary’s Report</h4>
            <iframe
              title="Secretary’s Report"
              src="https://player.vimeo.com/video/828498465?h=0ffb5f9edc"
              width="100%"
              height="166"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
            <p>
              <a
                href="https://player.vimeo.com/progressive_redirect/playback/828498465/rendition/1080p/file.mp4?loc=external&log_user=0&signature=7fb2015f27dd105cab68dc3f91a4088ead2264e335c93415cf4e1357bdbc1fdc"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download the video
              </a>
            </p>
            <hr />
            <h4>Treasurer’s Report</h4>
            <iframe
              title="Treasurer’s Report"
              src="https://player.vimeo.com/video/828504023?h=3e3e408818"
              width="100%"
              height="166"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
            <p>
              <a
                href="https://player.vimeo.com/progressive_redirect/playback/828504023/rendition/1080p/file.mp4?loc=external&signature=94143f10a7b39ed0807a79df5917fdf00da3aa4f93537fc9a41d3458eb9e7088"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download the video
              </a>
            </p>
            <hr />
            <h4>Resources</h4>
            <ul>
              <li className="small-bottom-padding">
                <Link to="/nine-year-plan">Nine Year Plan documents</Link>
              </li>
              <li className="small-bottom-padding">
                <Link to="/guidance/uhj/ridvan-2023-message-from-the-supreme-body-2023/ridvan-2023-message-from-the-supreme-body-2023">
                  Ridvan 2023 Message from the Supreme Body
                </Link>
              </li>
              <li className="small-bottom-padding">
                <Link to="/guidance/national_spiritual_assembly/national-spiritual-assembly-shares-ridvan-2023-annual-report-2023/national-spiritual-assembly-shares-ridvan-2023-annual-report-2023">
                  Ridván 2023 Annual Report
                </Link>
              </li>
              <li className="small-bottom-padding">
                <Link to="/guidance/uhj/universal-house-of-justice-responds-to-message-from-2023-national-convention-2023">
                  Universal House of Justice Responds to Message from 2023 National Convention
                </Link>
              </li>
            </ul>
            <hr />
            <h4>Remarks from the Continental Counselors</h4>
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527047419&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527047104&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527047449&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527047368&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <hr />
            <h4>Administration</h4>
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527039316&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527039292&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527039244&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527039217&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527039184&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527039172&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527039136&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527039115&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527039085&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <hr />
            <h4>Love</h4>
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527049726&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527049684&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <hr />
            <h4>Race</h4>
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527050737&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527050716&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <hr />
            <h4>Social Action</h4>
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527052213&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527052138&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <hr />
            <h4>Youth</h4>
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527054226&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527054202&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527054148&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527054064&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <iframe
              width="100%"
              height="20"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1527053986&color=%2336717a&inverse=false&auto_play=false&show_user=false"
            />
            <hr />
            <h4>National Convention Coverage Archives</h4>
            <ul className="plain-list">
              <li>
                <Link to="/news/natcon-2022">National Convention 2022</Link>
              </li>
              <li>
                <Link to="/resources/natcon-2019">National Convention 2019</Link>
              </li>
              <li>
                <Link to="/resources/natcon-2018">National Convention 2018</Link>
              </li>
              <li>
                <Link to="/resources/natcon-2017">National Convention 2017</Link>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default withContext(NatCon2023);
