enum FeatureLocalSetting {
  /**
   * Ignores value from cloud feature provider (e.g. Optimizely) and forces flag to OFF
   */
  ForcedOff = 'OFF',

  /**
   * Does not force any value, uses value received from cloud feature provdier (e.g. Optimizely)
   */
  UseCloud = 'AUTO',

  /**
   * Ignores value from cloud feature provider (e.g. Optimizely) and forces flag to ON
   */
  ForcedOn = 'ON',
}

export default FeatureLocalSetting;
