import parse from 'html-react-parser';
export default function DataCleansing(item) {
  let innerhtmlstring = '';
  let concatenatedInnerHtml = '';

  // Data cleansing!
  innerhtmlstring = item.base__body.value.replace(/<p>/g, '<p> ');
  innerhtmlstring = innerhtmlstring.split(' ');

  for (let i = 0; innerhtmlstring.length < 75 ? i < innerhtmlstring.length : i < 75; i++) {
    // Data cleansing!
    innerhtmlstring[i] = innerhtmlstring[i].replace(/<p>/g, '');
    innerhtmlstring[i] = innerhtmlstring[i].replace(/<\/p>/g, '');
    innerhtmlstring[i] = innerhtmlstring[i].replace(/<br>/g, '');
    innerhtmlstring[i] = innerhtmlstring[i].replace(/<object>/g, '');
    innerhtmlstring[i] = innerhtmlstring[i].replace(/<\/object>/g, '');
    innerhtmlstring[i] = innerhtmlstring[i].replace(/<object/g, '');

    if (
      !(
        innerhtmlstring[i].includes('data-type="item"') ||
        innerhtmlstring[i].includes('data-rel="component"') ||
        innerhtmlstring[i].includes('data-codename="') ||
        innerhtmlstring[i].includes('type="application/kenticocloud"')
      )
    )
      concatenatedInnerHtml = concatenatedInnerHtml + innerhtmlstring[i] + ' ';
  }

  return parse(concatenatedInnerHtml + '...');
}
