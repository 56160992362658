import React, { useEffect } from 'react';
import withContext from '../../ContextAPI/Context_HOC';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
import AutoDownload from '../../Common-Components/AutoDownload';
import ServiceOpsRichTextResolver from '../Utilities/ServiceOpsRichTextResolver';
import { dateStringToMonthYear, sortServicesByDateCountry } from '../../Utilities/UtilityFunctions';

const abortControllerglob = new AbortController();
function International(props) {
  const [value, setValue] = React.useState(1);
  const [serviceOpsList, setServiceOpsList] = React.useState([]);
  const contentProvider = props.context.digitalDesignContent;

  useEffect(() => {
    const subscription = contentProvider
      .items()
      .type('service_opportunities')
      .containsFilter('elements.service_opportunities_taxonomy', ['international'])
      .elementsParameter(['base__title', 'base__body', 'base__datepublished', 'department', 'location', 'document_download'])
      .orderByAscending('elements.location')
      .toObservable()
      .subscribe(async (response) => {
        if (!response.isEmpty) {
          setServiceOpsList(ServiceOpsRichTextResolver(sortServicesByDateCountry(response.items)));
        }
      });
    return () => subscription.unsubscribe();
  }, [contentProvider]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <h1>International Service Opportunities</h1>
      <Paper>
        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
          <Tab label="United States" component={Link} data-cy="us-opportunities" to="/resources/service-opportunities" />
          <Tab label="International" component={Link} data-cy="international-opportunities" to="/resources/international" />
          <Tab label="World Center" component={Link} data-cy="world-center-opportunities" to="/resources/world-center" />
        </Tabs>
      </Paper>
      <br />
      {serviceOpsList.map((job, key) => (
        <div key={key} data-cy={`country${key}`}>
          <h3>
            {job.location.value} - {job.base__title.value}{' '}
          </h3>
          <h6 data-cy="date-posted">{dateStringToMonthYear(job.base__datepublished.rawData.value)}</h6>
          <div data-cy="description">{job.base__body.value}</div>
          {job.document_download.value.length > 0 ? (
            <AutoDownload
              url={job.document_download.value[0].url}
              fileName={job.document_download.value[0].name}
              downloadText="Download Flyer"
            />
          ) : null}
          {abortControllerglob.abort()}
          <hr />
        </div>
      ))}
    </div>
  );
}

export default withContext(International);
