export default function CustomFilterAndSearch(term, rowData, field) {
  if (!term || !rowData) {
    return true;
  }

  if (typeof term === 'object') {
    if (field.startsWith('lookupList')) {
      if (!term.length) {
        return true;
      }
      const fieldName = field.substring(11);
      return !!term.filter((singleTerm) => rowData[fieldName].includes(singleTerm)).length;
    } else {
      const indexNumber = term.findIndex((lookupValue) => {
        return rowData.areas.includes(lookupValue);
      });

      if (indexNumber === -1) {
        return false;
      } else {
        return true;
      }
    }
  } else if (typeof term === 'string') {
    let isIncludedTerm = false;

    //HuquqRep
    if (field === 'areas') isIncludedTerm = rowData[field].findIndex((item) => item.toUpperCase().includes(term.toUpperCase()));

    //AbmDirectory
    if (field === 'areaClusters')
      isIncludedTerm = rowData[field].findIndex(
        (item) => item.value.toUpperCase().includes(term.toUpperCase()) || item.key.toUpperCase().includes(term.toUpperCase())
      );

    if (field === 'nameAddressABMD') {
      isIncludedTerm = rowData.fullName.split().findIndex((item) => item.toUpperCase().includes(term.toUpperCase()));

      if (isIncludedTerm === -1 && rowData.addressLine1)
        isIncludedTerm = rowData.addressLine1.split().findIndex((item) => item.toUpperCase().includes(term.toUpperCase()));
      if (isIncludedTerm === -1 && rowData.city)
        isIncludedTerm = rowData.city.split().findIndex((item) => item.toUpperCase().includes(term.toUpperCase()));
      if (isIncludedTerm === -1 && rowData.state)
        isIncludedTerm = rowData.state.split().findIndex((item) => item.toUpperCase().includes(term.toUpperCase()));
      if (isIncludedTerm === -1 && rowData.zip)
        isIncludedTerm = rowData.zip.split().findIndex((item) => item.toUpperCase().includes(term.toUpperCase()));
      if (isIncludedTerm === -1 && rowData.email)
        isIncludedTerm = rowData.email.split().findIndex((item) => item.toUpperCase().includes(term.toUpperCase()));
      if (isIncludedTerm === -1 && rowData.cellPhone)
        isIncludedTerm = rowData.cellPhone.split().findIndex((item) => item.toUpperCase().includes(term.toUpperCase()));
      if (isIncludedTerm === -1 && rowData.workPhone)
        isIncludedTerm = rowData.workPhone.split().findIndex((item) => item.toUpperCase().includes(term.toUpperCase()));
      if (isIncludedTerm === -1 && rowData.homePhone)
        isIncludedTerm = rowData.homePhone.split().findIndex((item) => item.toUpperCase().includes(term.toUpperCase()));
    }
    // AssemblyGroups
    if (field === 'nameAddressAG') {
      if (rowData.name)
        isIncludedTerm = rowData.name.split().findIndex((item) => item.toUpperCase().includes(term.toUpperCase()));

      if (isIncludedTerm === -1 && rowData.address.line1)
        isIncludedTerm = rowData.address.line1.split().findIndex((item) => item.toUpperCase().includes(term.toUpperCase()));

      if (isIncludedTerm === -1 && rowData.address.city)
        isIncludedTerm = rowData.address.city.split().findIndex((item) => item.toUpperCase().includes(term.toUpperCase()));

      if (isIncludedTerm === -1 && rowData.address.state)
        isIncludedTerm = rowData.address.state.split().findIndex((item) => item.toUpperCase().includes(term.toUpperCase()));

      if (isIncludedTerm === -1 && rowData.address.zipcode)
        isIncludedTerm = rowData.address.zipcode.split().findIndex((item) => item.toUpperCase().includes(term.toUpperCase()));
    }

    if (isIncludedTerm === -1) return false;
    else return true;
  }
}
