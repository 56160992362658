import React, { FC } from 'react';
import FeatureKey from './FeatureKey';
import FeatureFlagToggle from './FeatureFlagToggle';

const FeatureFlagToggleAll: FC = () => (
  <>
    {Object.values(FeatureKey)
      .filter((x) => x !== FeatureKey.GetFundStatusFromOCS && x !== FeatureKey.SpeechSynthesIsEnabled)
      .map((featureKey, index) => (
        <FeatureFlagToggle key={index} featureKey={featureKey} />
      ))}
  </>
);

export default FeatureFlagToggleAll;
