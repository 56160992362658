import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import withContext from '../ContextAPI/Context_HOC';
import SearchIcon from '@material-ui/icons/Search';
import HelpIcon from '@material-ui/icons/Help'; // feedback
import NewReleasesIcon from '@material-ui/icons/NewReleases'; //update
import AccountCircleIcon from '@material-ui/icons/AccountCircle'; //profile
import AppsIcon from '@material-ui/icons/Apps'; //tools
import parse from 'html-react-parser';
import RecentFeaturesListReference from './RecentFeaturesListReference';
import WebsiteUpdatesListOrRecentEndpoint from './Utilities/WebsiteUpdatesListOrRecentEndpoint';
import WebsiteUpdatesArticleEndpoint from './Utilities/WebsiteUpdatesArticleEndpoint';

var displayItemDetailsVar = [];
const WebsiteUpdatesArticle = (props) => {
  const [websiteData, setWebsiteData] = useState([
    {
      base__title: { value: '' },
      base__datepublished: { value: '' },
      base__body: { value: '', resolvedData: { html: '' } },
      url_slug: { value: '' },
      feature_image: {
        value: [
          {
            url: '',
            description: '',
          },
        ],
      },
    },
  ]);
  useEffect(() => {
    const subscription = WebsiteUpdatesArticleEndpoint(props, setWebsiteData);
    return () => {
      subscription.unsubscribe();
    };
  }, [props]);

  const [websitePreviousData, setWebsitePreviousData] = useState([
    {
      base__title: { value: '' },
      base__datepublished: { value: '' },
      base__body: { value: '', resolvedData: { html: '' } },
      url_slug: { value: '' },
      feature_image: {
        value: [
          {
            url: '',
            description: '',
          },
        ],
      },
    },
  ]);
  useEffect(() => {
    const subscription = WebsiteUpdatesListOrRecentEndpoint(props, setWebsitePreviousData);
    return () => {
      subscription.unsubscribe();
    };
  }, [props]);

  const options = {
    replace: (attribs) => {
      if (attribs.data === 'SearchIcon')
        return <SearchIcon data-identifier="SearchIcon" className="white-text" style={{ fontSize: '40' }} />;

      if (attribs.data === 'FeedBackIcon')
        return <HelpIcon data-identifier="HelpIcon" className="white-text" style={{ fontSize: '40' }} />;

      if (attribs.data === 'UpdatesIcon')
        return <NewReleasesIcon data-identifier="NewReleasesIcon" className="white-text" style={{ fontSize: '40' }} />;

      if (attribs.data === 'ProfileIcon')
        return <AccountCircleIcon data-identifier="AccountCircleIcon" className="white-text" style={{ fontSize: '40' }} />;

      if (attribs.data === 'ToolsIcon')
        return <AppsIcon data-identifier="AppsIcon" className="white-text" style={{ fontSize: '40' }} />;
    },
  };

  if (websiteData[0].base__body.resolvedData) {
    displayItemDetailsVar = parse(websiteData[0].base__body.resolvedData.html, options);
    if (!Array.isArray(displayItemDetailsVar)) {
      displayItemDetailsVar = [displayItemDetailsVar];
    }
  }
  return (
    <div>
      <Row>
        <Col md={12}>
          <h1>{websiteData[0].base__title.value}</h1>
          <p>
            {websiteData[0].base__datepublished.value !== ''
              ? new Date(websiteData[0].base__datepublished.value).toLocaleDateString('en-US', props.context.dateOptions)
              : null}
          </p>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={9}>
          {displayItemDetailsVar.map((elem, index) => {
            let item = '';
            let innerTitle = '';
            if (
              elem !== '' &&
              elem.props !== null &&
              elem.props !== undefined &&
              elem.props.children !== null &&
              elem.props.children.length > 1 &&
              elem.props.children !== 'This update includes these new features:' &&
              elem.props.children[1].type === 'p'
            ) {
              item = elem.props.children[1].props.children.props['data-identifier'];
              if (
                item === 'SearchIcon'
                  ? (innerTitle = 'Website Search')
                  : null || item === 'HelpIcon'
                  ? (innerTitle = 'FeedBack')
                  : null || item === 'NewReleasesIcon'
                  ? (innerTitle = 'Website Updates')
                  : null || item === 'AccountCircleIcon'
                  ? (innerTitle = 'New Profile with new Community Maps')
                  : null || item === 'AppsIcon'
                  ? (innerTitle = 'Tools')
                  : null
              )
                return (
                  <div key={index}>
                    <Row>
                      <Col md={1} className="website-updates-icons">
                        <div className="center">{elem.props.children[1]}</div>
                      </Col>
                      <Col md={11} className="borderTopGray">
                        <div className="small-top-padding">
                          <div className="small-bottom-padding">
                            <strong>{innerTitle}</strong>
                          </div>
                          {elem.props.children[3]}
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              else return '';
            } else
              return (
                <div key={index} data-cy="body-text">
                  {elem}
                </div>
              );
          })}
        </Col>
        <Col md={3}>
          {websitePreviousData !== null ? (
            <RecentFeaturesListReference details={websitePreviousData} url={props.match.params.id} />
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default withContext(WebsiteUpdatesArticle);
