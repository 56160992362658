import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';
import IToken from '../../Login/IToken';

export default class SecurityEmailProxy {
  authService: IToken;
  apiUrl: string;
  confirmUrl: string;

  constructor(auth: IToken, apiUrl: string, confirmUrl: string) {
    this.authService = auth;
    this.apiUrl = apiUrl;
    this.confirmUrl = confirmUrl;
  }

  confirm(tempCode: string) {
    return GlobalAPICall.postObservable(
      this.confirmUrl + tempCode,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }
}
