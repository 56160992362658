import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { CircularProgress, TextField } from '@material-ui/core';

/**
 * Component to edit Race/Ethnicity information
 * @example
 * // returns Checkboxes and other ethnicities text fields
 * const initialState = {
 *   RaceEthnicitySelections: { value: [] },
 * };
 * const validationSchema = {
 *   RaceEthnicitySelections: { required: false },
 * };
 * const { state, setState, handleValidationOnChange, handleOnSubmit } = useForm(initialState, validationSchema, submit, onInvalidSubmitAttempt);
 * return (<RaceEthnicityComponent
 *            raceEthnicityOptions={staticState.raceEthnicityOptions}
 *            value={state.RaceEthnicitySelections.value}
 *            onChange={handleValidationOnChange}
 *            name="RaceEthnicitySelections"
 *          />);
 * @param raceEthnicityOptions
 * Array of strings representing available options (these will be displayed as check boxes)
 * @param value
 * Array of strings representing currently selected values
 * @param onChange
 * Callback function that will be passed an event in the form of {target:{name:string,value:string[]}}
 * @param name
 * Name to be used when passing event to onChange callback function
 */
const RaceEthnicityComponent = ({ raceEthnicityOptions, value, onChange, name }) => {
  // Rename some variables for clarity
  const raceEthnicitySelections = value;
  const onChangeTargetName = name;
  const ethnicityMaxLength = 50;

  // Declare useStates
  const [otherEthnicitiesCheckBox, setOtherEthnicitiesCheckBox] = useState(false);
  const [otherEthnicity1, setOtherEthnicity1] = useState('');
  const [otherEthnicity2, setOtherEthnicity2] = useState('');
  const [otherEthnicity3, setOtherEthnicity3] = useState('');
  const [otherEthnicity4, setOtherEthnicity4] = useState('');

  // Load other ethnicities text fields once
  useEffect(() => {
    if (
      !raceEthnicityOptions?.length ||
      !raceEthnicitySelections?.length ||
      otherEthnicity1.length ||
      otherEthnicity2.length ||
      otherEthnicity3.length ||
      otherEthnicity4.length
    ) {
      return;
    }

    const raceEthnicityOptionsKeys = raceEthnicityOptions.map((raceEthnicityOption) => raceEthnicityOption.key.toUpperCase());
    const otherRaceEthnicitySelections = raceEthnicitySelections.filter(
      (raceEthnicitySelection) => !raceEthnicityOptionsKeys.includes(raceEthnicitySelection.toUpperCase())
    );

    const unallocatedOtherRaceEthnicitySelections = otherRaceEthnicitySelections.filter(
      (raceEthnicitySelection) =>
        raceEthnicitySelection.toUpperCase() !== otherEthnicity1.toUpperCase() &&
        raceEthnicitySelection.toUpperCase() !== otherEthnicity2.toUpperCase() &&
        raceEthnicitySelection.toUpperCase() !== otherEthnicity3.toUpperCase() &&
        raceEthnicitySelection.toUpperCase() !== otherEthnicity4.toUpperCase()
    );

    if (unallocatedOtherRaceEthnicitySelections.length > 0) {
      setOtherEthnicitiesCheckBox(true);
    }

    if (unallocatedOtherRaceEthnicitySelections.length > 0) {
      setOtherEthnicity1(unallocatedOtherRaceEthnicitySelections.shift());
    }

    if (unallocatedOtherRaceEthnicitySelections.length > 0) {
      setOtherEthnicity2(unallocatedOtherRaceEthnicitySelections.shift());
    }

    if (unallocatedOtherRaceEthnicitySelections.length > 0) {
      setOtherEthnicity3(unallocatedOtherRaceEthnicitySelections.shift());
    }

    if (unallocatedOtherRaceEthnicitySelections.length > 0) {
      setOtherEthnicity4(unallocatedOtherRaceEthnicitySelections.shift());
    }
  }, [raceEthnicityOptions, raceEthnicitySelections]); // eslint-disable-line react-hooks/exhaustive-deps

  // Show circular progress while waiting for options
  if (!raceEthnicityOptions?.length || !raceEthnicitySelections) {
    return <CircularProgress data-cy="race-ethnicity-circular-progress" className="loading-animation" size={40} />;
  }

  // Simulates onChange event call
  const simulateOnChangeEvent = (newRaceEthnicitySelections) =>
    onChange({
      target: {
        name: onChangeTargetName,
        value: newRaceEthnicitySelections,
      },
    });

  // Determines all selections and simulates an onChange event with the new array
  const handleCheckBoxChange = (event) => {
    const selection = event.target.name;
    const adding = event.target.checked;

    const newSelection = adding
      ? raceEthnicitySelections.indexOf(selection) === -1
        ? [...raceEthnicitySelections, selection]
        : [...raceEthnicitySelections]
      : raceEthnicitySelections.indexOf(selection) === -1
      ? [...raceEthnicitySelections]
      : raceEthnicitySelections.filter((x) => x !== selection);

    simulateOnChangeEvent(newSelection);
  };

  // Build checkboxes
  const raceEthnicityCheckboxes = raceEthnicityOptions.map((item, key) => (
    <div key={key} data-cy="inputrace">
      <Row middle="xs">
        <Col xs={1} className="end negative-left-margin">
          <input
            id={item.key}
            name={item.key}
            type="checkbox"
            data-cy={`raceethnicity${key}`}
            checked={raceEthnicitySelections.findIndex((r) => r.toUpperCase() === item.value.toUpperCase()) !== -1}
            onChange={handleCheckBoxChange}
          ></input>{' '}
        </Col>
        <Col xs={11}>
          <p className="very-small-top-padding">{item.value}</p>
        </Col>
      </Row>
    </div>
  ));

  // Only simulate onChange event when an other ethnicities text field loses focus
  const handleBlur = () => {
    const raceEthnicityOptionsKeys = raceEthnicityOptions.map((raceEthnicityOption) => raceEthnicityOption.key.toUpperCase());
    const raceEthnicitySelectionsToRemove = raceEthnicitySelections.filter(
      (raceEthnicitySelection) =>
        !raceEthnicityOptionsKeys.includes(raceEthnicitySelection.toUpperCase()) &&
        raceEthnicitySelection.toUpperCase() !== otherEthnicity1.toUpperCase() &&
        raceEthnicitySelection.toUpperCase() !== otherEthnicity2.toUpperCase() &&
        raceEthnicitySelection.toUpperCase() !== otherEthnicity3.toUpperCase() &&
        raceEthnicitySelection.toUpperCase() !== otherEthnicity4.toUpperCase()
    );

    const raceEthnicitySelectionsToRemoveUpperCase = raceEthnicitySelectionsToRemove.map((raceEthnicitySelection) =>
      raceEthnicitySelection.toUpperCase()
    );

    const newSelections = raceEthnicitySelections.filter(
      (raceEthnicitySelection) => !raceEthnicitySelectionsToRemoveUpperCase.includes(raceEthnicitySelection.toUpperCase())
    );

    if (otherEthnicity1.length > 0 && newSelections.indexOf(otherEthnicity1) === -1) {
      if (raceEthnicityOptionsKeys.includes(otherEthnicity1.toUpperCase())) {
        const matchingOption = raceEthnicityOptions.filter(
          (raceEthnicityOption) => raceEthnicityOption.key.toUpperCase() === otherEthnicity1.toUpperCase()
        );
        newSelections.push(matchingOption[0].key);
        setOtherEthnicity1('');
      } else {
        newSelections.push(otherEthnicity1);
      }
    } else {
      if (raceEthnicityOptionsKeys.includes(otherEthnicity1.toUpperCase())) {
        setOtherEthnicity1('');
      }
    }

    if (otherEthnicity2.length > 0 && newSelections.indexOf(otherEthnicity2) === -1) {
      if (raceEthnicityOptionsKeys.includes(otherEthnicity2.toUpperCase())) {
        const matchingOption = raceEthnicityOptions.filter(
          (raceEthnicityOption) => raceEthnicityOption.key.toUpperCase() === otherEthnicity2.toUpperCase()
        );
        newSelections.push(matchingOption[0].key);
        setOtherEthnicity2('');
      } else {
        newSelections.push(otherEthnicity2);
      }
    } else {
      if (raceEthnicityOptionsKeys.includes(otherEthnicity2.toUpperCase())) {
        setOtherEthnicity2('');
      }
    }

    if (otherEthnicity3.length > 0 && newSelections.indexOf(otherEthnicity3) === -1) {
      if (raceEthnicityOptionsKeys.includes(otherEthnicity3.toUpperCase())) {
        const matchingOption = raceEthnicityOptions.filter(
          (raceEthnicityOption) => raceEthnicityOption.key.toUpperCase() === otherEthnicity3.toUpperCase()
        );
        newSelections.push(matchingOption[0].key);
        setOtherEthnicity3('');
      } else {
        newSelections.push(otherEthnicity3);
      }
    } else {
      if (raceEthnicityOptionsKeys.includes(otherEthnicity3.toUpperCase())) {
        setOtherEthnicity3('');
      }
    }

    if (otherEthnicity4.length > 0 && newSelections.indexOf(otherEthnicity4) === -1) {
      if (raceEthnicityOptionsKeys.includes(otherEthnicity4.toUpperCase())) {
        const matchingOption = raceEthnicityOptions.filter(
          (raceEthnicityOption) => raceEthnicityOption.key.toUpperCase() === otherEthnicity4.toUpperCase()
        );
        newSelections.push(matchingOption[0].key);
        setOtherEthnicity4('');
      } else {
        newSelections.push(otherEthnicity4);
      }
    } else {
      if (raceEthnicityOptionsKeys.includes(otherEthnicity4.toUpperCase())) {
        setOtherEthnicity4('');
      }
    }

    const distinctSelections = [...new Set(newSelections)];

    simulateOnChangeEvent(distinctSelections);
  };

  // Add or remove other ethnicities text fields values to main list by simulating onChange event
  const handleOtherEthnicitiesCheckbox = () => {
    const isChecked = !otherEthnicitiesCheckBox;
    const newSelections = isChecked
      ? [...raceEthnicitySelections, otherEthnicity1, otherEthnicity2, otherEthnicity3, otherEthnicity4].filter(
          (raceEthnicitySelection) => raceEthnicitySelection !== ''
        )
      : raceEthnicitySelections.filter(
          (raceEthnicitySelection) =>
            raceEthnicitySelection.toUpperCase() !== otherEthnicity1.toUpperCase() &&
            raceEthnicitySelection.toUpperCase() !== otherEthnicity2.toUpperCase() &&
            raceEthnicitySelection.toUpperCase() !== otherEthnicity3.toUpperCase() &&
            raceEthnicitySelection.toUpperCase() !== otherEthnicity4.toUpperCase()
        );
    const distinctSelections = [...new Set(newSelections)];
    setOtherEthnicitiesCheckBox(isChecked);
    simulateOnChangeEvent(distinctSelections);
  };

  return (
    <div>
      <Row>
        <Col sm={12}>
          <p>Race / Ethnicity (Check all that apply)</p>
        </Col>
      </Row>
      {raceEthnicityCheckboxes}
      <Row middle="xs">
        <Col xs={1} className="end negative-left-margin">
          <input
            id="otherEthnicities"
            type="checkbox"
            data-cy="otherEthnicities"
            checked={otherEthnicitiesCheckBox}
            onChange={handleOtherEthnicitiesCheckbox}
          ></input>
        </Col>
        <Col xs={11}>
          <p className="very-small-top-padding">Other Ethnicities</p>
        </Col>
      </Row>
      {otherEthnicitiesCheckBox ? (
        <div>
          <Row>
            <Col sm={6}>
              <TextField
                inputProps={{ 'data-cy': 'inputotherRE1' }}
                id="otherRE1"
                name="otherRE1"
                type="text"
                error={otherEthnicity1?.length > ethnicityMaxLength}
                value={otherEthnicity1}
                onChange={(e) => setOtherEthnicity1(e.target.value)}
                margin="dense"
                maxLength={ethnicityMaxLength}
                maxwidth="200"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
              />
              <TextField
                inputProps={{ 'data-cy': 'inputotherRE2' }}
                id="otherRE2"
                name="otherRE2"
                type="text"
                error={otherEthnicity2?.length > ethnicityMaxLength}
                value={otherEthnicity2}
                onChange={(e) => setOtherEthnicity2(e.target.value)}
                margin="dense"
                maxLength={ethnicityMaxLength}
                maxwidth="200"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
              />
            </Col>
            <Col sm={6}>
              <TextField
                inputProps={{ 'data-cy': 'inputotherRE3' }}
                id="otherRE3"
                name="otherRE3"
                type="text"
                error={otherEthnicity3?.length > ethnicityMaxLength}
                value={otherEthnicity3}
                onChange={(e) => setOtherEthnicity3(e.target.value)}
                margin="dense"
                maxLength={ethnicityMaxLength}
                maxwidth="200"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
              />
              <TextField
                inputProps={{ 'data-cy': 'inputotherRE4' }}
                id="otherRE4"
                name="otherRE4"
                type="text"
                error={otherEthnicity4?.length > ethnicityMaxLength}
                value={otherEthnicity4}
                onChange={(e) => setOtherEthnicity4(e.target.value)}
                margin="dense"
                maxLength={ethnicityMaxLength}
                maxwidth="200"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
              />
            </Col>
          </Row>{' '}
        </div>
      ) : null}
    </div>
  );
};

export default RaceEthnicityComponent;
