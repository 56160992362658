import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText, Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';
import withContext from '../ContextAPI/Context_HOC';
import { OptimizelyFeature } from '@optimizely/react-sdk';

class NewsDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: null, newsTaxonomy: [] };
  }
  updateSelected(selectedIndex) {
    this.setState({ selected: selectedIndex });
  }
  componentDidMount() {
    this.props.context.digitalDesignContent
      .items()
      .types(['news_article'])
      .elementsParameter(['news'])
      .toObservable()
      .subscribe(async (responseItems) => {
        let taxonomies = [];
        responseItems.items.forEach((x) => {
          for (let i = 0; i < x.news.value.length; i++) {
            if (!taxonomies.find((y) => y.name == x.news?.value[i].name)) {
              taxonomies.push({ name: x.news.value[i].name, codename: x.news.value[i].codename });
            }
          }
        });
        this.setState({ newsTaxonomy: taxonomies.sort() });
      });
  }
  render() {
    const { selected } = this.state;
    const tabIndexValue = this.props.context.isDrawerOpen ? '0' : '-1';
    return (
      <List>
        {this.state.newsTaxonomy.map((news, index) => (
          <span key={index}>
            <ListItem
              data-cy="newsdrawerlist"
              key={index}
              onClick={() => this.updateSelected(index)}
              selected={selected === index}
            >
              <Link data-cysublev="subdrawerlink" data-cy={`newsdrawerlink${index}`} to={`/news/${news.codename}`}>
                <ListItemText primary={news.name} data-cy={`subdrawerlink`} className="subdrawer-list-item" />
              </Link>
            </ListItem>
            <Divider />
          </span>
        ))}
        <OptimizelyFeature feature="natcon">
          {(isEnabled) =>
            isEnabled ? (
              <ListItem tabIndex={tabIndexValue} onClick={() => this.updateSelected(5)} selected={selected === 5}>
                <Link tabIndex={tabIndexValue} data-cysublev="subdrawerlink" to="/news/natcon-2024">
                  <ListItemText primary="National Convention 2024" className="subdrawer-list-item" />
                </Link>
              </ListItem>
            ) : null
          }
        </OptimizelyFeature>
      </List>
    );
  }
}

NewsDrawer.propTypes = {
  details: PropTypes.shape({
    class: PropTypes.string,
    NewsTaxonomy: PropTypes.array,
  }),
};
NewsDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withContext(NewsDrawer);
