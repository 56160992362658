import React from 'react';
import ClusterValidationForm from '../CreateBOS/ClusterValidationForm';
import createAppToken from '../Utilities/AppTokenEndpoint';
import submitCluster from '../Utilities/ClusterEndpoint';
import setupAccount from '../Utilities/SetupAccountEndpoint';
import withContext from '../../ContextAPI/Context_HOC';
import OverseasValidationForm from '../Utilities/OverseasValidationForm';
import OverseasValidationEndpoint from '../Utilities/OverseasValidationEndpoint';
import SafeStringify from '../../Utilities/SafeStringify';
import { withOptimizely } from '@optimizely/react-sdk';
import CreateBOSBaseStep, { CreateBOSState, Step } from './CreateBOSBaseStep';
import CreateBOSHeader from './CreateBOSHeader';

class CreateBOSStep2 extends CreateBOSBaseStep {
  constructor(props: any) {
    super(props);
    this._step = Step.AdditionalQuestions;
    this.handleChange = this.handleChange.bind(this);
    this.handleClusterChange = this.handleClusterChange.bind(this);
    this.submitCluster = this.submitCluster.bind(this);
    this.submitOverseasData = this.submitOverseasData.bind(this);
    this.submitSetupAcc = this.submitSetupAcc.bind(this);
  }

  handleClusterChange(e: any) {
    this.saveStateToStorage({ [e.target.name]: e.target.value }, function () {});
  }

  submitOverseasData(e: any) {
    e.preventDefault();
    const answers: any[] = [];
    for (let i = -100; i <= 100; ++i) {
      if (this.state[`overseasAnswer_${i}`]) {
        answers.push({
          questionId: i,
          answer: this.state[`overseasAnswer_${i}`],
        });
      }
    }

    const answerCount = answers.length;
    if (answerCount === 0) {
      this.saveStateToStorage({
        displayMessage: (
          <p className="center error-message">{`No questions have been answered. Please answer at least ${this.state.overseasRequiredCorrectAnswerCount} questions`}</p>
        ),
      });
      return;
    }
    if (answerCount < this.state.overseasRequiredCorrectAnswerCount) {
      if (answerCount === 1) {
        this.saveStateToStorage({
          displayMessage: (
            <p className="center error-message">{`Only 1 question has been answered. Please answer at least ${this.state.overseasRequiredCorrectAnswerCount} questions`}</p>
          ),
        });
      } else {
        this.saveStateToStorage({
          displayMessage: (
            <p className="center error-message">{`Only ${answerCount} questions have been answered. Please answer at least ${this.state.overseasRequiredCorrectAnswerCount} questions`}</p>
          ),
        });
      }
      return;
    }

    this.saveStateToStorage({ showCircularProgress: true }, () =>
      createAppToken()
        .then((res: any) => {
          OverseasValidationEndpoint(res.data, this.state.bid, answers)
            .then(async (res) => {
              this.saveStateToStorage({
                showCircularProgress: false,
                displayMessage: '',
                accountIdn: res.data.accountIdentifier,
                securityQuestions: res.data.securityQuestionList,
                currentStep: Step.SecurityQuestions,
              });
            })
            .catch((err) => {
              this.saveStateToStorage({
                showCircularProgress: false,
                displayMessage: <p className="center error-message">{err.message ? SafeStringify(err.message) : null}</p>,
              });
            });
        })
        .catch((err: any) => {
          this.saveStateToStorage({
            showCircularProgress: false,
            displayMessage: <p className="center error-message">{err.message ? SafeStringify(err.message) : null}</p>,
          });
        })
    );
  }

  submitCluster(e: any) {
    e.preventDefault();

    if (!this.state.clustervalue || !this.state.clustervalue.length) {
      this.saveStateToStorage({
        displayMessage: <p className="center error-message">No cluster was selected</p>,
      });
      return;
    }

    createAppToken()
      .then((res: any) => {
        submitCluster(res.data, this.state.bid, this.state.zip, this.state.clustervalue)
          .then(async (res) => {
            this.saveStateToStorage({
              displayMessage: '',
              accountIdn: res.data.accountIdentifier,
              securityQuestions: res.data.securityQuestionList,
              currentStep: Step.SecurityQuestions,
            });
          })
          .catch((err) => {
            this.saveStateToStorage({
              displayMessage: <p className="center error-message">{err.message ? SafeStringify(err.message) : null}</p>,
            });
          });
      })
      .catch((err: any) => {
        this.saveStateToStorage({
          displayMessage: <p className="center error-message">{err.message ? SafeStringify(err.message) : null}</p>,
        });
      });
  }

  submitSetupAcc(e: any) {
    e.preventDefault();
    let userinput = {
      email: this.state.email,
      confirmEmail: this.state.confirmEmail,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      question1Id: this.state.questionOne,
      question1Answer: this.state.questionOneAns,
      question2Id: this.state.questionTwo,
      question2Answer: this.state.questionTwoAns,
      question3Id: this.state.questionThree,
      question3Answer: this.state.questionThreeAns,
    };

    createAppToken()
      .then((res: any) => {
        setupAccount(res.data, this.state.bid, this.state.accountIdn, userinput, this.state.temporaryCode)
          .then(async (res) => {
            this.saveStateToStorage({
              displayMessage: (
                <p className="center success-message">
                  {res.data?.message ? SafeStringify(res.data.message) : SafeStringify(res.data)}
                </p>
              ),
              success: true,
            });
          })
          .catch((err) => {
            this.saveStateToStorage({
              displayMessage: <p className="center error-message">{err.message ? SafeStringify(err.message) : null}</p>,
            });
          });
      })
      .catch((err: any) => {
        this.saveStateToStorage({
          displayMessage: <p className="center error-message">{err.message ? SafeStringify(err.message) : null}</p>,
        });
      });
  }

  render() {
    if (this.state.dontHaveZipCheck) {
      return (
        <div className="center">
          <div data-cy="logo" className="starlogo star-margin" />
          <h2 data-cy="logotext" className="logo-text">
            Bahá’ís of the United States
          </h2>
          <OverseasValidationForm
            model={this.state as CreateBOSState}
            title="Create an Account"
            handleSubmit={this.submitOverseasData}
            displayMessage={this.state.displayMessage}
            handleChange={this.handleChange}
            createAppTokenPromise={createAppToken}
            showCircularProgress={this.state.showCircularProgress}
            history={this.props.history}
            cancelHandler={this.cancel}
          />
        </div>
      );
    } else {
      return (
        <div className="center">
          <CreateBOSHeader></CreateBOSHeader>
          <ClusterValidationForm
            handleChange={this.handleChange}
            name="clustervalue"
            selectvalue={this.state.clustervalue}
            clusterdata={this.state.clusterdata}
            handleSubmit={this.submitCluster}
            displayMessage={this.state.displayMessage}
            history={this.props.history}
            cancelHandler={this.cancel}
          />
        </div>
      );
    }
  }
}
export default withContext(withOptimizely(CreateBOSStep2));
