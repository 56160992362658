import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import BoundariedRoute from '../Error/BoundariedRoute';
import withContext from '../ContextAPI/Context_HOC';
import DisplayArticle from './DisplayArticle.js';
import ThemeMainList from './ThemeMainList';
import NoMatch from '../Routers/NoMatch';

class ThemeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      themelist: [],
      routerlist: [],
      themetitle: '',
      wrongPath: false,
    };
  }
  NewsThemeDataLoad() {
    this.props.context.digitalDesignContent
      .items()
      .types(['news_article'])
      .containsFilter('elements.news', [this.props.match.params.id])
      .orderParameter('elements.base__datepublished', 'desc')
      .toObservable()
      .subscribe(async (response) => {
        if (!response.isEmpty) {
          this.setState({
            wrongPath: false,
            themelist: response.items,
            themetitle: this.props.match.params.id.replace(/_/g, ' '),
            routerlist: (
              <BoundariedRoute
                path={`/news/${this.props.match.params.id}/:articleid`}
                render={(props) => <DisplayArticle {...props} contenttype={'news_article'} />}
              />
            ),
          });
        } else {
          this.setState({ wrongPath: true });
        }
      });
  }
  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.NewsThemeDataLoad();
    }
  }
  componentDidMount() {
    this.NewsThemeDataLoad();
  }
  render() {
    return (
      <div>
        <Switch>
          {this.state.wrongPath === true ? (
            <BoundariedRoute component={NoMatch} />
          ) : (
            <BoundariedRoute
              exact
              path={`/news/${this.props.match.params.id}`}
              render={(props) => (
                <ThemeMainList
                  {...props}
                  newsdata={this.state.themelist}
                  articletype={this.props.match.params.id}
                  themetitle={this.state.themetitle}
                />
              )}
            />
          )}
          {this.state.routerlist}
        </Switch>
      </div>
    );
  }
}
export default withContext(ThemeList);
