import ContentProviderEndpoint from '../../Content/ContentProviderEndpoint';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NatconCarousel } from '../../Kentico/DigitalDesign/natcon_carousel';

export default class NatConCarouselProxy {
  digitalDesignContent: ContentProviderEndpoint;
  NatConCarouselsSubject: Map<string, ReplaySubject<NatconCarousel[]>>;

  constructor(digitalDesignContent: ContentProviderEndpoint) {
    this.digitalDesignContent = digitalDesignContent;
    this.NatConCarouselsSubject = new Map<string, ReplaySubject<NatconCarousel[]>>();
  }

  getNatConCarousels(natConTaxonomy: string): Observable<NatconCarousel[]> {
    const subject = this.NatConCarouselsSubject.get(natConTaxonomy);
    if (!subject) {
      const newSubject = new ReplaySubject<NatconCarousel[]>(1);
      this.NatConCarouselsSubject.set(natConTaxonomy, newSubject);
      this.digitalDesignContent
        .items<NatconCarousel>()
        .type('natcon_carousel')
        .anyFilter('elements.natcon', [natConTaxonomy])
        .orderByAscending('elements.order')
        .toObservable()
        .pipe(map((x) => x.items))
        .subscribe(newSubject);
      return newSubject;
    } else {
      return subject.asObservable();
    }
  }
}
