import React, { useState, useEffect } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import DisplaySubSectPage from './DisplaySubSectPage';
import GlsaRichTextResolver from './Utilities/GlsaRichTextResolver';
import NoMatch from '../Routers/NoMatch';
import { CircularProgress } from '@material-ui/core';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';
import { switchMap, map } from 'rxjs/operators';
import { of } from 'rxjs';

const GlsaSubSection = ({
  context: { glsaProxy, glsaContent },
  match: {
    params: { subSectId },
  },
}) => {
  const [showCircularProgress, setShowCircularProgress] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [showNoMatch, setShowNoMatch] = useState(false);
  const [subSectionData, setSubSectionData] = useState();
  const [linkData, setLinkData] = useState();
  const [allTaxonomies, setAllTaxonomies] = useState();
  const [matchingUrl, setMatchingUrl] = useState({});

  useEffect(() => {
    const subscription = glsaProxy
      .getChapters()
      .pipe(
        switchMap((taxonomyStuff) => {
          const matchingUrl = taxonomyStuff.urlsByCodename[subSectId];
          if (!matchingUrl) {
            return of({ noMatch: true });
          }
          setMatchingUrl(matchingUrl);
          return glsaContent
            .items()
            .type('subsection')
            .containsFilter('elements.chapters', [subSectId])
            .queryConfig({
              richTextResolver: (data) => {
                return GlsaRichTextResolver(data);
              },
            })
            .toObservable()
            .pipe(
              map((contentStuff) => {
                if (!contentStuff.isEmpty) {
                  const subSectionContentItem = contentStuff.items[0];
                  return { taxonomyStuff, subSectionContentItem };
                } else {
                  //If Kentico can't find the content
                  return { noMatch: true };
                }
              })
            );
        })
      )
      .pipe(
        switchMap((x) => {
          if (x.subSectionContentItem?.base__body?.links?.length > 0) {
            const links = x.subSectionContentItem.base__body.links;
            return glsaProxy.getLinkContentItems(links).pipe(
              map((linkContentItems) => {
                return { ...x, linkContentItems };
              })
            );
          } else {
            return of({ ...x, linkContentItems: [] });
          }
        })
      )
      .subscribe({
        next: (x) => {
          if (x.noMatch) {
            setShowNoMatch(true);
            setShowCircularProgress(false);
            return;
          }

          setSubSectionData(x.subSectionContentItem);
          setLinkData(x.linkContentItems);
          setAllTaxonomies(x.taxonomyStuff);
          setShowCircularProgress(false);
        },
        error: (e) => {
          console.error(e);
          setErrorOccurred(true);
          setShowCircularProgress(false);
        },
      });

    return () => subscription.unsubscribe();
  }, [glsaProxy, setShowCircularProgress, setErrorOccurred, setShowNoMatch, glsaContent, subSectId, setSubSectionData]);

  return (
    <div>
      {errorOccurred ? <ErrorMessageToUser /> : null}
      {showCircularProgress ? <CircularProgress className="loading-animation" size={40} /> : null}
      {showNoMatch ? <NoMatch /> : null}
      {allTaxonomies ? (
        <DisplaySubSectPage
          subSectionData={subSectionData}
          allTaxonomies={allTaxonomies}
          linkData={linkData}
          matchingUrl={matchingUrl}
        />
      ) : null}
    </div>
  );
};

export default withContext(GlsaSubSection);
