import React from 'react';
import { withStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';

const styles = () => ({
  '@global': {
    '#root': {
      background: 'radial-gradient(ellipse at center 32%, #0a5b55, #01252b)',
    },
    '#content': {
      background: 'transparent',
    },
  },
});

function PrivacyPolicy() {
  return (
    <Row around="xs" className="faded-background top-margin">
      <Col xs={12} sm={6} className="form-style wider">
        <h1>Privacy Policy</h1>
        <br />
        <p>
          The Bahá’ís of the United States know that you care about how your personal information is used and shared, and we take
          your privacy very seriously. This notice explains what information we collects about users, how we collect that
          information, for what purposes we use that information, to whom we may disclose that information, and how users can
          limit our use of their information. Please read the following to learn more about our Privacy Policy before using our
          websites.
        </p>
        <p>By visiting our website, you are accepting the practices outlined in this Privacy Policy.</p>
        <p>
          This Privacy Policy covers the Bahá’ís of the United States’ treatment of personal information that the Bahá’ís of the
          United States gather when you are on the Bahá’ís of the United States’ websites (Bahai.us) and when you use the Bahá’ís
          of the United States’ online platforms (collectively, “website”). This policy does not apply to the practices of third
          parties that the Bahá’ís of the United States does not own or control, or to individuals that the Bahá’ís of the United
          States does not employ or manage. The Bahá’ís of the United States websites may contain links to third party websites.
          We do not endorse and are not responsible for the content of third-party websites or resources, and our privacy notice
          does not apply to any sites that are not affiliated with the Bahá’ís of the United States, even if you access them via a
          link on our site. You should review the privacy policies of any third-party site before providing any information.
        </p>
        <br />
        <h6>Information Collected by the Bahá’ís of the United States</h6>
        <p>
          We only collect personal information that is relevant to the purpose of our website. This information allows us to
          provide you with a customized and efficient experience. We will retain such information for the duration of your
          relationship with us. We collect the following types of information from our Bahá’ís of the United States users:
        </p>
        <p>
          Information You Provide to Us: We receive and store any information that directly identifies you or could be reasonably
          be used in combination with other information to identify you (“Personal Data”) from information you enter on our
          website or that you provide directly provide to us in other ways, such as through email. You can choose not to provide
          us with certain information, but then you may not be able to take advantage of many of our special features. Examples of
          Personal Data that directly identify you include your name, contact information, email address, and other information in
          combination with such identifiers. Our websites may also collect Personal Data that does not directly identify you by
          name or contact information but which may be used to identify that a specific computer or device has accessed our
          website and could be used to identify you if combined with certain other information. Personal Data that we may collect
          includes:
        </p>
        <ul className="no-margin">
          <li>
            Contact Information. We collect this information in order to be able to communicate with you. This information may
            include your name, mailing address, telephone number, email address, job title, age, and other information you provide
            on our website. Where we solicit this information, we will explain how the information you provide is to be used. It
            is your choice as to whether to provide your Personal Data.
          </li>
          <li>
            Interests and Preferences. This information helps us understand your interest in our products and services so that we
            can best serve you. This information may include contact and product preferences, languages, marketing preferences and
            demographic data. Where we solicit this information, we will explain how the information you provide is to be used.
            This information may be collected as part of the information we collect automatically or pursuant to your consent.
          </li>
          <li>
            Interaction History. This information helps us fulfill services you have requested and to understand your interests
            and preferences. This includes how you interact with us and use our websites, your correspondence with us, and any
            purchase history or customer account information. Where you have purchased a product or service from us, we use this
            information in order to perform our agreement with you. This information is collected automatically, for our
            legitimate business interests.
          </li>
          <li>
            Automatically Collected Information. We receive and store certain types of information whenever you interact with us.
            The Bahá’ís of the United States and its authorized agents automatically receive and record certain “traffic data” on
            their server logs from your browser including your IP address, the Bahá’ís of the United States cookie information,
            and the page you requested. The Bahá’ís of the United States uses this traffic data to help diagnose problems with its
            servers, analyze trends and administer the website. For more information on automated collection, see the section on
            Cookies and Automated Technology below.
          </li>
        </ul>
        <br />
        <h6>Legal Basis for Collecting Personal Information</h6>
        <p>
          Consent: In some cases, at the point at which you provide information, we may ask you for your consent to collect and
          process your Personal Data. If you choose to provide us with your consent, you may later withdraw your consent by
          contacting us. Please note that if you withdraw your consent it will not affect any processing of your information that
          has already occurred. Where we process your information based on consent, we will provide more detailed information to
          you at the time when we obtain your consent.
        </p>
        <p>
          Our legitimate interest: We may process your information based on our legitimate interest in communicating with you and
          managing our interactions with you regarding our products, services, education opportunities, and the religious purposes
          of the Bahá’ís of the United States and its affiliates in the Bahá’í faith. We will not share your information outside
          these purposes without your consent. In addition to your other rights, you have the right to object to such processing
          of your information. You can register your objection by contacting us as described above.
        </p>
        <p>
          Our legitimate activities: Some of the information you provide us may include information about your religious
          affiliation or practices. We will process this information in the course of our legitimate activities as a
          not-for-profit body with a religious aim. Processing of this information will relate only to members of the Bahá’ís of
          the United States and we will not disclose this information to a third party without your explicit consent.
        </p>
        <br />
        <h6>Email Communications</h6>
        <p>
          The Bahá’ís of the United States are very concerned about your privacy and we will never provide your email address to a
          third party without your explicit permission, as detailed in the “Sharing Your Information” section below. If you have
          provided us your email address and opted in to such communications, the Bahá’ís of the United States may send out emails
          with related news, products, offers, surveys or promotions. You may manage your email preferences, including
          unsubscribing and resubscribing, at any time by using the links included at the bottom of every email.
        </p>
        <br />
        <h6>Cookies & Automated Technology</h6>
        <p>
          Our websites may use a tracking technology called a “cookie.” Cookies are alphanumeric identifiers that we transfer to
          your computer’s hard drive through your Web browser to enable our systems to recognize your browser and tell us how and
          when pages in our website are visited and by how many people. Cookies can also be used to enable a site to “remember”
          the information a visitor has previously inputted. Cookies may be placed on your computer both by us and by third
          parties with whom we have a relationship, such as web analytic services and advertising network services. Google
          Analytics may be used to help make our sites work better and to help us understand what parts of the Sites are being
          utilized. Google Analytics uses technical tools such as, first-party cookies and JavaScript code, to collect information
          about visitors. Google Analytics service tracks visitors to the site who have JavaScript enabled. Google Analytics
          anonymously tracks how visitors interact with a website, including where they came from and what they did on a site.
          Google Analytics collects the information set forth above from users. This information is used to administer and update
          the website, and we will also assess whether the visitors to the site match the expected site demographics and determine
          how key audiences are navigating the content.
        </p>
        <p>
          The “Help” portion of the toolbar on the majority of browsers will direct you on how to prevent your browser from
          accepting new cookies, how to command the browser to tell you when you receive a new cookie, or how to fully disable
          cookies. We recommend that you leave the cookies activated because cookies allow you to use some of the Bahá’ís of the
          United States’ best features. Certain web browsers and other programs may be used to signal your preferences to Company
          about how or whether the Bahá’ís of the United States or third parties may collect information about your online
          activities. Currently, our websites do not respond to such signals.
        </p>
        <br />
        <h6>Sharing Your Information</h6>
        <p>
          Rest assured that we neither rent nor sell your personal information to anyone and that we will share your personal
          information only as described below.
        </p>
        <p>
          Bahá’ís of the United States Personnel: Bahá’ís of the United States personnel and authorized consultants and/or
          contractors who provide us with business services may have access to user information if necessary in the normal course
          of the Bahá’ís of the United States business, including but not limited to monitoring and maintaining our websites and
          preparing newsletters and mailings.
        </p>
        <p>
          Business Transfers: In some cases, we may choose to buy or sell assets. In these types of transactions, user information
          is typically one of the business assets that is transferred. Moreover, if the Bahá’ís of the United States, or
          substantially all of its assets, were acquired, user information would be one of the assets that is transferred.
        </p>
        <p>
          With Your Consent: Except as noted above, we will contact you when your personal information is shared with third
          parties or used for a purpose incompatible with the purpose(s) for which it was originally collected, and you will be
          able to opt out to prevent the sharing of this information.
        </p>
        <br />
        <h6>Transfer of Your Personal Data</h6>
        <p>
          Our websites and online resources are owned and operated in the United States but the information you provide may be
          accessible by our affiliates and vendors in other countries. If you are visiting this site from a country other than the
          United States, your Personal Data will cross an international boundary.
        </p>
        <p>
          The level of protection for your Personal Data in the United States may not be the same as the level of protection in
          your country. By entering our site, you consent to the collection, storage, and processing of your Personal Data in the
          United States.
        </p>
        <br />
        <h6>Your Rights Concerning Your Personal Data</h6>
        <p>
          You have the right to the following information regarding the Bahá’ís of the United States’s processing of your Personal
          Data:
        </p>
        <ul className="no-margin">
          <li>The purposes of the processing.</li>
          <li>The categories of Personal Data concerned.</li>
          <li>The recipients or categories of recipient to whom the Personal Data have been or will be disclosed.</li>
          <li>
            Where possible, the envisaged period for which the Personal Data will be stored, or, if not possible, the criteria
            used to determine that period.{' '}
          </li>
          <li>
            To request that information, please contact us at <a href="mailto:membership@usbnc.org">membership@usbnc.org</a>.
          </li>
        </ul>
        <p>You also have the following rights with respect to your Personal Data:</p>
        <ul className="no-margin">
          <li>
            The right to request access to the personal data that the Bahá’ís of the United States has about you, as well as the
            right to request rectification of any data that is inaccurate or incomplete.
          </li>
          <li>
            The right to request a copy of your personal data in electronic format so that you can transmit the data to third
            parties, or to request that the Bahá’ís of the United States directly transfer your personal data to one or more third
            parties.
          </li>
          <li>The right to object to the processing of your personal data for marketing and other purposes.</li>
          <li>
            The right to erasure of your personal data when it is no longer needed for the purposes for which you provided it, as
            well as the right to restriction of processing of your personal data to certain limited purposes where erasure is not
            possible.
          </li>
          <li>
            The right to lodge a complaint with the supervisory authority where you believe that your rights have been violated.
          </li>
          <li>
            To exercise any of these rights, please contact us at <a href="mailto:membership@usbnc.org">membership@usbnc.org</a>.
          </li>
        </ul>
        <p>
          California Civil Code Section 1798.83 permits California residents to request certain information regarding our
          disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please
          write to the email address provided above. Be sure to include your name and contact information and we will respond
          within the time required by law.
        </p>
        <br />
        <h6>Children Under 18 Years of Age</h6>
        <p>
          You must be 16 years and older to register to use the Bahá’ís of the United States website. As a result, the Bahá’ís of
          the United States does not knowingly collect information about children unless they are registered by their parent or
          guardian. If we learn that Bahá’ís of the United States has collected information from a child under the age of 16, we
          will delete that information as quickly as possible. We recommend that minors between the ages of 16 and 18 ask and
          receive their parents’ permission before using the Bahá’ís of the United States website or sending information about
          themselves or anyone else over the Internet.
        </p>
        <br />
        <h6>Changes to this Privacy Policy</h6>
        <p>
          The Bahá’ís of the United States may amend this Privacy Policy from time to time, at its sole discretion. Use of
          information we collect now is subject to the Privacy Policy in effect at the time such information is used. If we make
          changes to the Privacy Policy, we will notify you by posting an announcement on the Bahá’ís of the United States
          website(s) so you are always aware of what information we collect, how we use it, and under what circumstances if any,
          it is disclosed. We suggest visiting this page regularly to ensure you are aware of the latest Privacy Policy.
        </p>
        <br />
        <h6>Conditions of Use</h6>
        <p>
          If you decide to visit the Bahá’ís of the United States’ websites (Bahai.us/community), your visit and any possible
          dispute over privacy is subject to this Privacy Policy and our Terms of Use, including limitations on damages,
          arbitration of disputes, and application of Illinois state law.
        </p>
        <br />
        <h6>Effective Date of this Privacy Policy</h6>
        <p>This Privacy Policy was last updated April 22, 2024.</p>
        <p>
          <NavLink data-cy="setNewPasswordBackToLogin" to="/login">
            &lt; back to login
          </NavLink>
        </p>
      </Col>
    </Row>
  );
}

export default withStyles(styles, { withTheme: true })(PrivacyPolicy);
