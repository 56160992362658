import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import ErrorMessageToUser from './ErrorMessageToUser';
import { withRouter } from 'react-router-dom';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      location: props.location,
    };
  }

  componentDidUpdate(prevProps) {
    const locationChanged = prevProps.location !== this.props.location;
    if (this.state.hasError && locationChanged) {
      this.setState({ hasError: false });
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    return (
      <div>
        {this.state.hasError ? (
          <Sentry.ErrorBoundary fallback={ErrorMessageToUser} showDialog>
            {this.props.children}
          </Sentry.ErrorBoundary>
        ) : (
          this.props.children
        )}
      </div>
    );
  }
}

export default withRouter(ErrorBoundary);
