import React, { Component } from 'react';
import withContext from '../../ContextAPI/Context_HOC';
import { Row, Col } from 'react-flexbox-grid';
import SacredObligationIcon from '../../assets/img/FundEducation/SacredObligation.png';
import Individual from '../../assets/img/FundEducation/Individual.png';
import Community from '../../assets/img/FundEducation/Community.png';
import Institution from '../../assets/img/FundEducation/Institution.png';
import SacredTrust from '../../assets/img/FundEducation/SacredTrust.png';
import InHonor from '../../assets/img/FundEducation/InHonor.png';
import InMemory from '../../assets/img/FundEducation/InMemory.png';
import Questions from '../../assets/img/FundEducation/Questions.png';

class SacredObligation extends Component {
  render() {
    return (
      <Row>
        <Col xs={12} md={6}>
          <Row>
            <Col sm={2}>
              <img src={SacredObligationIcon} alt="Sacred Obligation" className="fund-logo" />
            </Col>
            <Col sm={10}>
              <h1>Understanding Our Sacred Obligation and The National Bahá’í Fund Goals</h1>
              <p>
                &ldquo;There should be a continual flow of funds to the National treasury of the National Spiritual Assembly, if
                that body wishes to properly administer the manifold and ever-increasing activities of the Faith. Every Bahá’í no
                matter how poor, must realize what a grave responsibility he has to shoulder in this connection, and should have
                confidence that his spiritual progress as a believer in the World Order of Bahá’u’lláh will largely depend upon
                the measure in which he proves, in deeds, his readiness to support materially the divine institutions of His
                Faith.&rdquo;
              </p>
              <p>Shoghi Effendi, 17 July 1937, to a National Spiritual Assembly, published in Dawn of a New Day, pp. 67–68</p>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <a
            href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/Spiritual-importance-of-the-Fund-Individual.pdf"
            className="plain-link"
          >
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={Individual} alt="Individual" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Individual (PDF)</h4>
                <p>For further reflection, refer to Ruhi Book 11</p>
              </Col>
            </Row>
          </a>
          <br />
          <a
            href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/Spiritual-importance-of-the-Fund-Community.pdf"
            className="plain-link"
          >
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={Community} alt="Community" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Community (PDF)</h4>
                <p>For further reflection, refer to Ruhi Book 11</p>
              </Col>
            </Row>
          </a>
          <br />
          <a
            href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/Spiritual-importance-of-the-Fund-Institution.pdf"
            className="plain-link"
          >
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={Institution} alt="Institution" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Institution (PDF)</h4>
                <p>For further reflection, refer to Ruhi Book 11</p>
              </Col>
            </Row>
          </a>
          <br />
          <a
            href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/Description-of-the-Funds-of-the-Faith-2021.pdf"
            className="plain-link"
          >
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={SacredTrust} alt="Description of the Funds" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Description of the Funds (PDF)</h4>
              </Col>
            </Row>
          </a>
          <br />
          <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/in-honor-form.pdf" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={InHonor} alt="In Honor" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>In Honor contribution form (PDF)</h4>
              </Col>
            </Row>
          </a>
          <br />
          <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/in-memory-form.pdf" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={InMemory} alt="In Memory" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>In Memory contribution form (PDF)</h4>
              </Col>
            </Row>
          </a>
          <br />
          <a
            href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/forum-What-Questions-Should-I-Be-Asking-My-Treasurer.pdf"
            className="plain-link"
          >
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={Questions} alt="Questions I Should be Asking My Treasurer" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Questions to Ask the Treasurer as Assembly Members (PDF)</h4>
              </Col>
            </Row>
          </a>
        </Col>
      </Row>
    );
  }
}

export default withContext(SacredObligation);
