import React from 'react';

function FundArchivesProject() {
  return (
    <div>
      <h1>Archives Project</h1>
    </div>
  );
}

export default FundArchivesProject;
