import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import withContext from '../ContextAPI/Context_HOC';

class Prayers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkdisplay: [],
      prayertype: [],
      allMessages: [],
      feastmessages: [],
      feasturlname: [],
      errorOccurred: false,
    };
  }

  componentDidMount() {
    this.subscription = this.props.context.prayerContent
      .items()
      .type('prayer')
      .elementsParameter(['author', 'url_slug', 'base__title', 'prayer_category'])
      .toObservable()
      .subscribe({
        next: async (response) => {
          if (!response.isEmpty) {
            let links = [];
            let gpLinks = [];
            let taxonomies = [];
            let gpTaxonomies = [];
            let gpCount = 1;
            response.items.forEach((x) => {
              for (let i = 0; i < x.prayer_category.value.length; i++) {
                if (x.prayer_category.value.length === 2 && gpTaxonomies.indexOf(x.prayer_category.value[1].name) === -1) {
                  gpTaxonomies.push(x.prayer_category.value[1].name);
                }
                if (
                  taxonomies.indexOf(x.prayer_category.value[i].name) === -1 &&
                  !(gpTaxonomies.indexOf(x.prayer_category.value[i].name) !== -1) &&
                  x.prayer_category.value[i].name !== 'General Prayers'
                ) {
                  // && x.prayer_category.value[i].name !== "General Prayers"){
                  taxonomies.push(x.prayer_category.value[i].name);
                }
              }
            });
            taxonomies = taxonomies.sort();
            gpTaxonomies = gpTaxonomies.sort();
            gpLinks.push(
              <h2 className={'large-top-padding'} key={gpCount++}>
                General Prayers
              </h2>
            );
            gpTaxonomies.forEach((x, key) => {
              gpLinks.push(
                <h3 className={'large-top-padding left-margin'} key={gpCount}>
                  {x}
                </h3>
              );
              gpCount++;
              response.items.forEach((y) => {
                let urllevel = '';
                for (var j = 0; j < y.prayer_category.value.length; j++) {
                  urllevel = urllevel.concat('/', y.prayer_category.value[j].codename);
                }
                for (let i = 0; i < y.prayer_category.value.length; i++) {
                  if (x === y.prayer_category.value[i].name) {
                    gpLinks.push(
                      <li className={'left-margin'} key={gpCount} data-cy="prayerlist">
                        <Link
                          data-cy={`prayerlink${key}`}
                          to={{
                            pathname: `/prayer${urllevel}/${y.url_slug.value}`,
                          }}
                        >
                          {y.base__title.value}
                        </Link>
                      </li>
                    );
                    gpCount++;
                  }
                }
              });
            });
            taxonomies.forEach((x, key) => {
              links.push(
                <h2 className={'large-top-padding'} key={gpCount}>
                  {x}
                </h2>
              );
              gpCount++;
              response.items.forEach((y) => {
                let urllevel = '';
                for (var j = 0; j < y.prayer_category.value.length; j++) {
                  urllevel = urllevel.concat('/', y.prayer_category.value[j].codename);
                }
                for (let i = 0; i < y.prayer_category.value.length; i++) {
                  if (x === y.prayer_category.value[i].name) {
                    links.push(
                      <li key={gpCount} data-cy="prayerlist">
                        <Link
                          data-cy={`prayerlink${key}`}
                          to={{
                            pathname: `/prayer${urllevel}/${y.url_slug.value}`,
                          }}
                        >
                          {y.base__title.value}
                        </Link>
                      </li>
                    );
                    gpCount++;
                  }
                }
              });
            });
            this.setState({ linkdisplay: gpLinks.concat(links) });
          }
        },
        error: async () => {
          this.setState({ errorOccurred: true });
        },
      });
  }
  componentWillUnmount() {
    this.subscription.unsubscribe();
  }
  render() {
    return (
      <div>
        {this.state.errorOccurred === true ? (
          <div>
            An error has occurred while loading. Our team has been notified and is working to fix it. Please check back later.
          </div>
        ) : (
          <div>
            <h1>Prayers</h1>
            <ul className="plain-list">{this.state.linkdisplay}</ul>
          </div>
        )}
      </div>
    );
  }
}

export default withContext(Prayers);
