import React from 'react';
import { Link } from 'react-router-dom';

function LSA() {
  return (
    <div>
      <h1>Local Spiritual Assembly and Community References</h1>
      <p>
        This page provides a variety of resources to assist Local Spiritual Assemblies, communities, and individuals. Guidelines
        for Local Spiritual Assemblies: Developing Distinctive Bahá’í Communities is the standard reference for current guidance
        to assist Local Assemblies and facilitates their efforts to apply spiritual and administrative principles in consultation.
      </p>
      <p>
        Additionally, the page contains the most commonly used forms and resources related to Bahá’í law, marriage, divorce,
        domestic violence, Bahá’í funerals, protection of the Faith, and Assembly functioning.
      </p>
      <p>
        Community Administration
        <br />
        (847) 733-3485
        <br />
        community@usbnc.org
      </p>
      <hr />
      <h3>Emerging Insights Bulletin</h3>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/Judicious-Appointment-Committees_Emerging-Insights+%233.pdf">
            Judicious Appointment of Committees #3
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/Judicious-Appointment-Committees_Emerging-Insights_Persian.pdf">
            Judicious Appointment of Committees - Persian translation
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/Nurturing-Oneness-Expanding-Community_Emerging-Insights+%232.pdf">
            Nurturing Oneness in Expanding Community #2
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/Nurturing-Oneness-Expanding-Community_Emerging Insights_Persian.pdf">
            Nurturing Oneness in Expanding Community - Persian translation
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/Enhancing-Devotional-Character-Community_Emerging+Insights+%231.pdf">
            Enhancing Devotional Character of Community #1
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/Enhancing-Devotional-Character-Community_Emerging-Insights_Persian.pdf">
            Enhancing Devotional Character of Community - Persian translation
          </a>
        </li>
      </ul>
      <hr />
      <h3 id="GLSA" className="anchor-heading">
        Guidelines for Local Spiritual Assemblies: Developing Distinctive Bahá’í Communities
      </h3>
      <p>
        Originally produced in 1989, Guidelines for Local Spiritual Assemblies or GLSA, formerly known as{' '}
        <em>Developing Distinctive Bahá’í Communities: Guidelines for Local Spiritual Assemblies</em>, is a standard reference for
        authoritative guidance and national policies on facilitating the administration of community life by Local Spiritual
        Assemblies. The National Spiritual Assembly encourages all Local Assemblies to access and use this manual in preparation
        for and during Assembly meetings to clarify and deepen their understanding of relevant guidance necessary for their work.
      </p>
      <p>
        <em>Guidelines for Local Spiritual Assemblies</em> is available in electronic form only and is periodically updated.
        Please note that you have the National Assembly’s permission to make copies of portions of the manual for the use of your
        Local Assembly. Should you have any questions about the GLSA, please feel free to contact the Office of Community
        Administration by email at <a href="mailto:community@usbnc.org">community@usbnc.org</a> or by telephone at{' '}
        <a href="tel:847-733-3593">847-733-3593</a>.
      </p>
      <br />
      <h6>
        <strong>A NEW online GLSA is now available.</strong> You may access it <Link to="/glsa">here</Link> and learn more about
        how to navigate it <Link to="/website-updates/online-version-of-guidelines-for-lsas">here</Link>.
      </h6>
      <hr />
      <h3>Download Individual Chapters of Guidelines</h3>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/All+Table+of+Contents_Ch+1-14+as+of+March+2024.pdf">
            Table of Contents for all chapters
          </a>{' '}
          - <em>updated March 2024</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/GLSA-1-Foundations-(Updated-November-2023).pdf">
            Chapter 1 - Foundations of the Bahá’í Administrative Order
          </a>{' '}
          - <em>updated November 2023</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/GLSA+2+Baha'i+Elections+%26+LSA+Formation+(Updated+March+2024).pdf">
            Chapter 2 - Bahá’í Elections
          </a>{' '}
          - <em>updated March 2024</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/forms/Assemblies%26Communities/GLSA-3-Organization-updated-March-2016.pdf">
            Chapter 3 - Organization of the Local Assembly
          </a>{' '}
          - <em>updated March 2016</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/forms/Assemblies%26Communities/GLSA-4-Functioning-updated-April-2019.pdf">
            Chapter 4 - Functioning of the Local Assembly
          </a>{' '}
          - <em>updated April 2019</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/GLSA+5+Community+Membership+(Updated+October+2022).pdf">
            Chapter 5 - Community Membership
          </a>{' '}
          - <em>updated October 2022</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/oad-2014-GLSA-feb-chap-6.pdf">Chapter 6 - Community Life</a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/forms/Assemblies%26Communities/GLSA-7-Feast-updated-Jan-2021.pdf">
            Chapter 7 - The Nineteen Day Feast
          </a>{' '}
          - <em>updated January 2021</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/GLSA-8-Holy-Days-updated-Dec-2020.pdf">
            Chapter 8 – Holy Days
          </a>{' '}
          - <em>updated December 2020</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/forms/Assemblies%26Communities/GLSA-9-Protection-updated-April-2019.pdf">
            Chapter 9 - Protection
          </a>{' '}
          - <em>updated April 2019</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/forms/Assemblies%26Communities/GLSA-10-Laws-updated-June-2021.pdf">
            Chapter 10 - Applying Bahá’í Law
          </a>{' '}
          - <em>updated June 2021</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/GLSA+11+Marriage+and+Family+Life+updated+Oct.+2021.pdf">
            Chapter 11 - Marriage and Family Life
          </a>{' '}
          - <em>updated October 2021</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/GLSA-12-Divorce-updated-May-2022.pdf">
            Chapter 12 - Divorce - <em>updated May 2022</em>
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/oad-2014-GLSA-feb-chap-12-persian.pdf">
            Chapter 12 - Divorce (Persian)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/GLSA+13+Burial+updated+Oct.+2021.pdf">
            Chapter 13 - Burial
          </a>{' '}
          - <em>updated October 2021</em>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/GLSA+14+Other+LSA+issues+(updated+October+2022).pdf">
            Chapter 14 - Other Issues Brought Before the Assembly
          </a>{' '}
          - <em>updated October 2022</em>
        </li>
      </ul>
      <hr />
      <h3 id="SupplementsGuideLine" className="anchor-heading">
        Download Supplements to Guidelines
      </h3>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/Archival_Records_for_Assemblies_2022-08.pdf">
            Archival Records for Assemblies – revised August 2022 (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/BIA-Security-5.pdf">
            Computer Security for Bahá’í Institutions (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/tab/forms/2021-confidentiality-LSA-docs.pdf">
            Confidentiality Policy and Form (English and Persian) (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/oad-glsa-0911_dvs.pdf">
            Domestic Violence Supplement to Guidelines (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/oad-glsa-0911_dvs_Persian.pdf">
            Domestic Violence Supplement to Guidelines (Persian) (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/oad-emergency-contact-info-sheet.pdf">
            Emergency Contact Information Sheet (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/eso/sc-2012-child-protection-form.pdf">
            Protection of Minors - Form(s) (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/Guiding-Principles-and-Standards-Pertaining-to-the-Protection-of-Minors-revised-January-2023.pdf">
            Protection of Minors – Guiding Principles – revised January 2023 (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/Policy+for+the+Protection+of+Children%2C+Junior+Youth%2C+and+Youth+-+July+2024.pdf">
            Protection of Minors – Policy – revised July 2024 (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/opa-annual-guide.pdf">
            Public Affairs Annual Opportunities Guide (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/Public-Affairs-Policies-Guidelines-2017.pdf">
            Public Affairs Policies and Guidelines (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/Resources+for+Local+Spiritual+Assemblies+rev+2024-06-25.docx">
            Resources for LSAs - revised June 2024 (DOCX)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/OAD_Some+thoughts+on+Assembly+operational+practices_v15_July+2024.pdf">
            Some Thoughts on Operational Practices of the LSA (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/tab/forms/Year+of+Waiting+and+Divorce+Flowchart+for+LSAs.pdf">
            Year of Waiting Flowchart for LSAs (PDF)
          </a>
        </li>
      </ul>
      <hr />
      <h3>Persian Resources</h3>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/resources/assemblyReferences/Handbook+for+Persian-speaking+members+of+the+US+Baha%27i+community+-+01-2017+(Persian).pdf">
            Handbook for Persian-speaking members of the US Bahá’í community - 01-2017 (Persian)
          </a>{' '}
          (راهنما برای فارسی زبانان جامعۀ بهائی آمریکا)
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/An-Introduction-to-Bahai-Administration-in-Persian-2008.pdf">
            An Introduction to Bahá’í Administration – 2008 (Persian)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/resources/assemblyReferences/UHJ+letter+02-10-80+to+Iranian+Bah%C3%A1'%C3%ADs+outside+of+Iran+(Persian).pdf">
            UHJ letter 02-10-80 to Iranian Bahá’ís outside of Iran (Persian)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/resources/assemblyReferences/UHJ+letter+154+BE+to+Iranian+Bah%C3%A1'%C3%ADs+outside+of+Iran+(Persian).pdf">
            UHJ letter 154 BE to Iranian Bahá’ís outside of Iran (Persian)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/resources/assemblyReferences/Feast+-+attendance+by+Bah%C3%A1'%C3%ADs+only+(Persian).pdf">
            Feast - attendance by Bahá’ís only (Persian)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/resources/assemblyReferences/Feast+-+references+re.+Nineteen+Day+Feasts+(Persian).pdf">
            Feast - references re. Nineteen Day Feasts (Persian)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/resources/assemblyReferences/Feast+-+UHJ+04-10-01+scheduling+Feasts+(Persian).pdf">
            Feast - UHJ 04-10-01 scheduling Feasts (Persian)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/resources/assemblyReferences/Feast+-+UHJ+05-17-09+(Persian).pdf">
            Feast - UHJ 05-17-09 (Persian)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/resources/assemblyReferences/NSA+letter+02-07-13+re.+LSA+role+protecting+integrity+Baha%27i+law+(Persian).pdf">
            NSA letter 02-07-13 re. LSA role protecting integrity Bahá’í law (Persian)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/resources/assemblyReferences/Marriage+-+extracts+from+Baha%27i+Writings+(Persian).pdf">
            Marriage - extracts from Bahá’í Writings (Persian)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/resources/assemblyReferences/Divorce+-+extracts+from+Baha%27i+Writings+(Persian).pdf">
            Divorce - extracts from Bahá’í Writings (Persian)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/resources/assemblyReferences/GLSA+Ch.+12+Divorce+-+Feb+2014+(Persian).pdf">
            GLSA Ch. 12 Divorce - Feb 2014 (Persian)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/resources/assemblyReferences/UHJ+letter+01-24-1993+re.+domestic+violence+(Persian).pdf">
            UHJ letter 01-24-1993 re. domestic violence (Persian)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/resources/assemblyReferences/Domestic+Violence+Supplement+to+Guidelines+(Persian).pdf">
            Domestic Violence Supplement to Guidelines (Persian)
          </a>
        </li>
      </ul>
      <hr />
      <h2>
        <strong>Community Administration</strong>
      </h2>
      <p>
        <em>
          Looking for <a href="#ca-faq">Frequently Asked Questions</a>?
        </em>
      </p>
      <p>
        The Office of Community Administration is part of the National Spiritual Assembly’s Secretariat and handles issues
        pertaining to Bahá’í law, marriage, divorce, domestic violence, Bahá’í funerals, protection of the Faith and Assembly
        functioning.
      </p>
      <hr />
      <h3>Bahá’í Locality Boundary Questions</h3>
      <p>
        For boundary questions, please refer to Guidelines for Local Spiritual Assemblies, chapter 2, pages 35-37, or contact the
        Office of Community Administration at <a href="mailto:community@usbnc.org">community@usbnc.org</a> or{' '}
        <a href="tel:847-733-3485">847-733-3485</a>.
      </p>

      <hr />
      <h3>Assembly Confidentiality Agreement</h3>
      <p>
        FOR ALL LOCAL SPIRITUAL ASSEMBLIES — Please review the Summary of Confidentiality Policies. The National Spiritual
        Assembly mailed out the new Confidentiality Agreement to be signed annually by all Local Assembly members. Please download
        a copy of the Agreement and the cover letter which explains the Agreement and its importance.
      </p>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/tab/forms/2021-confidentiality-LSA-docs.pdf">
            Summary of Confidentiality Policies
          </a>
        </li>
      </ul>
      <hr />
      <h3>Articles of Incorporation and By-Laws for Local Assemblies</h3>
      <p>
        Assemblies are asked to submit their proposed Articles of Incorporation and By-Laws to the Office of Legal Affairs (
        <a href="mailto:legal@usbnc.org">legal@usbnc.org</a>) for approval before filing them with their Secretary of State since
        some states require additional information.
      </p>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/LSAByLaws.docx">
            By-Laws for Local Assemblies
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/ca-steps-for-incorporation.doc">
            Steps for Assembly Incorporation
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/LSA+Articles+of+Incorporation.docx">
            Articles of Incorporation for Assemblies
          </a>
        </li>
      </ul>
      <hr />
      <h3>Local Spiritual Assembly Role in Protecting Integrity of Bahá’í Law</h3>
      <p>
        The National Spiritual Assembly wishes to share with the community a letter dated February 7, 2013, addressed to a Local
        Spiritual Assembly regarding its role in protecting the integrity of Bahá’í law.
      </p>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/oad-lsa-role-protecting-integrity_English.pdf">
            National Spiritual Assembly Letter
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/oad-lsa-role-protecting-integrity_Persian.pdf">
            National Spiritual Assembly Letter (Persian Translation)
          </a>
        </li>
      </ul>
      <hr />
      <h3>Daylight Saving Time Affects Bahá’í Holy Days</h3>
      <p>
        The Universal House of Justice has clarified that Holy Days should be observed according to local standard time. A chart
        of Holy Days and the time they should be commemorated is below.
      </p>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/ca-dst-holy-days.pdf">Chart of Holy Days</a>
        </li>
      </ul>
      <hr />
      <h3>Bahá’í Holy Day Explanation Letter for Employers</h3>
      <p>
        This letter has been prepared by the National Spiritual Assembly to assist Bahá’í employees with their request to be
        excused from work on Bahá’í Holy Days.
      </p>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/20240101+-+Excused+absence+Holy+Days+-+Employers.pdf">
            2024 Holy Day Explanation Letter For Employers (PDF)
          </a>
        </li>
      </ul>
      <hr />
      <h3>Bahá’í Holy Day Explanation Letter for Students</h3>
      <p>
        This letter was prepared by the National Spiritual Assembly to request permission for students to be excused from schools
        on Bahá’í Holy Days falling during the current academic year.
      </p>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/20230101-Excused-absence-Holy-Days-Students.pdf">
            Letter for Students – Academic Year 2023–2024 (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/20240101+-+Excused+absence+Holy+Days+-+Students.pdf">
            Letter for Students – Academic Year 2024–2025 (PDF)
          </a>
        </li>
      </ul>
      <hr />
      <h3>Policy Statement from the National Spiritual Assembly on Domestic Violence </h3>
      <p>
        The National Spiritual Assembly wishes to convey a clear message that acts of domestic violence are at complete variance
        with the teachings of Bahá’u’lláh and that violence in the family is a practice to be condemned. In addition, domestic
        violence is a criminal act in the United States.
      </p>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/ca-domestic-violence-2002-nsa_English.pdf">
            Domestic Violence Policy
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/ca-domestic-violence-2002-nsa_Persian.pdf">
            Domestic Violence Policy (Persian)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/ca-domestic-violence-2002-nsa_Spanish.pdf">
            Domestic Violence Policy (Spanish)
          </a>
        </li>
      </ul>
      <hr />
      <h3>A Letter from the Universal House of Justice on Domestic Violence</h3>
      <p>
        As you know, the principle of the oneness of mankind is described in the Bahá’í Writings as the pivot round which all the
        Teachings of Bahá’u’lláh revolve. * Please Note: Do not distribute this PDF electronically, (e.g. by E-mail or posting on
        a Web site).
      </p>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/ca-domestic-violence-1993-uhj_English.pdf">
            Jan. 24, 1993 Letter
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/ca-domestic-violence-1993-uhj_Persian.pdf">
            Jan. 24, 1993 Letter (Persian)
          </a>
        </li>
      </ul>
      <hr />
      <h3>Statement from the National Spiritual Assembly to all Local Assemblies on Domestic Violence</h3>
      <p>
        Among the goals of the Five Year Plan are fostering the spirit of loving encouragement and support to Bahá’í families and
        championing gender equality and social justice. One of the most serious and pervasive crises facing families today, in all
        societies around the world, is domestic violence.
      </p>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/ca-domestic-violence-2002-nsa-lsa_English.pdf">
            Oct. 31, 2002 Letter
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/ca-domestic-violence-2002-nsa-lsa_Persian.pdf">
            Oct. 31, 2002 Letter (Persian)
          </a>
        </li>
      </ul>
      <hr />

      <h3>Some Guidance for Spiritual Assemblies Related to Mental Illness and Its Treatment</h3>
      <p>
        This document raises some questions and suggest some answers which might assist Local Spiritual Assemblies to acquire a
        basic education concerning mental illness and its treatment.
      </p>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/ca-mental-illness.pdf">
            Guidance for Assemblies Related to Mental Illness
          </a>
        </li>
      </ul>
      <hr />
      <h3>Information about the Bahá’í Faith for Funeral Directors</h3>
      <p>
        This document, suitable for giving to funeral directors, provides information on Bahá’í burial laws and related subjects,
        as well as general background information on the Faith.
      </p>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/Bahai-Information-for-Funeral-Directors-2019-09.pdf">
            Information about the Bahá’í Faith for Funeral Directors
          </a>
        </li>
      </ul>
      <hr />
      <h3>eMembership2</h3>
      <p>
        <a href="https://emem.usbnc.org/">Log in</a> to eMembership2
      </p>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/forms/Membership/eMem2-recent-updates-2018.pdf">
            eMembership2 Recent Updates (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/forms/Membership/eMem2-user-guide-2018.pdf">
            eMembership2 User Guide (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/mem-eM2-faq-2017.pdf">eMembership2 FAQ (PDF)</a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/2017-eMem2-helpful-election-tools.pdf">
            eMembership2 Helpful Election Tools (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/forms/Membership/eMem2-quick-guide-2018.pdf">
            eMembership2 Quick Guide (PDF)
          </a>
        </li>
      </ul>
      <hr />
      <h3 id="ca-faq">Frequently Asked Questions</h3>
      <ul className="plain-list">
        <li>
          <a href="#QA1">Is Bahá’í engagement contingent on parental consent?</a>
        </li>
        <li>
          <a href="#QA2">
            What is the current guidance concerning individuals of the opposite sex who are not related sharing the same
            residence?
          </a>
        </li>
        <li>
          <a href="#QA3">Are businesses owned by Bahá’ís obliged to conform to Bahá’ís laws and teachings?</a>
        </li>
        <li>
          <a href="#QA5">What is my obligation in assisting individual Bahá’ís who request financial aid or hospitality?</a>
        </li>
        <li>
          <a href="#QA6">Does the Bahá’í National Center release my personal information?</a>
        </li>
        <li>
          <a href="#QA7">Can Iranian refugees newly arrived to the United States attend the Nineteen Day Feast?</a>
        </li>
      </ul>
      <hr id="QA1" />
      <p>Is Bahá’í engagement contingent on parental consent?</p>
      <p>
        The National Spiritual Assembly wishes to remind the believers that a couple is not considered to be engaged to be married
        until the consent of all living parents has been obtained by the Bahá’í institutions. Accordingly, a couple should not
        make marriage plans, and certainly should not set a date for a ceremony, send invitations, and so on, until the consent of
        all natural parents is obtained.
      </p>
      <p>
        By delaying their plans until consent has been obtained, the couple will not only show proper respect for Bahá’í law but
        will also avoid the awkward situation of not having the required consents as the planned marriage date approaches.
      </p>
      <hr id="QA2" />
      <p>
        What is the current guidance concerning individuals of the opposite sex who are not related sharing the same residence?
      </p>
      <p>In a letter written on its behalf, dated October 13, 1997, the Universal House of Justice states:</p>
      <p>
        “With respect to your question about two people of the opposite sex who are not married living in the same house, such an
        arrangement would by no means automatically constitute an ‘immoral relationship.’ It is not uncommon, for example, for a
        person to let out bed-sitting rooms in his or her house to students, vacationers, or others. Similarly, a man or woman may
        employ a housekeeper or servant of the other sex. What is considered proper in such arrangements varies from country to
        country. The laws and principles of our Faith, on the other hand, are meant to apply across all cultures, and what Bahá’ís
        would consider immoral is people who are not married living together as husband and wife, and indulging in sexual
        relations.
      </p>
      <p>
        “Beyond this, however, even if the relationship between a man and woman is entirely innocent, it is vitally important that
        they should not live together in a way that would give the wrong impression. If there is any doubt about the perceived
        morality of a particular situation they should unhesitatingly avoid it and seek alternative arrangements in order to
        preserve the good name of the Faith. In following the standards of the Faith, the friends must be not only righteous, but
        also wise. Naiveté can do great harm, especially in the present unstable condition of social morals in the world at
        large.”
      </p>
      <p>
        Although it is no longer uncommon among the population at large in the United States for unmarried individuals of the
        opposite sex to share living arrangements, such arrangements are not in keeping with the high standards and moral
        expectations of the Bahá’í teachings. The National Assembly feels that, even when such arrangements maintain a purely
        economic character, they may still create an appearance of immorality or moral laxity that adversely affects the
        reputation of the Bahá’í Faith and its teachings. Although, generally speaking, unmarried Bahá’ís should not live in one
        house with members of the opposite sex who are unrelated to them, it is up to the Local Spiritual Assembly in whose area
        of jurisdiction the individuals are living to determine, on a case-by-case basis, whether or not a given living situation
        creates an appearance of immorality.
      </p>
      <hr id="QA3" />
      <p>Are businesses owned by Bahá’ís obliged to conform to Bahá’ís laws and teachings?</p>
      <p>
        Bahá’ís who entirely own businesses are obligated to conform their practices and dealings to the laws and teachings of the
        Faith. For example, a restaurant owned by a Bahá’í should not serve alcohol or feature entertainment that is inconsistent
        with the teachings. In addition, stores and establishments owned by Bahá’ís should be closed on the nine Holy Days, even
        though they may have non-Bahá’ís in their employment.
      </p>
      <hr id="QA5" />
      <p>What is my obligation in assisting individual Bahá’ís who request financial aid or hospitality?</p>
      <p>
        Because it is generally known that Bahá’ís are by nature generous and sympathetic, it is not uncommon for them to receive
        appeals for financial and other kinds of emergency help from itinerant Bahá’ís and others who claim to be Bahá’ís or
        friends of the Faith. While it is left to the discretion of those believers to respond as they see fit, it should be
        remembered that the Bahá’í community is not a traveler’s aid or social service agency prepared to assist the needs of
        itinerants other than in some selective and discriminating cases.
      </p>
      <p>
        Although Bahá’u’lláh has told us to “be a home for the stranger . . . an answerer of the cry of the needy,” no Bahá’í
        should presume that the hospitality of his fellow Bahá’ís is his due. Bahá’u’lláh also states, “No man should enter the
        house of his friend, save at his friend’s pleasure, nor lay hands upon his treasures, nor prefer his own will to his
        friend’s, and in no wise seek advantage over him.” Thus Bahá’u’lláh makes hospitality the prerogative of the host and not
        of the guest. Common courtesy demands that an individual should not impose himself upon anyone without prior invitation,
        and Bahá’ís should be far more courteous than others in this respect.
      </p>
      <p>
        Unfortunately, it sometimes happens that a person will contact an Assembly or individual believer, claiming to be a Bahá’í
        but having no Bahá’í credentials or other forms of identification. Before offering aid to such a person it is advisable to
        check with this Office to verify that the person is indeed a member of the Faith.
      </p>
      <hr id="QA6" />
      <p>Does the Bahá’í National Center release my personal information?</p>
      <p>
        From time to time, the names addresses and telephone numbers of individual Bahá’ís are requested from the Bahá’í National
        Center, Local Spiritual Assemblies, by other Bahá’ís, non-Bahá’ís, and public or private agencies.
      </p>
      <p>
        The National Spiritual Assembly’s policy is that such information is private and confidential and thus should not be made
        available without the person’s consent, unless it is requested by a Bahá’í Institution for official business purposes
        only. Correspondence and messages will, of course, be forwarded to the person that the inquirer wishes to contact.
      </p>
      <p>
        Addresses and telephone numbers of Local Spiritual Assemblies are not considered confidential and may be furnished upon
        request.
      </p>
      <hr id="QA7" />
      <p>Can Iranian refugees newly arrived to the United States attend the Nineteen Day Feast?</p>
      <p>
        Iranian refugees must have valid Bahá’í credentials before they can attend the Nineteen Day Feasts and other Bahá’ís-only
        meetings. While waiting for their Bahá’í credentials, Iranian refugees are welcome to participate in all activities open
        to non-Bahá’ís.
      </p>
      <p>
        To receive their credentials, Iranian refugees must transfer their Bahá’í membership to the United States, and this
        process can only be completed through the Membership Office at the Bahá’í National Center. Local Spiritual Assemblies are
        not authorized to make such transfers, nor should they become involved in legal matters of immigration. According to
        instructions from the Universal House of Justice, Persian (Iranian) Bahá’ís who have left Iran since the 1979 Revolution,
        and whose credentials have not yet been verified, may not be registered as U.S. Bahá’ís before their Bahá’í membership is
        confirmed. Unfortunately, registration of Iranian Bahá’ís involves a number of steps, and because communication with the
        Bahá’ís in Iran is often difficult, the verification process may be lengthy sometimes.
      </p>
      <p>
        The process for transferring Iranian Bahá’ís to U.S. Bahá’í membership is carefully outlined in Chapter 6 of Guidelines
        for Local Spiritual Assemblies, which is available online at the Bahá’í administrative website. Local Spiritual Assemblies
        should check the credentials of all newly-arrived Bahá’ís, lovingly explain the need for complying with the instructions
        of the Universal House of Justice and the policies of the National Spiritual Assembly, and enforce transfer procedures
        firmly and uniformly.
      </p>
    </div>
  );
}

export default LSA;
