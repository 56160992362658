import React, { CSSProperties, useEffect, useState } from 'react';
import DigitalIdCardImage from '../assets/img/digital-id-card.png';
import BrandFull from '../assets/img/brand-full.png';
import { Observable } from 'rxjs';
import { DigitalIdCardResponse } from './DigitalIdCardService';

type DigitalIdCardProps = {
  context: any;
  setDigitalIdCardOpen: React.Dispatch<React.SetStateAction<boolean | undefined>>;
};
type BahaiDate = {
  bahaiDate: {
    basedOnLocation: string;
    day: number;
    month: string;
    monthAscii: string;
    monthExtendedAscii: string;
    monthExtendedAsciiAndStylizedQuotes: string;
    year: number;
  };
};

export function DigitalIdCardFunction(props: DigitalIdCardProps) {
  const token = sessionStorage.getItem('id_token');

  const { setDigitalIdCardOpen } = props;
  let gregorianDate = '';
  if (!props.context.gregorianDate) {
    gregorianDate = new Date().toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  }
  const [digitalIdCard, setDigitalIdCard] = useState<DigitalIdCardResponse>();
  const [bahaiDate, setBahaiDate] = useState<BahaiDate>();
  const borderStyle: CSSProperties = {
    margin: '10px',
    border: '3px solid black',
    borderRadius: '16px',
    padding: '15px',
  };
  const textStyle: CSSProperties = {
    color: '#ada195',
    textAlign: 'center',
  };
  useEffect(() => {
    const observable: Observable<DigitalIdCardResponse> = props.context.digitalIdCardService.getDigitalIdCard(token);
    const subscription = observable.subscribe({
      next: (data) => {
        if (Object.keys(data).length > 0) {
          setDigitalIdCard(data);
        }
      },
      error: () => undefined,
    });
    const dateObservable = props.context.calendarService.getToday();

    const dateSubscription = dateObservable.subscribe((bahaiDate: BahaiDate) => {
      setBahaiDate(bahaiDate);
    });
    return () => {
      subscription.unsubscribe();
      dateSubscription.unsubscribe();
    };
  }, []);
  return (
    <>
      <div className="d-flex justify-content-center">
        <div>
          <div className="row">
            <div className="col-sm-9"></div>
            <div
              className="col-sm-3"
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                e.preventDefault();
                setDigitalIdCardOpen(false);
              }}
            >
              <div className="text-end">
                <button className="btn btn-danger" style={{ margin: '10px' }}>
                  <strong>X</strong>
                </button>
              </div>
            </div>
          </div>
          <div className="row" style={borderStyle}>
            <div className="col-sm-4">
              <img
                className="img-fluid rounded mx-auto d-block digital-id-card"
                src={DigitalIdCardImage}
                alt="The front entrance and reflecting pool of the Baha'i House of Worship for North America"
              />
            </div>
            <div className="col-sm-8">
              <img className="img-fluid rounded mx-auto d-block" src={BrandFull} alt="Nine-pointed star" />
              <h2 className="logo-text" style={textStyle}>
                Bahá’ís of the United States
              </h2>

              {digitalIdCard && (
                <div className="text-center">
                  <br />
                  <p className="center logo-text-description">
                    {gregorianDate}
                    {bahaiDate ? (
                      <>
                        |
                        <span title={'Based on sunset at ' + bahaiDate?.bahaiDate.basedOnLocation}>
                          {bahaiDate?.bahaiDate.basedOnLocation !== 'home address' ? <>*&nbsp;</> : null}
                          {bahaiDate?.bahaiDate.day}&nbsp;
                          {bahaiDate?.bahaiDate.monthExtendedAsciiAndStylizedQuotes}&nbsp;
                          {bahaiDate?.bahaiDate.year} B.E.
                        </span>
                      </>
                    ) : null}
                  </p>
                  <p data-cy={'digital-id-card-fullName'}>
                    <strong>{digitalIdCard?.fullName}</strong>
                  </p>
                  <p data-cy={'digital-id-card-idn'}>
                    <strong>IDN: </strong>
                    {digitalIdCard?.idn}
                  </p>
                  <p data-cy={'digital-id-card-blc'}>
                    <strong>Bahá’í Locality Code: </strong>
                    {digitalIdCard?.blc}
                  </p>
                  <p data-cy={'digital-id-card-cluster'}>
                    <strong>Cluster: </strong>
                    {digitalIdCard?.cluster}
                  </p>
                  <p data-cy={'digital-id-card-euCode'}>
                    <strong>EU Code: </strong>
                    {digitalIdCard?.euCode}
                  </p>
                  <p data-cy={'digital-id-card-region'}>
                    <strong>Region: </strong>
                    {digitalIdCard?.region}
                  </p>
                </div>
              )}
              {!digitalIdCard && (
                <div className="text-center">
                  <br />
                  <p data-cy={'no-member-message'}>
                    Please contact the Membership Office at{' '}
                    <a href="mailto:membership@usbnc.org" target="_blank" rel="noreferrer">
                      membership@usbnc.org
                    </a>{' '}
                    or call{' '}
                    <a href="tel:(847) 733-3445" rel="noreferrer" target="_blank">
                      (847) 733-3445
                    </a>
                    .
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DigitalIdCardFunction;
