import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';
import type IToken from '../../Login/IToken';
import { switchMap, filter, map } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import type LookupListCriteria from './LookupListCriteria';
import type LookupLists from './LookupLists';

export const requestBody: LookupListCriteria = {
  IncludeCountryList: true,
  IncludeRaceEthnicityList: true,
  IncludeTitleList: true,
  IncludeSuffixList: true,
  IncludeLanguagePreferenceList: true,
  IncludeNsaStateList: true,
  IncludeAllStateList: true,
};

export default class LookupProxy {
  authService: IToken;
  dataApiBaseUrl: string;
  lookupListsSubject = new ReplaySubject<LookupLists>(1);
  lookupListsRefreshed: boolean = false;

  constructor(auth: IToken, dataApiBaseUrl: string) {
    this.authService = auth;
    this.dataApiBaseUrl = dataApiBaseUrl;
  }

  countries() {
    if (!this.lookupListsRefreshed) {
      this.lookupListsRefreshed = true;
      this.refreshLists();
    }
    return this.lookupListsSubject.asObservable().pipe(map((lists) => lists.countryList));
  }

  refreshLists(): void {
    this.authService
      .getTokenObservable()
      .pipe(filter((token) => !!token))
      .pipe(
        switchMap((token) =>
          GlobalAPICall.postObservable(`${this.dataApiBaseUrl}/api/LookupList`, requestBody, {
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          })
        )
      )
      .pipe(map((items) => items.data))
      .subscribe(this.lookupListsSubject);
  }
}
