import React, { Component } from 'react';
import withContext from '../../ContextAPI/Context_HOC';
import { Row, Col } from 'react-flexbox-grid';
import Accounting from '../../assets/img/TreasurersTools/Accounting.png';
import BudgetWorksheet from '../../assets/img/TreasurersTools/BudgetWorksheet.png';
import BankBalance from '../../assets/img/TreasurersTools/BankBalance.png';
import FinancialReport from '../../assets/img/TreasurersTools/FinancialReport.png';

class TreasurersAccounting extends Component {
  render() {
    return (
      <Row>
        <Col xs={12} md={6}>
          <Row>
            <Col sm={2}>
              <img src={Accounting} alt="Accounting" className="fund-logo" />
            </Col>
            <Col sm={10}>
              <h1>Accounting</h1>
              <p>
                <strong>Note:</strong> For PDFs that you can edit on your computer, such as the Audit Worksheet Form, either
                download the file before making edits to the form or make edits within your browser and print directly.
              </p>
              <p>
                To stay connected to Treasurer information and upcoming Important dates, text 847–733–3472 and in the message box
                write &ldquo;Treasurer&rdquo;. Have questions? After signing up, text 847–733–3472 and in the message box write
                your question.
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <a
            href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/accounting_budget_worksheet_instructions_w_sample_entries_092821.pdf"
            className="plain-link"
          >
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={Accounting} alt="Accounting and Budget Worksheet Instructions" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Accounting and Budget Worksheet Instructions (PDF)</h4>
              </Col>
            </Row>
          </a>
          <br />
          <a
            href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/110222-Accounting-and-Budget-Worksheet-with-Sample-Entries.xlsx"
            className="plain-link"
          >
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={BudgetWorksheet} alt="Accounting and Budget Worksheet" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Accounting and Budget Worksheet for LSAs (XLSX)</h4>
              </Col>
            </Row>
          </a>
          <br />
          <a
            href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/treas-bank-balance-confirmation-2022.pdf"
            className="plain-link"
          >
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={BankBalance} alt="Bank Balance" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Bank Balance Confirmation Form (PDF)</h4>
              </Col>
            </Row>
          </a>
          <br />
          <a
            href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/treas-financial-report-form.pdf"
            className="plain-link"
          >
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={FinancialReport} alt="Financial Report Forms" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Financial Report Form (PDF)</h4>
              </Col>
            </Row>
          </a>
        </Col>
      </Row>
    );
  }
}

export default withContext(TreasurersAccounting);
