import React, { Component } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withContext from '../../ContextAPI/Context_HOC';
import { withRouter } from 'react-router-dom';
import { InputBase } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import QueryString from 'query-string';

const styles = (theme) => ({
  search: {
    position: 'relative',
    borderRadius: '3px',
    minWidth: '200px',
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
  },
  searchIcon: {
    width: theme.spacing(6),
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(6),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
});

class Search extends Component {
  constructor(props) {
    super(props);
    this.handleSearch = this.handleSearch.bind(this);
    this.submitSearch = this.submitSearch.bind(this);
    this.state = {
      searchValue: '',
    };
  }
  handleSearch(e) {
    this.setState({ searchValue: e.target.value });
  }
  submitSearch(e) {
    e.preventDefault();
    const queryParameters = { searchString: this.state.searchValue };
    const queryStringified = QueryString.stringify(queryParameters);
    this.props.history.push({
      pathname: '/search-results',
      search: queryStringified,
    });
    this.setState({ searchValue: '' });
  }

  render() {
    const { classes } = this.props;
    return (
      <form data-cy="search" className={classes.search} onSubmit={this.submitSearch}>
        <div className={classes.searchIcon} onClick={this.submitSearch}>
          <SearchIcon data-cy="searchicon" />
        </div>
        <InputBase
          data-cy="searchbar"
          placeholder="Search Guidance…"
          aria-label="Search"
          role="search"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          value={this.state.searchValue}
          id="searchinput"
          onChange={this.handleSearch}
        />
      </form>
    );
  }
}
Search.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withContext(withStyles(styles)(Search)));
