import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import withContext from '../../ContextAPI/Context_HOC';
import FormsAndDocs from '../../assets/img/TreasurersTools/FormsAndDocs.png';
import FAQ from '../../assets/img/TreasurersTools/FAQandInfo.png';
import EIN from '../../assets/img/TreasurersTools/EIN.png';
import TaxConfirm from '../../assets/img/TreasurersTools/TaxExemptionConfirmation.png';
import TaxExemptAffidavit from '../../assets/img/TreasurersTools/TaxAffidavit.png';
import Insurance from '../../assets/img/TreasurersTools/Insurance.png';
import InsuranceCert from '../../assets/img/TreasurersTools/InsuranceCert.png';
import Loans from '../../assets/img/TreasurersTools/Loans.png';
import SacredObligationIcon from '../../assets/img/FundEducation/SacredObligation.png';

class TreasurersForms extends Component {
  render() {
    return (
      <Row>
        <Col xs={12} md={6}>
          <Row>
            <Col sm={2}>
              <img src={FormsAndDocs} alt="Treasurer’s Forms" className="fund-logo" />
            </Col>
            <Col sm={10}>
              <h1>Treasurer’s Forms and Documents</h1>
              <p>
                <strong>Note:</strong> For PDFs that you can edit on your computer, such as the Audit Worksheet Form, either
                download the file before making edits to the form or make edits within your browser and print directly.
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/treas-lsa-ocs-faq.pdf" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={FAQ} alt="FAQs" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>FAQ for the Local Fund Online Program (PDF)</h4>
              </Col>
            </Row>
          </a>
          <br />
          <a href="https://s3.amazonaws.com/bahai-web/forms/Treasurer/Tools/how-to-apply-for-an-EIN.pdf" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={EIN} alt="EIN" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>How to Apply for an EIN (PDF)</h4>
              </Col>
            </Row>
          </a>
          <br />
          <a
            href="https://s3.amazonaws.com/bahai-web/forms/Treasurer/Tools/501(c)(3)-Feb-2018-NSA-group-tax-exempt-confirm.pdf"
            className="plain-link"
          >
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={TaxConfirm} alt="Tax Exemption" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>501(c)(3) Feb 2018 NSA Group Tax Exempt Confirm (PDF)</h4>
              </Col>
            </Row>
          </a>
          <br />
          <a
            href="https://s3.amazonaws.com/bahai-web/forms/Treasurer/Tools/501(c)(3)-Feb-2018-NSA-IRS-tax-exempt-affidavit.pdf"
            className="plain-link"
          >
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={TaxExemptAffidavit} alt="Tax Affidavit" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>501(c)(3) Feb 2018 NSA IRS Tax Exempt Affidavit (PDF)</h4>
              </Col>
            </Row>
          </a>
          <br />
          <a
            href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/Insurance-Annual-Letter-to-LSAs-2021-2022_Revised.pdf"
            className="plain-link"
          >
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={Insurance} alt="Insurance Info" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>LSA Liability Insurance Info (PDF)</h4>
              </Col>
            </Row>
          </a>
          <br />
          <a
            href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/Request-for-Certificate-of-Insurance-Form-2023.pdf"
            className="plain-link"
          >
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={InsuranceCert} alt="Insurance Certificate Request" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Insurance Certificate Request (PDF) (updated December 2022)</h4>
              </Col>
            </Row>
          </a>
          <br />
          <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/Making-a-Loan-to-the-NSA.pdf" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={Loans} alt="Making a Loan" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Making a Loan to the NSA (PDF)</h4>
              </Col>
            </Row>
          </a>
          <br />
          <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/Contributions-Form.pdf" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={SacredObligationIcon} alt="Contributions Form" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Contribution form (PDF)</h4>
              </Col>
            </Row>
          </a>
        </Col>
      </Row>
    );
  }
}

export default withContext(TreasurersForms);
