import React, { FunctionComponent, useEffect, useState } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import type HomeBlockProxy from './Utilities/HomeBlockProxy';
import { ByRowThenColumnThenMoveToPreviousDate, ToOrganizedBlocks, ToJsxElements } from './Utilities/HomeBlockUtilities';

const HomeBlockList: FunctionComponent<{
  context: { homeBlockProxy: HomeBlockProxy };
}> = ({ context: { homeBlockProxy } }) => {
  const [homeBlockRows, setHomeBlockRows] = useState([] as JSX.Element[]);

  useEffect(() => {
    const homeBlockProxyGetHomeBlocksSubscription = homeBlockProxy.getHomeBlocks().subscribe((x) => {
      const orderByRowThenColumnThenMoveToPreviousDate = x.sort(ByRowThenColumnThenMoveToPreviousDate);
      const organizedBlocks = orderByRowThenColumnThenMoveToPreviousDate.reduce(ToOrganizedBlocks, []);
      const displayRows = organizedBlocks.map(ToJsxElements);
      setHomeBlockRows(displayRows);
    });

    return () => homeBlockProxyGetHomeBlocksSubscription.unsubscribe();
  }, [homeBlockProxy]);

  return <div>{homeBlockRows}</div>;
};

export default withContext(HomeBlockList);
