import { Column } from 'material-table';
import React from 'react';

import { DirectoryData } from './AbmDirectory';
import { DataGrid, GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import { HuquqResponse } from './HuquqRep';
import { DatagridPropsCustom } from './interfaces/datagrid-props-custom';

type HuquqRepTableProps = {
  defaultmessage: JSX.Element;
  columns: {
    columns: (Column<HuquqResponse> & DatagridPropsCustom)[];
  };
  filteredData: HuquqResponse[];
  pageSize?: number;
  disableVirtualization?: boolean;
  columnBuffer?: number;
};
export default function HuquqRepTable(props: HuquqRepTableProps) {
  const { columns, filteredData, defaultmessage, pageSize, disableVirtualization, columnBuffer } = props;

  const columnsDataGrid: any[] = columns.columns.map((value) => {
    return {
      headerName: value.title,
      field: value.field,
      width: value.width,
      disableColumnMenu: value.disableColumnMenu,
      filterable: value.filterable,
      sortComparator: value.sortComparator,
      valueGetter: value.valueGetter,
      renderCell: value.render
        ? (rowData: GridRenderCellParams<HuquqResponse, any, any, GridTreeNodeWithRender>) => {
            if (value.render) {
              return value.render(rowData.row, 'row');
            }
            return rowData.row;
          }
        : undefined,
    } as GridColDef<DirectoryData>;
  });
  if (disableVirtualization && columnBuffer) {
    return (
      <div style={{ width: '100%' }}>
        <DataGrid
          rows={filteredData}
          columns={columnsDataGrid as any[]}
          getRowId={(row) => row.name}
          getRowHeight={() => 'auto'}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: pageSize ? pageSize : 10,
              },
            },
          }}
          disableColumnFilter={true}
          columnBuffer={columnBuffer ? columnBuffer : undefined}
          disableVirtualization={disableVirtualization}
          pageSizeOptions={pageSize ? [pageSize] : [10]}
          disableRowSelectionOnClick
        />
        {filteredData.length === 0 && <div>{defaultmessage}</div>}
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        rows={filteredData}
        columns={columnsDataGrid as any[]}
        getRowId={(row) => row.name}
        getRowHeight={() => 'auto'}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: pageSize ? pageSize : 10,
            },
          },
        }}
        disableColumnFilter={true}
        pageSizeOptions={pageSize ? [pageSize] : [10]}
        disableRowSelectionOnClick
      />
      {filteredData.length === 0 && <div>{defaultmessage}</div>}
    </div>
  );
}
