import IToken from '../../Login/IToken';
import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';
import { ReplaySubject, Observable } from 'rxjs';
import { map, switchMap, filter, tap } from 'rxjs/operators';
import MyProfileProxy from './MyProfileProxy';
import type IPhysicalAddressProxy from './IPhysicalAddressProxy';
import type PhysicalAddressModelGetResponse from './PhysicalAddressModelGetResponse';
import type PhysicalAddressChangeRequestModel from './PhysicalAddressChangeRequestModel';
import type PhysicalAddressChangeResponseModel from './PhysicalAddressChangeResponseModel';
import type PhysicalAddressChangeResponseModelWithStatus from './PhysicalAddressChangeResponseModelWithStatus';
import { ChangeResult } from './PhysicalAddressChangeResponseModelWithStatus';

export default class PhysicalAddressProxy implements IPhysicalAddressProxy {
  private physicalAddressDataSubject = new ReplaySubject<PhysicalAddressModelGetResponse>(1);
  private authService: IToken;
  private myProfileProxy: MyProfileProxy;

  constructor(auth: IToken, myprofileproxy: MyProfileProxy) {
    this.authService = auth;
    this.myProfileProxy = myprofileproxy;
    this.refresh();
  }

  // Returns an observable that will continuously report the latest profile data
  get() {
    return this.physicalAddressDataSubject.asObservable();
  }

  refresh() {
    this.authService
      .getTokenObservable()
      .pipe(filter((token) => !!token))
      .pipe(
        switchMap((token) =>
          GlobalAPICall.getObservable(process.env.REACT_APP_API_URL + `/api/physicaladdress`, {
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          })
        )
      )
      .pipe(map((x) => x.data))
      .subscribe(this.physicalAddressDataSubject);
  }

  // Returns a single response observable but will also update the continuous observable from the get() method
  set(data: PhysicalAddressChangeRequestModel): Observable<PhysicalAddressChangeResponseModelWithStatus> {
    return this.authService
      .getTokenObservable()
      .pipe(filter((token) => !!token))
      .pipe(
        switchMap((token) =>
          GlobalAPICall.postObservable(process.env.REACT_APP_API_URL + `/api/physicaladdress`, data, {
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          })
        )
      )
      .pipe(
        map((x) => {
          const responseModel: PhysicalAddressChangeResponseModel = x.data;

          const changeResult: ChangeResult =
            x.status === 200
              ? ChangeResult.OnlyValidated
              : x.status === 201
              ? ChangeResult.AddressChangeCompleted
              : x.status === 202
              ? ChangeResult.AddressChangePending
              : ChangeResult.Failed;

          const responseModelWithStatus: PhysicalAddressChangeResponseModelWithStatus = { ...responseModel, changeResult };
          return responseModelWithStatus;
        })
      )
      .pipe(
        tap((m) => {
          if (m.changeResult === ChangeResult.AddressChangeCompleted) {
            this.myProfileProxy.refresh();
            this.refresh();
          }
        })
      );
  }
}
