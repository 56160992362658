import React from 'react';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, TextField } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
export enum FieldFormFilterType {
  text = 'text',
  select = 'select',
}
export type FilterOptionsObjectType = {
  label: string;
  value: string;
};
export type FieldFormFilter = {
  filterFieldName: string;
  filterLabel: string;
  type: FieldFormFilterType;
  select?: {
    filterOptions: string[] | FilterOptionsObjectType[];
    notMultiple?: boolean;
  };
};
export type FilterFormParameter = { [key in string]: string | string[] };
export type FiltersMobileFriendlyProps = {
  fields: FieldFormFilter[];
  filterCallback: (formData: FilterFormParameter) => void;
};

export default function (props: FiltersMobileFriendlyProps): [React.JSX.Element] {
  const { fields, filterCallback } = props;
  const { handleSubmit, control, reset } = useForm();
  const filterComponent = (
    <>
      <div style={{ margin: '15px' }}>
        <h4 style={{ minWidth: '300px' }}>Filters</h4>

        <form onSubmit={handleSubmit(filterCallback)} className="max-width-filters-table">
          <div className="row">
            {fields.map((fieldForm) => {
              if (fieldForm.type === FieldFormFilterType.select) {
                return (
                  <div key={fieldForm.filterFieldName} className="col-xl-4">
                    <div className="spaced-bottom">
                      {
                        <Controller
                          name={fieldForm.filterFieldName}
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => (
                            <FormControl className="w-100" variant="outlined">
                              <InputLabel id={fieldForm.filterFieldName}>{fieldForm.filterLabel}</InputLabel>
                              {fieldForm.select?.notMultiple && (
                                <Select
                                  {...field}
                                  inputProps={{ 'data-cy': fieldForm.filterFieldName }}
                                  labelId={fieldForm.filterFieldName}
                                  label={fieldForm.filterFieldName}
                                >
                                  {fieldForm.select &&
                                    fieldForm.select.filterOptions.map((filterNameType) => {
                                      const filterNameObject = filterNameType as FilterOptionsObjectType;
                                      const filterName = filterNameType as string;
                                      if (filterNameObject.value) {
                                        return (
                                          <MenuItem
                                            value={filterNameObject.value}
                                            key={fieldForm.filterFieldName + filterNameObject.value}
                                          >
                                            <Checkbox checked={field.value.indexOf(filterNameObject.value) > -1} />
                                            <ListItemText primary={filterNameObject.label} />
                                          </MenuItem>
                                        );
                                      }
                                      return (
                                        <MenuItem value={filterName} key={fieldForm.filterFieldName + filterName}>
                                          <Checkbox checked={field.value.indexOf(filterName) > -1} />
                                          <ListItemText primary={filterName} />
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              )}
                              {!fieldForm.select?.notMultiple && (
                                <Select
                                  {...field}
                                  labelId={fieldForm.filterFieldName}
                                  label={fieldForm.filterFieldName}
                                  multiple
                                  defaultValue={[]}
                                  inputProps={{ 'data-cy': fieldForm.filterFieldName }}
                                  renderValue={(selected) => (selected as any).join(', ')}
                                >
                                  {fieldForm.select &&
                                    fieldForm.select.filterOptions.map((filterNameType) => {
                                      const filterNameObject = filterNameType as FilterOptionsObjectType;
                                      const filterName = filterNameType as string;
                                      if (filterNameObject.value) {
                                        return (
                                          <MenuItem
                                            value={filterNameObject.value}
                                            key={fieldForm.filterFieldName + filterNameObject.value}
                                          >
                                            <Checkbox checked={field.value.indexOf(filterNameObject.value) > -1} />
                                            <ListItemText primary={filterNameObject.label} />
                                          </MenuItem>
                                        );
                                      }
                                      return (
                                        <MenuItem value={filterName} key={fieldForm.filterFieldName + filterName}>
                                          <Checkbox checked={field.value.indexOf(filterName) > -1} />
                                          <ListItemText primary={filterName} />
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              )}
                            </FormControl>
                          )}
                        />
                      }
                    </div>
                  </div>
                );
              }
              if (fieldForm.type === FieldFormFilterType.text) {
                return (
                  <div key={fieldForm.filterFieldName} className="col-xl-4">
                    <div className="spaced-bottom">
                      {
                        <Controller
                          name={fieldForm.filterFieldName}
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => (
                            <FormControl className="w-100">
                              <TextField
                                {...field}
                                label={fieldForm.filterLabel}
                                inputProps={{ 'data-cy': fieldForm.filterFieldName }}
                                type="text"
                                aria-label={fieldForm.filterLabel}
                                fullWidth={true}
                                variant="outlined"
                              />
                            </FormControl>
                          )}
                        />
                      }
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
          <div className="row">
            <div className="col-xl-6">
              <div className="spaced">
                <button type="submit" className="primary-button w-100">
                  {' '}
                  Apply Filters{' '}
                </button>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="spaced">
                <button
                  type="reset"
                  className="secondary-button w-100"
                  onClick={(e) => {
                    e.preventDefault();
                    reset();
                    filterCallback({});
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
  return [filterComponent];
}
