import type { NatconArticle } from '../../Kentico/DigitalDesign/natcon_article';

export const byPinnedThenByDatePublished = (a: NatconArticle, b: NatconArticle) => {
  const aPinned = a.pinnedArticle.value.filter((y) => y.codename === 'pin_to_top').length > 0;
  const bPinned = b.pinnedArticle.value.filter((y) => y.codename === 'pin_to_top').length > 0;

  if (aPinned && !bPinned) {
    return -1;
  }
  if (!aPinned && bPinned) {
    return 1;
  }

  if (a.baseDatepublished.value === null) {
    return 0;
  }

  if (b.baseDatepublished.value === null) {
    return 0;
  }

  const aDateValueToCompare = a.baseDatepublished.value.getTime();
  const bDateValueToCompare = b.baseDatepublished.value.getTime();
  const dateCompareResult = bDateValueToCompare - aDateValueToCompare;
  return dateCompareResult;
};
