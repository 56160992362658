import PhysicalAddressChangeResponseModel from './PhysicalAddressChangeResponseModel';

export enum ChangeResult {
  OnlyValidated,
  AddressChangeCompleted,
  AddressChangePending,
  Failed,
}

export default interface PhysicalAddressChangeResponseModelWithStatus extends PhysicalAddressChangeResponseModel {
  changeResult: ChangeResult;
}
