/**
 * Validates email address
 */
export const emailFormat = /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z0-9](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+$/;

/**
 * Validates phone number
 *
 * Note: The website https://rubular.com/ was helpful in developing this regex
 */
export const phoneFormat = /(^\d{10}$)|(^\(\d{3}\) ?\d{3}[ -]?\d{4}$)|(^\d{3}[ -]?\d{3}[ -]?\d{4}$)|(^\+\d{1,3} [ -\\d]+$)/;

/**
 * Validates zip code
 */
export const zipcodeFormat = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

/**
 * UPPER case letters
 *
 * Note: Fancy regex documented at https://javascript.info/regexp-unicode
 */
export const upperCaseLetters = /\p{Lu}/u;

/**
 * Lower case letters
 *
 * Note: Fancy regex documented at https://javascript.info/regexp-unicode
 */
export const lowerCaseLetters = /\p{Ll}/u;

/**
 * NUMB3R5
 */
export const numbers = /\d/;

/**
 * Special characters allowed for password
 */
export const passwordAllowedSpecialCharacters = /[`~!@$%^*()\-_=+[{\]}\\|;:'",.>/?]/;

/**
 * Special characters NOT allowed for password
 */
export const passwordNotAllowedSpecialCharacters = /[#&<\s]/;

/**
 * Validates names to be one or multiple words without spaces on start or end of the string
 */
export const name = /^[A-zÀ-ú,.'-]+( [A-zÀ-ú,.'-]+)*$/i;
