import React from 'react';
import { NavLink } from 'react-router-dom';

const FaqMainList = (props) => {
  return (
    <div data-cy="faqlinkdisplay" data-testid="faqlinkdisplay">
      <h2 className="capitalize">{props.faqtitle}</h2>
      <ul className="plain-list">
        {props.faqdata.map((faq, key) => {
          return (
            <li key={key}>
              <NavLink data-cy={`faqlink${key}`} to={{ pathname: `/faq/${props.faqtype}/${faq.url_slug.value}` }}>
                {faq.base__title.value}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FaqMainList;
