import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';

export default function submitCluster(token: string, Id: string, Zipcode: string, Clustercode: string) {
  return GlobalAPICall.post(
    process.env.REACT_APP_AUTH_API_URL + '/api/registration/step2',
    {
      //('https://auth-test.usbnc.org/api/registration/step2', {
      UserName: Id,
      ZipCode: Zipcode,
      ClusterCode: Clustercode,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    }
  )
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      if (err.response.status === 400) {
        return Promise.reject({
          status: err.response.status,
          message: err.response.data.message,
        });
      } else {
        return Promise.reject({
          status: err.response.status,
          message: 'Our services are currently unavailable. Please come back later.',
        });
      }
    });
}
