import axios, { CancelToken } from 'axios';
import axiosObservable from 'axios-observable';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as Sentry from '@sentry/browser';

export default {
  async fetch(requestInfo, requestInit) {
    try {
      const response = await window.fetch(requestInfo, requestInit);
      if (response.status >= 400) {
        throw new Error(`Failed to call ${requestInfo}. Status code ${response.status}.`);
      } else {
        return response;
      }
    } catch (err) {
      Sentry.captureMessage(err);
      throw err;
    }
  },
  getObservable(url, headers) {
    return axiosObservable.get(url, headers).pipe(
      catchError((err) => {
        Sentry.captureMessage(err);
        return throwError(() => err);
      })
    );
  },
  postObservable(url, data, config) {
    return axiosObservable.post(url, data, config).pipe(
      catchError((err) => {
        Sentry.captureMessage(err);
        return throwError(() => err);
      })
    );
  },
  putObservable(url, data, config) {
    return axiosObservable.put(url, data, config).pipe(
      catchError((err) => {
        Sentry.captureMessage(err);
        return throwError(() => err);
      })
    );
  },
  deleteObservable(url, config) {
    return axiosObservable.delete(url, config).pipe(
      catchError((err) => {
        Sentry.captureMessage(err);
        return throwError(() => err);
      })
    );
  },

  get(url, header, setCanceller) {
    const options = { ...header };
    if (typeof setCanceller === 'function') {
      options.cancelToken = new CancelToken((c) => setCanceller(c));
    }
    const req = axios
      .get(url, options)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        Sentry.captureMessage(err);
        throw err;
      });
    return req;
  },

  put(url, options, header) {
    const req = axios
      .put(url, options, header)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        Sentry.captureMessage(err);
        throw err;
      });
    return req;
  },

  post(url, data, header, setCanceller) {
    const options = header ? { ...header } : {};
    if (typeof setCanceller === 'function') {
      options.cancelToken = new CancelToken((c) => setCanceller(c));
    }
    const req = axios
      .post(url, data, options)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        Sentry.captureMessage(err);
        throw err;
      });
    return req;
  },

  delete(url, options, header) {
    const req = axios
      .delete(url, options, header)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        Sentry.captureMessage(err);
        throw err;
      });
    return req;
  },

  isCancel(e) {
    return axios.isCancel(e);
  },
};
