import React, { useState, useEffect } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';
import getClusterDirectoryByClusterCode from './Utilities/DataClusterDirectory';
import { ClusterContactGroup } from './interfaces/ClusterContactGroup';
import { ClusterContact } from './interfaces/ClusterContact';
import DisplayNameEmailPhone from './Utilities/DisplayNameEmailPhone';

export interface ClusterInformationProps {
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  blc: string;
  token: string;
}

const ClusterInformation = (props: ClusterInformationProps) => {
  const blc = props.blc;
  const token = props.token;

  const predefinedRoles = [
    'Area Teaching Committee Member',
    'Area Teaching Committee Secretary',
    'Children’s Class Coordinator',
    'Cluster Institute Coordinator',
    'Cluster Seeker Response Coordinator',
    'Cluster Statistics Officer',
    'Junior Youth Coordinator',
    'Study Circle Coordinator',
  ];

  const regionalStatOfficerRole = 'Regional Statistics Officer';

  const [errorMessage, setErrorMessage] = useState(<></>);
  const [abmContactData, setAbmContactData] = useState<ClusterContact[]>([]);
  const [clusterContactByAreaData, setClusterContactByArea] = useState<ClusterContactGroup[]>([]);
  const defaultmessage = 'Currently loading data. Please wait several seconds!';

  const setPageTitle = props.setPageTitle;
  useEffect(() => setPageTitle('Cluster Information'), [setPageTitle]);
  useEffect(() => {
    const observable = getClusterDirectoryByClusterCode(token, blc);
    const subscription = observable.subscribe({
      next: (res) => {
        setAbmContactData(res);
        setClusterContactByArea(filterArrayByClusterContactAreaName(res));
      },
      error: () => setErrorMessage(<ErrorMessageToUser />),
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [token, blc, setErrorMessage]);

  const filterArrayByClusterContactAreaName = (clusterContactArray: ClusterContact[]) => {
    let objectFilteredAreas: { [key in string]: ClusterContactGroup } = {};

    for (let clusterContact of clusterContactArray) {
      const areaName = clusterContact.areaName.replace(/ /g, '').toLowerCase();
      if (!objectFilteredAreas[areaName]) {
        objectFilteredAreas[areaName] = { items: [], name: '' };
      }
      if (!objectFilteredAreas[areaName].items) {
        objectFilteredAreas[areaName].items = [];
      }
      objectFilteredAreas[areaName].items.push(clusterContact);
      objectFilteredAreas[areaName].name = clusterContact.areaName;
    }
    return Object.keys(objectFilteredAreas)
      .map((objectKey) => objectFilteredAreas[objectKey] as ClusterContactGroup)
      .sort((a, b) => (a?.items?.find((x) => x?.role == regionalStatOfficerRole) || a.name > b.name ? 1 : -1))
      .map((item) => {
        item.items = item.items.sort((a, b) => (a.areaName > b.areaName ? 1 : -1));
        return item;
      });
  };

  const filterArrayByRole = (clusterContactAreaArray: ClusterContactGroup) => {
    let objectFilteredRoles: { [key in string]: ClusterContactGroup } = {};

    //define forced headers
    if (clusterContactAreaArray.items?.length && !clusterContactAreaArray.items.find((x) => x.role == regionalStatOfficerRole)) {
      predefinedRoles.forEach((x) => {
        addRole(x);
      });
      if (!clusterContactByAreaData.find((x) => x.items?.find((y) => y.role == regionalStatOfficerRole))) {
        addRole(regionalStatOfficerRole);
      }
    }

    for (let clusterContactByRole of clusterContactAreaArray.items) {
      const roleName = clusterContactByRole.role.replace(/ /g, '').toLowerCase();
      if (!objectFilteredRoles[roleName]) {
        addRole(roleName);
      }
      if (!objectFilteredRoles[roleName].items) {
        objectFilteredRoles[roleName].items = [];
      }
      objectFilteredRoles[roleName].items.push(clusterContactByRole);
      objectFilteredRoles[roleName].name = clusterContactByRole.role;
    }
    return Object.keys(objectFilteredRoles)
      .sort()
      .map((objectKey) => objectFilteredRoles[objectKey] as ClusterContactGroup)
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((item) => {
        item.items = item.items.sort((a, b) => (a.firstName > b.firstName ? 1 : -1));
        return item;
      });

    function addRole(x: string) {
      objectFilteredRoles[x.replace(/ /g, '').toLowerCase()] = { items: [], name: x };
    }
  };

  return (
    <div className="large-top-padding">
      {errorMessage}
      {abmContactData === undefined ? (
        defaultmessage
      ) : (
        <div>
          {clusterContactByAreaData.map((item, index) => (
            <div key={index}>
              {item && <DisplayNameEmailPhone title={item.name} contacts={filterArrayByRole(item)}></DisplayNameEmailPhone>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default withContext(ClusterInformation);
