import { TypeResolver } from '@kentico/kontent-delivery';
// import { AboutUs } from './DigitalDesign/about_us';
// import { CollectionArticle } from './DigitalDesign/collection_article';
// import { CoverLetter } from './DigitalDesign/cover_letter';
// import { Faq } from './DigitalDesign/faq';
// import { FeastMessage } from './DigitalDesign/feast_message';
// import { GlsaDoc } from './DigitalDesign/glsa_doc';
import { HomeCarousel } from './DigitalDesign/home_carousel';
import { Homeblock } from './DigitalDesign/homeblock';
// import { Huquqpage } from './DigitalDesign/huquqpage';
// import { Iconcap } from './DigitalDesign/iconcap';
// import { ImageAndCaption } from './DigitalDesign/image_and_caption';
// import { Letter } from './DigitalDesign/letter';
// import { Myprofilemessages } from './DigitalDesign/myprofilemessages';
import { NatconArticle } from './DigitalDesign/natcon_article';
import { NatconCarousel } from './DigitalDesign/natcon_carousel';
// import { NewsArticle } from './DigitalDesign/news_article';
// import { Resources } from './DigitalDesign/resources';
// import { ServiceOpportunities } from './DigitalDesign/service_opportunities';
// import { Thermometer } from './DigitalDesign/thermometer';
// import { WebsiteUpdateArticle } from './DigitalDesign/website_update_article';
// import { Welcome } from './DigitalDesign/welcome';
// import { Writing } from './DigitalDesign/writing';

export default [
  // new TypeResolver('about_us', () => new AboutUs()),
  // new TypeResolver('collection_article', () => new CollectionArticle()),
  // new TypeResolver('cover_letter', () => new CoverLetter()),
  // new TypeResolver('faq', () => new Faq()),
  // new TypeResolver('feast_message', () => new FeastMessage()),
  // new TypeResolver('glsa_doc', () => new GlsaDoc()),
  new TypeResolver('home_carousel', () => new HomeCarousel()),
  new TypeResolver('homeblock', () => new Homeblock()),
  // new TypeResolver('huquqpage', () => new Huquqpage()),
  // new TypeResolver('iconcap', () => new Iconcap()),
  // new TypeResolver('image_and_caption', () => new ImageAndCaption()),
  // new TypeResolver('letter', () => new Letter()),
  // new TypeResolver('myprofilemessages', () => new Myprofilemessages()),
  new TypeResolver('natcon_article', () => new NatconArticle()),
  new TypeResolver('natcon_carousel', () => new NatconCarousel()),
  // new TypeResolver('news_article', () => new NewsArticle()),
  // new TypeResolver('resources', () => new Resources()),
  // new TypeResolver('service_opportunities', () => new ServiceOpportunities()),
  // new TypeResolver('thermometer', () => new Thermometer()),
  // new TypeResolver('website_update_article', () => new WebsiteUpdateArticle()),
  // new TypeResolver('welcome', () => new Welcome()),
  // new TypeResolver('writing', () => new Writing()),
];
