import React from 'react';
import { Row, Col } from 'react-flexbox-grid';

const InternationalResidentialAddress = ({ details }) => {
  const countryDisplay = !details.blc;

  return (
    <div>
      <Row className="small-bottom-padding">
        <Col sm={12}>
          <p className="faded-text small-text">In Care Of</p>
          <p>
            <strong>{details.physicalAddressCareOf}</strong>
          </p>
        </Col>
      </Row>

      {details.physicalAddressLine1 ? (
        <Row className="small-bottom-padding">
          <Col sm={12}>
            <p>
              <strong>{details.physicalAddressLine1}</strong>
            </p>
          </Col>
        </Row>
      ) : null}

      {details.physicalAddressLine2 ? (
        <Row className="small-bottom-padding">
          <Col sm={12}>
            <p>
              <strong>{details.physicalAddressLine2}</strong>
            </p>
          </Col>
        </Row>
      ) : null}

      {details.physicalAddressLine3 ? (
        <Row className="small-bottom-padding">
          <Col sm={12}>
            <p>
              <strong>{details.physicalAddressLine3}</strong>
            </p>
          </Col>
        </Row>
      ) : null}

      {details.physicalAddressLine4 ? (
        <Row className="small-bottom-padding">
          <Col sm={12}>
            <p>
              <strong>{details.physicalAddressLine4}</strong>
            </p>
          </Col>
        </Row>
      ) : null}

      {details.physicalAddressLine5 ? (
        <Row className="small-bottom-padding">
          <Col sm={12}>
            <p>
              <strong>{details.physicalAddressLine5}</strong>
            </p>
          </Col>
        </Row>
      ) : null}
      {details.physicalAddressCountry && countryDisplay ? (
        <Row>
          <Col sm={12}>
            <p>
              <strong>{details.physicalAddressCountry}</strong>
            </p>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};
export default InternationalResidentialAddress;
