import React, { useCallback, useEffect, useState } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import { emailFormat } from '../../src/Utilities/GeneralRegEx';
import useForm from '../Utilities/useForm';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';
import { CircularProgress, TextField } from '@material-ui/core';

const EmailSettings = (props) => {
  const validationSchema = {
    securityEmail: {
      required: true,
      validator: {
        regEx: emailFormat,
        error: 'Invalid email format.',
      },
    },
    primaryEmail: {
      required: false,
      validator: {
        regEx: emailFormat,
        error: 'Invalid email format.',
      },
    },
    alternateEmail: {
      required: false,
      validator: {
        regEx: emailFormat,
        error: 'Invalid email format.',
      },
    },
  };

  const initialValue = {
    securityEmail: { value: '', error: '' },
    primaryEmail: { value: '', error: '' },
    alternateEmail: { value: '', error: '' },
  };
  const [messageToUser, setMessageToUser] = useState(null);

  const [showCircularProgress, setShowCircularProgress] = useState(true);
  const [unrecoverableError, setUnrecoverableError] = useState(false);
  const [staticState, setStaticState] = useState({
    primaryEmailPending: undefined,
    alternateEmailPending: undefined,
    securityEmailPending: undefined,
  });

  const setPageTitle = props.setPageTitle;
  useEffect(() => setPageTitle('Change Email Settings'), [setPageTitle]);
  const emailProxy = props.context.emailProxy;
  const EmailCallback = useCallback(
    (state) => {
      setShowCircularProgress(true);
      setMessageToUser(null);

      const subscription = emailProxy
        .set(state.securityEmail.value, state.primaryEmail.value, state.alternateEmail.value)
        .subscribe({
          next: (res) => {
            setStaticState({
              primaryEmailPending: res.data.primaryEmailPending,
              alternateEmailPending: res.data.alternateEmailPending,
              securityEmailPending: res.data.securityEmailPending,
            });

            setMessageToUser(
              <p className="success-message wider" data-cy="success-message">
                {res.data.message}
              </p>
            );
            setShowCircularProgress(false);
          },
          error: (e) => {
            if (e?.response?.status === 400 && e?.response?.data) {
              const message = e.response.data.message ?? JSON.stringify(e.response?.data);
              setMessageToUser(
                <p className="error-message wider" data-cy="unsuccess-message">
                  {message}
                </p>
              );
              setShowCircularProgress(false);
            } else {
              setUnrecoverableError(true);
            }
          },
        });

      return () => subscription.unsubscribe();
    },
    [emailProxy, setMessageToUser, setUnrecoverableError]
  );

  const { setState, state, handleValidationOnChange, handleOnSubmit, isSubmitCalled, disable } = useForm(
    initialValue,
    validationSchema,
    EmailCallback
  );

  useEffect(() => {
    const subscription = emailProxy.get().subscribe({
      next: (x) => {
        setState({
          securityEmail: { value: x.data.securityEmail, error: '' },
          primaryEmail: { value: x.data.primaryEmail, error: '' },
          alternateEmail: { value: x.data.alternateEmail, error: '' },
        });
        setStaticState({
          primaryEmailPending: x.data.primaryEmailPending,
          alternateEmailPending: x.data.alternateEmailPending,
          securityEmailPending: x.data.securityEmailPending,
        });
        setShowCircularProgress(false);
      },
      error: () => setUnrecoverableError(true),
    });

    return () => subscription.unsubscribe();
  }, [emailProxy, setState, setUnrecoverableError]);

  const securityEmailError = state.securityEmail.error;
  const primaryEmailError = state.primaryEmail.error;
  const alternateEmailError = state.alternateEmail.error;
  useEffect(() => {
    if ((!isSubmitCalled && disable) || isSubmitCalled === false) {
      setMessageToUser(
        <p data-cy="unsuccess-message" className="error-message wider">
          Please check the highlighted field(s) and resolve the error(s).
        </p>
      );
    }
  }, [isSubmitCalled, disable, setMessageToUser, securityEmailError, primaryEmailError, alternateEmailError]);

  return (
    <div>
      {unrecoverableError ? (
        <ErrorMessageToUser />
      ) : (
        <span>
          {showCircularProgress ? (
            <CircularProgress data-cy="security-questions-circular-progress" className="loading-animation" />
          ) : (
            messageToUser
          )}
          <div className="profile-box">
            <p>
              <strong>Security Email</strong> is for receiving emails that are part of the password recovery process if you have
              forgotten your password. This can be the same as your primary email address.
            </p>
            {
              <form autoComplete="off">
                <div className="small-top-margin wide-form">
                  <TextField
                    id="securityEmail"
                    name="securityEmail"
                    placeholder="Security Email"
                    label="Security Email"
                    aria-label="Security Email"
                    type="text"
                    variant="outlined"
                    required
                    inputProps={{ 'data-cy': 'securityEmail' }}
                    value={state.securityEmail.value}
                    fullWidth={true}
                    onChange={handleValidationOnChange}
                    error={!!state.securityEmail.error}
                  />
                  {state.securityEmail?.error && (
                    <p className="form-field-error" data-cy="securityEmail_Required">
                      {state.securityEmail.error}
                    </p>
                  )}
                  {staticState.securityEmailPending && (
                    <p className="small-text attention very-small-top-padding">
                      The following email address is waiting for you to confirm:{' '}
                      <strong>{staticState.securityEmailPending}.</strong>
                    </p>
                  )}
                </div>
              </form>
            }
            <span>
              <p className="large-top-padding">
                <strong>Primary Email</strong> is used for correspondence from Bahá’í agencies.
              </p>
              <form autoComplete="off">
                <div className="small-top-margin wide-form">
                  <TextField
                    id="primaryEmail"
                    name="primaryEmail"
                    placeholder="Primary Email"
                    label="Primary Email"
                    aria-label="Primary Email"
                    type="text"
                    autoFocus={true}
                    variant="outlined"
                    inputProps={{ 'data-cy': 'primaryEmail' }}
                    value={state.primaryEmail?.value ?? ''}
                    fullWidth={true}
                    onChange={handleValidationOnChange}
                    error={!!state.primaryEmail?.error}
                  />
                  {state.primaryEmail?.error && (
                    <p className="form-field-error" data-cy="primaryEmail_Required">
                      {state.primaryEmail?.error}
                    </p>
                  )}
                  {staticState.primaryEmailPending && (
                    <p className="small-text attention very-small-top-padding">
                      The following email address is waiting for you to confirm:{' '}
                      <strong>{staticState.primaryEmailPending}.</strong>
                    </p>
                  )}
                </div>
              </form>
              <p className="large-top-padding">
                <strong>Alternate Email</strong> is used in the event the Primary email is no longer valid.
              </p>
              <form autoComplete="off">
                <div className="small-top-margin wide-form">
                  <TextField
                    id="alternateEmail"
                    name="alternateEmail"
                    placeholder="Alternate Email"
                    label="Alternate Email"
                    aria-label="Alternate Email"
                    type="text"
                    autoFocus={true}
                    variant="outlined"
                    inputProps={{ 'data-cy': 'alternateEmail' }}
                    value={state.alternateEmail?.value ?? ''}
                    fullWidth={true}
                    onChange={handleValidationOnChange}
                    error={!!state.alternateEmail?.error}
                  />
                  {state.alternateEmail?.error && (
                    <p className="form-field-error" data-cy="alternateEmail_Required">
                      {state.alternateEmail?.error}
                    </p>
                  )}
                  {staticState.alternateEmailPending && (
                    <p className="small-text attention very-small-top-padding">
                      The following email address is waiting for you to confirm:{' '}
                      <strong>{staticState.alternateEmailPending}</strong>.
                    </p>
                  )}
                </div>
              </form>
            </span>
          </div>
          <div className="medium-top-margin end">
            <input type="submit" value="Save" className="primary-button" data-cy="inputsubmit" onClick={handleOnSubmit} />
          </div>
        </span>
      )}
    </div>
  );
};

export default withContext(EmailSettings);
