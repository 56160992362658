import ContentProviderEndpoint from '../../Content/ContentProviderEndpoint';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Homeblock } from '../../Kentico/DigitalDesign/homeblock';
import { ByRowThenColumnThenMoveToPreviousDate, ToOrganizedBlocks } from './HomeBlockUtilities';
import { ItemResponses } from '@kentico/kontent-delivery';

export default class HomeBlockProxy {
  digitalDesignContent: ContentProviderEndpoint;
  homeBlocksSubject: ReplaySubject<Homeblock[]>;
  getHomeBlocksWasCalled: boolean;
  homeCarouselsSubject: ReplaySubject<Homeblock[]>;
  getHomeCarouselsWasCalled: boolean;

  constructor(digitalDesignContent: ContentProviderEndpoint) {
    this.digitalDesignContent = digitalDesignContent;
    this.homeBlocksSubject = new ReplaySubject<Homeblock[]>(1);
    this.getHomeBlocksWasCalled = false;
    this.homeCarouselsSubject = new ReplaySubject<Homeblock[]>(1);
    this.getHomeCarouselsWasCalled = false;
  }

  getHomeBlocks(): Observable<Homeblock[]> {
    if (!this.getHomeBlocksWasCalled) {
      const dateFormatted = new Date().toISOString().substring(0, 10);
      this.getHomeBlocksWasCalled = true;
      this.digitalDesignContent
        .items<Homeblock>()
        .type('homeblock')
        .greaterThanFilter('elements.move_to_previous_announcements_on', dateFormatted)
        .orderByAscending('elements.row')
        .limitParameter(18)
        .toObservable()
        .pipe(map((x) => x.items))
        .subscribe(this.homeBlocksSubject);
    }
    return this.homeBlocksSubject.asObservable();
  }

  getPreviousBlocks(itemsPerPage: number, page: number): Observable<ItemResponses.ListContentItemsResponse<Homeblock>> {
    const itemsToSkip = (page - 1) * itemsPerPage;
    return this.getHomeBlocks()
      .pipe(
        map((x) => {
          const orderByRowThenColumnThenMoveToPreviousDate = x.sort(ByRowThenColumnThenMoveToPreviousDate);
          const organizedBlocks = orderByRowThenColumnThenMoveToPreviousDate.reduce(ToOrganizedBlocks, []);
          const organizedBlocksCodeNames = organizedBlocks.reduce((accumulator, block) => {
            accumulator.push(block[0].system.codename);
            if (block.length === 2) {
              accumulator.push(block[1].system.codename);
            }
            return accumulator;
          }, [] as string[]);
          return organizedBlocksCodeNames;
        })
      )
      .pipe(
        switchMap((homeBlockCodeNames) => {
          return this.digitalDesignContent
            .items<Homeblock>()
            .type('homeblock')
            .notInFilter('system.codename', homeBlockCodeNames)
            .orderByAscending('elements.move_to_previous_announcements_on')
            .limitParameter(itemsPerPage)
            .skipParameter(itemsToSkip)
            .toObservable();
        })
      );
  }
}
