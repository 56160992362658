import React, { useState, useEffect } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import { Column } from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import Expansion from './Utilities/TableFunctions/Expansion';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import ShowCluster from './ShowCluster';
import { Drawer } from '@material-ui/core';
import useFilterComponent, { FieldFormFilter, FieldFormFilterType, FilterFormParameter } from './useFilterComponent';
import { History, Location } from 'history';
import { match, StaticContext } from 'react-router';
import AbmDirectoryTable from './AbmDirectoryTable';
import AbmDirectoryMobileTable from './AbmDirectoryMobileTable';
import { GridComparatorFn } from '@mui/x-data-grid/models/gridSortModel';
import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { Col, Row } from 'react-flexbox-grid';

export type DirectoryRawData = {
  regionName: string;
  roleName?: string | null;
  state?: string | null;
  roleShortName?: string | null;
  areaId: number;
  areaAbmName: string;
  firstName: string;
  lastName: string;
  middleName?: string | null;
  title: string;
  suffix?: string | null;
  homePhone?: string | null;
  cellPhone?: string | null;
  contactPhone?: string | null;
  email: string;
  areaStates: string;
  areaStateList: string[];
  areaClusters: {
    key: string;
    value: string;
  }[];
  fullName?: string | null;
  tableData: {
    id: number;
  };
};
export type DirectoryData = {
  regionName?: string | null;
  roleShortName?: string | null;
  areaStateList: string[];
  areaClusters: {
    key: string;
    value: string;
  }[];
  fullName?: string | null;
  contactPhone?: string | null;
  email?: string | null;
  areaId: number;
};
type FilterResponse = {
  fullName: string | string[];
  areaStateList: string[];
  regionName: string[];
  roleShortName: string[];
  areaClusters: string | string[];
};
type ABMDirectoryProps = {
  context: any;
  location: Location;
  history: History;
  match: match;
  staticContext: StaticContext;
};
const AbmDirectory = (props: ABMDirectoryProps) => {
  const token = sessionStorage.getItem('id_token');
  const [filtersOpened, setFiltersOpened] = useState(false);
  const [filterFieldsArray, setFilterFieldsArray] = useState<FieldFormFilter[]>([]);
  const filterCallback = (filterValues: FilterFormParameter) => {
    setFilteredData(filterDataWithValues(filterValues as FilterResponse));
    setFiltersOpened(false);
  };
  const [filterComponent] = useFilterComponent({ fields: filterFieldsArray, filterCallback: filterCallback });
  const [defaultmessage, setDefaultmessage] = useState(<p>Currently loading data. Please wait several seconds!</p>);
  const [filteredDataCache, setFilteredDataCache] = useState<DirectoryData[]>([]);
  const [filteredData, setFilteredData] = useState<DirectoryData[]>([]);
  const [columns, setColumns] = useState<{
    columns: (Column<any> & {
      disableColumnMenu?: boolean;
      filterable?: boolean;
      sortComparator?: GridComparatorFn<DirectoryData | string>;
      valueGetter?: (params: GridValueGetterParams<DirectoryData>) => string | number | boolean;
    })[];
  }>({
    columns: [],
  });

  makeStyles({
    '@global tbody tr:nth-child(odd)': {
      background: '#cccccc',
    },
    '@global tbody tr:nth-child(even)': {
      background: 'white',
    },
  })();

  const createFilterData = (dataUpToDate: DirectoryData[]) => {
    const { roleShortNameSet, regionNameSet, areaStateSet } = createFilters(dataUpToDate);
    const createOrUpdateRoleShortNameFilter = (filterArray: FieldFormFilter[], roleShortNameSet: Set<string>) => {
      const index = filterArray.findIndex((a) => a.filterFieldName === 'roleShortName');
      const actualValues: FieldFormFilter = {
        filterFieldName: 'roleShortName',
        filterLabel: 'ABM Role',
        type: FieldFormFilterType.select,
        select: {
          filterOptions: Array.from(roleShortNameSet).sort(),
        },
      };
      if (index < 0) {
        filterArray.push(actualValues);
      } else {
        filterArray[index] = actualValues;
      }
      return filterArray;
    };
    const createOrUpdateRegionNameFilter = (filterArray: FieldFormFilter[], regionNameSet: Set<string>) => {
      const index = filterArray.findIndex((a) => a.filterFieldName === 'regionName');
      const actualValues: FieldFormFilter = {
        filterFieldName: 'regionName',
        filterLabel: 'Region(s)',
        type: FieldFormFilterType.select,
        select: {
          filterOptions: Array.from(regionNameSet).sort(),
        },
      };
      if (index < 0) {
        filterArray.push(actualValues);
      } else {
        filterArray[index] = actualValues;
      }
      return filterArray;
    };
    const createOrUpdateAreaStateFilter = (filterArray: FieldFormFilter[], areaStateSet: Set<string>) => {
      const index = filterArray.findIndex((a) => a.filterFieldName === 'areaStateList');
      const actualValues: FieldFormFilter = {
        filterFieldName: 'areaStateList',
        filterLabel: 'State',
        type: FieldFormFilterType.select,
        select: {
          filterOptions: Array.from(areaStateSet).sort(),
        },
      };
      if (index < 0) {
        filterArray.push(actualValues);
      } else {
        filterArray[index] = actualValues;
      }
      return filterArray;
    };
    const createOrUpdateFullNameFilter = (filterArray: FieldFormFilter[]) => {
      const index = filterArray.findIndex((a) => a.filterFieldName === 'fullName');
      const actualValues: FieldFormFilter = {
        filterFieldName: 'fullName',
        filterLabel: 'Name & Contact Info',
        type: FieldFormFilterType.text,
      };
      if (index < 0) {
        filterArray.push(actualValues);
      } else {
        filterArray[index] = actualValues;
      }
      return filterArray;
    };
    const createOrUpdateAreaClustersFilter = (filterArray: FieldFormFilter[]) => {
      const index = filterArray.findIndex((a) => a.filterFieldName === 'areaClusters');
      const actualValues: FieldFormFilter = {
        filterFieldName: 'areaClusters',
        filterLabel: 'Cluster',
        type: FieldFormFilterType.text,
      };
      if (index < 0) {
        filterArray.push(actualValues);
      } else {
        filterArray[index] = actualValues;
      }
      return filterArray;
    };

    setFilterFieldsArray((filterArray) => {
      filterArray = createOrUpdateFullNameFilter(filterArray);
      filterArray = createOrUpdateRoleShortNameFilter(filterArray, roleShortNameSet);
      filterArray = createOrUpdateAreaStateFilter(filterArray, areaStateSet);
      filterArray = createOrUpdateRegionNameFilter(filterArray, regionNameSet);
      filterArray = createOrUpdateAreaClustersFilter(filterArray);
      return filterArray;
    });
  };
  useEffect(() => {
    const observable = props.context.abmDirectoryEndpoint.getabmdirectory(token);
    const subscription = observable.subscribe({
      next: function (data: DirectoryRawData[]) {
        const sortedData = data
          .map((dataField) => {
            const areaCluster = dataField.areaClusters?.sort((a, b) => a.value.localeCompare(b.value));
            let directoryData: DirectoryData = {
              areaId: dataField.areaId,
              fullName: dataField.fullName,
              regionName: dataField.regionName,
              areaClusters: areaCluster,
              areaStateList: dataField.areaStateList,
              roleShortName: dataField.roleShortName,
              contactPhone: dataField.contactPhone,
              email: dataField.email,
            };
            return directoryData;
          })
          .sort((a, b) => {
            if (a.fullName && b.fullName) {
              return a.fullName > b.fullName ? 1 : -1;
            }
            return 1;
          });
        setFilteredData(JSON.parse(JSON.stringify(sortedData)));
        setFilteredDataCache(JSON.parse(JSON.stringify(sortedData)));
        createFilterData(sortedData);
        setColumns({
          columns: [
            {
              title: 'Name & Address',
              field: 'fullName',
              valueGetter: (params: GridValueGetterParams<DirectoryData, any>) =>
                params.row.fullName ? params.row.fullName : '',
              sortComparator: (v1, v2) => (v1 as string).localeCompare(v2 as string),
              width: 420,
              render: (rowData) => <Expansion rowData={rowData} compName={'AbmDirectory'} />,
            },
            {
              title: 'ABM Role',
              field: 'roleShortName',
              valueGetter: (params: GridValueGetterParams<DirectoryData, any>) =>
                params.row.roleShortName ? params.row.roleShortName : '',
              sortComparator: (v1, v2) => (v1 as string).localeCompare(v2 as string),
            },
            {
              title: 'State',
              field: 'areaStateList',
              valueGetter: (params: GridValueGetterParams<DirectoryData, any>) =>
                params.row.areaStateList[0] ? params.row.areaStateList[0] : '',
              sortComparator: (v1, v2) => (v1 as string).localeCompare(v2 as string),
              render: (rowData: DirectoryData) => (
                <ul className="plain-list">
                  {rowData.areaStateList.map((state, index) => (
                    <li value={state as string} key={index}>
                      {state as string}
                    </li>
                  ))}
                </ul>
              ),
            },
            {
              title: 'Region(s)',
              field: 'regionName',
              valueGetter: (params: GridValueGetterParams<DirectoryData, any>) =>
                params.row.regionName ? params.row.regionName : '',
              sortComparator: (v1, v2) => (v1 as string).localeCompare(v2 as string),
            },
            {
              title: 'Cluster',
              field: 'areaClusters',
              width: 420,
              disableColumnMenu: true,
              filterable: false,
              valueGetter: (params: GridValueGetterParams<DirectoryData, any>) =>
                params.row.areaClusters ? params.row.areaClusters[0].value : '',
              sortComparator: (v1, v2) => (v1 as string).localeCompare(v2 as string),
              render: (rowData) => {
                return <ShowCluster rowData={rowData}></ShowCluster>;
              },
            },
          ],
        });

        setDefaultmessage(<p>No results found with the current criteria.</p>);
      },
      error: () => {
        setDefaultmessage(<ErrorMessageToUser />);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [props.context.abmDirectoryEndpoint, token]);

  const createFilters = (dataUpToDate: DirectoryData[]) => {
    const roleShortNameSet = new Set<string>();
    const regionNameSet = new Set<string>();
    const areaStateSet = new Set<string>();
    dataUpToDate.forEach((columnData) => {
      roleShortNameSet.add(columnData.roleShortName as string);
      regionNameSet.add(columnData.regionName as string);
      columnData.areaStateList.forEach((areaStateName) => {
        areaStateSet.add(areaStateName);
      });
    });
    return { roleShortNameSet, regionNameSet, areaStateSet };
  };

  const filterDataWithValues = (filterValues: FilterResponse) => {
    const existFullNameFilter =
      typeof filterValues.fullName === 'string' && filterValues.fullName && filterValues.fullName.length > 0;
    const existAreaStateFilter = filterValues.areaStateList && filterValues.areaStateList.length > 0;
    const existRegionNameFilter = filterValues.regionName && filterValues.regionName.length > 0;
    const existRoleShortNameFilter = filterValues.roleShortName && filterValues.roleShortName.length > 0;
    const existAreaClustersFilter = filterValues.areaClusters && filterValues.areaClusters.length > 0;
    if (
      existFullNameFilter ||
      existAreaStateFilter ||
      existRegionNameFilter ||
      existRoleShortNameFilter ||
      existAreaClustersFilter
    ) {
      const filteredCacheArray = [];
      for (let i = 0; i < filteredDataCache.length; i++) {
        const record = filteredDataCache[i];
        let validRecords = true;
        let atLeastOneFilteredValue = true;
        if (existFullNameFilter) {
          if (typeof record.fullName === 'string' && record.fullName && record.fullName.length > 0) {
            if (record.fullName.toLowerCase) {
              const isValid =
                record.fullName?.toLowerCase().indexOf((filterValues.fullName as string).toLowerCase()) > -1 ||
                (record.email as string)?.toLowerCase().indexOf((filterValues.fullName as string).toLowerCase()) > -1 ||
                (record.contactPhone as string)?.toLowerCase().indexOf((filterValues.fullName as string).toLowerCase()) > -1;
              if (isValid) {
                atLeastOneFilteredValue = true;
              }
              validRecords = validRecords && isValid;
            }
          }
        }
        if (existAreaStateFilter) {
          if (record.areaStateList && record.areaStateList.length > 0) {
            const isValid = filterValues.areaStateList.some((areaStateFilterValues) =>
              record.areaStateList.some((recordAreaState: string) => {
                return areaStateFilterValues === recordAreaState;
              })
            );
            if (isValid) {
              atLeastOneFilteredValue = true;
            }
            validRecords = validRecords && isValid;
          }
        }
        if (existRegionNameFilter) {
          if (record.regionName && record.regionName.length > 0) {
            const isValid = filterValues.regionName.some(
              (regionNameFilterValues) => regionNameFilterValues === record.regionName
            );
            if (isValid) {
              atLeastOneFilteredValue = true;
            }
            validRecords = validRecords && isValid;
          }
        }
        if (existRoleShortNameFilter) {
          if (record.roleShortName && record.roleShortName.length > 0) {
            const isValid = filterValues.roleShortName.some(
              (regionNameFilterValues) => regionNameFilterValues === record.roleShortName
            );
            if (isValid) {
              atLeastOneFilteredValue = true;
            }
            validRecords = validRecords && isValid;
          }
        }
        if (existAreaClustersFilter) {
          if (filterValues.areaClusters && filterValues.areaClusters.length > 0) {
            const isValid = record.areaClusters.some(
              (areaCluster) =>
                areaCluster.key.indexOf(filterValues.areaClusters as string) >= 0 ||
                areaCluster.value.indexOf(filterValues.areaClusters as string) >= 0
            );
            if (isValid) {
              atLeastOneFilteredValue = true;
            }
            validRecords = validRecords && isValid;
          }
        }
        if (validRecords && atLeastOneFilteredValue) {
          filteredCacheArray.push(record);
        }
      }
      return filteredCacheArray;
    }
    return filteredDataCache;
  };

  return (
    <div>
      <h1>Auxiliary Board Members</h1>
      <Drawer anchor="right" open={filtersOpened} onClose={() => setFiltersOpened(!filtersOpened)} keepMounted={true}>
        <div>
          <h1 className="cluster-contact-title">ABM Directory</h1>
          {filterComponent}
        </div>
      </Drawer>
      <Row>
        <Col lgOffset={9} lg={3} md={4} mdOffset={8} sm={12}>
          <div className="spaced">
            <button
              className="primary-button  w-100"
              data-cy={'open-filters'}
              onClick={(e) => {
                e.preventDefault();
                setFiltersOpened(!filtersOpened);
              }}
            >
              Open filters
            </button>
          </div>
        </Col>
      </Row>
      <div className="show-table">
        <p>You can search and filter the data:</p>
        <div data-cy="displaydirectory">
          <AbmDirectoryTable defaultmessage={defaultmessage} columns={columns} filteredData={filteredData}></AbmDirectoryTable>
        </div>
      </div>
      <div className="hide-table">
        <p>You can search and filter the data:</p>
        <AbmDirectoryMobileTable
          filteredData={filteredData}
          defaultmessage={defaultmessage}
          columns={columns}
        ></AbmDirectoryMobileTable>
      </div>
    </div>
  );
};

export default withContext(AbmDirectory);
