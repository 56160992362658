import React from 'react';
import { NavLink } from 'react-router-dom';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';

export function getBreadcrumbData(title) {
  if (title.toUpperCase() === 'UHJ') {
    return { isAllCaps: true, mouseOverTitle: 'UHJ®', displayTitle: 'UHJ®' };
  }

  if (title.toUpperCase() === 'FAQ') {
    return {
      isAllCaps: true,
      mouseOverTitle: 'Frequently Asked Questions',
      displayTitle: 'FAQ',
    };
  }

  if (title.toUpperCase() === 'RBC-ELECTIONS') {
    return {
      isAllCaps: true,
      mouseOverTitle: 'Regional Bahá’í Council Elections',
      displayTitle: 'RBC Elections',
    };
  }

  if (title.toUpperCase() === 'ABM-DIRECTORY') {
    return {
      isAllCaps: true,
      mouseOverTitle: 'Auxiliary Board Member Directory',
      displayTitle: 'ABM Directory',
    };
  }

  if (title.toUpperCase() === 'LSA AND COMMUNITY GUIDANCE') {
    return {
      isAllCaps: true,
      mouseOverTitle: 'Local Spiritual Assembly and Community Guidance',
      displayTitle: 'LSA and Community Guidance',
    };
  }

  if (title.toUpperCase() === 'NATIONAL-CONVENTIONS') {
    return {
      isAllCaps: false,
      mouseOverTitle: 'National Conventions',
      displayTitle: 'National Conventions',
    };
  }

  if (title.toUpperCase() === 'NATCON-2022') {
    return {
      isAllCaps: true,
      mouseOverTitle: 'National Convention 2022',
      displayTitle: 'National Convention 2022',
    };
  }

  if (title.toUpperCase() === 'NATCON-2023') {
    return {
      isAllCaps: true,
      mouseOverTitle: 'National Convention 2023',
      displayTitle: 'National Convention 2023',
    };
  }

  if (title.toUpperCase() === 'NATCON-2024') {
    return {
      isAllCaps: true,
      mouseOverTitle: 'National Convention 2024',
      displayTitle: 'National Convention 2024',
    };
  }

  if (title.toUpperCase() === 'GLSA') {
    return { isAllCaps: true, mouseOverTitle: 'GLSA', displayTitle: 'GLSA' };
  }
  const displayTitle = title.length > 14 ? title.substring(0, 14) + '...' : title;
  const displayTitleWithoutHyphens = displayTitle.replace(/-/g, ' ');
  return {
    isAllCaps: false,
    mouseOverTitle: title,
    displayTitle: displayTitleWithoutHyphens,
  };
}

const routes = [
  { path: '/', breadcrumb: 'Home' },
  { path: '/prayer', breadcrumb: 'Prayers' },
];

const Breadcrumbs = ({ breadcrumbs }) => (
  <div className="bread" data-cy="bread">
    {breadcrumbs.map(({ breadcrumb, match }, index) => {
      const { isAllCaps, mouseOverTitle, displayTitle } = getBreadcrumbData(breadcrumb.props.children);
      return (
        <span key={breadcrumb.key} data-cy={`bread${index}`}>
          <NavLink title={mouseOverTitle} to={match.url} className={isAllCaps ? '' : 'capitalize'}>
            {displayTitle}
          </NavLink>
          {index < breadcrumbs.length - 1 && <i> / </i>}
        </span>
      );
    })}
  </div>
);

export default withBreadcrumbs(routes)(Breadcrumbs);
