import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import SecuritiesIcon from '../../assets/img/PlannedGiving/Securities.png';

function Securities() {
  return (
    <Row>
      <Col sm={1}>
        <img src={SecuritiesIcon} alt="Securities" className="fund-logo" />
      </Col>
      <Col sm={11}>
        <h1>Securities</h1>
        <p>
          A gift of appreciated stock/securities involves the <strong>direct</strong> transfer of the ownership of securities that
          have been held for at least one year and a day to the National Spiritual Assembly. Next to gifts of cash, this option is
          one of the simplest ways to make a contribution to the National Bahá’í Fund. Securities acceptable as contributions are
          publicly traded stock, mutual funds, Treasury bills and bonds, etc. The National Spiritual Assembly will receive the
          Fair Market Value (FMV) of the stock/securities; the donor will be eligible for a tax deduction of the FMV, and the
          donor will avoid capital gains taxes on the appreciated value of the securities. Immediately following the transfer of
          ownership to the National Spiritual Assembly, the securities are sold and the proceeds transferred to the National
          Bahá’í Fund unless designated otherwise.
        </p>
        <p>
          For additional instructions and to ensure proper credit, please contact Amin Vargha, Planned Giving Associate at{' '}
          <a href="tel:847-733-3569">(847) 733-3569</a> or email{' '}
          <a href="mailto:plannedgiving@usbnc.org">plannedgiving@usbnc.org</a>.
        </p>
        <ul className="plain-list">
          <li>
            <strong>Download: </strong>
            <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Planned-Giving/Transfer-of-Securities.pdf">
              Transferring Securities
            </a>
          </li>
          <li>
            <strong>Download: </strong>
            <a href="https://s3.amazonaws.com/bahai-web/tab/forms/pg-transferring-stocks.pdf">Transferring Stock/Bond Power</a>
          </li>
        </ul>
      </Col>
    </Row>
  );
}

export default Securities;
