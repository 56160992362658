import React from 'react';
import { NavLink } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const SectGlsaLinks = ({ sectionTerms, chapterTitle, urlsByCodename, matchingUrl }) => {
  return (
    <div data-cy="displaylistlink">
      <h1 data-cy="listtitle">{chapterTitle}</h1>
      <NavLink to={matchingUrl.prevUrl} data-cy="sectionnavprevious">
        <ExpandLess className="large-text" />
      </NavLink>
      <ul className="plain-list">
        {sectionTerms.map((term, key) => (
          <li key={key}>
            <NavLink data-cy={`link${key}`} to={urlsByCodename[term.codename].url}>
              {term.name}
            </NavLink>
          </li>
        ))}
      </ul>
      <NavLink to={matchingUrl.nextUrl} data-cy="sectionnavnext">
        <ExpandMore className="large-text" />
      </NavLink>
    </div>
  );
};

export default SectGlsaLinks;
