import React from 'react';
import { MenuItem } from '@material-ui/core';

function monthNumberToString(monthNumber) {
  const monthNumberAsInt = parseInt(monthNumber);
  switch (monthNumberAsInt) {
    case 1:
      return 'January';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'June';
    case 7:
      return 'July';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 12:
      return 'December';
    default:
      return '';
  }
}

const letterPartsRegex = /(?<urlSlug>.+)-(?<year>\d\d\d\d)-?(?<language>.*)/;

/**
 * Converts ISO 8601 formatted date to full month then date then comma and finally the year
 * @example
 * // calling
 * dateStringToDisplayDate("2020-03-15");
 * // will return
 * 'March 15, 2020';
 *
 * // calling
 * dateStringToDisplayDate("2020-03-15", true);
 * // will return
 * 'Mar 15, 2020';
 */
export const dateStringToDisplayDate = (asOfInputDate, useThreeLetterMonth) => {
  if (!asOfInputDate) {
    return '';
  }

  const re = /(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})/;
  const match = re.exec(asOfInputDate);
  const year = match.groups.year;
  const monthNumber = match.groups.month;
  const monthString = useThreeLetterMonth ? monthNumberToString(monthNumber).substring(0, 3) : monthNumberToString(monthNumber);
  const dayNumberAsString = match.groups.day;
  const dateNumberAsInt = parseInt(dayNumberAsString);

  return `${monthString} ${dateNumberAsInt}, ${year}`;
};

/**
 * Converts ISO 8601 formatted date to full month then the year
 * @example
 * // calling
 * dateStringToDisplayDate("2020-03-15");
 * // will return
 * 'March 2020';
 */
export const dateStringToMonthYear = (asOfInputDate) => {
  const re = /(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2}).*/;
  const match = re.exec(asOfInputDate);
  const year = match.groups.year;
  const monthNumber = match.groups.month;
  const monthString = monthNumberToString(monthNumber);

  return `${monthString} ${year}`;
};

export const extractLetterPartsFromUrl = (url) => {
  const match = letterPartsRegex.exec(url);
  return match.groups;
};

export const stringToBoolean = (input) => {
  if (typeof input !== 'string') {
    return false;
  }

  switch (input.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
    case 'on':
      return true;
    default:
      return false;
  }
};

/**
 * Build full name
 * @example
 * // calling
 * buildFullName({title: "Mr", firstName: "John", nickName: "Jacob Jingle", middleName: "Heimer", lastName: "Schimdt", suffix: "Jr"});
 * // will return
 * 'Mr John "Jacob Jingle" Heimer Schmidt Jr';
 */
export const buildFullName = (myProfileResponse) => {
  let nameBuilder = myProfileResponse.title && myProfileResponse.title.length > 0 ? `${myProfileResponse.title} ` : '';
  nameBuilder += myProfileResponse.firstName && myProfileResponse.firstName.length > 0 ? `${myProfileResponse.firstName} ` : '';
  nameBuilder += myProfileResponse.nickName && myProfileResponse.nickName.length > 0 ? `"${myProfileResponse.nickName}" ` : '';
  nameBuilder +=
    myProfileResponse.middleName && myProfileResponse.middleName.length > 0 ? `${myProfileResponse.middleName} ` : '';
  nameBuilder += myProfileResponse.lastName;
  nameBuilder += myProfileResponse.suffix && myProfileResponse.suffix.length > 0 ? ` ${myProfileResponse.suffix}` : '';
  return nameBuilder;
};

/**
 * Build full name withOUT nickname
 * @example
 * // calling
 * buildFullName({title: "Mr", firstName: "John", nickName: "Jacob Jingle", middleName: "Heimer", lastName: "Schimdt", suffix: "Jr"});
 * // will return
 * 'Mr John Heimer Schmidt Jr';
 */
export const buildFormalFullName = (myProfileResponse) => {
  let nameBuilder = myProfileResponse.title && myProfileResponse.title.length > 0 ? `${myProfileResponse.title} ` : '';
  nameBuilder += myProfileResponse.firstName && myProfileResponse.firstName.length > 0 ? `${myProfileResponse.firstName} ` : '';
  nameBuilder +=
    myProfileResponse.middleName && myProfileResponse.middleName.length > 0 ? `${myProfileResponse.middleName} ` : '';
  nameBuilder += myProfileResponse.lastName;
  nameBuilder += myProfileResponse.suffix && myProfileResponse.suffix.length > 0 ? ` ${myProfileResponse.suffix}` : '';
  return nameBuilder;
};

/**
 * Callback to be passed into an array's map() function
 * @example <caption>Use by passing into map() function</caption>
 * // calling
 * const stateLookup = [{key:"FL", value:"Florida"}, {key:"CA", value:"California"}];
 * return (<Select>{stateLookup.map(keyValuesToMenuItems)}</Select>);
 * // will return
 * <Select><MenuItem key="0" value="FL">Florida</MenuItem><MenuItem key="1" value="CA">California</MenuItem></Select>
 */
export const keyValuesToMenuItems = (item, key) => {
  const keyValue = { itemKey: item.key, itemValue: item.value, reactKey: key };
  return keyValueToMenuItem(keyValue);
};

/**
 * Callback to be passed into an array's map() function
 * @example <caption>Use by passing into map() function</caption>
 * // calling
 * const languageLookup = ["English", "Spanish"];
 * return (<Select>{languageLookup.map(valuesToMenuItems)}</Select>);
 * // will return
 * <Select><MenuItem key="0" value="English">English</MenuItem><MenuItem key="1" value="Spanish">Spanish</MenuItem></Select>
 */
export const valuesToMenuItems = (item, key) => {
  const keyValue = { itemKey: item, itemValue: item, reactKey: key };
  return keyValueToMenuItem(keyValue);
};

/**
 * Build react menu item to be used as a child of Select input
 * @example
 * // calling
 * keyValueToMenuItem({itemKey: "FL", itemValue: "Florida", reactKey: 17});
 * // will return
 * <MenuItem key="17" value="FL">Florida</MenuItem>
 */
export const keyValueToMenuItem = ({ itemKey, itemValue, reactKey }) => {
  const itemKeyString = '' + itemKey;
  return (
    <MenuItem key={reactKey} value={itemKeyString}>
      {itemValue === 'None' ? <em>None</em> : itemValue}
    </MenuItem>
  );
};

/**
 * Sort by age display
 * @example
 * // calling
 * [{ageDisplay: "11"}, {ageDisplay: "Adult"}, {ageDisplay: "12"}].sort(byAgeDisplay);
 * // will return
 * [{ageDisplay: "Adult"}, {ageDisplay: "12"}, {ageDisplay: "11"}]
 */
export const byAgeDisplay = (householdMemberA, householdMemberB) => {
  if (householdMemberA?.ageDisplay === householdMemberB?.ageDisplay) {
    return 0;
  }
  if (householdMemberA?.ageDisplay === 'Adult') {
    return -1;
  }
  if (householdMemberB?.ageDisplay === 'Adult') {
    return 1;
  }

  if (parseInt(householdMemberA?.ageDisplay, 10) === parseInt(householdMemberB?.ageDisplay, 10)) {
    return 0;
  }

  if (parseInt(householdMemberA?.ageDisplay, 10) > parseInt(householdMemberB?.ageDisplay, 10)) {
    return -1;
  }

  if (parseInt(householdMemberA?.ageDisplay, 10) < parseInt(householdMemberB?.ageDisplay, 10)) {
    return 1;
  }

  return 0;
};

export const sortServicesByDateCountry = (items) => {
  const countryDateInOrder = items.sort((a, b) =>
    a.location.value.trim() === b.location.value.trim()
      ? a.base__datepublished.value < b.base__datepublished.value
        ? 1
        : -1
      : a.location.value.trim() > b.location.value.trim()
      ? 1
      : -1
  );

  return countryDateInOrder;
};

const phoneRegex = /^(?<areaCode>\d{3})(?<firstThree>\d{3})(?<lastFour>\d{4})$/;

/**
 * Format unformatted phone number
 * @example
 * // calling
 * tryFormatPhone("1234567890");
 * // will return
 * "(123) 456-7890"
 */
export const tryFormatPhone = (unformattedPhone) => {
  const match = phoneRegex.exec(unformattedPhone);
  const { areaCode, firstThree, lastFour } = match?.groups ?? {};
  if (areaCode && firstThree && lastFour) {
    return `(${areaCode}) ${firstThree}-${lastFour}`;
  } else {
    return unformattedPhone ?? '';
  }
};

export const replaceBaseUrlLinks = (item) => {
  const currentBaseUrl = window.location.href.split('/').find((x) => x.startsWith('community'));
  return item ? item.replaceAll('<a href="/community/', '<a href="/' + currentBaseUrl + '/') : item;
};
