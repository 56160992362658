import React from 'react';

function NationalStatistics() {
  return (
    <div>
      <h1>National Statistics Office</h1>
      <p>
        <em>
          “One of the important accomplishments of recent Plans is a heightened awareness among institutions and believers
          everywhere of the role statistical information can play in facilitating the planning process at the cluster level. This
          is demonstrated by the increasing ability of the friends to collect pertinent data and to use this information, which
          draws attention to strengths to be built upon and weaknesses to be overcome, to track progress, analyse the patterns of
          growth prevalent in their communities and decide upon the next set of goals to be adopted.”
        </em>
      </p>
      <p>~ From a message dated 8 April 2007 from the Universal House of Justice to National Spiritual Assemblies</p>
      <p>
        The National Statistics Office was established in April 2007 to manage information and resources pertaining to the
        Statistical Report Program (SRP), a database designed by the Bahá’í World Center, operated by the Department of
        Statistics, to collect, analyze, and report information about the progress of the clusters in meeting the goals of the
        current Plan within the United States.
      </p>
      <p>
        <a href="tel:847-733-3403">847-733-3403</a>
      </p>
      <p>
        <a href="mailto:nso@usbnc.org">NSO@usbnc.org</a>
      </p>
      <br />
      <p>
        <strong>The National Assembly ensures that the capacity to use SRP is firmly established</strong>
      </p>
      <p>
        National Assemblies have been assisting cluster and regional institutions and agencies to build their capacity to use such
        instruments, and often appoint regional and national statistics officers to aid in this effort . . . As the cluster
        continues to develop, other capacities emerge, such as to increase the accuracy of statistics; to collect information and
        use it to obtain a better reading of reality; and to analyse information and use such analyses in consultations. SRP is
        also introduced as a means of recording such information and the friends are assisted in becoming familiar with its use.
        In this regard, the House of Justice, in a letter written on its behalf to a National Assembly, has stated “you will need
        to ensure that capacity to use this important instrument is firmly established at the cluster, regional and national
        levels. At the cluster level, this is an objective that would need to be pursued step by step, starting in the most
        advanced clusters and gradually reaching other clusters as capacity grows.” The House of Justice has indicated that an
        essential element is to help those responsible for reflection and planning to become increasingly adept in the collection
        and use of statistical information and that periodic gatherings for reflection are necessary in this regard.
      </p>
      <p>~ Flow of Guidance and Information, 5 March 2023 Office of the Development of Administrative Systems, (p. 9)</p>
      <br />
      <p>
        <strong>The Role of the National Statistics Office</strong>
      </p>
      <p>
        To effectively evaluate the progress of a cluster through analysis and planning, the Universal House of Justice
        established three instruments. The Statistical Report Program (SRP)—operational online across the United States since
        November 2019--is used by cluster agencies at the grassroots—across neighborhoods and localities--to report on progress of
        the society-building power of the Faith that is extending deeply into the soil of each community, making it possible to
        measure the number of clusters where a program of growth is being initiated and the degree of intensity that each has
        reached. Another instrument, the Cluster Growth Profile—automatically generated from information flowing into
        SRP—accelerates planning and analysis of emerging patterns of growth at the cluster and regional levels. Finally, the
        third instrument, the Biannual Progress Survey--also flowing from SRP--provides a snapshot of progress by Regional
        Council, in May and November of each year.
      </p>
      <p>
        The National Statistics Officer works with institutions and agencies—such as Regional Bahá’í Councils, regional statistics
        officers, and Regional Training Institute members--to ensure that the capacity to use these instruments is firmly
        established, and to further strengthen the flow of statistical information, stemming from various sources, into SRP, so
        that the Regional Bahá’í Councils, “remain fully acquainted with the developments at the grassroots and what is being
        learned in the clusters whose progress they oversee.” The National Statistics Officer supports such efforts and provides
        monthly reports to the National Assembly reflecting analysis on the progress made in expansion and consolidation efforts
        as reported through the Statistical Report Program.
      </p>
      <p>
        The National Spiritual Assembly’s (NSA) progress in meeting the quantitative goals of the current Plan is managed by its
        National Statistics Office (NSO), which is part of the NSA’s Secretariat. Should any questions arise concerning any
        aspects of local, cluster, regional, or national statistics, feel free to contact this office by email at{' '}
        <a href="mailto:nso@usbnc.org">NSO@usbnc.org</a> or by phone at <a href="tel:847-733-3403">847-733-3403</a>.
      </p>
      <br />
      <h3>More Guidance on the Role of Statistics</h3>
      <p>
        <strong>The essentially spiritual undertakings have measurable outcomes . . .</strong>
      </p>
      <p>
        <em>
          “By the conclusion of the new series of Plans recently begun, the Bahá’í community will need to have acquired capacities
          that can scarcely be glimpsed at present. In your deliberations over the coming days, you will be occupied with
          exploring what is required to bring into being such a fortified community.”
        </em>
      </p>
      <p>
        <em>
          “A greater expression of the society-building power of the Faith requires, first and foremost, still further advances in
          the process of entry by troops in every part of the world. The essentially spiritual undertakings of diffusing the light
          of Bahá’u’lláh’s Revelation ever more widely and extending the roots of His Faith ever more deeply into the soil of
          society have measurable outcomes: the number of clusters where a programme of growth has been initiated and the degree
          of intensity that each has reached. The means now exist for a swift advance in relation to both measures. The goal that
          the community of the Greatest Name must aspire to fulfil during the current series of global Plans is to establish
          intensive programmes of growth in all the clusters in the world. This formidable objective implies a broadening and
          intensification of activity on a scale never witnessed. Rapid progress towards this goal must be achieved in the course
          of the Nine Year Plan.”
        </em>
      </p>
      <p>~2021 Dec 30 Universal House of Justice, (p. 1 & 5)</p>
      <br />
      <p>
        <strong>Data collected whether quantitative or qualitative is for planning</strong>
      </p>
      <p>
        <em>
          “The House of Justice in a letter written on its behalf stated that “the data collected, whether quantitative or
          qualitative, is primarily for the purpose of reviewing and planning grassroots efforts. . . Timely access to information
          about the movement of clusters and the work of the institute in their jurisdictions is required for institutions to
          support their agencies and take the many decisions . . . Further, the House of Justice states that such information
          allows these institutions “to accurately read the reality of their communities and act on the basis of clearly
          understood needs when marshaling the energies of the friends towards meeting the exigencies of the hour.”. . More
          broadly, news and information is shared with the generality for the believers to inspire them, promote greater unity and
          keep them well informed of developments.”
        </em>
      </p>
      <p>~ Flow of Guidance and Information, 5 March 2023 Office of the Development of Administrative Systems, (p. 8)</p>
      <br />
      <p>
        <strong>When the numbers grow ... a system emerges ...</strong>
      </p>
      <p>
        With regard to collecting statistics, the House of Justice, in a letter written on its behalf to a National Assembly
        states, “As the process of expansion and consolidation progresses within a cluster the gathering of numerical data is a
        natural part of the everyday activities that take place as the friends carry out their work and make plans for the next
        steps.” When the number of activities is small, information flows easily, but as the number grows, the necessity for a
        system to facilitate the collection, utilization, and dissemination of reliable information emerges. In this regard,
        several formal instruments have been developed, such as the cluster growth profile, the Statistical Report Program (SRP),
        and the bi-annual Survey.
      </p>
      <p>~ Flow of Guidance and Information, 5 March 2023 Office of the Development of Administrative Systems, (p. 9)</p>
      <br />
      <p>
        <strong>A function of SRP is to enable information to be shared swiftly</strong>
      </p>
      <p>
        The flow of information “that takes place in everyday conversation” can occur through “informal means”; yet formal
        structures and instruments are required, some of which are “highly structured”. . . Formalizing the collection of
        information has had the benefit of introducing consistency and efficiency. For example, a function of SRP is to enable
        information on the numerical progress of a cluster to be shared swiftly amongst institutions. However, care has had to be
        exercised, so that reporting requirements are kept to a minimum required for planning and decision making . . .
      </p>
      <p>~ Flow of Guidance and Information, 5 March 2023 Office of the Development of Administrative Systems, (p. 10-11)</p>
      <br />
      <p>
        <strong>. . . to ensure that capacity to use this important instrument is firmly established</strong>
      </p>
      <p>
        National Assemblies have been assisting cluster and regional institutions and agencies to build their capacity to use such
        instruments, and often appoint regional and national statistics officers to aid in this effort . . . As the cluster
        continues to develop, other capacities emerge, such as to increase the accuracy of statistics; to collect information and
        use it to obtain a better reading of reality; and to analyse information and use such analyses in consultations. SRP is
        also introduced as a means of recording such information and the friends are assisted in becoming familiar with its use.
        In this regard, the House of Justice, in a letter written on its behalf to a National Assembly, has stated “you will need
        to ensure that capacity to use this important instrument is firmly established at the cluster, regional and national
        levels. At the cluster level, this is an objective that would need to be pursued step by step, starting in the most
        advanced clusters and gradually reaching other clusters as capacity grows.” The House of Justice has indicated that an
        essential element is to help those responsible for reflection and planning to become increasingly adept in the collection
        and use of statistical information and that periodic gatherings for reflection are necessary in this regard.
      </p>
      <p>~ Flow of Guidance and Information, 5 March 2023 Office of the Development of Administrative Systems, (p. 9)</p>
      <p>
        <strong>Why gather statistics?</strong>
      </p>
      <p>
        <em>
          “In general, the statistical information collected at the level of the cluster is primarily intended to facilitate local
          analysis and planning. Therefore, it is important for the friends and especially the agencies guiding the efforts at the
          cluster level to strive to understand the nature of the work called for by the House of Justice in the Five Year Plan
          and how the gathering of statistical information will inform reflection on action and assist in revising and improving
          approaches in cycle after cycle.”
        </em>
      </p>
      <p>~ From a message dated 10 October 2016 from the Universal House of Justice to an individual believer</p>
      <br />
      <p>
        <strong>A fundamental aspect of the work of agencies . . . to expand the vision of what can be achieved?</strong>
      </p>
      <p>
        <em>
          “The Universal House of Justice has provided guidance over the years about the gathering of accurate statistics and the
          careful analysis of this information as a fundamental aspect of the work of agencies at the cluster level in their
          efforts to ensure the steady progress of a programme of growth. . . A fundamental element of such an approach is
          developing the capacity to collect statistical data and utilize this information to expand the vision of what can be
          achieved in the cluster and make decisions that will facilitate its development.”
        </em>
      </p>
      <p>
        ~From a document prepared February 2016 by the Department of Statistics at the Bahá’í World Center, “The Development of
        Capacity to Collect and Utilize Statistics
      </p>
      <br />
      <p>
        <strong>Why is there no need to try to present a favorable image?</strong>
      </p>
      <p>
        <em>
          “There need be no concern with trying to present a particularly favorable image of the work in a cluster; rather,
          statistics that accurately reflect the condition of the cluster, even when there is lack of progress for some time, are
          essential to aid the understanding of the friends within the clusters as well as help agencies at the regional and
          national level determine what type of support may be required to overcome existing challenges.”
        </em>
      </p>
      <p>~ From a message dated 10 October 2016 from the Universal House of Justice to an individual believer</p>
      <br />
      <p>
        <strong>Why are certain statistics gathered?</strong>
      </p>
      <p>
        <em>
          “It should be noted that statistics are gathered only on particular kinds of activities related to the progress of a
          cluster and that many other endeavors which are also very important to the development of the Faith are not counted.
          This is because of the need to focus on learning about specific aspects of the work at this time, and the Area Teaching
          Committee should not feel the necessity to alter the recording of statistics merely to try to reflect the entirety of
          services of the believers, all of whose contributions are nevertheless meritorious and appreciated.”
        </em>
      </p>
      <p>~ From a message dated 10 October 2016 from the Universal House of Justice to an individual believer</p>
      <br />
      <p>
        <strong>Who helps the Area Teaching Committee gather statistics?</strong>
      </p>
      <p>
        <em>
          “In managing the complexities involved in its own work - which includes gathering and analyzing statistical data, as
          well as a diversity of other tasks - the [Area Teaching] Committee draws on the help of individuals beyond its own
          members. These complexities also require increasingly close collaboration with Local Spiritual Assemblies.”
        </em>
      </p>
      <p>
        ~ From a message dated 29 December 2015 from the Universal House of Justice to the Conference of the Continental Boards of
        Counsellors
      </p>
      <br />
      <p>
        <strong>Who is in need of relevant and accurate information?</strong>
      </p>
      <p>
        <em>
          “That all of the participants in the Plan - engaged in core activities; attending reflection meetings; acting as tutors,
          animators or teachers of children’s classes; serving as institute coordinators or as members of other agencies and
          institutions - are in need of relevant and accurate information is quite clear. One source of such information is the
          body of statistics maintained by the Area Teaching Committee on various dimensions of the growth process advancing in
          the cluster.”
        </em>
      </p>
      <p>~From Building Vibrant Communities, [Ruhi book 10] Unit 3, p. 6</p>
      <br />
      <p>
        <strong>What does progress require?</strong>
      </p>
      <p>
        <em>
          “One day, we leave our home, drive and drive, thrilled by the fact that we are moving. Late at night, overwhelmed by
          fatigue, we stop and rest. The next day, eager to renew our efforts, we are shocked to find that we are parked in front
          of our house—the very point where we began! With renewed determination, we return to the road, driving day and night;
          our exertion has never been greater. But when we pause to get our bearings, to our dismay, we find ourselves once again
          at our starting point. It is not traveling itself that is important, we now understand, but whether we move closer to a
          desired destination. We have traveled extensively, with diligent effort, and gone nowhere. Progress requires more than
          activity. We have to have a destination in mind and then chart our progress against that intended goal.”
        </em>
      </p>
      <p>~ Creating a New Mind, p. 132</p>
      <br />
      <p>
        <strong>What role does statistical information play?</strong>
      </p>
      <p>
        <em>
          “One of the important accomplishments of recent Plans is a heightened awareness among institutions and believers
          everywhere of the role statistical information can play in facilitating the planning process at the cluster level. This
          is demonstrated by the increasing ability of the friends to collect pertinent data and to use this information, which
          draws attention to strengths to be built upon and weaknesses to be overcome, to track progress, analyse the patterns of
          growth prevalent in their communities and decide upon the next set of goals to be adopted.”
        </em>
      </p>
      <p>~ From a message dated 8 April 2007 from the Universal House of Justice to National Spiritual Assemblies</p>
      <br />
      <p>
        <strong>How does the local Assembly nurture the process of growth?</strong>
      </p>
      <p>
        <em>
          “What needs to occur in the first instance is relatively straightforward: Individual awareness of the process of growth
          gathering momentum in the village, born of each member’s personal involvement in the core activities, must coalesce into
          a collective consciousness that recognizes both the nature of the transformation under way and the obligation of the
          Assembly to foster it. Without doubt, some attention will have to be given to certain basic administrative functions—for
          example, meeting with a degree of regularity, conducting the Nineteen Day Feast and planning Holy Day observances,
          establishing a local fund, and holding annual elections in accordance with Bahá’í principle. However, it should not
          prove difficult for the Local Assembly to begin, concomitant with such efforts and with encouragement from an assistant
          to an Auxiliary Board member, to consult as a body on one or two specific issues with immediate relevance to the life of
          the community: how the devotional character of the village is being enhanced through the efforts of individuals who have
          completed the first institute course; how the spiritual education of the children is being addressed by teachers raised
          up by the institute; how the potential of junior youth is being realized by the programme for their spiritual
          empowerment; how the spiritual and social fabric of the community is being strengthened as the friends visit one another
          in their homes. As the Assembly consults on such tangible matters and learns to nurture the process of growth lovingly
          and patiently, its relationship with the Area Teaching Committee and the training institute gradually becomes cemented
          in a common purpose. But, of still greater importance, it will begin to lay the foundations on which can be built that
          uniquely affectionate and genuinely supportive relationship, described by the beloved Guardian in many of his messages,
          which Local Spiritual Assemblies should establish with the individual believer.”
        </em>
      </p>
      <p>
        ~ From a message dated 28 December 2010 from the Universal House of Justice to the Conference of the Continental Boards of
        Counsellors
      </p>
      <br />
      <p>
        <strong>Extending beyond encouraging words</strong>
      </p>
      <p>
        <em>
          “Of course, it is not the provision of training by itself that brings about progress. Efforts to build capacity fall
          short if arrangements are not swiftly made to accompany individuals into the arena of service. An adequate level of
          support extends far beyond encouraging words. When preparing to take on an unfamiliar task, working alongside a person
          with some experience increases consciousness of what is possible. An assurance of practical help can give a tentative
          venturer the courage to initiate an activity for the first time. Souls then advance their understanding together, humbly
          sharing the insights each possesses at a given moment and eagerly seeking to learn from fellow wayfarers on the path of
          service. Hesitation recedes and capacity develops to the point where an individual can carry out activities
          independently and, in turn, accompany others on the same path.”
        </em>
      </p>
      <p>
        ~ From a message dated 29 December 2015 from the Universal House of Justice to the Conference of the Continental Boards of
        Counsellors
      </p>
      <br />
      <p>As a natural part of everyday activities of the cluster . . .</p>
      <p>
        <em>
          “ . . . that informal methods of keeping track of information during the early stages of a cluster’s development give
          way to the compilation of a cluster growth profile every three months as the programme of growth begins to conform to
          the rhythm of cycles of activity; that, as the pattern of activity intensifies, National Assemblies and Regional
          Councils need to remain alert to when cluster agencies could begin maintaining information in the Statistical Report
          Program and to make available the necessary resources for its implementation; that the gathering of accurate numerical
          data is a natural part of the everyday activities of the cluster agencies, who rely on such data to effectively carry
          out their service; that there are often a few points during the cycle when a special effort is made to ensure that the
          information in the program is complete in order to facilitate planning; and that, in clusters where capacity must
          develop to manage the increased complexity associated with embracing large numbers, the Area Teaching Committee needs
          access to complete and accurate data so that it can engage in a more thorough reading of circumstances in the whole
          cluster, which will assist it in its efforts to draw the entire company of believers into the pattern of activities of
          the Plan and to reach out to ever-wider circles to share the teachings of the Faith.”
        </em>
      </p>
      <p>
        ~From a letter dated 22 May 2017 from the Department of Statistics at the Bahá’í World Center, to all National Spiritual
        Assemblies
      </p>
      <br />
      <h3>Downloads</h3>
      <ul>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/resources/2024+June+-+Exploring+Questions+for+Analysis+-+Group+3+slides.pdf">
            Exploring Questions for Analysis
          </a>{' '}
          - June 2024
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/resources/A+story+by+Abdul-Baha+about+Accompaniment+Star+of+the+West.pdf">
            A story from Abdul-Baha about accompaniment
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/resources/Why+Gather+Statistics+-+LSA+-+Excerpts+from+Guidance.pdf">
            Why Statistics?
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/resources/5+Slides+and+1+Case+Study+-+Statistics+in+Everyday+Activities.pdf">
            5 Slides and 1 Case Study – Guidance from the Bahá’í World Center
          </a>
        </li>
      </ul>
      <p>
        Statistics Questions? Contact the National Statistics Office at <a href="mailto:nso@usbnc.org">NSO@usbnc.org</a>
      </p>
    </div>
  );
}

export default NationalStatistics;
