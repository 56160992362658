import React, { Component } from 'react';
import Article from './Article';
import NoMatch from '../Routers/NoMatch';
import withContext from '../ContextAPI/Context_HOC';
import ImageCaptionResolver from './Resolver/ImageCaptionResolver';

class DisplayArticle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calldata: [],
      displayDrawer: 'none',
    };
  }
  componentDidMount() {
    this.subscription = this.props.context.digitalDesignContent
      .items()
      .type(this.props.contenttype)
      .equalsFilter('elements.url_slug', [this.props.match.params.articleid])
      .queryConfig({
        urlSlugResolver: (link, text) => {
          let pushthis = this.props.context.newsLinkIds;
          if (pushthis.indexOf(link.linkId) === -1) {
            pushthis.push(link.linkId);
            this.props.context.setNewsLinkIds(pushthis);
          }
          return {
            asHtml: `<a value=${link.linkId} href=/news>${text.linkText}</a>`,
          };
        },
        richTextResolver: (data) => {
          return ImageCaptionResolver(data);
        },
      })
      .toObservable()
      .subscribe(async (response) => {
        if (!response.isEmpty) {
          response.items[0].base__body.resolveHtml();
          this.setState({ calldata: response.items }, function () {
            if (this.props.contenttype === 'news_article') {
              this.setState({
                display: <Article details={this.state.calldata[0]} />,
              });
            }
          });
        } else {
          this.setState({ display: <NoMatch /> });
        }
      });
  }
  render() {
    return <div data-cy="newsarticlepage">{this.state.display}</div>;
  }
}
export default withContext(DisplayArticle);
