import { stringToBoolean } from '../Utilities/UtilityFunctions';

export default class ContentProviderEnvironment {
  isPreviewApi(): boolean {
    return stringToBoolean(sessionStorage.getItem('contentProviderIsPreviewApi'));
  }

  setPreviewApi(): void {
    sessionStorage.setItem('contentProviderIsPreviewApi', true.toString());
    window.location.reload();
  }

  setDeliveryApi(): void {
    sessionStorage.setItem('contentProviderIsPreviewApi', false.toString());
    window.location.reload();
  }
}
