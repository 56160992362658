import React from 'react';

function ErrorMessageToUser() {
  return (
    <div data-cy="error-message-to-user">
      An error has occurred while loading. Our team has been notified and is working to fix it. Please check back later.
    </div>
  );
}

export default ErrorMessageToUser;
