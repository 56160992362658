import React, { Component } from 'react';
import FeastLetter from './FeastLetter';
import Letters from './Letters';
import { extractLetterPartsFromUrl } from '../Utilities/UtilityFunctions';
import withContext from '../ContextAPI/Context_HOC';

class DisplayLetter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calldata: {},
      display: <div>Loading...</div>,
      displayDrawer: 'none',
    };
  }
  componentDidMount() {
    const { typeid, messageid } = this.props.match.params;
    const letterParts = extractLetterPartsFromUrl(messageid);
    const urlslug = letterParts.urlSlug;
    const dateFilter = parseInt(letterParts.year);
    const language = letterParts.language?.length === 0 ? 'en-US' : letterParts.language;
    const contenttype = typeid === 'feast_message' ? 'feast_message' : 'letter';

    this.subscription = this.props.context.digitalDesignContent
      .items()
      .type(contenttype)
      .languageParameter(language)
      .elementsParameter([
        'base__title',
        'url_slug',
        'base__datepublished',
        'messages',
        'linkeditemssection',
        'coverletterlink',
        'base__body',
        'attachments',
        'base__listenhtml',
      ])
      .equalsFilter('elements.url_slug', urlslug)
      .rangeFilter('elements.base__datepublished', dateFilter, dateFilter + 1)
      .orderParameter('elements.base__datepublished', 'desc')
      .toObservable()
      .subscribe(async (response) => {
        if (!response.isEmpty) {
          this.setState({ calldata: response.items }, function () {
            let display;
            if (typeid === 'feast_message') {
              display = <FeastLetter details={this.state.calldata[0]} />;
              this.setState({ display: display });
            } else {
              display = <Letters details={this.state.calldata[0]} />;
              this.setState({ display: display });
            }
          });
        } else {
          this.setState({ display: <FeastLetter details={''} /> });
          this.setState({ display: <Letters details={''} /> });
        }
      });
  }
  componentWillUnmount() {
    this.subscription.unsubscribe();
  }
  render() {
    return <div>{this.state.display}</div>;
  }
}
export default withContext(DisplayLetter);
