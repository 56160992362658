import { map } from 'rxjs/operators';
import AuthService from '../Login/AuthService';
import GlobalAPICall from '../GlobalComponents/GlobalAPICall';
import { Observable } from 'rxjs';

export interface DigitalIdCardResponse {
  approvalDate: string;
  blc: string;
  cluster: string;
  euCode: string;
  fullName: string;
  idn: number;
  region: string;
}
export default class DigitalIdCardService {
  constructor(
    private readonly authService: AuthService,
    private readonly apiCaller: typeof GlobalAPICall,
    private readonly apiUrl: string
  ) {
    this.getDigitalIdCard = this.getDigitalIdCard.bind(this);
  }

  getDigitalIdCard(token: string): Observable<DigitalIdCardResponse> {
    const observable = this.apiCaller
      .getObservable(process.env.REACT_APP_API_URL + `/api/idcard/id`, {
        headers: { Authorization: 'bearer ' + token },
      })
      .pipe(
        map((x: any) => {
          return x.data as DigitalIdCardResponse;
        })
      );
    return observable;
  }
}
