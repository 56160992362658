import React, { useEffect, useState, FunctionComponent } from 'react';
import GlobalAPICall from '../GlobalComponents/GlobalAPICall';
/*
 * This will be tested by International.test, Leterlist.test, KeyDownEventListener.Test, Article.test, and UnitedStates.test
 */
const AutoDownload: FunctionComponent<{ url: string; fileName: string; downloadText: string }> = ({
  url,
  fileName,
  downloadText,
}) => {
  const [data, setData] = useState('');

  useEffect(() => {
    if (url) {
      GlobalAPICall.fetch(url, { method: 'GET' })
        .then((resp) => resp.blob())
        .then((blob) => setData(URL.createObjectURL(blob)))
        .catch((err) => {
          throw err;
        });
    }
    return () => URL.revokeObjectURL(data);
  }, [url]);

  return (
    <>
      {url ? (
        <a data-cy="attachment" href={data} download={fileName} datatype="json">
          {downloadText}
        </a>
      ) : (
        <p data-cy="attachment">No attachment provided</p>
      )}
    </>
  );
};

export default AutoDownload;
