import ContentProviderEndpoint from '../../Content/ContentProviderEndpoint';
import { ReplaySubject, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentItem, ItemResponses, TaxonomyTerms } from '@kentico/kontent-delivery';

export default class FaqProxy {
  taxonomySubject = new ReplaySubject<
    {
      codenames: {
        digitalDesign: string;
        glsa: string;
      };
      name: string;
      codename: string;
      terms: TaxonomyTerms[];
    }[]
  >(1);
  digitalDesignContent: ContentProviderEndpoint;
  glsaContent: ContentProviderEndpoint;
  taxonomyInitialized: boolean = false;

  constructor(digitalDesignContent: ContentProviderEndpoint, glsaContent: ContentProviderEndpoint) {
    this.digitalDesignContent = digitalDesignContent;
    this.glsaContent = glsaContent;
  }

  getTaxonomy() {
    if (!this.taxonomyInitialized) {
      this.taxonomyInitialized = true;

      const digitalDesignTaxonomyObservable = this.digitalDesignContent
        .taxonomy('faq_taxonomy')
        .toObservable()
        .pipe(map((x) => x.taxonomy.terms));

      const glsaTaxonomyObservable = this.glsaContent
        .taxonomy('faq_taxonomy')
        .toObservable()
        .pipe(map((x) => x.taxonomy.terms));

      const singleTaxonomyArray = this.getSingleTaxonomyArray(digitalDesignTaxonomyObservable, glsaTaxonomyObservable);
      singleTaxonomyArray.subscribe(this.taxonomySubject);
    }

    return this.taxonomySubject.asObservable();
  }

  getSingleTaxonomyArray(
    digitalDesignTaxonomyObservable: Observable<TaxonomyTerms[]>,
    glsaTaxonomyObservable: Observable<TaxonomyTerms[]>
  ) {
    const joined = forkJoin({
      digitalDesign: digitalDesignTaxonomyObservable,
      glsa: glsaTaxonomyObservable,
    });

    const singleTaxonomyArray = joined.pipe(
      map((combined) => {
        const digitalDesignTerms = combined.digitalDesign.slice().map((term) => {
          return {
            ...term,
            codenames: { digitalDesign: term.codename, glsa: '' },
          };
        });

        let allTerms = digitalDesignTerms;

        const glsaTerms = combined.glsa.slice().map((term) => {
          return {
            ...term,
            codenames: { digitalDesign: '', glsa: term.codename },
          };
        });

        allTerms = digitalDesignTerms.concat(glsaTerms);
        const allTermsSorted = allTerms.sort((a, b) => a.name.localeCompare(b.name));

        const allTermsSortedDistinct = allTermsSorted.reduce(
          (
            accumulator: {
              codename: string;
              codenames: { digitalDesign: string; glsa: string };
              name: string;
              terms: any[];
            }[],
            current
          ) => {
            const existingValue = accumulator.find((a) => a.name === current.name);
            if (existingValue) {
              if (current.codenames.digitalDesign !== '') {
                existingValue.codenames.digitalDesign = current.codenames.digitalDesign;
              }
              if (current.codenames.glsa !== '') {
                existingValue.codenames.glsa = current.codenames.glsa;
              }
            } else {
              accumulator.push(current);
            }
            return accumulator;
          },
          []
        );

        return allTermsSortedDistinct;
      })
    );
    return singleTaxonomyArray;
  }

  getItems(taxonomyFilter?: string) {
    const digitalDesignItemsTypes = this.digitalDesignContent.items().types(['faq']);
    const digitalDesignItemsFiltered = taxonomyFilter
      ? digitalDesignItemsTypes.containsFilter('elements.faq_taxonomy', [taxonomyFilter])
      : digitalDesignItemsTypes;
    const digitalDesignItemsObservable: Observable<ItemResponses.ListContentItemsResponse<
      ContentItem
    >> = digitalDesignItemsFiltered.elementsParameter(['base__title', 'url_slug', 'faq_taxonomy']).toObservable();

    const glsaItemsTypes = this.glsaContent.items().types(['faq']);
    const glsaItemsFiltered = taxonomyFilter
      ? glsaItemsTypes.containsFilter('elements.faq_taxonomy', [taxonomyFilter])
      : glsaItemsTypes;
    const glsaItemsObservable: Observable<ItemResponses.ListContentItemsResponse<
      ContentItem
    >> = glsaItemsFiltered.elementsParameter(['base__title', 'url_slug', 'faq_taxonomy']).toObservable();

    const joined: Observable<{
      digitalDesign: ItemResponses.ListContentItemsResponse<ContentItem>;
      glsa: ItemResponses.ListContentItemsResponse<ContentItem>;
    }> = forkJoin({
      digitalDesign: digitalDesignItemsObservable,
      glsa: glsaItemsObservable,
    });

    const singleItemsArray = joined.pipe(
      map(
        (x: {
          digitalDesign: ItemResponses.ListContentItemsResponse<ContentItem>;
          glsa: ItemResponses.ListContentItemsResponse<ContentItem>;
        }) => {
          const digitalDesignItems = x.digitalDesign.items;
          const glsaItems = x.glsa.items;
          const allItems = digitalDesignItems.concat(glsaItems);
          return allItems;
        }
      )
    );

    const singleItemsArraySorted = singleItemsArray.pipe(
      map((x) => x.sort((a, b) => a.base__title.value.localeCompare(b.base__title.value)))
    );

    return singleItemsArraySorted;
  }
}
