import parse from 'html-react-parser';

export default function (data) {
  const result = data.map((item) => {
    item.base__body.resolveHtml();
    return parse(item.base__body.resolvedData.html);
  });

  const updatedResult = data.map((item, index) => ({
    ...item,
    base__body: { value: result[index++] },
  }));

  return updatedResult;
}
