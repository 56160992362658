import React, { useState, useEffect, useCallback, Dispatch, FC, SetStateAction } from 'react';
import { Checkbox, Radio, FormControlLabel } from '@material-ui/core';
import { Row, Col } from 'react-flexbox-grid';
import type SearchCriteriaModel from './Utilities/SearchCriteriaModel';
import SearchExclusion, { SearchExclusionDisplayName } from './Utilities/SearchExclusion';
import { initializeCountByCategories, countCategories } from './Utilities/SearchResultsEndpoint';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const allSearchExclusions = Object.values(SearchExclusion);

type SearchExclusionsAsObject = {
  [key in SearchExclusion]?: boolean | undefined;
};

const initializeAllExcluded: () => SearchExclusionsAsObject = () =>
  allSearchExclusions.reduce((newSearchExclusions, exclusion) => {
    newSearchExclusions[exclusion] = true;
    return newSearchExclusions;
  }, {} as SearchExclusionsAsObject);

function exclusionsChanged(searchCriteria: SearchCriteriaModel, searchExclusions: SearchExclusionsAsObject) {
  const newExcludes = Object.keys(searchExclusions).filter(
    (key) => searchExclusions[key as SearchExclusion]
  ) as SearchExclusion[];
  const isExcludesChanged =
    !newExcludes.every((newExclude) => searchCriteria.excludes.includes(newExclude)) ||
    !searchCriteria.excludes.every((oldExclude) => newExcludes.includes(oldExclude));

  return { newExcludes, isExcludesChanged };
}

export type PropsType = {
  mobileShow: boolean;
  searchCriteria: SearchCriteriaModel;
  setSearchCriteria: Dispatch<SetStateAction<SearchCriteriaModel>>;
};

const AdvancedSearchOptions: FC<PropsType> = ({ mobileShow, searchCriteria, setSearchCriteria }) => {
  const [startDate, setStartDate] = useState(searchCriteria.startDate);
  const [endDate, setEndDate] = useState(searchCriteria.endDate);
  const [startDateDayjs, setStartDateDayjs] = useState(searchCriteria.startDate ? dayjs(searchCriteria.startDate) : null);
  const [endDateDayjs, setEndDateDayjs] = useState(searchCriteria.endDate ? dayjs(searchCriteria.endDate) : null);
  const [limitDateCheckbox, setLimitDateCheckbox] = useState(startDate.length > 1 || endDate.length > 1);
  const [showCategories, setShowCategories] = useState(searchCriteria.showCategories || searchCriteria.excludes.length > 0);
  const [countByCategory, setCountByCategory] = useState(initializeCountByCategories());
  const [orderByMostRelevant, setOrderByMostRelevant] = useState(!!searchCriteria.orderByMostRelevant);
  const [searchExclusions, setSearchExclusions] = useState<SearchExclusionsAsObject>(
    searchCriteria.excludes.reduce((newSearchExclusions, exclusion) => {
      newSearchExclusions[exclusion] = true;
      return newSearchExclusions;
    }, {} as SearchExclusionsAsObject)
  );
  const allCategories = searchCriteria.excludes.length === 0;

  const excludeAll = useCallback(() => {
    const newSearchExclusions = initializeAllExcluded();

    setSearchExclusions(newSearchExclusions);
  }, []);

  const includeAll = useCallback(() => setSearchExclusions({}), []);

  const resetFilters = useCallback(() => {
    setLimitDateCheckbox(false);
    setOrderByMostRelevant(false);
    setShowCategories(false);
  }, []);

  useEffect(() => {
    if (!showCategories) {
      includeAll();
    }
  }, [showCategories]);

  useEffect(() => {
    const newSearchCriteria = { ...searchCriteria };
    if (limitDateCheckbox) {
      newSearchCriteria.startDate = startDate;
      newSearchCriteria.endDate = endDate;
    } else {
      newSearchCriteria.startDate = '';
      newSearchCriteria.endDate = '';
      setStartDateDayjs(searchCriteria.startDate ? dayjs(searchCriteria.startDate) : null);
      setEndDateDayjs(searchCriteria.endDate ? dayjs(searchCriteria.endDate) : null);
      setStartDate('');
      setEndDate('');
    }

    const { newExcludes, isExcludesChanged } = exclusionsChanged(searchCriteria, searchExclusions);
    newSearchCriteria.excludes = newExcludes;
    newSearchCriteria.orderByMostRelevant = orderByMostRelevant ? true : undefined;

    // We set showCategories here but a searchCriteria refresh is intentionally NOT triggered below
    newSearchCriteria.showCategories = showCategories ? true : undefined;

    if (
      newSearchCriteria.startDate !== searchCriteria.startDate ||
      newSearchCriteria.endDate !== searchCriteria.endDate ||
      isExcludesChanged ||
      newSearchCriteria.orderByMostRelevant !== searchCriteria.orderByMostRelevant
    ) {
      setSearchCriteria(newSearchCriteria);
    }
  }, [startDate, endDate, limitDateCheckbox, showCategories, searchExclusions, orderByMostRelevant]);

  useEffect(() => {
    if (showCategories) {
      const subscription = countCategories(searchCriteria).subscribe((countByCategoryResult) => {
        setCountByCategory(countByCategoryResult);
      });

      return () => subscription.unsubscribe();
    }
  }, [searchCriteria, showCategories]);

  return (
    <>
      <button
        className={
          mobileShow ? 'small-bottom-margin primary-button mobile-show' : 'small-bottom-margin primary-button mobile-hide'
        }
        data-cy="clearFiltersButton"
        onClick={() => resetFilters()}
      >
        Clear filters
      </button>
      <div
        id={mobileShow ? 'OrderSearchOptionsMobile' : 'OrderSearchOptions'}
        className={mobileShow ? 'search grey-box mobile-show' : 'search grey-box mobile-hide'}
      >
        <h4>Order By</h4>
        <div data-cy="orderByContainer">
          <div>
            <FormControlLabel
              id="orderByMostRecentFirstRadioLabel"
              control={
                <Radio
                  id="orderByMostRecentFirstRadio"
                  inputProps={{ 'data-cy': 'orderByMostRecentFirstRadio' } as any}
                  checked={!orderByMostRelevant}
                  onChange={() => setOrderByMostRelevant(false)}
                  name="orderByRadios"
                  color="default"
                />
              }
              label="Most Recent"
            />
            <FormControlLabel
              id="orderByMostRelevantFirstRadioLabel"
              control={
                <Radio
                  id="orderByMostRelevantFirstRadio"
                  inputProps={{ 'data-cy': 'orderByMostRelevantFirstRadio' } as any}
                  checked={orderByMostRelevant}
                  onChange={() => setOrderByMostRelevant(true)}
                  name="orderByRadios"
                  color="default"
                />
              }
              label="Most Relevant"
            />
          </div>
        </div>
      </div>
      <div
        id={mobileShow ? 'AdvancedSearchOptionsMobile' : 'AdvancedSearchOptions'}
        className={mobileShow ? 'search grey-box mobile-show' : 'search grey-box mobile-hide'}
      >
        <h4>Advanced Search Options</h4>
        <div>
          <FormControlLabel
            id="limitDateCheckboxLabel"
            control={
              <Checkbox
                id="limitDateCheckbox"
                inputProps={{ 'data-cy': 'limitDateCheckbox' } as any}
                checked={limitDateCheckbox}
                onChange={(e) => setLimitDateCheckbox(!!e.target.checked)}
                name="limitDateCheckbox"
                color="default"
              />
            }
            label="Between Specific Dates"
          />
        </div>
        {limitDateCheckbox ? (
          <>
            <div>Start Date</div>
            <DatePicker
              slotProps={{
                textField: {
                  'aria-label': 'Start Date',
                  inputProps: { 'data-cy': 'limitDateStartDate' },
                  id: 'limitDateStartDate',
                  name: 'limitDateStartDate',
                  variant: 'outlined',
                  margin: 'dense',
                  required: false,
                },
              }}
              onChange={(e) => {
                if (e) {
                  if (e.toString().trim() !== 'Invalid Date') {
                    setStartDate(e.format('YYYY-MM-DD') as string);
                  }
                }
                if (e === null) {
                  setStartDate('');
                }
              }}
              value={startDateDayjs}
            />

            <div>End Date</div>

            <DatePicker
              slotProps={{
                textField: {
                  'aria-label': 'End Date',
                  inputProps: { 'data-cy': 'limitDateEndDate' },
                  id: 'limitDateEndDate',
                  name: 'limitDateEndDate',
                  variant: 'outlined',
                  margin: 'dense',
                },
              }}
              onChange={(e) => {
                if (e) {
                  if (e.toString().trim() !== 'Invalid Date') {
                    setEndDate(e?.format('YYYY-MM-DD') as string);
                  }
                }
                if (e === null) {
                  setEndDate('');
                }
              }}
              value={endDateDayjs}
            />
          </>
        ) : null}
        <div>
          <FormControlLabel
            id="showCategoriesCheckboxLabel"
            control={
              <Checkbox
                id="showCategoriesCheckbox"
                inputProps={{ 'data-cy': 'showCategoriesCheckbox' } as any}
                checked={showCategories}
                onChange={(e) => setShowCategories(!!e.target.checked)}
                name="showCategoriesCheckbox"
                color="default"
              />
            }
            label="Categories"
          />
        </div>
        {showCategories ? (
          <div data-cy="categoriesContainer">
            <Row>
              <Col xs></Col>
              <Col xs>Include</Col>
            </Row>

            <Row>
              <Col xs className="inline-vertical-center">
                <span className="default-body-font">All</span>
                {countByCategory['ALL'] > 0 ? (
                  <a onClick={includeAll} href="#" className="float-right" data-cy={`category-count-link-for-ALL`}>
                    ({countByCategory['ALL']})
                  </a>
                ) : null}
              </Col>
              <Col xs>
                <Checkbox
                  id="allCategoriesCheckbox"
                  inputProps={{ 'data-cy': 'allCategoriesCheckbox' } as any}
                  checked={allCategories}
                  onChange={(e) => (e.target.checked ? includeAll() : excludeAll())}
                  name="allCategoriesCheckbox"
                  color="default"
                />
              </Col>
            </Row>

            {allSearchExclusions.map((exclusion, index) => (
              <Row key={index}>
                <Col xs className="inline-vertical-center">
                  <span className="default-body-font">{SearchExclusionDisplayName[exclusion]}</span>
                  {countByCategory[exclusion] > 0 ? (
                    <a
                      onClick={() => setSearchExclusions({ ...initializeAllExcluded(), [exclusion]: undefined })}
                      href="#"
                      className="float-right"
                      data-cy={`category-count-link-for-${exclusion}`}
                    >
                      ({countByCategory[exclusion]})
                    </a>
                  ) : null}
                </Col>
                <Col xs>
                  <Checkbox
                    id={`category_${exclusion}_checkbox`}
                    inputProps={{ 'data-cy': `category_${exclusion}_checkbox` } as any}
                    checked={!searchExclusions[exclusion]}
                    onChange={(e) =>
                      setSearchExclusions({ ...searchExclusions, [exclusion]: e.target.checked ? undefined : true })
                    }
                    name={`category_${exclusion}_checkbox`}
                    color="default"
                  />
                </Col>
              </Row>
            ))}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default AdvancedSearchOptions;
