import React from 'react';
import { Link } from 'react-router-dom';
import parse, { DomElement } from 'html-react-parser';

export const guidanceRef = '/guidance/uhj';

export function ParseHtml(body: string, linkData: { [x: string]: any }) {
  const options = {
    replace: (element: DomElement) => {
      if (element.attribs !== undefined && element.name === 'a' && linkData) {
        let url = linkData[element.attribs['data-item-id']] as string;
        if (!url && element.attribs.href === guidanceRef) {
          url = element.attribs.href;
        }
        if (url) {
          return (
            <Link data-cy="parsed-link" to={url}>
              {element.children[0].data}
            </Link>
          );
        } else {
          return (
            <a target="_blank" rel="noreferrer" href={element.attribs.href}>
              {element.children[0].data}
            </a>
          );
        }
      }
    },
  };
  const x = parse(body, options);
  return x;
}
