import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';
import AuthService from "../../Login/AuthService";
import GlobalAPICall from "../../GlobalComponents/GlobalAPICall";
export interface PreviouslyEnrolledResponse {
  message: string;
}
export interface PreviouslyEnrolledPayload {
  GoogleReCaptchaResponse:string;
  EmailParameters:{
    State:string;
    FirstName:string;
    LastName:string;
    Title: string;
    MiddleName:string;
    NickName:string;
    Suffix:string;
    MailCareOf:string;
    MailAddress?:{
      City?:string;
      StateId?:string;
      AddressLine1?:string;
      AddressLine2?:string;
      ZipCode?:string;
    },
    PhysicalCareOf:string;
    PhysicalAddress:{
      City:string;
      StateId:string;
      AddressLine1:string;
      AddressLine2:string;
      ZipCode:string;
    },
    Races:string[],
    Ethnicities:string[],
    OtherEthnicities:string[],
    PKIdn:number;
    Gender:string;
    BirthDate:string;
    HomePhone:string;
    WorkPhone:string;
    CellPhone:string;
    FaxPhone:string;
    Email:string;
    AlternateEmail:string;
    CountryOfBirth:string;
    CityOfBirth:string;
    StateOfBirth:string;
    IsEnrolledPreviously: boolean,
    EnrolledInCity:string;
    EnrolledInState:string;
    EnrolledInCountry:string;
    EnrolledName:string;
    Comments:string;
    PhysicalAddressAppliesTo:string;
    MailingAddressAppliesTo:string;
  }
}
export default class PreviouslyEnrolledService {
  constructor(
    private readonly authService: AuthService,
    private readonly apiCaller: typeof GlobalAPICall,
    private readonly apiUrl: string
  ) {
    this.savePreviouslyEnrolledForm = this.savePreviouslyEnrolledForm.bind(this);
  }

  savePreviouslyEnrolledForm(data: PreviouslyEnrolledPayload, token: string): Observable<PreviouslyEnrolledResponse> {
    const observable = this.apiCaller
      .postObservable(process.env.REACT_APP_API_URL + `/api/Registration/PreviouslyEnrolledEmail`,
        data,
        {
        headers: { Authorization: 'bearer ' + token },
      })
      .pipe(
        map((x: any) => {
          return x.data as PreviouslyEnrolledResponse;
        })
      );
    return observable;
  }
}
