import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import BoundariedRoute from '../Error/BoundariedRoute';
import List from './List';
import DisplayCoverLetter from './DisplayCoverLetter';
import withContext from '../ContextAPI/Context_HOC';
import NoMatch from '../Routers/NoMatch';
import * as Sentry from '@sentry/browser';

class DuplicateUrlSlugError extends Error {
  constructor(message) {
    super(message);
    this.name = 'DuplicateUrlSlugError';
  }
}

class LetterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      letterlist: [],
      routerlist: [],
      lettertitle: '',
      displayDrawer: 'none',
      wrongPath: false,
    };
  }
  messagesDataLoad() {
    this.subscription = this.props.context.digitalDesignContent
      .items()
      .type('cover_letter')
      .containsFilter('elements.cover_letter', [this.props.match.params.id])
      .elementsParameter(['base__title', 'url_slug', 'base__datepublished', 'cover_letter'])
      .orderParameter('elements.base__datepublished', 'desc')
      .toObservable()
      .subscribe(async (response) => {
        if (!response.isEmpty) {
          let arrayOfLettersWithDuplicateUrls = [];
          try {
            arrayOfLettersWithDuplicateUrls = response.items.reduce((accumulatedItems, currentReduceItem, _, allItems) => {
              const itemsWithSameUrlSlug = allItems.filter((currentFilterItem) => {
                const currentFilterItemPublishedYear = parseInt(
                  currentFilterItem.base__datepublished.rawData.value.substring(0, 4)
                );
                const currentFilterItemSlugOne = currentFilterItem.url_slug.value + currentFilterItemPublishedYear;

                const currentReduceItemPublishedYear = parseInt(
                  currentReduceItem.base__datepublished.rawData.value.substring(0, 4)
                );
                const currentReduceItemSlugOne = currentReduceItem.url_slug.value + currentReduceItemPublishedYear;

                return currentFilterItemSlugOne === currentReduceItemSlugOne;
              });
              if (itemsWithSameUrlSlug.length > 1) {
                accumulatedItems.push(currentReduceItem);
              }
              return accumulatedItems;
            }, []);

            const lettersWithDuplicateUrlsLength = arrayOfLettersWithDuplicateUrls.length;
            if (arrayOfLettersWithDuplicateUrls.length > 0) {
              const firstFiveNames = arrayOfLettersWithDuplicateUrls
                .slice(0, 5)
                .map((item) => `"${item.system.name}"`)
                .join();
              const errorMessage = `At least ${lettersWithDuplicateUrlsLength} content items have duplicate URL slugs. First five content item names: ${firstFiveNames}`;
              const e = new DuplicateUrlSlugError(errorMessage);
              const contexts = arrayOfLettersWithDuplicateUrls.reduce((accumulator, item) => {
                accumulator[item.system.codename] = item.system;
                accumulator[item.system.codename].base__title = item.base__title.value;
                accumulator[item.system.codename].base__datepublished = item.base__datepublished.rawData.value;
                return accumulator;
              }, {});
              Sentry.captureException(e, { contexts });
              console.error(e);
              console.log(errorMessage, arrayOfLettersWithDuplicateUrls);
            }
          } catch (e) {
            console.error(e);
          }

          this.setState({
            lettertitle: response.firstItem.cover_letter.value[0].name,
            letterlist: response.items,
            routerlist: (
              <BoundariedRoute
                path={`/guidance/${this.props.match.params.id}/:coverid`}
                render={(props) => <DisplayCoverLetter {...props} contenttype={'letter'} />}
              />
            ),
          });
        } else {
          this.setState({ wrongPath: true });
        }
      });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.messagesDataLoad();
    }
  }
  componentDidMount() {
    this.messagesDataLoad();
  }
  componentWillUnmount() {
    this.subscription.unsubscribe();
  }
  render() {
    return (
      <div>
        <Switch>
          {this.state.wrongPath === true ? (
            <BoundariedRoute component={NoMatch} />
          ) : (
            <BoundariedRoute
              exact
              path={`/guidance/${this.props.match.params.id}`}
              render={(props) => (
                <List
                  {...props}
                  letterdata={this.state.letterlist}
                  lettertype={this.props.match.params.id}
                  lettertitle={this.state.lettertitle}
                />
              )}
            />
          )}
          {this.state.routerlist}
        </Switch>
      </div>
    );
  }
}
export default withContext(LetterList);
