import React, { Component } from 'react';
import { dateStringToDisplayDate } from '../Utilities/UtilityFunctions';
import { switchMap } from 'rxjs/operators';
import Fund from '../assets/img/OCSHeartSign.png';

class Thermometer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state.goalAmount = 0;
    this.state.goalAmountDisplay = 'Loading...';
    this.state.asOfDateDisplay = 'Loading...';
    this.state.goalYearDisplay = '';
    this.state.animationTime = 3000;
    this.state.numberPrefix = '$';
    this.state.numberSuffix = '';
    this.state.displayAmount = 0;
    this.state.widthOfNumbers = 100;
    this.state.tickMarkSegementCount = 4;
    this.state.tickHeight = 40;
    this.state.numberStartY = 6;
    this.state.thermTopHeight = 13;
    this.state.thermBottomHeight = 51;
    this.state.mercuryHeightOffset = 10;
    this.state.tooltipOffset = 15;
    this.state.heightOfBody = this.state.tickMarkSegementCount * this.state.tickHeight;
    this.state.heightOfBottom = this.state.heightOfBody + this.state.thermTopHeight;
    this.state.goalThermometerHeight = this.state.heightOfBody + this.state.thermTopHeight + this.state.thermBottomHeight;
    this.state.topOfThermBodyMercury = 0;
    this.state.heightOfThermBodyMercury = 0;
    this.state.topOfThermTooltip = this.state.heightOfBody + this.state.thermTopHeight - this.state.tooltipOffset;

    const resolution2x = window.devicePixelRatio === 2;
    if (resolution2x) {
      this.state.glassTopImg = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/61826/glassTop2x.png';
      this.state.glassBodyImg = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/61826/glassBody2x.png';
      this.state.redVerticalImg = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/61826/redVertical2x.png';
      this.state.thermBodyForeImg = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/61826/tickShine2x.png';
      this.state.glassBottomImg = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/61826/glassBottom2x.png';
      this.state.tootipPointImg = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/61826/tooltipPoint2x.png';
      this.state.tooltipMiddleImg = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/61826/tooltipMiddle2x.png';
      this.state.tooltipButtImg = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/61826/tooltipButt2x.png';
    } else {
      this.state.glassTopImg = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/61826/glassTop.png';
      this.state.glassBodyImg = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/61826/glassBody.png';
      this.state.redVerticalImg = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/61826/redVertical.png';
      this.state.thermBodyForeImg = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/61826/tickShine.png';
      this.state.glassBottomImg = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/61826/glassBottom.png';
      this.state.tootipPointImg = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/61826/tooltipPoint.png';
      this.state.tooltipMiddleImg = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/61826/tooltipMiddle.png';
      this.state.tooltipButtImg = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/61826/tooltipButt.png';
    }

    this.animateThermometer = this.animateThermometer.bind(this);
  }

  componentDidMount() {
    const animateThermometer = this.animateThermometer;

    this.fundStatusSubscription = this.props.readyObservable
      .pipe(switchMap(() => this.props.fundStatusProvider.getStatus()))
      .subscribe({
        next: async (fundStatus) => {
          const goalAmountInput = fundStatus.goalAmount;
          const currentAmountInput = fundStatus.currentAmount;
          const asOfDate = fundStatus.asOfDate;
          const goalYear = fundStatus.goalYear;
          const startTime = new Date().getTime();
          requestAnimationFrame(() => {
            animateThermometer(startTime, goalAmountInput, currentAmountInput, asOfDate, goalYear);
          });
        },
        error: async (error) => console.log('Error getting fund status: ', error),
      });
  }

  componentWillUnmount() {
    this.fundStatusSubscription.unsubscribe();
  }

  commaSeparateNumber(val) {
    let roundedVal = Math.round(val);
    while (/(\d+)(\d{3})/.test(roundedVal.toString())) {
      roundedVal = roundedVal.toString().replace(/(\d+)(\d{3})/, '$1,$2');
    }
    return this.state.numberPrefix + roundedVal + this.state.numberSuffix;
  }

  buildThermNumbers() {
    const thermNumbers = [];
    const countPerTick = this.state.goalAmount / this.state.tickMarkSegementCount;
    for (let i = 0; i < this.state.tickMarkSegementCount; i++) {
      const yPos = this.state.tickHeight * i + this.state.numberStartY;
      const style = {
        top: yPos + 'px',
        width: this.state.widthOfNumbers + 'px',
      };
      const dollarText = this.commaSeparateNumber(this.state.goalAmount - countPerTick * i);
      const uniqueKey = 'thermNumbers_' + i;
      thermNumbers.push(
        <div key={uniqueKey} className="therm-number" style={style}>
          {dollarText}
        </div>
      );
    }

    return thermNumbers;
  }

  animateThermometer(startTime, goalAmountInput, currentAmountInput, asOfDateInput, goalYearInput) {
    const currentTime = new Date().getTime();
    const calculatedAnimationProgress = (currentTime - startTime) / this.state.animationTime;
    const animationProgress = calculatedAnimationProgress > 1 ? 1 : calculatedAnimationProgress;
    const percentageComplete = currentAmountInput / goalAmountInput;
    const distanceToRise = (this.state.heightOfBody * percentageComplete + 1) * animationProgress;
    const mercuryHeight = this.state.mercuryHeightOffset + distanceToRise;
    const newMercuryTop = this.state.heightOfBody + this.state.thermTopHeight - distanceToRise;
    const newTopOfThermTooltip = this.state.heightOfBody + this.state.thermTopHeight - this.state.tooltipOffset - distanceToRise;

    let newDisplayAmount = currentAmountInput * animationProgress;
    if (newDisplayAmount >= currentAmountInput) {
      newDisplayAmount = currentAmountInput;
    }

    let newState = {
      topOfThermBodyMercury: newMercuryTop,
      heightOfThermBodyMercury: mercuryHeight,
      topOfThermTooltip: newTopOfThermTooltip,
      displayAmount: newDisplayAmount,
    };

    if (this.state.goalAmount === 0) {
      newState.goalAmount = goalAmountInput;
      const goalAmountToDisplay = Math.round(goalAmountInput / 1000000);
      newState.goalAmountDisplay = '$' + goalAmountToDisplay + 'M';
      newState.asOfDateDisplay = dateStringToDisplayDate(asOfDateInput);
      newState.goalYearDisplay = goalYearInput;
    }

    this.setState(newState);

    if (newDisplayAmount < currentAmountInput) {
      requestAnimationFrame(() => {
        this.animateThermometer(startTime, goalAmountInput, currentAmountInput, asOfDateInput, goalYearInput);
      });
    }
  }

  render() {
    const styles = {
      goalThermometerContainer: {
        paddingBottom: '10px',
      },
      goalThermometer: {
        zIndex: 1,
        height: this.state.goalThermometerHeight,
        opacity: 1,
      },
      themGraphics: {
        left: this.state.widthOfNumbers,
      },
      thermBodyBg: {
        height: this.state.heightOfBody,
      },
      thermBodyMercury: {
        top: this.state.topOfThermBodyMercury,
        height: this.state.heightOfThermBodyMercury,
      },
      thermBodyFore: {
        height: this.state.heightOfBody,
        backgroundImage: 'url(' + this.state.thermBodyForeImg + ')',
      },
      thermBottom: {
        top: this.state.heightOfBottom,
      },
      thermTooltip: {
        top: this.state.topOfThermTooltip,
      },
      thermToolTipMiddle: {
        backgroundImage: 'url(' + this.state.tooltipMiddleImg + ')',
      },
      fundHeart: {
        height: '30px',
        margin: '5px 0px 10px 0px',
      },
    };

    return (
      <div className="center">
        <img src={Fund} style={styles.fundHeart} alt="Fund Heart Logo" />
        <h4>National Bahá’í Fund</h4>
        {this.state.goalYearDisplay ? (
          <h4>
            {this.state.goalYearDisplay} Fund Goal: {this.state.goalAmountDisplay}
          </h4>
        ) : null}
        <p>As of {this.state.asOfDateDisplay}</p>
        <div className="thermobit">
          <div id="goal-thermometer-container" style={styles.goalThermometerContainer}>
            <div id="goal-thermometer" style={styles.goalThermometer}>
              <div id="therm-numbers">{this.buildThermNumbers()}</div>
              <div id="therm-graphics" style={styles.themGraphics}>
                <img alt="thermometer top" id="therm-top" src={this.state.glassTopImg} />
                <img alt="thermometer body" id="therm-body-bg" style={styles.thermBodyBg} src={this.state.glassBodyImg} />
                <img
                  alt="thermometer mercury"
                  id="therm-body-mercury"
                  style={styles.thermBodyMercury}
                  src={this.state.redVerticalImg}
                />
                <div id="therm-body-fore" style={styles.thermBodyFore}></div>
                <img alt="thermometer bottom" id="therm-bottom" style={styles.thermBottom} src={this.state.glassBottomImg} />
                <div id="therm-tooltip" style={styles.thermTooltip}>
                  <img alt="thermometer left tip" className="tip-left" src={this.state.tootipPointImg} />
                  <div className="tip-middle" style={styles.thermToolTipMiddle}>
                    <p>{this.commaSeparateNumber(this.state.displayAmount)}</p>
                  </div>
                  <img alt="thermometer right tip" className="tip-right" src={this.state.tooltipButtImg} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Thermometer;
