import React from 'react';
import withContext from '../ContextAPI/Context_HOC';
import { createInstance, OptimizelyProvider, enums } from '@optimizely/react-sdk';
import ProfileFunction from '../Profiles/Utilities/ProfileFunction';
import prodDataFile from './optimizely_Prod.json';
import testDataFile from './optimizely_Test.json';
import devDataFile from './optimizely_Dev.json';

const preLoadFileEnvString = process.env.REACT_APP_OPTIMIZELY_PRELOAD_FILE_ENV;

const dateFileToPreLoad =
  preLoadFileEnvString === 'prod' ? prodDataFile : preLoadFileEnvString === 'test' ? testDataFile : devDataFile;

const optimizely = createInstance({
  sdkKey: 'does not matter because urlTemplate does not contain %s',
  logLevel: enums.LOG_LEVEL.WARNING,
  eventBatchSize: 10,
  eventFlushInterval: 1000,
  datafileOptions: {
    autoUpdate: true,
    updateInterval: 600000, // 10 minutes in milliseconds
    urlTemplate: process.env.REACT_APP_OPTIMIZELY_FILE_URL,
  },
  datafile: dateFileToPreLoad, // These settings are used until
});

const profilefn: { getprofileinfo: (token: string) => { bid: string; region: string } } = new ProfileFunction();

function FeatureFlagProvider(props: { children: any; context: { authService: { getDecodedToken: () => string } } }) {
  const decodedToken = props.context.authService.getDecodedToken();
  const profileInfo = decodedToken
    ? profilefn.getprofileinfo(props.context.authService.getDecodedToken())
    : { bid: null, region: null };
  const userId = profileInfo.bid ?? 'unknown';
  const region = profileInfo.region ?? 'unknown';
  return (
    <OptimizelyProvider optimizely={optimizely} user={{ id: userId, attributes: { region: region } }}>
      {props.children}
    </OptimizelyProvider>
  );
}

export default withContext(FeatureFlagProvider);
