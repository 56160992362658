import React, { Fragment, ReactNode } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { createTheme, ThemeProvider } from '@mui/material';
import { Autocomplete, Checkbox, FormControl, FormLabel, Radio, RadioGroup, TextareaAutosize, TextField } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { DatePicker } from '@mui/x-date-pickers';
import type { RadioGroupProps } from '@mui/material/RadioGroup/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CustomFieldFormType } from './enums/custom-field-form-type';
import CreateRules from './function/create-rules';
import type { CustomDateFieldType, CustomField, CustomTextFieldType } from './type/custom-field';
import { CustomErrorMessage } from './use-custom-hook-form-error-alert';
import FormGroup from '@material-ui/core/FormGroup';
import dayjs from 'dayjs';

const theme = createTheme({
  components: {
    // Name of the component
    MuiTextField: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          input: {
            padding: '10.5px 14px',
            height: '1.1876em',
          },
          'label:not(.MuiInputLabel-shrink)': {
            lineHeight: '0.9em',
            marginTop: '-2px',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& > div': { margin: 0 },

          'label:not(.MuiInputLabel-shrink)': {
            lineHeight: '0.9em',
            marginTop: '-2px',
          },
        },
        input: {
          padding: '10.5px 14px',
          height: '1.1876em !important',
        },
        inputRoot: {
          padding: '3px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          svg: {
            fontSize: '1.0835rem',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root': {
            fontFamily: '"Open Sans", sans-serif',
            fontSize: '0.95rem',
            fontWeight: 300,
          },
        },
      },
    },
  },
});

export interface UseCustomHookFormProps<T extends FieldValues, CustomFieldValues> {
  customFieldsArray: CustomField<CustomFieldValues>[];
  initialValues: T;
}
export default function useCustomHookForm<T extends FieldValues, CustomFieldValues>(
  props: UseCustomHookFormProps<T, CustomFieldValues>
): [(React.JSX.Element | [React.JSX.Element])[], UseFormReturn<T, unknown>] {
  const useFormObject = useForm<T>({
    values: props.initialValues,
    mode: 'all',
  });
  const {
    control,
    clearErrors,
    formState: { errors },
  } = useFormObject;
  const createField: (customField: CustomField<CustomFieldValues>) => React.JSX.Element | [React.JSX.Element] = (
    customField: CustomField<CustomFieldValues>
  ) => {
    const rules = CreateRules(customField);

    const formControlNameForErrorValidation = customField.formControlName as any;
    if (customField.hidden) {
      return <Fragment key={customField.formControlName}></Fragment>;
    }
    if (customField.type === CustomFieldFormType.GroupLabel) {
      return <Fragment key={customField.formControlName}></Fragment>;
    }
    if (customField.type === CustomFieldFormType.Textarea) {
      return (
        <>
          <Controller
            control={control}
            key={customField.formControlName}
            name={customField.formControlName as never}
            render={({ field }) => {
              return (
                <TextareaAutosize
                  data-cy={customField.formControlName}
                  minRows={3}
                  {...field}
                  className={customField.cssClassName ? customField.cssClassName : ''}
                />
              );
            }}
          />
        </>
      );
    }
    if (customField.type === CustomFieldFormType.Checkbox) {
      return (
        <>
          <Controller
            control={control}
            key={customField.formControlName}
            name={customField.formControlName as never}
            render={({ field }) => {
              return (
                <FormGroup className={customField.cssClassName ? customField.cssClassName : ''}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        data-cy={customField.formControlName}
                        size="small"
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                    label={customField.label}
                  />
                </FormGroup>
              );
            }}
          />
        </>
      );
    }
    if (customField.type === CustomFieldFormType.JSXElement) {
      const display = () => customField.jsxElement;
      return (
        <div
          key={customField.formControlName}
          data-cy={customField.formControlName}
          className={customField.cssClassName ? customField.cssClassName : ''}
        >
          {(display() as unknown) as ReactNode}
        </div>
      ) as React.JSX.Element;
    }
    if (customField.type === CustomFieldFormType.Radio) {
      return (
        <Controller
          control={control}
          key={customField.formControlName}
          name={customField.formControlName as never}
          render={({ field }) => {
            const radioFieldProps: RadioGroupProps = {
              id: customField.formId ? customField.formId + customField.formControlName : customField.formControlName,
              ...customField.overrideFields?.radio,
            };

            return (
              <FormControl variant="outlined" className={customField.cssClassName ? customField.cssClassName : ''}>
                <FormLabel id={`${customField.formControlName}-label`}>
                  {customField.label + (customField.validators?.required ? '' : '')}
                </FormLabel>
                <RadioGroup {...radioFieldProps} {...field} data-cy={customField.formControlName}>
                  {customField.radio?.options.map((radioField) => {
                    return (
                      <FormControlLabel
                        control={
                          <Radio
                            data-cy={customField.formControlName + radioField.id + 'radio'}
                            required={Boolean(customField.validators?.required)}
                            size="small"
                            inputProps={{
                              ...({ 'data-cy': customField.formControlName + radioField.id } as any),
                            }}
                          />
                        }
                        key={radioField.id}
                        label={radioField.label}
                        value={radioField.value}
                      />
                    );
                  })}
                </RadioGroup>
                <FormHelperText>{customField.help}</FormHelperText>
              </FormControl>
            );
          }}
          rules={rules}
        />
      );
    }
    if (customField.type === CustomFieldFormType.Date) {
      const validateDate = (field: { value: any }) => {
        let isValid = false;
        if (field.value) {
          if (dayjs(field.value).isValid()) {
            const minDate = customField.overrideFields?.date?.minDate;
            const maxDate = customField.overrideFields?.date?.maxDate;
            if (minDate || maxDate) {
              if (minDate) {
                if (field.value.isBefore(minDate)) {
                  isValid = false;
                } else {
                  isValid = true;
                }
              }
              if (maxDate) {
                if (field.value.isAfter(maxDate)) {
                  isValid = false;
                } else {
                  isValid = true;
                }
              }
            } else {
              isValid = true;
            }
          }
        }
        if (!isValid) {
          control.setError(formControlNameForErrorValidation, {
            type: 'manual',
            message: customField.overrideFields?.date?.errorMessage
              ? customField.overrideFields?.date?.errorMessage
              : 'Invalid Date',
          });
        } else {
          clearErrors(formControlNameForErrorValidation);
        }
      };
      return (
        <Controller
          control={control}
          key={customField.formControlName}
          name={customField.formControlName as never}
          render={({ field }) => {
            const dateFieldProps: CustomDateFieldType = {
              label: customField.label,
              slotProps: {
                textField: {
                  helperText: customField.help,
                },
              },
              ...customField.overrideFields?.date,
            };
            return (
              <>
                <div className={customField.cssClassName ? customField.cssClassName : ''}>
                  <div className="row">
                    <div className="col-md-12" style={{ padding: '0', margin: '0' }}>
                      <DatePicker
                        {...dateFieldProps}
                        {...field}
                        sx={{ width: '100%' }}
                        data-cy={customField.formControlName}
                        disableFuture
                        onChange={(v, c) => {
                          field.onChange(v, c);
                          validateDate({ value: v });
                        }}
                        slotProps={{
                          textField: {
                            error: errors[customField.formControlName] ? true : false,
                            helperText: errors[customField.formControlName]
                              ? CustomErrorMessage(errors[customField.formControlName]?.type as any, customField as any, errors)
                              : customField.help,
                            onKeyDown: () => validateDate(field),
                            required: Boolean(customField.validators?.required),
                            inputProps: {
                              'data-cy': customField.formControlName,
                            },
                          },
                        }}
                        value={field.value}
                      />
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        />
      );
    }
    if (customField.type === CustomFieldFormType.Select) {
      return (
        <Controller
          control={control}
          key={customField.formControlName}
          name={customField.formControlName as never}
          render={({ field, ...props }) => {
            const propsAutocomplete = { ...props };
            // @ts-ignore
            delete propsAutocomplete['fieldState'];
            // @ts-ignore
            delete propsAutocomplete['formState'];
            return (
              <Autocomplete
                {...propsAutocomplete}
                className={customField.cssClassName ? customField.cssClassName : ''}
                fullWidth
                options={customField.select?.options as any[]}
                getOptionLabel={(option: any) => {
                  if (typeof option === 'object') {
                    return option.label;
                  }
                  return option as string;
                }}
                renderInput={(params) => {
                  const textFieldProps: CustomTextFieldType = {
                    type: customField.type,
                    id: customField.formId ? customField.formId + customField.formControlName : customField.formControlName,
                    variant: 'outlined',
                    label: customField.label,
                    name: customField.formControlName,
                    placeholder: customField.placeholder as string,
                    helperText: errors[customField.formControlName]
                      ? CustomErrorMessage(errors[customField.formControlName]?.type as any, customField as any)
                      : customField.help,

                    ...(customField.overrideFields?.textField as any),
                  };
                  return (
                    <TextField
                      {...params}
                      {...textFieldProps}
                      className="w-100"
                      data-cy={`${customField.formControlName}`}
                      error={Boolean(errors[customField.formControlName])}
                      required={Boolean(customField.validators?.required)}
                    />
                  );
                }}
                onChange={(e, data) => {
                  if (data) {
                    if (data.value) {
                      field.onChange(data.value);
                    } else {
                      field.onChange(data);
                    }
                  } else {
                    field.onChange(data);
                  }
                }}
              />
            );
          }}
          rules={rules}
        />
      );
    }
    if (customField.type === CustomFieldFormType.Mask) {
      return (
        <Controller
          control={control}
          key={customField.formControlName}
          name={customField.formControlName as never}
          render={({ field }) => {
            const textFieldProps: CustomTextFieldType = {
              type: customField.type,
              id: customField.formId ? customField.formId + customField.formControlName : customField.formControlName,
              variant: 'outlined',
              label: customField.label,
              name: customField.formControlName,
              placeholder: customField.placeholder as string,
              helperText: errors[customField.formControlName]
                ? CustomErrorMessage(errors[customField.formControlName]?.type as any, customField as any)
                : customField.help,
              ...(customField.overrideFields?.textField as any),
            };
            return (
              <FormControl variant="outlined" className={customField.cssClassName ? customField.cssClassName : ''}>
                <TextField
                  {...field}
                  {...textFieldProps}
                  error={errors[customField.formControlName] ? true : false}
                  data-cy={customField.formControlName}
                  InputProps={{
                    inputComponent: customField.inputComponent as any,
                  }}
                  variant="outlined"
                  required={Boolean(customField.validators?.required)}
                />
              </FormControl>
            );
          }}
          rules={rules}
        />
      );
    }
    return (
      <Controller
        control={control}
        key={customField.formControlName}
        name={customField.formControlName as never}
        render={({ field }) => {
          const textFieldProps: CustomTextFieldType = {
            type: customField.type,
            id: customField.formId ? customField.formId + customField.formControlName : customField.formControlName,
            variant: 'outlined',
            label: customField.label,
            name: customField.formControlName,
            placeholder: customField.placeholder as string,
            helperText: errors[customField.formControlName]
              ? CustomErrorMessage(errors[customField.formControlName]?.type as any, customField as any)
              : customField.help,

            ...(customField.overrideFields?.textField as any),
          };
          return (
            <div className={customField.cssClassName ? customField.cssClassName : ''}>
              <TextField
                data-cy={customField.formControlName + 'text-field'}
                className="w-100"
                required={Boolean(customField.validators?.required)}
                {...field}
                {...textFieldProps}
                error={errors[customField.formControlName] ? true : false}
                inputProps={{
                  'data-cy': customField.formControlName,
                }}
              />
            </div>
          );
        }}
        rules={rules}
      />
    );
  };

  const wrapInTheme: (customField: CustomField<CustomFieldValues>) => React.JSX.Element | [React.JSX.Element] = (
    customField: CustomField<CustomFieldValues>
  ) => {
    return (
      <ThemeProvider key={customField.formControlName} theme={theme}>
        {createField(customField)}
      </ThemeProvider>
    );
  };

  return [props.customFieldsArray.map((f) => wrapInTheme(f)), useFormObject];
}
