import React, { ElementType, MouseEventHandler } from 'react';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { withStyles } from '@material-ui/core/styles';
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions';
import { createTheme } from '@material-ui/core/styles';
const styles = () => ({
  root: {
    flexShrink: 0,
  },
});

const TablePaginationActions: ElementType<TablePaginationActionsProps & { classes: { root: string } }> = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
  classes,
}) => {
  const theme = createTheme();

  const handleFirstPageButtonClick: MouseEventHandler<HTMLButtonElement> = (ev) => {
    onPageChange(ev, 0);
  };
  const handleBackButtonClick: MouseEventHandler<HTMLButtonElement> = (ev) => {
    onPageChange(ev, page - 1);
  };
  const handleNextButtonClick: MouseEventHandler<HTMLButtonElement> = (ev) => {
    onPageChange(ev, page + 1);
  };
  const handleLastPageButtonClick: MouseEventHandler<HTMLButtonElement> = (ev) => {
    onPageChange(ev, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="First Page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="Next Page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
};

export default withStyles(styles)(TablePaginationActions);
