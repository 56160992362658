import { useEffect } from 'react';
import OneSignal from 'react-onesignal';

export default function OneSignalHandler() {
  useEffect(() => {
    const startOneSignal = async () => {
      try {
        const pathScope = window.location.pathname.match(/\/.*(?=\/.*?)/)?.[0];
        await OneSignal.init({
          appId: process.env.REACT_APP_ONE_SIGNAL_ID
            ? process.env.REACT_APP_ONE_SIGNAL_ID
            : 'e7745571-3afb-48b3-baf7-1b2f80de79dd',
          allowLocalhostAsSecureOrigin: true,
          serviceWorkerParam: { scope: `${pathScope}/` },
          serviceWorkerPath: `${pathScope}/OneSignalSDKWorker.js`,
          autoRegister: true,
          autoResubscribe: true,
        });
        OneSignal.Notifications.addEventListener('click', (obj) => {
          obj; // If browser is not open and the user clicked the notification, the notification arrives here
        });
        OneSignal.Notifications.addEventListener('foregroundWillDisplay', (obj) => {
          obj; // if notification send, the notification arrives here:
        });
        OneSignal.Notifications.addEventListener('permissionChange', (hasPermissions) => {
          if (hasPermissions) {
            // Have permissions
          } else {
            // Don't have permissions
          }
        });
        OneSignal.Notifications.addEventListener('permissionPromptDisplay', () => {
          // Permission modal from One signal is being displayed
        });
      } catch (error) {
        console.error(error);
      }
    };
    void startOneSignal().then().catch();
  }, []);
  return null;
}
