import React, { Component } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import WritingComponent from './WritingComponent';
import SpeechSynthesis from '../PWA/speech-synthesis/SpeechSynthesis';

class WritingDisplayCall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: [],
      showPlayer: false,
    };
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    this.subscription = this.props.context.digitalDesignContent
      .items()
      .type('writing')
      .equalsFilter('elements.url_slug', id)
      .orderParameter('elements.base__datepublished', 'desc')
      .toObservable()
      .subscribe((response) => {
        if (!response.isEmpty) {
          let display = <WritingComponent details={response.items[0]} />;
          this.setState({ display: display });
          this.setState({ showPlayer: true });
        } else this.setState({ display: <WritingComponent details={''} /> });
      });
  }
  componentWillUnmount() {
    this.subscription.unsubscribe();
  }
  render() {
    return (
      <>
        {this.state.showPlayer && window.speechSynthesis && (
          <SpeechSynthesis text={document.getElementById('writerDisplay')?.textContent} />
        )}
        <div id="writerDisplay">{this.state.display}</div>
      </>
    );
  }
}
export default withContext(WritingDisplayCall);
