import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';
import SafeStringifyAxiosError from '../../Utilities/SafeStringifyAxiosError';

export default function GetSecurityQuestionsWithTemporaryCodeEndpoint(token: string, temporaryCode: string) {
  return GlobalAPICall.get(`${process.env.REACT_APP_AUTH_API_URL}/api/registration/step3/${temporaryCode}`, {
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      if (err.response.status === 400) {
        return Promise.reject({
          status: err.response.status,
          message: SafeStringifyAxiosError(err),
        });
      } else {
        return Promise.reject({
          status: err.response.status,
          message: 'Our services are currently unavailable. Please come back later.',
        });
      }
    });
}
