import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import FlickrGallery from './FlickrGallery';

class NatCon2019 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenId: null,
    };
  }
  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }
  setHiddenId(id) {
    this.setState({
      hiddenId: id,
    });
  }
  render() {
    return (
      <div>
        <FlickrGallery apiKey="13d8e319087a91a92dc7d59d1b44490b" setId="72157666776565481" />
        <div>
          <h1>National Convention 2016</h1>
          <p>
            Photos from the Convention available at{' '}
            <a
              href="https://www.flickr.com/photos/bahaius/collections/72157667710272635/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Flickr
            </a>
            .
          </p>
          <p>
            Below are stories on the Convention from <em>The American Bahá’í</em>
          </p>
        </div>
        <hr />
        <Row className="thumbnails">
          <Col sm={12} md={8}>
            <img alt="voting" src="https://c2.staticflickr.com/2/1503/26460475610_d228ebd7c5_q.jpg" />
            <h4 className="small-top-padding">National Assembly elected ‘with a deep sense of reverence’</h4>
            <p>
              With all 171 delegates to the 108th Bahá’í National Convention voting either in person or by absentee ballot, the
              elected members of the National Spiritual Assembly of the Bahá’ís of the United States to serve through Riḍván 2017
              are listed alphabetically:
            </p>
            <ul className="plain-list">
              <li>Muin Afnani</li>
              <li>Fariba Aghdasi</li>
              <li>Kenneth E. Bowers</li>
              <li>Juana C. Conrad</li>
              <li>S. Valerie Dana</li>
              <li>Robert C. Henderson</li>
              <li>Jacqueline Left Hand Bull</li>
              <li>Kevin Trotter</li>
              <li>David F. Young</li>
            </ul>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  Election results were announced to delegates Saturday afternoon, April 30, 2016. When the newly elected Assembly
                  was presented that evening to the Convention, it was announced that one member was unable to participate in a
                  planned organizational meeting, so officers for the year are to be elected at a later meeting. In the meantime,
                  the previous year’s officers are to continue temporarily in those functions: Muin Afnani as chair, Jacqueline
                  Left Hand Bull as vice chair, Kenneth E. Bowers as secretary, S. Valerie Dana as deputy secretary, and Juana C.
                  Conrad as treasurer.
                </p>
                <p>
                  Voting had taken place that morning “with a deep sense of reverence and a profound sense of the nobility and
                  integrity of the process,” in the words of David Young, serving as chair of the Convention, held in Foundation
                  Hall at the Bahá’í House of Worship.
                </p>
                <p>
                  After instructions were read on procedures and the qualifications of Assembly members, prayers were said and
                  delegates each recorded their votes for nine people over the course of several minutes. Members of the
                  Continental Board of Counselors observed from the sides and back of the hall, and all attendees, including more
                  than 100 Convention guests, observed complete silence.
                </p>
                <p>
                  When the delegates were called in alphabetical order by the Convention secretary, Muin Afnani, they proceeded
                  one by one to the front of the hall and dropped a sealed ballot into a clear plastic box. Betsy Ayankoya, chief
                  teller, placed ballots that had been mailed in by delegates unable to attend in person. The procession revealed
                  a kaleidoscope of humanity: women and men of a wide variety of age, race and ethnicity, affirming the
                  world-embracing values of the Faith.
                </p>
                <p>
                  Joy beamed from many faces after the task was completed, perhaps most pronounced among those participating as
                  delegates for the first time this year. A standing ovation followed the chair’s announcement that 100 percent of
                  the delegates had voted.
                </p>
                <p>
                  As often happens, the delegates broke out into song. But even when the chair declared a half-hour break, they
                  weren’t finished; Van Gilmer, the House of Worship music director, sat at the piano and played in a lively
                  gospel rhythm. In less than a minute, a few dozen Convention-goers gathered around and were swaying, waving and
                  singing “We Have Come to Sing Praises” and “We Are Soldiers.”
                </p>
              </div>
            )}
            <hr />

            <img alt="Counselor speaking" src="https://c2.staticflickr.com/2/1447/26688146851_aab0fca941_q.jpg" />
            <h4 className="small-top-padding">Counselors conclude Convention with call to unity through the guidance</h4>
            <p>
              Our Plans evolve, our knowledge builds, our strategies are shaped by reflection. But at the conclusion of the 108th
              Bahá’í National Convention, the three attending members of the Continental Board of Counselors reminded us of the
              constants in the work of the Bahá’ís: Bahá’u’lláh’s vision of establishing the kingdom of God on earth, and His
              Covenant — with the guidance flowing from it — that brings us into unity.
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  Counselor Sonlla Heern drew on the image of a bridge that connects the current state of the world with the World
                  Order we are building. Bahá’u’lláh’s Revelation has built this bridge, she noted.
                </p>
                <p>
                  Bridges, she noted, have to have both static and dynamic forces working in their favor to stay strong; “If all
                  parts of the system are static the first earthquake that comes knocks the bridge over and it fails.”
                </p>
                <p>
                  Similarly, while the Covenant and the Administrative Order provide a constant core for this bridge that has been
                  built, the Bahá’ís have to widen and reinforce it through our processes of learning so that “more and more
                  people are welcomed across this bridge and it becomes better able to weather the storms that will come.”
                </p>
                <p>Some of the dynamic forces strengthening that bridge, she said, were evident in the Convention itself:</p>
                <ul>
                  <li>
                    The participation by every delegate in the voting for the National Spiritual Assembly, and the mindfulness
                    reflected in every single vote being valid.
                  </li>
                  <li>
                    Consultation by the delegates that showed how “all these various forces” that challenge our efforts “are
                    recognized and grappled with … [as well as showing] a sense of urgency.”
                  </li>
                  <li>
                    “The relationship between the three institutions that act within this space”; specifically the Institution of
                    the Counselors, the National Spiritual Assembly, and the Convention itself. “We are all rotating around that
                    fixed center of the Covenant.”
                  </li>
                </ul>
                <p>
                  “This bridge to the new world is well under construction and it’s not only structurally sound, it’s beautiful.”
                </p>
                <p>
                  Counselor Farah Guchani-Rosenberg harked to the state of the American Bahá’í community 100 years ago when its
                  mission was first emerging in the Tablets of the Divine Plan — and the way the system of Bahá’u’lláh has
                  unfolded in that century. “Can you picture how this will be in another 100 years, 200 years?” she asked.
                </p>
                <p>
                  She particularly praised how often the concepts of a learning community emerged in delegates’ discussions over
                  the four days of the Convention: “We are trying that, we are learning that, we are struggling with that.”
                </p>
                <p>
                  Through this long learning process, she noted, “it has taken us less than 100 years to move from opening
                  continents to opening neighborhoods. … Under one Covenant, we are learning.”
                </p>
                <p>
                  She also pointed out the clarity with which the Universal House of Justice has laid out, in its recent messages,
                  the essential elements of what we need to do in the next five years: the proven strategies for engaging people
                  in the processes of growth, as well as the roles of systematic action, informed analysis and keen insight —
                  “This convention is demonstrating all of those.”
                </p>
                <p>
                  “We have built capacity to study the guidance … not so much waiting for another institution to come from
                  somewhere else … to tell us what’s in this guidance,” she said.
                </p>
                <p>
                  And new elements of action have been introduced in light of our experience in the past two decades. Groups of
                  clusters are now encouraged to share learning, so that if capacities aren’t built strongly in one cluster “we
                  can ask to receive that assistance from more experienced clusters.”
                </p>
                <p>
                  Closer to home, people experienced in similar capacities — for instance, teaching children’s classes, tutoring
                  study circles, working with youth or junior youth, opening neighborhoods and so forth — are encouraged to form
                  collaborative teams to “study together and reflect and explore our own realities, then look at the guidance and
                  reflect how we can apply that guidance.”
                </p>
                <p>
                  The foundation is kept stable, she said, with the House’s reminder that “it is a spiritual enterprise … so
                  organic, but ultimately it is also combined with guidance.”
                </p>
                <p>
                  And as earlier believers have brought us to this point by following the guidance of the Central Figures, the
                  Guardian and the House of Justice, we can hasten progress by working with “absolute trust in the process,
                  absolute trust in the guidance we are given.”
                </p>
                <p>
                  Counselor Mark Sisson — characterizing our task ahead as “a cherished gift” from the Supreme Institution —
                  recounted a story that had been told at December’s conference of the Continental Boards of Counselors in the
                  Holy Land.
                </p>
                <p>
                  In Indonesia, the most populous Muslim country on earth, two Bahá’í youths some years ago were arrested and
                  jailed for holding a children’s class and a junior youth group, at a time when Bahá’í activity was suppressed.
                </p>
                <p>
                  During that imprisonment the Bahá’ís “were at a loss, really, on what to do,” he recounted. “Through
                  consultation and reflection they began to realize that they had to think differently about who they were.
                </p>
                <p>
                  “They realized that they were protagonists of change in their country, that they were at the tip of the arrow of
                  a transformation that would sweep the country. They were empowered by nothing less than the Revelation of
                  Bahá’u’lláh itself to exact that change” through the means provided by the Universal House of Justice.
                </p>
                <p>
                  “Suddenly their thinking had shifted … from a mindset of being a victim to being the means by which an entire
                  nation could be transformed,” Sisson said. “This shift in thinking enabled them to engage at the highest level.”
                </p>
                <p>
                  And after a series of conversations that eventually reached the national minister of religion, the government
                  changed its stance. Nearly two years ago the Bahá’í Faith gained the status of a religion whose believers are
                  officially allowed to practice and profess their faith freely.
                </p>
                <p>
                  “What struck me was this idea of a change of how we perceive ourselves,” Sisson said. Bahá’ís are increasingly
                  seeing ourselves as agents of real change in the world, walking a path of service that we can share “with
                  everyone who’s interested in transformation.”
                </p>
                <p>
                  A passage in the March 26, 2016, message from the Universal House of Justice to the Bahá’ís of the United States
                  and Canada emphasizes our capacity to channel the power for change: “The movement of your clusters to the
                  farthest frontiers of learning will usher in the time anticipated by Shoghi Effendi … when the communities you
                  build will directly combat and eventually eradicate the forces of corruption, of moral laxity, and of ingrained
                  prejudice. …”
                </p>
                <p>
                  With that image of moral combat, he said, “I almost think we’re in Fort Tabarsi and the army of the Shah is out
                  there waiting. … We’re on our horses, we’re on our steeds. We have the sword and the shield to accomplish this
                  task … We are certain of victory but there will be a battle for sure. It will be a battle of the heart, a battle
                  of the mind.”
                </p>
                <p>
                  The Covenant is the greatest force we can draw on to remain in unity. Even in a space such as the Convention,
                  “where hearts can be expressed and divergent views can be expressed and the clash of ideas can occur,” with
                  firmness in the Covenant of Bahá’u’lláh “we are able to come to agreement — we are transformed into a single
                  organism.”
                </p>
                <p>
                  Natural forces allow schools of fish or flocks of birds to move as one when they need to, he noted. But “because
                  we are thinking souls with free will, it’s not going to happen by default but by design.”
                </p>
                <p>
                  And the more we align our thoughts and actions with the guidance for the Plan, the more insights we draw from
                  it, the better we can as a community channel “the spiritual forces released through the unfoldment of the Divine
                  Plan.”
                </p>
                <p>
                  The last paragraph of the Riḍván message is emphatic in calling us to this moral battle: “to spare no effort in
                  applying all you have learned and every God-given ability and skill you possess to advance the Divine Plan into
                  its next essential stage.”
                </p>
                <p>
                  “We don’t know exactly our greatest potentials until we are thrown into the battlefield itself,” Sisson said.
                  How do we mirror the message of Bahá’u’lláh so closely that people see His face when they look at us? “It seems
                  that the House of Justice has given us a way, shown us the path. The only thing that is left for us to do is to
                  walk it.”
                </p>
              </div>
            )}
            <hr />
            <img alt="Secretary report" src="https://c2.staticflickr.com/2/1514/26654917911_4f626b7a92_q.jpg" />
            <h4 className="small-top-padding">Secretary shares vision of emerging capacities and future growth</h4>
            <p>
              Looking back on how far our national community has advanced in the past 20 years, there is every reason to be
              confident the Bahá’ís of the United States will find the “courage and the resolve and the resources necessary to
              take our community to the next level” in the upcoming Five Year Plan, said Kenneth Bowers, secretary of the National
              Spiritual Assembly, in his report to the 108th Bahá’í National Convention on Friday, April 29.
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  It’s impossible, he said, to express in words the Assembly’s joy as it witnessed the progress in the community’s
                  capacity in recent months, especially the rapid rise to 625 in the number of clusters operating programs of
                  growth. In the past two years alone, an average of one program of growth emerged every two days.
                </p>
                <p>
                  Now, by 2021, our aim is to intensify those efforts so that at least 375 of those clusters — where programs of
                  growth are at a relatively basic stage — pass a second milestone of development, where a range of core
                  activities are reinforcing each other, being coordinated by cluster agencies, and serving around 100 or more
                  people in and out of the Bahá’í community.
                </p>
                <p>The good news is, we are the best equipped we have ever been to accomplish it.</p>
                <p>
                  Heartened at the Convention itself by “the extent to which the delegates have chosen to express their experience
                  through the stories they have told of the transformation that has taken place at the level of the cluster, the
                  community and the individual,” he said, “that is really what it’s all about.”
                </p>
                <p>
                  He related a story that had been shared with the Assembly: about a young immigrant in California whose
                  experience followed “an entire arc” from discovery to heart-deep commitment in the past five years. That journey
                  went through participation in a junior youth group, studying all seven courses in the basic Ruhi sequence, and
                  beginning to facilitate core activities. Still, he hadn’t taken steps to enroll as a Bahá’í, but the believers
                  talked with him and confirmed a hunch: he already felt he was a Bahá’í and wasn’t aware there was an enrollment
                  process. Responding in a letter, he wrote of how he had changed as a person, learned about dedication and
                  sacrifice, and accepted the teachings wholeheartedly. “Love is what has kept me around and together. That is my
                  declaration,” he wrote.
                </p>
                <p>
                  No doubt, Bowers said, many people have walked a similar journey in the past five years, and the next five might
                  yield “thousands upon thousands upon thousands of stories” just as touching.
                </p>
                <p>
                  He recapped some of the steps in clusters’ development that the Universal House of Justice set out in its Dec.
                  29, 2015, letter, which outlines features of the Five Year Plan of 2016–2021.
                </p>
                <p>
                  The first milestone in a program of growth is passed when residents in a cluster begin acquiring two essential
                  capacities through study of the institute courses: initiating core activities and inviting others to join. That
                  pattern of action intensifies with love, participation by an increasing number of people, and an attitude that
                  all are learning together.
                </p>
                <p>
                  Even such simple concepts haven’t been easy to translate to action in many places. But when a “certain mastery
                  of that process” was achieved in enough clusters over the first few years of the previous Plan, the learning
                  could be shared — and those basic seeds of growth were planted in hundreds more clusters.
                </p>
                <p>
                  “Perhaps the story of the next plan will be similar to that,” Bowers noted. “There’s a certain amount of
                  foundation work we will have to do, but again there will no doubt be an acceleration” in the number of clusters
                  advancing to the second milestone.
                </p>
                <p>
                  One new capacity to develop will be getting the most out of groupings of clusters, especially positioning
                  more-advanced clusters to be “reservoirs” for a systematic exchange of learning and resources with “those who
                  are not as far along in the path.”
                </p>
                <p>
                  Another will be further releasing the potential of youth — not just Bahá’ís, but also others who join us in
                  building community.
                </p>
                <p>
                  The most exciting development at this transition between Plans is the emergence of clusters where “so many
                  people who are indigenous to the neighborhoods where they are participating are agents of their own
                  transformation.” Many of those in the vanguard, he said, are young people.
                </p>
                <p>
                  This is one of the characteristics of a third milestone in development, which will most often be passed by
                  clusters where a hundred or more core activities are serving a thousand or more people.
                </p>
                <p>
                  “This is a development of historic proportions,” he said. While there have been many instances around the world
                  of large numbers of people being attracted to the teachings of Bahá’u’lláh, “one of the great questions has been
                  balancing expansion and consolidation” so that new believers have a stable foundation and strength is built in
                  communities.
                </p>
                <p>
                  As witness the young Californian whose story is told above, many people will get so involved starting with their
                  first contact with the Teachings, by whatever avenue, that “by the time they become Bahá’ís, they’re already
                  consolidated.”
                </p>
                <p>
                  “Where teaching is concerned,” he said, “what this means is that we’re creating the maximum environment for
                  welcoming people into the Cause of God.”
                </p>
                <p>
                  Such progress also has implications for Local Spiritual Assemblies. Especially in more-advanced clusters, at
                  least half of the people participating in community-building activities are from outside the enrolled Bahá’í
                  community.
                </p>
                <p>
                  Last summer when the National Assembly met with selected Local Assemblies and asked how this new stage of
                  development affected them, it learned that “really the change has been a change of heart, a change of how we
                  think of the way we minister to the needs of those around us” — taking the entire population into account, not
                  just the Bahá’ís.
                </p>
                <p>
                  The enterprise will require considerable encouragement and nurturing, utilizing Feasts and Holy Day observances
                  as “opportunities to reinforce the high ideals of the community,” and learning to embrace all community members
                  — new and veteran — as having a share in building the World Order being shaped progressively by Bahá’u’lláh.
                </p>
                <p>
                  Young people especially have opportunities and responsibilities to deal forthrightly with issues of race, class
                  and other social divides — and as genuine friendships are formed, to walk together in paths of service.
                </p>
                <p>
                  “No wonder that the House of Justice characterized the labors of the next five years as herculean,” Bowers said
                  — even as it assured us that regular and rapid small steps in the right direction can ensure progress.
                </p>
                <p>
                  “Our aim … is to master this process, this unfolding story of growth over the next five years,” he said. “In
                  2021 we will know very well how to advance the process of entry by troops, there’s no question about that.”
                </p>
                <p>
                  The greatest challenges for the National Assembly are to understand what is happening in the clusters’ processes
                  of growth, foster the needed capacities, and refine the process of gathering, generating and disseminating new
                  knowledge about that process so that every cluster in our country and beyond can benefit.
                </p>
                <p>
                  At the same time, through its new Learning Desk, the National Assembly “is facilitating the capacity of people
                  in the trenches, in the clusters, to describe their own learning.” That will help strengthen activity in the
                  clusters themselves as well as informing the Regional Councils and the National Assembly.
                </p>
                <p>
                  Through other agencies, the Assembly is learning how neighborhood activity can stimulate beneficial social
                  action and give Bahá’í teachings a stronger presence in public discourses. One experiment the national Bahá’í
                  offices are taking part in is an online project aimed at attracting young people into spiritual conversation
                  about being active to help their neighborhoods. This small-scale exercise can feed learning about any future
                  efforts to utilize the Internet and social media.
                </p>
                <p>
                  In another effort to learn about honing Bahá’í communications, guests at the new Welcome Center at the House of
                  Worship have been surveyed over the past year to assess not only whether people are comprehending messages about
                  the Faith and its aims, but also whether they understand that everyone is welcome to join in our
                  community-building enterprise.
                </p>
                <p>
                  While warning against getting too attached to numbers, Bowers indulged in a brief speculation on the effect of
                  intensifying hundreds of programs of growth.
                </p>
                <p>
                  About 37,000 people are involved in core activities right now, he said. If 375 more clusters are brought to the
                  second milestone, meeting the goal for 2021, that could mean an increase of roughly 100 participants per cluster
                  — adding around 37,000. Then if perhaps 40 of the current 249 second-milestone clusters advance to the third
                  milestone, that in itself could mean a similar number of people added to the community-building process
                  nationwide.
                </p>
                <p>That scenario would result in “triple our numbers [of core activity participants] in five years,” he said.</p>
                <p>
                  Of course our community’s growth will not likely be on such a steady line of ascent. “Bahá’u’lláh does whatever
                  He does” to influence people’s receptivity, Bowers noted.
                </p>
                <p>
                  But if our community’s knowledge, understanding and capacity continue to increase, he said, that degree of
                  growth could continue to build through the next series of Plans that is to culminate in 2044, perhaps into the
                  millions.
                </p>
                <p>
                  Whenever that phenomenon happens, “we enter an entirely new era in the development of the Faith. … It will be
                  impossible not to have an influence on America.”
                </p>
                <p>
                  How exciting it will be, he said, when the growing influence of Bahá’u’lláh’s teachings is an effective antidote
                  to the fear, anger, aggression, corruption and feelings of helplessness that claim so much of people’s attention
                  nowadays.
                </p>
                <p>
                  He reminded the Convention of Shoghi Effendi’s admonition that “Until the public sees in the Bahá’í Community a
                  true pattern, in action, of something better than it already has, it will not respond to the Faith in large
                  numbers.” Through this process, the Bahá’í community is building that “something better” to give humanity
                  purpose, vision and hope.
                </p>
                <p>
                  “We feel unworthy — and we could say that it’s essential to feel unworthy,” Bowers said, because only by relying
                  on the assistance of the Blessed Beauty can we achieve victory in the Divine Plan.
                </p>
                <p>
                  During a question-and-answer session after the secretary’s presentation, delegates took opportunities to discuss
                  such topics as refining the National Assembly’s communications with the Bahá’í community, effective mentoring of
                  young people, patience with slow growth in the numbers of enrolled Bahá’ís as we master the process of community
                  building, and the evolving roles of permanent and seasonal Bahá’í schools.
                </p>
              </div>
            )}
            <hr />
            <img alt="Treasurer’s report" src="https://c2.staticflickr.com/2/1573/26654918461_960ac30be7_q.jpg" />
            <h4 className="small-top-padding">Treasurer accompanies joyous announcement with accomplishments, challenges</h4>
            <p>
              Arguably, the National Spiritual Assembly treasurer had the most joyous task at the 108th Bahá’í National
              Convention: announcing days before the end of the 2015–2016 administrative year that contributions to the National
              Bahá’í Fund had already exceeded the $34 million annual goal by more than $400,000.
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  “Isn’t this exciting? Honestly, the money is still coming in,” enthused Treasurer Juana C. Conrad, pointing out
                  one of the more poignant contributions — a blue change purse carrying a child’s donation of coins.
                </p>
                <p>
                  It was the second straight year the national community had won the annual goal, even after it had been increased
                  from the previous year’s mark. All through the past year, the level of contributions was consistently 10 percent
                  higher than the previous year.
                </p>
                <p>
                  “There are no words to describe the love the National Assembly has for this American Bahá’í community,” she
                  said. The increasing consciousness of the material needs of the Faith demonstrates “a deeper understanding of
                  the nature of the Bahá’í Funds.”
                </p>
                <p>
                  Even after she announced the National Assembly’s decision to keep the goal at $34 million for the upcoming year,
                  several delegates in a question-and-answer session after the treasurer’s presentation discussed the possibility
                  of increasing that goal.
                </p>
                <p>
                  Love, Conrad noted, is the root of all growth in the Faith, whether in the teaching field or in the Fund. “This
                  treasurer’s report, then, is a love story — of the love that the institutions of the Faith have for each other
                  and the commitment and the love that the friends have for Bahá’u’lláh, His Faith and His institutions.”
                </p>
                <p>The treasurer went over some of the achievements of the Five Year Plan that just ended:</p>
                <ul>
                  <li>A 14 percent increase in the number of individual contributors to the National Fund. </li>
                  <li>
                    Growth in usage of the Online Contribution System (OCS), “an invaluable tool in making it easier for more
                    friends to contribute and to do so regularly.”{' '}
                  </li>
                  <li>
                    The more recent Mobile-Friendly Giving tool, which succeeded in attracting more young people to contribute to
                    the National Fund but also can boast that 48 percent of its users are 50 and older.
                  </li>
                  <li>
                    A solid 35 percent of contributions to the National Fund coming from Local Spiritual Assemblies in the past
                    year.
                  </li>
                  <li>
                    Participation by 580 Local Assemblies — over half of all U.S. Assemblies — in the Local Fund Online program,
                    which has enabled many believers to contribute online to their local Funds since its introduction only two
                    years ago.
                  </li>
                  <li>
                    An average 9 percent annual increase in the amount contributed to the National Fund over the past five years,
                    in part reflecting increased participation through OCS.
                  </li>
                  <li>
                    A 90 percent retention rate among National Fund contributors, “more than double the national average of 43
                    percent … for nonprofits. … Clearly, once the heart is connected to the life-blood of the Cause, the
                    connection is strong,” Conrad remarked.
                  </li>
                  <li>
                    Development of the Fund Empowerment Workshop program — which has reached more than 800 local treasurers — and
                    a webinar series, both to build capacity among Assemblies for stewardship of the Funds.
                  </li>
                  <li>
                    Introduction of the general deepening course “Lifeblood,” focusing on writings that encourage a life of
                    generosity and sacrifice; and another course, “Young, Broke and Spiritual,” designed to assist young people at
                    various life stages to manage their financial affairs in light of the Bahá’í teachings.
                  </li>
                </ul>
                <p>
                  About 15 percent of the National Assembly’s budget is devoted to growth at the cluster level. A share of that
                  money, she said, goes to stipends for young people who have relocated or traveled to offer service. Touchingly,
                  Conrad said, one person who works for a Regional Council told her earlier at the Convention that “more and more
                  of the young people … are coming to her and saying, ‘I want to give up my stipend; I can make it in other
                  ways.’”
                </p>
                <p>
                  This throws into relief the massive number of volunteer hours that aren’t reflected in the budget, she said; “we
                  would accomplish very little without the huge network of volunteers that do so much of the work for the Faith.”
                </p>
                <p>
                  Another major benefit of the National Fund, she noted, is raising public awareness of the plight of the Bahá’ís
                  in Iran. The Assembly is supporting the Not A Crime campaign, whose high-profile murals and other publicity
                  around the world are bringing attention to Iran’s pattern of denying higher education to young Bahá’ís and
                  certain other groups.
                </p>
                <p>
                  Over and above the National Fund, she noted, U.S. Bahá’ís have contributed nearly $19 million to the Chilean
                  Temple Fund and $3 million to the International Temples Fund, an “amazing” demonstration of unity of purpose
                  around the world.
                </p>
                <p>
                  Closer to home, to provide land for future dependencies of the House of Worship in Wilmette, the National
                  Assembly purchased three properties near the Temple during the preceding Plan. “What great accomplishments all
                  of us have made!”
                </p>
                <p>
                  The Planned Giving Program continues to attract interest, with inquiries alone increasing by about 30 percent
                  this past year and a total of $3.5 million received this past year through various planned giving instruments.
                </p>
                <p>
                  We do face challenges ahead, Conrad noted, including the continuing obligation to pay for the expenses of
                  community-building activities that involve increasing numbers of friends of the Faith — who, by definition,
                  cannot contribute to the Bahá’í Fund unless they are enrolled as Bahá’ís.
                </p>
                <p>
                  Also, it is undeniable that the U.S. Bahá’í population has been aging, and believers 60 and older — with less
                  time on this earth — are those contributing the “lion’s share of the National Fund.”
                </p>
                <p>
                  A focus on accompanying youth will be essential in building “this fundamental aspect of Bahá’í identity,” just
                  as it is in building community.
                </p>
                <p>
                  “These challenges will be overcome in time through continual action and reflection … and greater understanding
                  of Bahá’u’lláh’s vision for humanity,” she said. Indeed, both teaching the Faith and contributing to the Fund
                  “with great love and sacrifice” are things that “we are assured in the Writings will draw down blessings and
                  will allow us to fulfill our divinely ordained obligation to humanity.”
                </p>
                <p>
                  She cited the guidance of Shoghi Effendi that there is “no way out of our current problems except through the
                  way of God” and that Bahá’ís in the Western world must sacrifice their comfort to some extent to win victory for
                  the Cause. “Once the friends start out to win the goals set in the Plan, they will find the Divine confirmation
                  sustaining them and hastening its consummation.”
                </p>
                <p>
                  Conrad concluded, then, “Let us sacrifice the good things God has provided us … thereby drawing down His good
                  pleasure.”
                </p>
              </div>
            )}
            <hr />
            <img alt="Huququllah presentation" src="https://c2.staticflickr.com/2/1670/26683984441_bc45be28f0_q.jpg" />
            <h4 className="small-top-padding">Ḥuqúqu’lláh board members emphasize spiritual essence of law</h4>
            <p>
              It was the spiritual essence of the law of the Right of God that members of the Board of Trustees of Ḥuqúqu’lláh in
              the United States strove to convey during their presentation April 30, 2016, at the 108th Bahá’í National
              Convention.
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  Robert Malouf, Mashiyyat Rahmani and Shoori Samandari Loghmanee represented the board at the Convention and
                  conveyed greetings from fellow members Danita Brown, Shannon Javid, Manoocher Mofidi and Catherine E. Schmitz.
                  They read a letter from the board to the Counselors, the National Spiritual Assembly and the delegates — which
                  has become an annual gift to the Convention — and answered delegates’ questions about the institution of Ḥuqúq.
                </p>
                <p>
                  The letter and presentation both urged the Bahá’ís to study and internalize the guidance related to the law of
                  the Right of God, “the source of grace, abundance, and of all good”; and to take advantage of the availability
                  of Ḥuqúqu’lláh representatives nationwide to offer educational presentations.
                </p>
              </div>
            )}
            <hr />
            <img alt="Delegate speaking" src="https://c2.staticflickr.com/2/1461/26148916864_5e840bc53f_q.jpg" />
            <h4 className="small-top-padding">Focused consultation concludes with what’s been learned</h4>
            <p>
              On the final morning of the 108th U.S. Bahá’í National Convention, Sunday, May 1, the opportunity to raise diverse
              topics in open consultation led perfectly into focused consultation, under the topic of “A Period of Special
              Potency,” on what’s been learned in the past 20 years.
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>Among open consultation remarks:</p>
                <ul>
                  <li>
                    A Massachusetts delegate voiced appreciation for the work of the National Spiritual Assembly’s Office of
                    Public Affairs, detailing some of its areas of engagement. She also said the seeker response system works and
                    encouraged delegates to ask representatives how they can help.
                  </li>
                  <li>
                    A Florida delegates recommended that consultation on race unity be moved higher in the agenda of future
                    National Conventions.
                  </li>
                  <li>
                    An Iowa delegate urged that an effort be made to attract additional homefront pioneers to the Prairie States
                    and Northern Plains.
                  </li>
                  <li>
                    A delegate from New York, speaking to the previous evening’s consultation on race unity, urged that Shoghi
                    Effendi’s instructions to black and white Bahá’ís be equally heeded. She added, “If we dare to get on the
                    motorbike” — referring to a comment by Counselor Mark Sisson about getting a motorcycle but being afraid at
                    first to ride — “we will be astonished on how the Plan allows us to deal with this issue on the local level.”
                  </li>
                  <li>
                    An Arkansas delegate told how the dilemma of having a university with no Bahá’í students was solved by
                    starting a Bahá’í club on campus and asking students who want to serve their community to join.
                  </li>
                  <li>
                    A Virginia delegate shared how her community has made a conscious effort to explain the guidance in simple
                    terms stripped of acronyms and jargon, to lessen any feelings of estrangement among believers.
                  </li>
                  <li>
                    A delegate from California described a concerted effort by Spiritual Assemblies and cluster agencies to work
                    together to channel resources into expansion and consolidation efforts.
                  </li>
                  <li>
                    Responding to a request from a delegate to hear stories from Iran, National Assembly secretary Kenneth E.
                    Bowers related that persecution of Bahá’ís in Iran has spiked since the nuclear agreement was reached but
                    believers there have made many friends who now consider themselves Bahá’ís and participate in — even lead —
                    activities. He also said the transformation that’s seen in Iranian leaders of thought who participate in the
                    annual Friends of Persian Culture conference in Chicago has influenced how the National Assembly approached
                    its external affairs work.
                  </li>
                </ul>
                <p>
                  As “A Period of Special Potency” was taken up, questions informing the consultation included: What have we
                  learned in the past 20 years? What is meant by a spiritual enterprise? What is required of the three
                  protagonists? And what are the implications for our National Spiritual Assembly?
                </p>
                <p>Among comments:</p>
                <ul>
                  <li>
                    A California delegate urged Bahá’ís to consider pioneering on the home front to clusters aiming to pass the
                    second milestone of development or reinvigorate ones already at that point along the path.
                  </li>
                  <li>
                    A Missouri delegate said Bahá’ís have learned much about describing Bahá’u’lláh, His life and teachings and
                    can apply that same effort to banishing terms that divide Bahá’ís from friends and collaborators in the larger
                    community.
                  </li>
                  <li>
                    A North Carolina delegate urged that veterans of the Black Men’s Gathering be seen as a resource for learning
                    about how to invite people of color and provide a welcoming environment.
                  </li>
                  <li>
                    A delegate from Georgia marveled at the realization that his grandchildren have never been to a gathering
                    attended by only registered Bahá’ís. He also noted that to reach the number of people necessary to win the
                    goals of this Plan, clusters will need to operate in a continuous expansion mode. A delegate, he said, texted
                    her husband to say they need to engage in one or two more core activities a week during this Plan.
                  </li>
                  <li>
                    A fellow Georgia delegate shared that only through relationships with his African-American brothers and
                    sisters in the neighborhood that are closer than those with blood relatives that he has learned how powerful
                    the force of unity is.
                  </li>
                </ul>
                <p>
                  A “lightning round” of reflection followed. Delegates offered brief comments on how National Convention
                  functioned as well as what they’ll be reporting to their electoral units and putting into practice in their
                  lives.
                </p>
                <p>Topics included:</p>
                <ul>
                  <li>Relating to Bahá’ís the sacred nature of the election of the National Spiritual Assembly is vital.</li>
                  <li>Every effort should be made to lift unit conventions to this level.</li>
                  <li>
                    Delegates would benefit from having representatives of offices and agencies of the National Assembly available
                    during breaks in Convention to answer questions.
                  </li>
                  <li>A special moment in Convention was having delegates mention the names of people who have passed.</li>
                  <li>Comments made in the spirit of embrace lifted consultation to a new level.</li>
                  <li>Opening sessions with music performed or led by delegates further fostered a spiritual environment.</li>
                  <li>Lastly, a delegate averred, “This Convention has been more unified than I’ve ever seen.”</li>
                </ul>
              </div>
            )}
            <hr />
            <img alt="Delegate speaking" src="https://c2.staticflickr.com/2/1446/26147260053_ba32e26df4_q.jpg" />
            <h4 className="small-top-padding">‘Frankness and sincerity’ mark Saturday consultation</h4>
            <p>
              A consultation on “Releasing the Potential of the Youth” that was commended for its “frankness and sincerity” and
              heartfelt, meaningful discussions of “Race Unity and the Five Year Plan” and “Enhancing Institutional Capacity”
              highlighted a day of opportunities, Saturday, April 30, for delegates to the 108th U.S. National Convention to share
              experiences, insights and concerns with the National Spiritual Assembly.
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  The day’s first consultative session was open to any topic on which delegates wished to speak — or in the case
                  of one delegate, recite an inspirational poem to demonstrate that that art form, like music, has a place in this
                  Dispensation.
                </p>
                <p>Other remarks:</p>
                <ul>
                  <li>
                    A Texas delegate called podcasting a relatively untapped medium for delivering not just stories of faith in
                    action, particularly by youth, but historic talks mined from archives.
                  </li>
                  <li>
                    An Arizona delegate described a recent Plan launch conference in which small groups took a collective approach
                    to planning, which he said lent a sense of ownership.
                  </li>
                  <li>
                    A delegate from South Dakota cited a recent study that showed religious affiliation is on the wane but people
                    still are seeking community for themselves and their children.
                  </li>
                  <li>
                    A Minnesota delegate raised a question related to background checks for anyone interacting with minors in
                    Bahá’í activities. Two members of the National Spiritual Assembly detailed the process and how it protects all
                    parties involved.
                  </li>
                  <li>
                    A Mississippi delegate urged that children’s class teachers and junior youth group animators talk with their
                    charges about the need for education and acquaint them with available pre-college programs.
                  </li>
                </ul>
                <p>
                  Next up was “Releasing the Potential of the Youth.” Questions posed for delegates to consider included: What
                  developments did we see in the just-completed Plan with regard to releasing the potential of the youth? How have
                  these developments impacted the individual believers, the institutions, and the community? What contributed to
                  these developments?
                </p>
                <p>Some comments:</p>
                <ul>
                  <li>
                    A Georgia delegate said that youth from Bahá’í families are not grounded in the teachings to the extent we’d
                    think and urged that attention be given to helping them develop a Bahá’í identity.
                  </li>
                  <li>
                    The key to doing that, said a Texas delegate, is being consistent in what we say and do and respecting the
                    intelligence of young people. She cited guidance that service by the whole family is a key to developing such
                    an identity.
                  </li>
                  <li>
                    An Illinois delegate said it is a false dichotomy that because youth have been placed in the vanguard there’s
                    little left for older Bahá’ís to do. He urged that when teams are formed they be multi-generational.
                  </li>
                  <li>
                    An Idaho delegate, referring to the map on the wall of Foundation Hall denoting the progress of clusters
                    around the country, noted it is largely through the “valiant efforts of youth” that the nucleus of active Plan
                    participants in the Northern Plains has grown and structures of the Faith have been cemented. That moved
                    Counselor Farah Guchani-Rosenberg to relate a story of 18 men from a sensitive country who concluded that a
                    Manifestation had come and proceeded to attract youths to the new teachings.
                  </li>
                  <li>
                    A delegate from Nevada urged that youths expressing interest in the Faith be embraced despite background (he
                    spoke of one who embraced the Faith while in prison), appearance or orientation.
                  </li>
                  <li>
                    From California came the story, related by the Convention’s youngest delegate, of how all ages participate
                    equally in his cluster. He also noted young people collaborate best in a circle with facilitation.
                  </li>
                  <li>
                    An Alabama delegate told how a Bahá’í youth starting college after a year of service was plugged into
                    activities, and he urged that anyone knowing a new student should make the community aware.
                  </li>
                  <li>
                    A delegate from Arizona described gatherings for youth that focus on issues they want to talk about and how
                    these gatherings are leading to greater engagement.
                  </li>
                  <li>
                    A delegate from South Carolina commended the “frankness and sincerity” of this consultation and expressed a
                    belief that the Bahá’í community has come a long way in its support of youth doing service.
                  </li>
                  <li>
                    A Texas delegate agreed but said a remaining challenge is the resistance of some parents to their children’s
                    participation in some activities.
                  </li>
                  <li>
                    A Kentucky delegate pointed to herself as an example of a young person from the wider community who was
                    brought into the loving embrace of the Bahá’ís and now is a regional training institute coordinator. Hearing
                    that, Counselor Mark Sisson arose to say youth — and parents, for that matter — from the Bahá’í and wider
                    communities have the same yearnings and fight the same battles. He, too, is concerned for the Bahá’í identity
                    of his children but said nothing cements identity like interacting and serving with other young people. Every
                    youth you ask believes he or she can make a difference, and every youth sees all other youth as brothers and
                    sisters, said Sisson.
                  </li>
                </ul>
                <p>
                  On the topic of “Enhancing Institutional Capacity,” delegates pondered: How has a shared spirit of collaboration
                  among the three protagonists of the Plan become a prerequisite for enhancing the institutional capacity? What is
                  the role of the Fund in this regard? And what are the implications for suggestions and recommendations to the
                  National Spiritual Assembly?
                </p>
                <p>Among remarks:</p>
                <ul>
                  <li>
                    In a perfect segue, a California delegate noted that in the Dec. 29, 2015, letter from the Universal House of
                    Justice to the Conference of the Continental Boards of Counselors the paragraph on youth moving into adult
                    lives leads into the paragraph on enhancing institutional capacity. She urged that spaces be created for young
                    adults reaching the age of service on Spiritual Assemblies to learn about the institution and its duties.
                  </li>
                  <li>
                    In a perfect segue, a California delegate noted that in the Dec. 29, 2015, letter from the Universal House of
                    Justice to the Conference of the Continental Boards of Counselors the paragraph on youth moving into adult
                    lives leads into the paragraph on enhancing institutional capacity. She urged that spaces be created for young
                    adults reaching the age of service on Spiritual Assemblies to learn about the institution and its duties.
                  </li>
                  <li>
                    A delegate from Colorado echoed the concern, noting that the Fund is where the spiritual and material aspects
                    of the Faith come together.
                  </li>
                  <li>
                    {' '}
                    North Carolina delegate described how a Spiritual Assembly, concerned that the time taken up by personal
                    issues was had divorced it from the teaching work, decided to place the Plan at the top of each agenda.
                    Guidance was read at each meeting, Assembly members began engaging in activities and the new spirit spilled
                    over into Feasts.
                  </li>
                  <li>
                    Another North Carolina delegate said her Assembly similarly has aligned its agenda with the three-month cycle
                    and the goals set in the cluster and sector. Previously, she said, the Assembly did not know when the ATC was
                    planning a project within that community; now the Assembly and agencies consult beforehand and collaborate.
                  </li>
                  <li>
                    An Indiana delegate related how, with the help of a web development team engaged by the Bahá’í National
                    Center, his community updated its website to become an interactive resource for believers and the public
                    alike.
                  </li>
                </ul>
                <p>
                  Suggested references to inform the consultation on “Race Unity and the Five Year Plan” were paragraph 25 of the
                  Dec. 29, 2015, message from the Universal House of Justice to the Conference of the Continental Boards of
                  Counselors; the March 26, 2016, letter from the House of Justice to the Bahá’ís of the United States and Canada;
                  and an April 10, 2011, letter written on behalf of the House of Justice to an individual believer.
                </p>
                <p>After two delegates read the April 10 letter, consultation began:</p>
                <ul>
                  <li>
                    Two delegates from Missouri, a Persian man and African-American woman, arose together to describe how they
                    have worked together through issues of race for more than 20 years and realize that being Bahá’ís doesn’t mean
                    we’ve conquered the tendencies of the wider society.
                  </li>
                  <li>
                    A delegate from North Carolina secured the permission of delegates for his wife to speak in his stead. She
                    described how the love she felt from Bahá’ís as a child turned to rejection and loneliness as an adult, and
                    she said when the Bahá’í community is unloving it not just breaks your heart but crushes your soul. She urged
                    that Bahá’ís venturing into neighborhoods not only share the teachings but listen to inhabitants and allow
                    their gifts to shine through.
                  </li>
                  <li>
                    A Washington delegate shared the early results of a Bahá’í-inspired effort in local schools to eliminate
                    prejudice and advance oneness. The pilot program, she said, is grounded in the framework for action and
                    provides training, tools, accompaniment and ongoing support to students, teachers and staff.
                  </li>
                  <li>
                    A Colorado delegate, noting that the institute process is a pipeline but not enough oil — in the form of
                    people of color — is coming through it, recommended that the National Assembly create a national program on
                    the issue of race equity.
                  </li>
                  <li>
                    A delegate from Illinois related how the film Racial Taboo and the ongoing discussions it spawns are forging
                    bonds among people of all backgrounds, some of whom have begun participating in core activities.
                  </li>
                  <li>
                    An Arizona delegate, reminding that native people reside in the cities as well as on reservations, urged that
                    their neighborhoods become foci of expansion and consolidation efforts.
                  </li>
                  <li>
                    A Michigan delegate related that because urban neighborhoods are “sorely segregated” and cluster boundaries
                    often mimic these divisions, it can take a “supreme effort” to step through space and reach receptive
                    populations. If that effort is made, Sisson rejoined, conversations can result and people become ennobled. The
                    institute process works, he said, and he urged Bahá’ís to take up that instrument, give it to the world and
                    “see what they do with it.”
                  </li>
                </ul>
              </div>
            )}
            <hr />
            <img alt="Delegate speaking" src="https://c2.staticflickr.com/2/1600/26708037976_868a8ae95d_q.jpg" />
            <h4 className="small-top-padding">Intensified effort is focus of Friday consultation</h4>
            <p>
              “This Plan is about pulling out all the stops,” a delegate observed as consultation at the 108th U.S. National
              Convention continued Friday, April 29, on two fronts: “Strengthening the Pattern of Action and an Institute Process
              Gaining Strength” and “Embracing Large Numbers.”
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  One portion of the afternoon session addressed such questions as: What have we observed about the movement of
                  clusters along the path of development in this past Plan? How were new programs of growth established? What
                  conditions were created? What are we learning about this movement? How can institutions such as the National
                  Spiritual Assembly strengthen this process?
                </p>
                <p>
                  The other was guided by these questions: What are the implications of the goal of this Plan for at least 600
                  intensive programs of growth in our country — for the individual believer, the institutions, and the community?
                  What conditions will need to be created? Are there particular implications for the National Spiritual Assembly?
                </p>
                <p>A sampling of remarks:</p>
                <ul>
                  <li>
                    A California delegate told how a cluster passed the first milestone of development with the help of a team of
                    five youths who invited townspeople to core activities and encountered a believer who wasn’t known to the
                    Bahá’í community. The effort led to mobile tutors offering study circles, the launch of a children’s class and
                    the arrival of a homefront pioneer.
                  </li>
                  <li>
                    A Wyoming delegate related a similar story of how homefront pioneers and mobile tutors are raising resources
                    in rural areas of the Northern Plains. One community, she said, had had a “bad experience” with the institute
                    process, and a homefront pioneer’s efforts helped turn that around.
                  </li>
                  <li>
                    A delegate from Florida described an Area Teaching Committee made up of young adults who are enthusiastic but
                    uncertain how an ATC should function. Counselor Sonlla Heern said this is golden opportunity for the community
                    to deepen on the agency and what has been learned elsewhere about its role. Later, Counselor Farah
                    Guchani-Rosenberg noted that accompanying ATCs in building capacity is a key function of regional development
                    facilitators.
                  </li>
                  <li>
                    From Kansas came the story of how a closed Facebook page helped believers in one area share news of teaching
                    efforts and get feedback and reinforcement.
                  </li>
                  <li>
                    A Michigan delegate asked how to turn enthusiasm for two ongoing activities — an international dinner and a
                    drum circle — into participation in core activities.
                  </li>
                  <li>
                    A Tennessee delegate urged that seasonal Bahá’í schools be seen as a means to help build capacity in people
                    from the wider community who have begun to participate in and initiate core activities.
                  </li>
                  <li>
                    A delegate from Florida related how a refresher on Ruhi Book 2, Arising to Serve, led to the realization that
                    in a meaningful conversation both parties speak and listen. He said a teaching team has been formed that
                    reflects each month on members’ experience in holding conversations and inviting people into activities.
                  </li>
                  <li>
                    A Missouri delegate described the impact of mini-reflection gatherings each Assembly held prior to the
                    cluster-wide gathering as families committed to host devotionals and children’s classes and the participation
                    of Persian speakers was enhanced.
                  </li>
                  <li>
                    A Texas delegate, noting the pastoral role played by clergy in other faiths, said if people are not involved
                    we must go to them and learn what impediments they face.
                  </li>
                  <li>
                    A Pennsylvania delegate related that he typically surveys local Bahá’ís on their activities before coming to
                    National Convention, but this year he asked what their needs are and most requested tools for Assembly
                    development. He described how one Assembly has urged each member to go back and carry out the practices
                    associated with the Ruhi Institute courses to stimulate their own and others’ involvement.
                  </li>
                  <li>
                    Echoing that, an Illinois delegate described Assemblies as a stable institution with much inherited learning.
                    She urged that Assemblies be reminded they can get together to share learning, and she suggested that a
                    bulletin for secretaries be developed similar to Treasurers Notes.
                  </li>
                  <li>
                    A delegate from South Carolina, saying “this Plan is about pulling out all the stops,” requested help from the
                    regional national institutions for believers in areas where receptivity outstrips resources.
                  </li>
                  <li>
                    A Washington delegate related how decentralized Holy Day observances have aided in cementing friendships with
                    core activity participants from the larger community.
                  </li>
                  <li>
                    A Texas delegate spoke to the conditions that need to be created for enhancing the pattern of action, saying
                    it would aid our learning if a searchable website were created that allows any Bahá’í to share news of and
                    reflect on activities.
                  </li>
                </ul>
                <p>
                  In the evening, questions related to embracing large numbers included: What has been our experience with
                  engaging a broad section of the population in conversations and activities that enable them to realize how
                  society can be transformed? What have we observed about this process? What does this experience suggest for
                  lines of action that might be adopted by the National Spiritual Assembly? Many delegates had questions of their
                  own and were keen to hear in particular from those whose clusters have passed the third milestone and entered
                  into the precincts of “hundreds serving thousands.”
                </p>
                <p>Some of the comments:</p>
                <ul>
                  <li>
                    A California delegate described the complementary roles of the Spiritual Assembly and Area Teaching Committee
                    in channeling resources to match needs in the field of action. She related how four sector teaching committees
                    empower the teams operating in focus neighborhoods and help remove obstacles to progress, and the secretaries
                    of those sector committees sit on the cluster ATC.{' '}
                  </li>
                  <li>
                    A Massachusetts delegate noted how deeply involved in social action and discourse are the Bahá’ís of Guyana,
                    where believers make up 8 percent of the population. The American community has not yet attained that critical
                    mass, she said, but it’s not too early to begin applying spiritual solutions to the problems of society.
                  </li>
                  <li>
                    A delegate from New Jersey pointed out the link between embracing large numbers and managing large numbers,
                    saying cluster statistical officers will increasingly need powerful tools for assessing human resources.
                    Fariba Aghdasi, member of the National Spiritual Assembly, responded that new software has arrived from the
                    Bahá’í World Center and will be rolled out by Regional Bahá’í Councils.
                  </li>
                  <li>
                    From a North Carolina delegate came a story of how the Triangle communities began acting like a cluster
                    “before we knew what a cluster was.” Assemblies collaborated on projects, college clubs abounded, later the
                    resources unleashed by the youth conferences was added, and people flooded the neighborhoods to teach. “We
                    figured things out as we went along,” she said.
                  </li>
                  <li>
                    A California delegate agreed, recalling how when he lived in the Triangle he and family members were visited
                    by Assembly members even as they unpacked, immediately plugged in to email lists, and accompanied as they
                    initiated a children’s class.
                  </li>
                  <li>
                    A California delegate agreed, recalling how when he lived in the Triangle he and family members were visited
                    by Assembly members even as they unpacked, immediately plugged in to email lists, and accompanied as they
                    initiated a children’s class.
                  </li>
                  <li>
                    A Washington delegate said ambiguities surrounding the term entry by troops have been clarified as we’ve
                    realized that troops need not be enrolled people; rather, people with certain characteristics who can mesh
                    seamlessly in the field of action.
                  </li>
                </ul>
              </div>
            )}
            <hr />
            <img alt="Delegate speaking" src="https://c2.staticflickr.com/2/1644/26650238381_53f0b3825e_q.jpg" />
            <h4 className="small-top-padding">Common purpose on delegates’ minds in initial consultation</h4>
            <p>
              Attaining universal participation within the Bahá’í community and engaging as many people as possible in the wider
              society was the focus of many remarks by delegates to the 108th U.S. Bahá’í National Convention as consultation
              began on Thursday, April 28.
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  Afternoon and evening sessions afforded delegates the opportunity to share thoughts with the National Spiritual
                  Assembly on the Riḍván 2016 message and the March 26 messages to the Bahá’ís of the world and to the Bahá’ís of
                  the United States and Canada as well as on the topic of “Nurturing a Spirit of Common Purpose in the Community.”
                </p>
                <p>
                  Consultation on the messages was sparked by comments from National Assembly member and delegate David F. Young.
                  He noted that the Bahá’í community has never been stronger or more united but faces the challenge of scaling up
                  its efforts to attain the far-reaching goals of the just-launched Plan. How can more believers be brought into
                  this seminal battle for the future of humankind? How can more youth balance their desire to serve with their
                  need to prepare for and begin careers? How can sufficient funds be generated to address opportunities for the
                  application of Bahá’u’lláh’s healing message?
                </p>
                <p>Some delegates arose in search of answers to those questions. Two examples:</p>
                <ul>
                  <li>A delegate from South Carolina pondered how the community can assist the service of new parents.</li>
                  <li>
                    A Georgia delegate, noting that a recent reflection gathering attracted only three believers beyond those who
                    serve cluster agencies, asked how participation can be increased.
                  </li>
                </ul>
                <p>
                  Other delegates suggested — from experiences where they live — possible paths to progress in several realms:
                </p>
                <ul>
                  <li>
                    From a Florida delegate came suggestions for meeting youth “where they are” through social gatherings and
                    developing relationships in advance of seeking their service. He also described ways to help Persian speakers
                    participate in community life.
                  </li>
                  <li>
                    A South Carolina delegate told how she is making connections after a homefront pioneer put her in touch with
                    the mothers and grandmothers of young people.
                  </li>
                  <li>
                    Another delegate from the Palmetto State related his experiences visiting the homes of believers who are
                    weighed down by personal circumstances.
                  </li>
                  <li>
                    A Massachusetts delegate suggested assembling teaching plans of parents who are anxiously concerned about the
                    welfare of children.
                  </li>
                  <li>Increased home visits to people in need of prayer were urged by a North Dakota delegate.</li>
                </ul>
                <p>
                  Moving to the topic “Nurturing a Spirit of Common Purpose in the Community,” delegates were aided by a set of
                  possible questions to ponder: How have devotional meetings, home visits, and meaningful conversations
                  contributed to promoting “a spirit of common purpose in the community” in the clusters in your regions? How has
                  “the strategy of initiating community-building activities in neighborhoods and villages that show promise” been
                  employed? What are the implications of your experience for the work of the National Spiritual Assembly?
                </p>
                <p>Some topics raised by delegates:</p>
                <ul>
                  <li>
                    From Maryland, how a visit to the Triangle learning site for the junior youth spiritual empowerment program
                    and a relationship with school officials led to the recruitment and training of high school students to
                    animate junior youth groups.
                  </li>
                  <li>
                    From Washington, how the Bahá’ís in a small community invited a pastor to their devotional gathering. At the
                    gathering, the pastor asked Bahá’ís how they embraced the Faith and what difference it has made in their
                    lives.
                  </li>
                  <li>
                    From California, how disunity was overcome by having Spiritual Assembly members visit believers’ homes to
                    share the Riḍván message. Nearly all believers “picked up a new activity” as a result.
                  </li>
                  <li>
                    From California, how disunity was overcome by having Spiritual Assembly members visit believers’ homes to
                    share the Riḍván message. Nearly all believers “picked up a new activity” as a result.
                  </li>
                  <li>
                    From California, how the accompaniment by regional development facilitators of the believers in clusters
                    associated with the San Diego junior youth learning site helped those clusters become reservoirs of resources
                    and learning for other clusters.
                  </li>
                  <li>
                    From Indiana, how a community “connected the dots.” A seeker inquiry led to encouragement for a Bahá’í family
                    to host a devotional the seeker could attend. And as part of their study of Ruhi Book 4, The Twin
                    Manifestations, other community members will visit the seeker to share stories on the life of Bahá’u’lláh.
                  </li>
                  <li>
                    From Vermont, how an Assembly’s initiative to “check in” on believers revealed the tests many are enduring and
                    led to greater unity through rendering assistance to one another.
                  </li>
                  <li>
                    From Ohio, how each devotional in an area has taken on a unique characteristic and brought about many teaching
                    opportunities.
                  </li>
                  <li>
                    From Kentucky, how a gathering to which all believers in Kentucky and Tennessee were invited achieved a
                    collective vision and pledges of service.
                  </li>
                  <li>
                    From Texas, how interaction with the parents and siblings of junior youth group participants led to a new
                    children’s class and a greater understanding by parents of paths of service awaiting junior youth who turn 15.
                  </li>
                  <li>
                    From Massachusetts, how opening reflection spaces for college students and for members of area Assemblies are
                    increasing collaboration.
                  </li>
                </ul>
              </div>
            )}
            <hr />
            <img alt="Counselor speaking" src="https://c2.staticflickr.com/2/1510/26109818564_a2aec7bb7d_q.jpg" />
            <h4 className="small-top-padding">Counselor ‘unpacks’ suitcase of guidance in recent messages</h4>
            <p>
              The messages the Bahá’í community has been blessed with in the past month — at Riḍván and on the centenary of the
              first Tablet of the Divine Plan — are like a well-packed suitcase, Counselor Sonlla Heern told delegates and
              visitors to the 108th U.S. National Convention on Thursday, April 28.
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  In this suitcase, she said, is information essential for reflection on the progress of the Faith to this date
                  and planning for action to advance every cluster in the Five Year Plan just being launched.
                </p>
                <p>
                  These “three amazing messages” from the Universal House of Justice are the Ridván 2016 letter to the Bahá’ís of
                  the world and the March 26 letters to the Bahá’ís of the world and to the Bahá’ís of the United States and
                  Canada.
                </p>
                <p>
                  Together they “take us on a journey of the unfoldment of the Divine Plan,” she said, so we can fully appreciate
                  what has brought the Cause of God to the present juncture and what is required to move it forward.
                </p>
                <p>
                  Heern harked to March 1916, when, as the flowers bloomed around Him, ‘Abdu’l-Bahá penned the first of the
                  Tablets. And to their reception by the tiny cadre of North American believers, who then began their own journey
                  of capacity building and action to answer the Master’s call. Fast forward to Riḍván 1996, she said, when North
                  American Bahá’ís were called on to rise to the occasion in a new way: to act as a reservoir of resources and
                  experience for the entire world.
                </p>
                <p>
                  And to 2016, with our challenges now on the home front as advanced clusters are asked to serve as reservoirs for
                  those around them.
                </p>
                <p>
                  To aid her audience in envisioning what lies ahead, Heern proceeded to “unpack,” paragraph by paragraph, the
                  Riḍván message.
                </p>
                <p>
                  The initial paragraph, she said, signals that the period of preparation for the new Plan is over and the
                  believers are summoned to a five-year commitment of “courage, resolve, and resources.”
                </p>
                <p>
                  That period of preparation is recalled In the second paragraph, she said. National institutional meetings, such
                  as the one in Pensacola, Florida, in February, have led to “decisive plans of action” already being implemented
                  in each region through launch conferences, vision meetings and other mechanisms. Indeed, the message indicates,
                  this entire series of Plans has planted seeds that now can be cultivated.
                </p>
                <p>
                  Heern said paragraph three is where the House of Justice traces the framework that has evolved from “simple acts
                  of service” to “more elaborate patterns of action.” The efforts of all three protagonists in the Plan,
                  individually and collectively, are enumerated. And in its final sentence, she said, the Supreme Body offers a
                  definition for today of “the very purpose of religion itself.”
                </p>
                <p>
                  Moving to paragraph four, Heern said it lays a broad foundation for the task ahead — to strengthen the process
                  of growth in each cluster and extend patterns of community life. Might the efforts of Bahá’ís and their friends
                  in this Plan, she wondered, lead into the sixth epoch of the Formative Age of the Faith?
                </p>
                <p>
                  The stark contrast between these efforts, which are “drawing people together to build communities that are
                  havens of unity,” and a society turning “fitfully from one false hope to another” is provided in paragraph five,
                  she noted.
                </p>
                <p>
                  And in the final paragraph, she said, is contained the vision of this Plan yielding in five years to a new phase
                  of activity that will last until 2044, the bicentennial of the blessed Báb’s mission. This truly, then, is “a
                  time for jubilation” as the House of Justice declared in its March 26 letter to the believers in the United
                  States and Canada, said Heern. With the same spirit shown by our predecessors, she said, American Bahá’ís can
                  meet head on what the Supreme Institution describes as “priceless opportunities and inescapable obligations” and
                  take the fate of humanity in their capable hands.
                </p>
              </div>
            )}
            <hr />
            <img alt="Delegate speaking" src="https://c2.staticflickr.com/2/1533/26610064852_2b2e62fe3c_q.jpg" />
            <h4 className="small-top-padding">Delegates’ study and devotions lead to opening of Convention</h4>
            <p>
              As Chicagoland commuters rushed along nearby Interstate 94, delegates to the 108th U.S. National Convention slowed
              down Thursday morning, April 27, to study documents pertaining to the duties they would begin to discharge that
              afternoon.
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  Filling a ballroom at their hotel, the delegates were greeted by Counselor Farah Guchani-Rosenberg on behalf of
                  her colleagues seated alongside, Sonlla Heern and Mark Sisson, and of the entire Continental Board of
                  Counselors.
                </p>
                <p>
                  Guchani-Rosenberg recalled her first experience, in 1995, as a delegate to National Convention. In preparation
                  for that occasion she “almost memorized” the guidance. Such was the spirit she exuded that her parents and young
                  son eagerly accompanied her to Convention, held that year in a downtown Chicago hotel, as visitors.
                </p>
                <p>
                  This year’s delegates have gathered in that same spirit, she said. As the Universal House of Justice noted in a
                  March 25, 2007, letter to the Bahá’ís of the world, the System of Bahá’u’lláh confers on each Bahá’í a “high
                  privilege” to select, “as a responsible citizen of the new world being brought into existence,” the composition
                  of its institutions.
                </p>
                <p>
                  And as the Supreme Body wrote in a May 16, 2013, letter to the delegates gathered at Bahá’í National
                  Conventions, their consultations are “unique” among all the “spaces that allow for the exchange of views” in
                  that National Convention “brings together the National Assembly and elected representatives from all parts of
                  the country” and this dimension calls for “thoughtful attention.”
                </p>
                <p>
                  Aiding this thoughtful attention was a set of suggested questions for delegates to discuss in small groups at
                  the preparation session. As summarized by Guchani-Rosenberg: What does this spirit of responsibility imply? Why
                  are the tasks of delegates sacred? What are some of the spiritual and moral principles that govern consultation?
                  What do we need to demonstrate to really practice it? How can delegates contribute toward fulfilling “the
                  function of an enlightened, consultative, and co-operative body,” in the words of Shoghi Effendi?
                </p>
                <p>
                  Thus equipped, delegates were transported to the Bahá’í House of Worship. Lunch beckoned, as did the marvelous
                  opportunity immediately afterward to ascend to the Temple Auditorium and celebrate the Ninth Day of Riḍván with
                  area believers.
                </p>
                <p>
                  With the choir’s plaintive refrain “what tongue can voice my thanks, voice my thanks to Thee” echoing in their
                  ears, delegates and visitors entered the lower-level Foundation Hall. As Convention opened, temporary chair Muin
                  Afnani greeted delegates on behalf of the National Spiritual Assembly.
                </p>
                <p>
                  Delegates were seated by temporary secretary Kenneth E. Bowers, then took up matters related to the Convention’s
                  organization and functioning.
                </p>
                <p>
                  David F. Young was elected chair — the servant of Convention, in his words — and Afnani was elected Convention
                  secretary. As delegates awaited the return of tellers, Counselor Mark Sisson and delegate Julianne Redson-Smith
                  led all in joyous song.
                </p>
                <p>
                  Convention procedures from recent years were retained by delegates about how recommendations and suggestions are
                  framed and voted on; how results of balloting for the National Spiritual Assembly are reported; and the
                  insertion of a reflection period on the final morning of Convention. Reminded that fewer delegates were able to
                  speak during last year’s Convention than previously, delegates decided on a 3.5-minute limit with discretion for
                  the chair to allow a speaker to continue.
                </p>
                <p>
                  The weekend’s agenda laid out by the National Assembly was ratified by delegates. It focuses consultative
                  sessions on features of the new Five Year Plan as detailed in the Dec. 29, 2015, message from the Universal
                  House of Justice to the Conference of the Continental Boards of Counselors as well as focused consultation
                  Saturday evening on “Race Unity and the Five Year Plan.”
                </p>
              </div>
            )}
            <hr />
            <img alt="voting box" src="https://c2.staticflickr.com/2/1456/26503211016_e6fabaa649_q.jpg" />
            <h4 className="small-top-padding">Plan’s launch is backdrop to duties awaiting Convention delegates</h4>
            <p>
              With the just-launched Five Year Plan very much on their minds, delegates to the 108th U.S. Bahá’í National
              Convention convene Thursday, April 28, at the Bahá’í House of Worship in Wilmette, Illinois.
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>Two principal tasks await them:</p>
                <ul>
                  <li>
                    Prayerfully elect the nine members of the National Spiritual Assembly that will guide the affairs of the
                    Bahá’í Faith in this country over the coming year.
                  </li>
                  <li>Inform the incoming National Assembly through consultation on the progress of the Faith.</li>
                </ul>
                <p>
                  The delegates, elected last October in their home units, also will hear reports on the past year’s activities
                  and gain insights from the Counselors present.
                </p>
                <p>Day by day, here is how Convention will unfold.</p>
                <strong>THURSDAY</strong>
                <p>
                  The Counselors will guide a deepening session for delegates on the purposes of National Convention and their
                  role in it.
                </p>
                <p>
                  Thus prepared, the delegates will be transported from their hotel to the nine-sided Temple on the shores of Lake
                  Michigan.
                </p>
                <p>
                  After a commemoration of the Ninth Day of Riḍván in the Temple Auditorium, Convention will begin in Foundation
                  Hall with the seating of delegates in a roll call by the temporary Convention secretary.
                </p>
                <p>
                  The first order of business: Elect Convention officers, approve the agenda, and adopt procedures for
                  consultation.
                </p>
                <p>
                  After delegates hear a recitation of the Riḍván message of the Universal House of Justice, the Counselors will
                  give thoughts on the message and delegates will explore their understanding of its guidance.
                </p>
                <strong>FRIDAY</strong>
                <p>
                  Presentations by the National Assembly’s secretary and treasurer expanding on key points in that body’s Annual
                  Report will be followed by questions from delegates.
                </p>
                <p>
                  The rest of the day will be devoted to consultation on themes that address the challenges and opportunities
                  facing the American Bahá’í community in the coming year
                </p>
                <strong>SATURDAY</strong>
                <p>
                  Delegates will cast their ballots for the incoming National Assembly. After the balloting delegates will have a
                  further opportunity to consult.
                </p>
                <p>
                  If the weather cooperates, a Convention photograph will be taken on the upper steps of the Bahá’í House of
                  Worship. Otherwise, it will be snapped inside Foundation Hall.
                </p>
                <p>The rest of the day:</p>
                <ul>
                  <li>Tellers’ report of the ballot results.</li>
                  <li>A presentation by the trustees of Bahá’í Ḥuqúqu’lláh in the United States.</li>
                  <li>More consultation.</li>
                  <li>Presentation of the new National Assembly and its officers.</li>
                </ul>
                <strong>SUNDAY</strong>
                <p>Convention wraps up with final opportunities to consult and hear from the Counselors.</p>
                <p>
                  That afternoon, delegates and the public will ascend to the Temple Auditorium for a commemoration of the 12th
                  Day of Riḍván.
                </p>
              </div>
            )}
            <hr />
            <img alt="Q&A" src="https://c2.staticflickr.com/2/1635/26436767432_596be9d40d_q.jpg" />
            <h4 className="small-top-padding">Questions and answers about National Convention</h4>
            <p>
              <strong>Q.</strong> What are the purposes of National Convention?
            </p>
            <p onClick={() => this.setHiddenId('one')}>Read More &hellip;</p>
            {this.state.hiddenId === 'one' && (
              <div>
                <p>
                  <strong>A.</strong> The Universal House of Justice, in an April 4, 1971, letter from the Universal House of
                  Justice to our National Spiritual Assembly, explained, “[T]he primary purpose in holding a convention is to
                  enable the delegates to meet together, deliberate freely upon the affairs of the community, and cast their
                  ballots. Other considerations such as allowing the rank and file of believers to attend the Convention or follow
                  its deliberations are secondary in importance. Obviously it is within the discretion of your National Assembly
                  to decide upon these secondary matters.” The balloting to which the letter refers is for the nine members of the
                  National Assembly that will serve for the coming year.
                </p>
                <p>
                  <strong>Q.</strong>When is National Convention?
                </p>
                <p>
                  <strong>A.</strong>Most years, National Convention is held on a weekend during the 12-day Festival of Ridván
                  that doesn’t conflict with the election of Local Spiritual Assemblies. Every five years, National Convention is
                  held in late May. Those are years in which the Universal House of Justice is elected at the Bahá’í World Center
                  in Haifa, Israel. Since that election takes place in April, the National Convention is held in May to enable the
                  National Assembly members to attend the International Convention and report back to the community about their
                  experience in Haifa.
                </p>
                <p>
                  <strong>Q.</strong>Where is National Convention held?
                </p>
                <p>
                  <strong>A.</strong>For the past several years, all sessions of the National Convention have been held at the
                  Bahá’í House of Worship in Wilmette, Illinois. In the past, the National Convention was often held in a large
                  hotel meeting space to allow a large number of Bahá’ís to attend. Mostly for budgetary reasons, Convention
                  sessions are now held at the Bahá’í House of Worship.
                </p>
                <p>
                  <strong>Q.</strong>Who can attend National Convention?
                </p>
                <p>
                  <strong>A.</strong>The small number of seats in Foundation Hall at the Bahá’í House of Worship greatly limits
                  the number of people who can attend. Delegates are guaranteed seats, as are members of the Continental Board of
                  Counselors and the National Spiritual Assembly. The remainder of seats in Foundation Hall are filled
                  first-come-first-served by members of the Auxiliary Boards for Protection and Propagation and the Regional
                  Bahá’í Councils, a small number of the Bahá’ís serving at the Bahá’í National Center and guests such as
                  delegates’ family members. Once all the seats in Foundation Hall are taken, the remainder of attendees may view
                  the proceedings from a designated satellite location.
                </p>
                <p>
                  <strong>Q.</strong>How are National Convention delegates elected, and who is eligible to serve?
                </p>
                <p>
                  <strong>A.</strong>The number of delegates allocated to the U.S. Bahá’í National Convention was set by the
                  Guardian, Shoghi Effendi, at 171. At unit conventions across the country in early October, the Bahá’ís in nearly
                  all geographical units elect one believer to be the delegate from that unit. A very few units, because of the
                  large number of believers they contain, elect more than one delegate. Any adult Bahá’í in good standing who
                  lives in the unit is eligible to serve as a delegate, except members of the Continental Board of Counselors. If
                  an Auxiliary Board member is elected as a delegate, he or she must decide whether to serve as delegate or remain
                  on the Auxiliary Board. If the elected delegate cannot serve, the National Spiritual Assembly chooses his or her
                  replacement. The list of Bahá’ís who finished second through fourth in the delegate balloting informs the
                  National Assembly’s consultation but does not dictate its selection of a replacement.
                </p>
                <p>
                  <strong>Q.</strong>How do delegates prepare for National Convention?
                </p>
                <p>
                  <strong>A.</strong>To assist new and returning delegates in preparing for participation in the Convention, a
                  booklet titled National Convention: A Statement and Compilation of Extracts from the Bahá’í Writings Prepared by
                  the Research Department of the Universal House of Justice is sent to them in advance of the Convention for their
                  study and reference. Election materials, the National Spiritual Assembly’s Annual Report, and a preliminary
                  Convention agenda also are sent to delegates. On the morning National Convention begins, a deepening for
                  delegates on the significance and purpose of the National Convention is facilitated by members of the
                  Continental Board of Counselors.
                </p>
                <p>
                  <strong>Q.</strong>Who may speak at National Convention?
                </p>
                <p>
                  <strong>A.</strong>The privilege of the floor is extended to delegates, members of the Continental Board of
                  Counselors, and members of the National Spiritual Assembly. Also able to speak when invited to are Bahá’ís who
                  deliver reports, such as representatives of the Board of Trustees for Ḥuqúqu’lláh, or possess information that
                  will inform the delegates’ consultation, such as a visiting Counselor member of the International Teaching
                  Center.
                </p>
                <p>
                  <strong>Q.</strong>Who may serve as a National Convention officer?
                </p>
                <p>
                  <strong>A.</strong>Any delegate present for the proceedings may serve as Convention chair or secretary. The body
                  of delegates elects the officers by secret ballot in the opening session of the Convention.
                </p>
                <p>
                  <strong>Q.</strong>How is the National Convention agenda decided?
                </p>
                <p>
                  =<strong>A.</strong>A preliminary agenda, guided by the purposes of National Convention and themes of the
                  current Plan, is presented by the National Spiritual Assembly to the delegates. Delegates may propose changes to
                  the agenda and have them adopted by the body of delegates by show of hands.
                </p>
                <p>
                  <strong>Q.</strong>How is consultation conducted at National Convention?
                </p>
                <p>
                  <strong>A.</strong>The exact manner of consultation, such as how long each delegate may speak or whether
                  preference will be given to delegates who have not yet spoken, is decided by the body of delegates at the
                  beginning of Convention. Each delegate has a number and raises that number to be recognized by the Convention
                  secretary. The secretary lists the delegates in order of recognition and that list can be seen by all on
                  monitors placed on the wall behind the stage. When the time is near for a delegate to speak, he or she moves to
                  one of the microphones at the front and awaits his or her turn. When delegates speak they may offer insights on
                  the topic of consultation for that time period and offer a suggestion or recommendation to the National
                  Spiritual Assembly for its consideration. Suggestions require no vote of the body of delegates. A recommendation
                  that receives a second is consulted on specifically and the body of delegates decides by show of hands whether
                  to pass it along to the National Assembly. When a recommendation is proposed, delegates who wish to speak to
                  that recommendation are listed and come to a microphone in the order they were recognized.
                </p>
                <p>
                  <strong>Q.</strong>How is the National Spiritual Assembly elected?
                </p>
                <p>
                  <strong>A.</strong>On Saturday morning of National Convention, the delegates gather in a session monitored by
                  the members of the Continental Board of Counselors present and witnessed by registered non-delegates to vote
                  prayerfully by secret ballot for the nine members of the National Spiritual Assembly. A ballot that contains
                  more or fewer than nine names is invalid. A vote for a person not eligible for election is not counted.
                  Delegates who cannot attend Convention may vote by absentee ballot. The nine individuals receiving the highest
                  number of votes serve on the National Assembly for one year.
                </p>
                <p>
                  <strong>Q.</strong>How are National Spiritual Assembly election results reported at National Convention?
                </p>
                <p>
                  <strong>A.</strong>The manner of reporting the results, beyond the names of the nine elected members of the
                  National Spiritual Assembly, is decided by the body of delegates on the first day of Convention.
                </p>
              </div>
            )}
          </Col>
          <Col sm={12} md={4}>
            <div className="grey-box">
              <h3>Resources </h3>
              <p>
                <a href="/docs/160419_uhj-ridvan-2016-message-(English).pdf" download="ridvan-2016-uhj-msg.pdf">
                  Riḍván 2016 Message from the <br /> Universal House of Justice
                </a>
              </p>
              <p>
                <a href="/docs/2016-annual-report_v1.pdf" download="ridvan-2016-annual-report.pdf">
                  Riḍván 2016 Annual Report
                </a>
              </p>
              <p>
                <a href="/docs/response-to-delegates-letter.pdf" download="response-to-delegates-letter.pdf">
                  Supreme Body Responds to Convention Delegates’ Letter
                </a>
              </p>
              <p>
                <a href="/docs/message-from-delegates-to-uhj.pdf" download="message-from-delegates-to-uhj.pdf">
                  Message to the Universal House of Justice from the Delegates Gathered at the 108th U.S. National Convention
                </a>
              </p>
              <h4>Secretary’s Report</h4>
              <iframe
                title="Secretary’s Report"
                src="https://player.vimeo.com/video/164783391?color=ffffff&byline=0"
                width="315"
                height="177"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              ></iframe>
              <a
                href="https://player.vimeo.com/external/164783391.hd.mp4?s=aeb42499d0c531197c07b895a7d7edf44dbdb359&profile_id=174&download=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                download video
              </a>
              <h4>Treasurer’s Report</h4>
              <iframe
                title="Treasurer’s Report"
                src="https://player.vimeo.com/video/164783390"
                width="315"
                height="177"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              ></iframe>
              <a
                href="https://player.vimeo.com/external/164783390.hd.mp4?s=7ac4eaf73fd822ee670bf18d4305a196142af276&profile_id=174&download=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                download video
              </a>
              <h4>20 Years of Growth in the American Bahá’í Community</h4>
              <iframe
                title="20 Years of Growth"
                src="https://player.vimeo.com/video/163886790"
                width="315"
                height="177"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              ></iframe>
              <a
                href="https://player.vimeo.com/external/163886790.hd.mp4?s=9c0702a2825ac54cf73b3700edf106ba1ef29701&profile_id=119&download=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                download video
              </a>
              <h4>The Bahá’í World View</h4>
              <iframe
                title="The Bahá’í World View"
                src="https://player.vimeo.com/video/129581880"
                width="315"
                height="177"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              ></iframe>
              <a
                href="https://player.vimeo.com/external/129581880.hd.mp4?s=5e93a93444b113e8fb0cd4883231c2a504b11f7a&profile_id=119&download=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                download video
              </a>
              <hr />
              <iframe
                title="other sound clips"
                width="300px"
                height="1150px"
                scrolling="no"
                frameBorder="no"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/221527363%3Fsecret_token%3Ds-M3Cyb&amp;color=7acffb&amp;auto_play=false&amp;hide_related=false&amp;show_comments=false&amp;show_user=false&amp;show_reposts=false"
              ></iframe>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default NatCon2019;
