import React, { FunctionComponent, useEffect, useState } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import type HomeBlockProxy from './Utilities/HomeBlockProxy';
import { ByRowThenColumnThenMoveToPreviousDate, ToOrganizedBlocks, ToJsxElements } from './Utilities/HomeBlockUtilities';
import { CircularProgress } from '@material-ui/core';

const numberOfItemsToLoadPerClick = 3;

const PreviousBlockList: FunctionComponent<{
  context: { homeBlockProxy: HomeBlockProxy };
}> = ({ context: { homeBlockProxy } }) => {
  const [homeBlockRows, setHomeBlockRows] = useState([] as JSX.Element[]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [page, setPage] = useState(1);
  const [showCircularProgress, setShowCircularProgress] = useState(true);

  useEffect(() => {
    setShowCircularProgress(true);
    const homeBlockProxyGetHomeBlocksSubscription = homeBlockProxy
      .getPreviousBlocks(numberOfItemsToLoadPerClick, page)
      .subscribe((x) => {
        if (!x.pagination?.nextPage || x.pagination.nextPage === '') {
          setHasNextPage(false);
        }
        const blocks = x.items;
        const orderByRowThenColumnThenMoveToPreviousDate = blocks.sort(ByRowThenColumnThenMoveToPreviousDate);
        const organizedBlocks = orderByRowThenColumnThenMoveToPreviousDate.reduce(ToOrganizedBlocks, []);
        const displayRows = organizedBlocks.map(ToJsxElements);
        const allDisplayRows = homeBlockRows.concat(displayRows);
        setHomeBlockRows(allDisplayRows);
        setShowCircularProgress(false);
      });

    return () => homeBlockProxyGetHomeBlocksSubscription.unsubscribe();
  }, [homeBlockProxy, page]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {homeBlockRows}
      {showCircularProgress && (
        <CircularProgress data-cy="previous-block-list-circular-progress" className="loading-animation" size={40} />
      )}
      {hasNextPage && (
        <div
          className="center medium-top-margin small-bottom-margin"
          onClick={() => setPage((prevPageCount) => prevPageCount + 1)}
        >
          <h6 data-cy="previous-block-list-read-more-link" className="link-text">
            Show more
          </h6>
        </div>
      )}
    </div>
  );
};

export default withContext(PreviousBlockList);
