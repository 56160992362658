import React from 'react';
import { Link } from 'react-router-dom';

const getLastChapterTaxonomy = (chaptersElement) => chaptersElement.value[chaptersElement.value.length - 1];

export default class HtmlLinkReplacer {
  constructor(kenticoItems, urlsByCodename) {
    this.kenticoItems = kenticoItems;
    this.urlsByCodename = urlsByCodename;
  }

  TryReplace(aDomNode) {
    const currentItem = this.kenticoItems?.find((x) => aDomNode.attribs['data-item-id'] === x.system.id);
    if (!currentItem) {
      return;
    }
    const lastChapterTaxonomy = getLastChapterTaxonomy(currentItem.chapters);
    if (!lastChapterTaxonomy) {
      console.error(
        `Following item could not render link "${currentItem.system.codename}", there is no chapters available for it:`,
        currentItem
      );
      return <>{currentItem.system.name}</>;
    }

    const url = this.urlsByCodename[lastChapterTaxonomy.codename].url;

    return <Link to={url}>{aDomNode.children[0].data}</Link>;
  }
}
