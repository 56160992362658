import React, { Component } from 'react';
import { Row } from 'react-flexbox-grid';
import { TextField } from '@material-ui/core';
import putphysicaladdress from './Utilities/PutPhysicalAddressEndpoint';
import ProfileFunction from './Utilities/ProfileFunction';
import stateList from './Utilities/StateList';
import withContext from '../ContextAPI/Context_HOC';
import { Link } from 'react-router-dom';
import addressendpoint from './Utilities/AddressEndpoint';
import SafeStringify from '../Utilities/SafeStringify';

class EditResidentialAddress extends Component {
  constructor(props) {
    super(props);
    this.auth = this.props.context.authService;
    this.profilefn = new ProfileFunction();
    this.state = {
      value: '',
      PersonId: '',
      token: '',
      testplease: 'another broken',
      serverError: 'Our services are currently unavailable. Please come back later.',

      selectList: [],
      stateList: [],
      addressdata: [{}, {}],
      selectedstateOption: { value: 'statevalue', label: 'test' },
      getAddressError: '',
      physicalAddressDisplay: 'Loading, please wait for a moment.',
      EditResidentialAddressDisplay: '',

      careOf: '',
      address1: '',
      address2: '',
      address3: '',
      city: '',
      statelocation: '',
      zip: '',
      physicalAddressMessage: '',
    };
    if (this.auth.loggedIn()) {
      this.state = {
        PersonId: this.profilefn.getprofileinfo(this.auth.getDecodedToken()).bid,
        token: this.auth.getToken(),
        stateList: stateList,
      };
    }
    this.handleChange = this.handleChange.bind(this);
    this.handelCancel = this.handelCancel.bind(this);
    this.handleAddressPutFormSubmit = this.handleAddressPutFormSubmit.bind(this);
    this.handleStateLocationChange = this.handleStateLocationChange.bind(this);
  }
  componentDidMount() {
    addressendpoint(this.state.token, this.state.PersonId, true)
      .then((res) => {
        this.setState(
          {
            careOf: res.careOf,
            address1: res.line1,
            address2: res.line2 !== null ? res.line2 : '',
            address3: res.line3 !== null ? res.line3 : '',
            city: res.city,
            statelocation: res.state,
            zip: res.zipCode,
          },
          function () {
            this.setState({}, function () {
              let selectList = [];
              selectList.push(<option key={0} value="" disabled="disabled"></option>);
              for (var i = 0; i < this.state.stateList.length; i++) {
                selectList.push(
                  <option key={i + 1} value={this.state.stateList[i].value}>
                    {this.state.stateList[i].label}
                  </option>
                );
              }
              this.setState({ selectList: selectList });
            });
          }
        );
      })
      .catch((err) => {
        if (err.status === 404 || err.status === 400) {
          this.setState({
            physicalAddressDisplay: <p className="error-message">{err.message ? SafeStringify(err.message) : null}</p>,
          });
        } else if (err.status === 500) {
          this.setState({
            physicalAddressDisplay: <div>{this.state.serverError}</div>,
          });
        }
      });
  }
  handelCancel() {
    this.props.context.setemergencyContactMessage('');
    this.props.context.setprofileUpdateMessage('');
    this.props.context.setphysicalAddressMessage('');
    this.props.context.setmailingAddressMessage('');
  }
  handleAddressPutFormSubmit(e) {
    e.preventDefault();
    let data = {
      PersonId: this.state.PersonId,
      careOf: this.state.careOf,
      line1: this.state.address1,
      line2: this.state.address2,
      line3: this.state.address3,
      city: this.state.city,
      state: this.state.statelocation,
      zipCode: this.state.zip,
      AddressType: 'domestic',
      toScrub: true,
    };
    putphysicaladdress(this.state.token, data)
      .then((res) => {
        this.props.history.push('./contactinfo', [
          this.setState({
            modalMessage: '',
            careOf: res.data.careOf,
            address1: res.data.line1,
            address2: res.data.line2,
            address3: res.data.line3,
            city: res.data.city,
            statelocation: res.data.state,
            zip: res.data.zipCode,
          }),
        ]);
        this.props.context.setphysicalAddressMessage(
          <p className="success-message">{res.data?.message ? SafeStringify(res.data.message) : null}</p>
        );
        this.props.context.setemergencyContactMessage('');
        this.props.context.setprofileUpdateMessage('');
        this.props.context.setmailingAddressMessage('');
      })
      .catch((err) => {
        if (typeof err === 'string') {
          this.setState({
            physicalAddressMessage: <p className="error-message">{err}</p>,
          });
        }
      });
  }
  handleStateLocationChange = (e) => {
    this.setState({ statelocation: e.target.value });
  };
  handleChange(e) {
    const value = e.target.value;
    this.setState({ [e.target.name]: value }, function () {});
  }
  render() {
    return (
      <div className="bounding-box center">
        <Row>
          <h3>Update your Residential Address</h3>
          <form data-cy="physicaladdress">
            {this.state.physicalAddressMessage}
            <p>* represents required fields</p>
            <TextField
              inputProps={{ 'data-cy': 'inputaddress1' }}
              id="address1"
              name="address1"
              label="Street Address*"
              aria-label="Street Address*"
              type="text"
              value={this.state.address1 || ''}
              onChange={this.handleChange}
              margin="dense"
              fullWidth={true}
              maxLength="40"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              inputProps={{ 'data-cy': 'inputcareOf' }}
              id="careOf"
              name="careOf"
              label="In care Of"
              aria-label="In care Of"
              type="text"
              value={this.state.careOf || ''}
              onChange={this.handleChange}
              margin="dense"
              fullWidth={true}
              maxLength="40"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              inputProps={{ 'data-cy': 'inputaddress2' }}
              id="address2"
              name="address2"
              label="Address line two"
              aria-label="Address line two"
              type="text"
              value={this.state.address2 || ''}
              onChange={this.handleChange}
              margin="dense"
              fullWidth={true}
              maxLength="40"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              inputProps={{ 'data-cy': 'inputaddress3' }}
              id="address3"
              name="address3"
              label="Address line three"
              aria-label="Address line three"
              type="text"
              value={this.state.address3 || ''}
              onChange={this.handleChange}
              margin="dense"
              fullWidth={true}
              maxLength="40"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              inputProps={{ 'data-cy': 'inputcity' }}
              id="city"
              name="city"
              label="City*"
              aria-label="City*"
              type="text"
              value={this.state.city || ''}
              onChange={this.handleChange}
              margin="dense"
              fullWidth={true}
              maxLength="40"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <p>
              State:
              <select
                name="statelocation"
                data-cy="selectstate"
                value={this.state.statelocation}
                onChange={this.handleStateLocationChange}
              >
                {this.state.selectList}
              </select>
            </p>
            <TextField
              inputProps={{ 'data-cy': 'inputzip' }}
              id="zip"
              name="zip"
              label="Zip Code*"
              aria-label="Zip Code*"
              type="text"
              value={this.state.zip || ''}
              onChange={this.handleChange}
              margin="dense"
              fullWidth={true}
              maxLength="40"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
            <button
              data-cy="inputphysicalsubmit"
              type="submit"
              className="primary-button"
              name="showAddressModal"
              onClick={this.handleAddressPutFormSubmit}
            >
              Request Update
            </button>
            <br />
          </form>
        </Row>
        <Row end="xs">
          <Link data-cy="cancel-button" className="secondary-button" to="contactinfo" onClick={this.handelCancel}>
            Cancel
          </Link>
        </Row>
      </div>
    );
  }
}
export default withContext(EditResidentialAddress);
