import IToken from '../../Login/IToken';
import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';
import type { Observable } from 'rxjs';
import { switchMap, map, take } from 'rxjs/operators';
import type PostChildRegistrationRequestModel from './PostChildRegistrationRequestModel';
import SafeStringify from '../../Utilities/SafeStringify';

export default class RegistrationProxy {
  authService: IToken;
  dataApiBaseUrl: string;

  constructor(auth: IToken, dataApiBaseUrl: string) {
    this.authService = auth;
    this.dataApiBaseUrl = dataApiBaseUrl;
  }

  registerChild(postChildRegistrationRequestModel: PostChildRegistrationRequestModel): Observable<any> {
    const observable = this.authService
      .getTokenObservable()
      .pipe(take(1))
      .pipe(
        switchMap((token) => {
          return GlobalAPICall.postObservable(
            `${this.dataApiBaseUrl}/api/Registration/Child`,
            postChildRegistrationRequestModel,
            {
              headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
              },
            }
          );
        })
      )
      .pipe(
        map((res) => {
          if (res.data?.message) {
            return SafeStringify(res.data.message);
          } else {
            return SafeStringify(res.data);
          }
        })
      );

    // Return single response observable instead of continuous response observable
    return observable;
  }
}
