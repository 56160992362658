import React, { Component } from 'react';
import withContext from '../../ContextAPI/Context_HOC';
import { Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import PrayersAndWritingsIcon from '../../assets/img/FundEducation/PrayersAndWritings.png';
import Stories from '../../assets/img/FundEducation/Inspiration.png';

class PrayersAndWritings extends Component {
  render() {
    return (
      <Row>
        <Col xs={12} md={6}>
          <Row>
            <Col sm={2}>
              <img src={PrayersAndWritingsIcon} alt="Sacred Obligation" className="fund-logo" />
            </Col>
            <Col sm={10}>
              <h1>Prayer, Writings, Inspirational Bahá’í Stories, and Resources</h1>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/THE-FUND-PRAYER.pdf" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={PrayersAndWritingsIcon} alt="Prayers" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Prayer for the Fund (PDF)</h4>
              </Col>
            </Row>
          </a>
          <br />
          <Link to="/resources/treasurers-tools/fund-education/prayers-and-writings/fund-stories" className="plain-link">
            <Row className="fund-rows">
              <Col xs={3} lg={2}>
                <img src={Stories} alt="Stories" className="fund-icons" />
              </Col>
              <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Inspirational Stories</h4>
              </Col>
            </Row>
          </Link>
        </Col>
      </Row>
    );
  }
}

export default withContext(PrayersAndWritings);
