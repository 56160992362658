import React, { useState } from 'react';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { ListItem, Collapse } from '@material-ui/core';

const Expansion = (props) => {
  const [expanded, setExpanded] = useState(false);
  const rowData = props.rowData;

  const abmExpansion = (
    <div>
      <ListItem
        data-cy="expansion-list-item"
        onClick={() => setExpanded(!expanded)}
        onKeyUp={function (event) {
          if (event.keyCode === 13) {
            setExpanded(!expanded);
          }
        }}
      >
        {expanded ? <ExpandLess tabIndex={0} /> : <ExpandMore tabIndex={0} />}
        {rowData.fullName ? rowData.fullName : null}
      </ListItem>
      <Collapse data-cy="expansion-collapse-item" in={expanded}>
        <div>
          <ListItem>
            <ul>
              {rowData.email ? (
                <li>
                  Email:{' '}
                  <div>
                    <a href={'mailto:' + rowData.email}>{rowData.email}</a>
                  </div>
                </li>
              ) : null}
              {rowData.contactPhone ? (
                <li>
                  Contact Number:{' '}
                  <div>
                    <a href={'tel:' + rowData.contactPhone}>{rowData.contactPhone}</a>
                  </div>
                </li>
              ) : null}
            </ul>
          </ListItem>
        </div>
      </Collapse>
    </div>
  );

  const assemblyGroupExpansion = (
    <div>
      <ListItem
        data-cy="expansion-list-item"
        onClick={() => setExpanded(!expanded)}
        onKeyUp={function (event) {
          if (event.keyCode === 13) {
            setExpanded(!expanded);
          }
        }}
      >
        {expanded ? <ExpandLess tabIndex={0} /> : <ExpandMore tabIndex={0} />}
        {rowData.name}
      </ListItem>
      <Collapse data-cy="expansion-collapse-item" in={expanded}>
        <div>
          <ListItem>
            {rowData.address ? (
              <ul>
                <li>
                  Address:{' '}
                  <div className="tableCellStyling">
                    {rowData.address.line1} {rowData.address.line2 ? '- ' + rowData.address.line2 : ''}
                  </div>
                  <div className="tableCellStyling">
                    {rowData.address.city}, {rowData.address.state} {rowData.address.zipcode}
                  </div>
                </li>
                <li className="tableCellStyling">
                  Phone number: <a href={'tel:' + rowData.localityPhone}>{rowData.localityPhone}</a>{' '}
                </li>
                <li className="tableCellStyling">
                  Email:{' '}
                  <a target="_blank" rel="noopener noreferrer" href={'mailto:' + rowData.email}>
                    {rowData.email}
                  </a>
                </li>
                <li className="tableCellStyling">Website: {rowData.website}</li>
              </ul>
            ) : null}
          </ListItem>
        </div>
      </Collapse>
    </div>
  );

  const huquqRepExpansion = (
    <div>
      <ListItem
        data-cy="expansion-list-item"
        onClick={() => setExpanded(!expanded)}
        onKeyUp={function (event) {
          if (event.keyCode === 13) {
            setExpanded(!expanded);
          }
        }}
      >
        {expanded ? <ExpandLess tabIndex={0} /> : <ExpandMore tabIndex={0} />}
        {expanded ? 'Close' : 'Open'}
        {!expanded && rowData.areas ? (
          <ul>
            {rowData.areas
              .filter((v, i) => i < 3)
              .map((area) => {
                return <li key={area}> {area}</li>;
              })}
          </ul>
        ) : null}
      </ListItem>
      <Collapse data-cy="expansion-collapse-item" in={expanded}>
        <div>
          <ListItem>
            {rowData.areas ? (
              <ul>
                {rowData.areas.map((area) => {
                  return <li key={area}> {area}</li>;
                })}
              </ul>
            ) : null}
          </ListItem>
        </div>
      </Collapse>
    </div>
  );
  if (props.compName === 'huquqRep') {
    return huquqRepExpansion;
  }

  return props.compName === 'AbmDirectory' ? abmExpansion : assemblyGroupExpansion;
};

export default Expansion;
