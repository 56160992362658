import React, { FunctionComponent } from 'react';
import type { NatconCarousel } from '../../Kentico/DigitalDesign/natcon_carousel';
import parse, { HTMLReactParserOptions } from 'html-react-parser';

const getTextOptions = (): HTMLReactParserOptions => ({
  replace: (domNode) => {
    if (domNode.name === 'p') {
      domNode.attribs = { ...domNode.attribs, class: `natcon-carousel-body-text` };
      return domNode;
    }
    if (domNode.name === 'a') {
      domNode.attribs = { ...domNode.attribs, class: `natcon-carousel-body-text` };
      return domNode;
    }
  },
});

const NatConCarouselItem: FunctionComponent<{ natConCarousel: NatconCarousel }> = ({ natConCarousel }) => {
  const setTextOptions = getTextOptions();
  const bodyRawHtml = natConCarousel.body.resolveHtml();
  const body = parse(bodyRawHtml, setTextOptions);

  return (
    <div>
      <div className="home-carousel-wrapper">
        <div className="home-carousel-image-wrapper">
          <img src={natConCarousel.image.value[0].url} alt="Carousel Placeholder" />
        </div>
        <div className="natcon-carousel-caption">
          <div className="natcon-carousel-content">{body}</div>
        </div>
      </div>
    </div>
  );
};

export default NatConCarouselItem;
