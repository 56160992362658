import { ServiceOpportunities } from '../../Kentico/DigitalDesign/service_opportunities';
import { ServiceOpportunitiesFlat } from '../ServiceOpportunities/Model/service_opportunities_flat';
export default function (data: ServiceOpportunities[]) {
  const updatedResult: ServiceOpportunitiesFlat[] = data.map((item) => ({
    ...item,
    getAllElements: item.getAllElements,
    base__title: { ...item.base__title, value: item.base__title.value.trim() },
    department: { ...item.department, value: item.department.value.trim() },
    isSummerPosition:
      item.position_type?.value[0]?.name && item.position_type.value[0].name.toLowerCase().includes('summer') ? true : false,
  }));

  return updatedResult;
}
