import React, { Component } from 'react';
import PrayerDisplay from './PrayerDisplay';
import SecondLevelLinkList from './SecondLevelLinkList';
import withContext from '../ContextAPI/Context_HOC';

class SecondLevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: '',
      router: [],
      secondtaxonomycodename: this.props.match.params.itemid,
    };
  }
  componentDidMount() {
    this.subscription = this.props.context.prayerContent
      .taxonomy('prayer_category')
      .toObservable()
      .subscribe(async (response) => {
        let display = '';
        let secondlevelcodename = [];
        for (let i = 0; i < response.taxonomy.terms.length; i++) {
          if (response.taxonomy.terms[i].terms.length !== 0) {
            for (let j = 0; j < response.taxonomy.terms[i].terms.length; j++) {
              secondlevelcodename.push(response.taxonomy.terms[i].terms[j].codename);
            }
          }
        }
        for (let i = 0; i < response.taxonomy.terms.length; i++) {
          if (
            response.taxonomy.terms[i].terms.length === 0 &&
            secondlevelcodename.indexOf(this.props.match.params.itemid) === -1
          ) {
            display = <PrayerDisplay urlcall={this.props.match.params.itemid} />;
          } else if (response.taxonomy.terms[i].terms.length !== 0) {
            display = (
              <SecondLevelLinkList
                firsttaxonomycodename={response.taxonomy.terms[i].codename}
                secondtaxonomycodename={this.props.match.params.itemid}
              />
            );
          }
        }
        this.setState({ display: display });
      });
  }
  //I have didupdate lifecyle so that the page update whenever a user clicks between sub menus
  componentDidUpdate(prevProps) {
    if (prevProps.match.params.itemid !== this.props.match.params.itemid) {
      this.subscription = this.props.context.prayerContent
        .taxonomy('prayer_category')
        .toObservable()
        .subscribe(async (response) => {
          let display = '';
          let secondlevelcodename = [];
          for (let i = 0; i < response.taxonomy.terms.length; i++) {
            if (response.taxonomy.terms[i].terms.length !== 0) {
              for (let j = 0; j < response.taxonomy.terms[i].terms.length; j++) {
                secondlevelcodename.push(response.taxonomy.terms[i].terms[j].codename);
              }
            }
          }
          for (let i = 0; i < response.taxonomy.terms.length; i++) {
            if (
              response.taxonomy.terms[i].terms.length === 0 &&
              secondlevelcodename.indexOf(this.props.match.params.itemid) === -1
            ) {
              display = <PrayerDisplay urlcall={this.props.match.params.itemid} />;
            } else if (response.taxonomy.terms[i].terms.length !== 0) {
              display = (
                <SecondLevelLinkList
                  firsttaxonomycodename={response.taxonomy.terms[i].codename}
                  secondtaxonomycodename={this.props.match.params.itemid}
                />
              );
            }
          }
          this.setState({ display: display });
        });
    }
  }
  componentWillUnmount() {
    this.subscription.unsubscribe();
  }
  render() {
    return <div>{this.state.display}</div>;
  }
}

export default withContext(SecondLevel);
