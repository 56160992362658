import { ClusterContactGroup } from '../interfaces/ClusterContactGroup';
import React from 'react';
import TransformNumberToPhoneMask from '../../Utilities/TransformNumberToPhoneMask';
import withContext from '../../ContextAPI/Context_HOC';

export interface DisplayNameEmailPhoneProps {
  contacts: ClusterContactGroup[];
  title: string;
}
function DisplayNameEmailPhone(props: DisplayNameEmailPhoneProps) {
  const { contacts, title } = props;
  return (
    <>
      <h2 className="cluster-contact-title cluster-contact-title-group">{title}</h2>
      {contacts.map((itemRole) => (
        <div key={itemRole.name}>
          <div className="small-bottom-margin">
            <h3 className="cluster-contact-title">{itemRole.name}</h3>
            {itemRole.items.map((itemRoleContact, indexItemRoleContact) => (
              <div key={itemRoleContact.idn} data-testid={'contact-info-idn' + itemRoleContact.idn}>
                <p>
                  <strong>
                    {itemRoleContact.firstName} {itemRoleContact.lastName}
                  </strong>
                </p>
                <p>{itemRoleContact.email && <a href={'mailto:' + itemRoleContact.email}>{itemRoleContact.email}</a>}</p>
                <p>
                  {itemRoleContact.phoneNumber && (
                    <a href={'tel:' + itemRoleContact.phoneNumber}>{TransformNumberToPhoneMask(itemRoleContact.phoneNumber)}</a>
                  )}
                </p>
                {!itemRoleContact.phoneNumber && <p>No Phone number</p>}
                {itemRole.items.length !== indexItemRoleContact + 1 && <hr></hr>}
              </div>
            ))}
            {!itemRole?.items?.length && (
              <div className="small-bottom-margin">
                <p>No one is assigned to this role</p>
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
}
export default withContext(DisplayNameEmailPhone);
