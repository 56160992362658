import useCustomHookForm from "../../Hooks/forms/use-custom-hook-form";
import {
  CustomFieldFormType
} from "../../Hooks/forms/enums/custom-field-form-type";
import React from "react";
import { UsStates } from "./us-states";
import { Countries } from "./countries";
import { CustomField } from "../../Hooks/forms/type/custom-field";
import { Button } from "@mui/material";
import theme from "../../styles/theme";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { name } from "../../Utilities/GeneralRegEx";

export interface VerificationResponseForm {
  originallyEnrolledCity: string;
  originallyEnrolledState: string;
  originallyEnrolledCountry: string;
  bahaiId: string;
  bahaiName: string;
  additionalInformation: string;
  recaptcha?: string;
}

export interface VerificationProps {
  callbackSummary: (response: VerificationResponseForm) => void
}
export const VerificationFieldsArray: CustomField<any>[] = [
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'customTextVerificationInformation',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <h3 className="text-left">Verification Information</h3>,
    cssClassName: 'col-lg-12'
  },
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'originDataSection',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <p className="text-left">
      <span className="font-weight-bold">Please provide the location where you originally enrolled.</span>
    </p>,
    cssClassName: 'col-lg-12'
  },
  {
    type: CustomFieldFormType.Text,
    formControlName: 'originallyEnrolledCity',
    label: 'City',
    placeholder: 'Ex: City',
    help: '',
    cssClassName: 'col-lg-4 p-2'
  },
  {
    type: CustomFieldFormType.Select,
    formControlName: 'originallyEnrolledState',
    label: 'State',
    placeholder: 'Ex: Florida',
    help: '',
    select: {
      options: UsStates(false),
      multiple: false
    },
    cssClassName: 'col-lg-4 p-2'
  },
  {
    type: CustomFieldFormType.Select,
    formControlName: 'originallyEnrolledCountry',
    label: 'Country',
    placeholder: 'Ex: United States of America',
    help: '',
    select: {
      options: Countries,
      multiple: false
    },
    cssClassName: 'col-lg-3 p-2'
  },
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'originBahaiInfoSection',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <p className="text-left">
      <span className="font-weight-bold">Please provide your Bahá&apos;í ID and name (if different) at the time of enrollment. You should copy this data from your Bahá&apos;í ID card if available.</span>
    </p>,
    cssClassName: 'col-lg-12'
  },
  {
    type: CustomFieldFormType.Number,
    formControlName: 'bahaiId',
    label: 'ID',
    placeholder: 'Ex: 12345',
    help: '',
    cssClassName: 'col-lg-4 p-2'
  },
  {
    type: CustomFieldFormType.Text,
    formControlName: 'bahaiName',
    label: 'Name',
    placeholder: 'Ex: John Doe',
    help: '',
    validators: {
      pattern: {
        pattern: name,
        message: ' Baha\'i Name should be valid'
      },
    },
    cssClassName: 'col-lg-4 p-2'
  },
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'additionalInfoQuestionSection',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <p className="text-left">
      <span className="font-weight-bold">Additional Information (such as Ruhi courses completed) or Questions</span>
    </p>,
    cssClassName: 'col-lg-12'
  },
  {
    type: CustomFieldFormType.Textarea,
    formControlName: 'additionalInformation',
    label: 'Additional information',
    placeholder: 'Ex: I have a Question...',
    help: '',
    cssClassName: 'col-lg-12 p-2'
  },
];

export default function SummaryForm(props: VerificationProps) {
  const [fields, useFormObject] = useCustomHookForm<VerificationResponseForm, string | number>({
    customFieldsArray: VerificationFieldsArray,
    initialValues: {
      originallyEnrolledCity: '',
      originallyEnrolledState: '',
      originallyEnrolledCountry: '',
      bahaiId: '',
      bahaiName: '',
      additionalInformation: '',
    },
  });
  const { handleSubmit } = useFormObject;
  const onSubmit = props.callbackSummary;
  const localSubmit = async (data: VerificationResponseForm) => {
    onSubmit({ ...data })
  }

  return (
    <form onSubmit={handleSubmit(localSubmit) as never}>
      <div className="row">
        {fields}
        <div className="col-sm-4" />
        <div className="col-sm-4">
          <Button size="small"
            type="submit" sx={{ width: '100%' }}>
            Continue
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        </div>
        <div className="col-sm-4"></div>
      </div>
    </form>
  )
}
