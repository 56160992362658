interface AccountValidationData {
  password: string;
  confirmPassword: string;
  email: string;
  confirmEmail: string;
  question1Id: string;
  question1Answer: string;
  question2Id: string;
  question2Answer: string;
  question3Id: string;
  question3Answer: string;
}

export function validatePassword(data: { password: string; confirmPassword: string }) {
  let message = [];
  let count = 0;

  if (data.password !== data.confirmPassword) {
    message.push('The password and confirm password do not match. ');
  }

  if (data.password.length < 8) {
    message.push('Your password does not have at least 8 characters. ');
  }
  if (/[A-Z]/.test(data.password) === true) {
    count++;
  }
  if (/[a-z]/.test(data.password) === true) {
    count++;
  }
  if (/[0-9]/.test(data.password) === true) {
    count++;
  }
  if (/[~`!$%*+=\-\];,/{}|:>]/.test(data.password) === true) {
    count++;
  }

  if (count < 3 || /[#&<]/.test(data.password) === true) {
    message.push('Your password does not meet 3 of the following character requirements below. ');
  }

  return message;
}

export default function setupAccValidation(data: AccountValidationData) {
  let message = [];

  if (data.email !== data.confirmEmail) {
    message.push('The email and confirm email do not match. ');
  }

  if (
    /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z0-9](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+$/.test(
      data.email
    ) === false &&
    data.email !== '' &&
    data.email !== null
  ) {
    message.push('Invalid email format. ');
  }

  message = message.concat(validatePassword(data));

  if (data.question1Id === '' || data.question2Id === '' || data.question3Id === '') {
    message.push('All three questions must be selected. ');
  }
  if (data.question1Answer === '' || data.question2Answer === '' || data.question3Answer === '') {
    message.push('The answers to all three questions must be answered. ');
  }
  if (message[0] !== undefined) {
    message = message.join('') as any;
    return Promise.reject({ message: message });
  }
  return false;
}
