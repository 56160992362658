import React from 'react';
import Slider from 'react-slick';
import './FlickrGallery.css';
import GlobalAPICall from '../GlobalComponents/GlobalAPICall';

class FlickrGallery extends React.Component {
  _cancel = () => {};

  constructor(props) {
    super(props);
    this.state = {
      slides: [],
    };
  }

  async componentDidMount() {
    const setCanceller = (c) => (this._cancel = c);
    var thisFlickrGallery = this;
    GlobalAPICall.get(
      'https://api.flickr.com/services/rest/',
      {
        params: {
          method: 'flickr.photosets.getPhotos',
          api_key: this.props.apiKey,
          photoset_id: this.props.setId,
          format: 'json',
          extras: 'description,url_m,url_o',
          nojsoncallback: true,
        },
      },
      setCanceller
    ).then(
      function (response) {
        thisFlickrGallery.setState({
          slides: response.data.photoset.photo.map(function (photo) {
            return {
              key: photo.id,
              url: photo.url_o,
              description: photo.description._content,
            };
          }),
        });
      },
      (e) => {
        const isCancel = GlobalAPICall.isCancel(e);
        if (!isCancel) {
          throw e;
        }
      }
    );
  }

  componentWillUnmount() {
    this._cancel('unmounting');
  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 10000,
      width: '80vw',
    };
    return (
      <div>
        <Slider {...settings}>
          {this.state.slides.map(function (slide) {
            return (
              <div className="slide" key={slide.key}>
                <div className="slide-container">
                  <img src={slide.url} alt={slide.description} />
                  <div className="slide__caption">{slide.description}</div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}

export default FlickrGallery;
