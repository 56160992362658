import React, { useState } from 'react';
import { ListItem, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

function NatConDropDown(props) {
  const [openDetails, setOpenDetails] = useState(false);

  return (
    <div key={props.chapterKey}>
      {/* button onClick to turn off and on the collpasable */}
      <ListItem button onClick={() => setOpenDetails(!openDetails)}>
        <div className="right-margin">{!openDetails ? 'Read More ...' : 'Read Less ...'}</div>
        {openDetails ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      {/* This is only visible if openDetails is true */}
      <Collapse in={openDetails} timeout="auto" unmountOnExit>
        <div>{props.display}</div>
      </Collapse>
    </div>
  );
}
export default NatConDropDown;
