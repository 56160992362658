import IToken from '../../Login/IToken';
import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';
import { ReplaySubject, combineLatest } from 'rxjs';
import { map, switchMap, take, filter, tap } from 'rxjs/operators';
import type MyProfileResponseModel from './MyProfileResponseModel';
import type IMyProfileProxy from './IMyProfileProxy';

export default class MyProfileProxy implements IMyProfileProxy {
  profileDataSubject = new ReplaySubject<MyProfileResponseModel>(1);
  authService: IToken;
  refreshComplete: boolean = false;

  constructor(auth: IToken) {
    this.authService = auth;
    this.refresh();
  }

  setChild(updatedChildData: any) {
    const observable = this.authService
      .getTokenObservable()
      .pipe(take(1))
      .pipe(
        switchMap((token) => {
          return GlobalAPICall.putObservable(process.env.REACT_APP_API_URL + `/api/MyProfile/Child`, updatedChildData, {
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          });
        })
      )
      .pipe(map((x) => x.data));
    return observable;
  }

  getChild(idn: number) {
    return this.authService
      .getTokenObservable()
      .pipe(filter((token) => !!token))
      .pipe(
        switchMap((token) =>
          GlobalAPICall.postObservable(
            process.env.REACT_APP_API_URL + '/api/MyProfile/ChildGet',
            { childIdn: idn },
            {
              headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
              },
            }
          )
        )
      )
      .pipe(map((x) => x.data));
  }

  // Returns an observable that will continuously report the latest profile data
  get() {
    return this.profileDataSubject.asObservable();
  }

  refresh() {
    this.authService
      .getTokenObservable()
      .pipe(filter((token) => !!token))
      .pipe(
        switchMap((token) =>
          GlobalAPICall.getObservable(process.env.REACT_APP_API_URL + `/api/myprofile`, {
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          })
        )
      )
      .pipe(
        map((x) => {
          this.refreshComplete = true;
          return x.data;
        })
      )
      .subscribe(this.profileDataSubject);
  }

  // Returns a single response observable but will also update the continuous observable from the get() method
  set(newProfileData: MyProfileResponseModel) {
    const combinedObservables = combineLatest([this.authService.getTokenObservable(), this.profileDataSubject.asObservable()]);

    const observable = combinedObservables
      .pipe(take(1))
      .pipe(
        switchMap(([token, currentProfileData]) => {
          const dataToSubmit = { ...currentProfileData, ...newProfileData } as any;
          delete dataToSubmit.languagePreferenceOptions;
          delete dataToSubmit.raceEthnicityOptions;
          delete dataToSubmit.stateOptions;
          delete dataToSubmit.suffixOptions;
          delete dataToSubmit.titleOptions;
          return GlobalAPICall.postObservable(process.env.REACT_APP_API_URL + `/api/myprofile`, dataToSubmit, {
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          });
        })
      )
      .pipe(map((x) => x.data))
      // update continuous observable from the get() method
      .pipe(tap((x) => this.profileDataSubject.next(x)));

    // Return single response observable instead of continuous response observable
    return observable;
  }
}
