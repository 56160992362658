import React from 'react';
import { Link } from 'react-router-dom';

function Ridvan() {
  return (
    <div>
      <h1>Riḍván Election Materials 2024</h1>
      <p>
        <em>
          For questions about any of these materials or the formation process, please contact the Membership Office by email at{' '}
          <a href="mailto:membership@usbnc.org">membership@usbnc.org</a>, or by phone at{' '}
          <a className="nowrap" href="tel:(847) 733-3445">
            (847) 733-3445
          </a>
          . For questions regarding the formation process, please contact the Office of Community Administration by email at{' '}
          <a href="mailto:community@usbnc.org">community@usbnc.org</a> or by phone at{' '}
          <a className="nowrap" href="tel:(847) 733-3485">
            (847) 733-3485
          </a>
          .
        </em>
      </p>
      <p>
        Below are the support documents and forms for Local Spiritual Assemblies and Registered Groups for formation at Riḍván.
      </p>
      <p>To view any document, click on that document’s link. It will be displayed in Adobe® Reader™.</p>
      <p>
        To print a paper copy of any document, click on the printer icon in the Adobe® Reader™ toolbar once the document appears.
      </p>
      <p>
        You must have Adobe® Reader™ installed on your system to view and print these documents. If you don’t have this free
        software,{' '}
        <a target="_blank" rel="noopener noreferrer" href="http://get.adobe.com/reader/">
          click here
        </a>{' '}
        to download it.
      </p>
      <br />
      <h3>Local Spiritual Assemblies</h3>
      <p>
        <strong>Educational Materials</strong>
      </p>
      <ul>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=2t7G3skZbTg">
            Video: Summons to Service
          </a>
        </li>
        <li>
          Video: Bahá’í Electoral Process (
          <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=-eOVRW22D6I">
            Watch
          </a>
          ,{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.dropbox.com/s/bpwylkisl1fu218/Bah%C3%A1%E2%80%99%C3%AD%20Elections.mov?dl=0"
          >
            Download
          </a>
          )
        </li>
        <li>
          Video: A Model of Spiritually Based Elections (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.dropbox.com/s/g81q6a4i7j5g5vd/A%20Model%20of%20Spiritually%20Based%20Elections%202019.mov?dl=0"
          >
            Download
          </a>
          )
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/unit-convention/2024/Book+8.2+section+12+and+8.3+section+25+extracts+re+serving+on+institutions.pdf">
            Ruhi Book 8.2 section 12 and 8.3 section 25
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/unit-convention/2024/Book+10.2+sections+8-9+extracts+on+consultation.pdf">
            Ruhi Book 10.2 sections 8-9
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/Functions-of-the-Local-Spiritual-Assembly.pdf">
            Compilation on the “Functions of the Local Spiritual Assembly” (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/uhj-on-importance-of-elections.pdf">
            Importance of Bahá’í Elections – Letter from the Universal House of Justice dated March 25, 2007 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/The-Nature-of-Bahai-Elections.pdf">
            The Nature of Bahá’í Elections – Quotations from the Writings of Shoghi Effendi (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2022/The+Nature+of+Bah%C3%A1%E2%80%99%C3%AD+Elections.docx">
            The Nature of Bahá’í Elections – Quotations from the Writings of Shoghi Effendi (MS Word)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/local-spiritual-assembly-compilation.pdf">
            The Local Spiritual Assembly – Compilation prepared by the Research Department of the Universal House of Justice (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2022/Purpose-and-Formation-of-the-Local-Spiritual-Assembly-compilation-Feb-2022.pdf">
            Purpose and Formation of the Local Spiritual Assembly – compilation (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2022/Purpose-and-Formation-of-the-Local-Spiritual-Assembly-compilation-Feb-2022.docx">
            Purpose and Formation of the Local Spiritual Assembly – compilation (MS Word)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/Purpose+and+Formation+of+the+Local+Spiritual+Assembly+-+compilation+(Persian).pdf">
            Purpose and Formation of the Local Spiritual Assembly – compilation (Persian) (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/Purpose+and+Formation+of+the+Local+Spiritual+Assembly+-+compilation+(Spanish).pdf">
            Purpose and Formation of the Local Spiritual Assembly – compilation (Spanish) (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/Purpose+and+Formation+of+the+Local+Spiritual+Assembly+-+compilation+(Swahili).pdf">
            Purpose and Formation of the Local Spiritual Assembly – compilation (Swahili) (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/Resources+for+Local+Spiritual+Assemblies+rev+2024-06-25.docx">
            Resources for LSAs (DOCX)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Assemblies%26Communities/Compilation+on+the+Administrative+Order+and+its+evolution.pdf">
            Compilation on the Administrative Order and its evolution (PDF)
          </a>
        </li>
      </ul>
      <h3>
        <a target="_blank" rel="noopener noreferrer" href="https://um.obsbahai.org/Quick_Guide.pdf">
          Online Balloting System (OBS) User Guides
        </a>
      </h3>
      <p>
        <strong>Support Documents</strong>
      </p>
      <ul>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/20240301+NSA+on+Preparation+for+2024+Ridvan+Election.pdf">
            National Spiritual Assembly on Preparation for 2024 Ridván Elections (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/20240301+NSA+on+Preparation+for+2024+Ridvan+Election.doc">
            National Spiritual Assembly on Preparation for 2024 Ridván Elections (MS Word)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/20240420+To+the+friends+participating+in+the+Ridvan+election.pdf">
            National Spiritual Assembly Ridván 2024 Letter on Local Spiritual Assembly Elections (English) (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/20240420+To+the+friends+participating+in+the+Ridvan+election.docx">
            National Spiritual Assembly Ridván 2024 Letter on Local Spiritual Assembly Elections (English) (MS Word)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/20240420+To+the+friends+participating+in+the+Ridvan+election+(Spanish).pdf">
            National Spiritual Assembly Ridván 2024 Letter on Local Spiritual Assembly Elections (Spanish) (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/20240420+To+the+friends+participating+in+the+Ridvan+election+(Spanish).docx">
            National Spiritual Assembly Ridván 2024 Letter on Local Spiritual Assembly Elections (Spanish) (MS Word)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/20240420+To+the+friends+participating+in+the+Ridvan+election+(Persian).pdf">
            National Spiritual Assembly Ridván 2024 Letter on Local Spiritual Assembly Elections (Persian) (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/20240420+To+the+friends+participating+in+the+Ridvan+election+(Swahili).pdf">
            National Spiritual Assembly Ridván 2024 Letter on Local Spiritual Assembly Elections (Swahili) (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/Implementing+two-stage+elections+2024+-+Updated+February+13%2C+2024.pdf">
            Implementing the Two-Stage Electoral Process (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/Instructions+for+Assembly+Formation+2024.pdf">
            Instructions for Assembly Formation 2024 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/Instructions+for+Assembly+Formation+2024.docx">
            Instructions for Assembly Formation 2024 (MS Word)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/Quick+Reference+Sheet+2024.pdf">
            Assembly Formation Quick Reference Sheet 2024 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/Quick+Reference+Sheet+2024.docx">
            Assembly Formation Quick Reference Sheet 2024 (MS Word)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/Q+and+A+2024.pdf">
            Q &amp; A about Assembly Formation 2024 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/Q+and+A+2024.docx">
            Q &amp; A about Assembly Formation 2024 (MS Word)
          </a>
        </li>
        <li>
          <Link to="/glsa/chapter_2___baha_i_elections">
            <em>Guidelines for Local Spiritual Assemblies: Developing Distinctive Bahá’í Communities</em> – Chapter 2: Bahá’í
            Elections (updated February 2024)
          </Link>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/persian-election-guidance.pdf">
            Bahá’í Elections – Guidance in Persian (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/eMembership2+-+Assembly+Election+Guide+2024.pdf">
            eMembership2: LSA Election Guide 2024 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/eMembership2+-+Assembly+Election+Guide+2024.docx">
            eMembership2: LSA Election Guide 2024 (MS Word)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/eMembership2+-+Helpful+Election+Tools+2024.pdf">
            eMembership2: Helpful Election Tools 2024 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/eMembership2+-+Helpful+Election+Tools+2024.docx">
            eMembership2: Helpful Election Tools 2024 (MS Word)
          </a>
        </li>
      </ul>
      <p>
        <strong>Assembly Formation &amp; Election Forms</strong>
      </p>
      <ul>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/LSA+Election+Report+Form+2024.pdf">
            Local Spiritual Assembly Election Form 2024 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/Joint+Declaration+2024.pdf">
            Local Spiritual Assembly Joint Declaration Report Form 2024 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/LSA+Officers'+Election+2024.pdf">
            Local Spiritual Assembly Officer Election Report Form 2024 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/By-Election+2024.pdf">
            Local Spiritual Assembly By-Election Report Form 2024 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/Two-Stage+Delegate+Election+Report+2024.pdf">
            Local Spiritual Assembly Two-Stage Election - Delegate Election Report 2024 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/Two-Stage+Summary+Delegate+Report+2024.pdf">
            Local Spiritual Assembly Two-Stage Election - Summary Delegate Report 2024 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/Two-Stage+Assembly+Election+Report+2024.pdf">
            Local Spiritual Assembly Two-Stage Election - Assembly Election Report 2024 (PDF)
          </a>
        </li>
      </ul>
      <h4>Registered Groups</h4>
      <p>
        <strong>Support Documents</strong>
      </p>
      <ul>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/Guidance+for+Groups+2024-02-27.pdf">
            Guidance for Bahá’í Groups (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/eMembership2+-+Group+Registration+Guide+2024.pdf">
            eMembership2: Group Registration Guide 2024 (PDF)
          </a>
        </li>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/eMembership2+-+Group+Registration+Guide+2024.docx">
            eMembership2: Group Registration Guide 2024 (MS Word)
          </a>
        </li>
      </ul>
      <p>
        <strong>Group Registration Form</strong>
      </p>
      <ul>
        <li>
          <a href="https://bahai-web.s3.amazonaws.com/Ridvan-Elections/2024/Group+Registration+2024.pdf">
            Bahá’í Group Registration Form 2024 (PDF)
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Ridvan;
