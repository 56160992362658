import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import FlickrGallery from './FlickrGallery';
import NatConDropDown from './NatConDropDown';
import withContext from '../ContextAPI/Context_HOC';

class NatCon2019 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenId: null,
    };
    this.display = {
      atmosphere: (
        <div>
          <p>
            That morning, a hush came across Foundation Hall as delegates gathered for the election, one of their two sacred
            duties, at the Bahá’í House of Worship in Wilmette, Illinois.
          </p>
          <p>
            More than 100 guests joined two members of the Continental Board of Counselors in witnessing 171 ballots being cast.
          </p>
          <p>
            As he called the session to order, Convention chair David F. Young briefed the delegates on procedures for carrying
            out the vote. He conveyed a long-standing instruction that applies to the election of any Bahá’í institution: to
            approach decisions on voting “in a prayerful attitude, seeking divine guidance and confirmation.”
          </p>
          <p>
            As the Convention secretary, Muin Afnani, read further instructions on filling out ballots, he noted that as this was
            a national election without nominations, sometimes more than a full name is needed. Among other things, he encouraged
            delegates to include further identifying information if they voted for someone with a common name “such as Smith or
            Jones or Rouhani,” drawing an appreciative chuckle.
          </p>
          <p>
            Each valid ballot would contain nine names. Any adult Bahá’í in good standing living in the 48 contiguous states and
            the District of Columbia was eligible to serve, except for the Counselors.
          </p>
          <p>The chief teller and five assistants then reported to the front of the hall to begin their duties.</p>
          <p>Each read a prayer or a passage from guidance of Shoghi Effendi or the Universal House of Justice.</p>
          <p>
            Once the tellers took their seats, only the sound of the ventilation system could be heard as the delegates present
            filled out their ballots. Many spent several minutes in contemplation before their final decisions.
          </p>
          <p>
            When the chair determined all ballots had been completed, delegates arose, roughly in alphabetical order, and lined up
            along the right edge of Foundation Hall. As the secretary called each one’s name, the delegate walked to a clear
            plastic box at the front of the hall and dropped in a sealed ballot envelope.
          </p>
          <p>
            Many beamed in joy or satisfaction. Many were brisk and intent, or perhaps experiencing more complex emotions. A few
            kissed their envelopes. One held his ballot over his heart before submitting it — significantly, as he had received
            that heart in a transplant years earlier. Only a handful of absentee votes were placed.
          </p>
          <p>“All 171 delegates have voted,” the secretary announced, and the audience arose in warm applause.</p>
          <p>
            With that completed, the tellers brought the ballot box to a secure location for counting. Results were to be reported
            that afternoon.
          </p>
        </div>
      ),

      consultation: (
        <div>
          <p>
            Her thoughts were part of a “lightning round” of 30- to 60-second reflections the morning of April 28 that led into
            the Counselors’ closing remarks.
          </p>
          <p>
            Some delegates rendered appreciation for what uplifted them during Convention. They touched on such topics as the
            nutritious and tasty food provided; the spiritual food of international music and other artistry; how delegates
            connected the work in neighborhoods with the issues of society; and how speakers universally put into words how joyous
            the Plan’s framework for action is.
          </p>
          <p>
            Others suggested ways to make the experience in future years even better: Change the way first-time and other
            delegates are prepared; experiment with the physical space of Convention; review how recommendations are shaped; and
            set up a space outside Foundation Hall for representatives of agencies of the National Assembly.
          </p>
          <p>
            The lightning round followed what is a special element of each U.S. Bahá’í National Convention: consultation open to
            whatever delegates wish to address.{' '}
          </p>
          <p>
            This 111th annual Convention was no different, as delegates presented thoughts born of reflection on remarks made
            earlier in the weekend or emerging in the Holiest House of Worship from the depths of their hearts.
          </p>
          <p>Thoughts centered on:</p>
          <p>
            <strong>Teaching</strong>
          </p>
          <ul>
            <li>
              Letting go and watching friends of the Faith steer core activities is easier when we realize God is in control.
            </li>
            <li>
              It’s a false dichotomy to think that our work with people engaged in service isn’t teaching. Everyone is on a path
              toward Baha’u’llah.
            </li>
            <li>
              The reflection gathering is a good place to discuss, with an eye toward inviting them to declare belief, where
              individuals are along a spiritual path.
            </li>
            <li>
              When gently inviting people to enroll as Baha’is, it’s appropriate to ask what might be a barrier to taking that
              step.
            </li>
            <li>
              Baha’is have done mass teaching, a media campaign and now the framework for action. All that we’ve done has been
              right for its time and is part of what we are now.
            </li>
          </ul>
          <p>
            <strong>Youth and junior youth</strong>
          </p>
          <ul>
            <li>
              The junior youth program is changing young people’s lives and, just as important, changing how they see the
              potential their neighborhoods hold.
            </li>
            <li>More can be done to aid youths who have had to pull back from activity to make money for college.</li>
            <li>
              Young people have been at the forefront of the successes of many clusters, and we need to create spaces to hear
              their perspectives.
            </li>
            <li>College campuses and neighborhoods and students are receptive populations.</li>
          </ul>
          <p>
            <strong>Cluster advancement</strong>
          </p>
          <ul>
            <li>
              We must transform ourselves to transform the world, and our clusters likewise must look far different in five years
              than they do today.
            </li>
            <li>
              Instead of taking mental snapshots of our cluster’s progress, we can take mental video clips that show not only
              where we are but where we’re going.
            </li>
            <li>
              Expanding a cluster’s nucleus of people engaged in service can create complications, but those complications — such
              as a person needing transportation — can be overcome.
            </li>
          </ul>
          <p>
            <strong>Race</strong>
          </p>
          <ul>
            <li>
              Those working in the neighborhood with people of different ethnicities can see the effects of racial injustice,
              develop true friendships, and start to dismantle racism.
            </li>
            <li>Love is the answer for having conversations on race, just as the love of fellow delegates healed her heart.</li>
            <li>
              Are our Baha’i communities refuges from racism? Services at black churches often provide an oppressed people their
              only refuge from racism.
            </li>
          </ul>
          <p>
            <strong>Discourses</strong>
          </p>
          <ul>
            <li>
              Each of us has a responsibility to share, within our profession and in other spaces, the impact our global
              community-building efforts are having.
            </li>
            <li>
              The issue of same-sex relationships is a barrier to discourse with many people. Society is transforming, and if we
              can’t address this issue it will fester.
            </li>
          </ul>
          <p>
            <strong>Delegate reports</strong>
          </p>
          <ul>
            <li>
              The guests delegates bring to Convention should be urged to participate in their reports back home to help convey
              the spirit as well as the content of the proceedings.
            </li>
            <li>
              In talking to people in the electoral unit as preparation for Convention, delegates can ask as well for thoughts on
              how to enable as many Baha’is as possible to hear their reports.
            </li>
            <li>Guidance from the institutions is the North Star. Go home and distribute compasses.</li>
          </ul>
          <p>
            Two other sweet moments during open consultation: A delegate recognized the presence of her father, who flew from his
            pioneering post in Brazil to be her guest at Convention. And a delegate, after relating that his father, a Hand of the
            Cause, had chanted during the dedication of the Temple, chanted a prayer his father loved.
          </p>
        </div>
      ),

      globalProsperity: (
        <div>
          <p>
            And it was oriented toward the spiritual forces running through the law of the Right of God that can steer
            individuals, and humanity as a whole, toward greater alignment with God’s Plan and sustainable global prosperity.{' '}
          </p>
          <p>By design, hardly a word was spoken about calculating one’s obligation and making payment.</p>
          <p>
            Representing the Board this evening were members Danita Brown, Robert Malouf and Mashiyyat Rahmani, all of whom
            expressed great appreciation to the National Spiritual Assembly for making precious Convention time available for this
            activity. They also praised the “wholehearted and collaborative relationship” that the Board has had with the National
            Assembly since its inception in 1986.
          </p>
          <p>
            The Board first gave the floor to Counselor Nwandi Lawson, who pointed out the “revolutionizing” influence of the law
            of Huqúqu’lláh in “uniting our spiritual and material beings.”
          </p>
          <p>
            Some faith traditions, she said, will flatly consider wealth to be evil and counsel followers to give up all their
            wealth and rely on charity. Other traditions may use wealth and accomplishments as a measure of a person’s worth.
            Bahá’u’lláh, though, “calls us to reconsider this and know that neither extreme could possibly be true.”
          </p>
          <p>
            Quoting the guidance of the Universal House of Justice that “The welfare of any segment of humanity is inextricably
            bound up with the welfare of the whole,” she extolled the Right of God as a way of contributing to welfare that
            respects both the wealthy and those of modest means — as well as remedying the injustice of a relative few people
            hoarding the majority of financial and natural resources for their own gain. “This is holistic,” she said.
          </p>
          <p>
            As a whimsical illustration of how these concepts can help junior youths develop critical thinking skills, she
            recalled one time when several families got together in a home to study a message of the House of Justice. They broke
            into small groups to study short passages, and one group with several young people was “placed near the kitchen [and]
            ate almost all of the cookies” being baked for the gathering. So to illustrate the principle they were studying on the
            welfare of the whole, “They took the two cookies that were remaining and tried to divide them up among the rest of us.
            … They clearly articulated that if any one group does not consider the welfare of the whole, all the rest of us will
            get a handful of crumbs.”
          </p>
          <p>
            Brown then prepared the ground for wider discussion by reviewing some fundamentals of the Right of God, as expressed
            in a few passages by the Blessed Beauty and by the House of Justice.{' '}
          </p>
          <p>
            Among them: purifying one’s riches is one of the highest-ranking obligations, as Tablets of Bahá’u’lláh tell us.
            Acceptance of Huqúq payment is dependent on a “spirit of joy, fellowship and contentment.” Observance of the law is a
            “private act of conscience” between a believer and God, and is “a direct and vital link between the individual
            believer and the Head of Faith.” It will not only “impart prosperity and protection” to believers who observe it, as
            well as their children, but also “contribute to the spiritualization of humanity through the promotion of a new
            attitude to the acquisition and use of material resources.”
          </p>
          <p>
            “These are very powerful concepts,” Brown said. “A lot of times we get hung up by the material aspects of the
            computations.” But she re-emphasized that application of the law has spiritual influences, not just for the benefit of
            individuals but, on the level of society, to release the power of love to “uplift the downtrodden.”{' '}
          </p>
          <p>
            A reading of the March 1, 2017, letter of the Universal House of Justice to the Bahá’ís of the world refreshed
            Convention delegates and guests on the spiritual and material conditions for global prosperity.{' '}
          </p>
          <p>
            Noting that the fifth and seventh paragraphs of that letter particularly resonate with the application of Huqúqu’lláh,
            Malouf moderated a discussion that he said the Board hoped might help increase understanding of “the profundity, the
            beauty, the elegance, the sacredness and the power that is in this law.”
          </p>
          <p>
            Re-reading of a couple of sentences from the March 1 letter was followed by questions designed to help the
            participants link their principles with how application of the Right of God can advance them. “This is the first time
            we’ve done this, so we’re learning with you,” Malouf added.
          </p>
          <p>
            In this vein, the first sentence re-read was: “Our call to examine the implications of the Revelation of Bahá’u’lláh
            for economic life is intended to reach Bahá’í institutions and communities but is directed more especially to the
            individual believer.”
          </p>
          <p>
            Questions following from that were: “How can we as delegates assist in directing this call more especially to the
            individual believer?” and “How can we collaborate with the Institution of Huqúqu’lláh to accomplish this?”
          </p>
          <p>Ideas this stimulated among delegates included:</p>
          <ul>
            <li>
              Going beyond reports to the community about the Convention, the need to “personally exemplify what we’re reading,
              what we’re studying and what we’re learning” in daily family life.
            </li>
            <li>
              The “imperative that we provide the friends with an education about Huqúqu’lláh” and encourage understanding of
              “what a bounty it is for them to learn about this law,” partly through calling on Huqúqu’lláh representatives, who
              are available in every state.
            </li>
            <li>
              The special receptivity of young adults who have participated in seminars of the Institute for Studies in Global
              Prosperity, who are ”already thinking at a foundational level” about the nature of work, wealth and justice and
              about how participation in the work of the Five Year Plan can shape their lives to reflect its inherent “spirit of
              generosity.”
            </li>
            <li>
              Educational techniques for deepenings, such as introducing positive spiritual and economic principles into
              discussion, then demonstrating how they all relate to the Right of God.
            </li>
            <li>
              The motivation to share the principles of this law with as many believers as possible, channeled into the ongoing
              quest to find the many enrolled Bahá’ís whose addresses may not be known to the administration.
            </li>
            <li>
              The power of teaching the Faith using the principles inherent in this law, especially among certain populations such
              as African Americans, many of whom keenly appreciate the concept of “giving what God has given you back to God.”
            </li>
          </ul>
          <p>
            Experience shows two populations have particular receptivity to those principles, Malouf observed: young adults and
            “the community of interest. … They’re thirsty … they’re looking for justice. They love it, they grasp it.”
          </p>
          <p>
            The second sentence from the March 1 letter at the center of study was: “If a new model of community life, patterned
            on the teachings, is to emerge, must not the company of the faithful demonstrate in their own lives the rectitude of
            conduct that is one of its most distinguishing features?”
          </p>
          <p>
            The follow-up question: “How is this related to the institution of the Bahá’í National Convention and the Institution
            of Huqúqu’lláh, and most especially its representatives?”
          </p>
          <p>Concepts expressed by delegates included:</p>
          <ul>
            <li>
              The need to spread awareness that the Right of God is for Bahá’ís of all ages to learn about, so that when children
              approach age 15 they will be ready to talk about the law and naturally take on its responsibilities.{' '}
            </li>
            <li>
              The importance of considering payments as an obligation that believers fulfill (though it was pointed out that
              Bahá’ís may contribute in excess of their obligation if moved to do so).
            </li>
            <li>
              Implications that “rectitude of conduct” has in all aspects of our lives and in the conduct of the delegates in
              carrying out their duties; some of them the Guardian cited include justice, equity, truthfulness, honesty,
              fair-mindedness, reliability and trustworthiness.{' '}
            </li>
            <li>
              Recognizing opportunities to assist others in carrying out the law of the Right of God, such as in the role of
              helping execute the provisions of the will of a fellow Bahá’í.
            </li>
          </ul>
          <p>
            In closing the session, Rahmani encouraged the delegates in their reports to re-emphasize the value, for believers
            across the country, of enlisting the help of Huqúqu’lláh representatives in deepening their understanding.
          </p>
        </div>
      ),

      socialAction: (
        <div>
          <p>
            A wealth of sharing was lavished on the topic of public discourse, revealing a breadth of involvement. Fewer remarks
            addressed the field of social action — projects emerging organically from the framework for action of the Plan.
          </p>
          <p>
            Counselor Natasha Bruss prefaced the social action consultation with a contrast of another kind by sketching a picture
            of two fictional communities:
          </p>
          <p>
            One received services delivered top-down from outsiders who perceived a material problem and rushed in to solve it,
            leaving residents with a useless facility.
          </p>
          <p>
            In the other, inhabitants engaged in a spiritual process of learning how to hold meaningful conversations and
            attracting neighbors to them. This awakened in the neighbors an awareness they can be protagonists and an eagerness to
            acquire the qualities and skills to read their reality and act on it together for everyone’s benefit.
          </p>
          <p>
            The latter is what Bahá’ís have begun to develop, said Bruss. And it’s a long process, begun ideally in junior youth
            groups so that by the time these young people become adults they have engaged in increasingly complex service and are,
            in short, “empowered and capable” to remake neighborhoods in line with Bahá’u’lláh’s vision for humanity.
          </p>
          <p>As consultation on social action opened, a few delegates zeroed in on developing the capacities of junior youths:</p>
          <ul>
            <li>
              Modest but inspiring results were attained when the members of a junior youth group read the reality of their
              neighborhood, identified several needs and decided to tackle hunger. The young people’s consultation was at time
              messy — echoing that of larger society — but in the end they rallied around a plan and carried it out.
            </li>
            <li>
              The animator was educated as much as the junior youths were when he and a female co-animator mentored a group of
              young ladies. In the process of preparing for and carrying out meetings of the group, he learned much about how
              girls communicate and about the equality of women and men.
            </li>
            <li>
              A team is aiding the formation of junior youth groups and the transition of middle-schoolers into the institute
              process at age 15. A story of true friendship emerged when group members came up with practical steps to help
              another participant who is going through a lot in her life.
            </li>
            <li>
              A cluster’s junior youth program wasn’t growing because not enough people could be found to visit families in the
              area. So the families were brought together and asked how they wanted to advance the program. Those families and an
              expanding nucleus of Bahá’ís and friends are now taking charge of forming and accompanying junior youth groups.
            </li>
          </ul>
          <p>
            Other delegates spotlighted the other end of the social and economic development spectrum, outlining their experiences
            with Bahá’í-inspired organizations that employ the same approach but on a national or global scale.
          </p>
          <p>
            Still other arenas touched on were battling Islamophobia, advancing the harmony of science and religion, and
            sponsoring a school writing contest on race amity.
          </p>
          <p>
            Turning to public discourse, delegates heard Counselor Nwandi Lawson describe this engagement as part of a rich
            tapestry that encompasses the community-building process.{' '}
          </p>
          <p>
            As the Universal House of Justice wrote in a 2013 letter to the Association for Bahá’í Studies, every believer can
            examine the forces of society and introduce relevant aspects of the teachings in whatever spaces are appropriate.
          </p>
          <p>
            Sometimes those spaces are already established. Bahá’ís who enter can both learn from others and contribute
            perspectives informed by the teachings, said Lawson. But Bahá’ís also are creating spaces — especially when the
            prevalent discourses are intractably at odds with the Revelation.
          </p>
          <p>
            In all cases, the Counselor noted, the support of Bahá’í community members is essential, providing “a measure of
            safety” as those engaged in the work learn how to effectively “move the needle” in their community.
          </p>
          <p>
            During consultation, a delegate further explained the evolution of the Association for Bahá’í Studies, sharing four
            insights from the systematic learning underway since 2013: how to foster working groups of people in the same field
            who can learn together over time; how to foster other focused projects and initiatives by teams; how to create
            specialized seminars that advance the first two; and how to develop schemes of coordination that aid those efforts and
            disseminate their learning.
          </p>
          <p>Other delegate remarks addressed:</p>
          <ul>
            <li>Incorporating discourses into school courses.</li>
            <li>Hosting symposia on race and providing ongoing spaces for attendees.</li>
            <li>Exploring the role of technology in our lives.</li>
            <li>Asking college students how they plan to use their education to be of service to humanity.</li>
            <li>Partnering with a college to publish a book that spotlights diversity in the community.</li>
            <li>Engaging in discourses about parenting.</li>
            <li>
              Preparing to enter spaces where contentious issues such as guns, the environment and government are discussed.
            </li>
            <li>
              Centering dialogue on our twofold moral purpose — transforming oneself and contributing to transformation of society
              — when discussing problems black men face.
            </li>
            <li>Tackling institutional racism through engagement with local governing and advisory bodies.</li>
            <li>
              Creating nonprofit organizations that raise the level of conversation in such arenas as charitable giving, race
              unity, gender equality, peace and prosperity.
            </li>
            <li>Gathering youths for healing service in the wake of school shootings.</li>
            <li>
              Role-playing difficult conversations.Honoring women who have made transformational contributions to a community.
            </li>
            <li>Exploring social justice from a Bahá’í perspective through online resources.</li>
          </ul>
        </div>
      ),

      secretary: (
        <div>
          <p>
            With trust in the vision expounded over the years by Shoghi Effendi and the Universal House of Justice — of
            alternating cycles of crisis and victory that advance God’s plan in the long run — Bahá’ís and our friends can
            identify opportunities and continue gaining capacity to get into conversations that address the turmoil many people
            are feeling, the secretary said.
          </p>
          <p>
            “Everywhere in our teaching work,” Bowers said, Bahá’ís are witnessing “a new search for the existential answers and
            the practical answers to their questions.” And in a cynical world, those answers increasingly must be not just
            idealistic and logical, but must point to “sustainable action.”
          </p>
          <p>
            Answering that call, Baha’i activity increasingly forges friendships across lines of race, class, ethnicity or income
            level. “People who might otherwise have had no relationship with each other are building loving bonds working shoulder
            to shoulder together for the betterment of the community,” he said.
          </p>
          <p>
            And it is empowering people to make positive changes in their lives and in society. He told how one movement of youths
            mentoring junior youth, in a neighborhood surrounding a historically African-American university, was so effective
            that the university’s chancellor declared that it “aligned perfectly with the mission of the university” to transform
            students into leaders, and asked one of the animators to explain the junior youth program at orientations for new
            students.{' '}
          </p>
          <p>
            Bowers’ remarks were a companion to the National Assembly’s published Annual Report, sharing the hopes and cares the
            institution wishes to express for the year ahead.
          </p>
          <p>Stimulating those hopes, he said, are a number of positive developments in the advancement of the Five Year Plan.</p>
          <p>
            Nineteen clusters in our national community have passed the third milestone of development, with perhaps 100 or more
            core activities embracing large numbers — up from only eight a year earlier.
          </p>
          <p>
            Evidence is increasing that our communities are not only learning how to multiply core activities but also “to
            generate and disseminate knowledge” about their power to build community, not just with “cookie-cutter” formulas but
            with “genuine insights” that can be applied in more and more neighborhoods and localities.
          </p>
          <p>
            There is a “rise in the capacity truly to empower others in the community-building process.” For example, a number of
            neighborhoods have seen homefront pioneers or visiting teachers accompany one group of people into service, and that
            group will take its turn accompanying others. In some localities as many as four “generations” have emerged through
            that process, Bowers said, and many in the newer generations are “infused with the spirit of the Faith” and deeply
            connected with the populations where they live.{' '}
          </p>
          <p>
            Young people are especially involved in that process, gaining capacities and skills in mentoring younger youth and
            children, tutoring study circles and raising their communities’ devotional character. “In some cases we are even
            talking about cohorts of youth,” he said: small groups that advance together in the complexity of their service while
            accompanying other groups in their own advancement.
          </p>
          <p>
            Even as devotional gatherings multiply, people are evolving in the way they see them: not just as getting together to
            worship God, which is powerful and worthy in its own right. When acts of service are influenced by the Word of God, it
            causes many “to take account of themselves and their attitudes, their predispositions and assumptions” — for example,
            unjust inner feelings of superiority or suspicions in interacting with those who are outwardly different.
          </p>
          <p>
            “Fundamentally the most important thing, and the most satisfying,” is how people at all stages of connection with the
            Faith are taking ownership of the process. “This is a revolution in the thinking of the Bahá’í world,” he said, as we
            are asked to increasingly entrust the carrying out of Bahá’u’lláh’s teachings to people not necessarily enrolled in
            the Faith.
          </p>
          <p>
            He recalled watching while a skeptical group of mothers, in a discussion of the junior youth groups their children
            were involved in, were shown complete respect for their opinions and concerns. Then when encouraged to help the effort
            develop, they began to shift from calling it “your program” to “our program. … We’re abandoning a model of control for
            a model of empowerment.”{' '}
          </p>
          <p>
            In one effort to stimulate elevated conversations, Louhelen Bahá’í School has employed a stage play, Henry Box Brown
            as produced by the Children’s Theater Company, to get people talking about race and justice in the Flint, Michigan,
            area. Also, a systematic experiment in use of social media that currently involves a few selected clusters has brought
            a total of 1,300 people into discussion groups centered on Bahá’í teachings.
          </p>
          <p>
            Among the lessons are that people want to talk about important things, they want to find practical ways forward from
            the way things are, and they are “sick and tired of the divisiveness and the blame game and the partisanship and the
            fiddle-faddling with truth and reality in the discourse we’ve seen.”
          </p>
          <p>
            As Bahá’í communities gain a continually more mature understanding of the growth process, he said, we will appreciate
            the balance between teaching people the Faith directly and inviting them into service through the institute process.
            One example is an effort in South Carolina that had trained dozens of young people in community building. Hardly any
            had committed to the Faith until a system of fireside teaching was introduced, and then about 18 sought enrollment as
            Bahá’ís in four months. This reverses earlier prevalent thinking — “OK, they’ve become Bahá’ís, now how do we get them
            involved?” — because these young people are fully involved in the work even before they join the Faith.
          </p>
          <p>
            Turning to racial prejudice, the “most vital and challenging issue” and a concern expressed by many Convention
            delegates over the years, he outlined processes ongoing at the national and local levels that have been noted in
            recent major National Assembly letters.
          </p>
          <p>
            Courses to build capacity for increasing interracial unity within and without the Bahá’í community — one of two
            national initiatives on race — have been under development. One such course is at the experimental level in three
            localities. Another will be used in Bahá’í summer schools nationwide. Both are expected to generate learning about
            stimulating “candid, loving conversation” on a painful subject and about “what kinds of action can be taken with more
            intentionality” that can be integrated with the Plan
          </p>
          <p>
            In Washington, DC, another major initiative has brought the National Assembly’s Office of Public Affairs into a
            process of learning about the state of America’s race discourse, and how to create Bahá’í-initiated spaces for it.
            This is expected to lead to a national Bahá’í conference on race in May 2021, a relatively small event that will
            involve a number of partners from outside the Faith. The overall process will lead to more gatherings and a wider
            circle of collaboration.
          </p>
          <p>
            Still, “the success of anything we do at the national level depends on what happens at the level of the cluster.”
            Recent surveys on Bahá’í social action show at least 2,000 local initiatives with a bearing on race, and that
            experience can be called on in our overall learning about related issues.
          </p>
          <p>
            A recent message on behalf of the Universal House of Justice to the National Assembly, Bowers said, conveyed assurance
            that a variety of actions at the grassroots, though seemingly unconnected, can “all contribute to a common enterprise”
            that can gradually influence with wider society. It adds that there are sometimes advantages to multiracial spaces for
            worship, study and action, and in other cases — perhaps as part of a neighborhood-based effort — sometimes such a
            space can be most effective with only African Americans, for example. Being sensitive to the needs of a particular
            population “steers us away from dichotomous thinking,” he said.
          </p>
          <p>
            As we incorporate greater and greater complexity into how we conduct community life — and gain new insights into
            familiar elements, such as firesides and the roles of Spiritual Assemblies — the bicentenary of the Birth of the Báb
            serves to remind us of the high degree of courage and self-sacrifice that our efforts in the years ahead will require.
          </p>
          <p>
            Even as we are not required to pick up a sword and battle attackers as did Mullá Husayn, or to face gruesome martyrdom
            such as Sulaymán Khán’s, the “heroes of the present day” can “devote themselves to the work that the Head of the Faith
            has given us to do with that same spirit of faith, with that same love, with that same ability.”
          </p>
          <iframe
            title="Secretary Presentation"
            src="https://player.vimeo.com/video/332772689"
            width="640"
            height="360"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </div>
      ),

      treasurer: (
        <div>
          <p>
            And it wasn’t just a spiritual victory but a material total of more than $38 million in contributions for the year
            that Juana Conrad, treasurer of the National Spiritual Assembly, was pleased to report to the 111th U.S. Bahá’í
            National Convention the morning of April 26.
          </p>
          <p>
            Sacrifices by Bahá’ís to meet National Bahá’í Fund goals in recent years have allowed for exciting strides in meeting
            immediate and long-term needs of the Cause, her report indicates.
          </p>
          <p>
            But Sophia’s letter, and other reports of the commitment of our country’s Bahá’ís, are what really touch hearts. “What
            a wondrous spirit of sacrifice and a growing sense of serious spiritual responsibility we witness daily in the Office
            of the Treasurer,” Conrad marveled.
          </p>
          <p>
            Her report outlined another step in the steady year-by-year increase of resources contributed to the National Fund,
            even as expenses stayed stable for most national offices.
          </p>
          <p>
            This has allowed the National Assembly to take some bold steps. Development of new, fitting facilities for the
            National Bahá’í Archives, with its precious relics and invaluable records, is to be launched in the coming months.
          </p>
          <p>
            At the same time, more properties are being bought in the vicinity of the Bahá’í House of Worship, helping fulfill a
            vision the Guardian expressed in 1944.
          </p>
          <p>
            Another initiative, an ambitious experiment in a few clusters using social media to bring people together for
            conversations based around Bahá’í teachings, has benefited from the focused support of a generous contributor.
          </p>
          <p>
            And she had breaking news: In the few days since the Riḍván message announced the project to build a Shrine of
            ‘Abdu’l-Bahá in the Holy Land, a fund for that purpose has already been made an option for earmarked and electronic
            contributions.
          </p>
          <p>
            Among highlights of the treasurer’s report regarding contributions in the administrative year that started May 1,
            2018:
          </p>
          <ul>
            <li>
              More than three-quarters of contributions to the National Fund were unrestricted, “which allows the National
              Assembly to direct funds to where they are most needed.” Individual Bahá’ís contributed the vast majority of the
              total, with Spiritual Assemblies and groups contributing most of the rest and Bahá’í-owned entities and companies
              responsible for another share.
            </li>
            <li>
              Following general trends for nonprofit and religious organizations — but still a cause for concern — less than half
              of the contributed total came from believers younger than age 60. Given that younger believers face more-severe
              financial realities than the older generation, “we are looking for new ways to engage younger people.”
            </li>
            <li>
              Electronic contributions through mobile devices are showing promise in that respect, becoming an increasingly
              popular method of giving for younger believers.
            </li>
            <li>
              Recurring electronic contributions are providing a rising base for the Fund, accounting for an average of $1.1
              million per Gregorian month.
            </li>
            <li>
              Planned giving was responsible for $7 million, through such means as bequests; contributions of securities,
              retirement funds, real estate and other accounts; charitable gift annuities; and a relatively new method,
              donor-advised funds.
            </li>
            <li>
              Direct contributions for activities of the 12 Regional Bahá’í Councils have increased by 13 percent. The National
              Fund fills the gap that most Councils face between those contributions and their needs.
            </li>
            <li>
              Direct U.S. contributions to the Continental Bahá’í Fund, supporting the activities of the Counselors and Auxiliary
              Boards, increased by 13 percent.
            </li>
          </ul>
          <p>
            Through the end of February, per-month expenditures by national offices were reported to be comparable to the previous
            few years in the areas of teaching and education, communications, and capacity building. Those account for the bulk of
            the National Assembly’s spending.
          </p>
          <p>Conrad also went over some other enterprises for which the National Fund’s support may not be well-known.</p>
          <p>
            For instance, the Fund pays salaries and expenses of the five learning sites for the junior youth spiritual
            empowerment program.
          </p>
          <p>
            It underwrites U.S. operations of the Institute for Studies in Global Prosperity, whose seminars drew 300 university
            and college students.
          </p>
          <p>
            The Universal House of Justice called on the National Assembly to provide a total of about $500,000 for needs in
            several countries including Zambia, Nigeria, Liberia, Jamaica and Togo.
          </p>
          <p>
            The Persian Bahá’í Media Service, with $1.5 million from the Fund, provides broadcasts that have “helped to correct so
            much of the inaccurate info spread about the Faith in Iran.”
          </p>
          <p>
            The National Assembly also oversees a Humanitarian Fund, which in the past year contributed $100,000 to the newly
            created Bahá’í International Development Fund.
          </p>
          <p>
            The National Assembly regularly contributes to the Bahá’í Chair for World Peace at the University of Maryland, “an
            important voice in raising the level of discourse around peace and … a leader in academic circles.” The Assembly
            established an endowment for the Chair last year, and contribution to that initiative is open to all.
          </p>
          <p>
            In reaffirming the $40 million National Fund contribution goal for fiscal 2019–2020, Conrad noted, “We know that this
            community is capable of achieving or even surpassing this goal. This is the amount needed to put the community on a
            firm footing to achieve the goals of the coming Twelve Month Plan.”
          </p>
          <p>
            And as the world moves through darkness, she said, our role is to move forward with confidence, optimism and resolve,
            and “never forget that our service is a spiritual one.”{' '}
          </p>
          <iframe
            title="Treasurer Presentation"
            src="https://player.vimeo.com/video/332762443"
            width="640"
            height="360"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </div>
      ),

      consolidation: (
        <div>
          <p>
            And delegates proceeding to the microphone shared experiences that were possible only if those qualities were
            bountiful in the ongoing cycles of study, consultation, action and reflection in clusters.
          </p>
          <p>
            “This is not a Plan for the lone wolf,” noted Counselor Nwandi Lawson. She added that those who function as “nuclei”
            of community-building work, “if they work in teams, … support each other in all aspects of their lives to meet
            [goals].” A primary worldwide goal is the launch of 5,000 intensive programs of growth — 600 of them in this country —
            by the end of this five-year period in 2021.
          </p>
          <p>
            Mutual support and collaboration are not only part of institutional development, she said, they also must mark what
            individuals and communities do as well. Those qualities enable “a growing number of people to be part of the process,
            giving every person a pathway to make a contribution.”
          </p>
          <p>
            Accompaniment is not the same as paternalism, said Lawson. In fact, it can be an aid in battling such let-me-help-you
            tendencies so we can “all march on a common path of service, focused.”
          </p>
          <p>
            The Counselor recalled a Houston teenager, Alberto, who appeared in a video that delegates and guests viewed two
            nights earlier. He had no experience in teaching children’s classes, but when he volunteered the teacher recognized he
            has strengths that would benefit the class: He speaks Spanish and plays the guitar.
          </p>
          <p>
            These are “roots of expansion and consolidation that can last forever,” said Lawson, as people who might otherwise be
            left in the margins are empowered to fit into the rich tapestry of community building we’re weaving in neighborhood
            after neighborhood.
          </p>
          <p>
            Counselor Natasha Bruss said “something profound” happens in the first book of the junior youth curriculum, Breezes of
            Confirmation, when the animator asks participants to identify each other’s talents. They excitedly call out those
            capacities and, just as important, how those talents can serve humanity.
          </p>
          <p>
            “That way they choose hope over despair,” she said, adding: Who knows how that might play out over time? “What kind of
            Assembly members will they be, or Auxiliary Board members or mayors or pastors or school superintendents?”
          </p>
          <p>
            The likes of this educational system don’t exist in society now, but this process encourages young people to develop
            capacities for service to others, said Bruss.
          </p>
          <p>
            Turning to the community-building process as a whole, the Counselor noted that as people “move from divergent
            directions closer to each other,” some elements of their cultures will fall away while others will be reinforced and
            new elements will be born.
          </p>
          <p>
            But she asked: How can a population coming into that process have a voice in shaping society if only a handful of
            people are engaged — and not hundreds or thousands? “All need to have a voice.”
          </p>
          <p>
            Bruss told of moving from an advanced cluster to a much smaller one and developing — through active listening and
            becoming part of the culture — compassion for and sensitivities “to the experience of people so far from the heart of
            the work.”
          </p>
          <p>
            There are people in even the most advanced clusters who feel outside the current of the process, she noted. “So let’s
            talk about how we’re learning to listen to people and how to extend that capacity to others.”
          </p>
          <p>
            Delegates consulting on the topic brought forward stories of progress made through meeting challenges. Others told of
            challenges they’re still facing.
          </p>
          <p>Some of their stories:</p>
          <ul>
            <li>
              A community is struggling with how to communicate expectations for behavior to young people without chasing them
              away. The situation has reached a point the abusive actions of two youths are affecting those around them.
            </li>
            <li>
              An Area Teaching Committee came home inspired from a trip to Dallas, where members saw how Bahá’ís and friends are
              working to address problems in a neighborhood. But adapting the lessons to a cluster with fewer resources is taking
              time.
            </li>
            <li>
              For the 200th anniversary of the Birth of Bahá’u’lláh, a cluster’s believers each committed to holding meaningful
              conversations with 10 people. The impact on engagement was significant and the same goal has been set for the two
              cycles leading to the bicentenary of the Birth of the Báb.
            </li>
            <li>
              A junior youth group that had barely started a year ago has grown significantly with the addition of children
              transiting from a children’s class. When it lost its meeting place, mothers of participants offered the use of their
              homes.
            </li>
            <li>
              A need was voiced for “those saintly ATC members” to get regular support from Spiritual Assemblies, including having
              the institution orient its calendar to the cycle of activity.
            </li>
            <li>
              A community is adapting what it learned about the “harmony house” gatherings held in another city by a family that
              has made its home a safe place for neighbors to discuss social issues.
            </li>
            <li>
              The analogy of yeast and bread was used to describe how the few Bahá’ís in an area have become the leaven for
              efforts by young people who have become involved in community building to make presentations in school about the
              Faith and the junior youth program.
            </li>
            <li>
              The partnership of a community and a school for the arts has resulted in the production of plays on racism, bullying
              and sexism, and donations have allowed the program to be expanded to several other areas.
            </li>
            <li>
              A need was expressed for messages from the institutions to be made available quickly to Swahili and Nepali speakers,
              so that all members of a cluster’s nucleus can contribute in a timely manner to consultations about furthering the
              institute process.
            </li>
            <li>
              A teaching team formed several years ago to support a long-standing devotional gathering, and to ensure coherence
              with other activities, has in the past year taken on the additional role of providing a space periodically for
              devotional attendees to discuss issues dear to them.
            </li>
            <li>
              A neighborhood has had a few core activities for years, but now that a teaching team has been formed the members
              meet three or more times a week to pray, study and act, and the power of prayer is bringing many more neighbors into
              the expanding activities.
            </li>
            <li>
              In a cluster where formerly various activities competed for the attention of believers, institutions and agencies
              have begun working in tandem and supporting one another.
            </li>
            <li>
              Youths are taking ownership of and expanding activities in a neighborhood that’s seen several of its young people
              participate in intensive training over school holidays. They are visiting families in the neighborhood and ensuring
              a warm and inviting environment for activities.
            </li>
            <li>
              Students from a historically African-American university are engaged in service in a cluster’s focus neighborhoods
              and are facilitating several activities.
            </li>
            <li>
              Bahá’ís and friends in an advanced cluster have become so much a part of the lives of the young people they
              accompany that parents bring them in to take part in discussions, such as where a youth will go for college.
            </li>
            <li>
              In another cluster, a tutor was able to take a high school senior around to prospective colleges when the parents
              couldn’t and even showed the student neighborhoods near the campuses where they could serve while attending.
            </li>
            <li>
              Native people from two regions came together to foster development of the junior youth program on reservations and
              in cities where many indigenous people live.
            </li>
            <li>
              Bahá’ís and friends in a cluster are practicing spiritual conversation by reading sections of The Bahá’ís magazine
              and exploring different ways to discuss them.
            </li>
            <li>
              The members of a Spiritual Assembly have become involved in the city’s focus neighborhoods and are coordinating with
              other institutions and agencies to ensure that new believers find paths of service.
            </li>
            <li>
              Youths are planning the themes of devotional gatherings in a community, with cooperative games becoming an adjunct
              to the prayers.
            </li>
            <li>
              “Roll the credits,” a delegate said in relating how, as with film and TV productions, more and more people are
              needed to expand community-building efforts.
            </li>
            <li>
              A need was voiced for advanced clusters to expand their role as reservoirs of knowledge and experience so outlying
              clusters can advance along the path of development.
            </li>
            <li>
              Activity in a community burgeoned after an artist began classes for youths. As they came together and bonded their
              community-building engagement expanded, and activities have increased from five to 13.
            </li>
            <li>
              An advanced cluster’s vision for the path ahead centers on two key capacities in all their dimensions: the
              collective capacity to converse with larger numbers of people and invite them into the process, plus the capacity to
              nurture these new friends’ increasing interest over the long run.
            </li>
            <li>
              A community believed it had few resources until it reflected on each individual’s strengths and what he or she is
              doing in the wider community.
            </li>
          </ul>
        </div>
      ),

      race: (
        <div>
          <p>
            Counselor Nwandi Lawson, in introducing the topic, noted that discussion of this “element of a process of drawing
            people to us” would not be limited to one portion of Convention but be part of the fabric of all sessions to come.
          </p>
          <p>
            “The hopes and aspirations of generations before can be realized tonight in part,” said Lawson, noting their genesis
            in The Advent of Divine Justice, but the elements of the process are not disparate.
          </p>
          <p>
            “We need to think about coherence so all things become one thing,” she said. “We imperfect people have come here with
            some degree of experience and understanding,” but none have all that’s needed to achieve the ideal of oneness.
          </p>
          <p>
            Videos in the storytelling series{' '}
            <em>
              <a href="https://www.bahai.us/collection/a-rich-tapestry/" target="_blank" rel="noopener noreferrer">
                A Rich Tapestry
              </a>
            </em>{' '}
            are part of that educational process, the Counselor said. They stem from consultations with the Bahá’í World Center
            and have been informed by recent letters from the Universal House of Justice answering questions related to race.
          </p>
          <p>
            Convention delegates and guests viewed the series introduction and elements of a not-yet-released video focusing on
            community building — spearheaded in great part by homefront pioneers — in the Nottingham neighborhood of Houston.
          </p>
          <p>
            A reading of the Dec. 25, 2018, message of the National Spiritual Assembly on the 80th anniversary of the letter from
            Shoghi Effendi that was later published as The Advent of Divine Justice also preceded consultation.
          </p>
          <p>
            As did Lawson’s reminder that to weave a strong tapestry of community building, all must contribute. “Each individual
            strand is important,” she said. But “if they stand apart one from another, there’s not a lot of strength.”
          </p>
          <p>
            Further stated, “In a tapestry, the strands are interwoven and then you have a piece of fabric,” she said. “And the
            more who participate, the stronger it is. If some don’t contribute, there’s a hole in the fabric.”
          </p>
          <p>
            This level of involvement is attainable, Lawson averred, and will “enable us to start to overcome” a history of
            disparity and oppression. But don’t take my word for it, she said. The activities of Bahá’ís and friends are
            demonstrating it, and these experiences need to be shared as part of a process of learning.
          </p>
          <p>
            “Where are we seeing a growing process of people taking ownership of their own development?” the Counselor asked in
            inviting delegates to share their experiences. “Where are we seeing the tapestry coming together?”
          </p>
          <p>
            As consultation began, a delegate spoke of an effort to tackle racism in local institutions of civil society. Spaces
            are opening for solution-based conversations, she said, and Bahá’ís are seeing their participation yield a much
            greater influence than expected.
          </p>
          <p>
            Another delegate, referring to mention in the Dec. 25 letter of the role of homefront pioneers in reaching out to
            special populations, outlined initiatives in which a mixed-race couple have “forged such bonds of love”; an apartment
            has been leased in a Nepalese neighborhood as a hub for activities; and a nucleus of junior youth activities has been
            created such “that you would be amazed.”
          </p>
          <p>
            Later, two perspectives on how to approach race unity efforts were voiced. A delegate, referring to The Advent of
            Divine Justice, urged that freedom from racial prejudice be the focus. A colleague expanded on that thought, saying
            the tools the Guardian gave us are rectitude of conduct, a chaste and holy life, and freedom from racial prejudice.
          </p>
          <p>
            The next afternoon, informed by the report of the secretary of the National Assembly outlining efforts underway to
            address issues of race, consultation continued. And given the richness of the sharing that followed, delegates had
            used wisely the time to further prepare remarks on the topic.
          </p>
          <p>Consider these contributions:</p>
          <ul>
            <li>
              Participants in three of a locality’s study circles have begun engaging in discourses in the wider community that
              were initiated by Bahá’ís.
            </li>
            <li>
              A devotional gathering launched to uplift and nurture African-American Bahá’ís has refreshed the spirits of
              once-estranged believers. Now, the Bahá’ís are working with all-black and all-white churches to begin a dialogue.
            </li>
            <li>
              Through a social media initiative, relationships are being forged with many more people in a historically
              African-American borough. And the frank feedback provided by these new friends is informing Bahá’ís on how to be
              truly welcoming.
            </li>
            <li>
              A recommendation was approved to allow Black Men’s Gathering veterans to create teams that would act in situations
              where it is felt African Americans alone can respond.
            </li>
            <li>
              A delegate urged fellow “white folks in the room” to dive deeply into works that detail inherent superiority and the
              black experience, even if such study is painful.
            </li>
            <li>
              The launching of periodic gatherings of people who engage in race unity efforts was suggested so they can share
              learning and achieve greater systematization.
            </li>
            <li>
              A Bahá’í representative to a religious gathering was alone in being able to speak proactively about solutions to
              social justice issues, using the junior youth spiritual empowerment program as a powerful example of the
              transformation possible in individuals and communities.
            </li>
            <li>
              A specific effort should be made to enlist Persian-American Bahá’ís in eliminating prejudice against other
              populations that have experienced oppression.
            </li>
            <li>
              Because the Universal House of Justice has said issues of race will be with us for centuries, our learning will
              never be done, and it’s best viewed and achieved in action.
            </li>
            <li>
              Such learning that takes advantage of resources such as museums is best facilitated when done together with people
              of another culture.
            </li>
            <li>
              A teacher formed an after-school race unity club, and the students themselves asked that prayers and quotes from the
              writings be part of its sessions.
            </li>
          </ul>
        </div>
      ),

      delegatesStudy: (
        <div>
          <p>
            The deepening, held in a rooftop ballroom affording views of the Chicago skyline in one direction and the Bahá’í House
            of Worship in another, gave delegates an opportunity to study and discuss two messages from the Universal House of
            Justice.
          </p>
          <p>
            Those messages, dated March 25, 2007, and May 16, 2013, relate directly to delegates’ twin duties of electing the
            members of the National Spiritual Assembly and consulting with that body on the progress of the Faith.
          </p>
          <p>
            The 2007 message tells of “the erosion of trust and collaboration between the individual and the institutions of
            governance” and contrasts that societal malaise with the System of Bahá’u’lláh. It is a System, the letter explains,
            that confers upon every Bahá’í “a bounden duty ... to select, as a responsible citizen of the new world being brought
            into existence, the composition of the institutions having authority over the functioning of the Bahá’í community.”
          </p>
          <p>
            Counselor Nwandi Lawson reflected that through engagement within the “sacred, rarefied” atmosphere of National
            Convention, delegates are “figuring out what are the next steps” in building the capacity to “take on the spirit of
            responsibility which each Bahá’í has for him- or herself.”
          </p>
          <p>
            The 2013 message further explores the spirit of responsibility, noting that while consultation is part of Bahá’í life
            at all levels, consultations at National Convention are particularly informed by the annual Riḍván message.
          </p>
          <p>
            These consultations, the House of Justice explains, take place “within the context of the larger set of relationships
            that bind the Plan’s three protagonists and must increasingly reflect the features of this distinctive conversation.”
          </p>
          <p>
            And through these consultations, delegates are allowed both to “enrich the perspective of the National Assembly” and
            “become informed of its hopes, challenges, and aims.”
          </p>
          <p>
            At morning’s end, thus fortified for their roles, delegates were transported to the gleaming Temple on the shores of
            Lake Michigan for a devotional program in the Auditorium and the Convention’s opening session in the downstairs
            Foundation Hall.
          </p>
          <p>
            There, delegates were greeted and seated by the temporary Convention chair and secretary. They then elected permanent
            Convention officers — David F. Young, chair, and Muin Afnani, secretary — and adopted an agenda and the procedures for
            the four days of consultation.
          </p>
        </div>
      ),

      plan: (
        <div>
          <p>
            With those landmark occasions very much in mind, delegates will be seated Thursday, April 25, in the Bahá’í House of
            Worship in Wilmette, Illinois, to convene the 111th U.S. Bahá’í National Convention.
          </p>
          <p>Delegates, elected last October in their home units, will have two principal tasks:</p>
          <ul>
            <li>
              Prayerfully elect the nine members of the National Spiritual Assembly that will guide the affairs of the Bahá’í
              Faith in this country over the coming year.
            </li>
            <li>Consult with the National Assembly on the progress of the Faith.</li>
          </ul>
          <p>
            A topic that will flavor consultation throughout Convention will be the momentum that is building as more and more
            clusters pass the third milestone of development and share the rich vein of their learning about patterns of activity
            that embrace large numbers. Another is how social and racial justice are being reflected in every aspect of Bahá’í
            community life.
          </p>
          <p>Here, in brief, is the Convention agenda:</p>
          <p>
            <strong>THURSDAY</strong>
          </p>
          <p>
            A deepening for delegates will start the day. Conducted by members of the Continental Board of Counselors, the
            deepening affords delegates an opportunity to study and discuss the purposes of National Convention and their role in
            it.
          </p>
          <p>
            Delegates then will be transported to the House of Worship for a devotional program in the Temple’s Auditorium and the
            convening of Convention downstairs in Foundation Hall. Delegates will be formally seated and proceed to address the
            organization of Convention — electing officers, approving the agenda and adopting procedures for consultation.
          </p>
          <p>
            Following a recitation of the Ridván message of the Universal House of Justice, the Counselors present will lend their
            thoughts on the message and delegates will consult on topics related to it.
          </p>
          <p>
            In the evening session, delegates and visitors will view portions of the video series A Rich Tapestry. Consultation
            will follow on a letter of the National Spiritual Assembly dated Dec. 25, 2018, that reflects on the 80th anniversary
            of The Advent of Divine Justice.
          </p>
          <p>
            <strong>FRIDAY</strong>
          </p>
          <p>
            The morning will be taken up with presentations by the National Assembly’s secretary and treasurer expanding on key
            points in that body’s Annual Report, followed by questions from delegates.
          </p>
          <p>
            All afternoon and evening, delegates will consult on expansion and consolidation, covering such topics as training
            institutes, the movement of clusters on a continuum, teaching, and reaching receptive populations.
          </p>
          <p>
            <strong>SATURDAY</strong>
          </p>
          <p>
            The Counselors will oversee — and Convention visitors will observe — the solemn balloting this morning as delegates
            vote for the National Assembly. Open consultation will follow.
          </p>
          <p>
            After Convention attendees gather for a photograph in early afternoon, delegate consultation will focus on social
            action and the discourses of society. When ready, the tellers will announce the results of balloting for National
            Assembly.
          </p>
          <p>
            The evening session will begin with an introduction of the newly elected national body. Then the day will wrap up with
            a presentation by the trustees of Bahá’í Ḥuqúqu’lláh in the United States and delegate questions for the trustees.
          </p>
          <p>
            <strong>SUNDAY</strong>
          </p>
          <p>Delegates will engage in open consultation. That will lead into closing remarks by the Counselors.</p>
        </div>
      ),

      qa: (
        <div>
          <p>
            <strong>Q. What are the purposes of National Convention?</strong>
            <br />
            A. The Universal House of Justice, in an April 4, 1971, letter from the Universal House of Justice to our National
            Spiritual Assembly, explained, “[T]he primary purpose in holding a convention is to enable the delegates to meet
            together, deliberate freely upon the affairs of the community, and cast their ballots. Other considerations such as
            allowing the rank and file of believers to attend the Convention or follow its deliberations are secondary in
            importance. Obviously it is within the discretion of your National Assembly to decide upon these secondary matters.”
            The balloting to which the letter refers is for the nine members of the National Assembly that will serve for the
            coming year.
          </p>
          <p>
            <strong>Q. When is National Convention?</strong>
            <br />
            A. Most years, National Convention is held on a weekend during the 12-day Festival of Riḍván that doesn’t conflict
            with the election of Local Spiritual Assemblies. Every five years — 2023 will be the next one — National Convention is
            held in late May. In those years the Universal House of Justice is elected at the Bahá’í World Center in Haifa,
            Israel. Since that election takes place in April, the National Convention is held in May to enable the National
            Assembly members to attend the International Convention.
          </p>
          <p>
            <strong>Q. Where is National Convention held?</strong>
            <br />
            A. For the past several years, all sessions of the National Convention have been held at the Bahá’í House of Worship
            in Wilmette, Illinois. In the past, the National Convention was often held in a large hotel meeting space to allow a
            large number of Bahá’ís to attend. Mostly for budgetary reasons, Convention sessions are now held at the Bahá’í House
            of Worship.
          </p>
          <p>
            <strong>Q. Who can attend National Convention?</strong>
            <br />
            A. The small number of seats in Foundation Hall at the Bahá’í House of Worship greatly limits the number of people who
            can attend. Delegates are guaranteed seats, as are members of the Continental Board of Counselors and the National
            Spiritual Assembly. The remainder of seats in Foundation Hall are filled by some members of the Auxiliary Boards for
            Protection and Propagation and the Regional Bahá’í Councils, a small number of the Bahá’ís serving at the Bahá’í
            National Center and guests such as delegates’ family members.
          </p>
          <p>
            <strong>Q. How are National Convention delegates elected, and who is eligible to serve?</strong>
            <br />
            A. The number of delegates allocated to the U.S. Bahá’í National Convention was set by the Guardian, Shoghi Effendi,
            at 171. At Unit Convention in early October, the Bahá’ís in most geographical units elect one believer to be the
            delegate from that unit. A few units, because of the large number of believers they contain, elect more than one
            delegate. Any adult Bahá’í in good standing who lives in the unit is eligible to serve as a delegate, except members
            of the Continental Board of Counselors. If an Auxiliary Board member is elected as a delegate, he or she must decide
            whether to serve as delegate or remain on the Auxiliary Board. If the elected delegate cannot serve, the National
            Spiritual Assembly chooses his or her replacement. The results of the unit convention elections in October must be
            confirmed by the National Spiritual Assembly and delegates are officially “seated” within one to two months.
          </p>
          <p>
            <strong>Q. How do delegates prepare for National Convention?</strong>
            <br />
            A. To assist new and returning delegates in preparing for participation in the Convention, they are provided with many
            writings from the Faith relating to Convention, including a booklet titled National Convention: A Statement and
            Compilation of Extracts from the Bahá’í Writings Prepared by the Research Department of the Universal House of
            Justice. Election materials, the National Spiritual Assembly’s Annual Report, and a preliminary Convention agenda also
            are provided to delegates. On the morning National Convention begins, a deepening for delegates on the significance
            and purpose of the National Convention is facilitated by members of the Continental Board of Counselors.
          </p>
          <p>
            <strong>Q. Who may speak at National Convention?</strong>
            <br />
            A. The privilege of the floor is extended to delegates, members of the Continental Board of Counselors, and members of
            the National Spiritual Assembly. Also able to speak when invited to are Bahá’ís who deliver reports, such as
            representatives of the Board of Trustees of Huqúqu’lláh, or possess information that will inform the delegates’
            consultation, such as a visiting Counselor member of the International Teaching Center.
          </p>
          <p>
            <strong>Q. Who may serve as a National Convention officer?</strong>
            <br />
            A. Any delegate present for the proceedings may serve as Convention chair or secretary. The body of delegates elects
            the officers by secret ballot in the opening session of the Convention.
          </p>
          <p>
            <strong>Q. How is the National Convention agenda decided?</strong>
            <br />
            A. A preliminary agenda, guided by the purposes of National Convention and themes of the current Plan, is presented by
            the National Spiritual Assembly to the delegates. Delegates are free to propose changes to the agenda and have them
            adopted by the body of delegates.
          </p>
          <p>
            <strong>Q. How is consultation conducted at National Convention?</strong>
            <br />
            A. The exact manner of consultation, such as how long each delegate may speak or whether preference will be given to
            delegates who have not yet spoken, is decided by the body of delegates at the beginning of Convention. Each delegate
            has a number and raises that number to be recognized by the Convention secretary. The secretary lists the delegates in
            order of recognition and that list can be seen by all on monitors placed on the wall above the stage. When the time is
            near for a delegate to speak, he or she moves to one of the microphones on the stage and awaits his or her turn. When
            delegates speak they may offer insights on the topic of consultation for that time period and offer a suggestion or
            recommendation to the National Spiritual Assembly for its consideration. Suggestions require no vote of the body of
            delegates. A recommendation that receives a second is consulted on specifically and the body of delegates decides by
            show of hands whether to pass it along to the National Assembly. When a recommendation is proposed, delegates who wish
            to speak to that recommendation are listed and come to a microphone in the order they were recognized.
          </p>
          <p>
            <strong>Q. How is the National Spiritual Assembly elected?</strong>
            <br />
            A. On Saturday morning of National Convention, the delegates gather — in a session monitored by the members of the
            Continental Board of Counselors present and witnessed by registered non-delegates — to vote prayerfully by secret
            ballot for the nine members of the National Spiritual Assembly. A ballot that contains more or fewer than nine names
            is invalid. A vote for a person not eligible for election is not counted. Delegates who cannot attend Convention may
            vote by absentee ballot. The nine individuals receiving the highest number of votes serve on the National Assembly for
            one year.
          </p>
          <p>
            <strong>Q. How are National Spiritual Assembly election results reported at National Convention?</strong>
            <br />
            A. The manner of reporting the results, beyond the names of the nine elected members of the National Spiritual
            Assembly, is decided by the body of delegates on the first day of Convention.
          </p>
        </div>
      ),
    };
  }
  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }
  setHiddenId(id) {
    this.setState({
      hiddenId: id,
    });
  }
  render() {
    return (
      <div>
        <FlickrGallery apiKey="13d8e319087a91a92dc7d59d1b44490b" setId="72157679798169268" />
        <div>
          <h1>National Convention 2019</h1>
          <p>
            Photos from the Convention available at&nbsp;
            <a
              href="https://www.flickr.com/photos/bahaius/collections/72157704528019692/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Flickr
            </a>
            .
          </p>
          <p>
            Below are stories on the Convention from <em>The American Bahá’í</em>
          </p>
        </div>
        <hr />
        <Row>
          <Col sm={12} md={8}>
            <Row>
              <Col md={4} lg={3}>
                <img alt="voting" src="https://live.staticflickr.com/65535/40749154593_c7c139633e_q.jpg" />
              </Col>
              <Col md={8} lg={9} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>National Spiritual Assembly elected in prayerful atmosphere</h4>
                <p>
                  Delegates to the 111th U.S. Bahá’í National Convention, on April 27, 2019, elected the National Spiritual
                  Assembly to oversee the affairs of the U.S. Bahá’í community for the year ahead. The Assembly met the same day
                  and elected its officers for the year.
                </p>
                <ul className="plain-list">
                  <li>Kenneth E. Bowers, Secretary</li>
                  <li>David F. Young, Chair</li>
                  <li>Jacqueline Left Hand Bull, Vice Chair</li>
                  <li>Muin Afnani</li>
                  <li>Juana C. Conrad, Treasurer</li>
                  <li>Kevin Trotter</li>
                  <li>Robert C. Henderson</li>
                  <li>Fariba Aghdasi</li>
                  <li>Rabi A. Musah</li>
                </ul>
              </Col>
            </Row>
            <NatConDropDown chapterKey={0} display={this.display.atmosphere} />
            <hr />
            <Row>
              <Col md={4} lg={3}>
                <img
                  alt=""
                  src="https://live.staticflickr.com/65535/47744048961_a6ca828fcf_q.jpghttps://live.staticflickr.com/65535/47744048961_a6ca828fcf_q.jpg"
                />
              </Col>
              <Col md={8} lg={9} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Open consultation recognizes miracles happening nationwide</h4>
                <p>
                  “Miracles are happening where people are working really hard.” That’s how a delegate to the 111th U.S. Bahá’í
                  National Convention summed up all she had heard the past four days from the Counselors, the National Spiritual
                  Assembly, the Board of Trustees of Ḥuqúqu’lláh and fellow delegates — or informally aboard buses, during breaks
                  and late at night.
                </p>
              </Col>
            </Row>
            <NatConDropDown chapterKey={2} display={this.display.consultation} />
            <hr />
            <Row>
              <Col md={4} lg={3}>
                <img alt="Huququllah Presentation" src="https://live.staticflickr.com/65535/46805861175_85b534d844_q.jpg" />
              </Col>
              <Col md={8} lg={9} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Learning session focuses on Right of God and global prosperity</h4>
                <p>
                  It was a learning space, a work in progress. Rather than a report and question-and-answer session, such as has
                  been shared at past Conventions, the Board of Trustees of Huqúqu’lláh in the United States used its 90 minutes
                  to offer delegates a space to study, reflect and share ideas at the end of the third day of the 111th U.S.
                  Bahá’í National Convention, April 27, 2019.
                </p>
              </Col>
            </Row>
            <NatConDropDown chapterKey={3} display={this.display.globalProsperity} />
            <hr />
            <Row>
              <Col md={4} lg={3}>
                <img alt="Delegate Speaking" src="https://live.staticflickr.com/65535/47722573201_35675499a5_q.jpg" />
              </Col>
              <Col md={8} lg={9} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Delegates share learning on public discourse and nascent social action</h4>
                <p>
                  It was a study in contrasts as delegates to the 111th U.S. Bahá’í National Convention consulted on social action
                  and the discourses of society during the afternoon session April 27.
                </p>
              </Col>
            </Row>
            <NatConDropDown chapterKey={4} display={this.display.socialAction} />
            <hr />
            <Row>
              <Col md={4} lg={3}>
                <img
                  alt="Secretary Kenneth Bowers Speaking"
                  src="https://live.staticflickr.com/65535/32765132327_a96e0b663b_q.jpg"
                />
              </Col>
              <Col md={8} lg={9} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Secretary’s report foresees ‘yet another step forward’ beyond crises</h4>
                <p>
                  The crises and divisions shaking the world are preparing the ground for “yet another step forward” in the
                  gradual unification of humanity, said Kenneth Bowers, secretary of the National Spiritual Assembly, in his
                  presentation to the 111th U.S. Bahá’í National Convention the morning of April 26.
                </p>
              </Col>
            </Row>
            <NatConDropDown chapterKey={5} display={this.display.secretary} />
            <hr />
            <Row>
              <Col md={4} lg={3}>
                <img
                  alt="Treasurer Juana Conrad speaking"
                  src="https://live.staticflickr.com/65535/46792851075_10707eca0b_q.jpg"
                />
              </Col>
              <Col md={8} lg={9} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Treasurer shares $38 million Fund victory and expectations for year ahead</h4>
                <p>
                  When 7-year-old Sophia responds to an appeal for generous giving by sending in $5 with a letter saying “I hope
                  that helps” … well, you can consider the Fund goal won.
                </p>
              </Col>
            </Row>
            <NatConDropDown chapterKey={6} display={this.display.treasurer} />
            <hr />
            <Row>
              <Col md={4} lg={3}>
                <img alt="Counselor speaking" src="https://live.staticflickr.com/65535/33838409578_c8aa21ba69_q.jpg" />
              </Col>
              <Col md={8} lg={9} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Consultation on expansion, consolidation focuses on accompaniment and listening</h4>
                <p>
                  The Counselors introducing the consultation on expansion and consolidation on Friday afternoon and evening,
                  April 26, of the 111th U.S Bahá’í National Convention spotlighted the essential qualities of accompaniment and
                  active listening.
                </p>
              </Col>
            </Row>
            <NatConDropDown chapterKey={7} display={this.display.consolidation} />
            <hr />
            <Row>
              <Col md={4} lg={3}>
                <img alt="Delegate speaking" src="https://live.staticflickr.com/65535/46792999025_b36c8252b3_q.jpg" />
              </Col>
              <Col md={8} lg={9} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Race consultation weaves a tapestry of ideas and concerns</h4>
                <p>
                  The imagery of a tapestry provided an overlay to sessions of the 111th U.S. Bahá’í National Convention on “Race
                  and the Five Year Plan” that started the evening of April 25 and carried over to the next afternoon.
                </p>
              </Col>
            </Row>
            <NatConDropDown chapterKey={8} display={this.display.race} />
            <hr />
            <Row>
              <Col md={4} lg={3}>
                <img alt="" src="https://live.staticflickr.com/65535/46783532275_162d6cf8dc_q.jpg" />
              </Col>
              <Col md={8} lg={9} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>’Joyful’ and ‘sacred’ Convention opens after delegates study duties</h4>
                <p>
                  A morning of preparation for delegates to the 111th U.S. Bahá’í National Convention conveyed the idea, voiced by
                  Counselor Natasha Bruss, that the four days of proceedings, April 25-28, would be at once “joyful and wonderful”
                  and “sacred.”
                </p>
              </Col>
            </Row>
            <NatConDropDown chapterKey={10} display={this.display.delegatesStudy} />
            <hr />
            <Row>
              <Col md={4} lg={3}>
                <img alt="Wilmette House of Worship" src="https://live.staticflickr.com/979/41379331015_35c08c02cb_q.jpg" />
              </Col>
              <Col md={8} lg={9} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>Convention to assemble as momentum builds toward end of Plan</h4>
                <p>
                  The 200th anniversary of the birth of the Báb is just months away. And two years remain in the last of a series
                  of Five Year Plans leading to the centenary of the passing of ‘Abdu’l-Bahá.
                </p>
              </Col>
            </Row>
            <NatConDropDown chapterKey={11} display={this.display.plan} />
            <hr />
            <Row>
              <Col md={4} lg={3}>
                <img alt="Q&A" src="https://live.staticflickr.com/7909/46864945864_6454549692_q.jpg" />
              </Col>
              <Col md={8} lg={9} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                <h4>National Convention questions and answers</h4>
              </Col>
            </Row>
            <NatConDropDown chapterKey={12} display={this.display.qa} />
          </Col>
          <Col sm={12} md={4}>
            <div className="grey-box">
              <h3>Resources </h3>
              <p>
                <a href="https://s3.amazonaws.com/bahai-messages/uhj/2019/20190420-UHJ-Ridvan-2019-Message-English.pdf">
                  Ridván 2019 Message of the Universal House of Justice
                </a>
              </p>
              <p>
                <a href="https://s3.amazonaws.com/bahai-messages/nsa/2019/US-NSA-Annual-Report-2019.pdf">
                  2019 Annual Report of the National Spiritual Assembly
                </a>
              </p>
              <p>
                <a href="https://s3.amazonaws.com/bahai-web/NatCon/2019/2019-0427-111th-US-Bahai-National-Convention-to-UHJ.pdf">
                  Letter from the delegates to the Universal House of Justice
                </a>
              </p>
              <p>
                <a href="https://s3.amazonaws.com/bahai-messages/uhj/2019/20190505-UHJ-Responds-to-111th-US-Bahai-National-Convention.pdf">
                  Supreme Body Responds to 111th US Bahá’í National Convention
                </a>
              </p>
              <p>
                <a href="https://s3.amazonaws.com/bahai-web/NatCon/2019/2019-NatCon-Delegate-Demographics.pdf">
                  Delegate Demographics chart
                </a>
              </p>
              <h4>National Convention Coverage Archives</h4>
              <ul className="plain-list">
                <li>
                  <Link to="./natcon-2018">2018</Link>
                </li>
                <li>
                  <Link to="./natcon-2017">2017</Link>
                </li>
                <li>
                  <Link to="./natcon-2016">2016</Link>
                </li>
              </ul>
              <h4>Secretary’s Report</h4>
              <iframe
                title="Secretary’s Report"
                src="https://player.vimeo.com/video/332772689"
                width="100%"
                height="166"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
              <p>
                <a href="https://player.vimeo.com/external/332772689.hd.mp4?s=82e0b4a37c2d5f9d0ee5aafbf39c320133420018&amp;profile_id=175&amp;download=1">
                  Download the video in high definition
                </a>
                <br />
                <a href="https://player.vimeo.com/external/332772689.sd.mp4?s=42a3607497cc5f48e50781fd4b1fdc3ad4fa7908&profile_id=165&download=1">
                  Download the regular video
                </a>
              </p>
              <h4>Treasurer’s Report</h4>
              <iframe
                title="Treasurer’s Report"
                src="https://player.vimeo.com/video/332762443"
                width="100%"
                height="166"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
              <p>
                <a href="https://player.vimeo.com/external/332779715.hd.mp4?s=ee46cb266c282370603f8da592331f632cab632c&profile_id=175&download=1">
                  Download the video in high definition
                </a>
                <br />
                <a href="https://player.vimeo.com/external/332779715.sd.mp4?s=590510ea86ea464764c53eeb8bfda3dacbb2e1ed&profile_id=165&download=1">
                  Download the regular video
                </a>
              </p>
              <hr />
              <h4>From the Counselors</h4>
              <iframe
                title="From the Counselors (2)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/615188352&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="From the Counselors (4)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/615188343&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="From the Counselors (6)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/615188328&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <h4>Thursday Evening Race and the Five Year Plan</h4>
              <iframe
                title="Thursday Evening Race and the Five Year Plan (1)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/615336783&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Thursday Evening Race and the Five Year Plan (2)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/615336780&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Thursday Evening Race and the Five Year Plan (3)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/615336765&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <h4>Friday Afternoon Race and the Five Year Plan</h4>
              <iframe
                title="Friday Afternoon Race and the Five Year Plan (1)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/615342834&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Friday Afternoon Race and the Five Year Plan (2)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/615342825&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Friday Afternoon Race and the Five Year Plan (3)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/615342813&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Friday Afternoon Race and the Five Year Plan (4)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/615342807&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <h4>Friday Evening Expansion and Consolidation</h4>
              <iframe
                title="Friday Evening Expansion and Consolidation (1)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/615347943&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Friday Evening Expansion and Consolidation (2)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/615349953&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Friday Evening Expansion and Consolidation (3)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/615347928&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Friday Evening Expansion and Consolidation (4)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/615347925&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Friday Evening Expansion and Consolidation (5)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/615347919&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Friday Evening Expansion and Consolidation (6)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/615347907&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Friday Evening Expansion and Consolidation (7)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/615347895&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Friday Evening Expansion and Consolidation (8)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/615347889&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Friday Evening Expansion and Consolidation (9)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/615347877&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Friday Evening Expansion and Consolidation (10)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/615347874&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <h4>Saturday Morning Open Consultation</h4>
              <iframe
                title="Saturday Morning Open Consultation (1)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/616480122&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Saturday Morning Open Consultation (2)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/616480104&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Saturday Morning Open Consultation (3)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/616480842&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <h4>Saturday Afternoon Social Action</h4>
              <iframe
                title="Saturday Afternoon Social Action"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/616499349&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <h4>Saturday Afternoon Discourses of Society</h4>
              <iframe
                title="Saturday Afternoon Discourses of Society (1)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/616481277&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Saturday Afternoon Discourses of Society (2)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/616481271&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Saturday Afternoon Discourses of Society (3)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/616481259&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Saturday Afternoon Discourses of Society (4)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/616481247&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Saturday Afternoon Discourses of Society (5)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/616481232&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <h4>Sunday Morning Open Consultation</h4>
              <iframe
                title="Sunday Morning Open Consultation (1)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/616500813&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Sunday Morning Open Consultation (2)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/616500807&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Sunday Morning Open Consultation (3)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/616500792&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Sunday Morning Open Consultation (4)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/616500783&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Sunday Morning Open Consultation (5)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/616500771&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Sunday Morning Open Consultation (6)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/616500744&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
              <iframe
                title="Sunday Morning Open Consultation (7)"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/616500726&color=%230066cc&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withContext(NatCon2019);
