import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';
import { throwError, Observable, combineLatest } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import type SearchResultSummaryModel from './SearchResultSummaryModel';
import type SearchResultCountByCategoryModel from './SearchResultCountByCategoryModel';
import type SearchCriteriaModel from './SearchCriteriaModel';
import SearchExclusion from './SearchExclusion';
import * as Sentry from '@sentry/browser';

const searchApiKey = 'D022D60F60756B36443CB198BADED95D';

const initialFilterSeparator = '&$filter=';
const inBetweenFilterSeparator = ' and ';
const filterUrlEncodedSpace = '%20';

const allSearchExclusions = Object.values(SearchExclusion);

const inclusionFilterMap: Record<SearchExclusion, string> = {
  [SearchExclusion.GLSA]: `kenticoprojectid eq '${process.env.REACT_APP_KENTICO_ID_GLSA}'`,
  [SearchExclusion.UHJ]: `institutionshortname eq 'Universal House of Justice' or institutionshortname eq 'La Casa Universal de Justicia' or secondcategorytype eq 'UHJ®' or secondcategorytype eq 'UHJ'`,
  [SearchExclusion.NSA]: `institutionshortname eq 'National Spiritual Assembly' or secondcategorytype eq 'National Spiritual Assembly'`,
  [SearchExclusion.Feast]: `categorytype eq 'feast_message' or (categorytype eq 'cover_letter' and secondcategorytype eq 'Feast Message')`,
  [SearchExclusion.FAQ]: `categorytype eq 'faq'`,
  // [SearchExclusion.Huququ]: "",
  [SearchExclusion.Prayers]: `categorytype eq 'prayer'`,
  [SearchExclusion.Writings]: `categorytype eq 'writing'`,
};

export function initializeCountByCategories() {
  return {
    ['ALL']: 0,
    [SearchExclusion.GLSA]: 0,
    [SearchExclusion.UHJ]: 0,
    [SearchExclusion.NSA]: 0,
    [SearchExclusion.Feast]: 0,
    [SearchExclusion.FAQ]: 0,
    // [SearchExclusion.Huququ]: 0,
    [SearchExclusion.Prayers]: 0,
    [SearchExclusion.Writings]: 0,
  } as SearchResultCountByCategoryModel;
}

function buildSearchUrlWithoutPaging(
  {
    searchString,
    startDate: limitDateStart,
    endDate: limitDateEnd,
    excludes,
    orderByMostRelevant: orderByMostRelevant,
  }: SearchCriteriaModel,
  additionalFilters?: string
): string {
  const encodedSearchText = encodeURIComponent(searchString);
  const urlBase =
    'https://kentico.search.windows.net/indexes/bncsearchindex/docs?api-version=2017-11-11&queryType=full&$count=true';
  const searchStringParam = `&search=${encodedSearchText}`;
  const urlBaseWithSearchString = `${urlBase}${searchStringParam}`;

  let filterSeparator = initialFilterSeparator;
  let filterText = '';

  if (excludes.length > 0) {
    const itemsNOTExcluded = allSearchExclusions.filter((x) => !excludes.includes(x));
    const inclusionFilters = itemsNOTExcluded.map((x) => inclusionFilterMap[x]);
    if (inclusionFilters.length > 0) {
      filterText += filterSeparator;
      filterText += '((';
      filterSeparator = inBetweenFilterSeparator;
      filterText += inclusionFilters.join(') or (');
      filterText += '))';
    } else {
      // Make sure NO results are returned
      filterText += filterSeparator;
      filterText += `kenticoprojectid eq 'NONE'`;
    }
  }

  if (limitDateStart.length > 0) {
    filterText += `${filterSeparator}datepublished ge ${limitDateStart}T00:00:00Z`;
    filterSeparator = inBetweenFilterSeparator;
  }

  if (limitDateEnd.length > 0) {
    filterText += `${filterSeparator}datepublished le ${limitDateEnd}T00:00:00Z`;
    filterSeparator = inBetweenFilterSeparator;
  }

  if (additionalFilters) {
    filterText += `${filterSeparator}(${additionalFilters})`;
    filterSeparator = inBetweenFilterSeparator;
  }

  const filterTextUrlEncoded = filterText.replaceAll(' ', filterUrlEncodedSpace);

  const finalUrl = `${urlBaseWithSearchString}${filterTextUrlEncoded}`;

  const finalUrlWithOrderBy = !orderByMostRelevant ? `${finalUrl}&$orderby=datepublished%20desc` : finalUrl;

  return finalUrlWithOrderBy;
}

export function countCategories(searchCriteria: SearchCriteriaModel): Observable<SearchResultCountByCategoryModel> {
  const topParameter = '&$top=0';

  const searchCriteriaWithoutExcludes: SearchCriteriaModel = {
    ...searchCriteria,
    excludes: [],
  };

  const observableObjectArray: Observable<{
    category: SearchExclusion | 'ALL';
    result: SearchResultSummaryModel;
  }>[] = allSearchExclusions.map((includedItem) => {
    const inclusionFilter = inclusionFilterMap[includedItem];
    const urlWithoutPaging = buildSearchUrlWithoutPaging(searchCriteriaWithoutExcludes, inclusionFilter);
    const finalUrl = `${urlWithoutPaging}${topParameter}`;
    const observableObject = GlobalAPICall.getObservable(finalUrl, {
      headers: {
        'api-key': searchApiKey,
      },
    }).pipe(
      map((endpointResult) => endpointResult.data as SearchResultSummaryModel),
      map((searchResultSummary) => ({ category: includedItem, result: searchResultSummary }))
    );

    return observableObject;
  });

  const urlWithoutPagingForCountAllResults = buildSearchUrlWithoutPaging(searchCriteriaWithoutExcludes);
  const finalUrlForCountAllResults = `${urlWithoutPagingForCountAllResults}${topParameter}`;
  const observableCountAllResults = GlobalAPICall.getObservable(finalUrlForCountAllResults, {
    headers: {
      'api-key': searchApiKey,
    },
  }).pipe(
    map((endpointResult) => endpointResult.data as SearchResultSummaryModel),
    map(
      (searchResultSummary) =>
        ({ category: 'ALL', result: searchResultSummary } as {
          category: SearchExclusion | 'ALL';
          result: SearchResultSummaryModel;
        })
    )
  );

  observableObjectArray.push(observableCountAllResults);

  const combinedObservableObjectArray = combineLatest(observableObjectArray);

  const countByCategoryObservable = combinedObservableObjectArray.pipe(
    map((searchResultByCategory) => {
      return searchResultByCategory.reduce((countByCategory, entry) => {
        const category = entry.category;
        const searchResult = entry.result;
        const searchCount = searchResult['@odata.count'];
        countByCategory[category] = searchCount;
        return countByCategory;
      }, {} as SearchResultCountByCategoryModel);
    })
  );

  const countByCategoryComparedObservable = countByCategoryObservable.pipe(
    tap((countByCategory) => {
      const countForAll = countByCategory['ALL'];

      const totalFromCountByCategory = Object.entries(countByCategory)
        .filter((x) => x[0] !== 'ALL')
        .reduce((count, entry) => {
          const categoryCount = entry[1];
          return count + categoryCount;
        }, 0);

      if (totalFromCountByCategory !== countForAll) {
        const errorMessage = `USBNC: Count by Category total was ${totalFromCountByCategory} but Full Search total was ${countForAll}.`;
        console.error(`${errorMessage} SearchCriteria=`, searchCriteria);
        Sentry.addBreadcrumb({ message: errorMessage, data: { searchCriteria } });
        Sentry.captureMessage(errorMessage);
      }
    })
  );

  return countByCategoryComparedObservable;
}

const SearchResultsEndpoint: (searchCriteria: SearchCriteriaModel) => Observable<SearchResultSummaryModel> = (searchCriteria) => {
  if (searchCriteria.searchString.length <= 0) {
    return throwError(() => ({ message: 'Search string is not defined' }));
  }

  const searchUrlWithoutPaging = buildSearchUrlWithoutPaging(searchCriteria);
  const skipResultsMultiplier = searchCriteria.currentPage - 1;
  const skipResults = searchCriteria.rowsPerPage * skipResultsMultiplier;
  const pagingParams = `&$top=${searchCriteria.rowsPerPage}&$skip=${skipResults}`;

  const finalUrlWithOrderBy = `${searchUrlWithoutPaging}${pagingParams}`;

  return GlobalAPICall.getObservable(finalUrlWithOrderBy, {
    headers: {
      'api-key': searchApiKey,
    },
  }).pipe(
    catchError((err) => {
      if (err?.response?.status === 400) {
        return throwError(() => ({
          status: err.response.status,
          message:
            'Search Failed. Please revise your criteria. If you continue to see this message please try again at a later time.',
        }));
      } else {
        return throwError(() => ({
          message: 'Our services are currently unavailable. Please come back later.',
        }));
      }
    }),
    map((endpointResult) => endpointResult.data as SearchResultSummaryModel)
  );
};

export default SearchResultsEndpoint;
