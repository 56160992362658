import React from 'react';

function TreasurersGeneral() {
  return (
    <div>
      <h1>General Treasurer Forms</h1>
      <p>
        <strong>Note:</strong> For PDFs that you can edit on your computer, such as the Audit Worksheet Form, either download the
        file before making edits to the form or make edits within your browser and print directly.
      </p>
      <ul className="plain-list">
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/Description-of-the-Funds-of-the-Faith-2021.pdf">
            Description of the Funds (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/forms/Treasurer/Tools/contributions-form-may18.docx">
            Contribution Form (DOCX)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/treas-in-honor-form.pdf">In Honor Form (PDF)</a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/treas-in-memory-form.pdf">In Memory Form (PDF)</a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/tab/forms/treas-sacred-trust.pdf">Sacred Trust (PDF)</a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/Stewardship-and-Development-Manual-Revised-2021.pdf">
            Stewardship &amp; Development (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/forms/Treasurer/Tools/LSA-application-enrollment-OCS.pdf">
            LSA Application Enrollment in OCS (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://um.ocsbahai.org/Quick_Guide.pdf">Donor’s Guide to the Online Contribution System (OCS) (PDF)</a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/forms/Treasurer/Tools/forum-Managing-Risk-in-Your-Community.pdf">
            Managing Risk in Your Community (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/forms/Treasurer/Tools/forum-The-Bigger-the-Community.pdf">
            The Bigger the Community (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/forms/Treasurer/Tools/forum-Spiritual-Fundraising.pdf">
            Spiritual Fundraising (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/forms/Treasurer/Tools/forum-Treasury-101.pdf">Treasury 101 (PDF)</a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/forms/Treasurer/Tools/forum-Treasury-201.pdf">Treasury 201 (PDF)</a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/forms/Treasurer/Tools/forum-Protecting-Yourself-and-Your-Community-Through-Effective-Stewardship-of-the-Funds.pdf">
            Protecting Yourself and Your Community Through Effective Stewardship of the Funds (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/forms/Treasurer/Tools/forum-What-Questions-Should-I-Be-Asking-My-Treasurer.pdf">
            What Questions Should I Be Asking My Treasurer? (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/forms/Treasurer/Tools/forum-What-is-the-point-of-a-fundraiser.pdf">
            What is the point of a fundraiser? (PDF)
          </a>
        </li>
        <li>
          <strong>Download: </strong>
          <a href="https://s3.amazonaws.com/bahai-web/forms/Treasurer/Tools/OCS-presentation-by-Sholeh.pdf">
            The Online Contribution System (PDF)
          </a>
        </li>
      </ul>
      {/* <a
                href="https://s3.amazonaws.com/bahai-web/forms/Treasurer/Tools/forum-Making-the-Funds-Exciting.pdf"
                className="plain-link"
              >
                <Row className="fund-rows">
                  <Col xs={3} lg={2}>
                    <img src={} alt="" className="fund-icons" />
                  </Col>
                  <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                    <h4>Making the Funds Exciting (PDF)</h4>
                  </Col>
                </Row>
              </a>
              <a
                href="https://s3.amazonaws.com/bahai-web/forms/Treasurer/Tools/forum-Raising-Consciousness.pdf"
                className="plain-link"
              >
                <Row className="fund-rows">
                  <Col xs={3} lg={2}>
                    <img src={} alt="" className="fund-icons" />
                  </Col>
                  <Col xs={9} lg={10} className={this.props.context.isDrawerOpen === true ? 'large-left-padding' : null}>
                    <h4>Raising Consciousness (PDF)</h4>
                  </Col>
                </Row>
              </a> */}
      <hr />
      <h3>Employee Identification Numbers</h3>
      <p>
        The Internal Revenue Service requires each Local Assembly or Registered Group to have its own Employer Identification
        Number. You will also need an EIN to open a bank account. For more information, please contact the Office of the Treasurer
        by phone: 847–733–3472 or email: <a href="mailto:finance@usbnc.org">finance@usbnc.org</a>
      </p>
    </div>
  );
}

export default TreasurersGeneral;
