import React, { ChangeEvent, FormEvent, JSX } from 'react';
import SetNewPassword from './SetNewPassword';
import createAppToken from '../Utilities/AppTokenEndpoint';
import OverseasValidationForm from '../Utilities/OverseasValidationForm';
import PasswordResetWithQuestionsEndpoint from '../Utilities/PasswordResetWithQuestionsEndpoint';
import { validatePassword } from '../Utilities/SetupAccValidation';
import ErrorMessageToUser from '../../Error/ErrorMessageToUser';
import { Link } from 'react-router-dom';
import SafeStringify from '../../Utilities/SafeStringify';
import { ClassesSelectListStyle } from './ClassesSelectListStyle';
import { RouteComponentProps } from 'react-router';
import { CreateBOSState } from '../CreateBOSWizard/CreateBOSBaseStep';

interface Question {
  questionId: number;
  answer: string;
}
interface PasswordResetWithQuestionsInitialState {
  showCircularProgress: boolean;
  currentStep: number;
  displayMessage: JSX.Element;
  success: boolean;
  temporaryCode: string;
  createAppTokenPromise: Function;
  unhandledErrorOccurred: boolean;
  overseasRequiredCorrectAnswerCount?: number;
  requiredCorrectSecurityAnswerCount?: number;
  countOfSecurityQuestions?: number;
  Password?: string;
  ConfirmPassword?: string;
  classes?: ClassesSelectListStyle;
}
interface PasswordResetWithQuestionsStateForm {
  [x: string]: string | number | boolean | undefined | null;
}
interface PasswordResetWithQuestionsStateQuestions {
  [x: string]: Question;
}
type PasswordResetWithQuestionsState =
  | PasswordResetWithQuestionsInitialState
  | PasswordResetWithQuestionsStateForm
  | PasswordResetWithQuestionsStateQuestions;
interface PasswordResetWithQuestionsProps {
  temporaryCode: string;
}
class PasswordResetWithQuestions extends React.Component<
  RouteComponentProps<PasswordResetWithQuestionsProps>,
  PasswordResetWithQuestionsState
> {
  constructor(props: RouteComponentProps<PasswordResetWithQuestionsProps>) {
    super(props);
    this.state = {
      showCircularProgress: false,
      currentStep: 1,
      displayMessage: <></>,
      success: false,
      temporaryCode: this.props.match.params.temporaryCode,
      createAppTokenPromise: createAppToken,
      unhandledErrorOccurred: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitAnswers = this.submitAnswers.bind(this);
    this.submitNewPassword = this.submitNewPassword.bind(this);
  }
  handleChange(e: ChangeEvent<HTMLInputElement> | { target: { name: string; value: number | string; type?: string } }) {
    const newState: PasswordResetWithQuestionsStateForm = {
      [e.target.name]:
        (e as ChangeEvent<HTMLInputElement>).target.type === 'checkbox'
          ? (e as ChangeEvent<HTMLInputElement>).target.checked
          : e.target.value,
    };
    this.setState(newState, function () {});
  }
  submitAnswers(e: ChangeEvent<HTMLInputElement> | FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const answers: Question[] = [];
    for (let i = -100; i <= 100; ++i) {
      let answerState = this.state as PasswordResetWithQuestionsStateForm;
      let existsAnswer = answerState[`overseasAnswer_${i}`] as string;
      if (existsAnswer) {
        answers.push({
          questionId: i,
          answer: existsAnswer,
        });
      }
    }

    const answerCount = answers.length;
    if (answerCount === 0) {
      this.setState({
        displayMessage: (
          <p className="center error-message">{`No questions have been answered. Please answer at least ${this.state.overseasRequiredCorrectAnswerCount} questions.`}</p>
        ),
      });
      return false;
    }

    const securityAnswerCount = answers.filter((answerInfo) => answerInfo.questionId > 0).length;
    const requiredCorrectSecurityAnswerCount = this.state.requiredCorrectSecurityAnswerCount as number;
    const countOfSecurityQuestions = this.state.countOfSecurityQuestions;
    if (requiredCorrectSecurityAnswerCount && securityAnswerCount < requiredCorrectSecurityAnswerCount) {
      const errorMessageFirstXQuestion = `first ${countOfSecurityQuestions} questions`;

      const errorMessageCount =
        securityAnswerCount === 0
          ? `None of the ${errorMessageFirstXQuestion} have been answered.`
          : securityAnswerCount === 1
          ? `Only 1 out of the ${errorMessageFirstXQuestion} has been answered.`
          : `Only ${securityAnswerCount} out of the first ${countOfSecurityQuestions} questions have been answered.`;

      const errorMessageRequirement =
        requiredCorrectSecurityAnswerCount > 1
          ? `Please answer at least ${requiredCorrectSecurityAnswerCount} questions out of the ${errorMessageFirstXQuestion}.`
          : `Please answer at least ${requiredCorrectSecurityAnswerCount} question out of the ${errorMessageFirstXQuestion}.`;

      this.setState({
        displayMessage: <p className="center error-message">{`${errorMessageCount} ${errorMessageRequirement}`}</p>,
      });

      return false;
    }

    if (
      this.state.overseasRequiredCorrectAnswerCount &&
      answerCount < (this.state.overseasRequiredCorrectAnswerCount as number)
    ) {
      if (answerCount === 1) {
        this.setState({
          displayMessage: (
            <p className="center error-message">{`Only 1 question has been answered. Please answer at least ${this.state.overseasRequiredCorrectAnswerCount} questions.`}</p>
          ),
        });
      } else {
        this.setState({
          displayMessage: (
            <p className="center error-message">{`Only ${answerCount} questions have been answered. Please answer at least ${this.state.overseasRequiredCorrectAnswerCount} questions.`}</p>
          ),
        });
      }
      return false;
    }
    this.setState({ showCircularProgress: true });
    const createAppToken = (this.state as PasswordResetWithQuestionsInitialState).createAppTokenPromise;
    createAppToken()
      .then((res: any) => {
        PasswordResetWithQuestionsEndpoint(res.data, this.state.temporaryCode as string, answers)
          .then(async () => {
            this.setState({
              displayMessage: '',
              currentStep: (this.state.currentStep as number) + 1,
              showCircularProgress: false,
            });
          })
          .catch((err) => {
            if (err.status === 400) {
              this.setState({
                showCircularProgress: false,
                displayMessage: <p className="center error-message">{err.message ? SafeStringify(err.message) : null}</p>,
              });
            } else {
              this.setState({
                showCircularProgress: false,
                unhandledErrorOccurred: true,
              });
            }
          });
      })
      .catch(() => {
        this.setState({
          showCircularProgress: false,
          unhandledErrorOccurred: true,
        });
      });
  }

  submitNewPassword(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const answers: any[] = [];

    const newPassword = this.state.Password as string;
    const newConfirmPassword = this.state.ConfirmPassword as string;
    const validatePasswordInputParameter = {
      password: newPassword,
      confirmPassword: newConfirmPassword,
    };

    const passwordValidationMessages = validatePassword(validatePasswordInputParameter);

    if (passwordValidationMessages.length > 0) {
      const errorMessage = passwordValidationMessages[0];
      this.setState({
        displayMessage: <p className="center error-message">{errorMessage}</p>,
      });
      return false;
    }

    for (let i = -100; i <= 100; ++i) {
      let answerState = this.state as PasswordResetWithQuestionsStateForm;
      let existsAnswer = answerState[`overseasAnswer_${i}`] as string;
      if (existsAnswer) {
        answers.push({
          questionId: i,
          answer: existsAnswer,
        });
      }
    }

    const createAppToken = (this.state as PasswordResetWithQuestionsInitialState).createAppTokenPromise;
    createAppToken()
      .then((res: any) => {
        PasswordResetWithQuestionsEndpoint(res.data, this.state.temporaryCode as string, answers, newPassword)
          .then(async (passwordResetResponse) => {
            this.setState({
              displayMessage: (
                <p className="center success-message">
                  {passwordResetResponse.data.message ? SafeStringify(passwordResetResponse.data.message) : null}
                </p>
              ),
              success: true,
            });
          })
          .catch((err) => {
            if (err.status === 400) {
              this.setState({
                displayMessage: <p className="center error-message">{err.message ? SafeStringify(err.message) : null}</p>,
              });
            } else {
              this.setState({ unhandledErrorOccurred: true });
            }
          });
      })
      .catch(() => {
        this.setState({ unhandledErrorOccurred: true });
      });
  }

  render() {
    if (this.state.unhandledErrorOccurred) {
      return (
        <div>
          <ErrorMessageToUser />
          <Link to="/forgot">Back</Link>
        </div>
      );
    }

    switch (this.state.currentStep) {
      case 1:
      default:
        return (
          <div className="center">
            <div data-cy="logo" className="starlogo star-margin" />
            <h2 data-cy="logotext" className="logo-text">
              Bahá’ís of the United States
            </h2>
            <OverseasValidationForm
              title="Recover an Account"
              handleSubmit={this.submitAnswers}
              displayMessage={(this.state as PasswordResetWithQuestionsInitialState).displayMessage}
              handleChange={this.handleChange}
              createAppTokenPromise={(this.state as PasswordResetWithQuestionsInitialState).createAppTokenPromise}
              temporaryCode={(this.state as PasswordResetWithQuestionsInitialState).temporaryCode}
              showCircularProgress={(this.state as PasswordResetWithQuestionsInitialState).showCircularProgress}
              model={this.state as CreateBOSState}
            />
          </div>
        );
      case 2:
        return (
          <div className="center">
            <div data-cy="logo" className="starlogo star-margin" />
            <h2 data-cy="logotext" className="logo-text">
              Bahá’ís of the United States
            </h2>
            <SetNewPassword
              displayMessage={(this.state as PasswordResetWithQuestionsInitialState).displayMessage}
              success={(this.state as PasswordResetWithQuestionsInitialState).success}
              handleChange={this.handleChange}
              handleSubmit={this.submitNewPassword}
            />
          </div>
        );
      case 3:
        return <div className="center">{(this.state as PasswordResetWithQuestionsInitialState).displayMessage}</div>;
    }
  }
}
export default PasswordResetWithQuestions;
