import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import Stories from '../../assets/img/FundEducation/Stories.png';

function FundStories() {
  return (
    <Row>
      <Col sm={1}>
        <img src={Stories} alt="Videos" className="fund-logo" />
      </Col>
      <Col sm={11}>
        <h1>Inspirational Stories</h1>
        <p>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/Stories-Tony+and+Maria.pdf">Tony and Maria</a>
        </p>
        <p>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/Stories-Uncle+Samad.pdf">Uncle Samad</a>
        </p>
        <p>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/Stories-Three+Dollars+and+Ten+Cents.pdf">
            Three Dollars and Ten Cents
          </a>
        </p>
        <p>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/Stories-Special+Day+of+Joyous+Giving.pdf">
            A Special Day of Joyous Giving
          </a>
        </p>
        <p>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/Stories-Norah+Crossley+About+Baha%27i+Funds.pdf">
            Norah Crossley about Bahá’í Funds
          </a>
        </p>
        <p>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/Stories-Icecream+in+the+Fund+Box.pdf">
            Ice Cream in the Fund Box
          </a>
        </p>
        <p>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/Stories-Ardeshir+Rustampui%CC%80ri.pdf">
            Ardeshir Rustampúri
          </a>
        </p>
        <p>
          <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Tools/Stories-God+always+repay+me+in+some+way+or+another!.pdf">
            God Always Repays me in Some Way or Another
          </a>
        </p>
      </Col>
    </Row>
  );
}

export default FundStories;
