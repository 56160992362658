import React from 'react';
import { Route } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';

function BoundariedRoute(props) {
  return (
    <ErrorBoundary>
      <Route {...props}>{props.children}</Route>
    </ErrorBoundary>
  );
}

export default BoundariedRoute;
