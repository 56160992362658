import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ListItem, ListItemText, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import FormatUpdatedDate from './Utilities/FormatUpdatedDate';

function DropDown(props) {
  const [openChapter, setOpenChapter] = useState(false);

  return (
    <div key={props.chapterKey}>
      <ListItem button onClick={() => setOpenChapter(!openChapter)}>
        <Link data-cy="glsalink" data-cyid={`glsaChapter${props.chapterKey}`} to={props.chapterUrl}>
          <ListItemText primary={props.chapterName} />
        </Link>
        {openChapter ? (
          <ExpandLess data-cy={`glsa-listitem${props.chapterKey}`} />
        ) : (
          <ExpandMore data-cy={`glsa-listitem${props.chapterKey}`} />
        )}
        <p className="inline-vertical-center">
          <em>{FormatUpdatedDate(props.chapterRollupRevisedDate)}</em>
        </p>
      </ListItem>
      <Collapse in={openChapter} timeout="auto" unmountOnExit>
        <div>
          <ListItem button selected={openChapter} className="display-block">
            {props.sections.map((section, index) => (
              <div key={index}>
                <ListItem data-cy="sectiondrawerlist" className="display-block">
                  <div className="display-flex">
                    <Link data-cysublev="sectionlink" data-cy={`sectiondrawerlink${index}`} to={section.url}>
                      <ListItemText data-cy={`sectiontext${index}`} primary={section.title} />
                    </Link>
                    <p className="inline-vertical-center medium-left-padding">
                      <em>{FormatUpdatedDate(section.sectionRevisedDate)}</em>
                    </p>
                  </div>
                  {section.subSections.map((subSection, key) => (
                    <div key={key}>
                      <ListItem key={key}>
                        <Link data-cysublev="subsectionlink" data-cy={`subSectiondrawerlink${index}-${key}`} to={subSection.url}>
                          <ListItemText data-cy={`subSectiontext${index}-${key}`} primary={subSection.title} />
                        </Link>
                        <p className="inline-vertical-center medium-left-padding">
                          <em>{FormatUpdatedDate(subSection.subSectionRevisedDate)}</em>
                        </p>
                      </ListItem>
                    </div>
                  ))}
                </ListItem>
              </div>
            ))}
          </ListItem>
        </div>
      </Collapse>
    </div>
  );
}
export default DropDown;
