import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import DonorAdvisedFunds from '../../assets/img/PlannedGiving/DonorAdvisedFunds.png';

function DonorFunds() {
  return (
    <Row>
      <Col sm={1}>
        <img src={DonorAdvisedFunds} alt="Donor Advised Funds" className="fund-logo" />
      </Col>
      <Col sm={11}>
        <h1>Donor Advised Funds</h1>
        <p>
          A Donor Advised Fund is a charitable giving investment account, administered by a financial institution or qualified
          Community Foundation that sponsors charitable organizations, which allows believers to make a direct contribution to the
          National Spiritual Assembly in a tax effective and convenient manner.
        </p>
        <ul className="plain-list">
          <li>
            <strong>Download: </strong>
            <a href="https://bahai-web.s3.amazonaws.com/forms/Treasurer/Planned-Giving/DonorAdvisedFunds.pdf">
              Donor Advised Funds
            </a>
          </li>
        </ul>
      </Col>
    </Row>
  );
}

export default DonorFunds;
