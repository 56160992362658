import React from 'react';
import ProfileFunction from '../Profiles/Utilities/ProfileFunction';
import withContext from '../ContextAPI/Context_HOC';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { OptimizelyFeature } from '@optimizely/react-sdk';

class Delegates extends React.Component {
  constructor(props) {
    super(props);
    this.profilefn = new ProfileFunction();
    this.auth = this.props.context.authService;
    this.state = {
      token: this.auth.getToken(),
      decodedtoken: this.auth.getDecodedToken(),
      wrongPath: false,
    };
  }
  render() {
    const isdelegate = this.profilefn.isPersonDelegate(this.state.decodedtoken);
    const isadmin = this.profilefn.isAdmin(this.state.decodedtoken);
    return (
      <div>
        {/* <img
          alt="Group photo of the attendees of National Convention on the steps of the House of Worship"
          className="full-width-view center"
        /> */}
        <h1 className="center">Delegate Resources for the 117th US Bahá’í National Convention</h1>
        <h2 className="center">April 24 - 27, 2025</h2>
        <OptimizelyFeature feature="delegate_page">
          {(isEnabled) =>
            (isEnabled && isdelegate) || isadmin ? (
              <div>
                <p>
                  The National Spiritual Assembly is pleased to recognize you as a delegate to the 117th United States Bahá’í
                  National Convention, scheduled to take place April 24-27, 2025. Delegates will receive an official email letter
                  from the National Assembly in January with important details and information about the gathering. A decision
                  about whether a delegate can bring a guest will be forthcoming. Please continue to check this webpage for
                  updates and ensure that your contact information (including phone number, email address, and postal address) is
                  up to date in our records. You may do this in <Link to="/myprofile">My Profile</Link>.
                </p>
                <p>
                  A delegate must reside within the electoral unit he or she represents; therefore, if you will move outside of
                  your electoral unit prior to Ridván, kindly notify the National Assembly so that another individual may be
                  appointed to replace you.
                </p>
                <p>
                  If you will travel by air, we strongly recommend flying in and out of O’Hare Airport, rather than Midway
                  Airport. O’Hare is much closer to our venue hotel and the Temple. In addition, the National Assembly offers
                  complimentary bus transport to O’Hare on Sunday, at the close of Convention.
                </p>
                <p>
                  If you have any questions, please contact the National Convention Desk at{' '}
                  <a href="mailto:nationalconvention@usbnc.org">nationalconvention@usbnc.org</a> or{' '}
                  <a href="tel:8477333541">(847) 733-3541</a>.
                </p>
                <p>Your service as a delegate is sincerely appreciated.</p>
              </div>
            ) : (
              <Row>
                <Col sm={12} md={6}>
                  {/* <div className="grey-box">
                    <h3>Downloadable Audio and Video Excerpts and Photos</h3>
                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href=""
                      >
                        Google Drive
                      </a>
                    </p>
                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href=""
                      >
                        Dropbox
                      </a>
                    </p>
                  </div> */}
                  {/* <div className="grey-box">
                    <h3>Convention Materials</h3>
                    <ul className="no-margin">
                    </ul>
                  </div> */}
                  <div className="grey-box">
                    <h3>Hotel Reservations</h3>
                    <p>DoubleTree by Hilton Hotel Chicago</p>
                    <ul className="plain-list">
                      <li>
                        <strong>Address:</strong>
                        <a
                          href="https://www.google.com/maps/place/DoubleTree+by+Hilton/@42.0506078,-87.7639778,14.14z/data=!4m13!1m7!3m6!1s0x880fcf62cb5479b3:0xed32cdbf062cd58d!2s9599+Skokie+Blvd,+Skokie,+IL+60077!3b1!8m2!3d42.0547616!4d-87.7465155!3m4!1s0x880fcf41d1d9cdbf:0xd3d969846198562!8m2!3d42.0543267!4d-87.7456187"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          9599 Skokie Blvd, Skokie, IL 60077
                        </a>
                      </li>
                      <li>
                        <strong>Check-in Time:</strong> 3:00 p.m.
                      </li>
                      <li>
                        <strong>Check-out Time:</strong> 12:00 noon
                      </li>
                    </ul>
                    <p>
                      <a className="primary-button" href="" target="_blank" rel="noopener noreferrer">
                        Make Hotel Reservations Now!
                      </a>
                    </p>
                    <p>
                      A special rate of $115 per night has been arranged for delegates at the DoubleTree by Hilton Hotel Chicago -
                      North Shore Conference Center (in Skokie, IL).{' '}
                      <span className="attention">
                        <strong>Please make reservations as early as possible but no later than March 25, 2024</strong>.
                      </span>{' '}
                      Transportation will be provided between this hotel and the Temple.
                    </p>
                    <p>
                      Delegates are responsible for covering the cost of their own hotel stay. Please see guidance in the
                      “Financial Assistance” section below for more information.
                    </p>
                    <p>
                      Delegates are not required to stay at the DoubleTree hotel and are free to make alternate lodging
                      arrangements, but you will then be responsible for your own transportation throughout Convention.
                    </p>
                    {/* <p>
                      The hotel reservation link has expired; if you need assistance with lodging, contact{' '}
                      <a href="mailto:NationalConvention@usbnc.org">nationalconvention@usbnc.org</a>.
                    </p> */}
                  </div>
                  <div className="grey-box">
                    <h4>117th US Bahá’í National Convention</h4>
                    <iframe
                      title="116th US Bahá’í National Convention"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2961.541837222021!2d-87.68646104909624!3d42.074438979105025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fdab8c6e3986f%3A0xe5587e514916bd5e!2sBaha&#39;i+House+of+Worship!5e0!3m2!1sen!2sus!4v1546897156094"
                      width="400"
                      height="450"
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                    <br />
                    <ul className="plain-list">
                      <li>
                        <strong>Location:</strong> Bahá’í House of Worship
                      </li>
                      <li>
                        <strong>Address:</strong>
                        <a
                          href="https://maps.google.com/maps?ie=UTF8&amp;q=Baha%27i+House+of+Worship+Photo+100+Linden+Ave,+Wilmette,+IL+%E2%80%8E&amp;fb=1&amp;gl=us&amp;hq=Baha%27i+House+of+Worship+Photo+100+Linden+Ave,+Wilmette,+IL+%E2%80%8E&amp;cid=16526097720219712862&amp;t=m&amp;iwloc=A&amp;ll=42.074296,-87.684095&amp;spn=0.006295,0.006295&amp;source=embed"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          100 Linden Ave, Wilmette, IL 60091
                        </a>
                      </li>
                      <li>
                        <strong>Email:</strong> <a href="mailto:NationalConvention@usbnc.org">nationalconvention@usbnc.org</a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col sm={12} md={6}>
                  <div className="grey-box">
                    <h3>Pre-Registration</h3>
                    <p>
                      All delegates must pre-register online for Convention.{' '}
                      <span className="attention">
                        <strong>The deadline for pre-registration is March 25, 2024.</strong>
                      </span>
                    </p>
                    <p>
                      In 2024, only delegates are invited to attend Convention; guests will not be allowed entrance to the lower
                      level of the Bahá’í House of Worship during National Convention. The Welcome Center and Auditorium will
                      remain open to the public.
                    </p>
                    <p>
                      At this time, no arrangements have been made for any portion of the National Convention to be accessible by
                      Zoom or other virtual means.
                    </p>
                    <a className="primary-button" href="" target="_blank" rel="noopener noreferrer">
                      Pre-register Now!
                    </a>
                  </div>
                  <Accordion allowZeroExpanded={true}>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h4 className="u-position-relative">
                            Topics previously addressed at National Convention
                            <div className="accordion__arrow" role="presentation" />
                          </h4>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          The topics below have been raised by delegates at several previous National Conventions. The National
                          Spiritual Assembly has considered these matters and shares the following information in response.
                        </p>
                        <h5>BAHÁ’Í TEACHINGS AND HOMOSEXUALITY:</h5>
                        <p>
                          <a href="https://www.bahai.us/bahai-teachings-homosexuality/" target="_blank" rel="noopener noreferrer">
                            The Baha’i Teachings and Homosexuality
                          </a>
                        </p>
                        <h5>REASONS FOR WITHDRAWALS FROM THE FAITH:</h5>
                        <p>
                          In 2023, 249 people withdrew from the Faith. Those who commented on their reasons for doing so shared
                          the following (beginning with most frequently cited reasons):
                        </p>
                        <ul>
                          <li>do not consider themselves believers</li>
                          <li>found another religious community or spiritual path</li>
                          <li>did not accept social laws (six explicitly related to LGBTQ+ issues)</li>
                          <li>were minors withdrawn by a parent</li>
                          <li>personal reasons</li>
                          <li>issues with the community</li>
                          <li>preferred not to say</li>
                          <li>enrolled too quickly</li>
                        </ul>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h4 className="u-position-relative">
                            Letters to the Delegates
                            <div className="accordion__arrow" role="presentation" />
                          </h4>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <ul></ul>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h4 className="u-position-relative">
                            Annual Reports
                            <div className="accordion__arrow" role="presentation" />
                          </h4>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          The National Assembly’s{' '}
                          <a href="https://bahai-web.s3.amazonaws.com/messages/AnnualReports/2024-US-NSA-Annual-Report.pdf">
                            Ridván 2024 Annual Report
                          </a>{' '}
                          is now available. A printed copy will be mailed to you prior to Convention so that you may arrive fully
                          informed of the activities and plans of your National Assembly.
                        </p>
                        <p>
                          <Link to="/resources/annual-reports">Read previous Annual Reports</Link>
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h4 className="u-position-relative">
                            Essential Study Materials
                            <div className="accordion__arrow" role="presentation" />
                          </h4>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <ul className="plain-list">
                          <h5>Essential Study Materials:</h5>
                          <li>
                            <a href="https://bahai-web.s3.amazonaws.com/NatCon/2023/Complete+Article+VIII+of+Constitution+and+By-Laws+of+US+NSA.pdf">
                              Complete Article VIII of Constitution and By-Laws of US NSA (PDF)
                            </a>
                          </li>
                          <li>
                            <a href="https://bahai-web.s3.amazonaws.com/NatCon/2022/UHJ-Compilation-on-National-Convention-Dec92.pdf">
                              Compilation on National Convention by the Research Dept. of the Universal House of Justice, dated
                              December 1992 (PDF)
                            </a>
                          </li>
                          <li>
                            <a href="https://bahai-web.s3.amazonaws.com/NatCon/2022/UHJ-Statement-on-National-Convention-Dec92.pdf">
                              Statement on National Convention by the Research Dept. of the Universal House of Justice, dated
                              December 1992 (PDF)
                            </a>
                          </li>
                          <li>
                            <Link to="/guidance/uhj/baha-i-world-center-publishes-new-compilation-on-the-institution-of-the-national-spiritual-assembly-2023/baha-i-world-center-publishes-new-compilation-on-the-institution-of-the-national-spiritual-assembly-2023">
                              Compilation on the National Spiritual Assembly (PDF)
                            </Link>
                          </li>
                        </ul>
                        <ul className="plain-list">
                          <h5>Bahá’í Electoral Process:</h5>
                          <li>
                            <a href="https://s3.amazonaws.com/bahai-web/tab/forms/March-25-2007-Electoral-Procedure.pdf">
                              March 25, 2007 Electoral Procedure - English (PDF)
                            </a>
                          </li>
                          <li>
                            <a href="https://s3.amazonaws.com/bahai-web/tab/forms/2007-uhj-importance-bahai-elections_Spanish.pdf">
                              March 25, 2007 Electoral Procedure - Spanish (PDF)
                            </a>
                          </li>
                          <li>
                            <a href="https://s3.amazonaws.com/bahai-web/tab/forms/2007-uhj-importance-bahai-elections_Persian.pdf">
                              March 25, 2007 Electoral Procedure - Persian (PDF)
                            </a>
                          </li>
                          <li>
                            <a href="https://s3.amazonaws.com/bahai-web/tab/forms/May-16-2013-UHJ-to-delegates.pdf">
                              May 16, 2013 Sacred Nature of National Convention (PDF)
                            </a>
                          </li>
                          <li>
                            <a href="https://bahai-web.s3.amazonaws.com/NatCon/2022/the-sacred-task-of-voting-in-a-bahai-election.pdf">
                              The Sacred Task of Voting in a Bahá’í Election - English (PDF)
                            </a>
                          </li>
                          <li>
                            <a href="https://bahai-web.s3.amazonaws.com/NatCon/2022/20160804-UHJ-to-all-NSAs-on-the-Allocation-of-Delegates.pdf">
                              August 4, 2016 from the Universal House of Justice to all National Spiritual Assemblies on the
                              Allocation of Delegates (PDF)
                            </a>
                          </li>
                        </ul>
                        <ul className="plain-list">
                          <h5>Other Significant Letters:</h5>
                          <li>
                            <Link to="/guidance/uhj/ridvan-2023-message-from-the-supreme-body-2023/ridvan-2023-message-from-the-supreme-body-2023">
                              Ridván 2023 Message from the Supreme Body
                            </Link>
                          </li>
                          <li>
                            <Link to="/guidance/uhj/message-to-counselors-conference-defines-features-of-new-nine-year-plan-2021">
                              December 30, 2021 - Message to Counselors’ Conference Defines Features of New Nine Year Plan
                            </Link>
                          </li>
                          <li>
                            <Link to="/guidance/feast_message/feast-of-ala-2022/feast-of-ala-2022">
                              March 1, 2022 - National Spiritual Assembly’s Message for the Feast of ‘Alá’
                            </Link>
                          </li>
                          <li>
                            <a href="https://bahai-web.s3.amazonaws.com/NatCon/2022/20200722-UHJ-to-Baha%27is-of-the-US+Moment+Historic+Portent.pdf">
                              July 22, 2020 - Universal House of Justice’s letter to the American Bahá’í community
                            </a>
                          </li>
                          <li>
                            <a href="https://bahai-web.s3.amazonaws.com/NatCon/2022/151229_uhj-to-cbc-conference-five-year-plan_english.pdf">
                              December 29, 2015 - Universal House of Justice’s letter to the Counselors outlines the Five Year
                              Plan
                            </a>
                          </li>
                          <li>
                            <Link to="/guidance/uhj/supreme-body-on-application-of-the-plan-s-framework-for-action-to-the-current-circumstances-in-the-world-2020/supreme-body-on-application-of-the-plan-s-framework-for-action-to-the-current-circumstances-in-the-world-2020">
                              May 9, 2020 - Supreme Body on Application of the Plan’s Framework for Action to the Current
                              Circumstances in the World
                            </Link>
                          </li>
                          <li>
                            <Link to="/guidance/uhj/supreme-body-shares-historical-review-and-reflections-on-the-future-2023/supreme-body-shares-historical-review-and-reflections-on-the-future-2023">
                              November 28, 2023 - Supreme Body Shares Historical Review and Reflections on the Future (PDF)
                            </Link>
                          </li>
                        </ul>
                        <ul className="plain-list">
                          <h5>Additional Resources:</h5>
                          <li>
                            You are encouraged to learn more about the responsibilities of delegates and the unique features of
                            the Bahá’í electoral process by studying a compilation prepared by the Research Department of the
                            Universal House of Justice entitled, “The Sanctity and Nature of Bahá’í Elections,” available online{' '}
                            <a
                              href="https://www.bahai.org/library/authoritative-texts/compilations/sanctity-nature-bahai-elections/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              here
                            </a>
                            .
                          </li>
                          <li>
                            Guidance from Shoghi Effendi and the Universal House of Justice on Bahá’í National Conventions can be
                            found in <em>Lights of Guidance</em>.
                          </li>
                          <li>
                            The Bahá’í National Convention is a unique institution of the Faith, distinct from other conferences
                            or deepenings. According to Shoghi Effendi, the two main purposes of the Convention are the election
                            of the National Assembly, and the “full, frank and unhampered consultation between the National
                            Assembly and the assembled delegates….” By virtue of their election, delegates are invested with
                            certain duties and privileges. Section 2 of Article VIII of the Articles of Incorporation,
                            Constitution, and By-Laws of the National Spiritual Assembly of the Bahá’ís of the United States
                            explains that “the rights and privileges of a delegate may not be assigned nor may they be exercised
                            by proxy.”
                          </li>
                        </ul>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h4 className="u-position-relative">
                            COVID-19 Vaccination Policy
                            <div className="accordion__arrow" role="presentation" />
                          </h4>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          The National Spiritual Assembly strongly encourages all participants to be fully vaccinated against
                          COVID-19, including all recommended boosters when eligible.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h4 className="u-position-relative">
                            Contact
                            <div className="accordion__arrow" role="presentation" />
                          </h4>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          If you have any problems or questions, or for additional information, please contact the National
                          Convention Desk: <a href="mailto:NationalConvention@usbnc.org">nationalconvention@usbnc.org.</a> or{' '}
                          <a href="tel:(847) 733-3541">(847) 733-3541</a>
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h4 className="u-position-relative">
                            Financial Assistance
                            <div className="accordion__arrow" role="presentation" />
                          </h4>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          “He [Shoghi Effendi] considers the policy of your Assembly of helping delegates from distant points to
                          attend the Convention, an excellent one, as the attendance of these delegates enables them to carry back
                          a very real awareness of the work in hand and the needs of the hour, to their local Communities.”{' '}
                          <em>(March 1, 1951 to a National Spiritual Assembly)</em>
                        </p>
                        <p>
                          In reference to delegates who may need financial assistance in order to participate in the Bahá’í
                          National Convention in the United States, the National Spiritual Assembly offers the following general
                          guidelines:
                        </p>
                        <p>
                          If a delegate is unable to cover the costs involved in serving in this capacity (travelling to Wilmette,
                          staying in a hotel, etc.) the recommended procedure is as follows: First, turn to your Local Spiritual
                          Assembly to request assistance. If your Local Assembly does not have the financial means to provide any
                          or sufficient assistance, it may be that other Local Assemblies within your electoral unit have monies
                          set aside for this purpose. Some electoral units have delegate assistance funds that Assemblies within
                          that unit donate to over time and use when the need arises. If no Local Assemblies in your unit have the
                          resources to assist you, then you may make a request directly to the National Spiritual Assembly.
                          Finances should never be an obstacle that prevents a delegate from being able to attend the proceedings
                          in person.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h4 className="u-position-relative">
                            Meals
                            <div className="accordion__arrow" role="presentation" />
                          </h4>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          <strong>Breakfast</strong> is included in the room rate for those who stay at the DoubleTree hotel and
                          will be available each morning from 6:00 to 8:00 a.m.
                        </p>
                        <p>
                          <strong>Lunch and dinner</strong> will be provided for delegates and registered guests, Thursday through
                          Saturday, in Foundation Hall.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h4 className="u-position-relative">
                            Post-Convention Reports
                            <div className="accordion__arrow" role="presentation" />
                          </h4>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          After the Bahá’í National Convention, delegates are expected to return to their electoral units and
                          convey to the believers in their respective areas the spirit and substance of the Convention. As the
                          Universal House of Justice wrote in a letter dated April 9, 1970 to a National Spiritual Assembly, “…it
                          is preferable that the delegates attend the sessions of the Convention in person so they may take an
                          active part in all of its proceedings and acquaint their fellow-workers on their return with an account
                          of the accomplishments, decisions and plans of their national community.”
                        </p>
                        <p>
                          Delegates often ask for advice regarding how to prepare a post-Convention report and coordinate the
                          logistics within their electoral unit. We provide below comments on some of the most frequently asked
                          questions about delegate reports:
                        </p>
                        <ul>
                          <li>
                            Delegates are welcome to consult with each other at the National Convention and share ideas and “best
                            practices,” usually during breaks and meals.
                          </li>
                          <li>
                            The Secretary of the National Spiritual Assembly reviews all written, video and photographic material
                            produced by the Office of the Secretary and The American Bahá’í staff, and approves its posting on the
                            community’s website. Delegates are free to use these online materials (photos, articles, video and
                            audio clips) to give their reports—this is part of the reason it is produced. Names of delegates who
                            spoke during Convention and whose comments are shared as part of these materials are redacted. Please
                            view <Link to="/resources/natcon-2019/">the 2019 Convention</Link> to see an example of the types of
                            materials posted.
                          </li>
                          <li>
                            Some delegates give one report, while others offer reports on several dates in different locations
                            throughout their electoral unit. This will depend on your own availability, the size of the electoral
                            unit, and your own personal preference. The style and presentation of the reports will be as different
                            and unique as the delegates themselves.
                          </li>
                          <li>
                            Refer to the letter dated December 1, 2023 from the National Convention Desk for more information.
                          </li>
                        </ul>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h4 className="u-position-relative">
                            Preliminary Schedule
                            <div className="accordion__arrow" role="presentation" />
                          </h4>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <h4>Wednesday, April 23</h4>
                        <p>Registration Opens at Hotel</p>
                        <h4>Thursday, April 24</h4>
                        <p>
                          <span>Morning:</span> Delegate Preparation Session with Counselors (at hotel)
                          <br />
                          <span>Afternoon:</span> 117th US Bahá’í National Convention Convenes (at House of Worship)
                        </p>
                        <h4>Friday, April 25</h4>
                        <p>Convention Continues</p>
                        <h4>Saturday, April 26</h4>
                        <p>Election and Convention Continues</p>
                        <h4>Sunday, April 27</h4>
                        <p>Convention Concludes at Noon</p>
                        <p>
                          A schedule of the entire Convention period will be shared with delegates when it has been finalized by
                          the National Spiritual Assembly.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h4 className="u-position-relative">
                            Transportation
                            <div className="accordion__arrow" role="presentation" />
                          </h4>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <h5>Chartered Bus Service</h5>
                        <p>
                          Bus service will be provided for delegates and registered guests between the DoubleTree hotel and the
                          House of Worship during the course of Convention.
                        </p>
                        <h5>Other Transportation</h5>
                        <p>
                          YOU ARE RESPONSIBLE FOR TRANSPORTATION BETWEEN THE AIRPORT AND THE HOTEL UPON YOUR ARRIVAL <u>AND</u>{' '}
                          DEPARTURE. THREE OPTIONS ARE PROVIDED BELOW:
                        </p>
                        <p>
                          <strong>
                            <em>American Taxi:</em>
                          </strong>{' '}
                          <a href="tel:(800) 244-1177">(800) 244-1177</a> /{' '}
                          <a href="http://www.americantaxi.com" target="_blank" rel="noopener noreferrer">
                            website
                          </a>
                        </p>
                        <p>
                          <strong>
                            <em>Go Airport Express:</em>
                          </strong>{' '}
                          <a href="tel:(888) 284-3826">(888) 284-3826</a> /{' '}
                          <a href="https://airportexpress.com/suburban-service/" target="_blank" rel="noopener noreferrer">
                            website
                          </a>
                        </p>
                        <p>
                          <strong>
                            <em>Public Transit:</em>
                          </strong>{' '}
                          Both the House of Worship and the DoubleTree Hotel are accessible by public transit.{' '}
                          <a href="https://www.transitchicago.com/planatrip/" target="_blank" rel="noopener noreferrer">
                            Click here to plan your trip
                          </a>{' '}
                          or call <a href="tel:3128367000">(312) 836-7000</a>.
                        </p>
                        <ul>
                          <li>
                            <strong>O’Hare Airport to Hotel (10 miles):</strong> there are two bus options that leave from the
                            Multi-Modal Facility (car rental area): PACE bus 250 and Pulse Dempster line toward Davis. Both will
                            take you to the Skokie Swift station where you can catch the 97 bus to the hotel. Another option is
                            the CTA Blue Line to Jefferson Park Station. From this station you can catch the 226 bus toward
                            Oakton/Hamilton and switch to the 97 bus at Oakton/Lincoln. Check which ones work best with your
                            timing using the{' '}
                            <a href="https://www.transitchicago.com/planatrip/" target="_blank" rel="noopener noreferrer">
                              trip planner
                            </a>
                            .
                          </li>
                          <li>
                            <strong>Midway Airport to Hotel (19 miles):</strong> CTA Orange Line to Roosevelt Station and switch
                            to the Red Line toward Howard. At Howard you can switch to the Yellow or Purple lines or the 97 bus.
                            Check which one works best with your timing using the{' '}
                            <a href="https://www.transitchicago.com/planatrip/" target="_blank" rel="noopener noreferrer">
                              trip planner
                            </a>
                            .
                          </li>
                          <li>
                            <strong>Hotel to the House of Worship:</strong>{' '}
                            <a href="https://www.pacebus.com/route/208" target="_blank" rel="noopener noreferrer">
                              Pace Bus 208
                            </a>{' '}
                            to Davis Station. Switch to the CTA Purple line toward Linden. Exit the station and turn right. Walk 1
                            block east.
                          </li>
                        </ul>
                        <p className="attention">
                          <strong>
                            For the second year, we are offering bus transport at the close of Convention — two buses will leave
                            the Temple for O’Hare Airport (the first departs at 12:30 p.m. and arrives at approx. 1:30 p.m., the
                            second departs at 2:30 p.m. and arrives at approx. 3:30.) Please sign up for this service when you
                            complete your online registration.
                          </strong>
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                  <div className="grey-box">
                    <h4>National Convention Coverage Archives</h4>
                    <ul className="plain-list">
                      <OptimizelyFeature feature="natcon">
                        {(isEnabled) =>
                          isEnabled ? (
                            <li>
                              <Link to="/news/natcon-2024">2024</Link>
                            </li>
                          ) : null
                        }
                      </OptimizelyFeature>
                      <li>
                        <Link to="/news/natcon-2023">2023</Link>
                      </li>
                      <li>
                        <Link to="/news/natcon-2022">2022</Link>
                      </li>
                      <li>
                        <Link to="/resources/natcon-2019/">2019</Link>
                      </li>
                      <li>
                        <Link to="/resources/natcon-2018/">2018</Link>
                      </li>
                      <li>
                        <Link to="/resources/natcon-2017/">2017</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            )
          }
        </OptimizelyFeature>
      </div>
    );
  }
}

export default withContext(Delegates);
