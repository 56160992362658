import React, { useEffect, useState, FunctionComponent } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import { Observable } from 'rxjs';

type calendarStringFormatsType = {
  fullUnicode: string;
  extendedAsciiAndStylizedQuotes: string;
  extendedAscii: string;
  ascii: string;
};
type calendarServiceType = { getStringFormats: () => Observable<calendarStringFormatsType[]> };
type contextType = { calendarService: calendarServiceType };
type functionParamsType = { context: contextType };

const tableStyle = {
  border: '1px solid black',
};

const CalendarFormats: FunctionComponent<functionParamsType> = ({ context: { calendarService } }) => {
  const [formats, setFormats] = useState<calendarStringFormatsType[]>([]);

  useEffect(() => {
    const subscription = calendarService.getStringFormats().subscribe((x) => setFormats(x));
    return () => subscription.unsubscribe();
  }, [calendarService]);

  return (
    <div>
      <h1>Calendar formats</h1>
      <table style={tableStyle}>
        <tr>
          <th style={tableStyle}>Full Unicode</th>
          <th style={tableStyle}>Extended ASCII and stylized quotes</th>
          <th style={tableStyle}>Extended ASCII</th>
          <th style={tableStyle}>ASCII</th>
        </tr>
        {formats.map((x, key) => (
          <tr key={key}>
            <td style={tableStyle}>{x.fullUnicode}</td>
            <td style={tableStyle}>{x.extendedAsciiAndStylizedQuotes}</td>
            <td style={tableStyle}>{x.extendedAscii}</td>
            <td style={tableStyle}>{x.ascii}</td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default withContext(CalendarFormats);
