import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';
import IToken from '../../Login/IToken';
import type { Observable } from 'rxjs';
import { switchMap, filter, map } from 'rxjs/operators';
import type IHouseholdProxy from './IHouseholdProxy';
import type HouseholdCriteria from './HouseholdCriteria';
import type HouseholdResponseModel from './HouseholdResponseModel';
import type HouseholdMemberModel from './HouseholdMemberModel';

export default class HouseholdProxy implements IHouseholdProxy {
  authService: IToken;
  dataApiBaseUrl: string;

  constructor(auth: IToken, dataApiBaseUrl: string) {
    this.authService = auth;
    this.dataApiBaseUrl = dataApiBaseUrl;
  }

  get(): Observable<HouseholdResponseModel> {
    return this.authService
      .getTokenObservable()
      .pipe(filter((token) => !!token))
      .pipe(
        switchMap((token) =>
          GlobalAPICall.getObservable(`${this.dataApiBaseUrl}/api/Household`, {
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          })
        )
      )
      .pipe(map((items) => items.data));
  }

  members(data: HouseholdCriteria): Observable<HouseholdMemberModel[]> {
    return this.authService
      .getTokenObservable()
      .pipe(filter((token) => !!token))
      .pipe(
        switchMap((token) =>
          GlobalAPICall.postObservable(`${this.dataApiBaseUrl}/api/Household/Members`, data, {
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          })
        )
      )
      .pipe(map((items) => items.data));
  }

  membersUnder15YearsOld() {
    return this.members({ AgeRangeTo: 14, IsMyChild: true });
  }

  members15YearsAndOlder() {
    return this.members({ AgeRangeFrom: 15 });
  }
}
