import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemText, List, Divider, Collapse } from '@material-ui/core';
import { Link } from 'react-router-dom';
import withContext from '../ContextAPI/Context_HOC';
import { withStyles } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const styles = (theme) => ({
  selected: {},
  seclistItemText: {
    fontSize: '0.35cm',
  },
  thirdlistItemText: {
    fontSize: '0.7em',
  },
  nested: {
    paddingLeft: theme.spacing(4),
    // fontSize:'0.72em',
    fontSize: theme.fontSize * 1,
  },
  drawerWidth: {
    width: '300px',
  },
  scroll: {
    maxHeight: '200px',
    overflowY: 'scroll !important',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  paperAnchorDockedLeft: {
    anchor: 'left',
    variant: 'permanent',
    paperAnchorDockedLeft: true,
    docked: true,
  },
});

class PrayerDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prayerTaxonomy: [],
      openSubMenu: false,
      selected: null,
      loading: true,
      error: false,
    };
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

  updateSelected(selectedIndex) {
    this.setState({ selected: selectedIndex });
  }

  componentDidMount() {
    this.props.context.prayerContent
      .taxonomy('prayer_category')
      .toObservable()
      .subscribe({
        next: async (response) => {
          this.setState({ prayerTaxonomy: response.taxonomy.terms.slice() });
          this.setState({ loading: false });
        },
        error: async () => {
          this.setState({ error: true });
        },
      });
  }

  handleMenuClick = () => {
    this.setState((state) => ({ openSubMenu: !state.openSubMenu }));
    this.setState((state) => ({ openPrayers: !state.openPrayers }));
  };

  handlePrayerItemClick = () => {
    if (this.state.openSubMenu === true) this.setState({ openSubMenu: false });
    this.setState(() => ({ openPrayers: false }));
  };

  render() {
    const SecPrayerDrawer = (props) => (
      <List className={props.class}>
        {props.secondLevel.map((data, index) => (
          <div key={index}>
            <ListItem data-cy="sec-prayerdrawer" onClick={() => this.updateSelected(index)} selected={selected === index}>
              <Link
                data-cysublev="subdrawerlink"
                data-cy={`prayersecdrawerlink${index}`}
                to={`/prayer/${props.firstLevel}/${data.codename}`}
              >
                <ListItemText classes={{ primary: classes.thirdlistItemText }} primary={data.name} />
              </Link>
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
    );
    const { classes } = this.props;
    const { selected } = this.state;
    let navLinks = [];
    this.state.prayerTaxonomy.forEach((prayer, index) => {
      if (prayer.terms.length === 0) {
        navLinks.push(
          <div key={index}>
            <ListItem data-cy="prayerdrawerlist" onClick={this.handlePrayerItemClick}>
              <Link data-cysublev="subdrawerlink" data-cy={`prayerdrawerlink${index}`} to={`/prayer/${prayer.codename}`}>
                <ListItemText primary={prayer.name} className="subdrawer-list-item" />
              </Link>
            </ListItem>
            <Divider />
          </div>
        );
      } else {
        navLinks.push(
          <div key={index}>
            <ListItem data-cy="prayerdrawerlist" button onClick={this.handleMenuClick}>
              <Link data-cy={`prayerdrawerlink${index}`} to={`/prayer/${prayer.codename}`}>
                <ListItemText primary={prayer.name} classes={{ primary: classes.seclistItemText }} />
              </Link>
              {this.state.openPrayers ? <ExpandLess data-cy="expand-secprayer" /> : <ExpandMore data-cy="expand-secprayer" />}
            </ListItem>
            <Collapse in={this.state.openSubMenu} timeout="auto" unmountOnExit>
              <div key={index} className={classes.scroll}>
                <ListItem data-cysublev="subdrawerlink" button selected={true} classes={{ selected: classes.selected }}>
                  <SecPrayerDrawer secondLevel={prayer.terms} firstLevel={prayer.codename} class={classes.list} />
                </ListItem>
              </div>
            </Collapse>
          </div>
        );
      }
    });
    return (
      <div>
        {this.state.error ? (
          <div>
            An error has occurred while loading. Our team has been notified and is working to fix it. Please check back later.
          </div>
        ) : (
          <div>{this.state.loading ? <div>Loading...</div> : <List>{navLinks}</List>}</div>
        )}
      </div>
    );
  }
}

PrayerDrawer.propTypes = {
  details: PropTypes.shape({
    class: PropTypes.string,
    prayerTaxonomy: PropTypes.array,
  }),
  classes: PropTypes.object.isRequired,
};

export default withContext(withStyles(styles, { withTheme: true })(PrayerDrawer));
