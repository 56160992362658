import React from 'react';
import { NavLink } from 'react-router-dom';

const ThemeMainList = (props) => {
  return (
    <div data-cy="newslinkdisplay">
      <h2 className="capitalize">{props.themetitle}</h2>
      <ul className="plain-list">
        {Object.keys(props.newsdata).map((i, key) => {
          return (
            <li key={key}>
              <NavLink
                data-cy={`articlelink${key}`}
                to={{
                  pathname: `/news/${props.articletype}/${props.newsdata[i].url_slug.value}`,
                }}
              >
                {props.newsdata[i].base__title.value}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ThemeMainList;
