import React from 'react';
import { Row, Col } from 'react-flexbox-grid';

const darkBox = {
  width: '50%',
  padding: '10px',
  marginBottom: '15px',
  background: '#01262C',
};

const lightText = { color: '#FCFCFC', padding: '20px', margin: '10px 0px' };
const spacing = { padding: '20px', margin: '10px 0px' };

function Styles() {
  return (
    <div>
      <h1>Style Guide</h1>
      <p>
        Our styles are based on the design of the public website. You can view their style guide here:{' '}
        <a href="https://www.bahai.us/components/">https://www.bahai.us/components/</a>
      </p>
      <hr />
      <h3>Logo</h3>
      <Row style={darkBox}>
        <Col sm={2}>
          <span className="starlogo" />
        </Col>
        <Col sm={10} className="inline-vertical-center">
          <h2 className="logo-text">Bahá’ís of the United States</h2>
        </Col>
      </Row>
      <p>Star: .starlogo</p>
      <p>Text: h2, .logo-text</p>
      <hr />
      <h3>Colors</h3>
      <div style={{ background: '#FCFCFC' }}>
        <p style={spacing}>White #FCFCFC main background</p>
      </div>
      <div style={{ background: '#fcf6eb' }}>
        <p style={spacing}>Tan #FCF6EB</p>
      </div>
      <div style={{ background: '#54676B' }}>
        <p style={lightText}>Grey #54676B</p>
      </div>
      <div style={{ background: '#01252b' }}>
        <p style={lightText}>Black #01252B</p>
      </div>
      <div style={{ background: '#463d2f' }}>
        <p style={lightText}>Brown #463D2F main text</p>
      </div>
      <div style={{ background: '#990033' }}>
        <p style={lightText}>Red #980033</p>
      </div>
      <div style={{ background: '#01262C' }}>
        <p style={lightText}>Teal #01262C navbar, footer, and links</p>
      </div>
      <hr />
      <h3>Typography</h3>
      <h4>Playfair Display, headings</h4>
      <br />
      <h5>Antonio, h5, accents</h5>
      <br />
      <h3>Open Sans, body text</h3>
      <br />
      <h1>Heading 1, 2.5 rem</h1>
      <h2>Heading 2, 2 rem</h2>
      <h3>Heading 3, 1.5 rem</h3>
      <h4>Heading 4, 1.2 rem</h4>
      <h5>Heading 5, 1.15 rem, all caps</h5>
      <h6>Heading 6, 0.875 rem</h6>
      <p>paragraph, 0.95rem</p>
      <p>base font size is 18px</p>
      <a href="/styles">link, size varies</a>
      <blockquote>blockquote, Playfair Display, black, 1.7 rem, italicized</blockquote>
      <hr />
      <h3>Screen Sizing</h3>
      <p>for styles that vary based on screen size use the media queries at the bottom of app.sass</p>
      <p>phone: max-width 640px</p>
      <p>tablet-portrait: min-width 750px</p>
      <p>tablet-landscape: min width 1080px</p>
      <p>desktop: min-width 1200px</p>
      <p>big-desktop: min width 1800px</p>
      <hr />
      <h3>General Page Layout</h3>
      <h1>Page Heading, h1</h1>
      <h3>Subheading, h3</h3>
      <h4 className="sidebar-headers">Sidebar Header, h4 .sidebar-headers</h4>
      <p>body text, p</p>
      <ul>
        <li>this is a regular list</li>
        <li>no extra styling needed on your ul for this list type</li>
      </ul>
      <ul className="plain-list">
        <li>this is a plain list</li>
        <li>use the class &quot;plain-list&quot; on your ul to achieve this style</li>
      </ul>
      <p>
        <a className="primary-button" href="/styles">
          button
        </a>{' '}
        .primary-button
      </p>
      <p>
        <a className="secondary-button" href="/styles">
          button
        </a>{' '}
        .secondary-button
      </p>
      <div className="bounding-box">
        <p>.bounding-box</p>
      </div>
      <Row>
        <Col sm={12} md={8}>
          <div>
            <h3>This is a main content section, h3</h3>
            <p>Col sm=&#123;12&#125; md=&#123;8&#125;</p>
          </div>
        </Col>
        <Col sm={12} md={4}>
          <div className="home-box">
            <h3>This is a side bar section, h3</h3>
            <p>Col sm=&#123;12&#125; md=&#123;4&#125;</p>
            <p>.home-box</p>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Styles;
