import React, { useState } from 'react';
import { Collapse, ListItem } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

export default function (props: { rowData: any }) {
  const { rowData } = props;
  const [isVisible, setIsVisible] = useState(false);
  return (
    <>
      <ListItem data-cy="expansion-list-item" style={{ width: 'inherit' }} onClick={() => setIsVisible(!isVisible)}>
        {isVisible ? <ExpandLess tabIndex={0} /> : <ExpandMore tabIndex={0} />}
        {!isVisible ? <>Open</> : <>Close</>}
      </ListItem>

      {!isVisible ? (
        <ul className="plain-list">
          {Object.values(rowData.areaClusters)
            .filter((a, b) => b < 3)
            .map((option: any, index) => (
              <li key={index}>{option.key + ' ' + option.value}</li>
            ))}
        </ul>
      ) : null}
      <Collapse data-cy="expansion-collapse-item" in={isVisible}>
        <ul className="plain-list">
          {Object.values(rowData.areaClusters).map((option: any, index) => (
            <li key={index}>{option.key + ' ' + option.value}</li>
          ))}
        </ul>
      </Collapse>
    </>
  );
}
