import React from 'react';
import { Link } from 'react-router-dom';

function CallToAction() {
  return (
    <div>
      <h1>National Spiritual Assembly Call to Action</h1>
      <p>
        We are pleased to direct your attention to a series of special messages from the National Spiritual Assembly—first, a Call
        to Action addressed to the whole community, then further letters drawing upon themes in the original letter and exploring
        their implications for Regional Bahá’í Councils and Regional Training Institutes, and for Local Spiritual Assemblies. The
        Assembly trusts that the friends will revisit these letters throughout the One Year Plan. Further translations of the
        letters will be posted as they become available. A special Call to Action video presentation features a reading of the
        June 3 message to the community:{' '}
        <a target="_blank" rel="noopener noreferrer" href="https://link.bahai.us/June2021">
          National Spiritual Assembly Call to Action Video
        </a>
      </p>
      <p>
        A library of guidance from the National Spiritual Assembly, in chronological order, is available on{' '}
        <Link to="/guidance/national_spiritual_assembly">this page</Link>.
      </p>
      <br />
      <ul>
        <li>
          <Link to="/guidance/national_spiritual_assembly/national-spiritual-assembly-call-to-action-to-all-lsas-2021">
            August 2, 2021 - National Spiritual Assembly Call to Action to All LSAs
          </Link>
        </li>
        <li>
          <Link to="/guidance/national_spiritual_assembly/national-spiritual-assembly-call-to-action-to-regional-councils-and-training-institutes-2021">
            June 24, 2021 - National Spiritual Assembly Call to Action to Regional Councils and Training Institutes
          </Link>
        </li>
        <li>
          <Link to="/guidance/national_spiritual_assembly/national-spiritual-assembly-call-to-action-2021">
            June 3, 2021 - National Spiritual Assembly Call to Action
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default CallToAction;
