import React from 'react';
import { Row, Col } from 'react-flexbox-grid';

const EmergencyContactComp = ({ details }) => {
  const className = details.mailingAddressZipCode
    ? 'profile-background'
    : details.mailingAddressType == 'International'
    ? 'profile-background negative-bottom-margin'
    : 'medium-top-padding';

  return (
    <div className={className}>
      <h4>Emergency Contact Information</h4>
      <Row>
        <Col sm={6}>
          <p className="faded-text small-text">Name</p>
          <p>
            <strong>{details.emergencyContactName}</strong>
          </p>
        </Col>
        <Col sm={6}>
          <p className="faded-text small-text">Relationship</p>
          <p>
            <strong>{details.emergencyContactRelationship}</strong>
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <p className="faded-text small-text">Email</p>
          <p>
            <strong>{details.emergencyContactEmail}</strong>
          </p>
        </Col>
        <Col sm={6}>
          <p className="faded-text small-text">Phone</p>
          <p>
            <strong>{details.emergencyContactPhone}</strong>
          </p>
        </Col>
      </Row>
    </div>
  );
};
export default EmergencyContactComp;
