import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';
import { AxiosResponse } from 'axios';

/**
 * HealthCheck of Auth Api
 * @return {Promise<string>} A promise to the health check response.
 */
export default function healthCheck() {
  return GlobalAPICall.get(`${process.env.REACT_APP_AUTH_API_URL}/api/HealthCheck`)
    .then((res: AxiosResponse<string>) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject({
        message: 'Our services are currently unavailable. Please come back later.',
        status: err.response?.status ?? err,
      });
    });
}
