import { ServiceOpportunitiesFlat } from './ServiceOpportunities/Model/service_opportunities_flat';
import { Col, Row } from 'react-flexbox-grid';
import AutoDownload from '../Common-Components/AutoDownload';
import React from 'react';

export interface DisplayResourcesListProps {
  dataArray: ServiceOpportunitiesFlat[];
  type: 'Position' | 'Job';
}
export default function DisplayResourcesList(props: DisplayResourcesListProps) {
  return (
    <>
      {props.dataArray.map((job: ServiceOpportunitiesFlat, key) => (
        <div key={key} data-cy={`job_${key}`}>
          <Row>
            <Col sm={4}>
              <h3 data-cy="job-title">
                {job.base__title.value}
                {job.isSummerPosition && <div>(Summer)</div>}
              </h3>
              <h6 data-cy={`job-location_${key}`}>{job.department.value}</h6>
            </Col>
            <Col sm={8}>
              <div data-cy="job-summary">{job.base__body.value}</div>
              {job.document_download.value.length > 0 ? (
                <AutoDownload
                  url={job.document_download.value[0].url}
                  fileName={job.document_download.value[0].name}
                  downloadText={`Download Full ${props.type} Description (PDF)`}
                />
              ) : null}
            </Col>
          </Row>
          <hr />
        </div>
      ))}
    </>
  );
}
