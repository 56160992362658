import { map } from 'rxjs/operators';

export default class AbmDirectoryEndpoint {
  constructor(authService, apiCaller, apiUrl) {
    this.authService = authService;
    this.apiCaller = apiCaller;
    this.apiUrl = apiUrl;
    this.getabmdirectory = this.getabmdirectory.bind(this);
  }
  getabmdirectory(token) {
    const observable = this.apiCaller
      .getObservable(process.env.REACT_APP_API_URL + '/api/AbmDirectory', {
        headers: { Authorization: 'bearer ' + token },
      })
      .pipe(
        map((x) => {
          return x.data;
        })
      );
    return observable;
  }
}
