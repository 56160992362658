import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import pdf from '../assets/img/pdf.png';
import doc from '../assets/img/doc.png';
import AutoDownload from './AutoDownload';

const Downloads = ({ links }) => {
  let loadcontent;
  let picicon;
  if (links.type === 'application/pdf') {
    picicon = pdf;
  } else {
    picicon = doc;
  }
  //If the description is empty (null or "") in kentico, it will display the file name instead. Otherwise it will display the description
  if (links.description === null || links.description === '') {
    loadcontent = <AutoDownload url={links.url} fileName={links.name} downloadText={links.name} />;
  } else {
    loadcontent = <AutoDownload url={links.url} fileName={links.name} downloadText={links.description} />;
  }
  return (
    <Row className="attachments">
      <Col sm={2}>
        <img src={picicon} alt="document icon" />
      </Col>
      <Col sm={10}>{loadcontent}</Col>
    </Row>
  );
};

Downloads.propTypes = {
  links: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default Downloads;
