import useCustomHookForm from "../../Hooks/forms/use-custom-hook-form";
import { CustomFieldFormType } from "../../Hooks/forms/enums/custom-field-form-type";
import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import theme from "../../styles/theme";
import { UsStates } from "./us-states";
import { CustomField } from "../../Hooks/forms/type/custom-field";
import { name } from "../../Utilities/GeneralRegEx";
import { TextMaskZipCodeUtil } from "./TextMaskZipCodeUtil";

export enum AddressRadio {
  Individual = 'Individual',
  Household = 'Household',
  Nothing = ''
}
export interface AddressResponseForm {
  addressCurrentInCareOf: string;
  addressCurrentLine1: string;
  addressCurrentLine2: string;
  addressCurrentCity: string;
  addressCurrentState: string;
  addressCurrentZipCode: string;
  addressCurrentRadio: AddressRadio;
  isMailingAddressDifferent: boolean;
  addressMailingInCareOf?: string;
  addressMailingLine1?: string;
  addressMailingLine2?: string;
  addressMailingCity?: string;
  addressMailingState?: string;
  addressMailingZipCode?: string;
  addressMailingRadio?: AddressRadio;
}
export interface AddressResponseWithoutMailingAddressForm {
  addressCurrentInCareOf: string;
  addressCurrentLine1: string;
  addressCurrentLine2: string;
  addressCurrentCity: string;
  addressCurrentState: string;
  addressCurrentZipCode: string;
  addressCurrentRadio: AddressRadio;
  isMailingAddressDifferent: boolean;
}
export interface AddressResponseWithMailingAddressForm {
  addressMailingInCareOf: string;
  addressMailingLine1: string;
  addressMailingLine2: string;
  addressMailingCity: string;
  addressMailingState: string;
  addressMailingZipCode: string;
  addressMailingRadio: AddressRadio;
}

export interface AddressProps {
  callbackAddress: (response: AddressResponseForm) => void
}

export const AddressFieldsArray: CustomField<any>[] = [
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'currentAddressSection',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <h3 className="text-left">Address Information</h3>,
    cssClassName: 'col-lg-12'
  },
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'currentAddressSubSection',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <h4 className="text-left">Current Address Information</h4>,
    cssClassName: 'col-lg-12'
  },
  {
    type: CustomFieldFormType.Text,
    formControlName: 'addressCurrentInCareOf',
    label: 'In care of',
    placeholder: 'Ex: John Doe',
    help: '',
    validators: {
      pattern: {
        pattern: name,
        message: 'In care of should be valid'
      },
    },
    cssClassName: 'col-lg-4 p-2'
  },
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'addressCurrentInCareOfSpace',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <></>,
    cssClassName: 'col-lg-8'
  },
  {
    type: CustomFieldFormType.Text,
    formControlName: 'addressCurrentLine1',
    label: 'Line 1',
    placeholder: 'Ex: Address 1',
    validators: {
      required: 'Address Line 1 is required'
    },
    help: '',
    cssClassName: 'col-lg-8 p-2'
  },
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'addressCurrentLine1Space',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <></>,
    cssClassName: 'col-lg-4'
  },
  {
    type: CustomFieldFormType.Text,
    formControlName: 'addressCurrentLine2',
    label: 'Line 2',
    placeholder: 'Ex: Address 2',
    help: '',
    cssClassName: 'col-lg-8 p-2'
  },
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'addressCurrentLine2Space',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <></>,
    cssClassName: 'col-lg-4'
  },
  {
    type: CustomFieldFormType.Text,
    formControlName: 'addressCurrentCity',
    label: 'City',
    placeholder: 'Ex: City',
    validators: {
      required: 'City is required'
    },
    help: '',
    cssClassName: 'col-lg-4 p-2'
  },
  {
    type: CustomFieldFormType.Select,
    formControlName: 'addressCurrentState',
    label: 'State',
    placeholder: 'Ex: Florida',
    validators: {
      required: 'State is required'
    },
    help: '',
    select: {
      options: UsStates(true),
      multiple: false
    },
    cssClassName: 'col-lg-4 p-2'
  },
  {
    type: CustomFieldFormType.Mask,
    formControlName: 'addressCurrentZipCode',
    label: 'ZIP Code',
    placeholder: 'Ex: 20888',
    help: '',
    validators: {
      required: 'ZIP Code is required',
      pattern: {
        pattern: /^[0-9]{5}(?:-[0-9]{4})?$/,
        message: 'Not valid ZIP code'
      }
    },
    cssClassName: 'col-lg-4 p-2',
    inputComponent: TextMaskZipCodeUtil as any,
  },
  {
    type: CustomFieldFormType.Radio,
    formControlName: 'addressCurrentRadio',
    label: 'Please indicate the following',
    placeholder: 'Ex: Radio',
    help: '',
    validators: {
      required: 'Household or Individual is required'
    },
    radio: {
      options: [
        {
          value: AddressRadio.Individual,
          label: 'This address applies only to me',
          id: '0'
        },
        {
          value: AddressRadio.Household,
          label: 'This address applies to everyone in my household',
          id: '1'
        },
      ],
    },
    cssClassName: 'col-lg-12 p-2',
  },
  {
    type: CustomFieldFormType.Checkbox,
    formControlName: 'isMailingAddressDifferent',
    label: 'Is mailing address different?',
    placeholder: '',
    help: '',
    cssClassName: 'col-lg-12 p-2',
  },
];

export const MailingAddressDifferentArray: CustomField<any>[] = [
  {
    type: CustomFieldFormType.GroupLabel,
    formControlName: 'AddressMailingFieldsArrayGroupLabel',
    label: 'Mailing Address',
    placeholder: '',
    help: '',
    cssClassName: 'col-lg-12'
  },
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'addressMailingAddressSection',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <h4 className="text-left">Mailing Address</h4>,
    cssClassName: 'col-lg-12'
  },
  {
    type: CustomFieldFormType.Text,
    formControlName: 'addressMailingInCareOf',
    label: 'In care of',
    placeholder: 'Ex: John Doe',
    validators: {
      pattern: {
        pattern: name,
        message: 'In care of should be valid'
      },
    },
    help: '',
    cssClassName: 'col-lg-4 p-2',
  },
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'addressMailingInCareOfSpace',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <></>,
    cssClassName: 'col-lg-8'
  },
  {
    type: CustomFieldFormType.Text,
    formControlName: 'addressMailingLine1',
    label: 'Line 1',
    placeholder: 'Ex: Address 1',
    help: '',
    validators: {
      required: 'Address Line 1 is required'
    },
    cssClassName: 'col-lg-4 p-2',
  },
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'addressMailingLine1Space',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <></>,
    cssClassName: 'col-lg-8'
  },
  {
    type: CustomFieldFormType.Text,
    formControlName: 'addressMailingLine2',
    label: 'Line 2',
    placeholder: 'Ex: Address 2',
    help: '',
    cssClassName: 'col-lg-4 p-2',
  },
  {
    type: CustomFieldFormType.JSXElement,
    formControlName: 'addressMailingLine2Space',
    label: '',
    placeholder: '',
    help: '',
    jsxElement: <></>,
    cssClassName: 'col-lg-8'
  },
  {
    type: CustomFieldFormType.Text,
    formControlName: 'addressMailingCity',
    label: 'City',
    placeholder: 'Ex: City',
    validators: {
      required: 'City is required'
    },
    help: '',
    cssClassName: 'col-lg-4 p-2',
  },
  {
    type: CustomFieldFormType.Select,
    formControlName: 'addressMailingState',
    label: 'State',
    placeholder: 'Ex: Florida',
    help: '',
    select: {
      options: UsStates(true),
      multiple: false
    },
    validators: {
      required: 'State is required'
    },
    cssClassName: 'col-lg-4 p-2',
  },
  {
    type: CustomFieldFormType.Mask,
    formControlName: 'addressMailingZipCode',
    label: 'ZIP Code',
    placeholder: 'Ex: 20888',
    help: '',
    validators: {
      required: 'ZIP Code is required',
      pattern: {
        pattern: /^[0-9]{5}(?:-[0-9]{4})?$/,
        message: 'Not valid ZIP code'
      }
    },
    cssClassName: 'col-lg-4 p-2',
    inputComponent: TextMaskZipCodeUtil as any,
  },
  {
    type: CustomFieldFormType.Radio,
    formControlName: 'addressMailingRadio',
    label: 'For mailing address',
    placeholder: 'Ex: Radio',
    help: '',
    radio: {
      options: [
        {
          value: AddressRadio.Individual,
          label: 'This address applies only to me',
          id: '0'
        },
        {
          value: AddressRadio.Household,
          label: 'This address applies to everyone in my household',
          id: '1'
        }
      ],
    },
    cssClassName: 'col-lg-12 p-2',
  },
]

export default function AddressForm(props: AddressProps) {
  const [isVisible, setIsVisible] = useState(false);
  const [fields, useFormObject] = useCustomHookForm<AddressResponseWithoutMailingAddressForm, string | number>({
    customFieldsArray: AddressFieldsArray,
    initialValues: {
      addressCurrentInCareOf: '',
      addressCurrentLine1: '',
      addressCurrentLine2: '',
      addressCurrentCity: '',
      addressCurrentState: '',
      addressCurrentZipCode: '',
      addressCurrentRadio: AddressRadio.Individual,
      isMailingAddressDifferent: false,
    },
  });
  const [fieldsWithMailingAddress, useFormObjectWithMailingAddress] = useCustomHookForm<AddressResponseWithMailingAddressForm, string | number>({
    customFieldsArray: MailingAddressDifferentArray,
    initialValues: {
      addressMailingInCareOf: '',
      addressMailingLine1: '',
      addressMailingLine2: '',
      addressMailingCity: '',
      addressMailingState: '',
      addressMailingZipCode: '',
      addressMailingRadio: AddressRadio.Individual,
    },
  });
  const { handleSubmit, formState: { errors }, watch } = useFormObject;
  const { getValues } = useFormObjectWithMailingAddress;
  const withMailingAddressError = useFormObjectWithMailingAddress.formState.errors;
  const watchIsMailingAddressDifferent = watch('isMailingAddressDifferent' as any);
  useEffect(() => {
    if (watchIsMailingAddressDifferent) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
      useFormObjectWithMailingAddress.reset()
    }
  }, [watchIsMailingAddressDifferent]);
  const onSubmit = (data: AddressResponseWithoutMailingAddressForm) => {
    const dataMailingAddress: AddressResponseWithMailingAddressForm = {
      ...getValues() as any
    };
    if (isVisible) {
      props.callbackAddress({
        ...data,
        ...dataMailingAddress,
      })
    } else {
      props.callbackAddress({
        ...data,
        ...dataMailingAddress,
        addressMailingRadio: undefined,
      })
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit) as never}>
      <div data-cy="neutral-message" className="physicaladdress-box neutral-message wider">
        <p>
          You may <strong>not</strong> enter an address outside of the Bahá’í jurisdiction of the United States. If you are
          moving out of the continental United States, please contact the Membership Office at{' '}
          <a href="mailto:membership@usbnc.org">membership@usbnc.org</a> or <a href="tel:8477333445">(847) 733-3445</a> or
          the Office of Pioneering at <a href="mailto:pioneer@usbnc.org">pioneer@usbnc.org</a>.
        </p>
      </div>
      <div className="row">
        {fields}
      </div>
      {
        isVisible && <div className="row">{fieldsWithMailingAddress}</div>
      }
      <div className="text-center">
        <Button size="small" disabled={Object.keys(errors).length > 0 || (
          isVisible ? Object.keys(withMailingAddressError).length > 0 : false
        )}
          type="submit">
          Continue
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </Button>
      </div>
    </form>
  )
}
