import React from 'react';

const Modal = (props) => {
  const showHideClassName = props.show ? 'modal display-block' : 'display-none';
  return (
    <div style={{ overflowY: 'auto', maxHeight: '100vh' }} data-cy="showModal" className={showHideClassName}>
      <section className="center">
        {props.children}
        <br />
      </section>
    </div>
  );
};

export default Modal;
