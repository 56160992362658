import React, { FunctionComponent, useEffect, useState } from 'react';
import withContext from '../../../ContextAPI/Context_HOC';
import NatConCarouselItem from '../NatConCarouselItem';
import NatConCarouselProxy from '../../Utilities/NatConCarouselProxy';
import Slider, { Settings } from 'react-slick';
import { SlickBackButton, SlickForwardButton } from '../../../Utilities/Slick/buttons';

const NatConGenericCarousel: FunctionComponent<{
  match: { params: { year: string } };
  context: { natConCarouselProxy: NatConCarouselProxy };
}> = ({ match, context: { natConCarouselProxy } }) => {
  const [NatConCarouselImages, setNatConCarouselImages] = useState([] as JSX.Element[]);

  useEffect(() => {
    const NatConCarouselProxyGetNatConCarouselsSubscription = natConCarouselProxy
      .getNatConCarousels(`national_convention_${match.params.year}`)
      .subscribe((x) => {
        const displayImages = x.map((contentItem, index) => <NatConCarouselItem key={index} natConCarousel={contentItem} />);
        setNatConCarouselImages(displayImages);
      });

    return () => NatConCarouselProxyGetNatConCarouselsSubscription.unsubscribe();
  }, [natConCarouselProxy, match.params.year]);
  const sliderSettings: Settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    nextArrow: <SlickForwardButton />,
    prevArrow: <SlickBackButton />,
  };
  return (
    <div className="carousel-container natcon-carousel">
      <Slider {...sliderSettings}>{NatConCarouselImages}</Slider>
    </div>
  );
};
export default withContext(NatConGenericCarousel);
