import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';

export default function address(token, id, isPhysicalAddress) {
  return GlobalAPICall.get(process.env.REACT_APP_API_URL + `/api/address`, {
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      var address;
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].isPhysicalAddress === isPhysicalAddress) {
          address = res.data[i];
          break;
        } else address = '';
      }
      return Promise.resolve(address);
    })
    .catch((err) => {
      if (err.response.status === 404 || err.response.status === 400) {
        return Promise.reject({
          status: err.response.status,
          message: 'Record not found',
        });
      } else {
        return Promise.reject({
          status: err.response.status,
          message: 'Service is currently unavailable. Please come back later.',
        });
      }
    });
}
