import React from 'react';
import { ListItem, ListItemText, IconButton, Paper, Popover, ClickAwayListener, List, Tooltip } from '@material-ui/core';
import { Apps } from '@material-ui/icons';
import withContext from '../../ContextAPI/Context_HOC';
import { withRouter } from 'react-router-dom';
import ProfileFunction from '../../Profiles/Utilities/ProfileFunction';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FeatureFlagToggleAll from '../../FeatureFlag/FeatureFlagToggleAll';
import { OptimizelyFeature } from '@optimizely/react-sdk';

class AdminTools extends React.Component {
  constructor(props) {
    super(props);
    this.profilefn = new ProfileFunction();
    this.auth = this.props.context.authService;
    this.contentProviderEnvironment = this.props.context.contentProviderEnvironment;

    this.state = {
      openTools: false,
      decodedtoken: this.auth.getDecodedToken(),
      jwt: this.auth.getToken(),
      displayDraftContentSwitch: this.contentProviderEnvironment.isPreviewApi(),
    };
  }

  handledisplayDraftContentSwitch = () => {
    const newValue = !this.state.displayDraftContentSwitch;
    if (newValue) {
      this.contentProviderEnvironment.setPreviewApi();
    } else {
      this.contentProviderEnvironment.setDeliveryApi();
    }

    this.setState({
      displayDraftContentSwitch: this.contentProviderEnvironment.isPreviewApi(),
    });
  };

  handleToolClick = (event) => {
    this.setState({ toolAnchorEl: event.currentTarget });
  };
  handleToolClose = () => {
    this.setState({ toolAnchorEl: null });
  };

  componentDidUpdate() {
    if (!this.state.decodedtoken) {
      const receivedDecodedToken = this.auth.getDecodedToken();
      const jwt = this.auth.getToken();
      if (receivedDecodedToken) {
        this.setState({ decodedtoken: receivedDecodedToken, jwt: jwt });
      }
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleToolClose);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleToolClose);
  }

  render() {
    const { toolAnchorEl } = this.state;
    const toolOpen = Boolean(toolAnchorEl);
    const isEmember = this.profilefn.iseMem(this.state.decodedtoken);
    const isUnityWeb = this.profilefn.isUWeb(this.state.decodedtoken);
    const isSrsUser = this.profilefn.isSrsUser(this.state.decodedtoken);
    const isTreasurer = this.profilefn.isPersonTreasurer(this.state.decodedtoken);
    const isLSAMember = this.profilefn.isPersonLSAMember(this.state.decodedtoken);
    const allowContentPreview = this.profilefn.allowContentPreview(this.state.decodedtoken);
    const allowForcingFeatureFlag = this.profilefn.allowForcingFeatureFlag(this.state.decodedtoken);
    if (!isEmember && !isUnityWeb && !isSrsUser && !isTreasurer) {
      return null;
    }
    const srsUrl = isSrsUser ? process.env.REACT_APP_SRS_URL : undefined;
    const linkToRedirectToEmembership = this.props.history.createHref({
      pathname: '/SingleSignOnRedirect/EMembership',
    });
    const linkToRedirectToUnityWeb = this.props.history.createHref({
      pathname: '/SingleSignOnRedirect/UnityWeb',
    });
    const linkToRedirectToOBS = this.props.history.createHref({
      pathname: '/SingleSignOnRedirect/OBS',
    });
    const linkToRedirectToLSAAudit = this.props.history.createHref({
      pathname: '/SingleSignOnRedirect/lsa-audit',
    });

    return (
      <div className="navbarItem pointer-finger flex-item small-top-padding">
        <Tooltip title="Admin Tools" aria-label="admin tools" role="menu">
          <div onClick={this.handleToolClick}>
            <IconButton
              data-cy="admintools"
              aria-owns={toolOpen ? 'tool-popper' : undefined}
              aria-haspopup="true"
              color="inherit"
              className="navbarIcon"
            >
              <Apps />
            </IconButton>
            <p className="white-text xs-text">TOOLS</p>
          </div>
        </Tooltip>
        <Popover
          id="tool-popper"
          open={toolOpen}
          anchorEl={toolAnchorEl}
          onClose={this.handleToolClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Paper data-cy="regulartoolbar">
            <ClickAwayListener onClickAway={this.handleToolClose}>
              <List>
                {isEmember && (
                  <ListItem
                    button
                    component="a"
                    rel="noopener noreferrer"
                    onClick={() => window.open(linkToRedirectToEmembership)}
                  >
                    <ListItemText primary="eMembership" />
                  </ListItem>
                )}
                <OptimizelyFeature feature="obs_single_sign_on">
                  {(isEnabled) =>
                    isEnabled ? (
                      <ListItem button component="a" rel="noopener noreferrer" onClick={() => window.open(linkToRedirectToOBS)}>
                        <ListItemText primary="Online Balloting System (OBS)" />
                      </ListItem>
                    ) : (
                      <ListItem
                        button
                        component="a"
                        rel="noopener noreferrer"
                        onClick={() => window.open('https://obsbahai.org/')}
                      >
                        <ListItemText primary="Online Balloting System (OBS)" />
                      </ListItem>
                    )
                  }
                </OptimizelyFeature>
                {isUnityWeb && (
                  <div>
                    <ListItem
                      button
                      component="a"
                      rel="noopener noreferrer"
                      onClick={() => window.open(linkToRedirectToUnityWeb)}
                    >
                      <ListItemText primary="Unity Web" />
                    </ListItem>
                    <ListItem button component="a" rel="noopener noreferrer" target="_blank" href="https://ocs.bahai.us/ocs/">
                      <ListItemText primary="Online Contribution System (OCS)" />
                    </ListItem>
                    <ListItem button component="a" rel="noopener noreferrer" target="_blank" href="https://ocs.bahai.us/hps">
                      <ListItemText primary="Ḥuqúqu’lláh Payment System (ḤPS)" />
                    </ListItem>
                  </div>
                )}
                {srsUrl && (
                  <ListItem button component="a" rel="noopener noreferrer" target="_blank" href={srsUrl}>
                    <ListItemText primary="Seeker Response System" />
                  </ListItem>
                )}
                {isTreasurer && (
                  <span>
                    <hr />
                    <h4 className="medium-left-padding">Treasurer</h4>
                    <ListItem
                      button
                      component="a"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://bahaitreasurer.us/feast-resourses"
                    >
                      <ListItemText primary="Bahá’í Treasurers Bulletin" />
                    </ListItem>
                    <ListItem button component="a" rel="noopener noreferrer" target="_blank" href="https://bahaitreasurer.us/">
                      <ListItemText primary="Treasurer’s Tools page" />
                    </ListItem>
                  </span>
                )}
                {isLSAMember && (
                  <ListItem button component="a" rel="noopener noreferrer" onClick={() => window.open(linkToRedirectToLSAAudit)}>
                    <ListItemText primary="Online Audit System (OAS)" />
                  </ListItem>
                )}
                {allowContentPreview && (
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.displayDraftContentSwitch}
                          onChange={this.handledisplayDraftContentSwitch}
                          name="displayDraftContent"
                          color="primary"
                        />
                      }
                      label="Display Draft Content"
                    />
                  </FormGroup>
                )}
                {allowForcingFeatureFlag && <FeatureFlagToggleAll />}
              </List>
            </ClickAwayListener>
          </Paper>
        </Popover>
      </div>
    );
  }
}

export default withRouter(withContext(AdminTools));
