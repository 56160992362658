import React, { useState, useEffect } from 'react';
import withContext from '../ContextAPI/Context_HOC';
import ResidentialAddressComp from './ResidentialAddressComp';
import MailingAddressComp from './MailingAddressComp';
import MyProfileComp from './MyProfileComp';
import EmergencyContactComp from './EmergencyContactComp';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';
import MembershipNote from './MembershipNote';

const OverseasProfile = (props) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [profileData, setProfileData] = useState([]);
  const setPageTitle = props.setPageTitle;
  useEffect(() => setPageTitle('Contact Information'), [setPageTitle]);
  const myProfileProxy = props.context.myProfileProxy;
  useEffect(() => {
    const observable = myProfileProxy.get();
    const subscription = observable.subscribe({
      next: function (res) {
        setProfileData(res);
      },
      error: () => setErrorMessage(<ErrorMessageToUser />),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [myProfileProxy, setErrorMessage]);

  const isMailingAddressDifferent =
    (profileData.mailingAddressCareOf !== null ||
      profileData.mailingAddressLine1 !== null ||
      profileData.mailingAddressLine2 !== null ||
      profileData.mailingAddressLine3 !== null ||
      profileData.mailingAddressLine4 !== null ||
      profileData.mailingAddressLine5 !== null ||
      profileData.mailingAddressCountry !== null) &&
    profileData.mailingAddressId !== profileData.physicalAddressId
      ? true
      : false;

  const details = { ...profileData, isMailingAddressDifferent };

  return (
    <div>
      {errorMessage ?? (
        <span>
          <MembershipNote />
          <div className="profile-box">
            <MyProfileComp details={profileData} />
            <ResidentialAddressComp details={details} />
            {isMailingAddressDifferent || profileData.institutionalMailingAddress ? (
              <MailingAddressComp details={profileData} />
            ) : null}
            <EmergencyContactComp details={details} />
          </div>
        </span>
      )}
    </div>
  );
};

export default withContext(OverseasProfile);
