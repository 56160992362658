import addressvalidation from './AddressValidation';
import GlobalAPICall from '../../GlobalComponents/GlobalAPICall';

export default function putphysicaladdress(token, data) {
  const addressvalidationdata = addressvalidation(data);
  if (addressvalidationdata !== false) {
    return addressvalidationdata;
  }
  return GlobalAPICall.put(process.env.REACT_APP_API_URL + '/api/address', data, {
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject({
        status: err.response.status,
        message: 'Our services are currently unavailable. Please come back later.',
      });
    });
}
