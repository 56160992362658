import React, { FunctionComponent, useState, useEffect, useRef, FormEvent } from 'react';
import AuthService from '../Login/AuthService';
import healthCheck from './Utilities/HealthCheckEndpoint';
import withContext from '../ContextAPI/Context_HOC';
import PasswordViewControl from '../Utilities/PasswordViewControl';
import { NavLink, withRouter } from 'react-router-dom';
import { withStyles, CircularProgress, TextField, OutlinedInput, InputLabel, FormControl } from '@material-ui/core';
import ErrorMessageToUser from '../Error/ErrorMessageToUser';
import MaintenanceMessageToUser from '../Error/MaintenanceMessageToUser';
import { History, Location } from 'history';
import GlobalAPICall from '../GlobalComponents/GlobalAPICall';
import SafeStringify from '../Utilities/SafeStringify';
import { from } from 'rxjs';

const styles = () => ({
  '@global': {
    '#root': {
      background: 'radial-gradient(ellipse at center 32%, #0a5b55, #01262C)',
    },
    '#content': {
      background: 'transparent',
    },
  },
});
type onSubmitEvent = FormEvent<HTMLFormElement>;
const Login: FunctionComponent<{
  context: {
    authService: AuthService;
    closeDrawerMenu: () => void;
    redirectUrl: Location;
    setredirectUrl: (newValue: { pathname: string }) => void;
    setCreateBosErrorDisplayMessage: (message: string) => void;
    setCreateBosPrefillBahaiId: (username: string) => void;
  };
  history: History;
}> = ({
  context: {
    authService,
    closeDrawerMenu,
    redirectUrl,
    setredirectUrl,
    setCreateBosErrorDisplayMessage,
    setCreateBosPrefillBahaiId,
  },
  history,
}) => {
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [showCircularProgress, setShowCircularProgress] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [showMaintenance, setShowMaintenance] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const mounted = useRef<boolean>(true);

  useEffect(() => {
    const appTokenPromise = healthCheck();
    const appTokenObservable = from(appTokenPromise);
    const subscription = appTokenObservable.subscribe({
      next: () => setShowCircularProgress(false),
      error: () => {
        setShowMaintenance(true);
        setShowCircularProgress(false);
      },
    });

    return () => {
      subscription.unsubscribe();
      mounted.current = false;
    };
  }, []);

  const signIn = (e: onSubmitEvent) => {
    e.preventDefault();
    setShowCircularProgress(true);
    const usernameRefCurrent = usernameRef.current;
    const passwordRefCurrent = passwordRef.current;

    const username = usernameRefCurrent?.value ?? '';
    const password = passwordRefCurrent?.value ?? '';

    authService
      .login(username, password)
      .then(() => {
        closeDrawerMenu();

        if (redirectUrl.pathname !== '') {
          history.replace(redirectUrl);
        } else {
          history.replace('/');
        }

        setredirectUrl({ pathname: '' });
      })
      .catch((err) => {
        const isCancel = GlobalAPICall.isCancel(err);
        if (isCancel) {
          return;
        }
        if (typeof err === 'string') {
          if (err === 'Account needs to be created.') {
            setCreateBosErrorDisplayMessage(
              'To increase security, this website now requires that every user create a Bahá’í Online Services Account'
            );
            setCreateBosPrefillBahaiId(username);
            history.push('/createboswizard/step1');
          } else {
            setMessage(err);
          }
        } else {
          if (err.message) {
            const stringifiedMessage = err.message ? SafeStringify(err.message) : null;
            setMessage(stringifiedMessage);
          } else {
            setErrorOccurred(true);
          }
        }
      })
      .finally(() => {
        if (mounted.current) {
          setShowCircularProgress(false);
          setShowPassword(false);
        }
        if (passwordRef.current) {
          passwordRef.current.value = '';
        }
      });
  };

  if (showMaintenance) {
    return <MaintenanceMessageToUser />;
  }

  return (
    <div className="center">
      <form data-cy="mainform" onSubmit={signIn}>
        {errorOccurred ? <ErrorMessageToUser /> : null}
        <div data-cy="logo" className="starlogo star-margin" />
        <h2 className="logo-text">Bahá’ís of the United States</h2>
        <div className="center form-style top-margin">
          <h3>Sign in to Bahai.us</h3>
          <p className="small-text">Bahá’í Online Services Account Required</p>
          {message ? <p className="error-message">{message}</p> : null}
          <TextField
            inputRef={usernameRef}
            inputProps={{ 'data-cy': 'username' }}
            id="username"
            name="username"
            label="US Bahá’í ID Number"
            aria-label="US Bahá’í ID Number"
            type="text"
            margin="normal"
            fullWidth={true}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              inputRef={passwordRef}
              type={showPassword ? 'text' : 'password'}
              name="password"
              data-cy="passwordField"
              endAdornment={
                <PasswordViewControl
                  showPassword={showPassword}
                  setShowPassword={(newState) => setShowPassword(newState)}
                  datacy="passwordEye"
                />
              }
              labelWidth={70}
            />
          </FormControl>
          <span className="center">
            {showCircularProgress ? (
              <div className="small-top-padding">
                <CircularProgress data-cy="login-circular-progress" className="loading-animation" size={40} />
              </div>
            ) : (
              <input data-cy="Login" type="submit" value="Sign In" className="primary-button small-top-margin" />
            )}
            <p className="small-top-margin">
              <NavLink data-cy="forgot" to="/forgot">
                Forgot Your Password?
              </NavLink>
            </p>
            <p>
              <a
                href="https://concourse.atlassian.net/servicedesk/customer/portal/11/group/40/create/181"
                target="_blank"
                rel="noopener noreferrer"
              >
                Need help logging in?
              </a>
            </p>
          </span>
        </div>
        <div className="faded-background-small">
          <p>
            New to Bahai.us?{' '}
            <NavLink data-cy="createbos" to="/createboswizard/step1">
              Create a Bahá’í Online Services Account (BOSA)
            </NavLink>
          </p>
        </div>
      </form>
    </div>
  );
};

export default withRouter(withContext(withStyles(styles, { withTheme: true })(Login)));
