export default class ProfileFunction {
  getprofileinfo(decodedtoken) {
    return {
      blc: decodedtoken.blc,
      cluster: decodedtoken.cluster,
      eucode: decodedtoken.eucode,
      bid: decodedtoken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
      name: decodedtoken.fname ? decodedtoken.fname + ' ' + decodedtoken.lname : undefined,
      region: decodedtoken.region,
      email: decodedtoken.email,
      entityName: decodedtoken.entityname,
    };
  }
  // National Convention Delegate
  isPersonDelegate(decodedtoken) {
    if (decodedtoken != null) {
      return decodedtoken.HasDelegateAccess === 'True';
    }
  }
  // eMem user
  iseMem(decodedtoken) {
    if (decodedtoken != null) {
      return decodedtoken.HasEmembershipAccess === 'True';
    }
  }
  isUWeb(decodedtoken) {
    if (decodedtoken != null) {
      return decodedtoken.HasUWebAccess === 'True';
    }
  }
  // Treasurer Role
  isPersonTreasurer = (decodedtoken) =>
    decodedtoken && decodedtoken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
      ? decodedtoken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'].includes('Treasurer')
      : false;

  // LSA Member Role
  isPersonLSAMember = (decodedtoken) =>
    decodedtoken && decodedtoken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
      ? decodedtoken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'].includes('Member')
      : false;

  // System Admin
  isAdmin(decodedtoken) {
    if (decodedtoken != null) {
      return decodedtoken.HasAdminAccess === 'True';
    }
  }
  isSrsUser(decodedtoken) {
    if (decodedtoken != null) {
      return decodedtoken.HasSRSAccess === 'True';
    }
  }

  /**
   * Allow content preview. True if administrator or has Web Preview Content role.
   * @param {*} decodedtoken
   * @returns
   */
  allowContentPreview = (decodedtoken) => {
    if (!decodedtoken) {
      return false;
    }

    if (decodedtoken?.HasAdminAccess === 'True') {
      return true;
    }

    return decodedtoken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'].includes('Web Preview Content');
  };

  /**
   * Allow forcing feature flags
   * @param {*} decodedtoken
   * @returns
   */
  allowForcingFeatureFlag = (decodedtoken) => {
    if (!decodedtoken) {
      return false;
    }

    if (decodedtoken?.HasAdminAccess === 'True') {
      return true;
    }

    return decodedtoken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'].includes('Force Feature Flag');
  };
}
