import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

const NatConList: FunctionComponent = () => {
  const natConYears = [2024, 2023, 2022];

  return (
    <div>
      <h4>Recent National Conventions</h4>
      {natConYears.map(function (val, i) {
        return (
          <div key={i}>
            <Link to={`national-conventions/${val}`}>{`National Convention ${val}`}</Link>
          </div>
        );
      })}
      <h4 className="medium-top-margin">National Convention Coverage Archives</h4>
      <ul className="plain-list">
        <li>
          <Link to="/resources/natcon-2019">National Convention 2019</Link>
        </li>
        <li>
          <Link to="/resources/natcon-2018">National Convention 2018</Link>
        </li>
        <li>
          <Link to="/resources/natcon-2017">National Convention 2017</Link>
        </li>
        <li>
          <Link to="/resources/natcon-2016">National Convention 2016</Link>
        </li>
      </ul>
    </div>
  );
};
export default NatConList;
