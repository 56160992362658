import React from 'react';
import { ListItem, ListItemIcon, ListItemText, Collapse, IconButton, List, Divider, Drawer } from '@material-ui/core';
import {
  LibraryBooks,
  Public,
  Folder,
  ImportContacts,
  ChevronLeft,
  ChevronRight,
  HowToVote,
  Help,
  ExpandLess,
  ExpandMore,
  InsertDriveFile,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withContext from '../../ContextAPI/Context_HOC';
import { withRouter, Link } from 'react-router-dom';
import HuquqDrawer from '../HuquqDrawer';
import PrayerDrawer from '../PrayerDrawer';
import MessageDrawer from '../MessageDrawer';
import FaqDrawer from '../FaqDrawer';
import GlsaDrawer from '../GlsaDrawer';
import NewsDrawer from '../NewsDrawer';
import ErrorBoundary from '../../Error/ErrorBoundary';
import ProfileFunction from '../../Profiles/Utilities/ProfileFunction';
import { OptimizelyFeature } from '@optimizely/react-sdk';

const DrawerSubItemsOpen = [
  'openMessages',
  'openFaq',
  'openNews',
  'openElections',
  'openDirectories',
  'openResources',
  'openPrayers',
  'openGlsa',
  'searchClicked',
  'coverletterClicked',
  'openHuquq',
];
const drawerWidth = 240;
const styles = (theme) => ({
  drawerWidth: {
    width: '300px',
  },
  scroll: {
    maxHeight: '200px',
    overflowY: 'scroll !important',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
});

class PancakeDrawer extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.handleSubMenuClick = this.handleSubMenuClick.bind(this);
    this.pancakeDrawerCloserButtonRef = React.createRef();
    this.auth = this.props.context.authService;
    this.profilefn = new ProfileFunction();
    this.state = {
      open: false,
      openSub: true,
      openMessages: false,
      openElections: false,
      openNews: false,
      openDirectories: false,
      openFaq: false,
      openResources: false,
      openPrayers: false,
      openGlsa: false,
      openHuquq: false,
      selected: null,
      decodedtoken: this.auth.getDecodedToken(),
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) window.scrollTo(0, 0);
    if (!prevProps.context.isDrawerOpen && this.props.context.isDrawerOpen) {
      this.pancakeDrawerCloserButtonRef.current.focus();
    }
  }

  closeOtherMenuItems = (ClickedMenuItem) => {
    DrawerSubItemsOpen.forEach((item) => {
      if (item !== ClickedMenuItem) {
        let closeitem = `${item}`;
        this.setState({ [closeitem]: false });
      }
    });
  };

  handleSubMenuClick = () => {
    this.setState((state) => ({ openSub: !state.openSub }));
  };
  handleMessagesMenuClick = () => {
    this.setState((state) => ({ openMessages: !state.openMessages }));
    this.closeOtherMenuItems('openMessages');
  };
  handleFaqMenuClick = () => {
    this.setState((state) => ({ openFaq: !state.openFaq }));
    this.closeOtherMenuItems('openFaq');
  };
  handleNewsMenuClick = () => {
    this.setState((state) => ({ openNews: !state.openNews }));
    this.closeOtherMenuItems('openNews');
  };
  handleElectionsMenuClick = () => {
    this.setState((state) => ({ openElections: !state.openElections }));
    this.closeOtherMenuItems('openElections');
  };
  handleDirectoriesMenuClick = () => {
    this.setState((state) => ({ openDirectories: !state.openDirectories }));
    this.closeOtherMenuItems('openDirectories');
  };
  handleResourcesMenuClick = () => {
    this.setState((state) => ({ openResources: !state.openResources }));
    this.closeOtherMenuItems('openResources');
  };
  handleHuquqMenuClick = () => {
    this.setState((state) => ({ openHuquq: !state.openHuquq }));
    this.closeOtherMenuItems('openHuquq');
  };
  handlePrayersMenuClick = () => {
    this.setState((state) => ({ openPrayers: !state.openPrayers }));
    this.closeOtherMenuItems('openPrayers');
  };
  handleGlsaMenuClick = () => {
    this.setState((state) => ({ openGlsa: !state.openGlsa }));
    this.closeOtherMenuItems('openGlsa');
  };
  handleSearchClick = () => {
    this.closeOtherMenuItems('searchClicked');
  };
  handleCoverLetterClick = () => {
    this.closeOtherMenuItems('coverletterClicked');
  };
  handleDrawerClose = () => {
    this.props.context.closeDrawerMenu();
  };

  updateSelected(selectedIndex) {
    this.setState({ selected: selectedIndex });
  }

  render() {
    const { classes, theme } = this.props;
    const { selected } = this.state;
    const tabIndexValue = this.props.context.isDrawerOpen ? '0' : '-1';
    const isdelegate = this.profilefn.isPersonDelegate(this.state.decodedtoken);
    const isadmin = this.profilefn.isAdmin(this.state.decodedtoken);
    return (
      <Drawer
        data-cy="drawer"
        className={classes.drawer}
        tabIndex={tabIndexValue}
        variant="persistent"
        anchor="left"
        open={this.props.context.isDrawerOpen}
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.drawerHeader}>
          <IconButton
            ref={this.pancakeDrawerCloserButtonRef}
            data-cy="closeicon"
            onClick={this.handleDrawerClose}
            aria-label="Close Menu"
          >
            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </div>
        <Divider />
        <List data-cy="list">
          <ListItem
            tabIndex={tabIndexValue}
            data-cy="messages-listitem"
            button
            onClick={this.handleMessagesMenuClick}
            backgroundcolor={this.state.isSubMenuOpen}
          >
            <ListItemIcon>
              <LibraryBooks />
            </ListItemIcon>
            <Link tabIndex={tabIndexValue} data-cy="letterdrawerlink" to="/guidance">
              <ListItemText primary="Recent Guidance" />
            </Link>
            {this.state.openMessages ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.openMessages} timeout="auto" unmountOnExit>
            <div className={classes.scroll}>
              <ListItem tabIndex="-1" button selected={this.state.openMessages} classes={{ selected: classes.selected }}>
                <ErrorBoundary>
                  <MessageDrawer classes={classes.list ?? {}} />
                </ErrorBoundary>
              </ListItem>
            </div>
          </Collapse>
          <ListItem tabIndex={tabIndexValue} button onClick={this.handleResourcesMenuClick} data-cy="resources-listitem">
            <ListItemIcon>
              <Folder />
            </ListItemIcon>
            <Link tabIndex={tabIndexValue} to="/resources">
              <ListItemText primary="Resources" />
            </Link>
            {this.state.openResources ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.openResources} timeout="auto" unmountOnExit>
            <div className={classes.scroll}>
              <ListItem tabIndex="-1" button selected={this.state.openResources} classes={{ selected: classes.selected }}>
                <List className={classes.list}>
                  <ListItem tabIndex={tabIndexValue} onClick={() => this.updateSelected(1)} selected={selected === 1}>
                    <Link tabIndex={tabIndexValue} data-cysublev="subdrawerlink" to="/resources/assembly-references">
                      <ListItemText primary="Assembly and Community References" className="subdrawer-list-item" />
                    </Link>
                  </ListItem>
                  <Divider />
                  <ListItem tabIndex={tabIndexValue} onClick={() => this.updateSelected(2)} selected={selected === 2}>
                    <Link tabIndex={tabIndexValue} data-cysublev="subdrawerlink" to="/resources/annual-reports">
                      <ListItemText primary="Annual Reports" className="subdrawer-list-item" />
                    </Link>
                  </ListItem>
                  <Divider />
                  <ListItem tabIndex={tabIndexValue} onClick={() => this.updateSelected(3)} selected={selected === 3}>
                    <a
                      data-cysublev="subdrawerlink"
                      href="http://www.bahaicenterassistance.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ListItemText primary="Bahá’í Center Assistance" className="subdrawer-list-item" />
                    </a>
                  </ListItem>
                  <Divider />
                  <ListItem tabIndex={tabIndexValue} onClick={() => this.updateSelected(4)} selected={selected === 4}>
                    <Link tabIndex={tabIndexValue} data-cysublev="subdrawerlink" to="/resources/inquiry-services">
                      <ListItemText primary="Inquiry Services" className="subdrawer-list-item" />
                    </Link>
                  </ListItem>
                  <Divider />
                  <ListItem tabIndex={tabIndexValue} onClick={() => this.updateSelected(5)} selected={selected === 5}>
                    <Link tabIndex={tabIndexValue} data-cysublev="subdrawerlink" to="/resources/membership">
                      <ListItemText primary="Membership" className="subdrawer-list-item" />
                    </Link>
                  </ListItem>
                  <Divider />
                  <ListItem tabIndex={tabIndexValue} onClick={() => this.updateSelected(6)} selected={selected === 6}>
                    <Link tabIndex={tabIndexValue} data-cysublev="subdrawerlink" to="/resources/national-statistics-office">
                      <ListItemText primary="National Statistics Office" className="subdrawer-list-item" />
                    </Link>
                  </ListItem>
                  <Divider />
                  <ListItem tabIndex={tabIndexValue} onClick={() => this.updateSelected(7)} selected={selected === 7}>
                    <Link tabIndex={tabIndexValue} data-cysublev="subdrawerlink" to="/resources/public-affairs">
                      <ListItemText primary="Public Affairs" className="subdrawer-list-item" />
                    </Link>
                  </ListItem>
                  <Divider />
                  <ListItem tabIndex={tabIndexValue} onClick={() => this.updateSelected(8)} selected={selected === 8}>
                    <Link tabIndex={tabIndexValue} data-cysublev="subdrawerlink" to="/resources/review">
                      <ListItemText primary="Review" className="subdrawer-list-item" />
                    </Link>
                  </ListItem>
                  <Divider />
                  <ListItem tabIndex={tabIndexValue} onClick={() => this.updateSelected(9)} selected={selected === 9}>
                    <Link tabIndex={tabIndexValue} data-cysublev="subdrawerlink" to="/resources/service-opportunities">
                      <ListItemText primary="Service Opportunities" className="subdrawer-list-item" />
                    </Link>
                  </ListItem>
                  <Divider />
                  <ListItem tabIndex={tabIndexValue} onClick={() => this.updateSelected(10)} selected={selected === 10}>
                    <Link tabIndex={tabIndexValue} data-cysublev="subdrawerlink" to="/resources/submission-guidelines">
                      <ListItemText primary="Stories for The American Bahá’í" className="subdrawer-list-item" />
                    </Link>
                  </ListItem>
                  <Divider />
                  <ListItem tabIndex={tabIndexValue} onClick={() => this.updateSelected(11)} selected={selected === 11}>
                    <a
                      tabIndex={tabIndexValue}
                      data-cysublev="subdrawerlink"
                      href="https://bahaitreasurer.us/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ListItemText primary="Treasurer’s Tools" className="subdrawer-list-item" />
                    </a>
                  </ListItem>
                </List>
              </ListItem>
            </div>
          </Collapse>
          <ListItem tabIndex={tabIndexValue} button onClick={this.handleDirectoriesMenuClick} data-cy="directories-listitem">
            <ListItemIcon>
              <ImportContacts />
            </ListItemIcon>
            <Link tabIndex={tabIndexValue} data-cy="directorylink" to="/directories">
              <ListItemText primary="Directories" />
            </Link>
            {this.state.openDirectories ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.openDirectories} timeout="auto" unmountOnExit>
            <div className={classes.scroll}>
              <ListItem tabIndex="-1" button selected={this.state.openDirectories} classes={{ selected: classes.selected }}>
                <List className={classes.list}>
                  <ListItem
                    tabIndex={tabIndexValue}
                    data-cy="dirdrawerlist"
                    onClick={() => this.updateSelected(1)}
                    selected={selected === 1}
                  >
                    <Link
                      tabIndex={tabIndexValue}
                      data-cysublev="subdrawerlink"
                      data-cy="dirdrawerlink6"
                      to="/directories/huququllah-trustees"
                    >
                      <ListItemText
                        primary="Board of Ḥuqúqu’lláh Trustees Deputies &amp; Representatives"
                        className="subdrawer-list-item"
                      />
                    </Link>
                  </ListItem>
                  <Divider />
                  <ListItem
                    tabIndex={tabIndexValue}
                    data-cy="dirdrawerlist"
                    onClick={() => this.updateSelected(2)}
                    selected={selected === 2}
                  >
                    <Link
                      tabIndex={tabIndexValue}
                      data-cysublev="subdrawerlink"
                      data-cy="dirdrawerlink2"
                      to="/directories/counselors"
                    >
                      <ListItemText primary="Continental Board of Counselors" className="subdrawer-list-item" />
                    </Link>
                  </ListItem>
                  <Divider />
                  <ListItem
                    tabIndex={tabIndexValue}
                    data-cy="dirdrawerlist"
                    onClick={() => this.updateSelected(3)}
                    selected={selected === 3}
                  >
                    <Link
                      tabIndex={tabIndexValue}
                      data-cysublev="subdrawerlink"
                      data-cy="dirdrawerlink0"
                      to="/directories/abm-directory"
                    >
                      <ListItemText primary="Auxiliary Board Members" className="subdrawer-list-item" />
                    </Link>
                  </ListItem>
                  <Divider />
                  <ListItem
                    tabIndex={tabIndexValue}
                    data-cy="dirdrawerlist"
                    onClick={() => this.updateSelected(4)}
                    selected={selected === 4}
                  >
                    <Link
                      tabIndex={tabIndexValue}
                      data-cysublev="subdrawerlink"
                      data-cy="dirdrawerlink4"
                      to="/directories/councils"
                    >
                      <ListItemText primary="Regional Bahá’í Councils" className="subdrawer-list-item" />
                    </Link>
                  </ListItem>
                  <Divider />
                  <ListItem
                    tabIndex={tabIndexValue}
                    data-cy="dirdrawerlist"
                    onClick={() => this.updateSelected(5)}
                    selected={selected === 5}
                  >
                    <Link
                      tabIndex={tabIndexValue}
                      data-cysublev="subdrawerlink"
                      data-cy="dirdrawerlink3"
                      to="/directories/national"
                    >
                      <ListItemText primary="National Offices" className="subdrawer-list-item" />
                    </Link>
                  </ListItem>
                  <Divider />
                  <ListItem
                    tabIndex={tabIndexValue}
                    data-cy="dirdrawerlist"
                    onClick={() => this.updateSelected(6)}
                    selected={selected === 6}
                  >
                    <Link
                      tabIndex={tabIndexValue}
                      data-cysublev="subdrawerlink"
                      data-cy="dirdrawerlink1"
                      to="/directories/assemblies-and-groups"
                    >
                      <ListItemText primary="Assemblies and Groups" className="subdrawer-list-item" />
                    </Link>
                  </ListItem>
                </List>
              </ListItem>
            </div>
          </Collapse>
          <ListItem tabIndex={tabIndexValue} button onClick={this.handleElectionsMenuClick} data-cy="elections-listitem">
            <ListItemIcon>
              <HowToVote />
            </ListItemIcon>
            <Link tabIndex={tabIndexValue} to="/elections">
              <ListItemText primary="Elections" />
            </Link>
            {this.state.openElections ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.openElections} timeout="auto" unmountOnExit>
            <div className={classes.scroll}>
              <ListItem tabIndex="-1" button selected={this.state.openElections} classes={{ selected: classes.selected }}>
                <List className={classes.list}>
                  <ListItem tabIndex={tabIndexValue} onClick={() => this.updateSelected(1)} selected={selected === 1}>
                    <Link tabIndex={tabIndexValue} data-cysublev="subdrawerlink" to="/elections/rbc-elections">
                      <ListItemText primary="Regional Bahá’í Council Elections" className="subdrawer-list-item" />
                    </Link>
                  </ListItem>
                  <Divider />
                  <ListItem tabIndex={tabIndexValue} onClick={() => this.updateSelected(2)} selected={selected === 2}>
                    <Link tabIndex={tabIndexValue} data-cysublev="subdrawerlink" to="/elections/ridvan-elections">
                      <ListItemText primary="Ridvan Elections" className="subdrawer-list-item" />
                    </Link>
                  </ListItem>
                  <Divider />
                  <ListItem tabIndex={tabIndexValue} onClick={() => this.updateSelected(3)} selected={selected === 3}>
                    <Link tabIndex={tabIndexValue} data-cysublev="subdrawerlink" to="/elections/unit-convention">
                      <ListItemText primary="Delegate Elections/Unit Conventions" className="subdrawer-list-item" />
                    </Link>
                  </ListItem>
                  <Divider />
                  <ListItem tabIndex={tabIndexValue} onClick={() => this.updateSelected(4)} selected={selected === 4}>
                    <Link tabIndex={tabIndexValue} data-cysublev="subdrawerlink" to="/elections/by-elections">
                      <ListItemText primary="By-Elections" className="subdrawer-list-item" />
                    </Link>
                  </ListItem>
                  <Divider />
                  <ListItem tabIndex={tabIndexValue} onClick={() => this.updateSelected(5)} selected={selected === 5}>
                    <Link tabIndex={tabIndexValue} data-cysublev="subdrawerlink" to="/elections/natcon-resources">
                      <ListItemText primary="National Convention Resources" className="subdrawer-list-item" />
                    </Link>
                  </ListItem>
                  <OptimizelyFeature feature="delegate_page">
                    {(isEnabled) =>
                      (isEnabled && isdelegate) || isadmin ? (
                        <>
                          <Divider />
                          <ListItem tabIndex={tabIndexValue} onClick={() => this.updateSelected(7)} selected={selected === 7}>
                            <Link tabIndex={tabIndexValue} data-cysublev="subdrawerlink" to="/elections/delegates">
                              <ListItemText primary="Delegate Resources" className="subdrawer-list-item" />
                            </Link>
                          </ListItem>
                        </>
                      ) : null
                    }
                  </OptimizelyFeature>
                </List>
              </ListItem>
            </div>
          </Collapse>
          <ListItem
            tabIndex={tabIndexValue}
            button
            onClick={this.handleHuquqMenuClick}
            backgroundcolor={this.state.isSubMenuOpen}
          >
            <ListItemIcon>
              <InsertDriveFile />
            </ListItemIcon>
            <Link tabIndex={tabIndexValue} to="/huququllah-resources">
              <ListItemText primary="Ḥuqúqu’lláh" />
            </Link>
            {this.state.openHuquq ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.openHuquq} timeout="auto" unmountOnExit>
            <div className={classes.scroll}>
              <ListItem tabIndex="-1" button selected={this.state.openHuquq} classes={{ selected: classes.selected }}>
                <ErrorBoundary>
                  <HuquqDrawer class={classes.list} />
                </ErrorBoundary>
              </ListItem>
            </div>
          </Collapse>
          <ListItem
            tabIndex={tabIndexValue}
            button
            onClick={this.handleFaqMenuClick}
            data-cy="faq-listitem"
            backgroundcolor={this.state.isSubMenuOpen}
          >
            <ListItemIcon>
              <Help />
            </ListItemIcon>
            <Link tabIndex={tabIndexValue} data-cy="faqdrawerlink" to="/faq">
              <ListItemText primary="FAQ" />
            </Link>
            {this.state.openFaq ? <ExpandLess data-cy="faq-expandmore" /> : <ExpandMore data-cy="expandless-faq" />}
          </ListItem>
          <Collapse in={this.state.openFaq} timeout="auto" unmountOnExit>
            <div data-cy="faqdrawer" className={classes.scroll}>
              <ListItem tabIndex="-1" button selected={this.state.openFaq} classes={{ selected: classes.selected }}>
                <ErrorBoundary>
                  <FaqDrawer faqTaxonomy={this.props.context.faqTaxonomy} class={classes.list} />
                </ErrorBoundary>
              </ListItem>
            </div>
          </Collapse>
          <ListItem tabIndex={tabIndexValue} button onClick={this.handlePrayersMenuClick} data-cy="prayer-listitem">
            <ListItemIcon>
              <ImportContacts />
            </ListItemIcon>
            <Link tabIndex={tabIndexValue} data-cy="prayerlink" to="/prayer">
              <ListItemText primary="Prayers" />
            </Link>
            {this.state.openPrayers ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.openPrayers} timeout="auto" unmountOnExit>
            <div className={classes.scroll}>
              <ListItem tabIndex="-1" button selected={this.state.openPrayers}>
                <ErrorBoundary>
                  <PrayerDrawer prayerTaxonomy={this.props.context.prayerTaxonomy} class={classes.list} />
                </ErrorBoundary>
              </ListItem>
            </div>
          </Collapse>
          <ListItem tabIndex="-1" button data-cy="writing-listitem">
            <ListItemIcon>
              <ImportContacts />
            </ListItemIcon>
            <Link tabIndex={tabIndexValue} data-cy="writingdrawerlink" to="/writing">
              <ListItemText primary="Writings" />
            </Link>
          </ListItem>
          <div>
            <ListItem tabIndex={tabIndexValue} button onClick={this.handleGlsaMenuClick} data-cy="glsa-listitem">
              <ListItemIcon>
                <ImportContacts />
              </ListItemIcon>
              <Link tabIndex={tabIndexValue} data-cy="glsadrawerlink" to="/glsa">
                <ListItemText primary="Guidelines for LSAs" />
              </Link>
              {this.state.openGlsa ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.openGlsa} timeout="auto" unmountOnExit>
              <div className={classes.scroll}>
                <ListItem tabIndex="-1" button selected={this.state.openGlsa}>
                  <ErrorBoundary>
                    <GlsaDrawer class={classes.list ?? {}} classes={classes ?? {}} />
                  </ErrorBoundary>
                </ListItem>
              </div>
            </Collapse>
          </div>
          <div>
            <ListItem tabIndex={tabIndexValue} button data-cy="news-listitem" onClick={this.handleNewsMenuClick}>
              <ListItemIcon>
                <Public />
              </ListItemIcon>
              <Link tabIndex={tabIndexValue} data-cy="newsdrawerlink" to="/news">
                <ListItemText primary="News" />
              </Link>
              {this.state.openNews ? <ExpandLess data-cy="news-expandmore" /> : <ExpandMore data-cy="expandless-news" />}
            </ListItem>
            <Collapse in={this.state.openNews} timeout="auto" unmountOnExit>
              <div data-cy="newsdrawer" className={classes.scroll}>
                <ListItem tabIndex="-1" button selected={this.state.openNews} classes={{ selected: classes.selected }}>
                  <ErrorBoundary>
                    <NewsDrawer class={classes.list ?? ''} classes={classes.list ?? {}} />
                  </ErrorBoundary>
                </ListItem>
              </div>
            </Collapse>
          </div>
        </List>
      </Drawer>
    );
  }
}

PancakeDrawer.defaultProps = {
  component: 'li',
  role: 'menuitem',
};

PancakeDrawer.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  role: PropTypes.string,
  theme: PropTypes.object.isRequired,
  details: PropTypes.shape({
    class: PropTypes.string,
    prayerTaxonomy: PropTypes.array,
  }),
};

export default withRouter(withContext(withStyles(styles, { withTheme: true })(PancakeDrawer)));
